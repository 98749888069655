import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrlConst } from 'src/app/const/apiConst';
import { JobFilters } from 'src/app/core/models/jobs';
import { config } from 'src/config/config';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(public _http: HttpClient, @Inject(DOCUMENT) private _doc: Document) { }

  /**
   * Get All Jobs
   * @param filters
   * @returns
   */
  getAllJobs(filters: JobFilters): Observable<any> {
    let params = new HttpParams();
    params = params.append('offset', filters.offset.toString());
    params = params.append('limit', filters.limit.toString());
    if (filters?.company) {
      params = params.append('company', filters.company.toString());
    }
    const options = { params: params };
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getAllJobs, options);
  }

  /**
   * Get Job Types
   * @returns
   */
  getJobTypes() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getJobTypes);
  }

  /**
   * Get Job categories
   * @returns
   */
  getJobCategories() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getCategories);
  }

  /**
   * Get PayRange List
   * @returns
   */
  getJobPayRangeList() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getPayRangeList);
  }

  /**
   * Get All Experience Level Data
   * @returns
   */
  getExperienceLevelList() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.experienceLevel);
  }

  /**
   * Filters Jobs
   * @param params
   * @returns
   */
  filtersJob(params: JobFilters): Observable<any> {
    const options = { params: params };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getJobsByFilter, options);
  }

  /**
   * Get Company Jobs By Filter
   * @param categories
   * @returns
   */
  getCompanyJobSByFilter(categories?: any, userId?: any) {
    var inputParams = {
      jobCategory: !categories ? null : categories,
      userId: userId,
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getCompanyFilterJob, inputParams);
  }

  /**
   * Get Jobs By Filter
   * @param offset
   * @param limit
   * @param cities
   * @param categories
   * @param skills
   * @param types
   * @param minSalary
   * @param maxSalary
   * @param salaryRange
   * @returns
   */
  getJobsByFilter(
    offset?: any,
    cities?: any,
    categories?: any,
    types?: any,
    salaryRange?: any,
    experiences?: any,
    sortBy?: any,
    keyword?: any,
    company?: any
  ) {
    var inputParams = {
      limit: 10,
      offset: offset,
      city: !cities ? null : cities,
      jobType: !types ? null : types,
      salaryrange: salaryRange,
      jobCategory: !categories ? null : categories,
      experience: !experiences ? null : experiences,
      sortBy: !sortBy ? null : sortBy,
      keyword: keyword,
      company: company ? company : ''
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getJobsByFilter, inputParams);
  }

  /**
   * Get Jobs By search
   * @param offset
   * @param limit
   * @param keyword
   * @returns
   */
  getJobsBySearch(offset?: any, limit?: any, keyword?: any) {
    if (offset === 0) {
      offset = offset;
    } else {
      offset = offset * limit;
    }
    var inputParams = {
      limit: limit,
      skip: offset,
      offset: offset,
      keyword: keyword,
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getJobsByFilter, inputParams);
  }

  /**
   * Get Copmany Posted Job By search
   * @param keyword
   * @returns
   */
  companyjobsearch(params:any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.companyjobsearch, params);
  }

  /**
   * Geting jobs By id
   * @param id
   * @returns
   */
  getJobsById(id: string) {
    let params = new HttpParams();
    params = params.append('_id', id);
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getSingleJob + '?' + params);
  }

  getJobById(id: string) {
    let params = new HttpParams();
    params = params.append('_id', id);
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getJob + '?' + params);
  }

  /**
   *  UpdatejobData
   * @param data
   * @returns
   */
  updateJobData(data: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.updateJobs, data);
  }

  updateJobStatus(payload: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.updateJobStatus, payload);
  }
  /**
   * post new job
   * @param inputParams
   * @returns
   */
  postNewJob(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.addNewJobs, inputParams);
  }

  checkEligibility() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.checkEligibility);
  }

  /**
   * Apply For job
   * @param inputParams
   * @returns
   */
  applyForJobs(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.applyForJobs, inputParams);
  }

  /**
   * Save job
   * @param inputParams
   * @returns
   */
  saveJob(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.saveJob, inputParams);
  }

  /**
   * Apply For job
   * @param inputParams
   * @returns
   */
  getUserAppliedJobs(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getAppliedJobsByUserId, inputParams);
  }

  /**
   * Apply For job
   * @param inputParams
   * @returns
   */
  getAppliedJobsByUserIdFilter(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getAppliedJobsByUserIdFilter, inputParams);
  }

  /**
   * Get User Applied job by search
   * @param inputParams
   * @returns
   */
  getAppliedJobsByUserIdbySearch(params: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getAppliedJobsByUserIdBySearch ,params);
  }

  /**
   * Get Candidate Job Preferences
   * @param candidateId
   * @returns
   */
  getCandidateJobPreferences() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getCandidateJobPreference);
  }

   /**
   * Get Candidate Job Preferences
   * @returns
   */
   getCandidateProfileJobPreferences(name:any) {
    let params = new HttpParams();
    params = params.append('user_name', name);    
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getCandidateProfileJobPreference+ '?' + params);
  }


  /**
   * Update Candidate Job Preferences
   * @param options
   * @returns
   */
  updateCandidateJobPreference(options: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.updateCandidateJobPreference, options);
  }

  /**
   * Post Message
   * @param inputParams
   * @returns
   */
  postmessage(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.postChat, inputParams);
  }

  /**
   * Get Chat Messages
   * @param fromId
   * @param toId
   * @param offset
   * @param limit
   * @returns
   */
  getChatMessages(fromId: any, filter: any) {
    var inputParams = {
      from_id: fromId,
      offset: filter.offset,
      limit: filter.limit,
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getChatMessages, inputParams);
  }
  /**
   * Read Unread Messages
   * @param to_id
   * @returns
   */
  readChatMessages(to_id: any) {
    var inputParams = {
      to_id: to_id,
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.readUnreadMessages, inputParams);
  }

  getRecruierUnreadMessages() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getRecruiterUnreadMessages);
  }

  getNetworkUnreadMessages() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getNetworkUnreadMessages);
  }

  getClientUnreadMessages() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getClientUnreadMessages);
  }

  readNetworkChatMessages(to_id: any) {
    var inputParams = {
      to_id: to_id,
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.readNetworkUnreadMessages, inputParams);
  }

  readClientChatMessages(to_id: any) {
    var inputParams = {
      to_id: to_id,
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.readClientUnreadMessages, inputParams);
  }

  /**
   * Get My network Messages
   * @param fromId
   * @param toId
   * @param offset
   * @param limit
   * @returns
   */
  getmynetworkMessages(fromId: any, filter: any) {
    var inputParams = {
      from_id: fromId,
      offset: filter.offset,
      limit: filter.limit,
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getMyNetworkMessages, inputParams);
  }

  getClientMessages(fromId: any, filter: any) {
    var inputParams = {
      from_id: fromId,
      offset: filter.offset,
      limit: filter.limit,
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getClientMessages, inputParams);
  }

  /**
   * Get All Plan List
   */
  getPlanList() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getPlanList);
  }

  getBillingTypes() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getBillingTypes);
  }

  /**
   * Create New Messages
   * @param inputParams
   * @returns
   */
  postChat(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.postChat, inputParams);
  }

  /**
   * Create New Messages
   * @param inputParams
   * @returns
   */
  postMyNetworkChat(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.postmynetworkChat, inputParams);
  }

  postClientChat(inputParams: any) {
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.postclientChat, inputParams);
  }

  /**
   * Get Users Chat
   * @param user_id
   * @param offset
   * @param limit
   * @returns
   */
  getChatUsers(chatFilters: any, keyword?: any) {
    var inputParams = {
      offset: chatFilters.offset,
      limit: chatFilters.limit,
      keyword: keyword && keyword.length ? keyword : '',
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getChatUsers, inputParams);
  }

  /**
   * Get my Network Users
   * @param user_id
   * @param offset
   * @param limit
   * @returns
   */
  getMyNetworkUsers(chatFilters: any, keyword?: any) {
    var inputParams = {
      offset: chatFilters.offset,
      limit: chatFilters.limit,
      keyword: keyword && keyword.length ? keyword : '',
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getMyNetworkUsers, inputParams);
  }

  getClientUsers(chatFilters: any, keyword?: any) {
    var inputParams = {
      offset: chatFilters.offset,
      limit: chatFilters.limit,
      keyword: keyword && keyword.length ? keyword : '',
    };
    return this._http.post(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getClientUsers, inputParams);
  }

  /**
   * Get Candidate by Id
   */
  getCandidateById(id: any) {
    let params = new HttpParams();
    params = params.append('profile_id', id);
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getCandidateById + '?' + params);
  }

  /**
   * Add prefrences
   * @param inputParams
   * @returns
   */
  updatePreferences(inputParams: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.updateprefrences, inputParams);
  }

  /**
   * Get Applicant Profiles
   */
  applicantProfile(id: any) {
    let params = new HttpParams();
    params = params.append('id', id);
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getApplicantsByJobId + '?' + params);
  }

  /**
   * Get Active Job List by Company
   * @param inputParams
   * @returns
   */
  jobListBycompany(companyId: any, filters: JobFilters) {
    let params = new HttpParams();
    params = params.append('_id', companyId);
    params = params.append('offset', filters.offset.toString());
    params = params.append('limit', filters.limit.toString());
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getJobListByCompany + '?' + params);
  }

  /**
   * Get All Job List by Company
   * @param inputParams
   * @returns
   */
   allJobListByCompany(companyId: any, filters: JobFilters) {
    let params = new HttpParams();
    params = params.append('_id', companyId);
    params = params.append('offset', filters.offset.toString());
    params = params.append('limit', filters.limit.toString());
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getAllJobListByCompany + '?' + params);
  }

  /**
   * Check Candidate Applied Job
   * @param candidate_id
   * @returns
   */
  checkForJobsApplied() {

    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.checkForJobApplied);
  }

  /**
   * Removed Applied Job
   * @param _id
   * @returns
   */
  removeAppliedJob(_id: any) {
    let params = new HttpParams();
    params = params.append('_id', _id);
    return this._http.delete(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.removeAppliedJobs + '?' + params);
  }

  /**
   * Removed Company Job
   * @param _id
   * @returns
   */
  removeCompanyJob(_id: any) {
    let params = new HttpParams();
    params = params.append('_id', _id);
    return this._http.delete(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.removeJob + '?' + params);
  }

  /**
   * Update status of Candidate Applied Job
   * @param payload
   * @returns
   */
  updateAppliedJob(payload: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.updateAppliedJob, payload);
  }

  addExperienceLevel(payload: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.addExperienceLevel, payload);
  }

  updateExperienceLevel(payload: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.updateExperienceLevel, payload);
  }

  deleteExperienceLevel(payload: any) {
    return this._http.delete(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.deleteExperienceLevel, {
      params: {
        _id: payload._id,
      },
    }
    );
  }

  addJobType(payload: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.addJobType, payload);
  }

  updateJobType(payload: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.updateJobType, payload);
  }

  deleteJobType(payload: any) {
    return this._http.delete(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.deleteJobType, {
      params: {
        _id: payload._id,
      },
    }
    );
  }

  addPayRange(payload: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.addPayRange, payload);
  }

  getPayRangeList() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getPayRangeList);
  }

  getTalentServicePayRangeList() {
    return this._http.get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getTalentServicePayRangeList);
  }

  updatePayRange(payload: any) {
    return this._http.put(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.updatePayRange, payload);
  }

  deletePayRange(payload: any) {
    return this._http.delete(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.deletePayRange, {
      params: {
        _id: payload._id,
      },
    }
    );
  }

  location(): Location {
    return this._doc.location;
  }

}
