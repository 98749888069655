import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PostService } from 'src/app/core/services/post.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { PostDetailCardComponent } from 'src/app/shared/components/post-detail-card/post-detail-card.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Navigation } from 'swiper/core';

SwiperCore.use([Navigation]);
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  postStoryFilters = { offset: 0, limit: 10 };
  stories: any = [];
  private unsubscribe$ = new Subject();
  routesConst = routesConst;
  relatedPosts0: any[];
  postID: string = '';
  commentId: string = '';
  interestList: any[] = [];
  followers: any[] = [];
  profile: any;
  @ViewChild('relatedPosts', { read: ElementRef }) relatedPosts!: ElementRef;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20 // Adjust this value to set the desired gap between slides
    // effect: 'slide'
  };
  currentUrl: any;
  limit = 10;
  offset = 0;
  specificUserTotalPost: number = 0;
  specificUserTotalAvailablePost: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private postService: PostService,
    private sharedService: SharedService,
    private authService: AuthService,
    private meta: Meta,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private commonService: CommonService
  ) {
    this.relatedPosts0 = [];
  }

  ngOnInit(): void {
    this.meta.removeTag("name='description'");
    this.meta.removeTag("property='og:description'");
    this.meta.removeTag("property='og:title'");
    this.meta.addTag({
      name: 'description',
      content:
        'Welcome to post details page Sell, Create & Earn, Monetize everything across all platforms, Revolutionize the multi creator community on JOBPros.'
    });
    this.meta.addTag({
      property: 'og:description',
      content:
        'Welcome to post details page Sell, Create & Earn, Monetize everything across all platforms, Revolutionize the multi creator community on JOBPros.'
    });
    this.meta.addTag({
      property: 'og:title',
      content: 'JOBPros post details page'
    });

    if (this.authService.loggedIn()) {
      this.getNetworkPosts();
      this.dashboard();
    } else {
      this.getPopularPosts();
    }

    this.currentUrl = this.route.snapshot.url;
    if (this.currentUrl[0]?.path === 'profile') {
      this.specificUserAllPosts(this.currentUrl[1].path, this.currentUrl[3].path);
    } else {
      if (this.authService.loggedIn()) {
        this.getFollowersAndFollowings();
      }
      this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(
        (params: any) => {
          if (params.post_id) {
            this.postID = params.post_id;
            this.postService
              .getPostCategory(params.post_id)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((category: any) => {
                if (category?.post?.length) {
                  this.getRelatedPosts(category.post[0].category, false);
                }
              });
          }
        },
        () => {
          this.spinner.hide();
        }
      );
      this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((comment: any) => {
        if (comment?.comment) {
          this.commentId = comment.comment;
        }
      });
      this.getInterestList();
    }
  }

  specificUserAllPosts(user_name: any, post_id: any) {
    this.spinner.show();
    this.postService
      .getSpecificUserAllPosts(user_name, post_id, this.limit, this.offset)
      .subscribe((result: any) => {
        this.spinner.hide();
        if (result?.data?.length) {
          this.relatedPosts0 = [...this.relatedPosts0, ...result.data];
          this.specificUserTotalPost = this.specificUserTotalPost + result.total;
          this.specificUserTotalAvailablePost = result.totalAvialblesPosts;
        }
      });
  }

  ScrollDown() {
    if (this.specificUserTotalPost !== this.specificUserTotalAvailablePost) {
      this.offset = this.offset + 10;
      this.specificUserAllPosts(this.currentUrl[1].path, this.currentUrl[3].path);
    }
  }

  onSlideChange(swiper: any) {
    if (swiper.isEnd) {
      this.postStoryFilters.offset = this.postStoryFilters.offset + 10;
      if (this.authService.loggedIn()) {
        this.getNetworkPosts(swiper);
      } else {
        this.getPopularPosts(swiper);
      }
    }
  }

  trackByFunc(i: number) {
    return i;
  }

  postDetail(post: any) {
    this.router.navigate([routesConst.community + '/' + 'post' + '/' + post._id]);
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(network => {
        if (network?.followings || network?.followers) {
          this.followers = network.followings;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  // extractHashtags from string and return hashtags array
  extractHashtags(inputString: any) {
    const regex = /#[A-Za-z0-9_]+/g;
    const matches = inputString.match(regex);
    if (matches) {
      return matches;
    } else {
      return [];
    }
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.profile = profileInfo;
      }
    });
  }

  getInterestList() {
    this.sharedService.skillsOrIntrest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((interest: any) => {
        if (interest.length) {
          this.interestList = interest;
        } else {
          this.sharedService.getSkillsOrIntrest();
        }
      });
  }

  getRelatedPosts(category: string[], isScroll: any) {
    this.relatedPosts0 = [];
    this.postService
      .getRelatedPost(category)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((post: any) => {
        this.relatedPosts0 = post.data.filter((item: any) => item._id !== this.postID);
        if (isScroll && post?.data?.length) {
          setTimeout(() => {
            this.relatedPosts.nativeElement.scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            });
          }, 1000);
        }
      });
  }

  getNetworkPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getNetworkPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          // swiper?.update();
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  postContent() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    if (!this.authService.loggedIn()) {
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      this.dialog.open(UploadPostModalComponent, {
        maxWidth: '100vw',
        width: '536px',
        height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
        disableClose: true,
        data: {}
      });
    }
  }

  getPopularPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getPopularPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  onPostViewLogged(data: any, postDetailCard: PostDetailCardComponent) {
    if (data?.success) {
      postDetailCard.onPostViewLogged(data);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
