import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { toDisplayConstant } from 'src/app/const/appConst';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { SharedService } from '../../services/shared.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
@Component({
  selector: 'app-follow-state',
  templateUrl: './follow-state.component.html',
  styleUrls: ['./follow-state.component.scss']
})

export class FollowStateComponent implements OnInit {
  toDisplayConstant = toDisplayConstant;
  private unsubscribe$ = new Subject();
  @Input() showButton = false;
  @Input() style: string = '';
  @Input() toDisplay: string = toDisplayConstant.icon_text;
  @Input() following: any[] = [];
  @Input() target_user_id: string = '';
  @Output() isFollowed: EventEmitter<any> = new EventEmitter();
  isFollow: boolean = false;

  constructor(private snack: SnackBarService, private authService: AuthService, private dialog: MatDialog,
    private networkService: NetworkService, private router: Router, private sharedService: SharedService) { }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.authService.loggedIn()) {
      this.isFollow = this.following?.some((f: any) => f?.user_id == this.target_user_id);
    }
  }

  followUser() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
    } else {
      if (this.target_user_id) {
        if (this.isFollow) {
          this.networkService.unFollow(this.target_user_id).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            if (res.status === 'Success') {
              this.isFollow = false;
              this.isFollowed.emit(-1);
              this.sharedService.getFollowersAndFollowings();
            }
          });
        } else {
          this.networkService.followUser(this.target_user_id).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.isFollow = true;
            this.isFollowed.emit(1);
            this.sharedService.getFollowersAndFollowings();
          }, (error: any) => {
            this.snack.showMessage(`${error} `,true)
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
