<div
  class="edit-education-form-group bg-normal lg:p-6"
  [formGroup]="achievementForm"
>
  <p class="montserrat-bold">
    {{
      achType === AchievementType.Education
        ? "School/College"
        : achType === AchievementType.WorkHistory
        ? "Company Name"
        : "Client Name"
    }}
  </p>
  <input
    #title
    maxlength="129"
    class="mt-2 w-full input-form text-sm px-4"
    type="text"
    formControlName="title"
  />
  <mat-error
    *ngIf="errorHandling('title', 'maxlength')"
    [class]="errorHandling('title', 'maxlength') ? 'pb-4 pt-4' : ''"
  >
    <p class="s red">length can not exceed more than 128 characters!</p>
  </mat-error>
  <mat-error
    *ngIf="
      errorHandling('title', 'required') &&
      achievementForm.controls['title'].dirty
    "
    [class]="
      errorHandling('title', 'required') && achievementForm.controls.title.dirty
        ? 'pb-4 pt-4'
        : ''
    "
  >
    <p class="s red">field can not be empty</p>
  </mat-error>
  <p class="montserrat-bold mt-2 md:mt-6">
    {{
      achType === AchievementType.Education
        ? "Degree/Certificate"
        : achType === AchievementType.WorkHistory
        ? "Company Title"
        : "Project Title"
    }}
  </p>
  <input
    #description
    maxlength="129"
    class="mt-2 w-full input-form text-sm px-4"
    type="text"
    formControlName="description"
  />
  <mat-error
    *ngIf="errorHandling('description', 'maxlength')"
    [class]="errorHandling('description', 'maxlength') ? 'pb-4 pt-4' : ''"
  >
    <p class="s red">length can not exceed more than 128 characters!</p>
  </mat-error>
  <mat-error
    *ngIf="
      errorHandling('description', 'required') &&
      achievementForm.controls['description'].dirty
    "
    [class]="
      errorHandling('description', 'maxlength') &&
      achievementForm.controls.description.dirty
        ? 'pb-4 pt-4'
        : ''
    "
  >
    <p class="s red">field can not be empty</p>
  </mat-error>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-1/2">
      <p class="montserrat-bold mt-2 md:mt-6">Start date</p>
      <div class="flex w-full rounded overflow-hidden mt-2 md:mt-4">
        <app-month-picker
          [date0]="start_date"
          (setDate)="setStartDate($event)"
          [min]=""
          [max]="startDateMax"
        >
        </app-month-picker>
      </div>
    </div>
    <div class="w-full lg:w-1/2">
      <p
        class="montserrat-bold mt-2 md:mt-6"
        [class]="studingNow ? 'hidden' : ''"
      >
        End date
      </p>
      <div
        class="flex w-full rounded overflow-hidden mt-2 md:mt-4"
        [class]="studingNow ? 'hidden' : ''"
      >
        <app-month-picker
          [date0]="end_date"
          (setDate)="setEndDate($event)"
          [min]="endDateMin"
          [max]=""
        >
        </app-month-picker>
      </div>
    </div>
  </div>
  <mat-checkbox class="mt-3" formControlName="studingNow">Present</mat-checkbox>
  <div class="mt-9 flex">
    <button
      [disabled]="
        updateDetect ||
        !title.value.trim().length ||
        !description.value.trim().length ||
        errorHandling('title', 'maxlength') ||
        errorHandling('description', 'maxlength') ||
        errorHandling('title', 'required') ||
        errorHandling('description', 'required')
      "
      [class]="
        updateDetect ||
        !title.value.trim().length ||
        !description.value.trim().length ||
        errorHandling('title', 'maxlength') ||
        errorHandling('description', 'maxlength') ||
        errorHandling('title', 'required') ||
        errorHandling('description', 'required')
          ? 'disabled button'
          : 'button'
      "
      mat-button
      (click)="onSave()"
    >
      Save
    </button>
    <div class="ml-2">
      <button mat-button class="button normal h-full" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="ml-2" *ngIf="editMode">
      <button
        mat-button
        [class]="deletBtn ? 'disabled button' : ''"
        [disabled]="deletBtn"
        class="button warning"
        (click)="onDelete()"
      >
        Delete
      </button>
    </div>
  </div>
</div>
