<div *ngIf="data?.userList" class="message-client-card w-full p-4 pr-2 flex items-center cursor-pointer">
  <div class="message-client-card_avatar bg-gray-400 mr-2">
    <img [src]="getProfileImage(data?.userList)" class="rounded-full" />
  </div>
  <div class="">
  </div>
  <div class="ml-2">
    <p class="montserrat-medium" *ngIf="data?.userList?.user_name">{{ data?.userList?.user_name }}
      <mat-icon *ngIf="getUnreadCount(data.unread_messages)"
      [matBadge]="getUnreadCount(data.unread_messages)" matBadgeColor="warn">
      message</mat-icon>
    </p>
  </div>
  <div class="ml-auto">
    <!-- <p class="s gray-dark">Completed</p> -->
  </div>
  <img class="block md:hidden ml-auto mr-2 expand-icon" src="../../../../assets/images/arrow-right.svg" />
</div>
