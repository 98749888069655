import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { String } from 'aws-sdk/clients/apigateway';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {AchievementType, MonthNames} from 'src/app/core/const/options'
import { JobService } from 'src/app/core/services/job.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-review-applicant',
  templateUrl: './review-applicant.component.html',
  styleUrls: ['./review-applicant.component.scss'],
})
export class ReviewApplicantComponent implements OnInit {
  AchievementType = AchievementType;
  private unsubscribe$=new Subject();
  isActive: boolean = false;
  reviewApplicantData: any;
  candidateDetails: any[];
  skills: any[] = [];
  selectedUser: any;
  loginUser:any;
  
  constructor(private sharedService: SharedService, private auth: AuthService,private _jobService: JobService, private datePipe: DatePipe, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.candidateDetails = [];
  }

  toggleAside() {
    this.isActive = !this.isActive;
  }

  ngOnInit(): void {
    this.getJobApplicantList();
    if (this.auth.loggedIn()) {
      this.dashboard();
    }
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        this.loginUser = information;
      }
    });
  }

  /**
   * Get Job Applicants List
   */
  getJobApplicantList() {
    this.candidateDetails = [];
    this.reviewApplicantData = [];
    this._jobService.applicantProfile(this.data.id).pipe(takeUntil(this.unsubscribe$)).subscribe((applicantDetails: any) => {      
      if (!applicantDetails && applicantDetails.data.length === 0) {
        this.reviewApplicantData = applicantDetails.data;
      } else {
        this.selectedUser = applicantDetails?.data[0]?.candidate?._id
      }
      this.reviewApplicantData = applicantDetails.data;
      this.candidateDetails.push(this.reviewApplicantData[0]);
    });
  }

  /**
   * Get Skills name
   * @param skillId
   * @returns
   */
  getSkillsName(skillId: any) {
    let name;
    this.data.skills.forEach((skill: any) => {
      if (skill.id == skillId) {
        name = skill.label;
      }
    });
    return name;
  }

  /**
    * Format Date according Applied Date
    * @param previousDate
    * @returns
    */
  formatAppliedDate(previousDate?: any) {
    let currentDate = new Date().getTime();
    let jobDate = new Date(previousDate).getTime();
    let time = (currentDate - jobDate) / 1000;
    let days = Math.floor(time / (3600 * 24));
    let minute = Math.floor(time / 60);
    let hour = Math.floor(time / (60 * 60));
    if (minute < 60) {
      return minute + ' minutes ago';
    } else if (hour < 24 && hour > 0) {
      return hour + ' hours ago';
    } else if (days < 100 && days >= 1) {
      return days + ' days ago';
    } else {
      return this.datePipe.transform(previousDate, 'MMM d, y');
    }
  }

  /**
   * Fetch Details on Changing Candidate
   * @param candidateId
   */
  changeCandidate(candidateId: String) {
    this.selectedUser = candidateId
    this.candidateDetails = [];
    this.reviewApplicantData.forEach((applicant: any) => {
      if (candidateId === applicant.candidate._id) {
        this.candidateDetails.push(applicant);
      }
    });
  }

  /**
   * Approve or reject Candidate Application
   * @param status
   * @param candidate_id
   * @param _id
   */
  changeApplicantStatus(status: String, candidate_id: String, _id: String) {
    const updatedJobAppliedStauts = {
      applied_status: status,
      candidate_id: candidate_id,
      _id: _id,
    };
    this._jobService.updateAppliedJob(updatedJobAppliedStauts).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      const idx = this.reviewApplicantData.findIndex((item: any) => { return item?.candidate?._id === res?.data?.candidate_id })
      if (idx !== -1) {
        this.reviewApplicantData[idx].jobApplied.applied_status = res?.data?.applied_status;
      }
    });
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  getFormattedDate(date: string) {
    if (date) {
      let dateArr = date.split('-');
      const dateStr = dateArr[1] + '/' + dateArr[0];
      if (dateStr) {
        let dateArr = dateStr.split('/');
        return MonthNames[Number(dateArr[0]) - 1]?.substring(0, 3) + ' ' + dateArr[1];
      }
      return 'In Progress'
    }
    return 'In Progress';
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
}
}
