import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-transfer-funds-modal',
  templateUrl: './transfer-funds-modal.component.html',
  styleUrls: ['./transfer-funds-modal.component.scss'],
})
export class TransferFundsModalComponent implements OnInit {
  fundsAmount: string;

  constructor(public dialogRef: MatDialogRef<TransferFundsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fundsAmount = '$';
  }

  ngOnInit(): void { }

  setFundsAmount(e: any) {
    this.fundsAmount = e.target.value;
    if (this.fundsAmount.charAt(0) !== '$') {
      this.fundsAmount = '$' + this.fundsAmount;
    }
  }
}
