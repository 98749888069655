<div
  #card
  class="job-card "
  *ngIf="jobData !== null && jobData !== undefined"
  [ngClass]="isHome && this.authService.loggedIn() ? 'home-jobs' : ''"
  [ngClass]="feedCardType === 'JOB' ? 'max-w-[630px] mx-auto' : ''"
>
  <div class="New-Job-Card mt-2">
    <div class="p-4">
      <!-- <span class="Newest-Feed px-2 py-1">New</span> -->
      <div class="flex justify-between items-start">
        <p class="mt-2 montserrat-bold job-tittle">
          {{ jobData?.job?.job_title }}
        </p>
          <button *ngIf="!jobData?.job?.isApplied" (click)="jobApplyModal()"
           class="flex w-20 h-fit cursor-pointer justify-center px-2 py-1 font-bold border border-[#2887ed] rounded bg-[#2887ed] text-white hover:bg-white hover:text-[#2887ed] text-sm">
           Apply</button>
        <div
          *ngIf="jobData?.job?.isApplied && !jobData.job.isSaved"
          class="flex flex-col items-center lg:flex-row mt-2"
        >
          <mat-icon class="blue">assignment_turned_in</mat-icon>
          <span class="ml-2 apply-btn">Applied</span>
        </div>
      </div>
      <div class="flex flex-row py-2">
        <div
          class="thumbnail-img w-full rounded-full border border-black overflow-hidden"
          (click)="showProfile($event)"
        >
          <img
            *ngIf="jobData?.company"
            [src]="jobData?.company?.profile_img_path"
            onerror="this.src='assets/images/no-image.png'"
          />

          <img
            *ngIf="jobData?.profile"
            [src]="jobData?.profile?.profile_img_path"
            onerror="this.src='assets/images/no-image.png'"
          />
        </div>
        <div class="flex items-center job-Overview">
          <div class="flex flex-wrap">
            <span class="job-location ml-1" *ngIf="jobData?.job?.location">{{
              getLocation(jobData.job)
            }}</span>
            <div class="flex flex-row">
              <!-- <span class="pl-2 rating">3.0</span>
            <mat-icon class="ml-1">stars</mat-icon> -->
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <span class="job-price">{{ jobData.job.job_type }}</span>
        <span class="job-price">{{ jobData.job.salary_range }}/ Per Week</span>
        <ng-container
          *ngFor="
            let preferred_requirement of showAllJobTags ? jobData.job.preferred_requirements : jobData.job.preferred_requirements.slice(0, 1);
            let i = index
          "
        >
          <span class="job-price">{{ preferred_requirement }}</span>
          <ng-container *ngIf="showAllJobTags && i === jobData.job.preferred_requirements.length - 1 && jobData.job.preferred_requirements.length > 0 || !showAllJobTags && i === 0 && jobData.job.preferred_requirements.length > 0">
            <span class="see-more-text underline text-[#2887ed] cursor-pointer" (click)="showAllJobTags = !showAllJobTags"> {{showAllJobTags ? '...less':'...more'}}</span>
          </ng-container>
        </ng-container>
      </div>
      <div class="flex justify-between items-center">
        <span class="mt-2 !text-[#2887ed] text-xs font-normal">{{ formatPostDate(jobData?.job?.created_at) }}</span>
        <span
          *ngIf="
            jobData?.job?.create_by &&
            loginedUserId == jobData?.job?.company_id &&
            loginedUserId !== jobData?.job?.create_by?._id
          "
          class="mt-2 time-posted"
          >By: {{ '@' + jobData?.job?.create_by?.user_name }}</span
        >
      </div>
    </div>
  </div>
  <app-media-interaction-panel
  *ngIf="isShowInteractionPanel"
    [profile]="profile"
    [data]="dataForInteractionPanel"
  ></app-media-interaction-panel>
</div>
