<div>
  <div class="flex flex-col md:flex-row gap-2 sticky top-0 bg-white header justify-between md:items-end px-4 pt-2">
    <div class="flex">
      <img class="service-img rounded-lg" [src]="data.selectedService?.profile?.profile_img_path"
        onerror="this.src='assets/images/no-image.png'" />
      <div class="ml-4">
        <h2 class="text-base md:text-xl font-bold mb-2 flex">
          {{ data.selectedService?.profile?.name }}
        </h2>
        <div *ngIf="averageRating" class="flex">
          <star-rating [starType]="'svg'" [showHalfStars]="true" [disabled]="true" [rating]="averageRating"
            [matTooltip]="'Rating: ' + averageRating"></star-rating>
        </div>
        <p>Ratings and reviews</p>
      </div>
    </div>
    <mat-icon class="absolute right-2.5 top-2 mt-1 cursor-pointer" (click)="close()">close</mat-icon>
    <div class="flex gap-2 items-center justify-center pb-1">
      <button class="tag text-nowrap " (click)="reviewsType = 'Seller'"
        [ngClass]="reviewsType == 'Seller' ? 'bg-[#2887ed] text-white':'bg-[#ededed]'">
        Buyers
      </button>
      <button class="tag text-nowrap cursor-pointer" (click)="reviewsType = 'Buyer'"
        [ngClass]="reviewsType == 'Buyer' ? 'bg-[#2887ed] text-white':'bg-[#ededed]'">
        Sellers
      </button>
    </div>

  </div>
  <div *ngIf="reviewsType == 'Buyer' && buyerReviews?.length || reviewsType == 'Seller' && sellerReviews.length"
    class="px-4 mt-4">
    <!-- SELLER RATING / EXPERTS PAGE -->
    <div *ngFor="let review of reviewsType == 'Buyer'? buyerReviews:sellerReviews; index as i" class="review">
      <!-- BUYER SECTION -->
      <div class="order-2">
        <p class="font-bold cursor-pointer text-black"
          [ngClass]="data?.profile_id !== review?.service_creatorId ? 'mt-2':' my-2'"
          (click)="showService(review?.service_id, review?.user_name)">
          {{ review?.title }}
        </p>
        <div class="flex flex-col gap-0">
          <p class="review_comment">
            <span>
              <ng-container *ngIf="(data?.profile_id === review?.service_creatorId ? review?.buyerRating : review.sellerRating) > 0; else noFeedback">
                <star-rating [starType]="'svg'" [showHalfStars]="true" [disabled]="true"
                  [rating]="data?.profile_id === review?.service_creatorId ? review?.buyerRating?.toFixed(1) : review.sellerRating?.toFixed(1)"
                  [matTooltip]="'Rating: ' + (data?.profile_id === review?.service_creatorId ? review?.buyerRating : review.sellerRating)"></star-rating>
              </ng-container>
              <ng-template #noFeedback>
                <span> No feedback</span>
              </ng-template>
              <b>{{(data?.profile_id === review?.service_creatorId ? review?.buyerRating : review.sellerRating) > 0 ? (data?.profile_id === review?.service_creatorId ? review?.buyerRating?.toFixed(1) : review.sellerRating?.toFixed(1)) : ''}}</b>
            </span>
            {{ review?.buyerComment }}
          </p>
          <p class="review_comment">
            {{data?.profile_id === review?.service_creatorId ? 'To Buyer' : 'To Seller'}}: <span
              class="blue underline cursor-pointer"
              [routerLink]="'/profile/' + review?.buyerUser_name">{{data?.profile_id === review?.service_creatorId ?
              review?.buyerName : review?.sellerName}}</span>
            <span>
              <ng-container *ngIf="(data?.profile_id !== review?.service_creatorId ? review?.buyerRating : review.sellerRating) > 0; else noFeedback">
                <star-rating 
                  [starType]="'svg'" 
                  [showHalfStars]="true" 
                  [disabled]="true"
                  [rating]="data?.profile_id !== review?.service_creatorId ? review?.buyerRating?.toFixed(1) : review.sellerRating?.toFixed(1)"
                  [matTooltip]="'Rating: ' + (data?.profile_id !== review?.service_creatorId ? review?.buyerRating?.toFixed(1) : review.sellerRating?.toFixed(1))">
                </star-rating>
              </ng-container>
              <ng-template #noFeedback>
                <span> No feedback</span>
              </ng-template>
              <b>{{(data?.profile_id !== review?.service_creatorId ? review?.buyerRating : review.sellerRating) > 0 ? (data?.profile_id !== review?.service_creatorId ? review?.buyerRating?.toFixed(1) : review.sellerRating?.toFixed(1)) : ''}}</b>
            </span>
            {{review?.sellerComment}}
          </p>
        </div>
        <mat-divider class="divider"></mat-divider>
      </div>
      <!-- BUYER SECTION END -->


    </div>

    <!-- <p
      *ngIf="totalReviews"
      class="blue cursor-pointer flex justify-center mb-4"
      (click)="loadMoreReviews()"
    >
      Show More
    </p> -->
  </div>
  <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
  </ngx-spinner>
</div>