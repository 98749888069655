import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  formData = {
    name: '',
    email: '',
    message: '',
    subject: '',
    phone: '',
  };

  contactForm!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ContactUsComponent>,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.initForm();
  }
  phoneNumberValidator(control: FormControl) {
    const phoneNumberPattern = /^\(\d{3}\) \d{3}-\d{4}$/; // Regular expression for the specific phone number format (e.g., (212) 656-7794)
    if (control.value && !phoneNumberPattern.test(control.value)) {
      return { invalidPhoneNumber: true };
    }
    return null;
  }
  private initForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.maxLength(79),
          Validators.email,
          Validators.pattern(
            '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z, A-Z]{2,3}'
          ),
        ],
      ],
      subject: ['', Validators.required],
      phone: ['', [Validators.required, Validators.maxLength(16)]],
      message: ['', Validators.required],
    });
  }

  submitForm() {
    if (this.contactForm.valid) {
      this.profileService
        .sendMail(this.contactForm.value)
        .subscribe((res: any) => {
          this.snackBarService.showMessage(res.message,false);
          this.dialogRef.close();
        });
    } else {
      this.contactForm.markAllAsTouched(); // Mark all fields as touched to display errors
    }
  }
}
