import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiUrlConst } from 'src/app/const/apiConst';
import { config } from 'src/config/config';
import { JobService } from 'src/app/core/services/job.service';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, fromEvent, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { DOCUMENT } from '@angular/common';
import { ProfileService } from './profile.service';
import { Achievement } from '../models';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public refreshUnReadMessages$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isUnreadMessagesExist = new BehaviorSubject({
    recruiter: false,
    mynetwork: false,
    client: false
  });
  socket!: Socket;
  socketConfig = {
    reconnectionDelay: 10000,
    reconnectionDelayMax: 50000,
    reconnectionAttempts: 2
  };
  constructor(
    public _http: HttpClient,
    private _jobService: JobService,
    private profile: ProfileService,
    @Inject(DOCUMENT) private _doc: Document,
    private snack: SnackBarService
  ) {}
  finalResult: Observable<any> = EMPTY;

  connectSocket() {
    const environment = window.location.hostname;
    let socketUrl = '';
    switch (environment) {
      case 'localhost':
        socketUrl = `http://localhost:9000`;
        break;
      case 'https://www.jobpros.io':
        socketUrl = `https://api-prod.jobpros.io`;

        break;
      case 'jobpros.io':
        socketUrl = `https://api-prod.jobpros.io`;

        break;
      case 'web-dev.jobpros.io':
        socketUrl = `https://api-dev.jobpros.io`;
        // socketUrl = `http://jobpros-api4243.ambitiouswave-2b4fe4ab.westus.azurecontainerapps.io:4243/`;

        break;
      case 'web-qa.jobpros.io':
        socketUrl = `https://api-qa.jobpros.io`;

        break;
      default:
        socketUrl = `https://api-prod.jobpros.io`;
    }
    const token = this.profile.loadUserCredentials();
    if (token) {
      this.socket = io(socketUrl, {
        // path: '/app-sockets/socket.io',
        ...this.socketConfig,
        auth: { token }
      });
    } else {
      console.error('No Token Provide for Socket Connection Error');
    }
  }

  getWindow(): Window | null {
    return this._doc.defaultView;
  }

  location(): Location {
    return this._doc.location;
  }

  /**
   * Get Locations List
   * @param keyword
   * @returns
   */
  getLocation(keyword: String) {
    const location = {
      name: keyword
    };
    return this._http.post(
      config.environmentMode(this.location().hostname).endPoint + apiUrlConst.locationSearch,
      location
    );
  }

  _filterLocation(element: any) {
    return fromEvent(element, 'keyup').pipe(
      map((event: any) => {
        return event?.target?.value;
      }),

      filter((res: any) => res.length > 3),
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap((text: string) => this.getLocation(text))
    );
  }

  _filterChatUser(element: any, filterT: any, panel: any) {
    return fromEvent(element, 'keyup').pipe(
      map((event: any) => {
        return event?.target?.value;
      }),
      debounceTime(1000),
      distinctUntilChanged(),
      mergeMap((text: string) =>
        panel === 'chat'
          ? this._jobService.getChatUsers(filterT, text)
          : 'myNetwork'
          ? this._jobService.getMyNetworkUsers(filterT, text)
          : 'client'
          ? this._jobService.getClientUsers(filterT, text)
          : ''
      )
    );
  }

  newMessageReceived() {
    let observable = new Observable<{ user: String; message: String }>(observer => {
      this.socket?.on('recruiter-new-message', (incomingMessage: any) => {
        observer.next(incomingMessage);
      });
    });

    return observable;
  }

  newNetworkMessageReceived() {
    let observable = new Observable<{ user: String; message: String }>(observer => {
      this.socket?.on('network-new-message', (incomingMessage: any) => {
        observer.next(incomingMessage);
      });
    });
    return observable;
  }

  newClientMessageReceived() {
    let observable = new Observable<{ user: String; message: String }>(observer => {
      this.socket?.on('client-new-message', (incomingMessage: any) => {
        observer.next(incomingMessage);
      });
    });
    return observable;
  }

  newMessageNotificationReceived() {
    let observable = new Observable<Boolean>(observer => {
      this.socket?.on('new-message-notification', (incomingNotification: any) => {
        observer.next(incomingNotification);
      });
    });
    return observable;
  }

  newNotificationReceived() {
    let observable = new Observable<any>(observer => {
      this.socket?.on('new-notification', (incomingNotification: any) => {
        observer.next(incomingNotification);
      });
    });
    return observable;
  }

  newPostNotificationReceived() {
    let observable = new Observable<any>(observer => {
      this.socket?.on('post-delete-notification', (incomingNotification: any) => {
        this.snack.showMessage(incomingNotification, true);
        observer.next(incomingNotification);
      });
    });
    return observable;
  }

  refreshUnreadCount(value: boolean) {
    this.refreshUnReadMessages$.next(value);
  }

  sortAchievements(achievements: Achievement[]): Achievement[] {
    return achievements.sort((a: Achievement, b: Achievement) => {
      if (a.end_date === null) return -1;
      if (b.end_date === null) return 1;
      const dateA = new Date(a.end_date).getTime();
      const dateB = new Date(b.end_date).getTime();
      return dateB - dateA;
    });
  }
}
