import { Component, OnInit } from '@angular/core';
import { routesConst } from 'src/app/core/const/routers';

@Component({
  selector: 'app-stripe-payment-failed',
  templateUrl: './stripe-payment-failed.component.html',
  styleUrls: ['./stripe-payment-failed.component.scss']
})
export class StripePaymentFailedComponent implements OnInit {
  routesConst = routesConst;

  constructor() { }

  ngOnInit(): void {
  }

}
