import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SwiperOptions } from 'swiper';
import { ActivatedRoute, Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { shareTypeConst, userTypeConstant } from 'src/app/const/appConst';
import { PostService } from 'src/app/core/services/post.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { JobModalComponent } from '../job-modal/job-modal.component';
import { PostDetailCardComponent } from '../post-detail-card/post-detail-card.component';
import { UploadPostModalComponent } from '../upload-post-modal/upload-post-modal.component';

@Component({
  selector: 'app-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss']
})
export class CommunitiesComponent implements OnInit {
  shareTypeConst = shareTypeConst;
  routesConst = routesConst;
  config: SwiperOptions = {
    slidesPerView: 'auto'
  };
  userTypeConst = userTypeConstant;
  userType: string = userTypeConstant.candidate;
  selected = 0;
  postFilters = {
    offset: 0,
    limit: 10
  };
  subscriptions: any = [];
  members: any = [];
  trendPosts: any = [];
  allTrendPosts: any = [];
  trendProfiles: any = [];
  creators: any = [];
  photos: any = [];
  Sec15: any = [];
  Sec30: any = [];
  Sec60: any = [];
  stories: any = [];
  postStoryFilters = { offset: 0, limit: 15 };
  usersPayload = { offset: 0, limit: 20 };

  private unsubscribe$ = new Subject();
  selectedTrends: string = 'jobs';
  Router: any;
  myFollowings: any = [];
  jobs: any = [];
  services: any = [];
  profiles: any = [];

  jobFilters = {
    offset: 0,
    limit: 6
  };
  currentLoggedInUserId: any;
  user: any;

  categories = [
    {
      name: 'Jobs',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/MAl97.jpeg',
      id: '#TrendsJobs'
    },
    {
      name: 'Services',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/JHSJh.jpeg',
      id: '#TrendsServices'
    },
    {
      name: 'Creators',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/L1hVR.jpeg',
      id: '#TrendsCreators'
    },
    {
      name: 'Subscriptions',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/kiga3.jpeg',
      id: '#TrendsSubscriptions'
    },
    {
      name: 'Members',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/jtC1v.jpeg',
      id: '#TrendsMembers'
    },
    {
      name: 'Photos',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/Qfot2.jpeg',
      id: '#TrendsPhotos'
    },
    {
      name: '15 Sec',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/5RKf4.jpeg',
      id: '#Trends15Secs'
    },
    {
      name: '30 Sec',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/f7Dij.jpeg',
      id: '#Trends30Secs'
    },
    {
      name: '60 Sec',
      image: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/NqeiQ.jpeg',
      id: '#Trends60Secs'
    }
  ];
  trend: any;
  innerWidth = this.commonService.getWindow()?.innerWidth;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private sharedService: SharedService,
    private postService: PostService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private networkService: NetworkService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private snackService: SnackBarService,
    private clipboard: Clipboard,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((param: any) => {
      if (param?.tab) {
        this.selected = param?.tab;
        this.trend = this.categories.find((item: any) => {
          return item.name == param.trends;
        });
        if (this.trend) {
          this.selectedTrends = this.trend.name;
        }
      }
    });
    if (this.authService.loggedIn()) {
      this.getLoginedUserType();
      this.getFollowings();
      this.dashboard();
      this.getNetworkPosts();
    } else {
      this.getPopularPosts();
      this.getCommunityData();
      this.getTrends();
    }

    // this.getTopUsers();
  }

  getFollowings() {
    this.networkService
      .getFollowings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network: any) => {
        this.myFollowings = network.data;
        this.getCommunityData();
      });
  }

  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information: { profileInfo: any } | null) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else if (information != null) {
          const { profileInfo } = information;
          this.user = profileInfo;
          this.currentLoggedInUserId = profileInfo?._id;
          this.getTrends();
        }
      });
  }

  getCommunityData(swiper?: any) {
    this.spinner.show();
    this.postService
      .getCommunitiesData(this.jobFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data: any) => {
          this.creators = data.data.creators;
          if (data.data.viewsPosts.length) {
            let photosPosts =
              data?.data?.viewsPosts.filter((item: any) => {
                return item.media_type == 'moment';
              })[0].posts || [];
            this.photos = [...this.photos, ...photosPosts];

            let sec15Posts =
              data?.data?.viewsPosts.filter((item: any) => {
                return item.media_type == 'clip';
              })[0]?.posts || [];
            this.Sec15 = [...this.Sec15, ...sec15Posts];

            let sec30Posts =
              data?.data?.viewsPosts.filter((item: any) => {
                return item.media_type == 'highlight';
              })[0]?.posts || [];
            this.Sec30 = [...this.Sec30, ...sec30Posts];

            let sec60Posts =
              data?.data?.viewsPosts.filter((item: any) => {
                return item.media_type == 'trailer';
              })[0]?.posts || [];
            this.Sec60 = [...this.Sec60, ...sec60Posts];
          }
          this.members = data.data.members;
          this.subscriptions = data.data.subscriptions;
          const resultantJob = data.data.jobs.map((x: any) => ({
            ...x,
            profile: {
              ...x.profile,
              followed: this.myFollowings.some((f: any) => f.following == x?.profile?._id),
              type: 'job'
            }
          }));

          const resultantService = data.data.services.map((x: any) => ({
            ...x,
            profile: {
              ...x?.profile,
              followed: this.myFollowings.some((f: any) => f.following == x.profile._id),
              type: 'service'
            }
          }));
          this.jobs = [...this.jobs, ...resultantJob];
          this.services = [...this.services, ...resultantService];
          // let allServicesAndJobs = [...this.jobs, ...this.services, ...this.subscriptions];

          // let profiles = allServicesAndJobs.map((item: any) => {
          //   return {
          //     ...item,
          //     ...item.profile,
          //     isFollowing: item?.profile?.followed
          //   };
          // });
          // Remove duplication
          // this.profiles = Array.from(new Map(profiles.map((obj: any) => [obj._id, obj])).values());

          // this.profiles = profiles.sort(() => Math.random() - 0.5);

          const activeIndex = swiper?.activeIndex || 1;
          swiper?.slideTo(activeIndex - 1);
          this.cdr.detectChanges();

          this.spinner.hide();
          if (this.trend) {
            setTimeout(() => {
              this.scrollToSection(this.trend?.id);
              this.trend = null;
            }, 100);
          }
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  // removeSuggested(index: number) {
  //   this.profiles.splice(index, 1);
  // }

  removeTrendSuggested(index: number) {
    this.trendProfiles.splice(index, 1);
  }

  followUser(user: any) {
    if (!this.authService.loggedIn()) {
      this.navigate('/');
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
      return;
    }
    let userId = user?._id;
    if (userId) {
      this.spinner.show();
      this.networkService
        .followUser(userId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.spinner.hide();
          user.isFollowing = true;
        });
    }
  }

  getLoginedUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      this.userType = type;
      if (type === '') {
        this.sharedService.getUserType();
      }
    });
  }

  addInfo() {
    // if (this.userType == userTypeConstant.candidate) {
    //   const queryParams = { tab: 1 };
    //   this.navigate('/' + routesConst.dashboard + '/' + routesConst.jobs, queryParams);
    // } else {
    //   const queryParams = { tab: 1 };
    //   this.navigate('/' + routesConst.dashboard + '/' + routesConst.services, queryParams);
    // }

    let innerWidth = this.commonService.getWindow()?.innerWidth;
    if (!this.authService.loggedIn()) {
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      this.dialog.open(UploadPostModalComponent, {
        maxWidth: '100vw',
        width: '536px',
        height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
        disableClose: true,
        data: {}
      });
    }
  }

  navigate(url: any, params?: any) {
    this.ngZone.run(() => {
      const queryParams = {};
      if (params) Object.assign(queryParams, params);
      this.router.navigate([url], {
        queryParams: queryParams
      });
    });
  }

  tabSelect(event: any) {
    setTimeout(() => {
      this.selected = event;
    }, 1);
  }

  subscrptionBubbles(packeg?: any) {
    let selected;
    for (let index = 0; index < packeg.length; index++) {
      if (packeg[index].type == 'multimedia') {
        selected = 'Multimedia';
        break;
      } else if (packeg[index].type == 'videos') {
        selected = 'Videos';
        break;
      } else if (packeg[index].type === 'photos') {
        selected = 'Photos';
      }
    }
    return selected;
  }

  // onSlideChange(swiper: any) {
  //   if (swiper.isEnd) {
  //     this.usersPayload.offset = this.usersPayload.offset + 10;
  //     this.getTopUsers(swiper);
  //   }
  // }

  readMessage(userProfile: any, type?: any) {
    const { email, name, profile_image, user_name, user_type, _id } = userProfile;
    if (_id != this.currentLoggedInUserId) {
      const userInfo = {
        email,
        name,
        profile_img_path: profile_image,
        user_name,
        user_type,
        _id
      };
      localStorage.setItem('userId', JSON.stringify(userInfo));
    }

    let queryParams = {
      tab: type == 'job' ? 1 : 3
    };

    this.navigate('/' + routesConst.dashboard + '/' + routesConst.messages, queryParams);
  }

  copyLink(id: string, type: string, userName?: string) {
    if (type == 'job') {
      this.clipboard.copy(this.commonService.location().origin + '/jobs?id=' + id);
      this.snackService.showMessage(`Job Link Copied`, false);
    } else if (type == 'service') {
      this.clipboard.copy(
        this.commonService.location().origin + '/services/service/' + userName + '/' + id
      );
      this.snackService.showMessage(`Service Link Copied`, false);
    }
    // for subscription
    else {
      this.clipboard.copy(this.commonService.location().origin + '/profile/' + userName + '?tab=4');
      this.snackService.showMessage(`Subscription Link Copied`, false);
    }
  }

  share() {
    if (this.authService.loggedIn()) {
      this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px' : '100%',
        maxWidth: window.innerWidth > 600 ? '80vw' : '100vw',
        data: {
          link: this.commonService.location().origin + '/profile/' + this.user.user_name,
          shareType: shareTypeConst.profile
        }
      });
    } else {
      this.navigate('/');
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  showProfile(post: any) {
    this.navigate('/profile' + '/' + post?.profile?.user_name);
  }

  getTrends(swiper?: any) {
    this.spinner.show();
    if (this?.user?._id) {
      Object.assign(this.postFilters, {
        userId: this.user?._id
      });
    }
    this.postService
      .getTrendingPosts(this.postFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.status === 'Success') {
            this.trendPosts = [...this.trendPosts, ...res.data.trendingPosts];
            this.allTrendPosts = [
              ...this.allTrendPosts,
              ...res.data.hashtagPosts.concat(res.data.posts, res.data.trendingPosts)
            ];
            let profiles = this.allTrendPosts.map((item: any) => {
              return {
                ...item.profile,
                isFollowing: item?.isFollowing
              };
            });
            // Remove duplication
            this.trendProfiles = Array.from(
              new Map(profiles.map((obj: any) => [obj._id, obj])).values()
            );

            this.trendProfiles = this.trendProfiles.sort(() => Math.random() - 0.5);
            const activeIndex = swiper?.activeIndex || 1;
            swiper?.slideTo(activeIndex - 1);
            this.cdr.detectChanges();
          }

          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getRelatedPosts(postId: string) {
    this.router.navigate([routesConst.community + '/' + 'post' + '/' + postId]);
  }

  onTrendSlideChange(swiper: any) {
    if (swiper.isEnd) {
      this.postFilters.offset = this.postFilters.offset + 10;
      this.getTrends(swiper);
    }
  }

  copyTrendLink(postId: string) {
    this.clipboard.copy(this.commonService.location().origin + '/creators/post/' + postId);
    this.snackService.showMessage(`Profile Link Copied`, false);
  }

  onStorySlideChange(swiper: any) {
    this.postStoryFilters.offset = this.postStoryFilters.offset + 10;
    if (this.authService.loggedIn()) {
      this.getNetworkPosts(swiper);
    } else {
      this.getPopularPosts(swiper);
    }
  }

  getPopularPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getPopularPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getNetworkPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getNetworkPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  scrollToSection(element: any) {
    document.querySelector(element)?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  getServiceDescription(service: any) {
    return service.packages.find((item: any) => {
      if (item.type == 'basic') return item;
      else return service?.packages[0];
    });
  }

  getImage(suggestion: any) {
    return suggestion.portfolios.find((item: any) => item.cover_type == 'true') || {};
  }

  jobDetail(jobId: any) {
    const dialogRef = this.dialog.open(JobModalComponent, {
      width: innerWidth && innerWidth > 768 ? '650px' : '100vw',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      data: {
        id: jobId
      }
    });
  }

  // getTopUsers(swiper?:any){
  //   this.postService
  //   .getCommunitiesUsers(this.usersPayload)
  //   .pipe(takeUntil(this.unsubscribe$))
  //   .subscribe(
  //     (res: any) => {
  //       this.profiles = [...this.profiles, ...res.data]

  //       if (res.status === 'Success') {
  //         // this.profiles = res.data;

  //         // Remove duplication
  //       this.profiles = Array.from(
  //         new Map(this.profiles.map((obj: any) => [obj._id, obj])).values()
  //       );

  //       }

  //       const activeIndex = swiper?.activeIndex || 1;
  //       swiper?.slideTo(activeIndex - 1);
  //       this.cdr.detectChanges();
  //     })
  // }

  onPostViewLogged(data: any, postDetailCard: PostDetailCardComponent) {
    if (data?.success) {
      postDetailCard.onPostViewLogged(data);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
