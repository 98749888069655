export const AUTHCONSTANTS = {
  LOGINED_SUCCESS: 'Logined Success',
  LOGIN: 'Login',
  REGISTER: 'Register',
  WRONG_EMAIL_PASSWORD: 'Wrong email or password.',
  SESSION_EXPIRED: 'Session expired. Please login again',
  USER_NAME_NULL: 'user_name is null',
  ERROR_CHANGING_PASSWORD: 'Error in Changing Password',
  LOGGED_IN: 'loggedin',
  A_VERIFICATION_EMAIL_SENT_TO: 'A verification email has been sent to the',
  PLEASE_VERIFY_YOUR_EMAIL: 'Please verify your email and try to login again.',
  EMAIL_NOT_EXIST:
    'Your email is not exist in our record.Please contact with portal admin',
  NOT_EXIST: 'Not Exist',

  EMAIL_ALREADY_EXIST:'Email Already Exist'
};
