import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { JobService } from 'src/app/core/services/job.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { combineLatest } from 'rxjs';
import { isEqual } from 'lodash'; // Import the isEqual function from Lodash (install lodash first: npm install lodash)

@Component({
  selector: 'app-user-prefrences',
  templateUrl: './user-prefrences.component.html',
  styleUrls: ['./user-prefrences.component.scss'],
})
export class UserPrefrencesComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @ViewChild('locationSearchInput', { static: true })
  locationSearchInput?: ElementRef;
  form?: FormGroup;

  @Input() userData: any;
  @Input() set candidateJobPreference(data: any) {
    if (data) {
      this.setupJobPreferences(data);
    }
  }

  @Output() dateFilter = new EventEmitter<any>();
  @Output() updatedUserPreferences = new EventEmitter<any>();

  userForm: FormGroup;
  jobTypes: any[] = [];
  categories: any[] = [];
  expereienceLevel: any[] = [];
  types: any[] = [];
  category: any[] = [];
  jobpreferences: any;
  payRanges: any[] = [];
  experience: any[] = [];
  locations: any[] = [];
  filteredOptions: any[] = [];
  myControl = new FormControl();
  userUpdatedPreferences: any = {};
  selectedLocation: any = '';
  savePreferences = false;
  categoryTypes: any = [];
  jobType: any = [];
  experienceType: any = [];
  salaryRange: any;
  userLocation: any = '';

  constructor(
    public fb: FormBuilder,
    public _jobsService: JobService,
    private _commonService: CommonService,
    private snack: SnackBarService,
    private sharedService: SharedService
  ) {
    this.userForm = this.fb.group({
      desired_salary: ['', [Validators.required]],
      location: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this._commonService
      ._filterLocation(this.locationSearchInput?.nativeElement)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.filteredOptions = result.data;
      });
  }

  update(type: any, item: any) {
    if (this.category.filter((cat) => cat.active).length > 10) {
      item.active = false;
      this.snack.showMessage(
        'you cannot select more than 10 categories',
        false
      );
      return;
    }
    type.filter((i: any) => i.active === true).map((item: any) => item.name);
    if (
      JSON.stringify(this.categoryTypes) != JSON.stringify(this.category) ||
      JSON.stringify(this.jobType) != JSON.stringify(this.types) ||
      JSON.stringify(this.experienceType) != JSON.stringify(this.experience)
    ) {
      this.savePreferences = true;
    } else {
      this.savePreferences = false;
    }
  }

  private setupJobPreferences(data: any) {
    this.jobpreferences = data;
    this.setPayandLocation(data);
    this.getAllTypes(data);
  }

  trackByFunc(i: number) {
    return i;
  }

  setPayandLocation(preferences: any) {
    if (preferences?.desired_salary?.length) {
      this.userForm
        .get('desired_salary')
        ?.patchValue(preferences.desired_salary);
      this.salaryRange = JSON.parse(JSON.stringify(preferences.desired_salary));
    }

    const location = preferences?.location?.[0];

    if (location) {
      const formattedLocation = this.formatLocation(location);
      this.myControl.setValue(formattedLocation);
      this.userLocation = JSON.parse(JSON.stringify(formattedLocation));
      this.selectedLocation = location._id;
    }

    this.userForm.get('location')?.setValue(preferences?.location_id);
  }

  formatLocation(location: any): string {
    return `${location.city_name} - ${location.state_name} - ${location.country_code}`;
  }

  /**
   * Get Job Types Filters Data
   */
  getAllTypes(preferences: any) {
    combineLatest([
      this.sharedService.jobCategoryList$.pipe(takeUntil(this.unsubscribe$)),
      this.sharedService.jobTypeList$.pipe(takeUntil(this.unsubscribe$)),
      this.sharedService.payRangeList$.pipe(takeUntil(this.unsubscribe$)),
      this.sharedService.experienceLevelList$.pipe(
        takeUntil(this.unsubscribe$)
      ),
    ]).subscribe(([jobCategory, jobType, payRange, experienceLevel]) => {
      if (jobCategory.length) {
        this.categories = jobCategory;
        this.category = this.mapToActiveObject(
          jobCategory,
          preferences?.categories
        );
      } else {
        this.sharedService.categoriesList();
      }

      if (jobType.length) {
        this.jobTypes = jobType;
        this.types = this.mapToActiveObject(jobType, preferences?.job_type);
        this.jobType = JSON.parse(JSON.stringify(this.types));
      } else {
        this.sharedService.jobTypeList();
      }

      if (payRange.length) {
        this.payRanges = payRange;
      } else {
        this.sharedService.getPayRangeList();
      }

      if (experienceLevel.length) {
        this.expereienceLevel = experienceLevel;
        this.experience = this.mapToActiveObject(
          experienceLevel,
          preferences?.experience_level
        );
        this.experienceType = JSON.parse(JSON.stringify(this.experience));
      } else {
        this.sharedService.getexperienceLevel();
      }

      this.categoryTypes = JSON.parse(JSON.stringify(this.category));
    });
  }

  mapToActiveObject(items: any[], preferences: any[]): any[] {
    return items.map((item, index) => ({
      index: index,
      name: item.label,
      _id: item.id,
      active: preferences?.includes(item.id) || false,
    }));
  }

  /**
   * Update User Preferences
   */
  updatePreferences() {
    // Extract commonly used properties
    const { _id: jobPreferencesId } = this.jobpreferences;
    const { value: userFormValue } = this.userForm;
    const { selectedLocation } = this;

    // Filter active types, categories, and experiences
    const selectedType =
      this.types?.filter((type) => type.active).map((type) => type._id) || [];
    const selectedCategories =
      this.category
        ?.filter((category) => category.active)
        .map((category) => category._id) || [];
    const selectedExperience =
      this.experience
        ?.filter((experience) => experience.active)
        .map((experience) => experience._id) || [];

    // Build the updated preferences object
    const userUpdatedPreferences = {
      _id: jobPreferencesId || null,
      job_type: selectedType,
      categories: selectedCategories,
      experience_level: selectedExperience,
      desired_salary: userFormValue.desired_salary,
      location_id: selectedLocation || null,
    };

    // Emit the updated preferences
    this.updatedUserPreferences.emit(userUpdatedPreferences);

    // Set the savePreferences flag to false
    this.savePreferences = false;
  }

  setLocation(option: any) {
    this.selectedLocation = option._id;
    if (
      JSON.stringify(this.userLocation) != JSON.stringify(this.myControl.value)
    ) {
      this.savePreferences = true;
    } else {
      this.savePreferences = false;
    }
  }

  setPayRange() {
    const desiredSalaryValue = this.userForm.get('desired_salary')?.value;

    if (!isEqual(this.salaryRange, desiredSalaryValue)) {
      this.savePreferences = true;
    } else {
      this.savePreferences = false;
    }
  }

  onLocationChange(event: any) {
    if (
      this.myControl.value === '' ||
      event.key === 'Delete' ||
      event.key === 'Backspace'
    ) {
      this.selectedLocation = '';

      if (!isEqual(this.myControl.value, this.userLocation)) {
        this.savePreferences = true;
      } else {
        this.savePreferences = false;
      }
    }
  }
}
