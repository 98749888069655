<div class="images-main" *ngIf="contentType === 'FEATURED' || contentType === 'SPONSORED'">
    <swiper [pagination]="{ clickable: true }" [config]="config">
        <ng-template swiperSlide *ngFor="let content of filterRecords">
            <div class="member-card h-fit mb-2 border rounded-lg" >
                <div class="img-wrapper-h-w-set rounded-lg relative overflow-hidden member-card_bg" style="padding-top: 43%;">
                  <div class="absolute top-0 left-0 flex justify-center w-full h-full items-center  object-cover">
                    <img *ngIf="content?.contentImage !== ''" [src]="content?.contentImage" class="w-full " />
                  </div>
                </div>
                <div class="w-24 h-24 relative mx-auto avater-wrapper border-2 border-white rounded-full overflow-hidden">
                  <img [src]="content?.profile?.profile_img_path ? content?.profile?.profile_img_path  : avatar" class="w-full h-full object-cover cursor-pointer" (click)="showProfile()" />
                </div>
                  <p class="mx-w-40 mx-auto my-3 truncate cursor-pointer h-5" (click)="showProfile()" style="color: rgb(15, 20, 25);">{{content?.profile?.user_name}}</p>
                <div class="flex items-center mx-auto mb-4 justify-center h-6">
                  <mat-icon style="color: #2887ed;" *ngIf="content.location?.length">room</mat-icon>
                  <p class="text-xs w-full text-gray-400 mx-w-40 truncate" *ngIf="content.location?.length">
                    {{getLocation(content.location[0])}}
                  </p>
                </div>
                <div class="text-center">  
                  <div class="flex justify-between mb-4 mx-2 ">
                    <app-follow-state
                    [showButton]="true"
                    [following]="following"
                    [style]="'cursor-pointer'"
                    [target_user_id]="content?._id"
                    ></app-follow-state>
                  
                      <div class="flex items-center p-2 cursor-pointer profile-btn" (click)="readMessage()">
                        Messages
                      </div>
                      <button class="profile-btn items-center flex" (click)="contactDetails(data.profile)">
                        Contact
                     </button>
                      <button  [matMenuTriggerFor]="exploreMenu"  class="profile-btn flex">
                        <svg
                        class="w-6 h-6 share-icon text-gray-800 dark:text-white ml-2"
                        style="margin-top: -5px; transform: rotate(68deg)"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 20"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
                        />
                      </svg>
                      </button>
                  </div>
                  <div class="flex justify-around">
              
                    <mat-menu #exploreMenu="matMenu">
                      <div class="grid grid-cols-3 gap-2 cursor-pointer">
                        <div class="flex flex-col items-center">
                          <button
                            class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
                            (click)="copy()"
                          >
                            <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
                          </button>
                          <span class="text-xs">Copy</span>
                        </div>
                        <div class="flex flex-col items-center">
                          <button
                            (click)="share()"
                            class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2"
                          >
                            <mat-icon>share</mat-icon>
                          </button>
                          <span class="text-xs">Share</span>
                        </div>
                        <div class="flex flex-col items-center">
                          <button
                            class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
                          >
                            <mat-icon>close</mat-icon>
                          </button>
                          <span class="text-xs">Cancel</span>
                        </div>
                      </div>
                    </mat-menu>
                  </div>
                </div>
            </div>
        </ng-template>

    </swiper>
</div>


<div class="top-categories w-full" *ngIf="contentType === 'HIGHLIGHTS' || contentType === 'SUGGESTED'">
  <p class="category-heading pb-3">Top Categories</p>
  <swiper class="feed-tiles" [pagination]="{ clickable: true }" [config]="categoryConfig">
    <ng-template swiperSlide  *ngFor="let category of filterRecords">
    <div class="category-items cursor-pointer">
      <img
      *ngIf="category?.contentType == 'moment'"
      [ngClass]="{ 'object-right': category.type === 'POST' }"
      class="category-image mb-1 object-cover"
      [src]="category.contentImage"
    />
    <video
      *ngIf="category?.contentType != 'moment'"
      class="category-image mb-1 object-cover"
      [ngClass]="{ 'object-right': category.type === 'POST' }"
      [src]="category.contentImage+ '#t=0.1'"  preload="metadata"
      loop
      autoplay
      [muted]="'muted'"
      playsinline
      webkit-playsinline
    ></video>
      <p class="category-tittle truncate">{{ category.profile.user_name }}</p>
    </div>
  </ng-template>
  </swiper>
  <!-- <div class="flex gap-2 md:gap-3 xl:gap-4 overflow-x-auto">
  </div> -->
</div>

<div *ngIf="contentType === 'RECOMMENDED'">
  <app-recommended-post-story [storyPosts]="filterRecords"></app-recommended-post-story>
</div>