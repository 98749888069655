<form [formGroup]="uploadPost">
  <div class="upload-post-modal bg-normal relative -m-6 flex flex-col justify-between">
    <div class="header w-full fixed md:relative">
      <button *ngIf="isLastStep" class="text-white absolute w-36 left-3 h-full flex items-center"
        (click)="goToPrev(stepper)">
        <mat-icon class="text-white">arrow_back</mat-icon> Back
      </button>
      <h4 class="montserrat-bold py-6 text-center text-white items-center flex">
        Create Campaign
      </h4>
      <div class="absolute right-0 top-0 mt-1">
        <button mat-icon-button (click)="close()">
          <mat-icon class="text-white">cancel</mat-icon>
        </button>
      </div>
    </div>
    <div class="md:py-4 overflow-auto stepper-wrapper" [ngClass]="isLastStep ? 'next-wrapper' : 'stepper-main'">
      <mat-horizontal-stepper labelPosition="bottom" #stepper>
        <mat-step>
          <div class="flex flex-col justify-center h-full">
            <p class="text-sm mb-2 ml-2 h-12 flex items-center montserrat-bold">
              Upload
              <img *ngIf="
                  contentTypes[selected?.value].id === 'clip' ||
                  contentTypes[selected?.value].id === 'trailer' ||
                  contentTypes[selected?.value].id === 'highlight'
                " class="h-12" [src]="contentTypes[selected?.value].icon" />
              {{
              contentTypes[selected?.value].id === "clip" ||
              contentTypes[selected?.value].id === "trailer" ||
              contentTypes[selected?.value].id === "highlight"
              ? contentTypes[selected?.value].text
              : ""
              }}
              <span *ngIf="contentTypes[selected?.value].id === 'moment'" class="montserrat-bold ml-1">
                {{ contentTypes[selected?.value].text1 }}
              </span>
              <span *ngIf="contentTypes[selected?.value].id === 'moment'" class="blue moment-icon montserrat-bold mx-1">
                {{ contentTypes[selected?.value].photos }}
              </span>
              {{
              contentTypes[selected?.value].id === "moment"
              ? contentTypes[selected?.value].text
              : ""
              }}
            </p>
            <label
              class="portfolio-wrapper w-full justify-center items-center montserrat-bold blue cursor-pointer overflow-hidden bg-white"
              [class]="
                !coverPostContent?.content
                  ? 'border border-dashed border-gray-400'
                  : ''
              ">
              <label [htmlFor]="'fileInput'" (click)="selectedIndex = 0">
                <div *ngIf="!coverPostContent || !coverPostContent.content" class="insert-photo-button text-center">
                  <!-- <img class="mx-auto" width="100" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Upload+Icon.png" />
              <p class="montserrat-bold text-center uppercase black-dark text-2xl md:text-3xl">
                upload content
              </p>
              <p class="gray-dark text-sm uppercase text-center mt-1">
                jpg, png, gif, mp4
                <br>
                Max size: 2MB
              </p> -->
                  <img class="mx-auto opacity-30 max-widthHeight object-scale-down"
                    src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/j7m3z.png" />
                </div>
              </label>
              <div *ngIf="coverPostContent && coverPostContent.content" class="w-full h-full relative">
                <div *ngIf="
                    (coverPostContent.content &&
                      coverPostContent.format === 'image') ||
                    coverPostContent.format === 'moment'
                  " class="w-full h-full bg-contain bg-no-repeat bg-center">
                  <img class="h-full img-mx-height mx-auto" [src]="coverPostContent.content" />
                </div>
                <video *ngIf="
                    (coverPostContent.content &&
                      coverPostContent.format === 'video') ||
                    coverPostContent.format === 'clip' ||
                    coverPostContent.format === 'trailer' ||
                    coverPostContent.format === 'highlight'
                  " class="w-full h-full bg-black-dark" [src]="coverPostContent.content + '#t=0.1'" preload="metadata"
                  controls playsinline webkit-playsinline></video>
                <label [htmlFor]="'fileInput'" (click)="selectedIndex = 0">
                  <div *ngIf="coverPostContent.content"
                    class="absolute right-0 top-0 flex items-center justify-center p-1 border-2 bg-black rounded-full">
                    <mat-icon class="white">edit</mat-icon>
                  </div>
                </label>
              </div>
            </label>
            <div>
              <div class="mt-3 grid grid-cols-3 gap-2" formArrayName="images">
                <div *ngFor="let imageControl of getArray(3); let i = index"
                  class="flex relative h-24 border border-dashed border-current rounded bg-white">
                  <div *ngIf="
                      postImages[i] &&
                      postImages[i].content &&
                      (postImages[i]?.format === 'image' ||
                        postImages[i]?.format === 'moment')
                    " (click)="preview(postImages[i], i)" class="w-full h-full bg-contain bg-no-repeat bg-center"
                    [ngStyle]="{
                      'background-image': 'url(' + postImages[i].content + ')'
                    }"></div>
                  <label class="w-full" [htmlFor]="'fileInput'" *ngIf="
                      !postImages[i] &&
                      !postImages[i]?.content &&
                      (postImages[i]?.format != 'image' ||
                        postImages[i]?.format != 'moment')
                    " (click)="selectedIndex = i + 1">
                    <mat-icon class="gray-dark icon-align">add_a_photo</mat-icon>
                  </label>
                  <video *ngIf="
                      (postImages[i] &&
                        postImages[i].content &&
                        postImages[i].format === 'video') ||
                      postImages[i]?.format === 'clip' ||
                      postImages[i]?.format === 'trailer' ||
                      postImages[i]?.format === 'highlight'
                    " disablePictureInPicture class="w-full h-full bg-black-dark"
                    [src]="postImages[i].content + '#t=0.1'" preload="metadata" (click)="preview(postImages[i], i)"
                    playsinline webkit-playsinline></video>
                  <div style="height: 36px" *ngIf="postImages[i] && postImages[i].content"
                    class="absolute right-0 top-0 flex items-center justify-center p-1 border-2 bg-black rounded-full">
                    <label [htmlFor]="'fileInput'" (click)="selectedIndex = i + 1">
                      <mat-icon class="white" style="margin-top: 2px">edit</mat-icon>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div class="mt-4 lg:mt-0" [formGroup]="uploadForm">
            <p class="montserrat-bold">Title of the post</p>
            <input maxlength="257" class="w-full input-form px-4 mt-2" type="text" formControlName="title" />
            <mat-error *ngIf="errorHandling('title', 'maxlength')"
              [class]="errorHandling('title', 'maxlength') ? 'pb-4 pt-4' : ''">
              <p class="s red">Name can not exceed 256 characters!</p>
            </mat-error>
            <p class="montserrat-bold mt-2">Description</p>
            <textarea maxlength="1025" class="w-full border-normal rounded px-4 py-3.5 mt-2 resize-none h-24"
              type="text" formControlName="description"></textarea>
            <mat-error *ngIf="errorHandling('description', 'maxlength')" [class]="
                errorHandling('description', 'maxlength') ? 'pb-4 pt-4' : ''
              ">
              <p class="s red">Description can not exceed 1024 characters!</p>
            </mat-error>
            <p class="montserrat-bold mt-2 md:mt-4">Hashtags</p>
            <mat-form-field class="w-full -mt-2">
              <input type="text" placeholder="" aria-label="Number" matInput class="input-form search-form"
                formControlName="interest" placeholder="Search hashtags" [matAutocomplete]="auto" />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                  (click)="setInterest(option)">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="flex items-center flex-wrap">
              <div *ngFor="let item of usertags" class="flex items-center hashTag mr-2 mb-2">
                #{{ item }}
                <mat-icon (click)="removeInterest(item)">close</mat-icon>
              </div>
            </div>
            <!-- <div class="mt-2">
          <p class="montserrat-bold">Copyright Content</p>
          <input maxlength="513" class="w-full input-form px-4 mt-2" type="text"
            placeholder="Ex. Artist + Song & Album + Publisher" formControlName="desclaimer" />
          <mat-error *ngIf="errorHandling('desclaimer', 'maxlength')"
            [class]="errorHandling('desclaimer', 'maxlength') ? 'pb-4 pt-4' : ''">
            <p class="s red">Length can not exceed 512 characters!</p>
          </mat-error>
          <div class="flex items-center flex-wrap">
            <div *ngFor="let item of disclaimers" class="flex items-center hashTag mr-2 mt-2">
              {{ item }}
              <mat-icon (click)="removeDisclaimer(item)">close</mat-icon>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <button mat-button class="button outlined black" (click)="setDisclaimer()">+ Add Disclaimer</button>
        </div> -->
            <div class="flex">
              <div class="ml-3.5">
                <div>
                  <p class="montserrat-bold mt-6">Send to</p>
                  <mat-radio-group aria-label="Select an option" class="flex flex-col mt-4">
                    <mat-radio-button value="1" checked class="text-center" (click)="sendTo('All')">
                      <p class="ml-2">All subscribers</p>
                    </mat-radio-button>
                    <mat-radio-button value="2" class="text-center mt-4" (click)="viewOption = 2">
                      <p class="ml-2">Paid suscribers only</p>
                    </mat-radio-button>
                  </mat-radio-group>
                  <div class="scroll">
                    <div *ngIf="viewOption === 2 && myPlans.length"
                      class="flex flex-col ml-8 mt-4 overflow-auto w-full h-20">
                      <div *ngFor="let plan of myPlans" [class]="
                          checkFeatures(plan.features) ? 'opacity-50' : ''
                        ">
                        <mat-checkbox [disabled]="checkFeatures(plan.features)" class="pt-1" [color]="'primary'"
                          (change)="change($event)" [value]="plan._id">
                          <p>
                            <b>{{ plan.title | titlecase }} --
                              {{
                              plan.features.length > 1
                              ? "Combo"
                              : plan.features.length == 1 &&
                              plan.features[0] == "Photo Content"
                              ? "Moment"
                              : plan.features.length == 1 &&
                              plan.features[0] == "Video Content"
                              ? "Clip"
                              : ""
                              }}</b>
                          </p>
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="fixed md:relative left-6 post-wrapper right-6 bottom-20 md:bottom-0" *ngIf="!isLastStep">
      <mat-tab-group (click)="resetImages()" class="post-tab" [selectedIndex]="selected.value"
        (selectedIndexChange)="selected.setValue($event)">
        <mat-tab (click)="resetImages()" *ngFor="let type of contentTypes" [label]="type.label">
        </mat-tab>
      </mat-tab-group>
    </div>
    <div
      class="bg-white fixed left-0 right-0 bottom-0 md:relative w-full flex justify-between items-center px-4 h-20 border-2">
      <button mat-flat-button class="button outlined black w-36" (click)="close()">
        Cancel
      </button>
      <button *ngIf="!isLastStep" mat-button class="button outlined black w-36"
        [class]="coverPostContent && coverPostContent.content ? '' : 'disabled'"
        [disabled]="!coverPostContent || !coverPostContent.content" (click)="goToNext(stepper)">
        Next
      </button>
      <button *ngIf="isLastStep" mat-button class="button outlined black w-36" (click)="postContent()"
        [disabled]="isProcessing || !uploadForm.valid || !postImagesPath.length"
        [class]="!uploadForm.valid || !postImagesPath.length ? 'disabled' : ''">
        Send now
      </button>
    </div>
  </div>
</form>
<input id="fileInput" [accept]="
    selected.value === 0
      ? 'image/*'
      : selected?.value > 0 && selected?.value < 4
      ? 'video/*'
      : ''
  " #file multiple type="file" class="uploadFile img w-0 h-0 overflow-hidden" value="Upload Media"
  (change)="uploadFile(file.files, selectedIndex)" style="display: none" />