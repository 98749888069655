import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransferFundsModalComponent } from 'src/app/modules/dashboard/pages/dashboard-payment/components/transfer-funds-modal/transfer-funds-modal.component';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { statusConstant } from 'src/app/const/appConst';
import { ProfileService } from 'src/app/core/services/profile.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CommonService } from 'src/app/core/services/common.service';
import { routesConst } from 'src/app/core/const/routers';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-dashboard-payment-balance',
  templateUrl: './dashboard-payment-balance.component.html',
  styleUrls: ['./dashboard-payment-balance.component.scss']
})
export class DashboardPaymentBalanceComponent implements OnInit {
  private unsubscribe$ = new Subject();
  transferOption: any;
  transferSubOption: any;
  userBalance = 0;
  currencyArr = [
    { key: 'normal', value: '100' },
    { key: 'large', value: '500' },
    { key: 'xlarge', value: '1000' }
  ];
  transferOptions = [
    { key: 'manual', value: 'Transfer mannually' },
    { key: 'auto', value: 'Set auto transfer' }
  ];
  transferSubOptions = [
    { key: 'daily', value: 'Every day' },
    { key: 'monthly', value: 'Start of the month' },
    { key: 'conditional', value: 'Conditional' }
  ];
  conditionalTransferArr = [{ key: 'large', value: 'Funds greather than' }];

  constructor(
    private router: Router,
    private commonService: CommonService,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private stripePaymentService: paymentService,
    private snack: SnackBarService,
    private spinner: NgxSpinnerService,
    private userPlan: userPlans
  ) {
    this.transferOption = 'manual';
    this.transferSubOption = 'daily';
  }

  ngOnInit(): void {
    this.getAccountBalance();
  }

  setTransferOption(e: any, type: string): void {
    if (type !== 'sub') {
      this.transferOption = e.value;
    } else {
      this.transferSubOption = e.value;
    }
  }

  getAccountBalance() {
    this.stripePaymentService
      .getBalance()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.userBalance = res.balance;
      });
  }

  getPayment() {
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.getPaymentLink();
        }
      });
  }

  getPaymentLink() {
    this.stripePaymentService
      .getLoginLink()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res.status == 'Success') {
          this.commonService.getWindow()?.open(res.link.url, '_blank');
        } else {
          this.router.navigate(['/' + routesConst.planPricing]);
          this.snack.showMessage(`${res?.link?.message}`, true);
        }
      });
  }

  showTransferFundsModal() {
    this.dialog.open(TransferFundsModalComponent, {
      maxWidth: '100vw',
      width: '100vw',
      height: '100vh',
      data: { balance: this.userBalance ? this.userBalance : 0 }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
