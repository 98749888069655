import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';

@Component({
  selector: 'app-subscribe-modal',
  templateUrl: './subscribe-modal.component.html',
  styleUrls: ['./subscribe-modal.component.scss'],
})
export class SubscribeModalComponent implements OnInit {
  mode: 'pro' | 'exclusive' = 'exclusive';
  sending: boolean;
  showResult: boolean;

  constructor(
    public dialogRef: MatDialogRef<SubscribeModalComponent>,
    public router: Router
  ) {
    this.sending = false;
    this.showResult = false;
  }

  ngOnInit(): void {}

  setMode(mode: string) {
    this.mode = mode === 'pro' ? 'pro' : 'exclusive';
  }

  subscribe() {
    this.sending = true;
    setTimeout(() => {
      this.sending = false;
      this.showResult = true;
    }, 1000);
  }

  manageSubscribe() {
    this.showResult = false;
  }

  showPostPage() {
    this.dialogRef.close();
    this.router.navigate([routesConst.community]);
  }
}
