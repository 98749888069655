<div class="section-wrapper bg-white p-4 lg:p-10">
  <!-- <app-plan-upgrade></app-plan-upgrade> -->
  <p class="black-light montserrat-bold">Social Profiles</p>
  <p class="mt-2 black-light s">Add social links to your profile</p>
  <div class="mt-2">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <form class="needs-validation" [formGroup]="social" (ngSubmit)="saveChanges()">
    <div class="form-group">
      <p class="black-light montserrat-bold mt-6">Facebook</p>
      <input maxlength="513" class="form-control border-normal rounded w-full mt-2 py-3.5 px-4" formControlName="facebook"
        [class.is-invalid]="(social.get('facebook')?.touched || social.get('facebook')?.dirty) && social.get('facebook')?.invalid"
        type="text" placeholder="https://facebook.com/username">
      <span class="invalid-feedback"
        *ngIf="((social.get('facebook')?.touched || social.get('facebook')?.dirty) && social.get('facebook')?.invalid) && !errorHandling('facebook', 'maxlength')">
        field must contain https://www.facebook.com/
      </span>
      <mat-error *ngIf="errorHandling('facebook', 'maxlength')" class="text-danger pb-2">
        Length cannot exceed more than 512 characters!
      </mat-error>
    </div>
    <div class="form-group">
      <p class="black-light montserrat-bold mt-6">Instagram</p>
      <input maxlength="513" class="form-control border-normal rounded w-full mt-2 py-3.5 px-4" formControlName="instagram"
        [class.is-invalid]="(social.get('instagram')?.touched || social.get('instagram')?.dirty) && social.get('instagram')?.invalid"
        type="text" placeholder="https://instagram.com/username">
        <mat-error *ngIf="errorHandling('instagram', 'maxlength')" class="text-danger pb-2">
          Length cannot exceed more than 512 characters!
        </mat-error>
      <span class="invalid-feedback"
        *ngIf="((social.get('instagram')?.touched || social.get('instagram')?.dirty) && social.get('instagram')?.invalid) && !errorHandling('instagram', 'maxlength')">
        Url must contain https://www.instagram.com/
      </span>
    </div>
    <div class="form-group">
      <p class="black-light montserrat-bold mt-6">TikTok</p>
      <input maxlength="513" class="form-control border-normal rounded w-full mt-2 py-3.5 px-4" formControlName="tiktok"
        [class.is-invalid]="(social.get('tiktok')?.touched || social.get('tiktok')?.dirty) && social.get('tiktok')?.invalid"
        type="text" placeholder="https://tiktok.com/username">
        <mat-error *ngIf="errorHandling('tiktok', 'maxlength')" class="text-danger pb-2">
          Length cannot exceed more than 512 characters!
        </mat-error>
      <span class="invalid-feedback"
        *ngIf="((social.get('tiktok')?.touched || social.get('tiktok')?.dirty) && social.get('tiktok')?.invalid) && !errorHandling('tiktok', 'maxlength')">
        Url must contain https://www.tiktok.com/
      </span>
    </div>
    <div class="form-group">
      <p class="black-light montserrat-bold mt-6">Twitter</p>
      <input maxlength="513" class="form-control border-normal rounded w-full mt-2 py-3.5 px-4" formControlName="twitter"
        [class.is-invalid]="(social.get('twitter')?.touched || social.get('twitter')?.dirty) && social.get('twitter')?.invalid"
        type="text" placeholder="https://twitter.com/username">
        <mat-error *ngIf="errorHandling('twitter', 'maxlength')" class="text-danger pb-2">
          Length cannot exceed more than 512 characters!
        </mat-error>
      <span class="invalid-feedback"
        *ngIf="((social.get('twitter')?.touched || social.get('twitter')?.dirty) && social.get('twitter')?.invalid) && !errorHandling('twitter', 'maxlength')">
        Url must contain https://www.twitter.com/
      </span>
    </div>
    <div class="form-group">
      <p class="black-light montserrat-bold mt-6">Linkedin</p>
      <input maxlength="513" class="form-control border-normal rounded w-full mt-2 py-3.5 px-4" formControlName="linkedin"
        [class.is-invalid]="(social.get('linkedin')?.touched || social.get('linkedin')?.dirty) && social.get('linkedin')?.invalid"
        type="text" placeholder="https://linkedin.com/username">
        <mat-error *ngIf="errorHandling('linkedin', 'maxlength')" class="text-danger pb-2">
          Length cannot exceed more than 512 characters!
        </mat-error>
      <span class="invalid-feedback"
        *ngIf="((social.get('linkedin')?.touched || social.get('linkedin')?.dirty) && social.get('linkedin')?.invalid) && !errorHandling('linkedin', 'maxlength')">
        Url must contain https://www.linkedin.com/
      </span>
    </div>
    <div class="mt-14">
      <div class="form-group">
        <button
          [disabled]="!saveSocial || social.get('facebook')?.invalid || social.get('instagram')?.invalid || social.get('tiktok')?.invalid || social.get('twitter')?.invalid || social.get('linkedin')?.invalid"
          mat-button class="button outlined black">Save</button>
      </div>
    </div>
  </form>