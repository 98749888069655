import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-members-panel',
  templateUrl: './members-panel.component.html',
  styleUrls: ['./members-panel.component.scss'],
})
export class MembersPanelComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() layoutMode: string = 'grid_view';
  @Input() searchTypeVal: string = '';
  @Input() followings: any[] = [];
  @Output() followMember: EventEmitter<any> = new EventEmitter();
  @Output() unFollowMember: EventEmitter<any> = new EventEmitter();
  members: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.members = this.data;
  }

  ngOnChanges(changes: any): void {
    if (changes?.data?.currentValue) this.members = changes.data.currentValue;
  }

  onFollow(e: any) {
    this.followMember.emit(e);
  }

  onUnFollow(e: any) {
    this.unFollowMember.emit(e);
  }

  trackByFunc(i: number) {
    return i;
  }
}
