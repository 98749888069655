<!-- <div class="section-main-padding"></div> -->

<div class="background-section">
  <div class="sections-main">
    <div class="section-2-main">
      <div class="flex flex-col w-full mx-auto items-center">
        <div class="block md:hidden">
          <app-feature-menu></app-feature-menu>
        </div>
        <div>
          <p class="join-heading text-center">
              Explore our memberships<br>
              tutorial video collection
          </p>
          <p class="divider w-full"></p>
        </div>
        <div class="search-wrapper-home my-4">
          <div class="relative search-bar-wrapper w-full md:w-[500px]">
            <div class="search-input-wrapper w-full">
              <input placeholder="Search" class="search-input text-sm" />
              <mat-icon class="absolute search-icon">search</mat-icon>
            </div>
          </div>
        </div>
        <p class="section-title-divider fastgrowing-divider"></p>
      </div>

      <!-- for mobile screen & Individuals -->
      <div *ngIf="innerWidth < 768" class="images-main">
        <swiper [pagination]="{ clickable: true }" [config]="config">
          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network">
                <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/PZKlC.png" alt="" />
                <p>Micro Style Learning</p>
                <small>Learn From Anywhere</small>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services">
                <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/SRX19.png" alt="" />
                <p>Explore Our Collection</p>
                <small>Step By Step Guides</small>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments">
                <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/qUZJP.png" alt="" />
                <p>Best -In -Class Videos</p>
                <small>Member Level Tutorials</small>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals">
                <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/sbTtt.png" alt="" />
                <p>Community Learning</p>
                <small>Your Success Matters</small>
            </div>
          </ng-template>
        </swiper>
      </div>
      <!---------->

      <!-- for laptop screen & Individuals -->
      <div *ngIf="innerWidth >= 768" class="grid grid-cols-4 gap-5">
        <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network">
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/PZKlC.png" alt="" />
          <p>Micro Style Learning</p>
          <small>Learn From Anywhere</small>
        </div>
        <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services">
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/SRX19.png" alt="" />
          <p>Explore Our Collection</p>
          <small>Step By Step Guides</small>
        </div>
        <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments">
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/qUZJP.png" alt="" />
          <p>Best -In -Class Videos</p>
          <small>Member Level Tutorials</small>
        </div>
        <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals">
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/sbTtt.png" alt="" />
          <p>Community Learning</p>
          <small>Your Success Matters</small>
        </div>
      </div>
      <!-------->
    </div>
  </div>
</div>

<div>
  <div class="mt-6">
    <p class="montserrat-bold black-dark text-center text-xl comparePlans-text">Membership Tutorials</p>
    <p class="font-style black-dark text-center my-4">Explore our videos</p>
  </div>
</div>