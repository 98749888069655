import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  OperationHours,
  shareTypeConst,
  userTypeConstant,
} from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { CustomerAllReviewsModalComponent } from 'src/app/modules/dashboard/pages/customer-all-reviews-modal/customer-all-reviews-modal.component';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ContactDetailsModalComponent } from '../../modals/contact-details-modal/contact-details-modal.component';

interface UserInfo {
  email: string;
  name: string;
  profile_image: string;
  user_name: string;
  user_type: string;
  banner_image?: string;
  _id: string;
}

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() user: any;
  @Input() currentLoggedInUserId: any;
  @Input() postN: number;
  @Input() followerN: number;
  @Input() followingN: number;
  @Input() planN?: number;
  @Input() serviceN?: number;
  @Input() subscribersN?: number;
  @Input() averageRating?: any;
  @Input() totalRatings?: any;
  @Input() set isFollow(value: boolean) {
    this.sharedService.getFollowersAndFollowings();
  }
  @Output() isFollowed: EventEmitter<any> = new EventEmitter();

  routesConst = routesConst;
  userTypeConstant = userTypeConstant;
  following: any[] = [];
  selectedProfile!: UserInfo;
  profileId:any;
  allExperienceLevel: any;
  userInfo: any;
  operationHours: any[] = OperationHours;
  userSkills: any = ([] = []);
  educations: any[] = [];
  workHistory: any[] = [];
  projects: any[] = [];
  expLevel: any;
  userDetails: any;
  constructor(
    private clipboard: Clipboard,
    public dialog: MatDialog,
    private snackService: SnackBarService,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
    private commonService: CommonService,
    private profileService: ProfileService
  ) {
    this.postN = 0;
    this.followerN = 0;
    this.followingN = 0;
    this.planN = 0;
    this.serviceN = 0;
    this.subscribersN = 0;
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.getFollowersAndFollowings();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user']?.currentValue) {
      this.getUserInfo(changes['user']?.currentValue?.user_name);
      this.getProfileInformation(changes['user']?.currentValue?.user_name)
    }
  }

  getProfileInformation(user_name: any) {
    this.profileService
      .getCommunityPageUserProfile(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        const { data } = user;
        this.selectedProfile = data[0].profile;
        const { email, name, profile_image, user_name, user_type, _id } =
          this.selectedProfile;
          this.profileId= _id
        const userInfo = {
          email,
          name,
          profile_img_path: profile_image,
          user_name,
          user_type,
          _id,
        };
        
      });
  }
  openReviewModal() {
    const data = {
      profile_id: this.profileId,
      profile: {
        profile_img_path: this.selectedProfile.profile_image,
        name: this.selectedProfile.name,
      },
    };
    this.dialog.open(CustomerAllReviewsModalComponent, {
      maxWidth: innerWidth && innerWidth > 768 ? '800px' : '100vw',
      width: '100%',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: { selectedService: data, reviewsType: 'Buyer' },
      disableClose: true,
    });
  }

  getUserInfo(user_name: any) {
    this.profileService
      .getUserDetails(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        const { data } = user;
        this.userDetails = data[0];
        this.userInfo = this.userDetails;
        this.sortData();
      });
  }

  sortData() {
    this.userInfo?.operationHours.map((item: any) => {
      const idx = this.operationHours.findIndex(
        (oh: any) => oh.day_name === item.day_name
      );
      delete item.created_at;
      delete item.profile_id;
      delete item.__v;
      this.operationHours[idx] = {
        ...this.operationHours[idx],
        ...item,
      };
    });
    this.expLevel = this.userInfo?.profile?.achievements?.find(
      (x: any) => x.type === 'ExpLevel'
    );
    this.userSkills = this.userInfo?.profile?.achievements?.filter(
      (x: any) => x.type === 'Skill'
    );
    this.educations = this.userInfo?.profile?.achievements?.filter(
      (x: any) => x.type === 'Education'
    );
    this.workHistory = this.userInfo?.profile?.achievements?.filter(
      (x: any) => x.type === 'WorkHistory'
    );
    this.projects = this.userInfo?.profile?.achievements?.filter(
      (x: any) => x.type === 'ClientProject'
    );
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network) => {
        if (network?.followings || network?.followers) {
          this.following = network.followings;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  share() {
    if (this.authService.loggedIn()) {
      this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px': '100%',
        maxWidth:window.innerWidth > 600 ? '80vw': '100vw',
        data: {
          link:
            this.commonService.location().origin +
            '/profile/' +
            this.user.user_name,
          shareType: shareTypeConst.profile,
        },
      });
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
    }
  }

  copyLink() {
    this.clipboard.copy(
      this.commonService.location().origin +
        this.commonService.location().pathname
    );
    this.snackService.showMessage(`Profile Link Copied`, false);
  }

  increaseFollower(count: any) {
    this.isFollowed.emit(count);
  }

  formatDate(date: Date): any {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'MMMM yyyy');
  }

  contactDetails() {
    this.dialog.open(ContactDetailsModalComponent, {
      width: '400px',
      panelClass: 'custom-dialog-container',
      data: { user: this.userDetails, operationHours: this.operationHours },
    });
  }

  readMessage() {
    const { email, name, profile_image, user_name, user_type, _id } = this.user;
    if (_id != this.currentLoggedInUserId) {
      const userInfo = {
        email,
        name,
        profile_img_path: profile_image,
        user_name,
        user_type,
        _id,
      };
      localStorage.setItem('userId', JSON.stringify(userInfo));
    }
    this.router.navigate(
      ['/' + routesConst.dashboard + '/' + routesConst.messages],
      {
        queryParams: {
          tab: 2,
        },
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
