import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { RefundStatusConst } from 'src/app/const/appConst';
import { TalentService } from 'src/app/core/services/talent.service';
import { SubscriptionManagmentDirective } from 'src/app/utilities/subscription.management';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';
import { ServiceDetailModalComponent } from '../service-detail-modal/service-detail-modal.component';
@Component({
  selector: 'app-dashboard-sold-services',
  templateUrl: './dashboard-sold-services.component.html',
  styleUrls: ['./dashboard-sold-services.component.scss']
})
export class DashboardSoldServicesComponent
  extends SubscriptionManagmentDirective
  implements OnInit
{
  RefundStatusConst = RefundStatusConst;
  displayedColumns: string[] = ['last4', 'isDefault', 'expiry'];

  talentFilters = { offset: 0, limit: 10 };
  dataSource: any[] = [];

  constructor(public dialog: MatDialog, private talentService: TalentService) {
    super();
  }

  ngOnInit(): void {
    this.getTalentService();
  }

  getTalentService() {
    this.talentService
      .getSoldServices()
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((services: any) => {
        const { data: service } = services;
        if (service) {
          this.dataSource = service;
        }
      });
  }

  serviceDetails(service: any) {
    const dialogRef = this.dialog.open(ServiceDetailModalComponent, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      data: {
        service,
        service_id: service?.service?._id,
        service_type: 'sold-service',
        isRefund: service?.refund?.length > 0
      },
      hasBackdrop: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTalentService();
      }
    });
  }

  feedBack(event: any, row: any) {
    const service = {
      serviceDetails: { _id: row?.service?._id },
      seller_id: row.seller_id,
      buyer_id: row.buyer_id,
      _id: row._id,
      type: 'sold_service'
    };
    const dialogRef = this.dialog.open(FeedbackModalComponent, {
      width: '440px',
      data: service
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
    event.stopPropagation();
  }
}
