import { CdkAccordionItem } from '@angular/cdk/accordion';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { loginSignUpConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SubscriptionManagmentDirective } from 'src/app/utilities/subscription.management';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';
import { ContactUsComponent } from '../contact-us/contact-us.component';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-home-part-footer',
  templateUrl: './home-part-footer.component.html',
  styleUrls: ['./home-part-footer.component.scss']
})
export class HomePartFooterComponent  extends SubscriptionManagmentDirective
implements OnInit, AfterViewInit
{
@Output() closeSideMenu: EventEmitter<string> = new EventEmitter<string>();

@ViewChild('accordionItemCompany') accordionItemCompany: any;
@ViewChild('accordionItemIntegrations') accordionItemIntegrations: any;
@ViewChild('accordionItemPlatform') accordionItemPlatform: any;

isExpanded!: string;
@Input() data: any;
@Input() faqData: any;
loginSignUpConstant = loginSignUpConstant;
routesConst = routesConst;
subscribeForm!: FormGroup;
config: SwiperOptions = {
  breakpoints: {
    768: {
      slidesPerView: 1,
      slidesPerGroup: 1
    }
  }
};
innerWidth = window.innerWidth;
services = [
  { label: 'Pricing', url: this.routesConst.pricing },
  { label: 'FAQ', url: this.routesConst.faq },
  { label: 'Tutorials', url: this.routesConst.tutorials }

  // { label: 'Features', url: this.routesConst.features },
  // { label: 'Instant Pay', url: this.routesConst.instantPay },
  // { label: 'Referrals', url: this.routesConst.earnReferrals },
  // { label: 'Tutorials', url: '' },
  // { label: 'Jobs', url: routesConst.jobs },
];
experts = [
  { label: 'About Us', url: this.routesConst.aboutus },
  { label: 'Referrals', url: this.routesConst.earnReferrals },
  {
    label: 'Contact',
    url: '',
    ismodal: true,
    modalToOPen: ContactUsComponent
  }

  // { label: 'Blog', url: 'https://medium.com/@jobpros' },
  // { label: 'Press', url: '' },
  // { label: 'FAQs', url: this.routesConst.faq },
  // {
  //   label: 'Contact',
  //   url: '',
  //   ismodal: true,
  //   modalToOPen: ContactUsComponent,
  // },
  // { label: 'Experts', url: routesConst.talents },
];
apps = [
  { label: 'Stripe', url: 'https://stripe.com/' },
  { label: 'Microsoft', url: 'https://azure.microsoft.com/en-us/' },
  { label: 'Auth0', url: 'https://auth0.com/' }
  // { label: 'Amazon', url: 'https://aws.amazon.com/s3/' },
  // { label: 'Nodeasset', url: 'https://www.nodeasset.com/' },
  // { label: 'Social', url: routesConst.community },
];

Creators = [
  { label: 'Disclaimer', type: 'people', url: routesConst.disclaimer },
  { label: 'Do Not Sell', type: 'people', url: routesConst['do-not-sell'] }
  // { label: 'Terms', type: 'image', url: routesConst.terms },
  // { label: 'Privacy', type: 'video', url: routesConst.privacy },
  // { label: 'Cookies', type: 'people', url: routesConst.cookies },
  // { label: 'Preferences', type: 'people', url: routesConst.preferences }
  // { label: 'Features', url: routesConst.features },
];

links = [
  {
    url: 'https://www.facebook.com/jobpros.io',
    class: 'facebook'
  },
  {
    url: 'https://twitter.com/jobproshq',
    class: 'twitter'
  },
  {
    url: 'https://www.linkedin.com/company/jobpros-inc/',
    class: 'linkedin'
  },
  {
    url: 'https://www.instagram.com/jobpros.io/',
    class: 'instagram'
  },
  // {
  //   url: 'https://medium.com/@jobpros',
  //   class: 'medium',
  // },
  {
    url: 'https://www.youtube.com/channel/UCGOx8v2Q2q3vgrsQThtWboQ/featured',
    class: 'youtube'
  }
];

@HostListener('window:resize', ['$event'])
onResize() {
  this.innerWidth = window.innerWidth;
}
currentRoute: any;
contactObject={
  label: 'Contact',
  url: '',
  ismodal: true,
  modalToOPen: ContactUsComponent
}
constructor(
  private authService: AuthService,
  private dialog: MatDialog,
  private _router: Router,
  public fb: FormBuilder,
  private profileService: ProfileService,
  private snack: SnackBarService,
  private route: ActivatedRoute,
  private sharedService: SharedService
) {
  super();
  this.subscribeForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });
}
ngAfterViewInit(): void {
  this.listenForFeatureMenuToogle();
}

ngOnInit(): void {
  this.currentRoute = this.route.snapshot.url.join('/');
}

listenForFeatureMenuToogle() {
  this.sharedService.featureMenuToggle
    .pipe(takeUntil(this.componetDestroyed))
    .subscribe((id: string) => {
      this.closeAllAccordions();

      const accordionItems: { [key: string]: CdkAccordionItem | undefined } = {
        '#Company': this.accordionItemCompany,
        '#Integrations': this.accordionItemIntegrations,
        '#Platform': this.accordionItemPlatform
      };
      const accordionItem = accordionItems[id];
      if (accordionItem) {
        accordionItem.open();
      }
    });
}

private closeAllAccordions() {
  if (this.accordionItemCompany) {
    this.accordionItemCompany.close();
  }
  if (this.accordionItemIntegrations) {
    this.accordionItemIntegrations.close();
  }
  if (this.accordionItemPlatform) {
    this.accordionItemPlatform.close();
  }
}

navigateTo(item: any, param?: string) {
  if (item?.url.includes('https')) {
    window.open(item?.url, '_blank');
  } else if (item?.ismodal) {
    this.dialog.open(item.modalToOPen, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100%' : 'fit-content',
      maxHeight: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      hasBackdrop: false
    });
  } else {
    this._router.navigate([item?.url], {
      queryParams: {
        tab: param
      },
      queryParamsHandling: 'merge'
    });
  }
}

public errorHandling = (control: string, error: string) => {
  return this.subscribeForm.controls[control].hasError(error);
};

subscribe() {
  if (!this.errorHandling('email', 'email') && this.subscribeForm.controls['email'].value) {
    const email = this.subscribeForm.controls['email'].value;
    this.profileService
      .subscribeEmail(email)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((res: any) => {
        this.snack.showMessage(res.message, false);
      });
  } else {
    this.snack.showMessage('Please enter a valid Email address.', true);
  }
}

openSignUp(pageToOpen: string) {
  if (!this.authService.loggedIn()) {
    this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px' : '100vw',
      height: window.innerWidth > 600 ? 'h-fit' : '100%',
      maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
      maxHeight: window.innerWidth > 600 ? '600' : '100vh',
      data: { pageToOpen }
    });
  } else {
    this._router.navigate(['/' + this.routesConst.dashboard + '/' + this.routesConst.account]);
  }
}

uploadPostModal(pageToOpen: string) {
  if (!this.authService.loggedIn()) {
    this.dialog.open(LoginSignupDialogComponent, {
      width: '400px',
      height: 'h-fit',
      maxWidth: '96%',
      maxHeight: '600',
      data: { pageToOpen }
    });
  } else {
    this.dialog.open(UploadPostModalComponent, {
      maxWidth: '100vw',
      width: '536px',
      disableClose: true,
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: {}
    });
  }
}

offSideMenu() {
  this.closeSideMenu.emit();
}
}
