<div class="payment-checkout-modal -m-6">
  <div class="w-full relative flex items-center justify-center payment-checkout-modal_header bg-white-dark">
    <h4 class="montserrat-bold text-white">Checkout</h4>
    <mat-icon class="cursor-pointer hover:bg-red-200 rounded-full text-white z-30 absolute right-2 top-2" mat-dialog-close>
      cancel
    </mat-icon>
  </div>
  <div class="w-full">
    <mat-divider class="w-full"></mat-divider>
    <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
      [fullScreen]="true" class="job-list-spinner">
    </ngx-spinner>
  </div>
  <div class="payment-checkout-modal_content flex flex-col lg:flex-row">
    <div class="w-full mx-auto">
      <div class="flex items-center h-20 justify-center w-full rounded">
        <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_Black.png" class="px-4 h-full" />
      </div>
      <div class="border-normal rounded p-4 mt-2" *ngIf="productPrice && productPrice.length">
        <h3 class="montserrat-bold">{{ data?.name }}</h3>
        <mat-radio-group aria-label="Select an option" class="grid gap-4 mt-4">
          <mat-radio-button (change)="onChange($event)" [checked]="i == 0" [value]="item"
            *ngFor="let item of productPrice; index as i">
            <div class="lg:flex flex-col items-start">
              <h4 class="montserrat-bold break-space-word">
                {{ data.title }}
                {{
                item.recurring.interval == intervalConstant.year
                ? "Annual plan"
                : "Monthly plan"
                }}
              </h4>
              <div class="flex flex-col lg:items-end text-left lg:text-right lg:ml-auto">
                <h4>
                  <span class="montserrat-bold">${{ item?.unit_amount / 100 }}/</span>mo
                </h4>
                <p class="s montserrat-medium">
                  ${{ item?.unit_amount / 100 }}
                  {{
                  item.recurring.interval == intervalConstant.year
                  ? "billed once a year"
                  : "billed every month"
                  }}
                </p>
              </div>
            </div>
          </mat-radio-button>
        </mat-radio-group>
        <p class="mt-4">Description</p>
        <div class="flex items-center lg:py-1 cursor-pointer mt-2">
          <img class="mr-2" src="../../../../assets/images/ok_fill__popup.png" />{{ data.description }}
        </div>
        <div *ngIf="selectedPlanPrice" class="mt-5 flex items-center justify-center">
          <button class="pay-subcription-button montserrat-medium"
            (click)="pay('Subscription')">
            {{ !data.viewMode ? "Pay" : "Unsubscribe" }}
          </button>
        </div>
      </div>
      <div class="flex justify-center items-center mt-5">
        <mat-icon class="green mr-2" style="font-size: 14px; width: 10px; height: 14px">lock</mat-icon>
        <p class="s gray-dark">All your payments are encrypted</p>
      </div>
    </div>
  </div>
</div>