<!-- <app-header class="mobile-mode"></app-header> -->
<div class="sign-up-page" [ngStyle]="{'min-height.px': isDialog ? 0 : 'auto','padding-top':isDialog ? '16px':'32px'}">
  <!-- <a routerLink="{{ routesConst.launchPath }}"
    class="h-6 absolute right-0 mr-5 lg:mr-28 mt-2.5 lg:mt-10 rounded-full z-30">
    <mat-icon>cancel</mat-icon>
  </a> -->
  <div class="" [ngClass]="isDialog ? 'pt-0':'sign-up-content'">
    <div class="flex flex-col w-full items-center text-center">
      <!-- <a routerLink="{{ routesConst.launchPath }}">
        <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_Black.png"
          class="w-32 h-10 object-cover" />
      </a> -->
      <h3 class="montserrat-bold forgot-password" [ngClass]="isDialog ? 'mt-3.5':'lg:mt-10'">Forgot password</h3>
      <p class="text-sm" [ngClass]="isDialog ? 'mb-0 mt-2':' mb-10 mt-4'">
        Enter email reset password
      </p>
      <form class="flex flex-col w-full px-4 mb-6" [formGroup]="resetForm" novalidate>
        <mat-form-field class="mt-6">
          <input matInput class="border-normal rounded" placeholder="Your email" formControlName="email" />
          <mat-error *ngIf="errorHandling('email', 'required')"
            [class]="errorHandling('email', 'required') ? 'pb-4' : ''">
            <p class="text-red-600">You must provide a email.</p>
          </mat-error>
          <mat-error *ngIf="errorHandling('email', 'pattern')" [class]="errorHandling('email', 'pattern') ? 'pb-4' : ''">
            <p class="text-red-600">Please enter a valid email address.</p>
          </mat-error>
        </mat-form-field>
        <a  (click)="isDialog ? forSignUpRedirection('login'): openSignUp('login')" class="text-left blue s montserrat-bold cursor-pointer mt-3">Login to JOBPros</a>
        <div class="mt-6">
          <button mat-button class="button outlined black w-full cursor-pointer" (click)="changePassword()"
            [disabled]="!resetForm.valid || disableResetButton">
            Reset password
          </button>
        </div>
      </form>
    </div>
  </div>
</div>