import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ProfileService } from '../services/profile.service';
import { planTypeConstant } from 'src/app/const/appConst';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { routesConst } from '../const/routers';

@Injectable({
   providedIn: 'root'
})
export class UserPlanGuard implements CanActivate {
   userCurrentPlan: any;

   constructor(private dialog: MatDialog, private router: Router, private profileService: ProfileService) { }

   canActivate(): boolean | Observable<boolean> | Promise<boolean> {
      return new Observable<boolean>(obs => {
         this.profileService.getpurchasedPlan().subscribe((res: any) => {
            this.userCurrentPlan = res.currentPlan;

            if (this.userCurrentPlan?.plan_type == planTypeConstant.elite || this.userCurrentPlan?.plan_type == planTypeConstant.nil || this.userCurrentPlan?.plan_type == planTypeConstant.enterprise) {
               obs.next(true);
            }
            else {
               const dialogRef = this.dialog.open(ConfirmModalComponent,
                  {
                     width: '500px',
                     data: { message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`, header:'Send Subscription' },
                  }
               );
               dialogRef.afterClosed().subscribe((result) => {
                  if (result) {
                     this.router.navigate(['/' + routesConst.pricing]);
                  }
               });
               obs.next(false);
            }
         });
      });
   }

}
