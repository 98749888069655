import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-email-verification-popup',
  templateUrl: './email-verification-popup.component.html',
  styleUrls: ['./email-verification-popup.component.scss'],
})
export class EmailVerificationPopupComponent {
  message: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) data: { message: string }) {
    this.message = data ? data.message : '';
  }
}
