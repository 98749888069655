import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'app-part-features',
  templateUrl: './part-features.component.html',
  styleUrls: ['./part-features.component.scss'],
})
export class PartFeaturesComponent implements OnInit {
  stripeOnboardImage = '';
  routesConst = routesConst;
  scrollELement: any = '';
  private unsubscribe$ = new Subject();
  partFeatures: any = [];
  partFeatures2: any = [];
  forIndividual = true;
  individualHowItWorks: any = [];
  companyHowItWorks: any = [];

  partFeaturesHeaderData = {
    styling: {
      'max-width': '620px',
    },
    bluetextStyle: 'span-blue-text',
    class: 'platform-heading-width',
    heading: ' how you spend time online',
    blueheading: 'think',
    description:
      'Manage your career, brand, or company on the go. Apply on the jobs, see invoices, get paid and much more.',
    button: { text: 'Try 3 Months Free', buttonLink: false },
    image: {
      src: 'assets/static/home/images/section1.png',
      redirectUrl: '',
    },
  };

  items = [
    {
      logo: '../../../../../assets/images/stripe.png',
      title: 'Recurring Payments',
      description: 'Manage memberships, and reduce declines with Stripe',
      img: '../../../../../assets/images/recruiter-w-icon.png',
      label: 'manage profile',
      url: `/${routesConst.dashboard}/${routesConst.account}`,
    },
    {
      logo: '../../../../../assets/images/azure.png',
      title: 'Azure DevOps',
      description: 'Services for teams to share, code, work and software',
      icon: 'image',
      label: 'post content',
    },
    {
      logo: '../../../../../assets/images/azure-backup.png',
      title: 'Azure Backup',
      description: 'Simplify data protection with Built-in backup management',
      icon: 'design_services',
      label: 'add services',
      url: `/${routesConst.dashboard}/${routesConst.services}`,
    },
    // {
    //   logo: '../../../../../assets/images/home/logo-monetize-social-network.png',
    //   title: this.isLoggedIn ? 'Add Subscriptions' : 'Create Income',
    //   description: 'create original content leverage social networks to buy exclusive access',
    //   icon: 'loyalty',
    //   label: 'subscriptions',
    //   url: `/${routesConst.dashboard}/${routesConst.subscription}`
    // },
    {
      logo: '../../../../../assets/images/ms-defender.png',
      title: 'Microsoft Cloud Defender',
      description: 'Extend threat protection to any infrastructure',
      icon: 'work',
      label: 'find jobs',
      url: `/${routesConst.jobs}`,
    },
    {
      logo: '../../../../../assets/images/firewall.png',
      title: 'Web Apps Firewall',
      description: 'A cloud native service that provides web app protection',
      icon: 'paid',
      label: 'payments',
      buttonImg: '../../../../../assets/images/home/btn-payments.png',
      url: `/${routesConst.dashboard}/${routesConst.payments}`,
    },
  ];

  Refferal: any = [
    {
      title: 'Referral Program',
      description:
        'Refer connections to our platform & earn cash each time they pay their memberships. Send messages to your friends, family, and business partners.',
    },
    {
      title: 'Earn 10% Rewards',
      description:
        'Earn 10% cash reward each time your referee pays their memberships. See all your referrals within your network displayed on referral dashboard.',
    },
    {
      title: 'Lets Grow Together',
      description:
        'Get an individual or company member referral plan then copy your link. You receive cash rewards as appreciation for sharing your community and network.',
    },
  ];
  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
  };

  innerWidth = this.commonService.getWindow()?.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = this.commonService.getWindow()?.innerWidth;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: any) => {
        this.navigateTo(event.url);
      });
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    this.stripeOnboardImage =
      innerWidth && innerWidth > 768
        ? 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/WCUoe.png'
        : 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/8PkVC.png';

    this.partFeatures = [
      {
        section: 'createMemberAccount',
        imagePosition: 'left',
        descWidth: 'member-account-desc-width',
        divider: false,
        superHeading: 'CREATE ACCOUNT',
        mainHeading: 'Create your member account in minutes',
        description: {
          content:
            '“Time equals money”, spend it doing what you love. Create your account in minutes on any device.',
        },
        button: [{ text: 'Get Started', buttonLink: false }],
        image: [
          {
            src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_3.png',
            redirectUrl: '',
          },
        ],
      },

      {
        section: 'instantAccountAccess',
        descWidth: 'instant-acc-desc-width',
        imagePosition: 'right',
        superHeading: 'INSTANT ACCESS',
        divider: false,
        mainHeading: 'Get instant access to your dashboard',
        description: {
          content:
            'Create your member account get instant access to your dashboard and features. Sell services, post jobs, content, and much more.',
          styling: '550px',
        },
        button: [{ text: 'Get Started', buttonLink: false }],
        image: [
          {
            src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_8.png',
            redirectUrl: '',
          },
        ],
      },

      {
        headingStyle: {
          'max-width': innerWidth && innerWidth < 768 ? '350px' : '',
        },
        section: 'growYourBrand',
        imagePosition: 'left',
        descWidth: 'creators-desc-width',
        superHeading: 'BRAND GROWTH',
        divider: false,
        mainHeading: 'Creators, athletes, experts & entrepreneurs',
        description: {
          content:
            'Create and earn all on one platform right from your profile.',
        },
        list: [
          'Increase paid subscriber audience',
          'Learn more from industry experts',
          'Own 100% of your content',
          // 'No max seller prices ',
          'Instant payout',
        ],
        button: [{ text: 'See Posts', buttonLink: routesConst.community }],
        image: [
          {
            src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_31.png',
            redirectUrl: '',
          },
        ],
      },

      {
        section: 'instantPay',
        styling: {
          'max-width': '550px',
        },
        id: 'instantPay',
        imageSize: 'images-sizing',
        descWidth: 'instant-pay-desc-width',
        imagePosition: 'right',
        superHeading: 'HOW WE PAY',
        divider: false,
        mainHeading: 'Get paid in minutes with instant payouts',
        description: {
          content:
            'Instant seller payouts, automated platform fees. Once services and or subscriptions are sold your money is transferred to your Stripe account.',
        },
        button: [
          {
            text: 'Earn More',
            buttonLink: `/${routesConst.dashboard}/${routesConst.payments}`,
          },
        ],
        image: [
          {
            src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/LV2bU.png',
            redirectUrl: '',
          },
          // {
          //   src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros+%2B+stripe.png',
          //   redirectUrl: ''
          // }
        ],
      },

      {
        imagestyle: {
          'border-radius': '10px',
        },
        imageSize: 'images-sizing',
        section: 'stripeConnectAccount',
        imagePosition: 'left',
        superHeading: 'MERCHANT ACCOUNT',
        divider: false,
        mainHeading: 'Send & receive payouts via Stripe seller account',
        description: {
          content: 'All paid members get a Stripe connect merchant account',
          styling: 'lg:max-w-xl',
        },
        list: [
          'Join & qualify for Stripe merchant account',
          'Stripe connect allows seamless payouts',
          'See account balances via Stripe account ',
          'Stripe verified partners added Instant Pay',
        ],
        button: [
          { text: 'Stripe Onboarding', buttonLink: '', id: 'onboarding' },
        ],
        image: [
          {
            // src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/Stripe+Onboarding+Phone.png',
            src: '../../../../../assets/images/discover.png',
            redirectUrl: '',
          },
        ],
      },

      {
        section: 'increaseYourProfits',
        imagePosition: 'right',
        superHeading: 'INCREASE PROFITS',
        divider: false,
        mainHeading: 'Companies, recruiters & job seekers',
        description: {
          content:
            'Recruit talent, apply on jobs and get hired on one platform',
          styling: 'lg:max-w-xl',
        },
        list: [
          'Unlimited job posting features ',
          'Message job seekers & applicants',
          'See job skills and requirements',
          'See weekly pay range for all jobs',
          // 'Manage jobs and applications',
        ],
        button: [{ text: 'See Jobs', buttonLink: routesConst.jobs }],
        image: [
          {
            // src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_web_photos26.png',
            src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/afHkn.png',
            redirectUrl: '',
          },
        ],
      },

      // {
      //   decStyle: {
      //     'max-width': innerWidth && innerWidth > 1440 ? '530px' : '',
      //   },
      //   section: 'weEmpowerYou',
      //   imagePosition: 'left',
      //   descWidth: 'empowerYou-desc-width',
      //   superHeading: 'WE EMPOWER YOU',
      //   divider: false,
      //   imageSize: 'img-max-height',
      //   mainHeading: 'Unleash your social influence with JOBPros',
      //   description: {
      //     content:
      //       'Increase paid subscriber audience by sharing your JOBPros profile. Share your referral link and manage cash rewards.',
      //   },
      //   list: [
      //     'Track all sales and balances on your member dashboard',
      //     'Transfer money to bank from your Stripe connect account',
      //     'Review paid referral cash rewards on your member account',
      //     'And much, much more',
      //   ],
      //   button: [
      //     {
      //       text: 'Get Paid',
      //       buttonLink: `/${routesConst.dashboard}/${routesConst.referrals}`,
      //     },
      //   ],
      //   image: [
      //     {
      //       src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_41.png',
      //       redirectUrl: '',
      //     },
      //   ],
      // },

      // {
      //   decStyle: {
      //     'max-width': innerWidth && innerWidth <= 586 ? '365px' : '',
      //   },
      //   section: 'WeValueReferrals',
      //   id: 'referrals',
      //   descWidth: 'referrals-desc-width',
      //   imagePosition: 'right',
      //   superHeading: 'WE VALUE REFERRALS',
      //   divider: false,
      //   mainHeading: 'Earn 10% monthly revenue share',
      //   description: {
      //     content: 'Our members can earn 10% referral revenue for memberships sold up to 12 months ongoing',
      //     styling: 'lg:max-w-xl',
      //   },
      //   list: [
      //     'Select referral membership add on',
      //     'Invite your professional and personal networks',
      //     'Share referral link to social and business networks',
      //     'Get consistent revenue per referral memberships paid',
      //   ],
      //   button: [{ text: 'Get Started', buttonLink: false }],
      //   image: [
      //     {
      //       src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_6.png',
      //       redirectUrl: '',
      //     },
      //   ],
      // },
    ];

    this.partFeatures2 = [
      {
        decStyle: {
          'max-width':
            innerWidth && innerWidth > 991
              ? '590px'
              : innerWidth && innerWidth <= 586
              ? '370px'
              : '',
        },
        section: 'howWeProtectYou',
        imagePosition: 'right',
        superHeading: 'HOW WE PROTECT YOU',
        divider: false,
        mainHeading: 'Your money and data are safe and secure',
        description: {
          content:
            'Our platform is integrated with some applications leading the tech industry. We accept payments, send payouts and allow members to manage their earnings via Stripe. We use Microsoft Azure & Dev Ops for trusted cloud, firewall and backup services.',
        },
      },
    ];

    this.individualHowItWorks = [
      {
        route: '/' + routesConst.dashboard + '/' + routesConst.account,
        icon: '../../../assets/images/home/logo-monetize-social-network.png',
        heading: 'Monetize everywhere',
        description:
          'Display what your’e about, share social posts with your network with industry experts, creators & hiring companies.',
      },

      {
        route: '/' + routesConst.dashboard + '/' + routesConst.payments,
        icon: '../../../assets/images/home/logo-create-passive-income.png',
        heading: 'Fast payouts',
        description:
          'Sell content & expert services get paid for referrals within minutes. Earn income streams & transfer directly to your bank.',
      },

      {
        route: '/' + routesConst.dashboard + '/' + routesConst.referrals,
        icon: '../../../assets/images/home/logo-monetize-service.png',
        heading: 'Best referral bonuses',
        description:
          'Earn 10% on every monthly referral. Share your network with us and get paid. All your referrals are displayed on your dashboard.',
      },
    ];

    this.companyHowItWorks = [
      {
        route: '/' + routesConst.dashboard + '/' + routesConst.network,
        icon: '../../../assets/images/home/logo-explore-job.png',
        heading: 'Community network',
        description:
          'Post unlimited jobs to hire the best talent. Network with industry experts, creators & athletes. Sell services and access to content.',
      },

      {
        route: '/' + routesConst.dashboard + '/' + routesConst.payments,
        icon: '../../../assets/images/home/logo-create-passive-income.png',
        heading: 'Fast payouts',
        description:
          'Sell content & expert services get paid for referrals within minutes. Earn income streams & transfer directly to your bank.',
      },

      {
        route: '/' + routesConst.dashboard + '/' + routesConst.referrals,
        icon: '../../../assets/images/home/logo-monetize-service.png',
        heading: 'Best referral bonuses',
        description:
          'Earn 10% on every monthly referral. Share your network with us and get paid. All your referrals are displayed on your dashboard.',
      },
    ];
  }

  ngAfterViewChecked() {
    if (this.scrollELement !== '') {
      this.scroll(this.scrollELement);
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.router.url.includes(routesConst.instantPay)) {
        this.commonService.getWindow()?.scroll(0, 0);
      }
    }, 600);
  }

  scroll(element: any) {
    if (this.scrollELement !== '') {
      document.querySelector(element)?.scrollIntoView({ block: 'start' });
    }
    this.resetScrollElement();
  }

  navigateTo(url: any) {
    if (this.router.url == url) {
      if (url.includes(routesConst.instantPay)) {
        setTimeout(() => {
          this.scrollELement = '#instantPay';
        }, 600);
      }
      if (url.includes(routesConst.featuresReferrals)) {
        this.scrollELement = '#referrals';
      }
    } else {
      this.scroll(this.scrollELement);
      this.router.navigate(['/' + url]);
    }
  }

  resetScrollElement() {
    setTimeout(() => {
      this.scrollELement = '';
    }, 100);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
