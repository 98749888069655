<div class="post-page">
  <div class="post-page_header border-normal hidden lg:flex justify-between items-center">
    <div class="flex items-center">
      <img src="../../../../../assets/images/users/icon-live-1.svg" width="58">
      <h4 class="montserrat-bold ml-4">kl.hairartist</h4>
      <p class="s blue ml-1 cursor-pointer">· Follow</p>
    </div>
    <a mat-button routerLink="{{'/' + routesConst.community + '/' + routesConst.profile}}" class="button flex items-center view-profile ml-auto">
      <span class="white montserrat-medium">View profile</span>
    </a>
  </div>
  <div class="post-page_main border-normal rounded w-full flex flex-col lg:flex-row mt-4">
    <div class="post-page_main_img relative">
      <iframe [src]="video_url" height="296" width="636" class="w-full" allowfullscreen >
      </iframe>
      <div class="post-page_main_info flex flex-col lg:flex-row" style="border-right: 1px solid #E5E5E5;">
        <div class="w-full flex items-center">
          <img src="../../../../../assets/images/users/icon-live-1.svg" class="ml-0.5">
          <div class="ml-2 pr-10 mr-auto">
            <div class="flex items-center">
              <p class="montserrat-bold">kl.hairartist</p>
              <p class="s blue cursor-pointer ml-1 mr-auto">· Follow</p>
            </div>
            <p class="montserrat-bold hidden lg:block mt-2">Crystal bridal hair comb in a rhinestone vine design</p>
            <p class="s my-2 hidden lg:block">You will fall in love with all the sparkle of this crystal and rhinestone bridal hair comb!</p>
            <p class="s blue hidden lg:block">#hairdesign</p>
          </div>
          <div class="block lg:hidden ml-auto">
            <button
              mat-icon-button
              class="cursor-pointer"
              [matMenuTriggerFor]="menu">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
          <mat-menu #menu="matMenu">
            <a routerLink="{{'/' +'profile' + '/' + routesConst.profile}}"
              class="flex lg:hidden items-center ml-auto px-4">
              <mat-icon class="mr-4">person</mat-icon>
              <span>View profile</span>
            </a>
          </mat-menu>
        </div>
        <p class="montserrat-bold block lg:hidden mt-2">Crystal bridal hair comb in a rhinestone vine design</p>
        <div class="hidden lg:flex flex-col justify-items-end">
          <div class="flex items-center">
            <mat-icon class="blue">visibility</mat-icon>
            <p class="s blue ml-2">22,222</p>
          </div>
          <div class="flex items-center">
            <mat-icon *ngIf="!isFavor" class="cursor-pointer ml-auto mt-1" (click)="isFavor = !isFavor">favorite_border</mat-icon>
            <mat-icon *ngIf="isFavor" class="cursor-pointer red ml-auto mt-1" (click)="isFavor = !isFavor">favorite</mat-icon>
            <mat-icon class="cursor-pointer icon-send overflow-hidden ml-3 mt-0.5" (click)="submitForm()">send_border</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="false" class="post-page_main_right relative">
      <div class="w-full flex items-center m-4">
        <mat-icon class="absolute" style="transform: rotate(90deg);">upgrade</mat-icon>
        <h3 class="montserrat-bold mx-auto">CHART</h3>
      </div>
      <mat-divider></mat-divider>
      <div class="comment-list pointer-events-none pt-4 pr-5 pb-9 pl-6">
        <div class="comment-card w-full py-2">
          <div class="comment-card_header flex">
            <div class="flex items-center">
              <img src="../../../../../assets/images/users/icon-comment-1.svg">
              <p class="montserrat-bold ml-2">username</p>
              <p class="s montserrat-medium ml-2">Love this shade!</p>
              <p class="s red ml-1"> ❤️❤❤</p>
            </div>
          </div>
          <p class="s gray-dark mt-2">1h · 21 likes · Reply</p>
        </div>
        <div class="comment-card w-full py-2">
          <div class="comment-card_header flex">
            <div class="flex items-center">
              <img src="../../../../../assets/images/users/icon-comment-2.svg">
              <p class="montserrat-bold ml-2">beautybymarissadawn</p>
              <p class="s montserrat-medium ml-2">Amazing</p>
              <p class="s ml-1"> 🔥🔥🔥</p>
            </div>
          </div>
          <p class="s gray-dark mt-2">1h · 33 likes · Reply</p>
        </div>
        <div class="comment-card w-full py-2">
          <div class="comment-card_header flex">
            <div class="">
              <div class="float-left flex items-center mr-2">
                <img src="../../../../../assets/images/users/icon-comment-3.svg">
                <p class="montserrat-bold ml-2">beautybyrubygrace</p>
              </div>
              <p class="comment-card_comment s montserrat-medium">Ok love the hair but I wanna know where her earrings are from 😂</p>
            </div>
          </div>
          <p class="s gray-dark mt-2">1h · 33 likes · Reply</p>
        </div>
      </div>
      <div class="absolute w-full bottom-0">
        <mat-divider></mat-divider>
        <form class="flex flex-col w-full px-4 mt-2 mb-2.5" [formGroup]="commentForm" (ngSubmit)="submitForm()" novalidate>
          <mat-form-field>
            <textarea matInput
              cdkTextareaAutosize
              placeholder="Add a comment"
              formControlName="comment"
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="3"></textarea>
            <mat-error
              *ngIf="errorHandling('comment', 'required')"
              [class]="errorHandling('comment', 'required') ? 'pb-4' : ''">
              <p >Please input a comment.</p>
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div *ngIf="true" class="flex items-center lg:px-10 mx-1.5">
      <div class="flex justify-center flex-col items-center">
        <h3 class="montserrat-bold mt-10 lg:mt-0 mb-0.5">Livestream begins in</h3>
        <p class="mt-6">2 days · 2 hours · 45 minutes · 34 seconds</p>
        <div class="flex flex-col items-center justify-center text-center border-normal lg:border-none lg:bg-blue-light p-6 mt-10">
          <p class="montserrat-bold lg:w-96 mb-6">The author has decided to do this livestream with their paid subscribers.</p>
          <button mat-button class="button mx-auto" (click)="subscribe()"><span class="white montserrat-medium">Subscribe</span></button>
        </div>
      </div>
    </div>
  </div>
  <div class="about-card border-normal rounded hidden lg:flex items-center mt-4">
    <img src="../../../../../assets/images/users/icon-live-1.svg" width="87">
    <div class="ml-6">
      <div class="flex items-center">
        <h3>About</h3>
        <h3 class="montserrat-bold ml-2">kl.hairartist</h3>
      </div>
      <p class="mt-1 mb-3" style="max-width: 566px;">Stylist at Beautiful Hair Color Studio ·  Balayage + Color Specialist · Port Orange | Central Florida</p>
      <p class="montserrat-bold s">3.2M followers</p>
    </div>
  </div>
  <div class="flex items-center mt-10">
    <h3 class="montserrat-bold">Live right now</h3>
    <p class="blue s">· Start your livestream</p>
  </div>
  <div class="live-min-card-list flex mt-7">
    <div class="live-min-card flex flex-col justify-items-center items-center relative">
      <img src="../../../../../assets/images/users/live-img-1.svg" width="100">
      <p class="live-min-card-tag montserrat-bold bg-red white capitalize absolute">live</p>
    </div>
    <div class="live-min-card flex flex-col justify-items-center items-center relative ml-2">
      <img src="../../../../../assets/images/users/live-img-2.svg" width="100">
      <p class="live-min-card-tag montserrat-bold bg-red white capitalize absolute">live</p>
    </div>
  </div>
</div>
