import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { registerValues } from 'src/app/core/const/register';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  private unsubscribe$ = new Subject();
  selected = new FormControl(1); //TODO: 1 for prelaunch
  user: any;
  registerValues = registerValues;
  type = registerValues.UserTypes.candidate;
  isAdmin = false;
  reciever: any;
  sender_id: any
  userProfile: any;
  recruiterUnread: any = false;
  myNetworkUnread: any = false;
  clientUnread: any = false;
  networkUser: any;
  clientUser: any;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5,
  };

  constructor(private commonService: CommonService, public dialogRef: MatDialogRef<MessagesComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, public authService: AuthService, public dialog: MatDialog, private route: ActivatedRoute, private sharedService: SharedService) {
    this.isAdmin = this.authService.isAdmin();
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((inbox: any) => {
      if (inbox.tab) {
        this.selected.setValue(parseInt(inbox.tab));
      } else {
        this.selected.setValue(1);
      }
    });
  }

  ngOnInit() {
    if (this.authService.loggedIn()) {
      this.getUserInfo();
      this.recruitersUnreadMessages();
      this.myNetworkUnreadMessages();
      this.clientUnreadMessages();
    }
    const data = this.commonService.getWindow()?.localStorage.getItem('companyId');
    if (data) {
      const companyId = JSON.parse(data);
      if (companyId) {
        this.reciever = companyId;
      }
    }
    const selectedUser = this.commonService.getWindow()?.localStorage.getItem('userId');
    if (selectedUser) {
      const user = JSON.parse(selectedUser);
      if (user) {
        this.networkUser = user;
      }
    }
    const selectedClient = this.commonService.getWindow()?.localStorage.getItem('talentInfo');
    if (selectedClient) {
      const user = JSON.parse(selectedClient);
      if (user) {
        this.clientUser = user;
      }
    }
  }

  getUserInfo() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(((information: { profileInfo: any; } | null) => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        const { _id, user_type } = profileInfo;
        this.user = profileInfo;
        this.sender_id = _id;
        this.type = user_type;
        this.userProfile = profileInfo;
      }
    }))
  }

  recruitersUnreadMessages() {
    this.sharedService.isRecruiterUnreadMessageExist$.pipe(takeUntil(this.unsubscribe$)).subscribe((isUnread: any) => {
      this.recruiterUnread = isUnread;
    })
  }

  myNetworkUnreadMessages() {
    this.sharedService.isNetworkUnreadMessageExist$.pipe(takeUntil(this.unsubscribe$)).subscribe((isUnread: any) => {
      this.myNetworkUnread = isUnread;
    })
  }

  clientUnreadMessages() {
    this.sharedService.isClientUnreadMessageExist$.pipe(takeUntil(this.unsubscribe$)).subscribe((isUnread: any) => {
      this.clientUnread = isUnread;
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
