<div [ngClass]=" !isDialog ? 'sign-in-page relative pt-14 lg:pt-0 sign-in-page-wrapper':'relative sign-in-page'"
  [class]="loading ? 'spinner' : ''">
  <!-- <div *ngIf="!isDialog" routerLink="{{ routesConst.launchPath }}"
    class="h-6 absolute right-0 mr-5 lg:mr-28 mt-2.5 lg:mt-10 rounded-full z-30 cursor-pointer">
    <mat-icon>cancel</mat-icon>
  </div> -->
  <div class="sign-up-content">
    <div class="flex flex-col w-full text-center">
      <!-- <a *ngIf="isDialog" routerLink="{{ routesConst.launchPath }}" class="padding-bottom absolute -left-3 -top-6">
        <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_Black.png"
          class="w-32 object-cover" />
      </a> -->
      <h3 *ngIf="!isDialog" class="montserrat-bold lg:mt-6 mb-4">Login</h3>
      <h3 *ngIf="isDialog" class="heading mt-8">Login to JOBPros</h3>
      <p *ngIf="isDialog" class="mt-2 accout-link" >Don't have an account? <a class="blue cursor-pointer" (click)="forSignUpRedirection('SignUp')">Create an account</a></p>
      <form class="flex flex-col w-full px-4 mb-6" [ngClass]="isDialog ? '' : 'mt-6'" [formGroup]="signInForm" (ngSubmit)="submitForm()">
        <mat-form-field [ngClass]="isDialog ? 'mt-6' : ''" class="border-no rounded">
          <input matInput class="border-own" placeholder="Your email" formControlName="email" />
          <mat-error *ngIf="errorHandling('email', 'required')"
            [class]="errorHandling('email', 'required') ? 'pb-0' : ''">
            You must provide an email
          </mat-error>
          <mat-error *ngIf="errorHandling('email', 'email')" [class]="errorHandling('email', 'email') ? 'pb-0' : ''">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field [ngClass]="isDialog ? 'mt-6' : ''" class="border-no border-0 mb-3 rounded">
          <input maxlength="257" matInput placeholder="Your password" class="border-own" formControlName="password"
            [type]="hide.password ? 'password' : 'text'" />
          <mat-icon matSuffix (click)="hide.password = !hide.password">{{ hide.password ? "visibility" :
            "visibility_off"}}</mat-icon>
          <mat-error *ngIf="errorHandling('password', 'required')"
            [class]="errorHandling('password', 'required') ? 'pb-0' : ''">
            You must provide a password.
          </mat-error>
          <mat-error *ngIf="errorHandling('password', 'minlength')"
            [class]="errorHandling('password', 'minlength') ? 'pb-0' : ''">
            Must be at least 8 characters!.
          </mat-error>
        </mat-form-field>
        <a (click)="forSignUpRedirection('ResetPassword')"
          class="blue s montserrat-bold text-left ml-1 cursor-pointer">Forgot password</a>
        <div class="mt-6">
          <button type="submit" mat-button class="button outlined black w-full">
            Sign In
          </button>
        </div>
        <p *ngIf="!isDialog" class="mt-4">Don’t have an account?<a routerLink="{{ '/' + routesConst.signup }}"
            class="blue montserrat-bold ml-1">Sign Up</a>
        </p>
      </form>
    </div>
  </div>
  <div *ngIf="!isDialog" class="mx-auto my-10 md:mt-10 md:bottom-20 w-full">
    <div class="flex flex-col items-center text-center">
      <div class="flex flex-col items-center text-center cursor-pointer" (click)="contactUs()">
        <button mat-icon-button class="button outlined black"
          style="display: flex; align-items: center; justify-content: center; margin-bottom: 4px;">
          <mat-icon class="blue" style="transform: rotate(-45deg); font-size: 30px">send</mat-icon>
        </button>
        Contact Us
      </div>
    </div>
  </div>
</div>