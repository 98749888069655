import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AchievementType } from 'src/app/core/const/options';
import { Achievement } from 'src/app/core/models';

@Component({
  selector: 'app-achievement-form',
  templateUrl: './achievement-form.component.html',
  styleUrls: ['./achievement-form.component.scss'],
})
export class AchievementFormComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() achievement: Achievement | undefined;
  @Input() type: string | undefined;
  @Output() save: EventEmitter<any> = new EventEmitter<Achievement>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<Achievement>();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  achievementForm: FormGroup;
  studingNow: boolean;
  start_date?: string | null;
  end_date?: string | null;
  startDateMax: string;
  endDateMin?: string;
  editMode: boolean;
  achType: string;
  AchievementType = AchievementType;
  updateDetect = true;
  deletBtn:boolean = false;



  constructor(public fb: FormBuilder) {
    this.achievementForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(128)]],
      description: ['', [Validators.required, Validators.maxLength(128)]],
      studingNow: [false, []],
    });

    this.studingNow = false;
    this.startDateMax = '';
    this.endDateMin = '';
    this.editMode = false;
    this.achType = AchievementType.Education;
  }

  ngOnInit(): void {
    if (this.achievement?._id) {

      this.editMode = true;
      this.achievementForm.controls['title'].setValue(this.achievement?.title);
      this.achievementForm.controls['description'].setValue(this.achievement?.description);
      const setEndDate = this.achievement?.end_date === null ? true : false
      this.achievementForm.controls['studingNow'].setValue(setEndDate);
      this.studingNow = setEndDate;
    }


    if (this.achievement !== undefined) {
      this.start_date = this.achievement.start_date;
      this.end_date = this.achievement.end_date;
    }
    this.achievementForm.controls['studingNow'].valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((studingNow) => {
      this.studingNow = studingNow;
    });
    this.achievementForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
      const previousEndDate = this.achievement?.end_date === null ? true : false
      if (form.title !== this.achievement?.title ||
        form.description !== this.achievement?.description ||
        form.studingNow !== previousEndDate
      ) {
        this.updateDetect = false;
      } else {
        this.updateDetect = true;
      }
    });
    this.startDateMax = new Date().getMonth() + 1 + '/' + new Date().getFullYear().toString();
    this.endDateMin = this.startDateMax;
    if (this.achievement?.type) {
      this.achType = this.achievement.type
    } else if (this.type) {
      this.achType = this.type
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.achievementForm.controls[control].hasError(error);
  };

  setStartDate(date: string) {
    this.checkIfDateChange();
    if (date) {
      this.start_date = date;
      this.endDateMin = date;
    }
  }

  checkIfDateChange() {
    (this.start_date != this.achievement?.start_date || this.end_date != this.achievement?.end_date) ? this.updateDetect = false : this.updateDetect = true;
  }

  setEndDate(date: string) {
    this.checkIfDateChange();
    if (date) {
      this.end_date = date;
      this.startDateMax = this.end_date;
    }
  }

  onSave() {
    this.save.emit({
      ...this.achievement,
      title: this.achievementForm.controls['title'].value.trim(),
      description: this.achievementForm.controls['description'].value.trim(),
      start_date: this.start_date ? this.start_date : this.startDateMax,  //MM/YYYY
      end_date: this.studingNow ? null : this.startDateMax,  //MM/YYYY
    });
  }

  onCancel() {
    this.cancel.emit();
  }

  onDelete() {
    this.deletBtn = true;
    this.remove.emit();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
