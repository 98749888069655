<div class="px-4 lg:px-0 pt-0.5 lg:pt-0 dashboard-payment">
  <div class="flex items-center w-full">
    <h1 class="montserrat-bold window-mode text-style">Payments</h1>
    <!-- <div class="ml-auto md:hidden">
      <button mat-button class="button outlined black mt-md" (click)="payments()">
        Stripe Payments
      </button>
    </div> -->
  </div>
  <div class="tabs mt-4">
    <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container common-arrow"
    >
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 0" [class]="selected.value === 0 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
              Balance
            </p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 1" [class]="selected.value === 1 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
              History
            </p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 2" [class]="selected.value === 2 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">
              Manage
            </p>
          </div>
        </ng-template>
    </swiper>
    <mat-tab-group color="#fff0" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
            <p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
              Balance
            </p>
          </div>
        </ng-template>
        <app-dashboard-payment-balance></app-dashboard-payment-balance>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 1 ? 'selected' : ''">
            <p [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
              History
            </p>
          </div>
        </ng-template>
        <app-dashboard-payment-history></app-dashboard-payment-history>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 2 ? 'selected' : ''">
            <p [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">
              Manage
            </p>
          </div>
        </ng-template>
        <app-manage-cards></app-manage-cards>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>