<div *ngIf="school !== undefined" class="py-2">
  <div class="flex items-center">
    <p class="montserrat-bold whitespace-nowrap truncate mx-width">{{school.title}}</p>
  </div>
  <div class="flex items-center">
    <p *ngIf="school.start_date !== undefined" class="s montserrat-medium gray-dark mt-0.5" style="margin-left: 1px;">
      {{school.start_date | date : 'MMM yyyy' }}
    </p>
    <p class="mx-1">-</p>
    <p *ngIf="school.end_date !== undefined" class="s montserrat-medium gray-dark mt-0.5" style="margin-left: 1px;">
      {{school.end_date | date : 'MMM yyyy'}}
    </p>
    <p *ngIf="school.end_date == null" class="s montserrat-medium gray-dark mt-0.5" style="margin-left: 1px;">
      In-Progress
    </p>
  </div>
  <div class="whitespace-nowrap truncate mx-width text-sm">
    {{school.description}}
  </div>
</div>