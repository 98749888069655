<div *ngIf="data" class="flex flex-row gap-2 md:gap-0 md:flex-col border-normal rounded bg-white relative items-center px-2.5 py-4">
  <div class="absolute right-0 -top-1" *ngIf="!isFollower">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon class="gray-dark cursor-pointer">more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onClick()">Unfollow</button>
    </mat-menu>
  </div>
  <img (click)="showProfile()"
    [src]="data?.profile_image?.length ? data.profile_image : 'https://i.postimg.cc/Hk7vyZZM/no-image.png' "
    class="cursor-pointer mt-2.5 rounded-full user-avatar w-10 h-10 md:w-24 md:h-24" (click)="showProfile()" />
  <p (click)="showProfile()" *ngIf="data?.user_name" class="cursor-pointer text-left md:text-center s max-w-40 w-full card-boxes-date truncate">@{{ data.user_name  }}</p>
  <p *ngIf="data?.created_at" class="cursor-pointer s max-w-40 w-full text-right md:text-center card-boxes-date truncate">Since {{ data.created_at | date:'mediumDate' }}</p>
</div>
<mat-divider class="w-full lg:hidden opacity-50"></mat-divider>