import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { ModeratorStatusConst } from 'src/app/const/appConst';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { InviteUserModalComponent } from 'src/app/shared/components/invite-user-modal/invite-user-modal.component';
@Component({
  selector: 'app-admin-setting',
  templateUrl: './admin-setting.component.html',
  styleUrls: ['./admin-setting.component.scss']
})
export class AdminSettingComponent implements OnInit {
  ModeratorStatusConst = ModeratorStatusConst;
  private unsubscribe$ = new Subject();
  filterOptions = [
    {
      id: 'all',
      label: 'All users'
    }
  ];

  users = [
    {
      name: 'Max Lorens',
      mail: 'm.loren@milleniumtower.com',
      status: true,
      role: 'recruiter'
    },
    {
      name: 'Sarah Pierce',
      mail: 's.pierceleniumtower.com',
      status: false,
      role: 'admin'
    },
    {
      name: 'John Doe',
      mail: 'mjohn.doe@milleniumtower.com',
      status: false,
      role: 'recruiter'
    }
  ];

  constructor(public dialog: MatDialog, private profileService: ProfileService) {}

  ngOnInit(): void {
    this.getModerators();
  }

  showModal(user?:any) {    
    const dialogRef = this.dialog.open(InviteUserModalComponent, {
      width: '536px',
      maxWidth: '100vw',
      height: window.innerWidth > 600 ? '650px' : '100%',
      data:{user:user ? user:null}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getModerators()
    })
  }
  showConfirmModal(user: any, isActive: boolean) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        header: !isActive ? 'Activate User' : 'Deactivate User',
        message: !isActive
          ? 'Are you sure you want to Activate this user?'
          : 'Are you sure you want to Deactivate this user?'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.profileService
          .updateMooderatorStatus({
            id: user._id,
            status: isActive ? this.ModeratorStatusConst.INACTIVE : this.ModeratorStatusConst.ACTIVE
          })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res) this.getModerators();
          });
      }
    });
  }

  getModerators() {
    this.profileService
      .getModerators({ offset: 0, limit: 10 })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.users = result.data;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
