<div class="pt-0.5 lg:pt-0 dashboard-payment">
  <div class="flex items-center w-full window-mode">
    <h1 class="montserrat-bold mr-auto text-style">Referrals</h1>
  </div>
  <div class="tabs mat-tabs-referal mt-4">
    <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container common-arrow"
    >
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 0" [class]="selected.value === 0 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">Invite</p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 1" [class]="selected.value === 1 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">Referrals</p>
          </div>
        </ng-template>
    </swiper>
    <mat-tab-group color="#fff0" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
            <p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">Refer Connections</p>
          </div>
        </ng-template>
        <app-dashboard-referals-connection (yourReferral)="goToReferral()"></app-dashboard-referals-connection>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 1 ? 'selected' : ''">
            <p [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">Your Referrals</p>
          </div>
        </ng-template>
        <app-dashboard-referals-me [selectedTab]="selected.value"  [referralData]="referralData" [earning]="totalEarning" (sortBy)="sortBy($event)">
        </app-dashboard-referals-me>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>