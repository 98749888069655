<div class="section-wrapper network-tab flex h-full ">
  <div class="flex flex-col w-full md:w-1/3 tab-aside" [ngClass]="{active: tabCollapse}">
    <div class="flex w-full items-center justify-center md:px-4 py-2.5 bg-white">
      <input maxlength="256" class="input-form w-full bg-white-dark px-4 py-3.5" placeholder="Search network…" #userSearch
        [formControl]="myControl" />
    </div>
    <div *ngIf="userList && userList.length" class="scroll" #scrollUserList (scroll)="onScrollDown()"  (click)="openTabCollapse()">
      <app-message-user-contact-card *ngFor="let item of userList" [data]="item"  [sender]="sender_id"
        class="w-full" [class]="item?.userList?._id === selectedUser?.userList?._id ? 'activeUser' : ''"
        (click)="showMessageHistory(item.userList._id)">
      </app-message-user-contact-card>
    </div>
  </div>
  <mat-divider class="hidden md:block h-full w-0.5" [vertical]="true"></mat-divider>
  <div class="flex flex-col w-full md:w-2/3 h-full tab-contnet relative" [ngClass]="{active: !tabCollapse}">
    <!-- <button class=" flex justify-start px-5 md:hidden" (click)="openTabCollapse()">
      <mat-icon class="blue arrow-back">west</mat-icon>
      <span class="blue ml-1 montserrat-bold">All messages</span>
    </button> -->
    <div class="px-3 py-2.5 flex items-center bg-white-dark">
      <img (click)="openTabCollapse()" class="block md:hidden mr-2 expand-icon" src="../../../../assets/images/arrow-right.svg" />
      <img [src]="getProfileImage()" class="image-round mr-2" />
      <p *ngIf="selectedUser?.userList?._id" class="montserrat-medium mx-w-40 truncate">{{ selectedUser?.userList?.user_name }}</p>
      <p *ngIf="selectedUser?.userList?._id" class="s blue mt-1.6 ml-2  cursor-pointer"
        (click)="viewProfile(selectedUser.userList.user_name)">View profile</p>
    </div>
    <div *ngIf="showSpinner" class="uploader-status flex justify-center absolute items-center inset-0">
      <mat-spinner [diameter]="diameter" [color]="color"></mat-spinner>
    </div>
    <div class="p-4 m-0.5 scroll-net" *ngFor="let chat of chatMessages" #scrollChat (scroll)="onScrollUp()">
      <div class="flex pl-0.5 w-3/3" *ngFor="let messages of chat.data">
        <div class="w-full" [ngClass]="
              sender_id === messages.from_id
                ? ' flex flex-col ml-auto'
                : ' flex flex-col'
            ">
          <div class="flex w-full flex-row flex-nowrap">
            <!-- <div class="image-round mr-3" [ngClass]="
                  sender_id === messages.from_id
                    ? ' flex flex-col ml-auto'
                    : ' flex flex-col'
                ">
              <img [src]="
                    sender_id == messages.from_id
                      ? getSenderImage()
                      : getProfileImage()
                  " class="image-round" />
            </div> -->
            <p class="break-words" *ngIf="messages?.message_content?.includes('http')" [ngClass]="
                  sender_id === messages.from_id
                    ? ' s border-normal rounded-full px-6 py-3.5 ml-auto sender-bg'
                    : 's border-normal rounded-full px-6 py-3.5 background'
                ">
              <a href='{{messages.message_content}}' target="_blank">
                <img *ngIf="messages.post_content" [src]="messages.post_content" class="message_content mb-4">
                <p *ngIf="messages.post_description">{{messages.post_description}}</p>
                {{messages.message_content}}
              </a>
            </p>
            <p class="w-100 break-words" *ngIf="!(messages?.message_content?.includes('http'))" [ngClass]="
                        sender_id === messages.from_id
                          ? ' s border-normal rounded-full px-6 py-3.5 ml-auto sender-bg'
                          : 's border-normal rounded-full px-6 py-3.5 background'
                      ">
              {{ messages.message_content}}
            </p>
          </div>
          <p class="s gray-dark mt-1.5" [ngClass]="
                sender_id === messages.from_id
                  ? ' flex flex-col ml-auto'
                  : ' flex flex-col'
              ">
            {{ messages.created_at | date: "EE d MMM - h:mm a" }}
          </p>
        </div>
      </div>
    </div>
    <div class="mt-auto message-edit-part bg-white" [ngClass]="{'for-mobile-text': !tabCollapse}">
      <div class="flex items-center justify-between border-normal rounded">
        <textarea #messageContent [class]="!userList || userList?.length==0? 'disabled':'' "
          [disabled]="!userList || userList?.length==0" class="border-none s resize-none w-5/6 h-20 outline-none"
          placeholder="Compose your message" [(ngModel)]="sendMessagetext"  (keydown)="sendMessage($event)"></textarea>
        <button [disabled]="!messageContent.value.trim().length" [class]="!messageContent.value.trim().length ? '':'btn-able'" class="gray-dark flex items-center montserrat-bold border-blue cursor-pointer ml-auto mr-3.5"
          (click)="sendMessage($event)">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>