<div class="individual-service-page" *ngIf="selectedService">
  <!-- <div class="mb-4">
    <app-go-back></app-go-back>
  </div> -->
  <div class="main-grid-wrapper">
    <div class="user-profile-section">
      <div>
        <!-- <h1
          class="individual-service-page_title break-word pb-4"
        >
          {{ selectedService?.title | titlecase }}
        </h1> -->
        <div *ngIf="selectedService?.profile_id == loginedUser?._id">
          <p
            *ngIf="!selectedService?.editTitle"
            type="text"
            class="individual-service-page_title pb-4 px-0 w-full pt-0"
            readonly
          >
            {{ selectedService.title }}
            <mat-icon
              class="blue text-lg"
              (click)="selectedService.editTitle = true; updatedTitle = selectedService.title"
              >edit</mat-icon
            >
          </p>
        </div>

        <div *ngIf="selectedService?.editTitle" class="flex">
          <textarea
            class="input-form pb-4 px-0 w-full pt-0 title"
            placeholder="Title"
            [(ngModel)]="updatedTitle"
          >
          </textarea>

          <!-- <textarea type="text" class="individual-service-page_title pb-4 px-0 w-full pt-0" [(ngModel)]="updatedTitle"></textarea> -->
          <mat-icon class="blue" (click)="updateTitle()">check</mat-icon>
        </div>
        <div class="mb-4 flex flex-col md:flex-row md:gap-3 cursor-pointer" (click)="viewProfile()">
          <div class="flex gap-3">
            <img
              class="avatar-profile rounded-full object-cover border"
              [src]="
                selectedService?.profile?.profile_img_path
                  ? selectedService?.profile?.profile_img_path
                  : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs'
              "
            />
            <div>
              <div>
                <p class="user-name">{{ selectedService?.profile?.name }}</p>
              </div>
              <div class="flex mt-3" *ngIf="averageRating">
                <div class="review">
                  <star-rating
                    [starType]="'svg'"
                    [showHalfStars]="true"
                    [disabled]="true"
                    [rating]="averageRating"
                    [matTooltip]="'Rating: ' + averageRating"
                  ></star-rating>
                </div>

                <!-- <div
                  class="flex items-center"
                  *ngIf="selectedService?.profile?.location?.length"
                >
                  <mat-icon class="blue">room</mat-icon>
                  <p class="location">
                    {{ selectedService.profile.location[0]?.city_name }} ·
                    {{ selectedService.profile.location[0]?.state_name }} ·
                    {{ selectedService.profile.location[0]?.country_code }}
                  </p>
                </div> -->
              </div>
            </div>
          </div>
          <p
            *ngIf="
              selectedService?.create_by &&
              loginedUser?._id == selectedService?.profile?._id &&
              loginedUser?._id !== selectedService?.create_by?._id
            "
            class="ml-[48px] md:ml-auto md:mt-1 moderator-name text-sm"
          >
            By: @{{ selectedService?.create_by?.user_name }}
          </p>
        </div>
      </div>

      <!-- Images View Mode -->
      <div class="mt-4 px-0" *ngIf="images?.slides && !images?.editMode">
        <p
          *ngIf="selectedService?.profile_id == loginedUser?._id"
          class="blue cursor-pointer font-bold flex justify-end text-sm mb-4"
          (click)="images.editMode = true"
        >
          Edit
        </p>
        <div id="service-img" class="slide-wrapper relative">
          <!-- <span *ngIf="images?.slides?.length && images?.slides?.length > 1" class="slide-number">{{
            activeSlide + "/" + images?.slides?.length
          }}</span> -->
          <swiper [pagination]="images?.slides.length > 1" (slideChange)="onSlideChange($event)">
            <ng-template swiperSlide *ngFor="let portfolio of images?.slides">
              <img [src]="portfolio?.path" class="w-full cursor-pointer img-width" />
            </ng-template>
          </swiper>
        </div>
      </div>

      <!-- Images Edit Mode -->
      <div class="mt-4 px-0" *ngIf="images?.slides && images?.editMode">
        <!-- your-component.component.html -->

        <!-- <form [formGroup]="imageForm" (ngSubmit)=""> -->

        <swiper
          class="edit-swipper max-h-[231px] md:max-h-full"
          [pagination]="images?.slides.length > 1"
          (slideChange)="onSlideChange($event)"
        >
          <ng-template swiperSlide *ngFor="let portfolio of getArray(4); let i = index">
            <div
              class="flex items-center relative choose-img-wrapper border border-dashed border-current rounded bg-white max-h-[231px] md:max-h-full"
            >
              <img
                *ngIf="allImages[i] && allImages[i].content && allImages[i]?.format === 'image'"
                (click)="preview(allImages[i], i)"
                [src]="allImages[i].content"
                class="w-full h-full bg-contain bg-no-repeat bg-center max-h-[231px] md:max-h-full"
              />
              <label
                class="w-full flex justify-center"
                [htmlFor]="'fileInput'"
                *ngIf="!allImages[i] && !allImages[i]?.content"
                (click)="selectedIndex = i + 1"
              >
                <mat-icon class="gray-dark icon-align">add_a_photo</mat-icon>
              </label>
              <div
                style="height: 36px"
                *ngIf="allImages[i] && allImages[i].content"
                class="absolute right-0 top-0 flex items-center justify-center p-1 border-2 bg-black rounded-full"
              >
                <label [htmlFor]="'fileInput'" (click)="selectedIndex = i + 1">
                  <mat-icon class="white" style="margin-top: 2px">edit</mat-icon>
                </label>
              </div>
            </div>

            <input
              id="fileInput"
              [accept]="'image/*'"
              #file
              multiple
              type="file"
              class="uploadFile img w-0 h-0 overflow-hidden"
              value="Upload Media"
              (change)="uploadFile(file.files, i)"
              style="display: none"
            />
          </ng-template>
        </swiper>
        <!-- <div
        class="flex mt-2 flex-col md:flex-row flex-wrap justify-center mx-auto gap-6 lg:gap-2 card-container"
      >
        <ng-container *ngFor="let item of pricingPackages">
          <div class="Pricing-pakage-border"*ngFor="let item of pricingPackages">
          <app-pricing-package-card
            [pricingPackage]="item"
          ></app-pricing-package-card>
        </div>
        </ng-container>
      </div> -->

        <!-- INDEX:{{i}} -->

        <!-- </form> -->

        <button
          class="py-1.5 px-4 text-center hover:bg-[#2887ed] text-black w-full hover:text-white border-2 border-[#2887ed] rounded mt-9 md:mt-0 mb-2"
          (click)="updateServiceContent()"
        >
          Save
        </button>
      </div>

      <!-- <p
    *ngIf="averageRating"
    class="mb-4 p-4 font-bold blue cursor-pointer flex justify-center"
    (click)="openReviewModal()"
  >
    See reviews
  </p> -->
    </div>
    <div class="mb-6 pricingPackage">
      <!-- <h3 class="montserrat-bold font-siyze text-center mb-2">Pricing</h3> -->
      <!-- <swiper [pagination]="pricingPackages.length > 1" [navigation]="pricingPackages.length > 1"  [config]="config"> -->
      <!-- <div
        class="flex mt-2 flex-col md:flex-row flex-wrap justify-center mx-auto gap-6 lg:gap-2 card-container"
      > -->
      <!-- <ng-container *ngFor="let item of pricingPackages"> -->
      <!-- <div class="Pricing-pakage-border"*ngFor="let item of pricingPackages"> -->
      <!-- <app-pricing-package-card
            [pricingPackage]="item"
          ></app-pricing-package-card> -->
      <!-- </div> -->
      <!-- </ng-container>
      </div> -->
      <!-- </swiper> -->
      <div class="grid grid-cols-3">
        <div
          class="plans-btn border"
          (click)="selectPackage('Basic')"
          [ngClass]="selectedPlan === 'Basic' ? 'selected-plan' : ''"
        >
          Basic
        </div>
        <div
          class="plans-btn border-y"
          (click)="selectPackage('Standard')"
          [ngClass]="selectedPlan === 'Standard' ? 'selected-plan' : ''"
        >
          Standard
        </div>
        <div
          class="plans-btn border"
          (click)="selectPackage('Premium')"
          [ngClass]="selectedPlan === 'Premium' ? 'selected-plan' : ''"
        >
          Premium
        </div>
      </div>

      <!-- PACKAGE VIEW MODE -->
      <div
        *ngIf="selectedPackage && !selectedPackage?.editMode"
        class="border-x border-b px-6 pb-5 pt-8"
      >
        <p
          *ngIf="selectedService?.profile_id == loginedUser?._id"
          class="blue cursor-pointer font-bold flex justify-end text-sm mb-4"
          (click)="selectedPackage.editMode = true"
        >
          Edit
        </p>
        <div>
          <div class="flex justify-between">
            <p class="packge-name">
              {{ selectedService?.title }}
            </p>
            <p class="pakge-price">${{ selectedPackage?.price }}</p>
          </div>
          <p class="package-description">{{ selectedPackage?.description }}</p>
          <p class="points-heading">What's Included</p>
          <div class="services-items mb-5">
            <div
              class="item flex items-center mt-2 gap-3"
              *ngFor="let item of selectedPackage?.features"
            >
              <mat-icon class="item-icon font-bold">check</mat-icon>
              <p class="item-text font-bold">{{ item }}</p>
            </div>
          </div>
          <button class="package-btn" (click)="purchaseService(selectedPackage, 'Service')">
            Pay
          </button>
          <button
            *ngIf="selectedService?.profile_id != loginedUser?._id"
            class="py-1.5 message-btn"
            (click)="message(selectedService)"
          >
            Message
          </button>
          <button
            *ngIf="
              selectedService?.profile_id === loginedUser?._id && selectedService?.is_active != true
            "
            class="py-1.5 message-btn blue"
            (click)="activeInactiveTalentService(true)"
          >
            Activate
          </button>
          <button
            *ngIf="
              selectedService?.profile_id === loginedUser?._id && selectedService?.is_active == true
            "
            class="py-1.5 deactivate-btn"
            (click)="activeInactiveTalentService(false)"
          >
            Deactivate
          </button>
        </div>
      </div>

      <!-- NO PACKAGE FOUND -->
      <div *ngIf="!selectedPackage" class="border-x border-b px-6 pb-5 pt-8">
        <p class="flex justify-center font-bold">No package found</p>
      </div>

      <!-- PACKAGE EDIT MODE -->
      <div *ngIf="selectedPackage?.editMode" class="border-x border-b px-6 pb-5 pt-4">
        <p
          *ngIf="selectedService?.profile_id == loginedUser?._id"
          class="blue cursor-pointer font-bold flex justify-end text-sm mb-4"
          (click)="selectedPackage.editMode = false"
        >
          Cancel
        </p>
        <div>
          <div class="mt-6" *ngFor="let item of getArray(1)">
            <app-package-unit-update
              [pricingPackages]="selectedPackage"
              [checkPriceValidation]="true"
              [order]="getSelectedPackageIndex()"
              (setPackageForm)="savePackageData($event)"
            ></app-package-unit-update>
          </div>
          <button
            class="package-btn"
            (click)="updatePackage(selectedPackage)"
            [class]="!isUpdated ? 'disabled' : ''"
            [disabled]="!isUpdated"
          >
            Update
          </button>
        </div>
      </div>
    </div>
    <!-- <button class="package-btn">Update</button> -->
  </div>
  <div class="my-4">
    <mat-divider></mat-divider>
  </div>
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="black-dark"
    size="medium"
    color="red"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    class="job-list-spinner"
  >
  </ngx-spinner>
  <div class="" *ngIf="services?.length">
    <h4 class="user-name mb-3 text-center">
      Other services by {{ selectedService?.profile?.user_name }}
    </h4>
    <div class="grid sm:grid-cols-2 md:grid-cols-3">
      <div *ngFor="let service of services">
        <app-individual-service-card [service]="service"></app-individual-service-card>
      </div>
    </div>
  </div>
</div>
