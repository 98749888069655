import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
// import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { apiUrlConst } from 'src/app/const/apiConst';
import { config } from 'src/config/config';
import { takeUntil } from 'rxjs/operators';
// import { ProfileService } from 'src/app/core/services/profile.service';
import { DOCUMENT } from '@angular/common';
import { JobService } from 'src/app/core/services/job.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private unsubscribe$ = new Subject();
  getUserInfoInProgress = false;
  getUserTypeInProgress = false;
  jobProsPlansAndAdOnsInProgress = false;
  getexperienceLevelInProgress = false;
  getSkillsOrIntrestInProgress = false;
  getFollowersAndFollowingsInProgress = false;
  getTalentServicePayRangeListInProgress = false;
  getPayRangeListInProgress = false;
  getBillingTypesInProgress = false;
  jobTypeListInProgress = false;
  categoriesListInProgress = false;
  userOnBoardStatusInProgress = false;
  getUserNotificationInProgress = false;

  userFeedData: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userType$: BehaviorSubject<any> = new BehaviorSubject('');
  onBoardStatus$: BehaviorSubject<any> = new BehaviorSubject('');
  userInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  signup$: BehaviorSubject<any> = new BehaviorSubject(null);

  talentServicePayRangeList$: BehaviorSubject<any> = new BehaviorSubject([]);
  skillsOrIntrest$: BehaviorSubject<any> = new BehaviorSubject([]);
  experienceLevelList$: BehaviorSubject<any> = new BehaviorSubject([]);
  payRangeList$: BehaviorSubject<any> = new BehaviorSubject([]);
  billingTypeList$: BehaviorSubject<any> = new BehaviorSubject([]);
  jobTypeList$: BehaviorSubject<any> = new BehaviorSubject([]);
  jobCategoryList$: BehaviorSubject<any> = new BehaviorSubject([]);
  jobprosPlans$: BehaviorSubject<any> = new BehaviorSubject([]);
  jobprosAdOn$: BehaviorSubject<any> = new BehaviorSubject([]);
  followersAndFollowings$: BehaviorSubject<any> = new BehaviorSubject([]);
  socketConnectionEstablished$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isUnreadNotification$: BehaviorSubject<number> = new BehaviorSubject(0);
  bottomSheetElement$: BehaviorSubject<string> = new BehaviorSubject('');
  isRecruiterUnreadMessageExist$: BehaviorSubject<any> = new BehaviorSubject(false);
  isNetworkUnreadMessageExist$: BehaviorSubject<any> = new BehaviorSubject(false);
  isClientUnreadMessageExist$: BehaviorSubject<any> = new BehaviorSubject(false);
  isUnreadMessageExist$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private storedIndex?: number;
  featureMenuToggle: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    public _http: HttpClient,
    // private router: Router,
    // private profileService: ProfileService,
    private jobService: JobService
  ) {}

  setIndex(index: number | undefined) {
    this.storedIndex = index;
  }

  getIndex(): number | undefined {
    return this.storedIndex;
  }

  getUserType() {
    if (!this.getUserTypeInProgress) {
      this.getUserTypeInProgress = true;
      this._http
        .get(
          config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getLoginedUserType
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (user: any) => {
            this.userType$.next(user.type);
            this.getUserTypeInProgress = false;
          },
          () => {
            this.getUserTypeInProgress = false;
          }
        );
    }
  }

  checkIfUnreadMessageRemaining() {
    const recruiterUnreadMessages$ = this.jobService.getRecruierUnreadMessages();
    const networkUnreadMessages$ = this.jobService.getNetworkUnreadMessages();
    const clientUnreadMessages$ = this.jobService.getClientUnreadMessages();
    forkJoin([recruiterUnreadMessages$, networkUnreadMessages$, clientUnreadMessages$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([res1, res2, res3]: any) => {
        let recruiterUnreadMessages = JSON.parse(JSON.stringify(res1.unreadMessages));
        let networkUnreadMessages = JSON.parse(JSON.stringify(res2.unreadMessages));
        let clientUnreadMessages = JSON.parse(JSON.stringify(res3.unreadMessages));
        let messageNotifications = [
          ...recruiterUnreadMessages,
          ...networkUnreadMessages,
          ...clientUnreadMessages
        ];
        this.isUnreadMessageExist$.next(messageNotifications?.length ? true : false);
        this.isRecruiterUnreadMessageExist$.next(recruiterUnreadMessages?.length ? true : false);
        this.isNetworkUnreadMessageExist$.next(networkUnreadMessages?.length ? true : false);
        this.isClientUnreadMessageExist$.next(clientUnreadMessages?.length ? true : false);
        this.getUnreadNotification();
      });
  }

  getUnreadNotification() {
    if (!this.getUserNotificationInProgress) {
      this.getUserNotificationInProgress = true;
      this._http
        .get(
          config.environmentMode(this.location().hostname).endPoint +
            apiUrlConst.unreadNotificationLength
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            this.isUnreadNotification$.next(res.data);
            this.getUserNotificationInProgress = false;
          },
          () => {
            this.getUserNotificationInProgress = false;
          }
        );
    }
  }

  getOnBoardStatus() {
    if (!this.userOnBoardStatusInProgress) {
      this.userOnBoardStatusInProgress = true;
      this._http
        .get(
          config.environmentMode(this.location().hostname).endPoint + apiUrlConst.on_baord_status
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (user: any) => {
            this.onBoardStatus$.next(user.status);
            this.userOnBoardStatusInProgress = false;
          },
          () => {
            this.userOnBoardStatusInProgress = false;
          }
        );
    }
  }

  jobProsPlansAndAdOns() {
    if (!this.jobProsPlansAndAdOnsInProgress) {
      this.jobProsPlansAndAdOnsInProgress = true;
      this._http
        .get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getPlanList)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (jobpros: any) => {
            this.jobprosPlans$.next(jobpros.plans);
            this.jobprosAdOn$.next(jobpros.addOns);
            this.jobProsPlansAndAdOnsInProgress = false;
          },
          () => {
            this.jobProsPlansAndAdOnsInProgress = false;
          }
        );
    }
  }

  getUserInfo() {
    if (!this.getUserInfoInProgress) {
      this.getUserInfoInProgress = true;
      this._http
        .get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getUserDetails)
        .pipe()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (information: any) => {
            this.userInfo$.next(information.user);
            this.getUserInfoInProgress = false;
          },
          () => {
            // this.profileService.destroyUserCredentials();
            // this.router.navigate(['/logout']);
            this.getUserInfoInProgress = false;
          }
        );
    }
  }

  getexperienceLevel() {
    if (!this.getexperienceLevelInProgress) {
      this.getexperienceLevelInProgress = true;
      this._http
        .get(
          config.environmentMode(this.location().hostname).endPoint + apiUrlConst.experienceLevel
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (experience: any) => {
            this.getexperienceLevelInProgress = false;
            this.experienceLevelList$.next(
              experience.data.map((item: any) => {
                return {
                  label: item.level + ' (' + item.job_experience + ')',
                  id: item._id
                };
              })
            );
          },
          () => {
            this.getexperienceLevelInProgress = false;
          }
        );
    }
  }

  getSkillsOrIntrest() {
    if (!this.getSkillsOrIntrestInProgress) {
      this.getSkillsOrIntrestInProgress = true;
      this._http
        .get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.skills)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (skills: any) => {
            this.getSkillsOrIntrestInProgress = false;
            this.skillsOrIntrest$.next(
              skills.data.map((skill: any) => {
                return { label: skill.name, id: skill._id };
              })
            );
          },
          () => {
            this.getSkillsOrIntrestInProgress = false;
          }
        );
    }
  }

  getFollowersAndFollowings() {
    if (!this.getFollowersAndFollowingsInProgress) {
      this.getFollowersAndFollowingsInProgress = true;
      this._http
        .get(
          config.environmentMode(this.location().hostname).endPoint +
            apiUrlConst.getFollowersandFollowings
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (x: any) => {
            this.followersAndFollowings$.next(x.data);
            this.getFollowersAndFollowingsInProgress = false;
          },
          () => {
            this.getFollowersAndFollowingsInProgress = false;
          }
        );
    }
  }

  getTalentServicePayRangeList() {
    if (!this.getTalentServicePayRangeListInProgress) {
      this.getTalentServicePayRangeListInProgress = true;
      this._http
        .get(
          config.environmentMode(this.location().hostname).endPoint +
            apiUrlConst.getTalentServicePayRangeList
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (payrange: any) => {
            this.getTalentServicePayRangeListInProgress = false;
            this.talentServicePayRangeList$.next(
              payrange.data.map((item: any) => {
                return { label: item.pay_range, id: item._id };
              })
            );
          },
          () => {
            this.getTalentServicePayRangeListInProgress = false;
          }
        );
    }
  }

  getPayRangeList() {
    if (!this.getPayRangeListInProgress) {
      this.getPayRangeListInProgress = true;
      this._http
        .get(
          config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getPayRangeList
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (payrange: any) => {
            this.getPayRangeListInProgress = false;
            this.payRangeList$.next(
              payrange.data.map((item: any) => {
                return { label: item.pay_range, id: item._id };
              })
            );
          },
          () => {
            this.getPayRangeListInProgress = false;
          }
        );
    }
  }

  getBillingTypes() {
    if (!this.getBillingTypesInProgress) {
      this.getBillingTypesInProgress = true;
      this._http
        .get(
          config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getBillingTypes
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            this.getBillingTypesInProgress = false;

            this.billingTypeList$.next(
              res.billigTypes.map((item: any) => {
                return { label: item.type, id: item._id };
              })
            );
          },
          () => {
            this.getBillingTypesInProgress = false;
          }
        );
    }
  }

  jobTypeList() {
    if (!this.jobTypeListInProgress) {
      this.jobTypeListInProgress = true;
      this._http
        .get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getJobTypes)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (jobtype: any) => {
            this.jobTypeListInProgress = false;

            this.jobTypeList$.next(
              jobtype.data.map((item: any) => {
                return { label: item.name, id: item._id };
              })
            );
          },
          () => {
            this.jobTypeListInProgress = false;
          }
        );
    }
  }

  categoriesList() {
    if (!this.categoriesListInProgress) {
      this.categoriesListInProgress = true;
      this._http
        .get(config.environmentMode(this.location().hostname).endPoint + apiUrlConst.getCategories)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (jobcategories: any) => {
            this.categoriesListInProgress = false;

            this.jobCategoryList$.next(
              jobcategories.data.map((item: any) => {
                return { label: item.name, id: item._id };
              })
            );
          },
          () => {
            this.categoriesListInProgress = false;
          }
        );
    }
  }

  location(): Location {
    return this._doc.location;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
