import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { retry, takeUntil } from 'rxjs/operators';
import { apiUrlConst } from 'src/app/const/apiConst';
import { loginValues } from 'src/app/core/const/login';
import { EmailVerificationPopupComponent } from 'src/app/shared/components/email-verification-popup/email-verification-popup.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Auth0Vars, Auth0Settings } from 'src/config/auth0Variables';
import { config } from 'src/config/config';
import { environment } from 'src/environments/env';
import { CommonService } from '../common.service';
import { EventEmitterService } from '../event-emitter.service';
import { ProfileService } from '../profile.service';
import { AUTHCONSTANTS } from 'src/app/const/auth.constants';

interface USER {
  created_at?: string;
  email?: string;
  password?: string;
  __v?: number;
  _id?: string;
  user_name?: string;
  user_type?: string;
  name?: string;
}

interface DecodedInformation {
  nickname?: string;
  name?: string;
  picture?: string;
  updated_at?: string;
  email?: string;
  email_verified?: boolean;
  iss?: string;
  aud?: string;
  iat?: number;
  exp?: number;
  sub?: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private unsubscribe$ = new Subject();
  private auth0Dev: any;
  private productionAuth?: import('auth0-js').WebAuth;
  jwtHelper = new JwtHelperService();
  decodedToken?: DecodedInformation | undefined | null;
  isLoggedIn = new BehaviorSubject<boolean>(false);
  userData = new BehaviorSubject<boolean>(false);
  user?: USER | null;
  zoneImpl?: NgZone;
  idToken?: string | null;

  public initAuth = async () => {
    import('auth0-js').then(({ WebAuth }) => {
      this.auth0Dev = new WebAuth({
        clientID: Auth0Vars.DEV_AUTH0_CLIENT_ID,
        domain: Auth0Vars.DEV_AUTH0_DOMAIN,
        responseType: 'id_token token',
        scope: 'openid profile user_metadata DELETE:CURRENT_USER',
      });

      this.productionAuth = new WebAuth({
        clientID: Auth0Vars.PRODUCTION_AUTH0_CLIENT_ID,
        domain: Auth0Vars.PRODUCTION_AUTH0_DOMAIN,
        responseType: 'id_token token',
        scope: 'openid profile user_metadata DELETE:CURRENT_USER ',
      });
    });
  };

  constructor(
    zone: NgZone,
    private commonService: CommonService,
    private profileService: ProfileService,
    private sharedService: SharedService,
    private _http: HttpClient,
    private eventEmitterService: EventEmitterService,
    private snack: SnackBarService,
    private dialog: MatDialog
  ) {
    this.zoneImpl = zone;
    this.initAuth();
  }

  /**
   * Login
   * @param user
   * @returns
   */
  public login(user: USER): Promise<any> {
    const origin = environment?.production ? 'productionAuth' : 'auth0Dev';
    return new Promise((resolve, reject) => {
      this.profileService
        .checkUserExistence(user.email)
        .subscribe((res: any) => {
          if (res.userExist) {
            this[origin].client.login(
              {
                realm: 'Username-Password-Authentication',
                scope: 'openid offline_access user_metadata role',
                username: user.email,
                password: user.password,
              },
              (error: any, authResult: { idToken: string } | null) => {
                if (!authResult?.idToken) {
                  this.snack.showMessage(error.description, true);
                  resolve(false);
                  return;
                }
                if (authResult !== null) {
                  this.onAuthentication(
                    authResult,
                    user,
                    AUTHCONSTANTS.LOGIN
                  ).then((message) => {
                    if (res) {
                      if (message === AUTHCONSTANTS.LOGINED_SUCCESS) {
                        this.profileService.storeUserCredentials(
                          authResult.idToken
                        );
                      }
                      resolve(message);
                    } else {
                      resolve(false);
                    }
                  });
                }
              }
            );
          } else {
            resolve(false);
            this.snack.showMessage(AUTHCONSTANTS.WRONG_EMAIL_PASSWORD, true);
          }
        });
    });
  }

  /**
   * Check if user is logedIn
   * @returns
   */
  loggedIn() {
    const token = this.profileService.loadUserCredentials();
    if (token) {
      if (!this.jwtHelper.isTokenExpired(token)) {
        this.decodedToken = this.jwtHelper.decodeToken(token);
        return true;
      } else {
        this.snack.showMessage(AUTHCONSTANTS.SESSION_EXPIRED, true);
        this.logout();
      }
    }
    return false;
  }

  isAdmin() {
    return false;
  }

  /**
   * Sign up
   * @param user
   * @returns
   */
  public signup(user: any): Promise<any> {
    const origin = environment?.production ? 'productionAuth' : 'auth0Dev';
    return new Promise((resolve, reject) => {
      this[origin]?.signupAndAuthorize(
        {
          connection: Auth0Vars.AUTH0_CONNECTION,
          scope: 'openid offline_access user_metadata DELETE:CURRENT_USER',
          username: user.email,
          email: user.email,
          password: user.password,
          userMetadata: {
            name: user.name,
            user_type: user.user_type,
            signup_form: window.origin,
            origin_used: origin,
          },
        },
        (err: any, authResult: any) => {
          if (err) {
            reject(err);
          }

          if (authResult) {
            this.onAuthentication(
              authResult,
              user,
              AUTHCONSTANTS.REGISTER
            ).then(
              () => {
                resolve(authResult);
              },
              (error) => {
                reject(error);
              }
            );
          }
        }
      );
    });
  }

  /**
   * Authenticate User on Signup or Login
   * @param authResult
   * @param user_Info
   * @param formType
   * @returns
   */
  onAuthentication(authResult: any, user_Info: any, formType: string) {
    const origin = environment?.production ? 'productionAuth' : 'auth0Dev';
    return new Promise((resolve, reject) => {
      this.idToken = authResult.idToken;
      this[origin]?.client.userInfo(
        authResult.accessToken,
        (
          error: any,
          profile: { email: any; email_verified: boolean; sub: string }
        ) => {
          if (error) {
            reject(error);
          } else {
            // user_Info.email = profile.email;
            const { email } = profile;

            // If Form Type is Login
            if (formType === AUTHCONSTANTS.LOGIN) {
              //If the email is not verified
              if (!profile.email_verified) {
                this.snack.showMessage(
                  `${AUTHCONSTANTS.A_VERIFICATION_EMAIL_SENT_TO}" ${email} ". ${AUTHCONSTANTS.PLEASE_VERIFY_YOUR_EMAIL}`,
                  true
                );
                reject(false);
              }
              //If the email is verified
              else if (profile.email_verified) {
                this._http
                  .get(
                    config.environmentMode(
                      this.commonService.location().hostname
                    ).endPoint + apiUrlConst.checkLoginedUserExistance,
                    { headers: this.setHeadersToken() }
                  )
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe({
                    next: (user: any) => {
                      if (user?.profile?.user_name === null) {
                        resolve(AUTHCONSTANTS.USER_NAME_NULL);
                      } else {
                        this.eventEmitterService.emitNavChangeEvent(
                          AUTHCONSTANTS.LOGGED_IN
                        );
                        this.user = user.profile;
                        authResult.profile = this.user;
                        this.zoneImpl?.run(
                          () => (this.user = authResult.profile)
                        );
                        resolve(AUTHCONSTANTS.LOGINED_SUCCESS);
                      }
                    },
                    error: (e) => {
                      resolve(false);
                    },
                  });
              }
            } else if (user_Info && formType === AUTHCONSTANTS.REGISTER) {
              this._http
                .post(
                  config.environmentMode(this.commonService.location().hostname)
                    .endPoint + apiUrlConst.candidateRegister,
                  user_Info,
                  { headers: this.setHeadersToken() }
                )
                .pipe(takeUntil(this.unsubscribe$), retry(4))
                .subscribe({
                  next: () => {
                    if (!profile.email_verified) {
                      const emailVerificationDialog = this.dialog.open(
                        EmailVerificationPopupComponent,
                        {
                          width: '400px',
                          height: 'h-fit',
                          maxHeight: '600',
                          data: {
                            message: `${AUTHCONSTANTS.A_VERIFICATION_EMAIL_SENT_TO}" ${email} ". ${AUTHCONSTANTS.PLEASE_VERIFY_YOUR_EMAIL}`,
                          },
                        }
                      );

                      if (emailVerificationDialog) {
                        emailVerificationDialog.afterClosed().subscribe(() => {
                          resolve(true);
                        });
                      }
                    }
                  },
                  error: (error) => {
                    this.getAccessToken()
                      .pipe(takeUntil(this.unsubscribe$))
                      .subscribe((grant) => {
                        this.deletAuth0User(profile.sub, grant.access_token)
                          .pipe(takeUntil(this.unsubscribe$))
                          .subscribe({
                            next: () => {
                              this.snack.showMessage(
                                'Registration Failed Please try again later',
                                true
                              );
                            },
                            error: (error: any) => {
                              this.snack.showMessage(
                                'Auth0 User Deletion failed.',
                                true
                              );
                            },
                          });
                      });
                    reject(error);
                  },
                });
            }
          }
        }
      );
    }).catch((e) => {
    });
  }

  /**
   * Logout
   */
  public logout() {
    this.isLoggedIn.next(false);
    this.sharedService.userType$.next('');
    this.sharedService.userInfo$.next(null);
    this.profileService.purchasedPlan.next({});
    this.idToken = null;
    this.profileService.destroyUserCredentials();
    this.zoneImpl?.run(() => (this.user = null));
    this.commonService.location().href = '/';
  }

  /**
   * Validate UserName it helps to choose unique username
   * @param user_name
   * @returns
   */
  validateUsername(user_name: string) {
    return new Promise((resolve, reject) => {
      this._http
        .post(
          config.environmentMode(this.commonService.location().hostname)
            .endPoint + apiUrlConst.ValidateUsername,
          { user_name }
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            resolve(true);
          },
          error: () => {
            resolve(false);
          },
        });
    });
  }

  /**
   * Change Password
   * @param email
   * @returns
   */
  changePassword(email: string) {
    const origin = environment?.production ? 'productionAuth' : 'auth0Dev';
    return new Promise((resolve, reject) => {
      this.validateEmail(email).subscribe(
        (email: any) => {
          const { message } = email;

          if (message === AUTHCONSTANTS.NOT_EXIST) {
            this.snack.showMessage(AUTHCONSTANTS.EMAIL_NOT_EXIST, true);
            reject(false);
          }
        },
        (err) => {
          if (err === AUTHCONSTANTS.EMAIL_ALREADY_EXIST) {
            this[origin]?.changePassword(
              {
                email,
                connection: loginValues.dbConnection,
              },
              (err: any, authResult: string) => {
                if (authResult) {
                  this.snack.showMessage(authResult, false);
                  resolve(true);
                }
                if (err) {
                  this.snack.showMessage(
                    AUTHCONSTANTS.ERROR_CHANGING_PASSWORD,
                    true
                  );
                  reject(false);
                }
              }
            );
          }
        }
      );
    });
  }

  /**
   * Update User Name
   * @param user_name
   * @returns
   */
  updateUserName(user_name: string) {
    return this._http
      .put(
        config.environmentMode(this.commonService.location().hostname)
          .endPoint + apiUrlConst.update_user_name,
        { user_name },
        { headers: this.setHeadersToken() }
      )
      .pipe(takeUntil(this.unsubscribe$));
  }

  getAccessToken(): Observable<any> {
    const auth = environment?.production
      ? Auth0Settings.PRODAUTH
      : Auth0Settings.DEVAUTH;
    const authUrl = auth.url;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = auth.body;
    return this._http.post<any>(authUrl, body, { headers });
  }

  deletAuth0User(userId: string, accessToken: any) {
    const domain = environment?.production
      ? Auth0Vars.PRODUCTION_AUTH0_DOMAIN
      : Auth0Vars.DEV_AUTH0_DOMAIN;
    const url = `https://${domain}/api/v2/users/${userId}`;
    const authToken = `Bearer ${accessToken}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: authToken,
    });
    return this._http.delete(url, { headers });
  }

  /**
   * Set Headers Token
   * @returns
   */
  setHeadersToken() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + `${this.idToken}`,
    });
  }

  /**
   * Validate Email
   * @param email
   * @returns
   */
  validateEmail(email: string) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.checkEmailExistance,
      { params: { email } }
    );
  }

  /**
   * Set Token
   */
  setToken() {
    this.profileService.storeUserCredentials(this.idToken);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
