import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dsar',
  templateUrl: './dsar.component.html',
  styleUrls: ['./dsar.component.scss']
})
export class DsarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    document.body.appendChild(script);
  }

}
