import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { TalentService } from 'src/app/core/services/talent.service';
import { PostService } from 'src/app/core/services/post.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { ImageService, ImageSnippet } from 'src/app/core/services/image.service';
import { userPlans } from '../../services/user-plans/user-plans.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
@Component({
  selector: 'app-individual-subscription',
  templateUrl: './individual-subscription.component.html',
  styleUrls: ['./individual-subscription.component.scss']
})
export class IndividualSubscriptionComponent implements OnInit {
  currentImageSlide = 0;
  updatedTitle: any;
  updatedPackage: any;
  private unsubscribe$ = new Subject();
  selectedPlan = 'Photos';
  user_name: string = '';
  selectedSubscription: any;
  selected: number;
  images: any = {};
  slides?: any[];
  pricingPackages!: any[];
  subscriptions: any[] = [];
  otherServices: any[] = [];
  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      1025: {
        slidesPerView: 4,
        slidesPerGroup: 4
      }
    }
  };
  activeSlide: number = 1;
  loginedUser: any;
  service_id: any;
  selectedPackage: any;
  isUpdated: boolean = false;
  allImages: any = [];

  constructor(
    private postService: PostService,
    private authService: AuthService,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private talentService: TalentService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private snack: SnackBarService,
    private sharedService: SharedService,
    private stripeService: paymentService,
    private imageService: ImageService,
    private userPlan: userPlans
  ) {
    this.selected = 0;
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
    }

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      if (params.id && params.profile) {
        // this.addViews(params.id);
        this.user_name = params.profile;
        this.service_id = params.id;
        this.talentService
          .getSingleSubscription(params.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (result: any) => {
              this.selectedSubscription = result.data;
              this.images.slides = this.selectedSubscription?.portfolios.map((portfolio: any) => {
                return {
                  path: portfolio.url,
                  type: portfolio.media_type
                };
              });
              this.allImages = this.selectedSubscription?.portfolios.map((portfolio: any) => {
                return {
                  content: portfolio.url,
                  cover_type: portfolio.cover_type,
                  format: portfolio?.media_type || 'image',
                  order_by: portfolio.order_by,
                  url: portfolio.url
                };
              });
              this.pricingPackages = this.selectedSubscription?.packages.map((pkg: any) => {
                return {
                  _id: pkg._id,
                  title: pkg.name,
                  price: pkg.price,
                  description: pkg.description,
                  features: pkg.offers.split(','),
                  price_id: pkg.price_id,
                  isMore: true,
                  type: pkg?.type ? pkg.type : '',
                  isBigger: pkg?.description?.length > 140
                };
              });
              this.selectedPackage =
                this.pricingPackages.find(obj => obj.type === 'photos') || this.pricingPackages[0];
              this.plans(result.data.profile.user_name);
            },
            () => {
              this.router.navigate(['/404']);
            }
          );
      } else {
        this.router.navigate(['/404']);
      }
    });
  }

  refreshSubscription(service: any) {
    this.talentService
      .getSingleSubscription(service._id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result: any) => {
          this.selectedSubscription = result.data;
          this.images.slides = this.selectedSubscription?.portfolios.map((portfolio: any) => {
            return {
              path: portfolio.url,
              type: portfolio.media_type
            };
          });
          this.allImages = this.selectedSubscription?.portfolios.map((portfolio: any) => {
            return {
              content: portfolio.url,
              cover_type: portfolio.cover_type,
              format: portfolio.media_type,
              order_by: portfolio.order_by,
              url: portfolio.url
            };
          });
          this.pricingPackages = this.selectedSubscription?.packages.map((pkg: any) => {
            return {
              title: pkg.name,
              price: pkg.price,
              description: pkg.description,
              features: pkg.offers.split(','),
              price_id: pkg.price_id,
              // billing_type: pkg.billing_type,
              isMore: true,
              type: pkg?.type ? pkg.type : '',
              isBigger: pkg?.description?.length > 140
            };
          });
          this.selectedPackage =
            this.pricingPackages.find(obj => obj.type === 'photos') || this.pricingPackages[0];
          this.plans(service.profile.user_name);
        },
        () => {
          this.router.navigate(['/404']);
        }
      );
  }

  plans(user_name: any) {
    if (!user_name) return;
    this.userPlan
      .publicPlans(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.plans) {
          this.subscriptions = plans.plans.map((plan: any) => ({
            ...plan,
            isMore: true,
            isBigger: plan?.description?.length > 140
          }));
        }
      });
  }

  // addViews(serviceId: any) {
  //   if (this.authService.loggedIn()) {
  //     const payload = {
  //       content_id: serviceId,
  //       type: 'service'
  //     };
  //     this.postService
  //       .updateViews(payload)
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((res: any) => {});
  //   }
  // }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.loginedUser = profileInfo;
      }
    });
  }

  viewProfile() {
    this.router.navigate(['/profile' + '/' + this.user_name]);
  }

  onSlideChange(swiper: any) {
    this.currentImageSlide = swiper.activeIndex;
    this.activeSlide = swiper.activeIndex + 1;
    this.changeDetector.detectChanges();
  }

  savePackageData(formData: any) {
    let index = 0;
    if (this.selectedPlan == 'Photos') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'photos');
    }
    if (this.selectedPlan == 'Videos') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'videos');
    }
    if (this.selectedPlan == 'Multimedia') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'multimedia');
    }
    this.updatedPackage = formData;
    Object.assign(this.updatedPackage, {
      price_id: this.selectedPackage.price_id
    });
    this.isUpdated = true;
  }

  getArray(size: number) {
    let array = [0];
    for (let i = 1; i < size; i++) {
      array.push(i);
    }
    return array;
  }

  updatePackage(selectedPackage: any) {
    let payload = {
      name: this.updatedPackage.name,
      description: this.updatedPackage.description,
      offers: this.updatedPackage.offers,
      price_id: this.updatedPackage.price_id
    };

    this.userPlan
      .updateSubscriptionPackage(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        selectedPackage.editMode = false;
        this.spinner.hide();
        if (res.status === 'success') {
          this.selectedPackage.title = payload.name;
          this.selectedPackage.description = payload.description;
          this.selectedPackage.features = payload.offers.split(',');
          this.snack.showMessage('Package updated successfully.', false);
        }
      });
  }

  selectPackage(planType: string) {
    this.selectedPlan = planType;
    if (this.selectedPlan == 'Photos') {
      this.selectedPackage =
        this.pricingPackages.find(obj => obj.type === 'photos') || this.pricingPackages[0];
    }
    if (this.selectedPlan == 'Videos') {
      this.selectedPackage =
        this.pricingPackages.find(obj => obj.type === 'videos') || this.pricingPackages[1];
    }
    if (this.selectedPlan == 'Multimedia') {
      this.selectedPackage =
        this.pricingPackages.find(obj => obj.type === 'multimedia') || this.pricingPackages[2];
    }
  }

  getSelectedPackageIndex() {
    let index = 0;
    if (this.selectedPlan == 'Photos') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'photos');
    }
    if (this.selectedPlan == 'Videos') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'videos');
    }
    if (this.selectedPlan == 'Multimedia') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'multimedia');
    }
    return index;
  }

  message() {
    if (this.selectedSubscription.profile._id) {
      const talentInfo = {
        email: this.selectedSubscription.profile.email,
        name: this.selectedSubscription.profile.name,
        profile_img_path: this.selectedSubscription.profile.profile_img_path,
        user_name: this.selectedSubscription.profile.user_name,
        user_type: this.selectedSubscription.profile.user_type,
        _id: this.selectedSubscription.profile._id
      };
      localStorage.setItem('talentInfo', JSON.stringify(talentInfo));
      this.router.navigate(['/dashboard/messages'], {
        queryParams: { tab: 3 }
      });
    }
  }

  updateTitle() {
    let payload = {
      subscriptionId: this.selectedSubscription._id,
      title: this.updatedTitle
    };
    this.userPlan
      .updateSubscriptionPackage(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.selectedSubscription.editTitle = false;
        this.spinner.hide();
        if (res.status === 'success') {
          this.selectedSubscription.title = payload.title;
          this.snack.showMessage('Title updated successfully.', false);
        }
      });
  }

  uploadFile(files: FileList, index: number) {
    index = this.currentImageSlide;
    let format: string;
    if (files.length === 0) {
      return;
    }

    if (files[0].type.includes('image') || files[0].type.includes('video')) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event: any) => {
        if (files[0].size >= 10485760) {
          this.snack.showMessage(`Size exceeded`, true);
          return;
        }
      };

      const file: File = files[0];
      reader.addEventListener('load', (event: any) => {
        const newImagePath = {
          content: reader.result !== null ? reader.result : undefined,
          cover_type: index == 0 ? 'true' : 'false',
          format: files[0].type.includes('image') ? 'image' : 'video',
          url: event.target.result,
          order_by: index,
          file
        };
        this.allImages[index] = newImagePath;
      });
    } else {
      this.snack.showMessage('File Type Not Supported', true);
      return;
    }
  }

  updateSubscriptionContent() {
    let promises: any[] = [];
    this.allImages.forEach(async (item: any, index: any) => {
      if (item?.file?.type) {
        promises.push(this.convertImages(item, index));
      }
    });
    Promise.all(promises).then(() => {
      let payload = {
        _id: this.selectedSubscription._id,
        // title: this.selectedSubscription.title,
        images: this.allImages.map((portfolio: any) => {
          return {
            content: portfolio.content,
            cover_type: portfolio.cover_type,
            media_type: portfolio?.media_type ? portfolio.media_type : portfolio?.format,
            order_by: portfolio.order_by,
            url: portfolio.url
          };
        })
      };
      this.userPlan
        .updateUserPlan(payload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.refreshSubscription(this.selectedSubscription);
          this.images.editMode = false;
          this.spinner.hide();
          if (res.status === 'success') {
            // this.selectedSubscription.title = payload.title;
            this.snack.showMessage('Content updated successfully.', false);
          }
        });
    });
  }

  async convertImages(item: any, index: any) {
    await new Promise(async resolve => {
      await this.imageService
        .imageConvertion(new ImageSnippet(item.url, item.file))
        .then((imageUri: any) => {
          const newImagePath = {
            order_by: index,
            cover_type: index == 0 ? 'true' : 'false',
            media_type: item.format ? item.format : item.media_type,
            url: imageUri[0]
          };
          this.allImages[index] = newImagePath;
        });
      resolve(true);
    });
  }

  pay() {
    const params = {
      mode: 'subscription',
      cancel_url: `${window.origin}/failed`,
      success_url: `${window.origin}/success`,
      line_items: [
        {
          price: this.selectedPackage?.price_id,
          quantity: 1
        }
      ]
    };
    if (this.authService.loggedIn()) {
      if (this.selectedSubscription.profile_id == this.loginedUser._id)
        return this.snack.showMessage(`You can't purchase your own subscription`, true);
      this.spinner.show();
      this.stripeService
        .purchaseUserCreatedPlan(
          params,
          this.selectedSubscription.profile_id,
          this.loginedUser._id,
          this.selectedPackage._id,
          this.selectedPackage.title,
          'month',
          'Subscription'
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res?.accountLink?.url) {
              this.commonService.location().href = res.accountLink.url;
            } else {
              this.snack.showMessage(
                'Unable to connect to Stripe at the moment, Please try again later',
                true
              );
            }
          },
          (error: HttpErrorResponse) => {
            this.spinner.hide();
            this.snack.showMessage(`${error} `, true);
          }
        );
    } else {
      this.snack.showMessage('Please login to Purchase', true);
    }
  }

  activeInactiveSubscription(isActive: any) {
    let payload = {
      _id: this.selectedSubscription._id,
      active: isActive
    };
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: {
        message: `Are you sure you want to ${isActive ? 'Activated' : ' Deactivate'} this Subscription?`
      }
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          this.talentService
            .patchSubscription(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              () => {
                this.selectedSubscription.active = isActive;
                this.snack.showMessage(
                  this.selectedSubscription.active
                    ? 'Subscription Activated Sucessfully'
                    : 'Subscription Deactivated Sucessfully',
                  false
                );
              },
              () => {
                this.snack.showMessage(
                  `Failed to ${
                    this.selectedSubscription.active ? ' Active ' : ' Delete '
                  } Subscription Service`,
                  true
                );
              }
            );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
