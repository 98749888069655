<div class="dashboard-service-preference bg-white p-4 lg:p-10">
  <p class="text-black montserrat-bold">Business Settings</p>
  <p class="s black-light my-2">Include operations details</p>
  <mat-divider class="w-full"></mat-divider>
  <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
  </ngx-spinner>
  <form [formGroup]="formGroup" class="mt-1 lg:mt-4">
    <div class="mt-6">
      <p class="montserrat-bold text-black">City</p>
      <div class="flex items-center -mt-4 -mb-6">
        <mat-form-field>
          <input class="text-sm city-input" maxlength="256" type="text" aria-label="Location" #locationSearchInput matInput
            formControlName="location" [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions"
              value="{{ option.city_name }} - {{ option.state_name }} - {{option.country_code}}"
              (click)="setLocation(option)">

              {{ option.city_name }}{{ option.state_name ? ' - ' + option.state_name : '' }} {{
              option.country_code ? ' - ' + option.country_code : '' }}

            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <mat-error *ngIf="errorHandling('location', 'required') "
        [class]="errorHandling('location', 'required') ? 'pb-4 pt-4' : ''">
        <p class="s red">Location can not be empty.</p>
      </mat-error>
    </div>
    <div *ngIf="type== userTypeConstant.company" class="mt-6">
      <p class="montserrat-bold text-black">Address</p>
      <input maxlength="513" class="input-form text-sm w-full px-2 mt-2" placeholder="Enter Street Address"
        formControlName="address" />
      <mat-error *ngIf="errorHandling('address', 'maxlength') "
        [class]="errorHandling('title', 'maxlength') ? 'pb-4 pt-4' : ''">
        <p class="s red">Address can not exceed 512 characters!</p>
      </mat-error>
    </div>
    <div *ngIf="type==userTypeConstant.company" class="mt-6">
      <p class="montserrat-bold text-black">Phone number</p>
      <input type="number" onKeyPress="if(this.value.length==16) return false;" class="input-form text-sm w-full px-2 mt-2"
        placeholder="Enter your business phone number" formControlName="phone_number" />
    </div>
    <div *ngIf="type==userTypeConstant.company" class="mt-6">
      <p class="montserrat-bold text-black">Website</p>
      <input maxlength="513" class="input-form text-sm w-full px-2 mt-2" placeholder="https://yourdomain.com"
        formControlName="website" />
      <mat-error *ngIf="errorHandling('website', 'maxlength')"
        [class]="errorHandling('website', 'maxlength') ? 'pb-4 pt-4' : ''">
        <p class="s red">Website can not exceed 512 characters!</p>
      </mat-error>
      <mat-error *ngIf="validateWebsite('website')" [class]="validateWebsite('website') ? 'pb-4 pt-4' : ''">
        <p class="s red">Please enter valid url</p>
      </mat-error>
    </div>
    <div class="mt-6 mb-4">
      <p class="montserrat-bold text-black">Experience Level</p>
      <div class="input-form flex items-center px-4 mt-2">
        <mat-select placeholder="Select Experience" class="city-input" formControlName="experience_level">
          <mat-option *ngFor="let experience of experienceLists" [value]="experience.id">
            {{ experience.label }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <button mat-button class="button outlined black"
      [class]="!savingBS || (type==userTypeConstant.company && validateWebsite('website')) || errorHandling('license_number', 'maxlength') || errorHandling('website', 'maxlength') ||errorHandling('location', 'required') || errorHandling('address', 'required') || errorHandling('address', 'maxlength') || errorHandling('phone_number', 'maxlength') || errorHandling('phone_number', 'required')  ? 'disabled' : ''"
      [disabled]="!savingBS || (type==userTypeConstant.company && validateWebsite('website')) || errorHandling('license_number', 'maxlength') || errorHandling('website', 'maxlength') || errorHandling('location', 'required') || errorHandling('address', 'required') || errorHandling('address', 'maxlength') || errorHandling('phone_number', 'maxlength') || errorHandling('phone_number', 'required')"
      (click)="saveTalent()">Save</button>
    <div class="my-4 lg:my-9">
      <p class="montserrat-bold text-black mb-2">Hours of operation</p>
      <div class="mt-3 lg:mt-6" *ngFor="let item of operationHours">
        <app-operation-hour-unit [day]="item" (setHour)="setHour($event)"></app-operation-hour-unit>
      </div>
    </div>
    <button mat-button class="button outlined black" [class]="!savingOH ? 'disabled' : ''" [disabled]="!savingOH "
      (click)="saveOperationHours()">
      Save
    </button>
  </form>
</div>