export const loginValues={
  title:'Login',
  hometitle:'Home',
  page:'page',
  formTitle:'LOGIN TO YOUR ACCOUNT',
  facebookLogin:'Login with Facebook',
  googleLogin:'Login with Google ',
  or:'or',
  rememberMe:'Remember me',
  forgotPassword:'Forgot Password',
  login:'Login',
  donthaveAccount:'Don’t have an account ? ',
  registerNow:' Register Now ',
  changePassword:'Forgot Password?',
  suggesstion:'In case you are using a public/shared computer we recommend that you logout to prevent any un-authorized access to your account',
  faceBookConnection:'facebook',
  googlConnection:'google-oauth2',
  dbConnection: 'Username-Password-Authentication'
}
