<div class="comment-card w-full py-2">
  <div class="comment-card_header flex relative" *ngIf="data.userInfo[0]">
    <div class="w-full">
      <div class="flex items-center cursor-pointer">
        <img
          [src]="data.userInfo[0].profile_img_path"
          class="comment_user-avatar w-9 h-9 rounded-full overflow-hidden"
          onerror="this.src='assets/images/no-image.png'"
        />
        <div class="comment-card-header_main flex items-center">
          <p
            class="ml-2 cursor-pointer font-bold hover:underline"
            (click)="showCommenterProfile(data.userInfo[0])"
          >
            {{ data.userInfo[0].user_name }}
          </p>
        </div>
      </div>
      <div
        [class]="data?._id == commentId ? 'selected-comment' : ''"
        class="pl-9 mt-2 md:w-full flex items-end"
      >
        <ng-container *ngIf="!isEditing; else editMode">
          <pre
            class="s montserrat-medium mx-2 words"
            [innerHTML]="data.comment | mentionLink | hashtagLink"
          ></pre>
          <span class="flex">
            <mat-icon
              class="ml-3 text-base blue edit-icon cursor-pointer"
              *ngIf="data?.user_id == profile?._id"
              (click)="toggleEditMode(data?.comment)"
              >edit</mat-icon
            >
            <mat-icon
              class="ml-1 text-base blue edit-icon cursor-pointer"
              *ngIf="data?.user_id == profile?._id"
              (click)="deleteComment(data, false)"
              >delete</mat-icon
            >
          </span>
        </ng-container>

        <!-- Edit mode content -->
        <ng-template #editMode>
          <textarea
            type="text"
            class="s w-5/6 montserrat-medium mx-2 words h-20 rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            [(ngModel)]="editedComment"
            (keydown.enter)="saveEditedComment(data)"
          ></textarea>
          <mat-icon
            *ngIf="editedComment?.trim()?.length"
            class="cursor-pointer"
            (click)="saveEditedComment(data)"
            >check</mat-icon
          >
          <mat-icon class="cursor-pointer" (click)="cancelEditMode()"
            >close</mat-icon
          >
        </ng-template>
      </div>
      <p class="time-wrapper ml-11 s">{{ formatJobDate(data.created_at) }}</p>
    </div>

    <div
      class="absolute bg-white md:relative flex flex-col items-end right-0 -top-2 md:-mr-2 md:ml-auto z-10"
    >
      <div class="flex items-center">
        <button
          class="s border-2 border-[#2887ed] hover:bg-[#2887ed] text-[#2887ed] hover:text-white rounded px-2 py-1 font-bold"
          matTooltip="Reply Comment"
          (click)="showReplyForm = !showReplyForm; selectComment(data)"
        >
          Reply
        </button>
        <button mat-icon-button (click)="onLike()">
          <mat-icon [class]="isFavor ? 'red' : ''">
            {{ isFavor ? "favorite" : "favorite_border" }}
          </mat-icon>
          <p class="s mr-2">
            {{ data?.likeDetails?.length }}
            {{ data?.likeDetails?.length <= 1 ? "Like" : "Likes" }}
          </p>
        </button>
      </div>
    </div>
  </div>

  <div
    class="comment-reply-container"
    *ngIf="data?.userInfo[0] && data?.replyCommentDetails?.length > 0"
  >
    <mat-expansion-panel [expanded]="isExpanded" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          ____________
          {{
            data.replyCommentDetails.length <= 1 ? "View reply" : "View replies"
          }}
          ({{ data.replyCommentDetails.length }})
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="my-2" *ngFor="let replier of data.replyCommentDetails">
        <div class="cursor-pointer" *ngIf="replier.userInfo[0]">
          <div class="flex items-center">
            <img
              [src]="
                replier.userInfo[0].profile_img_path != ''
                  ? replier.userInfo[0].profile_img_path
                  : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs'
              "
              class="comment_user-avatar w-9 h-9 rounded-full overflow-hidden"
              onerror="this.src='assets/images/no-image.png'"
            />
            <p
              class="ml-2 cursor-pointer font-bold hover:underline"
              (click)="showCommenterProfile(replier.userInfo[0])"
            >
              {{ replier.userInfo[0].user_name }}
            </p>
            <button
              mat-button
              class="button outlined black reply-comment-btn"
              matTooltip="Reply Comment"
              (click)="showReplyForm = !showReplyForm; selectComment(replier)"
            >
              Reply
            </button>
          </div>
          <div
            [class]="replier._id == commentId ? 'selected-comment' : ''"
            class="ml-9 flex items-end"
          >
            <ng-container *ngIf="!replier.isReplyEditing; else replyEditMode">
              <pre class="s montserrat-medium mx-2 words flex items-center" [innerHTML]="replier.comment | mentionLink | hashtagLink">
                <!-- {{ replier.comment  }}  -->
                <span>
                  <mat-icon
                    class="ml-3 text-base blue edit-icon cursor-pointer"
                    *ngIf="replier?.user_id == profile?._id"
                    (click)="replyToggleEditMode(replier?.comment, replier)"
                    >edit</mat-icon
                  >
                  <mat-icon
                    class="ml-1 text-base blue edit-icon cursor-pointer"
                    *ngIf="replier?.user_id == profile?._id"
                    (click)="deleteComment(replier, true)"
                    >delete</mat-icon
                  >
                </span>
              </pre>
            </ng-container>

            <!-- Edit mode content -->
            <ng-template #replyEditMode>
              <textarea
                type="text"
                class="s montserrat-medium mx-2 words sm:w-80 h-20 rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                [(ngModel)]="editedComment"
                (keydown.enter)="replySaveEditedComment(replier)"
              ></textarea>
              <mat-icon
                *ngIf="editedComment?.trim()?.length"
                class="cursor-pointer"
                (click)="replySaveEditedComment(replier)"
                >check</mat-icon
              >
              <mat-icon
                class="cursor-pointer"
                (click)="replyCancelEditMode(replier)"
                >close</mat-icon
              >
            </ng-template>
          </div>
          <p class="time-wrapper ml-11 s">
            {{ formatJobDate(replier.created_at) }}
          </p>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

  <div *ngIf="showReplyForm" class="w-full px-4 mt-1 mb-2.5 relative">
    <mat-form-field class="mention-list-wrapper">
      <textarea
        appMentionTrigger
        [mentionConfig]="mentionService.mentionConfig"
        (itemSelected)="mentionSelect()"
        [mentionListTemplate]="mentionListTemplate"
        matInput
        maxlength="257"
        cdkTextareaAutosize
        placeholder="Write a reply"
        [(ngModel)]="comment"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="2"
        #textarea
        #autosize="cdkTextareaAutosize"
      ></textarea>
      <ng-template #mentionListTemplate let-item="item">
        <div class="flex items-center">
          <div class="rounded-full h-10 w-10 overflow-hidden">
            <img
              *ngIf="item.isShowImage"
              class="rounded-full h-full w-full overflow-hidden"
              onerror="this.src='assets/images/no-image.png'"
              [src]="item.profile_img_path"
              alt=""
            />
          </div>
          &nbsp;
          <span class="ml-2">{{ item.user_name }}</span>
        </div>
      </ng-template>
      <mat-error
        *ngIf="comment?.length > 256"
        [class]="comment.length > 256 ? 'pb-2 pt-1x' : ''"
      >
        <p class="s red">Reply can not exceed more than 256 characters!</p>
      </mat-error>
    </mat-form-field>
    <div
      *ngIf="checkWhitespaces(textarea.value).length"
      class="flex items-center absolute right-5 h-full top-1 comment-btn-position"
    >
      <button
        [disabled]="!comment"
        mat-button
        class="button outlined black"
        (click)="onReply()"
      >
        Reply
      </button>
    </div>
  </div>
</div>
