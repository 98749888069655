import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-operation-hour-unit',
  templateUrl: './operation-hour-unit.component.html',
  styleUrls: ['./operation-hour-unit.component.scss']
})
export class OperationHourUnitComponent implements OnInit {
  fromTime: any;
  toTime: any;
  @Input() day: any;
  @Output() setHour: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.day = '';
  }

  ngOnInit(): void { }

  setTime(evt: any, field: string) {
    if (field == 'from_time') {
      this.fromTime = evt;
    }
    if (field == 'to_time') {
      this.toTime = evt;
    }
    this.onChange(evt, field, this.day.day_name);
  }

  setMarkDay(evt: any) {
    this.day.from_time = '12:00 AM'
    this.day.to_time = '12:00 PM'
    this.onChange(evt.checked, 'closed', this.day.day_name);
  }

  onChange(value: any, field: string, day_name: string) {
    this.setHour.emit({ value, field, day_name });
  }

  onClose(field: any) {
    if (!this.fromTime && field == 'from_time') {
      this.onChange('12:00 AM', field, this.day.day_name);
    }
    if (!this.toTime && field == 'to_time') {
      this.onChange('12:00 PM', field, this.day.day_name);
    }

  }
}
