<div *ngIf="!editF" class="px-2 py-4">
  <div class="mt-0.5 flex items-center w-full justify-between">
    <p class="montserrat-bold mt-0.5 whitespace-nowrap truncate w-fit-content-wraper tab-size">{{achievement.title}}</p>
    <button mat-icon-button (click)="edit()">
      <mat-icon class="blue">edit</mat-icon>
    </button>
  </div>
  <p class="gray-dark mt-1.5 s">{{getDate(achievement.start_date)}} - {{getDate(achievement.end_date)}}</p>
  <p class="mt-4 w-full border-none px-0 py-0 whitespace-nowrap truncate w-fit-content-wraper tab-size text-sm">{{achievement.description}}</p>
</div>
<div *ngIf="editF" class="px-2 py-4">
  <app-achievement-form
    [achievement]="achievement"
    (save)="saveAchievement($event)"
    (cancel)="cancel()"
    (remove)="onDelete()"
  ></app-achievement-form>
</div>
