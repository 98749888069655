import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { CoreModule } from 'src/app/core/core.module';
import { ModulesModule } from 'src/app/modules/modules.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { GeneralComponent } from 'src/app/layouts/general/general.component';
import { AuthComponent } from 'src/app/layouts/auth/auth.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { StripePaymentSuccessComponent } from './stripe/stripe-payment-success/stripe-payment-success.component';
import { StripePaymentFailedComponent } from './stripe/stripe-payment-failed/stripe-payment-failed.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './core/services/token-interceptor.service';

const config: SocketIoConfig = { url: 'http://localhost:4200', options: {} };
@NgModule({
  declarations: [
    AppComponent,
    GeneralComponent,
    AuthComponent,
    StripePaymentSuccessComponent,
    StripePaymentFailedComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CoreModule,
    FormsModule,
    SocketIoModule.forRoot(config),
    ModulesModule,
    SharedModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
