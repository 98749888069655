<div class="pricing-section">
    <div class="flex flex-col justify-center items-center">
      <p *ngIf="this.authService.loggedIn()" class="montserrat-bold black-dark join-heading comparePlans-text mt-6 text-center">Perks so good you will never <br>
        need to go anywhere else
      </p>
      <p *ngIf="!this.authService.loggedIn()" class="montserrat-bold black-dark join-heading comparePlans-text mt-6 text-center"> You deserve better options <br> to earn on your own terms
      </p>
    <p class="font-style black-dark my-4">Compare Memberships</p>
    </div>
    <div class="mb-8 pt-1 flex rounded-full justify-center">
        <button class="payment-section_tab border-plus-text bg-gray payment-section_tab px-4 py-1 rounded-full mr-4"
          [class]="forIndividual ? 'bg-blu shadow-lg' : ''" (click)="forIndividual = true">
          <p class="montserrat-medium" [class]="!forIndividual ? 'gray-dark' : 'black-dark'">Individuals</p>
        </button>
        <button
          class="payment-section_tab payment-section_tab bg-gray border-plus-text payment-section_tab px-4 py-1 rounded-full"
          [class]="!forIndividual ? 'bg-blu shadow-lg' : ''" (click)="forIndividual = false">
          <p class="montserrat-medium" [class]="forIndividual ? 'gray-dark' : 'black-dark'">Companies</p>
        </button>
      </div>
    <div class="flex justify-center">
        <div class="membership-card h-full w-full max-w-[1200px]">
            <img class="image-container" [src]="forIndividual ? '../../../../../assets/images/Individuals.png' : '../../../../../assets/images/Company.png'" alt="">
        </div>
    </div>
    <div class="block mt-10 mb-0 md:mb-4 lg:mb-10">
      <mat-divider class="w-full"></mat-divider>
    </div>
</div>