<div class="service-modal h-full bg-white flex flex-col justify-between -m-6">
  <div class="header w-full relative">
    <h4 class="montserrat-bold py-6 text-center text-white items-center flex">
      Dispute Details
    </h4>
    <div class="absolute right-0 top-0 mt-1">
      <button mat-icon-button (click)="onClose()">
        <mat-icon class="text-white">cancel</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="service-modal_content md:px-10 py-6">
    <div class="">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor="black-dark"
        size="medium"
        color="red"
        type="ball-spin-clockwise"
        [fullScreen]="true"
        class="job-list-spinner"
      >
      </ngx-spinner>
      <div>
        <h6 class="montserrat-bold mb-4">
          Provide the maximum details that can help to resolve your case
        </h6>
        <label class="black-dark montserrat-bold">Description</label>
        <textarea
          [(ngModel)]="description"
          maxlength="257"
          required
          class="input-form w-full mt-2 px-4"
          placeholder="Description"
        ></textarea>
      </div>
      <div class="mt-4">
        <label class="black-dark montserrat-bold">Attachments</label>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">
            Attachments
          </label>
          <input
            (change)="onFileChange($event)"
            type="file"
            class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <input
            (change)="onFileChange($event)"
            type="file"
            class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <input
            (change)="onFileChange($event)"
            type="file"
            class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <input
            (change)="onFileChange($event)"
            type="file"
            class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <input
            (change)="onFileChange($event)"
            type="file"
            class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
  </div>
  <div class="w-full flex justify-between items-center px-4 h-20 border-2">
    <button
      mat-flat-button
      class="w-36 button outlined black"
      (click)="onClose()"
    >
      Cancel
    </button>
    <button
      [disabled]="!attachments.length || !description.trim().length"
      [class]="
        !attachments.length || !description.trim().length ? 'disabled' : ''
      "
      mat-button
      class="button outlined black w-36"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</div>
