import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { JobService } from 'src/app/core/services/job.service';
import { TalentService } from 'src/app/core/services/talent.service';

@Component({
  selector: 'app-talents',
  templateUrl: './talents.component.html',
  styleUrls: ['./talents.component.scss'],
})
export class TalentsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  talentFilters = { offset: 0, limit: 10 };
  talents: any[];
  protected _onDestroy = new Subject();
  totalcount?: number;
  totalAvailableTalents?: number;

  constructor(
    private spinner: NgxSpinnerService,
    public _jobsService: JobService,
    private talentService: TalentService,
    private commonService: CommonService
  ) {
    this.talents = [];
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.getWindow()?.scroll(0, 0);
    }, 1000);
  }

  isFilterApplied(isFilter: any) {
    if (!isFilter) {
      this.getTalents();
    }
  }

  getTalents() {
    this.spinner.show();
    this.talentService
      .getAllTalents({ offset: 0, limit: 10 })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.talents = res?.data;
        this.totalcount = res?.data?.length;
        this.totalAvailableTalents = res?.totalAvailableTalents;
        this.spinner.hide();
      });
  }

  clearFilter(clear: any) {
    if (clear) {
      this.getTalents();
    }
  }

  onScrollDown() {
    this.spinner.show();
    this.talentFilters.offset = this.talentFilters.offset + 10;
    this.talentService
      .getAllTalents(this.talentFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.talents = [...this.talents, ...res.data];
        this.totalAvailableTalents = res.totalTalents;
        this.spinner.hide();
      });
  }

  getFilteredList(options: any) {
    this.spinner.show();
    this.talentFilters.offset = 0;
    this.talentService
      .getFilteredTalents(options)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (options.offset === 0) {
          this.talents = [];
          this.talents = res?.data;
        } else {
          this.talents = [...this.talents, ...res.data];
        }
        this.spinner.hide();
      });
  }

  ngOnDestroy() {
    this.spinner.hide();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this._onDestroy.next(true);
    this._onDestroy.complete();
  }
}
