import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReplayObject } from 'src/app/core/models';
import { TwitchService } from 'src/app/core/services/twitchService';

@Component({
  selector: 'app-dashboard-livestreams-replays',
  templateUrl: './dashboard-livestreams-replays.component.html',
  styleUrls: ['./dashboard-livestreams-replays.component.scss']
})
export class DashboardLivestreamsReplaysComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Output() totalStreams: EventEmitter<any> = new EventEmitter();
  message: string = '';
  replayOptionArr = [
    { key: 'Newest', value: 'Newest' },
    { key: 'popular', value: 'Most popular' }
  ]
  replayOption: any;
  replays: ReplayObject[] = [];

  constructor(private twitchService: TwitchService) {
    this.replayOption = 'popular'
  }

  ngOnInit(): void {
  }

  getLiveStreams(profile_id: any) {
    // this.spinner.show();
    this.twitchService.getStreams(profile_id).pipe(takeUntil(this.unsubscribe$)).subscribe((LiveStreams: any) => {
      this.message = LiveStreams.message
      this.replays = LiveStreams.data?.data;
      this.totalStreams.emit(LiveStreams.data?.data?.length);
      //  // this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
