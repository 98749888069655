<form
  [formGroup]="planForm"
  (ngSubmit)="addPlan()"
  class="subscription-package-modal flex flex-col relative bg-white"
>
  <div class="service-modal h-full bg-white flex flex-col justify-between">
    <div class="header w-full relative">
      <button *ngIf="isLastStep" (click)="goToPrev(stepper)" class="absolute back-btn">
        <mat-icon class="arrow-back text-white">west</mat-icon>
      </button>
      <img
        *ngIf="!isLastStep"
        class="w-10 absolute top-2 left-3"
        src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png "
        alt=""
      />

      <h4 class="montserrat-bold py-6 text-center text-white items-center flex subscribe-tittle">
        {{ data?.editmode ? 'Edit Subscription' : isLastStep ? packageName : 'Paid Subscription' }}
      </h4>
      <div class="absolute right-0 top-0 mt-1">
        <button mat-icon-button (click)="close($event)">
          <mat-icon class="text-white">close</mat-icon>
        </button>
      </div>
    </div>
    <mat-horizontal-stepper class="subscription-modal" labelPosition="bottom" #stepper>
      <mat-step>
        <div class="">
          <div>
            <input
              id="searchServiceIdx"
              [formControl]="serviceIndex"
              class="outline-none input-form w-full mt-2 px-4"
              placeholder="Subscriptions"
            />
          </div>
          <div
            *ngIf="selectedServiceCategory && selectedServiceCategory.length > 0"
            class="flex flex-wrap mt-4"
          >
            <div
              class="tag m-1 flex items-center gap-1"
              *ngFor="let item of selectedServiceCategory"
            >
              {{ item.label }}
              <mat-icon class="text-white close-icon" (click)="removeService(item.id)"
                >cancel</mat-icon
              >
            </div>
          </div>
          <div class="mt-4">
            <div *ngFor="let group of filteredCategories" class="mb-2">
              <app-service-category-expansion
                [group]="group"
                (onChange)="updateCategory($event)"
                [activeList]="selectedServiceCategory"
              ></app-service-category-expansion>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <mat-divider></mat-divider>
        <div class="service-modal_content">
          <div class="">
            <ngx-spinner
              bdOpacity="0.9"
              bdColor="black-dark"
              size="medium"
              color="red"
              type="ball-spin-clockwise"
              [fullScreen]="true"
              class="job-list-spinner"
            >
            </ngx-spinner>
            <div class="flex items-center justify-between mt-6">
              <div class="flex items-center">
                {{ this.selectedServiceCategory[0]?.label }}
                <p class="blue montserrat-bold cursor-pointer ml-2" (click)="goToPrev(stepper)">
                  Change
                </p>
              </div>
              <button
                mat-button
                class="montserrat-medium mt-md button black upload-btn-size"
                (click)="uploadButtonClick()"
              >
                Upload
              </button>
            </div>
            <div>
              <mat-form-field class="w-full">
                <input
                  maxlength="257"
                  matInput
                  class="input-form px-4 py-3.5 w-full"
                  placeholder="Title"
                  formControlName="title"
                />
                <mat-error
                  *ngIf="errorHandling('title', 'required')"
                  [class]="errorHandling('title', 'required') ? 'pb-4' : ''"
                >
                  <p class="s red">You must provide a title.</p>
                </mat-error>
                <mat-error
                  *ngIf="errorHandling('title', 'maxlength')"
                  [class]="errorHandling('title', 'maxlength') ? 'pb-4' : ''"
                >
                  <p class="s red">Title can not exceed 256 characters!</p>
                </mat-error>
              </mat-form-field>
              <mat-error
                *ngIf="errorHandling('title', 'maxlength')"
                [class]="errorHandling('title', 'maxlength') ? 'pb-4' : ''"
              >
                <p class="s red">Title can not exceed 256 characters!</p>
              </mat-error>
            </div>
            <div>
              <!-- IMAGES SECTION -->
              <label class="h-full" [htmlFor]="'fileInput'">
                <div class="w-full h-full relative slide-wrapper">
                  <swiper [config]="config" [pagination]="{ clickable: true }">
                    <ng-template
                      swiperSlide
                      *ngFor="let portfolio of getArray(swaperUploadSize); index as i"
                    >
                      <div
                        (click)="imageIndex = i; updateImage = true"
                        class="relative w-full h-full"
                      >
                        <ng-container *ngIf="allImages[i] && allImages[i]?.media_type == 'image'">
                          <img [src]="allImages[i]?.url" class="h-full img-mx-height mx-auto" />
                          <mat-icon
                            (click)="removeImage($event, i)"
                            class="text-black absolute top-0 right-0 z-10"
                            >cancel</mat-icon
                          >
                        </ng-container>
                        <ng-container *ngIf="allImages[i] && allImages[i]?.media_type == 'video'">
                          <video
                            [src]="allImages[i]?.url + '#t=0.1'"
                            preload="metadata"
                            class="h-full img-mx-height mx-auto"
                            controls
                            playsinline
                            webkit-playsinline
                          ></video>
                          <mat-icon
                            (click)="removeImage($event, i)"
                            class="text-black absolute top-0 right-0 z-10"
                            >cancel</mat-icon
                          >
                        </ng-container>
                        <div
                          *ngIf="!allImages[i]"
                          class="relative w-full h-full border border-dashed border-gray-400 flex items-center"
                        >
                          <img
                            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/j7m3z.png"
                            class="mx-auto opacity-30 max-widthHeight object-scale-down"
                          />
                        </div>
                      </div>
                    </ng-template>
                  </swiper>
                </div>
              </label>
              <!-- IMAGES SECTION END -->
            </div>
          </div>

          <div class="mt-2">
            <div class="flex flex-col gap-2">
              <p class="montserrat-bold">Categories</p>
            <div class="flex flex-col gap-2">
                <mat-checkbox
                formControlName="featured"
                class="pt-1"
                [color]="'primary'"
                (change)="onCheckboxChange('FEATURED',$event)"
                [value]=""
              >
                <p>Featured</p>
              </mat-checkbox>
                <mat-checkbox
                formControlName="sponsored"
                class="pt-1"
                [color]="'primary'"
                (change)="onCheckboxChange('SPONSORED', $event)"
                [value]=""
              >
                <p>Sponsored</p>
              </mat-checkbox>
            </div>
            </div>
          </div>

          <div *ngIf="!data?.editmode">
            <mat-select
              required
              class="input-form mt-2 text-sm"
              [formControl]="priceRange"
              name="priceRange"
              placeholder="Price Range"
              (selectionChange)="validatePrice()"
            >
              <mat-option *ngFor="let payrange of allPayRange" [value]="payrange"
                >{{ payrange.label }}
              </mat-option>
            </mat-select>
          </div>

          <div>
            <mat-tab-group class="subscription-tab" (selectedTabChange)="selectedTabValue($event)">
              <mat-tab label="Photos">
                <div class="mt-6 mb-12">
                  <app-subscription-package-unit
                    [checkPriceValidation]="checkPrice"
                    [order]="0"
                    (setPackageForm)="savePackageData($event, 0, 'photos')"
                  ></app-subscription-package-unit>
                </div>
              </mat-tab>
              <mat-tab label="Videos">
                <div class="mt-6 mb-12">
                  <app-subscription-package-unit
                    [checkPriceValidation]="checkPrice"
                    [order]="1"
                    (setPackageForm)="savePackageData($event, 1, 'videos')"
                  ></app-subscription-package-unit>
                </div>
              </mat-tab>
              <mat-tab label="Multimedia">
                <div class="mt-6 mb-12">
                  <app-subscription-package-unit
                    [checkPriceValidation]="checkPrice"
                    [order]="2"
                    (setPackageForm)="savePackageData($event, 2, 'multimedia')"
                  ></app-subscription-package-unit>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <div
      class="w-full flex justify-between items-center px-6 py-3 mt-auto border-2 sticky bottom-0 z-10 bg-white"
    >
      <button mat-flat-button class="btn-size button warning black w-36" (click)="close($event)">
        Cancel
      </button>
      <button
        *ngIf="!isLastStep"
        class="next-btn w-36"
        [class]="!selectedServiceCategory.length ? 'disabled' : ''"
        (click)="goToNext(stepper)"
        [disabled]="!selectedServiceCategory.length"
      >
        {{ 'Next' }}
      </button>
      <button
        *ngIf="isLastStep"
        mat-button
        class="button btn-size w-36"
        [class]="
          isProcessing ||
          !planForm.get('title')?.value?.trimStart()?.length ||
          errorHandling('title', 'required') ||
          errorHandling('title', 'maxlength') ||
          !allImages.length ||
          !checkPackageValidations() ||
          (data?.editmode && !updatedata) ||
          this.priceRange.value == null
            ? 'disabled button h-10'
            : 'button h-10'
        "
        [disabled]="
          isProcessing ||
          !planForm.get('title')?.value?.trimStart()?.length ||
          errorHandling('title', 'required') ||
          errorHandling('title', 'maxlength') ||
          !allImages.length ||
          !checkPackageValidations() ||
          (data?.editmode && !updatedata) ||
          this.priceRange.value == null
        "
      >
        {{ data?.editmode ? 'Update' : 'Post' }}
      </button>
    </div>
  </div>
</form>
<input
  id="fileInput"
  [accept]="getAcceptValue(packageName)"
  #file
  multiple
  type="file"
  class="uploadFile img w-0 h-0 overflow-hidden"
  value="Upload Media"
  (click)="clearFileInput(file)"
  (change)="uploadFile(file.files, imageIndex)"
  style="display: none"
/>
