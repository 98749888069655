<div
  [ngClass]="layoutMode === 'grid_view' ? 'grid' : 'grid-view-hide'"
  class="grid masonary-grid-gap"
  [class]="
    searchTypeVal === 'video'
      ? 'grid-cols-2'
      : isHome
      ? 'grid-cols-2 md:grid-cols-4 homePage-grid'
      : 'grid-cols-2 md:grid-cols-4 '
  "
>
  <div class="post-card" *ngFor="let item of masonryImages">
    <div class="w-full relative mobile-height" style="padding-top: 100%">
      <app-post-card
        class="absolute top-0 left-0 w-full h-full"
        [loginedUser]="profile"
        [data]="item"
        [layoutMode]="layoutMode"
      ></app-post-card>
    </div>
  </div>
</div>
<div class="grid grid-cols-1 md:hidden gap-9" *ngIf="layoutMode !== 'grid_view'">
  <app-post-card
    *ngFor="let item of masonryImages"
    [myFollowings]="myFollowings"
    class="w-full"
    [loginedUser]="profile"
    [data]="item"
    [layoutMode]="layoutMode"
    [appInViewLogger]="{type:'post', postId: item._id, isMobileView: false, isSingleGrid: true }"
  ></app-post-card>
</div>
