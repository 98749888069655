import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-part-pricing',
  templateUrl: './part-pricing.component.html',
  styleUrls: ['./part-pricing.component.scss']
})
export class PartPricingComponent implements OnInit {
  routesConst = routesConst;
  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  };
  forIndividual = true;
  private unsubscribe$ = new Subject();
  scrollELement: any = '';
  innerWidth = window.innerWidth;

  plans: any[];
  addOns: any[];

  partPricingHeaderData = {
    styling: {
      'max-width': '545px'
    },
    class: 'heading-width',
    heading: 'how you are valued online',
    blueheading: 'think ',
    description: 'Whether you’re an established brand, company, or your exploring jobs there is a plan for you.',
    button: { text: 'Try 3 Months Free', buttonLink: false },
    image: {
      src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/1c2aP.jpeg',
      redirectUrl: ''
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }


  constructor(public router: Router,
    private profileService: ProfileService,
    public authService: AuthService,
    private sharedService: SharedService,
    private dialog: MatDialog,) {
    this.plans = [];
    this.addOns = [];
    this.getPlateformPlansAndAddons();
    router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.unsubscribe$)).subscribe((event: any) => {
      this.navigateTo(event.url)
    });
  }

  ngAfterViewChecked() {
    if (this.scrollELement !== '') {
      this.scroll(this.scrollELement, 'start')
    }
  }

  ngOnInit(): void { }

  scroll(element: any, scrollPosition: any) {
    if (this.scrollELement !== '') {
      document.querySelector(element).scrollIntoView({ block: scrollPosition });
    }
    this.resetScrollElement();
  }

  navigateTo(url: any) {
    if (this.router.url == url) {
      if (url.includes(routesConst.checkPricing)) {
        this.scrollELement = '#pricing'
      }
      if (url.includes(routesConst.planPricing)) {
        setTimeout(() => {
          this.scrollELement = '#comparePlans'
        }, 600);
      }
      if (url.includes(routesConst.addonPricing)) {
        setTimeout(() => {
          this.scrollELement = '#planAddOns'
        }, 600);
      }
    }
    else {
      this.scroll(this.scrollELement, 'center')
      this.router.navigate(['/' + url])
    }
  }

  resetScrollElement() {
    setTimeout(() => {
      this.scrollELement = '';
    }, 100);
  }

  getPlateformPlansAndAddons() {
    this.sharedService.jobprosPlans$.pipe(takeUntil(this.unsubscribe$)).subscribe((plans: any) => {
      if (plans.length) {
        this.plans = plans;
      } else {
        this.sharedService.jobProsPlansAndAdOns();
      }
    });
    this.sharedService.jobprosAdOn$.pipe(takeUntil(this.unsubscribe$)).subscribe((addOns: any) => {
      if (addOns.length) {
        this.addOns = addOns;
      } else {
        this.sharedService.jobProsPlansAndAdOns();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
