import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import * as moment from 'moment';
import { ImageTransform } from 'ngx-image-cropper';
import { Observable, Observer, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { userTypeConstant } from 'src/app/const/appConst';
import { AchievementType } from 'src/app/core/const/options';
import { registerValues } from 'src/app/core/const/register';
import { Achievement } from 'src/app/core/models/model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ImageService } from 'src/app/core/services/image.service';
import { JobService } from 'src/app/core/services/job.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SubscriptionManagmentDirective } from 'src/app/utilities/subscription.management';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent extends SubscriptionManagmentDirective implements OnInit {
  imageChangedEvent: any = '';
  bannerCroppedImage: any[] = [];
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  @ViewChild('locationSearchInput', { static: true })
  locationSearchInput?: ElementRef;
  alreadyChoosenUserName: string = '';
  AchievementType = AchievementType;

  isBannerSaving=false;

  userForm: FormGroup;
  user: any;
  showAddStudyForm = false;
  showAddClientProjectForm = false;
  showAddSkillForm = false;
  showAddHistoryForm = false;
  avatar: any;
  banner: any;
  avatarUploading = false;
  bannerUploading = false;
  location_id: string = '';
  education: Achievement[] = [];
  history: Achievement[] = [];
  project: Achievement[] = [];
  saveProfile = true;
  experienceLists: any = [];
  expLevel: any = {
    title: '',
    description: '',
    start_date: '',
    end_date: '',
    type: AchievementType.ExpLevel
  };
  skills: any[] = [];
  skill: string = '';
  filteredOptions: any[] = [];
  myControl = new FormControl();
  selectedLocation: any;
  userRole = userTypeConstant;
  isEditImageMode: boolean = false;
  userList: any;
  chatFilters = { offset: 0, limit: 10 };
  recruiterUnread: any[] = [];
  myNetworkUnread: any[] = [];
  clientUnread: any[] = [];
  usernameUpdate = new Subject<string>();
  validationChecking = false;
  usernameValidationError = false;
  imageUpload = true;
  showBannerSave = false;
  uloadedImgPath: any;

  registerValues = registerValues;
  @Input() set profile(dashboard: any) {
    if (dashboard) {
      this.selectedCategories = dashboard.profileCategory;
      const { name, user_name, bio, location } = dashboard;
      this.getRecruiterUserList();
      this.getMyNetworkUserList();
      this.getClientUserList();
      this.user = dashboard;
      this.alreadyChoosenUserName = user_name;
      this.userForm.get('name')?.setValue(name);
      this.userForm.get('userName')?.setValue(user_name);
      this.userForm.get('bio')?.setValue(bio);
      if (location?.length) {
        this.location_id = location[0]._id;
        this.selectedLocation = location[0];
        this.myControl.setValue(
          location[0].city_name + ' - ' + location[0].state_name + ' - ' + location[0].country_code
        );
      }
    }
  }

  @Input() set experienceLevel(experienceLevel: any) {
    this.experienceLists = experienceLevel;
  }

  @Input() set achievements(achievements: any) {
    if (achievements?.length) {
      this.education = this.getFormattedAchievement(
        this._commonService.sortAchievements(achievements.filter((a: Achievement) => a.type === AchievementType.Education))
      );
      this.history = this.getFormattedAchievement(
        this._commonService.sortAchievements(achievements.filter((a: Achievement) => a.type === AchievementType.WorkHistory))
      );
      this.project = this.getFormattedAchievement(
        this._commonService.sortAchievements(achievements.filter((a: Achievement) => a.type === AchievementType.ClientProject))
      );
      this.skills = achievements.filter((a: Achievement) => a.type === AchievementType.Skill);
      this.expLevel = achievements.find((a: Achievement) => a.type === AchievementType.ExpLevel);
    }
  }

  allSkills: any = [];
  allActiveSkills: any = [];
  serviceCategories: any = [];
  selectedCategories: any;

  constructor(
    private sharedService: SharedService,
    public fb: FormBuilder,
    private imageService: ImageService,
    private profileService: ProfileService,
    private _commonService: CommonService,
    private snack: SnackBarService,
    private _jobService: JobService,
    private authService: AuthService,
  ) {
    super();
    this.userForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(128)]],
      userName: [
        '',
        [
          Validators.required,
          Validators.maxLength(128),
          this.noWhitespaceValidator({ hasWhiteSpace: true })
        ]
      ],
      bio: ['', Validators.maxLength(1024)]
    });
    this.sharedService.getUserInfo();
  }

  ngOnInit(): void {
    this.formvalueChangesDetection();
    this.getTalentServiceCategory();
    this.filterLocation();
    this.getSkills();
  }

  filterLocation() {
    this._commonService
      ._filterLocation(this.locationSearchInput?.nativeElement)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((result: any) => {
        this.filteredOptions = result.data;
      });
  }

  getTalentServiceCategory() {
    this.profileService
      .getProfileCategory()
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((res: any) => {
        if (res?.data) {
          this.serviceCategories = res.data;
          this.selectedCategories = this.serviceCategories.find(
            (item: any) => item._id == this.selectedCategories
          );
        }
      });
  }

  private noWhitespaceValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').indexOf(' ') >= 0;
      return isWhitespace && control.value != '' ? error : null;
    };
  }

  private getSkills() {
    this.profileService
      .profileSkills()
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((result: any) => {
        const { data: skills } = result;
        this.allSkills = skills.map((skill: any) => ({
          _id: skill._id,
          title: skill.name,
          active: this.user?.profileSkills?.find((item: string) => item === skill._id)
            ? true
            : false
        }));
        this.allActiveSkills = this.allSkills.filter((item: any) => item.active == true);
      });
  }

  removeProfileSkill(skill: any) {
    const profileSkills = this.allActiveSkills.filter((i: any) => i._id !== skill._id);
    const updatedProfileSkills = profileSkills.map((item: { _id: any }) => item._id);
    this.profileService
      .updateProfile({ profileSkills: updatedProfileSkills })
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe(
        () => {
          this.allActiveSkills = profileSkills;
          this.snack.showMessage('Skill Deleted Sucessfully', false);
        },
        () => {
          this.snack.showMessage('Failed to Update Skills', true);
        }
      );
  }

  onCategorySelection(item: any) {
    this.profileService
      .updateProfile({ profileCategory: item._id })
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe(
        () => {
          this.selectedCategories = item;
          this.snack.showMessage('Category Update Sucessfully', false);
        },
        () => {
          this.snack.showMessage('Failed to Update Category', true);
        }
      );
  }


  search(event: any) {
    this.usernameUpdate
      .pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.componetDestroyed))
      .subscribe(() => {
        if (this.alreadyChoosenUserName !== event.target.value) {
          this.validationChecking = true;
          this.authService.validateUsername(event.target.value).then(res => {
            this.usernameValidationError = !res;
            this.validationChecking = false;
          });
        } else {
          this.validationChecking = false;
          this.usernameValidationError = false;
        }
      });
  }

  fileChangeEvent(event: any, files: any, type: string): void {
    this.showBannerSave = false;
    this.imageUpload = true;
    this.isEditImageMode = true;
    this.imageChangedEvent = event;
  }

  imageCropped(event: any) {
    this.convertToBlob(event.base64);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  public errorHandling = (control: string, error: string) => {
    return this.userForm.controls[control].hasError(error);
  };

  formvalueChangesDetection() {
    this.userForm.valueChanges.pipe(takeUntil(this.componetDestroyed)).subscribe(form => {
      if (
        form.bio !== this.user?.bio ||
        form.name !== this.user?.name ||
        form.userName !== this.user?.user_name
      ) {
        this.saveProfile = true;
        this.validationChecking = false;
      } else {
        this.saveProfile = false;
        this.validationChecking = false;
      }
    });
    this.myControl.valueChanges.pipe(takeUntil(this.componetDestroyed)).subscribe(location => {
      if (
        location !=
        this.selectedLocation?.city_name +
        ' - ' +
        this.selectedLocation?.state_name +
        ' - ' +
        this.selectedLocation?.country_code
      ) {
        this.saveProfile = true;
      } else {
        this.saveProfile = false;
      }
    });
  }

  setExpLevel(e: any) {
    if (this.expLevel) {
      this.expLevel = {
        ...this.expLevel,
        title: e.value
      };
      this.updateAchievement(this.expLevel, 'Experience Level');
    } else {
      const achievement = {
        title: e.value,
        type: AchievementType.ExpLevel,
        description: '',
        start_date: '',
        end_date: ''
      };
      this.saveAchievement(achievement, AchievementType.ExpLevel);
    }
  }

  removeSkill(skill: Achievement) {
    this.deleteAchievement(skill, 'Skill');
  }

  addNewSkill(skill: string) {
    if (skill.trim().length && skill.trim()?.length < 129) {
      const achievement = {
        title: skill.trim(),
        type: AchievementType.Skill,
        description: '',
        start_date: '',
        end_date: ''
      };
      this.saveAchievement(achievement, AchievementType.Skill);
      this.skill = '';
    }
  }

  getUpdated(arr: any[], item: any) {
    const idx = arr.findIndex(a => a._id === item._id);
    arr[idx] = {
      ...item,
      start_date: this.getFormattedMonth(item.start_date),
      end_date: this.getFormattedMonth(item.end_date)
    };
    return arr;
  }

  /**
   * Update Achievement
   * @param achievement
   */
  updateAchievement(achievement: Achievement, Type: String) {
    const ach = {
      ...achievement,
      start_date: this.getFormattedDate(achievement.start_date),
      end_date: this.getFormattedDate(achievement.end_date)
    };
    this.profileService
      .updateAchievement(ach)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((res: any) => {
        if (res.status === 'success') {
          if (Type === AchievementType.WorkHistory) {
            this.snack.showMessage('Work History Updated Sucessfully', false);
          } else {
            this.snack.showMessage(` ${Type} Updated Sucessfully`, false);
          }
          switch (res.update.type) {
            case AchievementType.Education:
              this.education = this.getUpdated(this.education, ach);
              break;
            case AchievementType.WorkHistory:
              this.history = this.getUpdated(this.history, ach);
              break;
            case AchievementType.ClientProject:
              this.project = this.getUpdated(this.project, ach);
              break;
            case AchievementType.Skill:
              this.skills = this.getUpdated(this.skills, ach);
              break;
            default:
              this.expLevel = ach;
              break;
          }
        }
      });
  }

  /**
   * Save Education
   * @param achievement
   */
  saveAchievement(achievement: any, type: string) {
    const idx = this.skills.findIndex(
      (item: any) =>
        item.title.replace(/\s/g, '').toLowerCase() ===
        achievement.title.replace(/\s/g, '').toLowerCase()
    );
    if (idx != -1 && type == AchievementType.Skill) {
      this.snack.showMessage(`Skill already exist`, true);
      return;
    }
    const newAchievement = {
      ...achievement,
      type: type,
      start_date: this.getFormattedDate(achievement.start_date),
      end_date: achievement.end_date
        ? this.getFormattedDate(achievement.end_date)
        : achievement.end_date
    };
    this.profileService
      .saveAchievement(newAchievement)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe(
        (res: any) => {
          if (res.status === 'Sucess') {
            if (type === AchievementType.WorkHistory) {
              this.snack.showMessage('Work History Added Successfully', false);
            } else if (type === AchievementType.ClientProject) {
              this.snack.showMessage('Client Project Added Successfully', false);
            } else {
              this.snack.showMessage(` ${type} Added Sucessfully`, false);
            }
            const ach = {
              ...res.data,
              start_date: this.getFormattedMonth(res.data.start_date),
              end_date: this.getFormattedMonth(res.data.end_date)
            };
            switch (res.data.type) {
              case AchievementType.Education:
                if (this.education && this.education.length > 0) {
                  this.education = [...this.education, ach];
                } else {
                  this.education = [ach];
                }
                break;
              case AchievementType.WorkHistory:
                if (this.history && this.history.length > 0) {
                  this.history = [...this.history, ach];
                } else {
                  this.history = [ach];
                }
                break;
              case AchievementType.ClientProject:
                if (this.project && this.project.length > 0) {
                  this.project = [...this.project, ach];
                } else {
                  this.project = [ach];
                }
                break;
              case AchievementType.Skill:
                if (this.skills && this.skills.length > 0) {
                  this.skills = [...this.skills, ach];
                } else {
                  this.skills = [ach];
                }
                break;
              default:
                this.expLevel = ach;
                break;
            }
          } else {
            this.snack.showMessage(` ${type} ${res.message}`, true);
          }
        },
        () => {
          this.snack.showMessage(`Failed to Update`, true);
        }
      );

    if (type === AchievementType.Education) {
      this.showAddStudyForm = false;
    } else if (type === AchievementType.ClientProject) {
      this.showAddClientProjectForm = false;
    } else {
      this.showAddHistoryForm = false;
    }
  }

  deleteAchievement(achievement: Achievement, Type: String) {
    if (achievement._id)
      this.profileService
        .deleteAchievement(achievement._id)
        .pipe(takeUntil(this.componetDestroyed))
        .subscribe(
          (res: any) => {
            if (res.status === 'Success') {
              this.snack.showMessage(`${Type} Deleted  Sucessfully`, false);
              switch (achievement.type) {
                case AchievementType.Education:
                  this.education = this.education.filter((e: any) => e._id !== achievement._id);
                  break;
                case AchievementType.WorkHistory:
                  this.history = this.history.filter((e: any) => e._id !== achievement._id);
                  break;
                case AchievementType.ClientProject:
                  this.project = this.project.filter((e: any) => e._id !== achievement._id);
                  break;
                default:
                  this.skills = this.skills.filter((e: any) => e._id !== achievement._id);
                  break;
              }
            }
          },
          () => {
            this.snack.showMessage(`Failed to Update.`, true);
          }
        );
  }

  /**
   * Get Formated Educatio
   * @param achievements
   * @returns
   */
  getFormattedAchievement(achievements: Achievement[]) {
    if (achievements?.length) {
      return achievements.map((ach: Achievement) => {
        return {
          ...ach,
          start_date: this.getFormattedMonth(ach.start_date),
          end_date: this.getFormattedMonth(ach.end_date)
        };
      });
    }
    return [];
  }

  /**
   * Add Study
   */
  addStudy() {
    this.showAddStudyForm = !this.showAddStudyForm;
  }

  addHistory() {
    this.showAddHistoryForm = !this.showAddHistoryForm;
  }

  addProject() {
    this.showAddClientProjectForm = !this.showAddClientProjectForm;
  }

  dataURItoBlob(dataURI: any): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString = this._commonService.getWindow()?.atob(dataURI);
      if (byteString) {
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        observer.next(blob);
      }
      observer.complete();
    });
  }


  convertToBlob(data: any): void {
    // Remove the data URL prefix
    const base64Image = data.replace('data:image/png;base64,', '');
    // Generate a unique image name using current timestamp
    const timestamp = new Date().valueOf();
    const imageName = timestamp + '.png';
    // Convert base64 to Blob
    this.dataURItoBlob(base64Image)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe(
        (imageBlob: Blob) => {
          // Create a File object from Blob
          const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
          // Create an ImageSnippet object
          const selectedImage = new ImageSnippet(data, imageFile);
          this.bannerCroppedImage.unshift(selectedImage.file);
          this.showBannerSave = true;
        },
        (error) => {
          console.error('Error converting base64 to Blob:', error);
        }
      );
  }


  saveBanner(files: FileList) {
    if (files.length === 0) return;
    this.isBannerSaving=true;
    if (!this.imageService.validateImageType(files[0].type)) return;
    const file: any = files[0];
    this.bannerUploading = true;
    this.banner = file;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.addEventListener('load', (event: any) => {
      this.imageService
        .imageConvertion(new ImageSnippet(event.target.result, file))
        .then((imageUri: any) => {
          this.user.banner = event.target.result;
          this.bannerUploading = false;
          this.showBannerSave = false;
          this.imageUpload = false;
          this.isEditImageMode = false;
          this.isBannerSaving=false;
          const avatarUpdate = { banner_img_path: imageUri[0] }
          this.updateProfileImageAndBanner(avatarUpdate);
        })
    });
  }




  /**
   * Upload Avatar
   * @param files 
   * @returns 
   */
  uplaodAvatar(files: FileList) {
    if (!files.length) return;
    if (!this.imageService.validateImageType(files[0].type)) return;
    const file: any = files[0];
    this.avatarUploading = true;
    this.avatar = file;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.addEventListener('load', (event: any) => {
      this.imageService
        .imageConvertion(new ImageSnippet(event.target.result, file))
        .then((imageUri: any) => {
          this.user.avatar = event.target.result;
          this.avatarUploading = false;
          const avatarUpdate = { profile_img_path: imageUri[0] }
          this.updateProfileImageAndBanner(avatarUpdate);
        })

    });
  }

  getControlValue(control: any) {
    return this.userForm.get(control)?.value;
  }

  /**
   * Update Profile
   */
  updateProfile() {
    const payload = {
      name: this.getControlValue('name'),
      user_name: this.getControlValue('userName'),
      bio: this.getControlValue('bio'),
      location_id: this.selectedLocation?._id ? this.selectedLocation._id : this.location_id
    };
    this.profileService
      .updateProfile(payload)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe(
        () => {
          this.sharedService.getUserInfo();
          this.snack.showMessage('Profile Information Updated', false);
          this.saveProfile = false;
        },
        () => {
          this.saveProfile = true;
          this.snack.showMessage('Faile to Update Profile Information', true);
        }
      );
  }

  /**
   * Get Formatted Month
   * @param date
   * @returns
   */
  getFormattedMonth(date: string) {
    if (date) {
      let dateArr = date.split('-');
      const dateStr = dateArr[1] + '/' + dateArr[0];
      return dateStr;
    }
    return date;
  }

  /**
   * Get Formated Date
   * @param date
   * @returns
   */
  getFormattedDate(date: any) {
    if (date) {
      let dateArr = date?.split('/');
      const str = dateArr[1] + '-' + (dateArr[0].length === 1 ? '0' + dateArr[0] : dateArr[0]);
      return moment(str + '-01T00:00:00.000Z').toISOString();
    }
    return null;
  }

  addSkill() {
    this.showAddSkillForm = true;
  }

  /**
   * Set user's Location
   * @param option
   */
  setLocation(option: any) {
    this.selectedLocation = option;
    this.saveProfile = true;
  }

  onEnter() {
    this.saveProfile = true;
  }

  /**
   * get Recruiters User list
   * @param user_id
   */
  getRecruiterUserList() {
    this._jobService
      .getChatUsers(this.chatFilters)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((userList: any) => {
        this.recruitersUnreadMessages(userList.data);
      });
  }

  /**
   * get My Network User list
   * @param user_id
   */
  getMyNetworkUserList() {
    this._jobService
      .getMyNetworkUsers(this.chatFilters)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((userList: any) => {
        this.myNetworkUnreadMessages(userList.data);
      });
  }

  /**
   * get Client User list
   * @param user_id
   */
  getClientUserList() {
    this._jobService
      .getClientUsers(this.chatFilters)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((userList: any) => {
        this.clientUnreadMessages(userList.data);
      });
  }

  /**
   * get Recruiters Unread Messages
   * @param  {any} event
   */
  recruitersUnreadMessages(event: any) {
    this.recruiterUnread = [];
    if (event) {
      event.find((element: any) => {
        if (element.unread_messages.length) {
          this.recruiterUnread.push(element);
        }
      });
    }
    this.checkUnreadMessages();
  }

  /**
   * get my Network Unread Messages
   * @param  {any} event
   */
  myNetworkUnreadMessages(event: any) {
    this.myNetworkUnread = [];
    if (event) {
      event.find((element: any) => {
        if (element?.unread_messages?.length) {
          this.myNetworkUnread.push(element);
        }
      });
    }
    this.checkUnreadMessages();
  }

  /**
   * get Client Unread Messages
   * @param  {any} event
   */
  clientUnreadMessages(event: any) {
    this.clientUnread = [];
    if (event) {
      event.find((element: any) => {
        if (element?.unread_messages?.length) {
          this.clientUnread.push(element);
        }
      });
    }
    this.checkUnreadMessages();
  }

  /**
   * check if unread messages left
   */
  checkUnreadMessages() {
    let recruiter = this.recruiterUnread.length > 0 ? true : false;
    let client = this.clientUnread.length > 0 ? true : false;
    let mynetwork = this.myNetworkUnread.length > 0 ? true : false;
    this._commonService.isUnreadMessagesExist.next({
      recruiter: recruiter,
      mynetwork: mynetwork,
      client: client
    });
  }

  /**
   * Used To Remove User's Banner or Profile Image
   * @param user 
   * @param type 
   */
  removeImage(type: string): void {
    this.profileService.updateImage({ type })
      .pipe(
        takeUntil(this.componetDestroyed),
        tap(() => {
          this.sharedService.getUserInfo();
          this.user[type === 'Banner' ? 'banner' : 'avatar'] = '';
          this.snack.showMessage(`${type} Removed Successfully`, false);
        }),
        catchError(() => {
          this.snack.showMessage(`${type} Failed to Remove`, true);
          return [];
        })
      )
      .subscribe();
  }

  addSkills(event: any) {
    if (this.skill.trim().length && this.skill.trim()?.length < 129) {
      if (event.keyCode == 13) {
        return this.addNewSkill(this.skill);
      } else if (event.keyCode != 13) {
        return null;
      }
    }
  }


  /**
   * Update Banner or Profile Image
   * @param payload 
   */
  updateProfileImageAndBanner(payload: any): void {
    this.profileService.updateProfile(payload)
      .pipe(
        takeUntil(this.componetDestroyed),
        tap(() => {
          this.sharedService.getUserInfo();
          this.snack.showMessage('Profile Updated', false);
        }),
        catchError((error) => {
          this.snack.showMessage('Failed to Update Profile', true);
          console.error('Error updating profile:', error);
          return of(null);
        }),
      )
      .subscribe();
  }

}
