<div class="mb-10 md:mb-20">
	<div
		class="unit-wrapper flex flex-col-reverse md:grid md:grid-cols-2 px-6 gap-6"
	>
		<img
			class="m-auto"
			src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Earning+Homepage+Final.png"
		/>
		<div class="">
			<div class="mb-2 bg-blue rounded-full w-12 h-12 flex items-center">
				<img
					class="m-auto"
					src="../../../assets/images/recuiter-w-icon.png"
					width="32"
				/>
			</div>
			<h3 class="montserrat-bold capitalize mb-5">your earnings</h3>
			<p class="gray-dark mb-8 capitalize">manage your commissions</p>
			<div class="grid grid-cols-2 gap-2 md:gap-6">
				<div class="flex items-center">
					<img
						src="../../../assets/images/icon-check.png"
						width="24"
            class="mr-2 md:mr-4"
					/>
					<p class="montserrat-bold capitalize text-xs">view earnings<br />on dashboard</p>
				</div>
				<div class="flex items-center">
					<img
						src="../../../assets/images/icon-check.png"
						width="24"
            class="mr-2 md:mr-4"
					/>
					<p class="montserrat-bold capitalize text-xs">
						referral partner<br />commission
					</p>
				</div>
				<div class="flex items-center">
					<img
						src="../../../assets/images/icon-check.png"
						width="24"
            class="mr-2 md:mr-4"
					/>
					<p class="montserrat-bold capitalize text-xs">cash out<br />earned income</p>
				</div>
				<div class="flex items-center">
					<img
						src="../../../assets/images/icon-check.png"
						width="24"
            class="mr-2 md:mr-4"
					/>
					<p class="montserrat-bold capitalize text-xs">
						view all payment<br />& transfer history
					</p>
				</div>
			</div>
		</div>
	</div>
	<div class="unit-wrapper grid md:grid-cols-2 px-6 gap-6">
		<div class="">
			<div class="mb-2 bg-blue rounded-full w-12 h-12 flex items-center">
				<img
					class="m-auto"
					src="../../../assets/images/recuiter-w-icon.png"
					width="32"
				/>
			</div>
			<h3 class="montserrat-bold capitalize mb-5">Referral Program</h3>
			<p class="gray-dark mb-8">Earn Passive Income on One Platform.</p>
			<div class="grid grid-cols-2 gap-2 md:gap-6">
				<div class="flex items-center">
					<img
						src="../../../assets/images/icon-check.png"
						width="24"
            class="mr-2 md:mr-4"
					/>
					<p class="montserrat-bold capitalize text-xs">
						5% commission per<br />elite users referral
					</p>
				</div>
				<div class="flex items-center">
					<img
						src="../../../assets/images/icon-check.png"
						width="24"
            class="mr-2 md:mr-4"
					/>
					<p class="montserrat-bold capitalize text-xs">get personal<br />referral code</p>
				</div>
				<div class="flex items-center">
					<img
						src="../../../assets/images/icon-check.png"
						width="24"
            class="mr-2 md:mr-4"
					/>
					<p class="montserrat-bold capitalize text-xs">
						add referral partner<br />to any user plan
					</p>
				</div>
				<div class="flex items-center">
					<img
						src="../../../assets/images/icon-check.png"
						width="24"
            class="mr-2 md:mr-4"
					/>
					<p class="montserrat-bold capitalize text-xs">
						individual users &<br />companies earn
					</p>
				</div>
			</div>
		</div>
		<img
			class="m-auto"
			src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Referrals+Homepage.png"
		/>
	</div>
</div>
