<div class="job-modal relative flex flex-col">
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="black-dark"
    size="medium"
    color="red"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    class="job-list-spinner"
  >
  </ngx-spinner>
  <div class="absolute right-4 top-4 z-10">
    <button mat-icon-button (click)="onClose()">
      <mat-icon class="text-3xl">close</mat-icon>
    </button>
  </div>
  <div class="New-Job-Card h-full">
    <div class="px-4 pt-4">
      <p class="mt-2 montserrat-bold job-tittle">
        {{ job?.data?.job_title }}
      </p>
      <div class="flex flex-row py-2">
        <div class="thumbnail-img w-full rounded-full border border-black overflow-hidden">
          <img
            *ngIf="job?.data?.companyDetails?.profile_img_path"
            [src]="job?.data.companyDetails.profile_img_path"
          />
          <img
            *ngIf="!job?.data?.companyDetails?.profile_img_path"
            src="assets/images/no-image.png"
            alt="No Image Found"
          />
        </div>
        <div class="flex items-center job-Overview">
          <div class="flex flex-wrap">
            <span class="job-detail ml-1" *ngIf="job?.data?.companyDetails?.name">{{
              job?.data?.companyDetails?.name
            }}</span>
            <span class="job-detail ml-1" *ngIf="!job?.data?.companyDetails?.name"
              >unknown company *</span
            >
          </div>
        </div>
      </div>
      <div class="flex flex-wrap justify-between items-center pb-2">
        <span class="job-detail ml-1" *ngIf="job?.data?.location">{{
          getLocation(job?.data)
        }}</span>
        <!-- <p class="py-2 mr-8">
          {{ job?.data?.experience_required }}
        </p> -->
      </div>
      <div class="flex flex-wrap">
        <span class="job-price">{{ job?.data?.job_type }}</span>
        <span class="job-price">{{ job?.data?.salary_range }}/ Per Week</span>
      </div>
      <p class="mt-2 time-posted border-b pb-2">
        Posted Date: {{ formatJobDate(job?.data?.created_at) }}
      </p>
    </div>
    <div class="relative flex flex-col flex-grow px-4 pt-4">
      <div class="lg:mt-0 mr-2 flex-wrap w-full">
        <p class="s montserrat-bold mr-2">Preferred Skills:</p>
        <div class="flex flex-wrap py-2 gap-1">
          <p
            class="job-price"
            *ngFor="let preferred_requiremets of job?.data?.preferred_requirements; let i = index"
          >
            {{ preferred_requiremets }}
          </p>
        </div>
      </div>
      <hr />
      <br />
      <p class="s montserrat-bold mr-2">Job Description:</p>
      <p class="py-2">
        {{ job?.data.job_description }}
      </p>
      <hr />
      <br />
      <div class="job-content">
        <p class="s montserrat-bold mr-2">Job Category:</p>
        <div class="flex flex-wrap py-2">
          <span class="job-price">
            {{ job?.data?.job_category }}
          </span>
        </div>
      </div>
      <hr />
      <br />
      <div class="job-content">
        <p class="s montserrat-bold mr-2">Experience Level</p>
        <div class="flex flex-wrap py-2">
          <span class="job-price">
            {{ job?.data?.experience_required }}
          </span>
        </div>
      </div>
      <hr />
      <br />
    </div>
  </div>
  <div
    *ngIf="data?.tab != 'my-jobs'"
    class="flex items-center justify-center mt-auto py-2 sticky bottom-0 left-0 right-0 bg-white"
  >
    <button
      (click)="openApplyDialog(job?.data)"
      *ngIf="
        (!job?.data?.isApplied || job?.data?.isSaved) && job?.data?.company_id !== loginedUserId
      "
      mat-button
      class="button black my-1 mr-2"
    >
      Apply
    </button>
    <div class="mx-2">
      <button
        (click)="saveJob(job?.data)"
        *ngIf="!job?.data?.isSaved && !job?.data?.isApplied"
        mat-button
        class="button outlined black my-1 mr-2"
      >
        Save
      </button>
      <button *ngIf="job?.data?.isSaved" mat-button class="success button my-1 mr-2">Saved</button>
    </div>
    <div>
      <div class="flex items-center">
        <button
          mat-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
          style="
            border: 1px solid #e5e5e5;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            min-width: 40px;
          "
        >
          <mat-icon style="color: #2887ed">more_vert</mat-icon>
        </button>
        <p class="mt-0 md:mt-2.5 green ml-2" *ngIf="job?.data?.isApplied && !job?.data?.isSaved">
          Applied on {{ job.data.created_at | date: 'mediumDate' }}
        </p>
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="showProfile($event)">
          <mat-icon style="color: #2887ed">person</mat-icon>
          <span>Profile</span>
        </button>
        <button mat-menu-item (click)="messageRecuirter(job.data.companyDetails)">
          <mat-icon style="color: #2887ed">chat</mat-icon>
          <span>Message</span>
        </button>
        <button mat-menu-item [matMenuTriggerFor]="exploreMenu">
          <mat-icon style="color: #2887ed; transform: rotate(-45deg)">send</mat-icon>
          <span>Share</span>
        </button>
        <mat-menu #exploreMenu="matMenu">
          <div class="grid grid-cols-3 gap-2 cursor-pointer">
            <div class="flex flex-col items-center">
              <button
                (click)="copyLink()"
                class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
              >
                <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
              </button>
              <span class="text-xs">Copy</span>
            </div>
            <div class="flex flex-col items-center">
              <button
                (click)="share()"
                class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2"
              >
                <mat-icon>share</mat-icon>
              </button>
              <span class="text-xs">Share</span>
            </div>
            <div class="flex flex-col items-center">
              <button
                class="w-10 h-10 flex items-center justify-center rounded-full border border-red-400 p-1"
              >
                <mat-icon class="text-red-400">close</mat-icon>
              </button>
              <span class="text-xs text-red-400">Cancel</span>
            </div>
          </div>
        </mat-menu>
      </mat-menu>
    </div>
  </div>

  <div
    *ngIf="data?.tab == 'my-jobs'"
    class="flex justify-evenly gap-2 px-4 md:flex items-center mt-auto py-2 sticky bottom-0 left-0 right-0 bg-white"
    [ngClass]="data?.userType == userRole.company &&
    job?.data?.is_active &&
    !job?.data?.isSaved &&
    !job?.data?.isApplied ? 'grid-cols-3':'grid-cols-2'"  >
    <button
      *ngIf="
        (job?.data?.isSaved || job?.data?.isApplied) &&
        data?.userInfo?.profileInfo?._id !== job?.data?.company_id
      "
      (click)="messageRecuirter(job.data.companyDetails)"
      class="py-1.5 px-2 w-full md:w-auto msg-btn hover:bg-[#2887ed] hover:text-white border-2 border-[#2887ed] rounded"
    >
      Message
    </button>

    <button
      *ngIf="job?.data?.isSaved && job?.data?.is_active && job?.data?.company_id !== loginedUserId"
      (click)="openApplyDialog(job?.data)"
      mat-stroked-button
      class="button outlined black w-full md:w-auto my-jobs-button"
    >
      Apply
    </button>
    <button
      *ngIf="job?.data?.isSaved"
      (click)="unsaveJob(job, index)"
      mat-stroked-button
      class="button outlined black w-full md:w-auto my-jobs-button"
    >
      Unsave
    </button>

    <button
      *ngIf="
        data?.userType == userRole.company &&
        job?.data?.is_active &&
        !job?.data?.isSaved &&
        !job?.data?.isApplied
      "
       mat-button
      mat-stroked-button
      class="button warning"
      (click)="activeDeactiveJob(job?.data, false)"
    >
      Delete
    </button>
    <button
      *ngIf="data?.userType == userRole.company && !job?.data?.isSaved && !job?.data?.isApplied"
      mat-stroked-button
      class="button"
      (click)="reviewApplicant(job?.data?._id, job?.data?.job_title)"
    >
      Applicants
    </button>

    <button
      *ngIf="data?.userType == userRole.company && !job?.data?.isSaved && !job?.data?.isApplied"
      mat-stroked-button
      class="button normal"
      (click)="editJob(job?.data?._id)"
    >
      Edit
    </button>


    <button
      *ngIf="data?.userType == userRole.company && !job?.data?.is_active && !job?.data?.isSaved"
      mat-stroked-button
      class="button normal blue w-full md:w-auto my-jobs-button"
      (click)="activeDeactiveJob(job?.data, true)"
    >
      Active
    </button>
  </div>
</div>
