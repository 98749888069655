<div id="container" class="h-full w-full">
    <div id="success-box">
        <!-- <div class="dot"></div>
        <div class="dot two"></div> -->
        <div class="face">
            <div class="eye"></div>
            <div class="eye right"></div>
            <div class="mouth happy"></div>
        </div>
        <div class="shadow scale"></div>
        <div class="message">
            <h1 class="alert">Success!</h1>
            <p class="text-white">Your payment has been sucessfully done </p>
        </div>
        <button class="button-box" [routerLink]="['/' + routesConst.dashboard + '/' + routesConst.subscription]">
            <h1 class="green">continue</h1>
        </button>
    </div>
</div>