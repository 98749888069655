<ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
</ngx-spinner>
<div class="dashboard-payment-history section-wrapper bg-white lg:p-10">
    <button mat-button class="montserrat-medium button outlined black new-card" (click)="addNewCard()">
        <mat-icon>credit_card</mat-icon>
        + Card
    </button>
    <p class="montserrat-bold text-black ">Manage</p>
    <div class="flex justify-between items-center">
    </div>
    <div class="mt-4">
        <table mat-table [dataSource]="dataSource" class="w-full shadow-none mat-elevation-z8"
            *ngIf="dataSource?.length">
            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="text-black montserrat-bold s header">Card Type</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p class="table-data">{{element.brand | titlecase}}</p>
                </td>
            </ng-container>
            <ng-container matColumnDef="last4">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="text-black montserrat-bold s header">Last 4 Digit</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p>{{element.last4 }}</p>
                </td>
            </ng-container>
            <ng-container matColumnDef="expiry">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="text-black montserrat-bold s text-right header">Expiry</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p class="text-right  ">{{element.expiry}}</p>
                </td>
            </ng-container>
            <ng-container matColumnDef="isDefault">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="text-black montserrat-bold s text-right header">Default Card</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p class="text-right" [ngClass]="{'icon-color':element.isPrimary }">
                        <mat-icon>{{element.isPrimary ? 'done' :'close' }}</mat-icon>
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="makeDefault">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="text-black montserrat-bold s text-right header">Set Default</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p class="text-right">
                        <mat-icon *ngIf="!element.isPrimary" (click)="makeDefault(element)">add</mat-icon>
                    </p>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef>
                    <p class="text-black montserrat-bold s text-right header">Delete</p>
                </th>
                <td mat-cell *matCellDef="let element">
                    <p class="text-right  ">
                        <mat-icon (click)="deletePaymentMethod(element)">delete</mat-icon>
                    </p>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="flex justify-center text-center mt-6">
            <p class="s gray-dark">You’ve reached the end of the list</p>
        </div>
    </div>
</div>