<div class="influencer-profile">
  <div class="flex items-center">
    <button matStepperPrevious class="back-icon mt-2">
      <mat-icon class="arrow-back hover:bg-gray-200 rounded-full"
        >arrow_back</mat-icon
      >
    </button>
    <h1 class="montserrat-bold black-dark title">@valentinaacevedo</h1>
    <div class="hidden lg:flex items-center lg:ml-auto mt-3">
      <button matStepperPrevious disabled>
        <mat-icon class="font-bold s gray-dark arrow-back"
          >arrow_back_ios</mat-icon
        >
      </button>
      <button matStepperPrevious>
        <mat-icon class="font-bold s blue arrow-back"
          >arrow_forward_ios</mat-icon
        >
      </button>
    </div>
  </div>

  <div class="p-4 lg:p-0">
    <div class="flex flex-col lg:flex-row items-center py-4">
      <div class="flex items-center">
        <img [src]="item.img" class="rounded-full" />
        <div class="ml-4">
          <div class="flex items-center">
            <p class="montserrat-bold">{{ item.name }}</p>
            <p class="montserrat-bold blue pl-1">· View profile</p>
          </div>
          <p class="s my-2.5">{{ item.location }}</p>
        </div>
      </div>
      <div
        class="flex flex-col w-full lg:w-32 justify-end lg:ml-auto mt-4 lg:mt-0"
      >
        <div
          class="border-normal rounded w-full flex items-center justify-center px-4 py-2 bg-blue"
        >
          <mat-select [value]="option" class="text-white">
            <mat-option *ngFor="let item of options" [value]="item.key">{{
              item.value
            }}</mat-option>
          </mat-select>
          <mat-icon class="text-white -ml-4">expand_more</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="section-wrapper p-4 lg:p-10">
    <div class="flex flex-col lg:flex-row mb-2">
      <div class="mb-2 lg:mb-0">
        <p class="black-light montserrat-bold uppercase mt-1.5">Earnings</p>
        <p class="s mt-2 black-light">Overall earnings from this member</p>
      </div>
      <div class="block lg:hidden">
        <mat-divider class="w-full"></mat-divider>
      </div>
    </div>
    <div class="hidden lg:block">
      <mat-divider class="w-full"></mat-divider>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
      <div class="flex flex-col lg:items-center">
        <h1 class="montserrat-bold">{{ item.postN }}</h1>
        <p class="gray-dark">Paid posts</p>
      </div>
      <div class="flex flex-col lg:items-center">
        <h1 class="montserrat-bold">{{ item.earningN }}</h1>
        <p class="gray-dark">Earnings</p>
      </div>
      <div class="flex flex-col lg:items-center">
        <h1 class="montserrat-bold">{{ item.commissionN }}</h1>
        <p class="gray-dark">Commissions</p>
      </div>
      <div class="flex flex-col lg:items-center">
        <h1 class="montserrat-bold">{{ item.expectedCommissionN }}</h1>
        <p class="gray-dark">Expected commisions</p>
      </div>
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-3 px-20 gap-2 mt-6>">
      <!-- <div
        class="flex flex-col items-center justify-center"
        *ngFor="let chartOptions of chartOptionsArr"
      >
        <div class="flex items-center justify-center">
          <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [plotOptions]="chartOptions.plotOptions"
            [stroke]="chartOptions.stroke"
            [colors]="chartOptions.colors"
          ></apx-chart>
          <div class="flex absolute items-center justify-center flex-col">
            <h4 class="montserrat-bold">{{ chartOptions.series }}%</h4>
            <div class="flex items-center -ml-1">
              <mat-icon class="s green flex items-center justify-center p-1 w-3"
                >arrow_upward</mat-icon
              >
              <p class="s green -ml-1">2%</p>
            </div>
          </div>
        </div>
        <p class="text-center">{{ chartOptions.label }}</p>
      </div> -->
    </div>
  </div>

  <div class="section-wrapper my-4 p-4 lg:p-10">
    <div class="flex flex-col lg:flex-row mb-2">
      <div class="mb-2 lg:mb-0">
        <p class="black-light montserrat-bold uppercase mt-1.5">
          Membership plans
        </p>
        <p class="s mt-2 black-light">
          How much of comissions do their Membership plans represent
        </p>
      </div>
      <div class="block lg:hidden">
        <mat-divider class="w-full"></mat-divider>
      </div>
    </div>

    <div class="">
      <table
        mat-table
        [dataSource]="planDataSource"
        class="w-full shadow-none mat-elevation-z8"
      >
        <ng-container matColumnDef="plan">
          <th mat-header-cell *matHeaderCellDef>
            <p class="black-light montserrat-bold s">Plan</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="montserrat-bold">{{ element.name }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="subscribers">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="black-light montserrat-bold s text-right pl-8 hidden lg:block"
            >
              Subscribers
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="text-right hidden lg:block">
                {{ kFormatter(element.subscriberN) }}
              </p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="black-light montserrat-bold s text-right hidden lg:block pl-8"
            >
              Price
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="text-right hidden lg:block">
              ${{ kFormatter(element.price) }}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="commission">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="black-light montserrat-bold s text-right pl-8 hidden lg:block"
            >
              commission
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="text-right hidden lg:block">
              {{ kFormatter(element.commission) }}
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="planColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: planColumns"></tr>
      </table>
    </div>
  </div>

  <div class="section-wrapper p-4 lg:p-10 mt-4">
    <div class="flex flex-col lg:flex-row mb-2">
      <div class="mb-2 lg:mb-0">
        <p class="black-light montserrat-bold uppercase mt-1.5">
          All-time content engagement
        </p>
        <p class="s mt-2 black-light">
          Member since 13 July, 2020. Elite Member since 20 August 2020.
        </p>
      </div>
      <div class="block lg:hidden">
        <mat-divider class="w-full"></mat-divider>
      </div>
    </div>
    <div class="hidden lg:block">
      <mat-divider class="w-full"></mat-divider>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
      <div class="flex flex-col lg:items-center">
        <h1 class="montserrat-bold">{{ item.totalFollowerN }}</h1>
        <p class="gray-dark">Followers</p>
      </div>
      <div class="flex flex-col lg:items-center">
        <h1 class="montserrat-bold">{{ item.totalPostN }}</h1>
        <p class="gray-dark">Posts</p>
      </div>
      <div class="flex flex-col lg:items-center">
        <h1 class="montserrat-bold">{{ item.totalLikeN }}</h1>
        <p class="gray-dark">Likes</p>
      </div>
      <div class="flex flex-col lg:items-center">
        <h1 class="montserrat-bold">{{ item.totalComments }}</h1>
        <p class="gray-dark">Comments</p>
      </div>
    </div>
    <p class="mt-10 black-light uppercase">MOST POPULAR content</p>
    <div class="">
      <table
        mat-table
        [dataSource]="totalDataSource"
        class="w-full shadow-none mat-elevation-z8"
      >
        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef>
            <p class="black-light montserrat-bold s">Content</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="flex items-center">
              <img [src]="element.img" />
              <div class="ml-3">
                <p class="montserrat-bold">{{ element.name }}</p>
                <p class="s montserrat-bold black-light">
                  {{ element.description }}
                </p>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="views">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="black-light montserrat-bold s text-right pl-8 hidden lg:block"
            >
              Views
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="text-right hidden lg:block">
                {{ kFormatter(element.viewN) }}
              </p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="likes">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="black-light montserrat-bold s text-right hidden lg:block pl-8"
            >
              Likes
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="text-right hidden lg:block">
              {{ kFormatter(element.likeN) }}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="black-light montserrat-bold s text-right pl-8 hidden lg:block"
            >
              Comments
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="text-right hidden lg:block">
              {{ kFormatter(element.commentN) }}
            </p>
          </td>
        </ng-container>

        <ng-container matColumnDef="newAudience">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="black-light montserrat-bold s text-right pl-8 hidden lg:block"
            >
              New audience
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="text-right hidden lg:block">
              {{ kFormatter(element.audienceN) }}
            </p>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="totalColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: totalColumns"></tr>
      </table>
    </div>
  </div>
</div>
