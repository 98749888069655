import { Component, OnInit } from '@angular/core';

export type StateElement = {
  id: string;
  name: string;
  jobN: number;
  applicationN: number;
}

const STATE_DATA: StateElement[] = [
  {
    id: '0',
    name: 'Hair',
    jobN: 2,
    applicationN: 16,
  },
  {
    id: '1',
    name: 'Face',
    jobN: 1,
    applicationN: 22,
  },
  {
    id: '2',
    name: 'Body',
    jobN: 19,
    applicationN: 54,
  },
];

@Component({
  selector: 'app-admin-jobs-overview',
  templateUrl: './admin-jobs-overview.component.html',
  styleUrls: ['./admin-jobs-overview.component.scss']
})
export class AdminJobsOverviewComponent implements OnInit {
  stateRangeArr = [
    { key: 'thisWeek', value: 'This week' },
    { key: 'thisMonth', value: 'This month' },
    { key: 'thisYear', value: 'This year' },
    { key: 'lastYear', value: 'Last year' },
  ]
  stateRange: any

  state = {
    jobN: 21,
    applicationN: 80,
    closedN: 17
  }

  displayedColumns: string[] = ['category', 'jobs', 'applications'];
  dataSource = STATE_DATA;

  constructor() {
    this.stateRange = 'thisYear'
  }

  ngOnInit(): void {
  }

  kFormatter(num: number) {
    return num > 999 ? (num/1000 + 'K') : num
  }
}
