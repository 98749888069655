<div class="my-jobs-page">
  <div class="flex items-center w-full window-mode">
    <h1 class="montserrat-bold mr-auto text-style">Messages</h1>
  </div>
  <div class="mt-4 mx-4 lg:mx-0 Messages-tabes">
    <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container common-arrow"
    >
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 cursor-pointer tab overflow-hide" (click)="selected.value = 1" [class]="selected.value === 1 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
              {{type === registerValues.UserTypes.candidate ? 'Recruiters' : 'Applicants'}}
            </p>&nbsp;
            <span class="badge" *ngIf="recruiterUnread"></span>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 2" [class]="selected.value === 2 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">
              Network
            </p>&nbsp;
            <span class="badge" *ngIf="myNetworkUnread"></span>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div>
            <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 3" [class]="selected.value === 3 ? 'selected' : ''">
              <p class="tab-size" [class]="selected.value === 3 ? 'blue montserrat-bold' : ''">
                Clients
              </p>&nbsp;
              <span class="badge" *ngIf="clientUnread"></span>
            </div>
          </div>
        </ng-template>
    </swiper>
    <mat-tab-group color="#fff0" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
      <mat-tab>
        <!-- <ng-template mat-tab-label>
          <div>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
              <p [class]="selected.value === 0 ? 'blue inter-bold' : ''" matTooltip="Coming Soon">Campaigns</p>
            </div>
          </div>
        </ng-template>
        <app-campaign-tab></app-campaign-tab> -->
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab overflow-hide" [class]="selected.value === 1 ? 'selected' : ''">
            <p [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
              {{type === registerValues.UserTypes.candidate ? 'Recruiters' : 'Applicants'}}
            </p>&nbsp;
            <span class="badge" *ngIf="recruiterUnread"></span>

          </div>
        </ng-template>
        <app-users-tab *ngIf="userProfile && selected.value === 1"
          (recruitersUnreadMessages)="recruitersUnreadMessages($event)" [reciever]="reciever" [sender_id]="sender_id"
          [user]="userProfile">
        </app-users-tab>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 2 ? 'selected' : ''">
            <p [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">
              Network
            </p>&nbsp;
            <span class="badge" *ngIf="myNetworkUnread"></span>
          </div>
        </ng-template>
        <app-my-network-tab *ngIf="userProfile&&selected.value === 2"
          (myNetworkUnreadMessages)="myNetworkUnreadMessages($event)" type="Network" [reciever]="networkUser"
          [sender_id]="sender_id" [user]="userProfile">
        </app-my-network-tab>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 3 ? 'selected' : ''">
              <p [class]="selected.value === 3 ? 'blue montserrat-bold' : ''">
                Clients
              </p>&nbsp;
              <span class="badge" *ngIf="clientUnread"></span>
            </div>
          </div>
        </ng-template>
        <app-client-tab *ngIf="userProfile && selected.value === 3"
          (clientUnreadMessages)="clientUnreadMessages($event)" [reciever]="clientUser" [sender_id]="sender_id"
          [user]="userProfile">
        </app-client-tab>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>