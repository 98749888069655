import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Subject, takeUntil } from 'rxjs';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { SnackBarService } from '../../services/snack-bar.service';
@Component({
  selector: 'app-stripe-payment-card',
  templateUrl: './stripe-payment-card.component.html',
  styleUrls: ['./stripe-payment-card.component.scss'],
})

export class StripePaymentCardComponent implements OnInit {
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  stripeCardValid: boolean = false;
  private unsubscribe$ = new Subject();
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(private stripService: StripeService, private stripePaymentService: paymentService, public dialogRef: MatDialogRef<StripePaymentCardComponent>, private spinner: NgxSpinnerService, private snack: SnackBarService) { }

  ngOnInit(): void { }

  onChange(event: any) {
    this.stripeCardValid = event.complete;
  }

  addNewCard() {
    this.spinner.show();
    this.stripeCardValid = false;
    this.stripService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
    }).pipe(takeUntil(this.unsubscribe$)).subscribe((stripeElement: any) => {
      this.stripePaymentService.addNewCard(stripeElement.paymentMethod.id).subscribe(() => {
        this.spinner.hide();
        this.snack.showMessage('New Card Added',false);
        this.close(true);
      });
    });
  }

  close(saved: boolean) {
    this.dialogRef.close(saved);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
