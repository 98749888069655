import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { MentionService } from '../../services/mentions.service';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss']
})
export class CommentModalComponent implements OnInit {
  private unsubscribe$ = new Subject();
  data: any;
  post_id!: string;
  postOwnerID!: string;
  profile: any;
  mentionUsers: any[] = [];
  showComment = false;
  commentForm: FormGroup;
  mentionConfig = {
    items: this.mentionUsers,
    labelKey: 'user_name',
    triggerChar: '@'
    // maxItems: 10
    //mentionSelect: this.mentionSelect
  };
  offset = 0;
  limit = 5;
  commentId: any;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public commentData: any,
    private _bottomSheetRef: MatBottomSheetRef<CommentModalComponent>,
    public fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private postService: PostService,
    private route: ActivatedRoute,
    public mentionService: MentionService,
    private snack: SnackBarService
  ) {
    this.commentForm = this.fb.group({
      comment: ['', [Validators.required, Validators.maxLength(256)]]
    });
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((comment: any) => {
      if (comment?.comment) {
        this.showComment = true;
        this.commentId = comment.comment;
      }
    });
  }

  ngOnInit(): void {
    let { data, postOwnerID, post_id, profile } = this.commentData;
    this.data = data;
    this.postOwnerID = postOwnerID;
    this.post_id = post_id;
    this.profile = profile;
  }

  loadMoreComments() {
    this.offset += 5;
    this.postService
      .getPostDetails(this.post_id, this.offset, this.limit, this.commentId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((postResult: any) => {
        let moreComments = postResult.data[0].commentDetails;

        this.data.commentDetails = [...this.data.commentDetails, ...moreComments];
      });
  }

  triggerFunction(event: any) {
    let extractComment = this.commentForm.get('comment')?.value.split(' ');
    let mentionUser = extractComment[extractComment.length - 1];
    if (mentionUser.includes('@') && event.keyCode !== 40 && event.keyCode !== 38) {
      let searchUser = mentionUser.replace('@', '');
      let params = {
        keyword: searchUser
      };
      this.postService
        .getMentionedCandidate(params)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.mentionUsers = res.data;
        });
    }
    if (event.ctrlKey && event.key === 'Enter') {
      let comment = this.commentForm.get('comment')?.value;
      comment += '\n';
      this.commentForm.get('comment')?.setValue(comment);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      this.submitForm();
    }
  }

  submitForm() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      if (this.commentForm.valid) {
        if (this.postOwnerID) {
          const options = {
            content_type: 'comment',
            comment: this.commentForm.get('comment')?.value,
            content_id: this.data._id,
            profile_id: this.postOwnerID
          };
          if (this.checkWhitespaces(this.commentForm.get('comment')?.value)?.length) {
            this.postService
              .addComment(options)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.status == 'success') {
                  if (this.data.commentDetails) {
                    this.data.totalThreads++;
                    this.data.commentDetails.unshift({
                      ...res.data,
                      userInfo: [
                        {
                          email: this.profile.email,
                          profile_img_path: this.profile.avatar,
                          user_name: this.profile.user_name,
                          userType: this.profile.user_type,
                          _id: this.profile._id
                        }
                      ],
                      likeDetails: [],
                      replyCommentDetails: []
                    });
                  }
                } else {
                  this.snack.showMessage(res.message, true);
                }
              });
            this.commentForm.get('comment')?.setValue('');
          }
        }
      }
    }
  }

  mentionSelect() {
    this.commentForm.get('comment')?.setValue(this.commentForm.get('comment')?.value + ' ');
  }

  isFavorComment(likes: any) {
    return likes?.findIndex((l: any) => l?.user_id === this.profile?._id) !== -1;
  }

  checkWhitespaces(text: string) {
    return text?.replace(/\s/g, '');
  }

  public errorHandling = (control: string, error: string) => {
    return this.commentForm.controls[control].hasError(error);
  };

  likeComment(user: any) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      const options = {
        user_id: this.profile?._id,
        content_id: user._id,
        content_type: 'like'
      };
      if (this.isFavorComment(user.likeDetails)) {
        const id = user.likeDetails.find((like: any) => like.user_id === this.profile?._id)._id;
        if (id) {
          this.postService
            .unLikePost(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: any) => {
              if (res.status === 'Success') {
                const u = {
                  ...user,
                  likeDetails: user.likeDetails.filter(
                    (like: any) => like.user_id !== this.profile?._id
                  )
                };
                this.data = {
                  ...this.data,
                  commentDetails: this.data.commentDetails.map((c: any) => {
                    if (c._id === u._id) return u;
                    return c;
                  })
                };
                this.data = {
                  ...this.data,
                  contentDetails: this.data.contentDetails.map((content: any) => {
                    if (content._id === this.data._id) return this.data;
                    return content;
                  })
                };
              }
            });
        }
      } else {
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res.status === 'success') {
              this.data = {
                ...this.data,
                commentDetails: this.data.commentDetails.map((comment: any) => {
                  if (comment._id === user._id) {
                    return {
                      ...user,
                      likeDetails: [
                        ...user.likeDetails,
                        {
                          ...res.data,
                          userInfo: [
                            {
                              email: this.profile.email,
                              profile_img_path: this.profile.avatar,
                              user_name: this.profile.user_name,
                              userType: this.profile.user_type,
                              _id: this.profile._id
                            }
                          ]
                        }
                      ]
                    };
                  }
                  return comment;
                })
              };
            }
          });
      }
    }
  }

  removeDeletedComment(commentId: any) {
    var indexToRemove = this.data.commentDetails.findIndex(
      (comment: any) => comment._id === commentId
    );

    // If the index is valid, remove the object from the array
    if (indexToRemove !== -1) {
      this.data.commentDetails.splice(indexToRemove, 1);
      this.data.totalThreads--;
    }
  }

  closeSheet(event: any) {
    this._bottomSheetRef.dismiss(event);
    // event?.preventDefault();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
