import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs';
import { planTypeConstant, userTypeConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { CommonService } from 'src/app/core/services/common.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { SubscriptionManagmentDirective } from 'src/app/utilities/subscription.management';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';
import { ServiceDetailModalComponent } from '../service-detail-modal/service-detail-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-dashboard-paid-services',
  templateUrl: './dashboard-paid-services.component.html',
  styleUrls: ['./dashboard-paid-services.component.scss']
})
export class DashboardPaidServicesComponent
  extends SubscriptionManagmentDirective
  implements OnInit
{
  routesConst = routesConst;
  purchased_services: any;
  stripe_user: any;
  userType: any;
  userTypeConstant = userTypeConstant;
  planTypeConstant = planTypeConstant;
  selectedRating: number = 1;
  userInfo: any;

  constructor(
    private dialog: MatDialog,
    private userPlan: userPlans,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private sharedService: SharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getPurchasedServices();
  }

  getCurrentUser() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((information: { profileInfo: any } | null) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else if (information != null) {
          this.userInfo = information;
        }
      });
  }

  getPurchasedServices() {
    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((subscription: any) => {
        const { purchased_services, stripe_user } = subscription.data;
        this.purchased_services = purchased_services;
        this.stripe_user = stripe_user;
      });
  }

  serviceDetails(service: any) {
    const dialogRef = this.dialog.open(ServiceDetailModalComponent, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      data: {
        service,
        service_id: service?.serviceDetails?._id,
        _id: service?._id,
        service_type: 'paid-service',
        isRefund: false
      },
      hasBackdrop: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPurchasedServices();
      }
    });
  }

  stripeExpress(service: any) {
    this.spinner.show();
    let { seller_account_id, customer } = service;
    let payload = { account_id: seller_account_id, customer_id: customer };
    this.userPlan
      .createBillingPortal(payload)
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((res: any) => {
        this.commonService.getWindow()?.open(res.billingSession.url, '_blank');
        this.spinner.hide();
      });
  }

  feedback(service: any) {
    const dialogRef = this.dialog.open(FeedbackModalComponent, {
      width: '440px',
      maxWidth: '90vw',
      data: service
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getPurchasedServices();
      }
    });
  }

  isReviewAllowded(service: any) {
    const userId = this.userInfo.profileInfo._id;
    return service.ratingDetails.some(
      (r: any) =>
        r.service_purchased_id == service._id && r.buyer_rating > 0 && r.buyer_id === userId
    );
  }
}
