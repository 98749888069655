<div class="service-detail">
  <div class="header w-full">
    <img class="w-10 absolute top-2 left-3"
      src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">
    <p class="montserrat-bold p-4 justify-center text-white items-center flex">
      Service Details
    </p>
    <div class="absolute right-0 top-0 mt-1">
      <button mat-icon-button (click)="close($event)">
        <mat-icon class="text-white">close</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
  </div>
  <div class="px-4 pb-4 detail-wraper">
    <div class="flex items-center gap-3 mt-4">
      <img [routerLink]="'/profile/'+ selectedService?.profile?.user_name" mat-dialog-close
        [src]="selectedService?.profile?.profile_img_path" class="avatar-profile rounded-full object-cover border"
        onerror="this.src='assets/images/no-image.png'" />
      <p class="user-name">{{selectedService?.profile?.name}}</p>

    </div>

    <div class="mt-4 px-0" *ngIf="slides">
      <div id="service-img" class="slide-wrapper relative">
        <!-- <span *ngIf="slides?.length && slides?.length > 1" class="slide-number">{{
      activeSlide + "/" + slides?.length
    }}</span> -->
        <swiper [pagination]="slides?.length > 1" (slideChange)="onSlideChange($event)">
          <ng-template swiperSlide *ngFor="let portfolio of slides">
            <img [src]="portfolio?.path" class="w-full object-fill cursor-pointer img-width" />
          </ng-template>
        </swiper>
      </div>
    </div>
    <div class="mb-6 pricingPackage-swipper">
      <div class="grid grid-cols-3">
        <div class="plans-btn border" (click)="selectPackage('Basic')"
          [ngClass]="selectedPlan === 'Basic' ? 'selected-plan' : ''">
          Basic
        </div>
        <div class="plans-btn border-y" (click)="selectPackage('Standard')"
          [ngClass]="selectedPlan === 'Standard' ? 'selected-plan' : ''">
          Standard
        </div>
        <div class="plans-btn border" (click)="selectPackage('Premium')"
          [ngClass]="selectedPlan === 'Premium' ? 'selected-plan' : ''">
          Premium
        </div>
      </div>
      <div class="flex flex-col md:flex-row flex-wrap justify-center mx-auto gap-6 lg:gap-2 card-container">
        <ng-container>
          <div *ngIf="selectedPackage" class="border-remove border border-gray-300 rounded">
            <div class="px-6 pb-5 pt-8">
              <div class="mb-2">

                <div class="flex justify-between">
                  <p class="title-wrapper">{{ selectedService.title }}</p>
                  <h3 class="text-color cards-font flex justify-center service-price font-bold">
                    ${{ selectedPackage.price}}<sub *ngIf="pricingType === 'Monthly' || pricingType === 'Weekly'"
                      class="text-color text-base font-bold">/{{ pricingType === "Weekly" ? "wkly " : "mo" }}</sub>
                  </h3>
                </div>
                <p class="descrption black-light break-words">
                  {{ selectedPackage?.description }}
                </p>
              </div>
              <div>
                <div class="mb-5">
                  <ng-container *ngFor="let feature of selectedPackage.features">
                    <div class="my-1 break-words gap-3 cards-font text-lg flex items-center">
                      <mat-icon class="item-icon font-bold">check</mat-icon>
                      <p class="item-text font-bold">{{ feature }}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div>
                <button class="package-btn" *ngIf="selectedService?.profile_id != loginedUser?._id"
                  (click)="purchaseService(selectedService, 'Service')">
                  {{ selectedPackage.has_purchased_plan ? 'Repay':'Pay'}}
                </button>
                <button mat-dialog-close *ngIf="selectedService?.profile_id != loginedUser?._id"
                  class="py-1.5 message-btn" (click)="message(selectedService)">
                  Message
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <p *ngIf="
        data?.service_type == 'paid-service' &&
        !data?.service?.refund?.length &&
        isRefundable
      " (click)="refundService()" class="cursor-pointer blue text-center">
      send refund request
    </p>

    <p *ngIf="
        data?.service_type == 'paid-service' && data?.service?.refund?.length
      " class="cursor-pointer blue text-center">
      refund request sent
    </p>
    <div *ngIf="data?.service_type == 'sold-service'">
      <div class="flex justify-between p-2 border border-b-0">
        <p class="font-bold">Customer Name:</p>
        <p>{{ data?.service?.buyer?.name }}</p>
      </div>
      <div class="flex justify-between p-2 border">
        <p class="font-bold">Date:</p>
        <p>{{ data?.service?.created_at | date : "mediumDate" }}</p>
      </div>
      <p *ngIf="data?.service?.refund?.length" class="text-center p-2 blue">
        {{ data?.service?.buyer?.name }} <br> sent a refund request
      </p>
      <p *ngIf="
          data?.service?.refund[0]?.refund_request_status ==
          RefundStatusConst.APPROVED
        " class="cursor-pointer text-center refund-status">
        Refund request approved on:
        {{ data?.service?.refund[0]?.supplier_action_at | date : "mediumDate" }}
      </p>

      <p *ngIf="
          data?.service?.refund[0]?.refund_request_status ==
          RefundStatusConst.DECLINED
        " class="cursor-pointer text-center refund-status">
        Refund request declined on:
        {{ data?.service?.refund[0]?.supplier_action_at | date : "mediumDate" }}
      </p>
    </div>
    <div *ngIf="data?.service_type == 'sold-service' &&  selectedPackage?.has_purchased_plan"
      class="flex items-end w-full">
      <button class="py-2 px-4 rounded shadow-md cursor-pointer bg-[#2887ed] text-white mt-2" matDialogClose
        (click)="onAddReview(data.service,$event)">
        Add Review
      </button>
    </div>
  </div>
  <div *ngIf="
      data?.service_type == 'sold-service' &&
      data?.service?.refund?.length &&
      data?.service?.refund[0]?.refund_request_status !==
        RefundStatusConst.APPROVED
    " class="w-full sticky bottom-0 bg-white flex justify-between items-center h-20 border-2">
    <div class="button-wrapper px-4 w-full flex justify-between">
      <button *ngIf="
          data?.service?.refund[0]?.refund_request_status ==
          RefundStatusConst.PENDING
        " mat-flat-button class="button outlined black" (click)="updateRefundRequest(RefundStatusConst.DECLINED)">
        Decline
      </button>
      <!-- <button  *ngIf="
      (data?.service_type == 'sold-service') &&
        (data?.service?.refund?.length &&
        data?.service?.refund[0]?.refund_request_status !==
          RefundStatusConst.APPROVED)" mat-button class="button black" (click)="dispute()">
          {{ disputeNotification?.length &&
            checkDisputable(disputeNotification) ? 'Response to dispute ' : 'Dispute'}}
      </button> -->
      <button *ngIf="
          data?.service?.refund[0]?.refund_request_status ==
          RefundStatusConst.PENDING
        " mat-button class="button black" (click)="updateRefundRequest(RefundStatusConst.APPROVED)">
        Approve
      </button>
    </div>
  </div>
  <div *ngIf="
      data?.service_type == 'paid-service' &&
      ((data?.service?.refund?.length &&
        checkDate(data?.service?.refund[0]?.created_at) &&
        data?.service?.refund[0]?.refund_request_status ==
          RefundStatusConst.PENDING) ||
        data?.service?.refund[0]?.refund_request_status ==
          RefundStatusConst.DECLINED)
    " class="w-full sticky bottom-0 bg-white flex justify-between items-center h-20 border-2">
    <div class="button-wrapper px-4 w-full flex justify-center">
      <button class="py-1.5 message-btn" (click)="stripeExpress(data?.service)">
        Dispute
      </button>
    </div>
  </div>
</div>
<ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
  [fullScreen]="true" class="job-list-spinner">
</ngx-spinner>