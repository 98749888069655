<div class="flex justify-between  mt-2">
  <div class="flex items-start reaction">
    <!-- LikeIcon -->
    <div class="flex flex-col">
      <button
        [disabled]="isLoadingLike"
        class="cursor-pointer h-[32px]"
        (click)="likePost()"
      >
        <mat-icon class="heart-button-icon text-[32px] font-medium" [class]="isUserLiked() ? 'red' : ''">{{
          isUserLiked() ? 'favorite' : 'favorite_border'
        }}</mat-icon>
      </button>
      <div class="counter flex justify-center" *ngIf="data.likeDetails?.length">
        <span > {{ data.likeDetails?.length }}</span>
      </div>
    </div>
    <!-- chat icon -->
     <div class="flex flex-col">
       <button class="flex justify-center  h-[32px]" (click)="showComments(); openBottomSheet()">
         <svg
           xmlns="http://www.w3.org/2000/svg"
           x="0px"
           y="0px"
           width="28"
           height="28"
           viewBox="0 0 48 48"
           style="transform: scaleX(-1) scaleY(1); opacity: 0.8"
         >
           <path
             d="M 24 4 C 12.972292 4 4 12.972292 4 24 C 4 27.275316 4.8627078 30.334853 6.2617188 33.064453 L 4.09375 40.828125 C 3.5887973 42.631528 5.3719261 44.41261 7.1757812 43.908203 L 14.943359 41.740234 C 17.671046 43.137358 20.726959 44 24 44 C 35.027708 44 44 35.027708 44 24 C 44 12.972292 35.027708 4 24 4 z M 24 7 C 33.406292 7 41 14.593708 41 24 C 41 33.406292 33.406292 41 24 41 C 20.997029 41 18.192258 40.218281 15.744141 38.853516 A 1.50015 1.50015 0 0 0 14.609375 38.71875 L 7.2226562 40.78125 L 9.2851562 33.398438 A 1.50015 1.50015 0 0 0 9.1503906 32.263672 C 7.7836522 29.813476 7 27.004518 7 24 C 7 14.593708 14.593708 7 24 7 z"
             stroke="black"
             stroke-width="1"
             fill="black"
           ></path>
         </svg>
       </button>
       <div class="counter flex justify-center" *ngIf="data.totalThreads">
        <span >{{ data.totalThreads }}</span>
      </div>
     </div>
    <!-- share button -->
    <button class="flex justify-center h-[32px] items-center" [matMenuTriggerFor]="exploreMenu">
      <svg
        class="w-6 h-6 text-gray-800 dark:text-white ml-2"
        style="margin-top: -7px; transform: rotate(68deg)"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 20"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
        />
      </svg>
    </button>
  </div>
  <!-- Views section -->
  <div class="flex items-start gap-2">
    <div class="flex flex-col">
      <div>
        <img src="../../../../assets/images/icons/eye-outline (2).png" class="h-5" alt="" />
      </div>
      <div class="counter flex justify-center">
        <span>{{ data.views }} </span>
      </div>
    </div>
    <p class="text-sm montserrat-medium" *ngIf="data.created_at">
      {{ formatPostDate(data.created_at) }}
    </p>
  </div>
</div>
<mat-menu #exploreMenu="matMenu">
  <div class="grid grid-cols-3 gap-2 cursor-pointer">
    <div class="flex flex-col items-center">
      <button
        class="flex items-center justify-center w-10 h-10 rounded-full border border-gray-800 p-1"
        (click)="copyLink()"
      >
        <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
      </button>
      <span class="text-xs">Copy</span>
    </div>
    <div class="flex flex-col items-center">
      <button
        (click)="sharePost()"
        class="flex items-center justify-center w-10 h-10 rounded-full border border-gray-800 p-1 mx-2"
      >
        <mat-icon>share</mat-icon>
      </button>
      <span class="text-xs">Share</span>
    </div>
    <div class="flex flex-col items-center">
      <button
        class="flex items-center justify-center w-10 h-10 rounded-full border border-red-400 p-1"
      >
        <mat-icon class="text-red-400">close</mat-icon>
      </button>
      <span class="text-xs text-red-400">Cancel</span>
    </div>
  </div>
</mat-menu>
<p class="s h-fit text-black break-words max-h-full description w-full my-1 home-post-description">
  {{ data.description }}
</p>
<div class="flex flex-wrap home-post-interests">
  <p class="text-sm blue mr-2 cursor-pointer hover:underline" *ngFor="let item of data.tags">
    {{ item }}
  </p>
</div>
<!-- COMMENT SECTION TO SHOW ONLY LAPTOP SCREEN -->
<div *ngIf="innerWidth > 768" class="w-full bottom-0 bg-white pt-2">
  <div class="" *ngIf="showComment">
    <div class="flex relative items-center justify-center w-full py-4">
      <p class="montserrat-bold cursor-pointer blue" (click)="showComments(); openBottomSheet()">
        Comments
      </p>
    </div>
    <div class="comment-list custom-content-vscroll pr-5 pl-4 mb-5">
      <div *ngFor="let user of data.commentDetails">
        <app-comment-card
          *ngIf="user?.userInfo[0]"
          [post_id]="data._id"
          [commentInfo]="user"
          [id]="user_id"
          [isFavor]="isFavorComment(user.likeDetails)"
          (likeComment)="likeComment($event)"
          (deletedCommentId)="removeDeletedComment($event)"
          (hashtagClicked)="searchByInterest($event)"
          [contentType]="data.contentType"
        >
        </app-comment-card>
      </div>
      <button
        *ngIf="data.commentDetails.length !== data.totalThreads"
        class="mx-auto my-2 flex blue text-sm custom-button"
        [class.loading]="isLoading"
        (click)="loadMoreComments()"
      >
        <span class="blue" *ngIf="!isLoading">Load More</span>
        <div *ngIf="isLoading" class="loader"></div>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>
  <form class="flex w-full relative" [formGroup]="commentForm" (ngSubmit)="submitForm()" novalidate>
    <div class="flex-col w-full relative">
      <mat-form-field class="mention-list-wrapper">
        <textarea
          appMentionTrigger
          class="text-area"
          [mentionConfig]="mentionService.mentionConfig"
          (itemSelected)="mentionSelect($event)"
          [mentionListTemplate]="mentionListTemplate"
          style="padding-right: 86px"
          maxlength="257"
          matInput
          cdkTextareaAutosize
          placeholder="Write a comment"
          formControlName="comment"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="2"
          #textarea
          #autosize="cdkTextareaAutosize"
        ></textarea>
        <ng-template #mentionListTemplate let-item="item">
          <div class="flex items-center">
            <div class="rounded-full h-10 w-10 overflow-hidden">
              <img
                *ngIf="item.isShowImage"
                class="rounded-full h-full w-full overflow-hidden"
                onerror="this.src='assets/images/no-image.png'"
                [src]="item.profile_img_path"
                alt=""
              />
            </div>
            &nbsp;
            <span class="ml-2">{{ item.user_name }}</span>
          </div>
        </ng-template>
      </mat-form-field>
      <mat-error
        *ngIf="errorHandling('comment', 'maxlength')"
        [class]="errorHandling('comment', 'maxlength') ? 'pb-4 pt-1' : ''"
      >
        <p class="s red">Comment can not exceed more than 256 characters!</p>
      </mat-error>
    </div>
    <div class="flex items-center absolute right-1.5 top-0.5 h-full comment-btn-position">
      <button
        *ngIf="checkWhitespaces(textarea.value).length"
        mat-button
        class="button outlined black"
        type="submit"
      >
        Post
      </button>
    </div>
  </form>
</div>
