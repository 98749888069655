import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { statusConstant } from 'src/app/const/appConst';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { SharedService } from '../../services/shared.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { routesConst } from 'src/app/core/const/routers';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';

@Component({
  selector: 'app-pricing-package-card',
  templateUrl: './pricing-package-card.component.html',
  styleUrls: ['./pricing-package-card.component.scss']
})
export class PricingPackageCardComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() pricingPackage: any;
  @Input() isDashboardService: any;
  @Input() isProfileSubscription: any;

  @Input() set serviceOwner(value: any) {
    if (value) {
      this.profile = value;
    }
  }

  @Input() set service(value: any) {
    if (value) {
      this.service_id = value;
    }
  }
  profile: any;
  service_id: any;
  loginedUser: any;

  constructor(
    public dialog: MatDialog,
    private snack: SnackBarService,
    private spinner: NgxSpinnerService,
    private stripeService: paymentService,
    private talentService: TalentService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private auth: AuthService,
    private profileService: ProfileService,
    private commonService: CommonService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.auth.loggedIn()) {
      this.dashboard();
    }
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      if (params?.profile && params.id) {
        this.profile = params?.profile;
        this.service_id = params?.id;
      }
    });
  }

  get pricingType() {
    let typeList: any;
    this.sharedService.billingTypeList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(billingType => {
        if (billingType.length) {
          typeList = billingType;
        } else {
          this.sharedService.getBillingTypes();
        }
      });
    return typeList?.find((type: any) => type?.id === this.pricingPackage?.billing_type)?.label;
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.loginedUser = profileInfo;
      }
    });
  }

  async purchaseService(event: any, purchasedType: string) {
    if (this.auth.loggedIn()) {
      if (!this.profile) {
        this.snack.showMessage('UnKnown Service', true);
        return;
      }
      if (this.profile == this.loginedUser?.user_name) {
        this.snack.showMessage("You can't purchase your own Service", true);
        return;
      }
      this.profileService
        .getOnBoardStatus()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((user: any) => {
          if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
              width: '500px',
              data: {
                message: `Complete one time Stripe onboarding
                to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
                header: 'Stripe Onboarding'
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                localStorage.setItem(
                  'purchase_plan_type',
                  JSON.stringify({ plan_type: 'Recruiter' })
                );
                this.router.navigate(['/' + routesConst.planPricing]);
              }
            });
          } else {
            this.purchase(event, purchasedType);
          }
        });
    } else {
      this.spinner.hide();
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  purchase(event: any, purchasedType: any) {
    // this.spinner.show();
    const redirect_url = this.commonService.location().href
    const refresh_url = this.commonService.location().href
    this.talentService
      .getStripeUser(redirect_url, refresh_url)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.status == 'on_board_required') {
            this.snack.showMessage(`${res.message}`, false);
            this.spinner.hide();
            this.commonService.location().href = res.accountLink.url;
          } else {
            let params: any = {
              mode: event.billing_type == '61e1a8fa54f7e11728dcfc86' ? 'payment' : 'subscription',
              cancel_url: `${window.origin}/failed`,
              success_url: `${window.origin}/success`,
              line_items: [
                {
                  price: event.price_id,
                  quantity: 1
                }
              ]
            };
            // this.spinner.show();
            this.profileService
              .getCommunityPageUserProfile(this.profile)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((x: any) => {
                if (x?.data && x?.data[0]) {
                  const { _id, user_name } = x.data[0].profile;
                  this.stripeService
                    .purchaseProduct(
                      params,
                      _id,
                      user_name,
                      this.loginedUser._id,
                      this.service_id,
                      purchasedType,
                      event.billing_type
                    )
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                      (res: any) => {
                        this.spinner.hide();
                        if (res && res?.accountLink?.url) {
                          this.commonService.location().href = res?.accountLink?.url;
                        } else {
                          this.spinner.hide();
                          this.snack.showMessage(
                            'Unable to connect to Stripe at the moment, Please try again later',
                            true
                          );
                        }
                      },
                      error => {
                        this.spinner.hide();
                        this.snack.showMessage(`${error}`, true);
                      }
                    );
                }
              });
          }
        },
        error => {
          this.spinner.hide();
          this.snack.showMessage(`${error} `, true);
        }
      );
  }

  getDescription(item: any) {
    const maxLength = 140;
    item.isBigger = maxLength < item?.description?.length;
    return item.isMore && maxLength < item?.description?.length
      ? item?.description?.substring(0, maxLength)
      : item?.description;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
