import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';
import { MentionService } from 'src/app/shared/services/mentions.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit {
  private unsubscribe$ = new Subject();
  followings: any[] = [];
  selectedUsers: any [] = [];
  searchIdx = '';
  searchByUser: any = '';
  filteredUser!: any[];
  shareContentBtn = false;

  constructor(
    private postService: PostService,
    private snackService: SnackBarService,
    private authService: AuthService,
    public dialog: MatDialogRef<ShareModalComponent>,
    private sharedService: SharedService,
    public mentionService: MentionService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.getFollowersAndFollowings();
    }
  }

  mentionSelect(item: any) {
    
    if (item.trigger == '@') {
      // this.searchByUser = item.user_name;
      this.filteredUser = [item]
    }
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network) => {
        if (network?.followings || network?.followers) {
          let share = network.followings.concat(network.followers);
          // Assuming share is your original array of items
        this.followings = Array.from(new Map(share.map((item: any) => [item['user_name'],
         { ...item, selectedUserToSend: false }])).values());          
        this.filtertedUser()
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  filtertedUser(){
    this.filteredUser = this.followings?.filter((profile) =>
      profile?.user_name?.toLocaleLowerCase()
        .includes(this.searchIdx?.toLocaleLowerCase())
    );

  }


  // remove(index: number) {
  //   this.selectedUsers.splice(index, 1);
  // }

  // isSelected(user_id: string) {
  //   const isSelected = this.selectedUsers.find((s) => s.user_id === user_id);
  //   return !!isSelected;
  // }

  // setActive(event: any, user_id: string) {
  //   event.target.disabled = true;
  //   const sendButton: HTMLDivElement = <HTMLDivElement>event.target;

  //   const isSelected = this.selectedUsers.find((s) => s.user_id === user_id);
  //   const selected = this.followings.find((f) => f.user_id === user_id);
  //   if (isSelected)
  //     this.selectedUsers = this.selectedUsers.filter(
  //       (s) => s.user_id !== user_id
  //     );
  //   else if (selected)
  //     if (this.selectedUsers.length > 0) {
  //       this.selectedUsers.push(selected);
  //     } else this.selectedUsers = [selected];
  //   this.searchIdx = '';
  //   this.share(selected, sendButton);
  // }

  selectedUserChange(user:any){
    if (this.selectedUsers?.length > 0) {
      let isUserFoundIndex = this.selectedUsers?.findIndex((item:any) => item.user_id === user.user_id)
      if (isUserFoundIndex != -1) {
        this.selectedUsers.splice(isUserFoundIndex,1)
      }else{
        this.selectedUsers.push(user)
      }
    }else{
      this.selectedUsers.push(user);
    }

    if (this.selectedUsers?.length > 0) {
      this.shareContentBtn = true
    }else{
      this.shareContentBtn = false
    }
  }

  share() {
    this.spinner.show()
    
    this.shareContentBtn = false
    let allSelectedUsersId = this.selectedUsers?.filter((item:any) => item.selectedUserToSend === true)?.map((item: any) => item.user_id);
    
    this.postService
      .sharePost({
        message_content: this.data.link,
        message_to: allSelectedUsersId,
        content_id: this.data.content_id ? this.data.content_id : null,
        shareType: this.data.shareType,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.snackService.showMessage(`Shared successfully!`, false);
          this.spinner.hide()
          this.dialog.close()
        }
      },
      (error) => {
        this.snackService.showMessage('Failed to share post. Please try again later.', true);
        this.spinner.hide();
        this.dialog.close()
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
