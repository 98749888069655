import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { shareTypeConst, userTypeConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { JobService } from 'src/app/core/services/job.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { JobApplyModalComponent } from 'src/app/modules/jobs/modals/job-apply-modal/job-apply-modal.component';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { SharedService } from '../../services/shared.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { ReviewApplicantComponent } from 'src/app/modules/dashboard/pages/my-jobs/tabs/review-applicant/review-applicant.component';
import { JobPostComponent } from '../job-post/job-post.component';

@Component({
  selector: 'app-job-modal',
  templateUrl: './job-modal.component.html',
  styleUrls: ['./job-modal.component.scss'],
})
export class JobModalComponent implements OnInit {
  userRole = userTypeConstant;
  private unsubscribe$ = new Subject();
  job: any;
  myFollowings: any;
  appliedJobs: any;
  loginedUserId!: string;
  constructor(
    private dialogRef: MatDialogRef<JobModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private jobService: JobService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private router: Router,
    private clipboard: Clipboard,
    private snackService: SnackBarService,
    private dialog: MatDialog,
    private networkService: NetworkService,
    private authService: AuthService,
    private snackBar: SnackBarService,
    private sharedService: SharedService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
      this.getFollowings();
      this.getUserAppliedJobs();
    } else {
      this.getJobDetail();
    }
  }

  onClose() {
    this.dialogRef.close(this.job.data);
  }

  messageRecuirter(company: any) {
    this.dialogRef.close(this.job.data);
    localStorage.setItem('companyId', JSON.stringify(company));
    this.router.navigate(['/dashboard/messages'], {
      queryParams: { tab: 1 },
    });
    this.dialogRef.close(this.job.data);
  }

  /**
   * Get Location Data
   * @param job
   * @returns
   */
  getLocation(job: any) {
    return (
      job?.location?.city_name +
      (job?.location?.state_name ? `, ${job?.location?.state_name} ` : '') +
      (job?.location?.country_code ? `, ${job?.location?.country_code} ` : '')
    );
  }

  getFollowings() {
    this.networkService
      .getFollowings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network: any) => {
        this.myFollowings = network.data;
      });
  }

  showProfile(e: any) {
    this.dialogRef.close(this.job.data);
    e.stopPropagation();
    this.router.navigate([
      '/profile' + '/' + this.job?.data?.companyDetails?.user_name,
    ]);
  }

  /**
   * Format Date according JobPosted Date
   * @param previousDate
   * @returns
   */
  formatJobDate(previousDate?: any) {
    let currentDate = new Date().getTime();
    let jobDate = new Date(previousDate).getTime();
    let time = (currentDate - jobDate) / 1000;
    let days = Math.floor(time / (3600 * 24));
    let minute = Math.floor(time / 60);
    let hour = Math.floor(time / (60 * 60));
    if (minute < 60 && hour < 1) {
      return minute + ' minutes ago';
    } else if (hour < 24 && hour > 0) {
      return hour + ' hours ago';
    } else if (days < 7 && days >= 1) {
      return days + ' days ago';
    } else {
      return this.datePipe.transform(previousDate, 'MMM d, y');
    }
  }

  share() {
    if (this.authService?.loggedIn()) {
      this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px': '100%',
        maxWidth:window.innerWidth > 600 ? '80vw': '100vw',
        data: {
          link:
            this.commonService.location().origin +
            `/${routesConst.jobs}?id=` +
            this.job.data._id,
          content_id: this.job.data._id,
          shareType: shareTypeConst.job,
        },
      });
      this.dialogRef.close(this.job.data);
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
      this.dialogRef.close(this.job.data);
    }
  }

  copyLink() {
    this.clipboard.copy(
      this.commonService.location().origin +
        this.commonService.location().pathname +
        '?id=' +
        this.job.data._id
    );
    this.snackService.showMessage(`Link Copied`, false);
  }

  /**
   * Get User's Applied Jobs
   */
  getUserAppliedJobs() {
    this.jobService
      .checkForJobsApplied()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        this.getJobDetail();
        if (data && data?.data && data?.data?.length) {
          this.appliedJobs = data.data;
        }
      });
  }

  getJobDetail() {
    this.spinner.show();
    this.jobService
      .getJobById(this.data?.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.job = res;
        this.job.data.isApplied = this.appliedJobs?.some(
          (p: any) => p.job_id == this.job?.data?._id
        );
        this.job.data.status = this.appliedJobs?.find(
          (j: any) => j.job_id === this.job?.data?._id
        )?.applied_status;
        this.job.data.isSaved = this.appliedJobs?.find(
          (j: any) => j.job_id === this.job?.data?._id
        )?.status_type;
        this.spinner.hide();
      });
  }

  saveJob(selectedJob: any) {
    if (
      selectedJob?.company_id &&
      selectedJob?._id &&
      this.authService.loggedIn()
    ) {
      const appliedJobParams = {
        company_id: selectedJob?.company_id,
        job_id: selectedJob?._id,
      };
      this.jobService
        .saveJob(appliedJobParams)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.job.data.isSaved = true;
            this.snackBar.showMessage('Successfully saved this job.', false);
          },
          () => {
            this.snackBar.showMessage('Failed to save this job.', true);
          }
        );
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
      this.dialogRef.close(this.job.data);
    }
  }
  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else {
          const { profileInfo } = information;
          this.loginedUserId = profileInfo._id;
        }
      });
  }

  openApplyDialog(selectedJob: any): void {
    if (selectedJob && this.authService.loggedIn()) {
      if (selectedJob?.company_id == this.loginedUserId) {
        this.snackBar.showMessage('You cannot apply to your own Job', true);
        return;
      }
      this.dialog.open(JobApplyModalComponent, {
        maxWidth:'100%',
        width: '536px',
        height: window.innerWidth && window.innerWidth > 768 ? '650px' : '100vh',
        data: {
          job: { job: selectedJob, company: selectedJob.companyDetails },
        },
      });
      this.dialogRef.close(this.job.data);
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
      this.dialogRef.close(this.job.data);
    }
  }

  reviewApplicant(Id: String, jobTitle: String) {
    this.dialogRef.close(this.job.data);
    this.dialog.open(ReviewApplicantComponent, {
      width: '816px',
      height: '100vh',

      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { id: Id, title: jobTitle, skills: this.data?.interests },
    });
  }

  editJob(jobId: String) {
    this.dialogRef.close(this.job.data);
    const dialogRef = this.dialog.open(JobPostComponent, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      hasBackdrop: false,
      panelClass: 'post-job-dialog-container',
      data: { jobId: jobId },
    });
  }

  activeDeactiveJob(job: any, active: any) {
    let selectedJob = {
      _id: job._id,
      is_active: active,
    };
    this.dialogRef.close({ action: 'activeDeactiveJob', selectedJob });
  }

  unsaveJob(job: any, index: any) {
    const selectedJob = {
      jobId: job._id,
      index: index,
    };
    this.dialogRef.close({ action: 'unsaveJob', selectedJob });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
