<div class="post-page_main relative rounded w-full flex flex-col justify-between mt-5 mx-auto" [ngClass]="feedCardType === 'POST' ? 'max-w-[630px]' : ''">
  <div class="grid lg:grid-cols-1 gap-0 home-post-wrapper">
    <div
      class="w-full flex items-center justify-between mb-3 button-wrapper mx-auto"
      style="max-width: 630px"
    >
      <div class="flex items-center cursor-pointer" (click)="showProfile()">
        <img
          [src]="
            postOwnerAvatar?.length
              ? postOwnerAvatar
              : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs'
          "
          class="rounded-full overflow-hidden class-avatar-post-detail object-cover w-12 md:w-16 h-12 md:h-16 ml-0.5"
        />
        <p class="montserrat-bold break-all text-sm ml-1 md:ml-2">
          {{ postOwnerName }}
        </p>
      </div>
      <p
        *ngIf="
          data?.create_by && profile?._id == postOwnerID && postOwnerID !== data?.create_by?._id
        "
        class="montserrat-bold text-sm"
      >
        By: @{{ data?.create_by?.user_name }}
      </p>
      <app-follow-state
        [showButton]="true"
        *ngIf="profile?._id !== postOwnerID"
        [following]="followers"
        [style]="'text-xs blue mr-auto cursor-pointer'"
        [target_user_id]="postOwnerID"
      ></app-follow-state>
    </div>
    <div class="post-page_main_img relative flex flex-col mx-auto justify-center items-center">
      <span *ngIf="images?.length && images?.length > 1" class="slide-number">{{
        activeSlide + '/' + images?.length
      }}</span>
      <swiper
        class="creator-post-swipper"
        *ngIf="images"
        [pagination]="{ clickable: true }"
        (slideChange)="onSlideChange($event)"
      >
        <ng-template swiperSlide *ngFor="let item of images">
          <div (click)="showPost()" class="relative w-full h-fit flex">
            <img
              *ngIf="item.media_type === 'image' || item.media_type === 'moment'"
              [src]="item.url"
              class="w-full post-content"
            />
            <video
              #videoPlayer
              *ngIf="
                item.media_type === 'video' ||
                item.media_type === 'clip' ||
                item?.media_type === 'highlight' ||
                item?.media_type === 'trailer'
              "
              class="w-full cursor-pointer post-content post-img"
              [src]="item.url + '#t=0.1'"
              preload="metadata"
              controls
              [muted]="'muted'"
              playsinline
              webkit-playsinline
              (play)="addViews(post_id)"
            ></video>
          </div>
        </ng-template>
      </swiper>
    </div>

    <div class="post-page_main_right w-full relative">
      <div class="flex justify-between w-full flex-col">
        <div class="flex text-center justify-between mt-2 z-10">
          <div class="flex flex-col">
            <div class="flex items-center reaction">
              <!-- LikeIcon -->
              <button
                [disabled]="isLoadingLike"
                class="cursor-pointer"
                style="width: 35px !important; justify-content: start !important"
                mat-icon-button
                (click)="likePost()"
              >
                <mat-icon class="heart-button" [class]="isUserLiked() ? 'red' : ''">{{
                  isUserLiked() ? 'favorite' : 'favorite_border'
                }}</mat-icon>
              </button>
              <!-- chat icon -->
              <button
                class="flex justify-center"
                mat-icon-button
                (click)="showComments(); openBottomSheet()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="28"
                  height="28"
                  viewBox="0 0 48 48"
                  style="transform: scaleX(-1) scaleY(1); opacity: 0.8"
                >
                  <path
                    d="M 24 4 C 12.972292 4 4 12.972292 4 24 C 4 27.275316 4.8627078 30.334853 6.2617188 33.064453 L 4.09375 40.828125 C 3.5887973 42.631528 5.3719261 44.41261 7.1757812 43.908203 L 14.943359 41.740234 C 17.671046 43.137358 20.726959 44 24 44 C 35.027708 44 44 35.027708 44 24 C 44 12.972292 35.027708 4 24 4 z M 24 7 C 33.406292 7 41 14.593708 41 24 C 41 33.406292 33.406292 41 24 41 C 20.997029 41 18.192258 40.218281 15.744141 38.853516 A 1.50015 1.50015 0 0 0 14.609375 38.71875 L 7.2226562 40.78125 L 9.2851562 33.398438 A 1.50015 1.50015 0 0 0 9.1503906 32.263672 C 7.7836522 29.813476 7 27.004518 7 24 C 7 14.593708 14.593708 7 24 7 z"
                    stroke="black"
                    stroke-width="1"
                    fill="black"
                  ></path>
                </svg>
              </button>
              <!-- share button -->
              <button class="flex justify-center" mat-icon-button [matMenuTriggerFor]="exploreMenu">
                <svg
                  class="w-6 h-6 text-gray-800 dark:text-white ml-2"
                  style="margin-top: -7px; transform: rotate(68deg)"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
                  />
                </svg>
              </button>
            </div>
            <div class="flex items-center">
              <div class="counter">
                <span *ngIf="data?.likeDetails?.length"> {{ data?.likeDetails?.length }}</span>
              </div>
              <div class="counter">
                <span *ngIf="data?.totalThreads">{{ data?.totalThreads }}</span>
              </div>
              <div class="counter">&nbsp;</div>
            </div>
          </div>

          <!-- comment div end -->
          <div class="flex items-start md:py-2 gap-2">
            <div class="flex flex-col">
              <div>
                <img src="../../../../assets/images/icons/eye-outline (2).png" class="h-5" alt="" />
                <!-- <mat-icon class="ml-2 text-2xl">remove_red_eye</mat-icon> -->
                <!-- <i class="fa fa-solid fa-glasses ml-2 text-2xl"></i> -->
              </div>
              <div class="counter">
                <span>{{ data?.views }} </span>
              </div>
            </div>
            <p class="text-sm montserrat-medium" *ngIf="data?.created_at">
              {{ formatPostDate(data?.created_at) }}
            </p>
          </div>
          <mat-menu #exploreMenu="matMenu">
            <div class="grid grid-cols-3 gap-2 cursor-pointer">
              <div class="flex flex-col items-center">
                <button
                  class="flex items-center justify-center w-10 h-10 rounded-full border border-gray-800 p-1"
                  (click)="copyLink()"
                >
                  <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
                </button>
                <span class="text-xs">Copy</span>
              </div>
              <div class="flex flex-col items-center">
                <button
                  (click)="sharePost()"
                  class="flex items-center justify-center w-10 h-10 rounded-full border border-gray-800 p-1 mx-2"
                >
                  <mat-icon>share</mat-icon>
                </button>
                <span class="text-xs">Share</span>
              </div>
              <div class="flex flex-col items-center">
                <button
                  class="flex items-center justify-center w-10 h-10 rounded-full border border-red-400 p-1"
                >
                  <mat-icon class="text-red-400">close</mat-icon>
                </button>
                <span class="text-xs text-red-400">Cancel</span>
              </div>
            </div>
          </mat-menu>
        </div>
        <div class="post-page_main_info w-full">
          <p class="montserrat-bold max-h-full break-all w-full h-fit mt-2 home-post-title">
            {{ postTitle }}
          </p>
          <p
            [innerHTML]="getFormattedDescription()"
            (click)="handleClick($event)"
            class="s h-fit text-black break-words max-h-full description w-full my-1 home-post-description"
          ></p>
          <div class="flex flex-wrap home-post-interests">
            <p
              class="text-sm blue mr-2 cursor-pointer hover:underline"
              *ngFor="let item of postInterest"
              (click)="searchByInterest(item)"
            >
              {{ item }}
            </p>
          </div>
          <!-- <div class="pb-3 md:py-2">
            <p class="text-sm montserrat-medium">
              {{ formatPostDate(data?.created_at) }}
            </p>
          </div> -->
          <div *ngIf="postDesclaimer && postDesclaimer.length">
            <p class="s montserrat-bold">
              Obtained Copyright From
              <span class="cursor-pointer blue" (click)="showDesclaimer = !showDesclaimer">
                Licensor Or Owner :</span
              >
            </p>
            <div
              class="items-center overflow-auto w-full h-fit advanced-filter-item mt-2 mb-2"
              *ngIf="showDesclaimer"
            >
              <p class="break-all w-full" *ngFor="let desclaimer of postDesclaimer; index as i">
                {{ desclaimer }}
                {{ postDesclaimer.length - 1 === i ? '' : ' | ' }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- COMMENT SECTION TO SHOW ONLY LAPTOP SCREEN -->
      <div *ngIf="innerWidth > 768 && !isHomePost" class="w-full bottom-0 bg-white pt-2">
        <div class="" *ngIf="showComment">
          <div class="flex relative items-center justify-center w-full py-4">
            <p
              class="montserrat-bold cursor-pointer blue"
              (click)="showComments(); openBottomSheet()"
            >
              Comments
            </p>
          </div>
          <div class="comment-list custom-content-vscroll pr-5 pl-4 mb-5">
            <div *ngFor="let user of data?.commentDetails">
              <app-comment-card
                *ngIf="user?.userInfo[0]"
                [post_id]="data._id"
                [commentInfo]="user"
                [id]="user_id"
                [isFavor]="isFavorComment(user.likeDetails)"
                (likeComment)="likeComment($event)"
                (deletedCommentId)="removeDeletedComment($event)"
                (hashtagClicked)="searchByInterest($event)"
                [contentType]="'POST'"
              >
              </app-comment-card>
            </div>
            <button
              *ngIf="data?.commentDetails?.length !== data?.totalThreads"
              class="mx-auto my-2 flex blue text-sm custom-button"
              [class.loading]="isLoading"
              (click)="loadMoreComments()"
            >
              <span class="blue" *ngIf="!isLoading">Load More</span>
              <div *ngIf="isLoading" class="loader"></div>
            </button>
          </div>
        </div>
        <mat-divider></mat-divider>
        <form
          class="flex w-full relative"
          [formGroup]="commentForm"
          (ngSubmit)="submitForm()"
          novalidate
        >
          <div class="flex-col w-full relative">
            <mat-form-field class="mention-list-wrapper">
              <textarea
                appMentionTrigger
                class="text-area"
                [mentionConfig]="mentionService.mentionConfig"
                (itemSelected)="mentionSelect($event)"
                [mentionListTemplate]="mentionListTemplate"
                style="padding-right: 86px"
                maxlength="257"
                matInput
                cdkTextareaAutosize
                placeholder="Write a comment"
                formControlName="comment"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="2"
                #textarea
                #autosize="cdkTextareaAutosize"
              ></textarea>
              <ng-template #mentionListTemplate let-item="item">
                <div class="flex items-center">
                  <div class="rounded-full h-10 w-10 overflow-hidden">
                    <img
                      *ngIf="item.isShowImage"
                      class="rounded-full h-full w-full overflow-hidden"
                      onerror="this.src='assets/images/no-image.png'"
                      [src]="item.profile_img_path"
                      alt=""
                    />
                  </div>
                  &nbsp;
                  <span class="ml-2">{{ item.user_name }}</span>
                </div>
              </ng-template>
            </mat-form-field>
            <mat-error
              *ngIf="errorHandling('comment', 'maxlength')"
              [class]="errorHandling('comment', 'maxlength') ? 'pb-4 pt-1' : ''"
            >
              <p class="s red">Comment can not exceed more than 256 characters!</p>
            </mat-error>
          </div>
          <div class="flex items-center absolute right-1.5 top-0.5 h-full comment-btn-position">
            <button
              *ngIf="checkWhitespaces(textarea.value).length"
              mat-button
              class="button outlined black"
              type="submit"
            >
              Post
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div
    class="flex w-full items-center justify-center mt-4"
    *ngIf="data && profile?._id === data.profile_id && !isHomePost"
  >
    <button
      mat-button
      class="button outlined black eidit action-buttons"
      *ngIf="!data.campaign_post"
      (click)="editPost(data._id)"
    >
      <span class="blue">{{ !data.campaign_post ? 'Edit' : 'Edit Campaign' }}</span>
    </button>
    <div *ngIf="!data.campaign_post" class="ml-2">
      <button mat-button class="button outlined delete-btn action-buttons" (click)="deletePost()">
        Delete
      </button>
    </div>
  </div>
</div>
