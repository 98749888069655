import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SortOption, defaultSortByOption } from 'src/app/core/const/options';
import { NetworkService } from 'src/app/core/services/network.service';


@Component({
  selector: 'app-subscribing-list',
  templateUrl: './subscribing-list.component.html',
  styleUrls: ['./subscribing-list.component.scss']
})
export class SubscribingListComponent implements OnInit {
  private unsubscribe$ = new Subject();
  totalcount:any;
  subscriberList: any[] = [];
  sortByData = {
    label: 'Sort by: ',
    type: SortOption,
  };
  searchSortValue?: string = defaultSortByOption.defaultSortBy;
  subscriberFilter = {
    sort_by: '',
    offset: 0,
    limit: 20
  };

  totalAvaileList: any;

  constructor(private spinner: NgxSpinnerService,
    private networkService: NetworkService
  ) { this.getSubscribersList(this.searchSortValue) }

  ngOnInit(): void { }

  setSearchSort(val: string) {
    this.searchSortValue = this.sortByData.type.filter((item: { id: string; label: string }) => item.id === val)[0].label;
    this.getSubscribersList(this.searchSortValue)
  }

  getSubscribersList(searchTypeVal: any) {
    this.spinner.show()
    this.subscriberFilter.sort_by = searchTypeVal;
    this.subscriberFilter.offset = 0;
    this.networkService.getSubscribers(this.subscriberFilter).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.subscriberList = res.data.followings;
      this.totalcount = res.totalSubscribers
      this.totalAvaileList = this.subscriberList.length;
      this.spinner.hide()
    }, () => {
      this.spinner.hide()
    });
  }

  onScrollDown() {
    if (this.totalcount != this.totalAvaileList) {
      this.spinner.show()
      this.subscriberFilter.offset = this.subscriberFilter.offset + 20;
      this.networkService.getSubscribers(this.subscriberFilter).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        this.spinner.hide()
        if (res?.data?.followings?.length) {
          this.subscriberList = [...this.subscriberList, ...res.data.followings];
          this.totalcount = res.totalSubscribers;
          this.totalAvaileList = this.subscriberList.length;
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}

