<div class="realtive header-share-modal w-full">
  <img class="w-10 absolute top-2 left-3" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">

  <p class="text-white montserrat-bold">Share</p>
  <mat-icon
    class="text-white absolute right-2.5 top-2 mt-1 cursor-pointer"
    matDialogClose
    >close</mat-icon
  >
</div>
<div class="share-post-modal bg-white overflow-hidden lg:overflow-hidden mx-auto pt-4 pb-2 px-6">
  <div class="border-t border-b border-gray-300 height-search-wrapper overflow-auto flex items-center mb-4 py-2">
    <mat-icon>search</mat-icon>
    <input  appMentionTrigger
    [mentionConfig]="mentionService.mentionConfig"
    (itemSelected)="mentionSelect($event)"
    [mentionListTemplate]="mentionListTemplate" class="border-none w-full outline-none ml-2 my-1" (keyup)="filtertedUser()" [(ngModel)]="searchIdx" placeholder="@username">
  </div>

  <ng-template #mentionListTemplate let-item="item">
    <div class="flex items-center">
      <div class="rounded-full h-10 w-10 overflow-hidden">
        <img
          *ngIf="item.isShowImage"
          class="rounded-full h-full w-full overflow-hidden"
          onerror="this.src='assets/images/no-image.png'"
          [src]="item.profile_img_path"
          alt=""
        />
      </div>
      &nbsp;
      <span class="ml-2">{{ item.user_name }}</span>
    </div>
  </ng-template>
  <div  class=" pr-1 lg:overflow-y-auto  overflow-x-hidden max-height-members-wrapper">
    <div class="h-2/5 flex items-center justify-center" *ngIf="filteredUser?.length=== 0">
      <h2>No Result Found</h2>
    </div>
    <ng-container *ngIf="filteredUser?.length > 0">
      <div *ngFor="let profile of filteredUser">
        <div class="search-user-item flex items-center py-2">
          <img class="w-10 h-10 rounded-full object-contain bg-black"
            [src]="profile?.profile_image ? profile.profile_image : profile.profile_img_path ? profile.profile_img_path : 'https://i.postimg.cc/Hk7vyZZM/no-image.png'">
          <p class="mx-2 truncate profile-name">{{profile.user_name}}</p>
          <!-- <input type="checkbox" [checked]="profile.isSelected" class="w-5 h-5 rounded"> -->
          <!-- <button class="button white ml-auto text-xs" (click)="setActive($event , profile.user_id)">Send</button> -->
  
          <mat-checkbox [(ngModel)]="profile.selectedUserToSend" class="ml-auto blue"
            (change)="selectedUserChange(profile)"></mat-checkbox>
        </div>
      </div>
    </ng-container>
  </div>
  <button [disabled]="!shareContentBtn" [ngClass]="shareContentBtn ? 'opacity-100':'opacity-30'" (click)="share()" class="cursor-pointer py-1.5 w-full flex justify-center items-center bg-[#2887ed] rounded px-4 text-white font-bold mt-1 mx-auto">
    Send
  </button>
  
</div>

<ngx-spinner
      bdOpacity="0.9"
      bdColor="black-dark"
      size="medium"
      color="red"
      type="ball-spin-clockwise"
      [fullScreen]="true"
      class="job-list-spinner"
    >
    </ngx-spinner>