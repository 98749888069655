<!-- <app-part-header [data]="partPricingHeaderData"></app-part-header> -->
<!-- <div class="section-main-padding"></div> -->

<div class="background-section">
  <div class="sections-main">
    <div class="section-2-main">
      <div class="flex flex-col w-full mx-auto items-center">
        <div class="block md:hidden">
          <app-feature-menu></app-feature-menu>
        </div>
        <div>
          <p class="join-heading text-center">
            Explore memberships and <br />
            join our growing community
          </p>
          <p class="section-title-divider fastgrowing-divider w-full"></p>
        </div>
        <div class="btn-wrappers">
          <button
            [class]="forIndividual ? 'bg-blu shadow-lg' : ''"
            (click)="forIndividual = true"
            class="border-plus-text px-4 py-1 rounded-full mr-4 shadow-lg"
          >
            <p class="get-access-btn">Individuals</p>
          </button>
          <button
            [class]="!forIndividual ? 'bg-blu shadow-lg' : ''"
            (click)="forIndividual = false"
            class="border-plus-text px-4 py-1 rounded-full mr-4 shadow-lg"
          >
            <p class="get-access-btn text-white">Companies</p>
          </button>
        </div>
        <!-- <p class="description">
            Get access to experts, creators and companies. <br />
            Invite others to your network & earn residual income.
          </p> -->
      </div>

      <!-- for mobile screen & Individuals -->
      <div *ngIf="innerWidth < 768 && forIndividual" class="images-main">
        <swiper [pagination]="{ clickable: true }" [config]="config">
          <ng-template swiperSlide>
            <div
              class="footer-images"
              [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network"
            >
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/QlEAN.jpeg" alt="" />
              <p>Basic Level Membership</p>
              <small>Follow, Share & Network</small>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div
              class="footer-images"
              [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services"
            >
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/RiBLD.jpeg" alt="" />
              <p>Pro Level Membership</p>
              <small>Share, Sell & Network</small>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div
              class="footer-images"
              [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments"
            >
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/1fBfE.jpeg" alt="" />
              <p>Elite Level Membership</p>
              <small>Network, Share, Sell & Earn</small>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div
              class="footer-images"
              [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
            >
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/yuRqX.jpeg" alt="" />
              <p>Membership Add Ons</p>
              <small>Affiliate, Ambassador & Partner</small>
            </div>
          </ng-template>
        </swiper>
      </div>
      <!---------->

      <!-- for laptop screen & Individuals -->
      <div *ngIf="innerWidth >= 768 && forIndividual" class="grid grid-cols-4 gap-5">
        <div
          class="footer-images"
          [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network"
        >
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/QlEAN.jpeg" alt="" />
          <p>Basic Level Membership</p>
          <small>Follow, Share & Network</small>
        </div>
        <div
          class="footer-images"
          [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services"
        >
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/RiBLD.jpeg" alt="" />
          <p>Pro Level Membership</p>
          <small>Share, Sell & Network</small>
        </div>
        <div
          class="footer-images"
          [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments"
        >
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/1fBfE.jpeg" alt="" />
          <p>Elite Level Membership</p>
          <small>Network, Share, Sell & Earn</small>
        </div>
        <div
          class="footer-images"
          [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
        >
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/yuRqX.jpeg" alt="" />
          <p>Membership Add Ons</p>
          <small>Affiliate, Ambassador & Partner</small>
        </div>
      </div>
      <!-------->

      <!-- for mobile screen & Companies -->
      <div *ngIf="innerWidth < 768 && !forIndividual" class="images-main">
        <swiper [pagination]="{ clickable: true }" [config]="config">
          <ng-template swiperSlide>
            <div
              class="footer-images"
              [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network"
            >
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/A1hJv.jpeg" alt="" />
              <p>Recruiter Level Membership</p>
              <small>Post Jobs, Services & Recruit</small>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div
              class="footer-images"
              [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services"
            >
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/glB2F.jpeg" alt="" />
              <p>Business Level Membership</p>
              <small>Post Content, Jobs & Services</small>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div
              class="footer-images"
              [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments"
            >
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/D1HRm.jpeg" alt="" />
              <p>Enterprise Level Membership</p>
              <small>Post Jobs, Services & Subscriptions</small>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div
              class="footer-images"
              [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
            >
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/tmTSJ.jpeg" alt="" />
              <p>Membership Add Ons</p>
              <small>Silver, Gold & Platinum</small>
            </div>
          </ng-template>
        </swiper>
      </div>
      <!---------->

      <!-- for laptop screen & Companies -->
      <div *ngIf="innerWidth >= 768 && !forIndividual" class="grid grid-cols-4 gap-5">
        <div
          class="footer-images"
          [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network"
        >
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/A1hJv.jpeg" alt="" />
          <p>Recruiter Level Membership</p>
          <small>Post Jobs, Services & Recruit</small>
        </div>
        <div
          class="footer-images"
          [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services"
        >
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/glB2F.jpeg" alt="" />
          <p>Business Level Membership</p>
          <small>Post Content, Jobs & Services</small>
        </div>
        <div
          class="footer-images"
          [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments"
        >
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/D1HRm.jpeg" alt="" />
          <p>Enterprise Level Membership</p>
          <small>Post Jobs, Services & Subscriptions</small>
        </div>
        <div
          class="footer-images"
          [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
        >
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/tmTSJ.jpeg" alt="" />
          <p>Membership Add Ons</p>
          <small>Silver, Gold & Platinum</small>
        </div>
      </div>
      <!-------->
    </div>
  </div>
</div>

<div class="pb-6">
  <app-part-plan
    [openTab]="forIndividual"
    [originPlans]="plans"
    [originAddOns]="addOns"
  ></app-part-plan>
</div>
<div class="section-main">
  <app-part-footer></app-part-footer>
</div>
