import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
// import { ChartOptions } from 'src/app/core/models';
import { sortBy } from 'src/app/core/const/options';

@Component({
  selector: 'app-dashboard-referals-me',
  templateUrl: './dashboard-referals-me.component.html',
  styleUrls: ['./dashboard-referals-me.component.scss'],
})
export class DashboardReferalsMeComponent  {
  @Output() sortBy: EventEmitter<any> = new EventEmitter();
  // public chartOptions!: ChartOptions;
  searchSortValue?: string;
  sortByData = sortBy;
  selected: any;
  totalEarning: number = 0;
  dataSource: any;
  searchFollowerOptions = [{
    id: 'Last 24 Hours',
    label: 'Today',
  },
  {
    id: 'Last 30 Days',
    label: 'Last 30 Days',
  },
  {
    id: 'Over 30 Days',
    label: 'Over 30 Days',
  },];
  searchFollowerOption: string;
  displayedColumns: string[] = ['user', 'joined', 'earned'];
  plans: any;

  @Input() set referralData(referralData: any) {
    this.dataSource = referralData;
  }

  @Input() set selectedTab(value: any) {
    setTimeout(() => {
      this.selected = value;  
    }, 100);
  }

  @Input() set earning(earnings: any) {
    if (earnings) {
      // this.chartOptions = {
      //   series: [{ name: "", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }],
      //   chart: { height: 350, type: "line", dropShadow: { enabled: true, color: "#000", top: 18, left: 7, blur: 10, opacity: 0.2 }, toolbar: { show: false } },
      //   colors: ["#2887ED", "#E63946"],
      //   dataLabels: { enabled: false },
      //   stroke: { curve: "smooth" },
      //   title: { text: "", align: "right" },
      //   grid: {
      //     borderColor: "#E5E5E5", row: {
      //       colors: ["#FFFFFF", "transparent"], // takes an array which will be repeated on columns
      //       opacity: 0.5
      //     }
      //   },
      //   markers: { size: 3 },
      //   xaxis: {
      //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      //     title: {}
      //   },
      //   yaxis: { title: { text: "" }, min: 0 },
      //   legend: { position: "top", horizontalAlign: "right", floating: true, offsetY: -5, offsetX: -0 }
      // };
      // this.chartOptions.series = [{ name: "", data: earnings?.byMonths ? earnings?.byMonths : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }]
      // if (earnings?.byMonths?.length) {
        // this.chartOptions.yaxis.max = (Math.round(Math.max(...earnings.byMonths) / 100) * 100) + 100
      // }
      this.totalEarning = earnings?.totalReferralEarning;
    }
  }

  constructor(private datePipe: DatePipe, private cdr: ChangeDetectorRef) {
    this.searchFollowerOption = 'Over 30 Days';
    // if (this.chartOptions) {
    //   this.chartOptions = {
    //     series: [{ name: "", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }],
    //     chart: { height: 350, type: 'line', dropShadow: { enabled: true, color: '#000', top: 18, left: 7, blur: 10, opacity: 0.2 }, toolbar: { show: false } },
    //     colors: ['#2887ED', '#E63946'],
    //     dataLabels: { enabled: false },
    //     stroke: { curve: 'smooth' },
    //     title: { text: '', align: 'right' },
    //     grid: { borderColor: '#E5E5E5', row: { colors: ['#FFFFFF', 'transparent'], opacity: 0.5 } },
    //     markers: { size: 3 },
    //     xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nob', 'Dec'], title: {} },
    //     yaxis: { title: { text: '' }, min: 0 },
    //     legend: { position: 'top', horizontalAlign: 'right', floating: true, offsetY: -5, offsetX: -0 },
    //   };
    // }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  /**
   * Format Date according JobPosted Date
   * @param previousDate
   * @returns
   */
  formatDate(previousDate?: any) {
    let currentDate = new Date().getTime();
    let jobDate = new Date(previousDate).getTime();
    let time = (currentDate - jobDate) / 1000;
    let days = Math.floor(time / (3600 * 24));
    let minute = Math.floor(time / 60);
    let hour = Math.floor(time / (60 * 60));
    if (minute < 60) {
      return minute + ' minutes ago';
    } else if (hour < 24 && hour > 0) {
      return hour + ' hours ago';
    } else if (days < 100 && days >= 1) {
      return days + ' days ago';
    } else {
      return this.datePipe.transform(previousDate, 'MMM d, y');
    }
  }

  setSearchSort(val: string) {
    this.searchSortValue = this.sortByData.type.filter((item: { id: string; label: string }) => item.id === val)[0].label;
    this.sortBy.emit(val);
  }
}
