<div class="px-0 lg:px-10 py-6 bg-white section-wrapper">
  <div class="py-10 flex flex-col md:flex-row items-center md:items-start">
    <div class="w-64 p-4 flex flex-col items-center">
      <p class="text-center capitalize">
        Event Scheduler
      </p>
      <p class="montserrat-bold text-3xl py-4">$0</p>
      <p class="black-dark text-center">Forever</p>
      <div class="mt-4 grid gap-2 w-full">
        <div class="flex items-center">
          <img class="w-4 md:w-5" src="../../../../../../../assets/images/icon-check.png" alt="check-icon">
          <p class="text-sm ml-2 capitalize">display meetings on profile</p>
        </div>
        <div class="flex items-center">
          <img class="w-4 md:w-5" src="../../../../../../../assets/images/icon-check.png" alt="check-icon">
          <p class="text-sm ml-2">1 on 1 Client Consultations</p>
        </div>
        <div class="flex items-center">
          <img class="w-4 md:w-5" src="../../../../../../../assets/images/icon-check.png" alt="check-icon">
          <p class="text-sm ml-2 capitalize">virtual events & interviews</p>
        </div>
        <div class="flex items-center">
          <img class="w-4 md:w-5" src="../../../../../../../assets/images/icon-check.png" alt="check-icon">
          <p class="text-sm ml-2 capitalize">manage scheduled events</p>
        </div>
      </div>
      <div class="flex items-center mt-4">
        <p class="text-sm">Scheduled By</p>
        <img class="mx-2" width="84" src="../../../../../../../assets/images/logo/b-logo-desktop.png" alt="setmore-icon">
      </div>
      <div class="mt-6 md:mt-4">
        <button mat-button class="button outlined black" (click)="addNewEvent()">Add Event</button>
      </div>
    </div>
  
</div>

