<div class="jobs-page mx-auto">
  <!-- Temporarily commented -->
  <!-- <div class="px-4 md:px-0">
    <app-most-viewed-posts></app-most-viewed-posts>
  </div> -->
  <!-- <h1
    class="jobs-page_title hidden md:flex ml-0.5 pl-2 lg:ml-0 lg:pl-0 mx-auto mt-0 montserrat-bold"
  >
    Find Jobs
  </h1>
  <p class="capitalize ml-0.5 pl-2 hidden md:flex lg:ml-0 lg:pl-0 mb-2 lg:mb-4">
    By Hiring Companies
  </p> -->
  <app-dynamic-tabs
    (jobfileTypefilter)="jobFiletypeFilterEvent($event)"
    (jobcategoryfilter)="jobCategoryFilterEvent($event)"
    (locationTypeFilter)="locationFilterEvent($event)"
    (searchInputValue)="SearchingValueEvent($event)"
    (dateFilter)="salaryFilterEvent($event)"
    [candidateJobPreferences]="candidatePreferences"
    (experienceTypeFilter)="experienceTypeFilterEvent($event)"
    (sortByFilter)="sorByFilters($event)"
    (clearAllFilters)="getJobsList()"
    [companyName]="companyName"
    [isSearch]="isFilterSelected"
    [isShowAdvancedSearch]="isShowAdvancedSearch"
    (applyPreferences)="getCandidateJobPreferences()"
  ></app-dynamic-tabs>
  <!-- <div class="flex justify-between">
    <h1
      class="jobs-page_title md:hidden flex ml-0.5 pl-2 lg:ml-0 lg:pl-0 mx-auto lg:mt-12 xl:mt-0 montserrat-bold"
    >
      Find Jobs
    </h1>
    <p
      class="blue font-semibold pr-3 md:hidden"
      (click)="isShowAdvancedSearch = !isShowAdvancedSearch"
    >
      {{ isShowAdvancedSearch ? "Hide Advance Search" : "Advance Search" }}
    </p>
  </div>
  <p class="capitalize ml-0.5 pl-2 md:hidden flex lg:ml-0 lg:pl-0 mb-2 lg:mb-4">
    By Hiring Companies
  </p> -->

  <div
    class="flex flex-col lg:flex-row scroll mt-2 lg:mt-4"
    #jobsContainer
    infinite-scroll
    [infiniteScrollContainer]="jobsContainer"
    [infiniteScrollThrottle]="50"
    [infiniteScrollDistance]="0.5"
    (scrolled)="onScrollDown()"
  >
    <div class="flex flex-col lg:pl-0 job-list scroll-wrapper">
      <div class="grid grid-cols-1 gap-8 md:gap-2 md:grid-cols-3">
        <div *ngFor="let job of jobs" class="ml-3 md:ml-0 mr-1 md:mr-0 grow">
          <app-job-card
            [jobList]="job"
            (save)="saveJob(job)"
            (apply)="openApplyDialog(job)"
          ></app-job-card>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="spinner-wrapper">
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="black-dark"
    size="medium"
    color="red"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    class="job-list-spinner"
  >
  </ngx-spinner>
</div>