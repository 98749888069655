<div
  *ngIf="pricingPackage"
  class="border-remove border border-gray-300 rounded"
>
  <div class="mb-4">
    <p
      class="font-bold header-wrapper px-2 overflow-auto break-words flex items-center justify-center cards-font bg-black text-white"
    >
      {{ pricingPackage.title }}
    </p>
    <h3
      class="text-color cards-font flex items-center justify-center service-price font-bold mt-4"
    >
      <sup class="text-color text-base font-bold">$</sup
      >{{ pricingPackage.price
      }}<sub
        *ngIf="pricingType === 'Monthly' || pricingType === 'Weekly'"
        class="text-color text-base font-bold"
        >/{{ pricingType === "Weekly" ? "wkly " : "mo" }}</sub
      >
    </h3>
    <!-- <p class="black-dark cards-font text-center px-2 break-words font-medium text-sm mt-2">{{pricingType}}</p> -->
    <p class="font-style px-2 text-center black-light break-words mt-2">
      {{ getDescription(pricingPackage) }}
    </p>
    <span
      *ngIf="pricingPackage.isMore && pricingPackage.isBigger"
      class="blue cursor-pointer block text-center"
      (click)="pricingPackage.isMore = false"
      >more...</span
    >
    <span
      *ngIf="!pricingPackage.isMore && pricingPackage.isBigger"
      class="text-sm blue block text-center cursor-pointer"
      (click)="pricingPackage.isMore = true"
      >less</span
    >
  </div>
  <!-- <div class="w-full">
    <mat-divider></mat-divider>
  </div> -->
  <!-- <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
  </ngx-spinner> -->
  <div class="pt-2">
    <p
      *ngIf="isDashboardService"
      class="text-color px-4 py-2 pointsheading w-fit mx-auto rounded-full shadow-lg text-base text-center"
    >
      Details
    </p>
    <div *ngIf="!isDashboardService" class="flex justify-center my-4 mb-4">
      <button
        class="cards-font pay-button rounded-full font-bold py-2 px-8"
        (click)="purchaseService(pricingPackage, 'Service')"
      >
        {{isProfileSubscription ?'Subscribe' :'Pay'}}
      </button>
    </div>
    <div
      class="px-1 mt-3 overflow-auto mb-5"
      [ngClass]="isProfileSubscription ? 'h-full' : 'h-40'"
    >
      <ng-container *ngFor="let feature of pricingPackage.features">
        <p class="my-1 break-words pl-3 cards-font text-lg flex items-center">
          <img
            class="check-box mr-2"
            src="https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros+CHECK+MARK.png"
            width="24"
          />
          {{ feature }}
        </p>
      </ng-container>
    </div>
  </div>
  <!-- <div *ngIf="!isDashboardService" class="flex justify-center my-4">
    <button class="cards-font pay-button rounded-full font-bold py-2 px-8" (click)="purchaseService(pricingPackage,'Service')">
      Pay
    </button>
  </div> -->
</div>
