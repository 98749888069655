import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';
import { NetworkService } from 'src/app/core/services/network.service';

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
})
export class PostCardComponent {
  reelsIcon = '../../../../assets/images/reels-icon.png';
  imagesIcon = '../../../../assets/images/multipleImage.png';
  private unsubscribe$ = new Subject();
  @Input() set data(post: any) {
    if (post) {
      this.post = post;
    }
  }
  @Input() set loginedUser(profile: any) {
    this.profile = profile;
  }
  @Input() layoutMode?: string;
  post?: any;
  profile: any;
  isProcessing = false;

  @Input() myFollowings:any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private postService: PostService,
    public dialog: MatDialog
  ) {}

  ngOnInit() { }

  onLike(post: any) {
    this.isProcessing = true;
    if (this.authService.loggedIn()) {
      const isAlreadyLikedPost = this.post.likedUsers.find(
        (x: any) => x.user_id === this.profile._id
      );
      const index = this.post.likedUsers.findIndex(
        (x: any) => x.user_id === this.profile._id
      );
      if (isAlreadyLikedPost?._id) {
        this.postService
          .unLikePost(isAlreadyLikedPost._id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.post.likedUsers.splice(index, 1);
            this.isProcessing = false;
          });
      } else {
        const options = {
          content_id: post._id,
          content_type: 'Post',
        };
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((post: any) => {
            this.post.likedUsers.push({
              user_id: post.data.user_id,
              _id: post.data._id,
            });
            this.isProcessing = false;
          });
      }
      this.isUserLikedPost(this.post);
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
    }
  }

  getPostUserImage(image: string) {
    return image?.length
      ? image
      : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs';
  }

  showProfile() {
    if (this.post?.owner?.user_name) {
      this.router.navigate(['/profile' + '/' + this.post.owner.user_name]);
    } else if (this.post?.profile?.user_name) {
      this.router.navigate(['/profile' + '/' + this.post.profile.user_name]);
    }
  }

  showPost() {
    const currentUrl = this.route.snapshot.url;
    if (currentUrl[0]?.path === "profile") {
      
      this.router.navigate(['/profile/' + currentUrl[1]?.path + '/post/' + this.post._id,
      ]);
    }else{
      this.router.navigate([
        routesConst.community + '/' + 'post' + '/' + this.post._id,
      ]);
    }
  }

  isUserLikedPost(post: any) {
    return post?.likedUsers?.find(
      (like: any) => like?.user_id == this.profile?._id
    )
      ? true
      : false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
