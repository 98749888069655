import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent implements OnInit {
  private unsubscribe$ = new Subject();
  isProcessing = false
  today:any;
  constructor(public dialogRef: MatDialogRef<ScheduleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private profileService: ProfileService, private snack: SnackBarService) {
      dialogRef.disableClose = true;
      this.today = new Date(this.data?.events[0]?.start);
     }

  ngOnInit(): void { }

  saveEvent(event: any) {
    if (!event?.title?.trim()?.length || !event?.start || !event?.end) {
      return
    }
    this.isProcessing = true;
    event.start = new Date(event.start)
    event.end = new Date(event.end)
    this.profileService.saveEvent(event).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res.status == 'success') {
        this.snack.showMessage(`${res.message}`,false);
        this.dialogRef.close();
      }
    });
  }

  updateEndDate(event: any) {
    event.end = null;
  }

  updateEvent(event: any) {
    if (!event?.title?.length || !event?.start || !event?.end) {
      this.snack.showMessage(`Title Start Date and End Date Cannot be Blank`,true);
      return
    }
    event.start = new Date(event.start)
    event.end = new Date(event.end)
    this.isProcessing = true;
    this.profileService.updateEvent(event).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res.status == 'success') {
        this.isProcessing = false;
        this.snack.showMessage(`Event Updated`,false);
        this.dialogRef.close(res.update);
      }
      else{
        this.isProcessing = false;
        this.snack.showMessage(`${res.message}`,true);
        this.dialogRef.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
