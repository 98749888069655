import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { Subject, takeUntil } from 'rxjs';
import { TalentService } from 'src/app/core/services/talent.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-services-reviews',
  templateUrl: './dashboard-services-reviews.component.html',
  styleUrls: ['./dashboard-services-reviews.component.scss']
})
export class DashboardServicesReviewsComponent implements OnInit {
  userInfo: any;
  data: any;
  private unsubscribe$ = new Subject();
  reviews: any = [];
  totalReviews: number = 0;
  averageRating: any;
  reviewsType: any = 'Seller';
  buyerReviews: any = [];
  sellerReviews: any = [];

  ratingFilters = {
    offset: 0,
    limit: 10
  };

  constructor(
    private talentService: TalentService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.dashboard();
  }

  getSellerReviews() {
    this.spinner.show();
    Object.assign(this.ratingFilters, {
      profileId: this.data?.profile_id
    });

    this.talentService
      .getSellerReviews(this.ratingFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.averageRating = res?.averageRating;
        this.spinner.hide();
        this.totalReviews = res?.total;
        this.sellerReviews = [
          ...this.sellerReviews,
          ...res.data.map((item: any) => {
            return {
              buyerName: item?.buyer?.name,
              sellerName: item?.seller?.name,
              buyerUser_name: item?.buyer?.user_name,
              sellerUser_name: item?.seller?.user_name,
              buyerImageUrl: item?.buyer?.profile_img_path,
              sellerImageUrl: item?.seller?.profile_img_path,
              buyerRating: item?.buyer_rating,
              sellerRating: item?.seller_rating,
              buyerComment: item?.buyer_comment,
              sellerComment: item?.seller_comment,
              service_id: item?.service?._id,
              service_creatorId: item?.service?.profile_id,
              user_name: item?.seller?.user_name,
              title: item?.service?.title,
              created_at: item?.created_at
            };
          })
        ];
      });
  }

  getBuyerReviews() {
    this.spinner.show();
    Object.assign(this.ratingFilters, {
      profileId: this.data?.profile_id
    });

    this.talentService
      .getBuyerReviews(this.ratingFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.averageRating = res?.averageRating;
        this.spinner.hide();
        this.totalReviews = res?.total;
        this.buyerReviews = [
          ...this.buyerReviews,
          ...res.data?.map((item: any) => {
            return {
              buyerName: item?.buyer?.name,
              sellerName: item?.seller?.name,
              buyerUser_name: item?.buyer?.user_name,
              sellerUser_name: item?.seller?.user_name,
              buyerImageUrl: item?.buyer?.profile_img_path,
              sellerImageUrl: item?.seller?.profile_img_path,
              buyerRating: item?.buyer_rating,
              sellerRating: item?.seller_rating,
              buyerComment: item?.buyer_comment,
              sellerComment: item?.seller_comment,
              service_id: item?.service?._id,
              service_creatorId: item?.service?.profile_id,
              user_name: item?.seller?.user_name,
              title: item?.service?.title,
              created_at: item?.created_at
            };
          })
        ];
      });
  }

  getReviews() {
    if (this.reviewsType == 'Seller') {
      this.getSellerReviews();
    }
    if (this.reviewsType == 'Buyer') {
      this.getBuyerReviews();
    }
  }

  loadMoreReviews() {
    this.ratingFilters.offset = this.ratingFilters.offset + 10;
    // this.getBuyerReviews();
    this.getSellerReviews();
  }

  showService(serviceId: string, userName: string) {
    const url = '/services/service/' + userName + '/' + serviceId;
    this.router.navigate(['/' + url]);
  }

  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information: { profileInfo: any } | null) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else if (information != null) {
          this.userInfo = information;
          this.data = {
            profile_id: information.profileInfo._id,
            profile: {
              profile_img_path: information.profileInfo.avatar,
              name: information.profileInfo.name
            }
          };
          this.getBuyerReviews();
          this.getSellerReviews();
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.spinner.hide();
  }
}
