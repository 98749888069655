<div class="drag-icon cursor-pointer" (click)="closeSheet($event)"></div>
<div class="w-full relative comments-wrapper bg-white pt-2">
  <div class="h-full">
    <div class="flex relative items-center justify-center w-full py-4">
      <p class="montserrat-bold cursor-pointer blue">Comments</p>
    </div>
    <div class="comment-list custom-content-vscroll pr-5 pl-4">
      <div *ngFor="let user of data?.commentDetails">
        <app-comment-card
          *ngIf="user.userInfo[0]"
          [post_id]="data._id"
          [commentInfo]="user"
          [isFavor]="isFavorComment(user.likeDetails)"
          (likeComment)="likeComment($event)"
          (deletedCommentId)="removeDeletedComment($event)"
          (hashtagClicked)="closeSheet($event)"
        >
        </app-comment-card>
      </div>
      <button
        *ngIf="data?.commentDetails?.length !== data?.totalThreads"
        class="mx-auto my-2 flex blue text-sm"
        (click)="loadMoreComments()"
      >
        Load More
      </button>
    </div>
  </div>
  <!-- <mat-divider></mat-divider> -->
  <form
    class="flex inset-x-0 absolute bottom-0"
    [formGroup]="commentForm"
    (ngSubmit)="submitForm()"
    novalidate
  >
    <div class="flex-col w-full relative">
      <mat-form-field class="mention-list-wrapper">
        <textarea
        appMentionTrigger
          class="text-area"
          [mentionConfig]="mentionService.mentionConfig"
          (itemSelected)="mentionSelect()"
          [mentionListTemplate]="mentionListTemplate"
          style="padding-right: 86px"
          maxlength="257"
          matInput
          cdkTextareaAutosize
          placeholder="Write a comment"
          formControlName="comment"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="2"
          #textarea
          (keyup)="triggerFunction($event)"
          #autosize="cdkTextareaAutosize"
        ></textarea>
        <ng-template #mentionListTemplate let-item="item">
          <div class="flex items-center">
            <div class="rounded-full h-10 w-10 overflow-hidden">
              <img  *ngIf="item.isShowImage"
                class="rounded-full h-full w-full overflow-hidden"
                onerror="this.src='assets/images/no-image.png'"
                [src]="item.profile_img_path"
                alt=""
              />
            </div>
            &nbsp;
            <span class="ml-2">{{ item.user_name }}</span>
          </div>
        </ng-template>
      </mat-form-field>
      <mat-error
        *ngIf="errorHandling('comment', 'maxlength')"
        [class]="errorHandling('comment', 'maxlength') ? 'pb-4 pt-1' : ''"
      >
        <p class="s red">Comment can not exceed more than 256 characters!</p>
      </mat-error>
    </div>
    <div
      class="flex items-center absolute right-1.5 top-6  comment-btn-position"
    >
      <button
        *ngIf="checkWhitespaces(textarea.value).length"
        mat-button
        class="button outlined black comment-btn"
        type="submit"
      >
        Post
      </button>
    </div>
  </form>
</div>
