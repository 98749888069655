<div class="grid grid-cols-1 feature-container w-full md:px-4">
  <div class="flex gap-5 py-[9px] w-full">
    <swiper
      #swiperRef
      [navigation]="true"
      [config]="config"
      class="swpier-container feature-swipper common-arrow"
    >
      <ng-template swiperSlide *ngFor="let item of showActiveItems">
        <div
          class="feature-main tag grow"
          [ngClass]="item.name == '' ? '' : 'feature-main tag grow'"
          [class]="item.url.toLowerCase() == currentRoute.toLowerCase() || item.name.toLowerCase() == this.currentRoute.toLowerCase()? 'feature-blue' : ''"
          (click)="navigate(item.url, item.params, item.name)"
        >
          <p class="text-lg title text-center">
            {{ item.name }}
          </p>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
