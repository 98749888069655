import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { ModeratorRoles } from 'src/app/const/appConst';
import { ProfileService } from 'src/app/core/services/profile.service';
import { JobPostComponent } from 'src/app/shared/components/job-post/job-post.component';
import { ServiceModalComponent } from 'src/app/shared/components/service-modal/service-modal.component';
import { SubscriptionPackageModalComponent } from 'src/app/shared/components/subscription-package-modal/subscription-package-modal.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss']
})
export class AccessComponent implements OnInit {
  private unsubscribe$ = new Subject();
  users: [] = [];
  uploadPostModalComponent = UploadPostModalComponent;
  jobPostComponent = JobPostComponent;
  serviceModalComponent = ServiceModalComponent;
  subscriptionPackageModalComponent = SubscriptionPackageModalComponent;

  constructor(private profileService: ProfileService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.getAccessModerators();
  }

  getAccessModerators() {
    this.profileService
      .getAccessModerators({ offset: 0, limit: 10 })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.users = result.data;
      });
  }

  getAccessRoles(roles: any) {
    var accessRoles: any = [];
    if (roles.includes(ModeratorRoles.ALL)) {
      accessRoles = [
        { label: 'Add Post', modal: this.uploadPostModalComponent },
        { label: 'Add Job', modal: this.jobPostComponent },
        { label: 'Add Service', modal: this.serviceModalComponent },
        { label: 'Add Subscription', modal: this.subscriptionPackageModalComponent }
      ];
    }
    if (roles.includes(ModeratorRoles.POST_MODERATOR)) {
      accessRoles.push({ label: 'Add Post', modal: this.uploadPostModalComponent });
    }
    if (roles.includes(ModeratorRoles.JOB_MODERATOR)) {
      accessRoles.push({ label: 'Add Job', modal: this.jobPostComponent });
    }
    if (roles.includes(ModeratorRoles.SERVICE_MODERATOR)) {
      accessRoles.push({ label: 'Add Service', modal: this.serviceModalComponent });
    }
    if (roles.includes(ModeratorRoles.SUBSCRIPTIONS_MODERATOR)) {
      accessRoles.push({
        label: 'Add Subscription',
        modal: this.subscriptionPackageModalComponent
      });
    }
    return accessRoles;
  }

  openModal(modal: any, profileId: any) {
    this.dialog.open(modal, {
      maxWidth: '100vw',
      width: '536px',
      disableClose: true,
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: { isModeratorMode: true, profile_id: profileId }
    });
  }

  trackByFunc(i: number) {
    return i;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
