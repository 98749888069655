import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlanDetailComponent } from '../plan-detail/plan-detail.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { statusConstant } from 'src/app/const/appConst';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { routesConst } from 'src/app/core/const/routers';
import { Router } from '@angular/router';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';

@Component({
  selector: 'app-profile-plans',
  templateUrl: './profile-plans.component.html',
  styleUrls: ['./profile-plans.component.scss']
})
export class ProfilePlansComponent implements OnInit {
  private unsubscribe$ = new Subject();
  myPlans: any[] = [];

  @Input() profileId: any;

  @Input() currentLoggedInUserId: any;
  userType: any;

  @Input() set user_name(name: any) {
    this.plans(name);
  }

  cardheights: any = {
    title: 'h-20',
    desc: 'h-16'
  };

  constructor(
    private profileService: ProfileService,
    private dialog: MatDialog,
    private userPlan: userPlans,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private snack: SnackBarService,
    private talentService: TalentService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.getUserType();
    }
  }

  getUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === '') {
        this.sharedService.getUserType();
      } else {
        this.userType = type;
      }
    });
  }

  plans(user_name: any) {
    if (!user_name) return;
    this.userPlan
      .publicPlans(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.plans) {
          this.myPlans = plans.plans.map((plan: any) => ({
            ...plan,
            isMore: true,
            isBigger: plan.description.length > 140
          }));
        }
      });
  }

  purchasePlan(plan: any) {
    if (!this.authService.loggedIn()) {
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
      return;
    }
    if (this.profileId == this.currentLoggedInUserId)
      return this.snack.showMessage(`You can't purchase your own service`, true);
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToPurchase(plan);
        }
      });
  }

  proceedToPurchase(plan: any) {
    this.spinner.show();
    const redirect_url = window.location.href
    const refresh_url = window.location.href
    this.talentService
      .getStripeUser(redirect_url, refresh_url)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.spinner.hide();
        if (res.status == 'on_board_required') {
          this.snack.showMessage(`${res.message}`, true);
          window.location.href = res.accountLink.url;
        } else {
          this.dialog.open(PlanDetailComponent, {
            maxWidth: '100vw',
            width: '536px',
            height: window.innerWidth > 768 ? '650px' : '100vh',
            data: plan
          });
        }
      });
  }

  getToLowerCase(str: string) {
    return str.toLowerCase();
  }

  isShowSpinner(show: any) {
    show ? this.spinner.show() : this.spinner.hide();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
