<div class="section-wrapper client-tab flex h-full ">
  <div class="flex flex-col w-full md:w-1/3 tab-aside" [ngClass]="{active: tabCollapse}">
    <!-- <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
      [fullScreen]="true" class="job-list-spinner">
    </ngx-spinner> -->
    <div class="flex w-full items-center justify-center md:px-4 py-2.5 bg-white">
      <input maxlength="256" class="input-form w-full bg-white-dark px-4 py-3.5" placeholder="Search clients..."
        #userSearch [formControl]="myControl" />
    </div>
    <div *ngIf="userList && userList.length" class="scroll" #scrollUserList (scroll)="onScrollDown()"
      (click)="openTabCollapse()">
      <app-message-client-card *ngFor="let item of userList" [data]="item" [sender]="sender_id" class="w-full"
        [class]="item?.userList?._id === selectedUser?.userList?._id ? 'activeUser' : ''"
        (click)="showMessageHistory(item.userList._id)">
      </app-message-client-card>
    </div>
  </div>
  <mat-divider class="hide h-full w-0.5" [vertical]="true"></mat-divider>
  <div class="flex flex-col w-full md:w-2/3 h-full tab-contnet relative" [ngClass]="{active: !tabCollapse}">
    <!-- <button class=" flex justify-start px-5 md:hidden" (click)="openTabCollapse()">
      <mat-icon class="blue arrow-back">west</mat-icon>
      <span class="blue ml-1 montserrat-bold">All messages</span>
    </button> -->
    <mat-horizontal-stepper labelPosition="bottom" #stepper class="h-full">
      <mat-step>
        <div class="flex items-center bg-white-dark pt-2.5 px-3 pb-4">
          <img (click)="openTabCollapse()" class="block md:hidden mr-2 expand-icon" src="../../../../assets/images/arrow-right.svg" />
          <div class="flex items-center">
            <img [src]="getProfileImage()" class="image-round mr-2" />
            <p *ngIf="selectedUser?.userList?._id" class="montserrat-medium mr-2 mx-w-40 truncate">{{ selectedUser?.userList?.user_name }}</p>
            <p class="s blue cursor-pointer" (click)="getPayment()">View order</p>
          </div>
          <p class="s gray-dark cursor-pointer ml-auto">Report</p>
        </div>
        <div *ngIf="showSpinner" class="uploader-status flex justify-center absolute items-center inset-0">
          <mat-spinner [diameter]="diameter" [color]="color"></mat-spinner>
        </div>
        <div class="p-4 m-0.5 scrollChat"  #scrollChat (scroll)="onScrollUp()">
         <div  *ngFor="let chat of chatMessages">
          <div class="flex pl-0.5 w-3/3" *ngFor="let messages of chat.data">
            <div class="w-full" [ngClass]="
            sender_id === messages.from_id
              ? ' flex flex-col ml-auto'
              : ' flex flex-col'
          ">
              <div class="flex w-full flex-row flex-nowrap">
                <!-- <div class="image-round mr-3" [ngClass]="
                sender_id === messages.from_id
                  ? ' flex flex-col ml-auto'
                  : ' flex flex-col'
              ">
                  <img [src]="
                  sender_id == messages.from_id
                    ? getSenderImage() 
                    : getProfileImage()
                " class="image-round" />
                </div> -->
                <p class="break-words" *ngIf="messages?.message_content?.includes('http')" [ngClass]="
                sender_id === messages.from_id
                  ? ' s border-normal rounded-full px-6 py-3.5 ml-auto sender-msg'
                  : 's border-normal rounded-full px-6 py-3.5 background'
              ">
                  <a class="break-words" href='{{messages.message_content}}' target="_blank">
                    <img *ngIf="messages.post_content" [src]="messages.post_content" class="message_content"><br>
                    <p *ngIf="messages.post_description">{{messages.post_description}}</p>
                    {{messages.message_content}}
                  </a>
                </p>
                <p class="break-words" *ngIf="!(messages?.message_content?.includes('http'))" [ngClass]="
                      sender_id === messages.from_id
                        ? ' s border-normal rounded-full px-6 py-3.5 ml-auto sender-msg'
                        : 's border-normal rounded-full px-6 py-3.5 background'
                    ">
                  {{ messages.message_content}}
                </p>
              </div>
              <p class="s gray-dark mt-1.5" [ngClass]="
              sender_id === messages.from_id
                ? ' flex flex-col ml-auto'
                : ' flex flex-col'
            ">
                {{ messages.created_at | date: "EE d MMM - h:mm a" }}
              </p>
            </div>
          </div>
         </div>
        </div>
        <div class="mt-auto message-edit-part bg-white" [ngClass]="{'for-mobile-text': !tabCollapse}">
          <div class="flex items-center">
            <mat-checkbox class="example-margin s">Complete</mat-checkbox>
          </div>
          <div class="flex items-center justify-between border-normal rounded">
            <textarea #messageContent [class]="!userList || userList?.length==0? 'disabled':'' "
              [disabled]="!userList || userList?.length==0" class="border-none s resize-none w-5/6 h-20 outline-none"
              placeholder="Compose your message" [(ngModel)]="sendMessagetext"
              (keydown)="sendMessage($event)"></textarea>
            <p class="gray-dark">
              <mat-icon>attach_file</mat-icon>
            </p>
            <button  [disabled]="!messageContent.value.trim().length" [ngClass]="!messageContent.value.trim().length ? '':'btn-able'" class="gray-dark flex items-center border-blue montserrat-bold cursor-pointer mx-3.5"
              (click)="sendMessage($event)">
              <mat-icon>send</mat-icon>
            </button>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="flex items-center bg-white-dark pt-2.5 pr-3 pb-4 pl-5 cursor-pointer" (click)="goBack(stepper)">
          <mat-icon>arrow_back</mat-icon>
          <p class="">Work order</p>
        </div>
        <div class="p-4 text-center mb-10">
          <h4 class="mb-2"><span class="montserrat-bold">$180.00</span> paid</h4>
          <p class="s black-light">on November 21, 2021</p>
        </div>
        <div class="px-10">
          <div class="w-full flex justify-between">
            <p class="">Client</p>
            <div class="text-right">
              <p class="montserrat-medium">Max Lorens</p>
              <p class="s black-light">maxlorens@gmail.com</p>
            </div>
          </div>
          <div class="my-4">
            <mat-divider></mat-divider>
          </div>
          <div class="w-full flex justify-between">
            <p class="">Service</p>
            <div class="text-right">
              <p class="montserrat-bold blue">Resume writing for freelancers</p>
              <p class="s black-light">PRO Package</p>
            </div>
          </div>
          <div class="my-4">
            <mat-divider></mat-divider>
          </div>
          <div class="w-full flex justify-between">
            <p class="">Paid</p>
            <div class="text-right">
              <p class="montserrat-medium">$180.00</p>
            </div>
          </div>
          <div class="my-4">
            <mat-divider></mat-divider>
          </div>
          <div class="w-full flex justify-between">
            <p class="">Fee</p>
            <div class="text-right">
              <p class="montserrat-medium">-$1.80</p>
            </div>
          </div>
          <div class="my-4">
            <mat-divider></mat-divider>
          </div>
          <div class="w-full flex justify-between mb-9">
            <p class="">In your balance</p>
            <div class="text-right">
              <p class="montserrat-medium">$178.20</p>
            </div>
          </div>
          <div class="flex items-center mb-2">
            <mat-checkbox class="example-margin">Mark as job completed</mat-checkbox>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>