import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { intervalConstant } from 'src/app/const/appConst'
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-plan-detail',
  templateUrl: './plan-detail.component.html',
  styleUrls: ['./plan-detail.component.scss'],
})
export class PlanDetailComponent implements OnInit {
  intervalConstant = intervalConstant;
  private unsubscribe$ = new Subject();
  productPrice: any[] = [];
  selectedPlanPrice: any;
  interval: any;
  loginedUser: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userPlanService: userPlans,
    private stripeService: paymentService,
    private spinner: NgxSpinnerService,
    private snack: SnackBarService,
    private auth: AuthService,
    private sharedService: SharedService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {

    if (this.auth.loggedIn()) {
      this.dashboard();
    }

    this.spinner.show();
    this.userPlanService
      .getUserPlanPrice(this.data?.account_id)
      .pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        this.snack.showMessage(`${res.message}`,false);
        this.spinner.hide();
        res.productPrice.find((x: any) => {
          if (x.product.id == this.data.stripe_product_id) {
            this.productPrice.push(x);
          }
        });
        this.selectedPlanPrice = this.productPrice[0]?.id;
        this.interval = this.productPrice[0].recurring.interval

      });
  }


  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        return this.loginedUser = profileInfo;
      }
    }));
  }

  pay(purchasedType: string) {
    this.spinner.show();
    const params = {
      mode: 'subscription',
      cancel_url: `${window.origin}/failed`,
      success_url: `${window.origin}/success`,
      line_items: [
        {
          price: this.selectedPlanPrice,
          quantity: 1,
        },
      ],
    };
    if (this.auth.loggedIn()) {
      this.stripeService.purchaseUserCreatedPlan(
        params,
        this.data.profile_id,
        this.loginedUser._id,
        this.data._id,
        this.data.title,
        this.interval,
        purchasedType
      ).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        this.spinner.hide();
        if (res?.accountLink?.url) {
          this.commonService.location().href = res.accountLink.url;
        } else {
          this.snack.showMessage("Unable to connect to Stripe at the moment, Please try again later",true);
        }
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        this.snack.showMessage(`${error} `,true);

      });
    } else {
      this.snack.showMessage('Please login to Purchase',true);

    }


  }

  onChange(plan: any) {
    this.selectedPlanPrice = plan.value.id;
    this.interval = plan.value.recurring.interval

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
