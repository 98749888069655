import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MonthNames } from 'src/app/core/const/options';
import { Achievement } from 'src/app/core/models';

@Component({
  selector: 'app-achievement-card',
  templateUrl: './achievement-card.component.html',
  styleUrls: ['./achievement-card.component.scss']
})
export class AchievementCardComponent implements OnInit {
  @Input() achievement!: Achievement;
  @Output() save: EventEmitter<any> = new EventEmitter<Achievement>();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  editF = false;

  constructor() {
  }

  ngOnInit(): void { }

  edit() {
    this.editF = true
  }

  saveAchievement(achievement: Achievement) {
    this.save.emit(achievement)
    this.cancel()
  }

  cancel() {
    this.editF = false
  }

  getDate(date: string | undefined | null) {
    if (date) {
      let dateArr = date.split('/');
      return MonthNames[Number(dateArr[0]) - 1]?.substring(0, 3) + ' ' + dateArr[1];
    }
    return 'In Progress'
  }

  onDelete() {
    this.remove.emit();
  }
}
