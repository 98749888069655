export class Auth0Vars {
  static PRODUCTION_AUTH0_CLIENT_ID = 'eGOYGlBAgLHpyx8b5Wgec46qmQPl2lLW';
  static PRODUCTION_AUTH0_DOMAIN = 'jobpros.us.auth0.com';
  static DEV_AUTH0_CLIENT_ID = 'xrHe8C6eng3H0TgQH7X0r93GUo3ruxka';
  static DEV_AUTH0_DOMAIN = 'dev-3yfmei2c.us.auth0.com';
  static AUTH0_CONNECTION = 'Username-Password-Authentication';
}

export class Auth0Settings {
  static DEVAUTH = {
    url: 'https://dev-3yfmei2c.us.auth0.com/oauth/token',
    body: {
      client_id: 'UEVcWhwKrOBY0HO1DXVhS7DeMEAMWtkG',
      client_secret:
        'W-s8WMZW7YDaZOvLNczwqz6DRhRnhBmZbtwLsVHuRRRLvSvFsGJKAy6gmJfm542a',
      audience: 'https://dev-3yfmei2c.us.auth0.com/api/v2/',
      grant_type: 'client_credentials',
    },
  };

  static PRODAUTH = {
    url: 'https://jobpros.us.auth0.com/oauth/token',
    body: {
      client_id: 'e5rv0WN73Q1TmQZ0oeL93AUvjqHucO1u',
      client_secret:
        'yvV_es1Z3d-XCkWhppsralpy6jqdUrqo6wANROLZxzI-T2mo268H24Vuvuo4e3yg',
      audience: 'https://jobpros.us.auth0.com/api/v2/',
      grant_type: 'client_credentials',
    },
  };
}
