<div id="service-img" class="slide-wrapper relative">
  <div
    *ngFor="let item of slides; let idx = index"
    class="w-full h-full absolute left-0 top-0 rounded-2xl overflow-hidden"
    [class]="idx === selected ? 'block' : 'hidden'"
    (click)="close()"
  >
    <img [src]="item.path" class="w-full h-full object-cover"/>
  </div>
  <div class="nav-btn-group flex w-screen justify-between -mx-5">
    <button *ngIf="slides?.length>1" mat-icon-button class="button outlined black" (click)="prev()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button *ngIf="slides?.length>1"  mat-icon-button class="button outlined black" (click)="next()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>
