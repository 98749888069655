import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';

@Component({
  selector: 'app-auth-reset-password',
  templateUrl: './auth-reset-password.component.html',
  styleUrls: ['./auth-reset-password.component.scss'],
})
export class AuthResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  routesConst = routesConst;
  disableResetButton = false;
  @Input() isDialog = false;
  @Output() forsignup = new EventEmitter<string>();

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
  ) {
    this.resetForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z, A-Z]{2,3}'
          ),
        ],
      ],
    });
  }

  ngOnInit(): void {}

  public errorHandling = (control: string, error: string) => {
    return this.resetForm.controls[control].hasError(error);
  };

  openSignUp(pageToOpen: string) {
    this.router.navigate(['/']);
    this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px':'100vw',
      height: window.innerWidth > 600 ? 'h-fit':'100%',
      maxWidth: window.innerWidth > 600 ? '96%':'100vw',
      maxHeight: window.innerWidth > 600 ? '600':'100vh',
      data: { pageToOpen },
    });
  }

  changePassword() {
    this.disableResetButton = true;
    const email=this.resetForm.get('email')?.value.toLowerCase();
    this.authService
      .changePassword(email)
      .then(() => {
        setTimeout(() => {
          this.router.navigate(['/']);
          this.dialog.open(LoginSignupDialogComponent, {
            width: window.innerWidth > 600 ? '400px':'100vw',
            height: window.innerWidth > 600 ? 'h-fit':'100%',
            maxWidth: window.innerWidth > 600 ? '96%':'100vw',
            maxHeight: window.innerWidth > 600 ? '600':'100vh',
            data: { pageToOpen: 'login' },
          });
        }, 2000);
      })
      .catch(() => {
        this.disableResetButton = false;
      });
  }

  forSignUpRedirection(routeName:string) {
    this.forsignup.emit(routeName);
  }
}
