import { Component, OnInit } from '@angular/core';
// import { ChartOptions } from '@app-core/models/model';

@Component({
  selector: 'app-dashboard-users-influencers',
  templateUrl: './dashboard-users-influencers.component.html',
  styleUrls: ['./dashboard-users-influencers.component.scss'],
})
export class DashboardUsersInfluencersComponent implements OnInit {
  // public chartOptions: ChartOptions;

  followerRangeArr = [
    { key: 'thisWeek', value: 'This week' },
    { key: 'thisMonth', value: 'This month' },
    { key: 'thisYear', value: 'This year' },
    { key: 'lastYear', value: 'Last year' },
  ];
  followerRange: any;

  constructor() {
    this.followerRange = 'thisYear';
    // this.chartOptions = {
    //   series: [
    //     {
    //       name: 'Influencer',
    //       data: [
    //         7000, 2000, 2500, 12000, 11000, 16000, 10000, 4900, 10000, 7500,
    //         9000, 2500,
    //       ],
    //     },
    //   ],
    //   chart: {
    //     height: 350,
    //     type: 'line',
    //     dropShadow: {
    //       enabled: true,
    //       color: '#000',
    //       top: 18,
    //       left: 7,
    //       blur: 10,
    //       opacity: 0.2,
    //     },
    //     toolbar: {
    //       show: false,
    //     },
    //   },
    //   colors: ['#2887ED', '#E63946'],
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   stroke: {
    //     curve: 'smooth',
    //   },
    //   title: {
    //     text: '',
    //     align: 'right',
    //   },
    //   grid: {
    //     borderColor: '#E5E5E5',
    //     row: {
    //       colors: ['#FFFFFF', 'transparent'],
    //       opacity: 0.5,
    //     },
    //   },
    //   markers: {
    //     size: 3,
    //   },
    //   xaxis: {
    //     categories: [
    //       'Jan',
    //       'Feb',
    //       'Mar',
    //       'Apr',
    //       'May',
    //       'Jun',
    //       'Jul',
    //       'Aug',
    //       'Sep',
    //       'Oct',
    //       'Nob',
    //       'Dec',
    //     ],
    //     title: {},
    //   },
    //   yaxis: {
    //     title: {
    //       text: '',
    //     },
    //     min: 0,
    //     max: 20000,
    //   },
    //   legend: {
    //     position: 'top',
    //     horizontalAlign: 'right',
    //     floating: true,
    //     offsetY: -5,
    //     offsetX: -0,
    //   },
    // };
  }

  ngOnInit(): void {}
}
