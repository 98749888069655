import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { defaultSortByOption, sortBy } from 'src/app/core/const/options';
import { NetworkService } from 'src/app/core/services/network.service';

@Component({
  selector: 'app-following-list',
  templateUrl: './following-list.component.html',
  styleUrls: ['./following-list.component.scss']
})
export class FollowingListComponent implements OnInit {
  private unsubscribe$ = new Subject();
  totalcount = 0;
  followingList: any[] = [];
  followingFilter = {
    sort_by: '',
    offset: 0,
    limit: 20
  };
  sortByData = sortBy;
  searchSortValue?: string = defaultSortByOption.defaultSortBy;
  loading: boolean = false;
  totalAvaileList: any;

  constructor(private spinner: NgxSpinnerService, private networkService: NetworkService) {
    this.getFollowingsList(this.searchSortValue)
  }

  ngOnInit(): void { }

  setSearchSort(val: string) {
    this.searchSortValue = this.sortByData.type.filter((item: { id: string; label: string }) => item.id === val)[0].label;
    this.getFollowingsList(this.searchSortValue)
  }

  unFollowUser(item: any) {
    this.networkService.unFollow(item.user_id).pipe(takeUntil(this.unsubscribe$)).subscribe((x: any) => {
      if (x.status == 'Success') {
        this.totalcount = this.totalcount - 1;
      }
      this.followingList = this.followingList.filter(user => user.user_id !== item.user_id)
    })
  }

  getFollowingsList(searchTypeVal: any) {
    this.spinner.show()
    this.followingFilter.sort_by = searchTypeVal;
    this.followingFilter.offset = 0;
    this.followingList = [];
    this.networkService.getFollowingList(this.followingFilter).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.followingList = res.data.followings;
      this.totalcount = res.totalFollowings
      this.totalAvaileList = this.followingList.length;
      this.spinner.hide()
    })
  }

  onScrollDown() {
    if (this.totalcount != this.totalAvaileList) {
      this.spinner.show()
      this.followingFilter.offset = this.followingFilter.offset + 20;
      this.networkService.getFollowingList(this.followingFilter).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        this.spinner.hide()
        if (res?.data?.followings?.length) {
          this.followingList = [...this.followingList, ...res.data.followings];
          this.totalcount = res.totalFollowings;
          this.totalAvaileList = this.followingList.length;
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
