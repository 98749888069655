<div class="bg-white lg:border rounded-md px-4 pb-4 pt-0 lg:p-10 gap-2">
    <p  class="montserrat-bold text-black montserrat-bold">Skills</p>
    <p class="mt-2 black-light s">
      {{userCandiadate === user?.user_type ? 'Select your skills to be displayed':'Select skills your looking for'}} 
    </p>
    <div class="mt-2">
      <mat-divider class="w-full"></mat-divider>
    </div>
    <div class="flex flex-wrap gap-2 justify-center mx-auto mt-5 lg:mt-10">
        <p class="tag" *ngFor="let item of allSkills" (click)="item.active = !item.active; saveSkill = true;"
         [class]="item.active ? 'active' : ''">
          {{ item.name | titlecase }}
        </p>
    </div>
    <div class="mt-2">
      <button mat-button class="button outlined black" [class]="!saveSkill ? 'disabled' : ''" (click)="updateProfileSkills()">
        Save
      </button>
    </div>
  </div>