<div class="border-normal rounded bg-white py-6 px-4 lg:px-10 disabled" title="Coming Soon">
  <app-plan-upgrade></app-plan-upgrade>
  <p class="mt-7 black-light montserrat-bold uppercase">membership packages</p>
  <p class="mt-2 black-light s">Add membership plans to earn money in the site</p>
  <div class="mt-2">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div *ngFor="let plan of plans" class="flex flex-col lg:flex-row items-center border-normal rounded p-4 mt-6">
    <div class="flex w-full items-center flex-row">
      <p class="tag bg-black white montserrat-bold">{{plan.title}}</p>
      <p class="ml-auto lg:ml-4 lg:mt-0">34 subscribers · {{plan.price > 0 ? '$' + plan.price + '/mo' : 'Free'}}</p>
    </div>
    <div class="flex justify-between lg:justify-end mt-4 lg:mt-0 ml-0 lg:ml-auto">
      <button mat-flat-button (click)="createNewSubscriptionPackageModal()">
        <span class="s blue cursor-pointer montserrat-bold">Edit</span>
      </button>
      <button mat-flat-button>
        <span class="s red cursor-pointer montserrat-bold ml-3">Remove</span>
      </button>
    </div>
  </div>
  <div class="mt-8">
    <button class="blue montserrat-bold cursor-pointer disabled" (click)="createNewSubscriptionPackageModal()">+ Add
      new</button>
  </div>
</div>

<div class="border-normal rounded bg-white p-4 lg:p-10 mt-4  ">
  <p class="black-light montserrat-bold uppercase">privacy
  </p>

  <p class="mt-2 black-light s">Choose the content you want to share in the community</p>
  <div class="mt-2">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div class="flex mt-6">
    <label class="flex mt-2  ">
      <input id="checkbox1" [checked]="user?.online_status" type="checkbox" class="checkbox1 hidden" />
      <label class="switchbox " for="checkbox1"></label>
    </label>
    <div class="ml-3.5">
      <p class="montserrat-bold">Show active status</p>
      <p class="s black-light mt-2">A green dot next to your avatar will show people that you're active.</p>
    </div>
  </div>
  <div class="flex mt-6  disabled ">
    <label class="flex mt-2 ">
      <input id="checkbox2" disabled type="checkbox" class="checkbox1 hidden" />
      <label class="switchbox disabled" for="checkbox2"></label>
    </label>
    <div class="ml-3.5">
      <p class="montserrat-bold">Allow sharing</p>
      <p class="s black-light mt-2">Let people share your content as messages.</p>
    </div>
  </div>
  <div class="flex mt-6 disabled">
    <label class="flex mt-2">
      <input id="checkbox3" disabled type="checkbox" class="checkbox1 hidden" />
      <label class="switchbox disabled" for="checkbox3"></label>
    </label>
    <div class="ml-3.5 disabled">
      <p class="montserrat-bold">Allow tags</p>
      <p class="s black-light mt-2">Let people tag you in content.</p>
    </div>
  </div>
</div>