import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MonthPickerComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() date0: string | undefined | null;
  @Input() min: string | undefined;
  @Input() max: string | undefined;
  @Output() setDate: EventEmitter<any> = new EventEmitter();
  date = new FormControl(moment());
  minDate?: Moment;
  maxDate?: Moment;
  choosenYear: any;

  constructor() {
    this.date.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setDate.emit(moment(this.date.value).format('MM/YYYY'));
    });
  }

  ngOnInit(): void {
    let dateArr = this.date0?.split('/');
    if (dateArr) {
      this.date0 = dateArr[1] + '-' + dateArr[0];
      this.date.setValue(moment(this.date0 + '-28T00:00:00.000Z'));
    }
    this.updateMinMax();
  }
  updateMinMax() {
    const minArr = this.min?.split('/');
    if (minArr)
      this.minDate = moment([Number(minArr[1]), Number(minArr[0]) - 1, 1]);

    const maxArr = this.max?.split('/');
    if (maxArr)
      this.maxDate = moment([Number(maxArr[1]), Number(maxArr[0]) - 1, 1]);
  }

  ngOnChanges() {
    this.updateMinMax();
  }

  chosenYearHandler(normalizedYear: Moment) {
    this.choosenYear = this.date.value;
    this.choosenYear?.year(normalizedYear.year());
    this.choosenYear?.month(new Date().getMonth());
    // this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.date.setValue(this.choosenYear);
    const ctrlValue = this.date.value;
    ctrlValue?.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
