<div class="campaign-detail-modal flex flex-col justify-between relative md:-m-6 md:p-10 md:pb-6">
  <div class="absolute -right-4 md:right-3 -top-4 md:top-2 cursor-pointer">
    <mat-icon class="close-button" mat-dialog-close>cancel</mat-icon>
  </div>
  <div class="flex flex-col justify-center items-center lg:flex-row">
    <img [src]="data?.content[0]?.url" class="campaign-detail-modal_avatar">
    <div class="lg:ml-8">
      <div class="flex justify-center lg:justify-start items-center mt-2 lg:mt-0">
        <!-- <div class="w-1 h-1 bg-red"></div> -->
        <p >{{data?.profile?.name}}</p>
      </div>
      <div class="mt-2">
        <h2 class="montserrat-bold livestream-description">{{data.title | titlecase}}</h2>
      </div>
      <div class="flex items-center">
        <p class="s mr-1">Posted {{data?.created_at | date : 'medium'}} ·</p>
      </div>
      <li *ngFor="let data of data.usercreatedplan">
        {{data.title}}
      </li>
      <h4 *ngIf="!data.usercreatedplan.length" class="montserrat-bold m">All</h4>
      <p class="mt-5 lg:mt-6">{{data?.description}}</p>
    </div>
  </div>
  <div class="pt-6" >
    <mat-divider class="w-full"></mat-divider>
  </div>
</div>
