<div class="multimedia-wrapper mx-auto">
  <div #homeJobs id="homeJobs" class="mb-10 pb-10 border-b" *ngIf="jobs?.length">
    <div class="flex justify-between md:justify-start mb-4">
      <div>
        <h1 class="text-underline">Jobs</h1>
        <!-- <p class="heading-underline"></p> -->
      </div>
      <div
        class="flex ml-4 w-full"
        [ngClass]="
          userType == userTypeConstant.company ? 'justify-end md:justify-between' : 'justify-end'
        "
      >
        <button
          mat-button
          class="flex montserrat-medium mt-md button outlined black btn-size"
          *ngIf="userType == userTypeConstant.company"
          (click)="navigateTo(routesConst.jobs)"
        >
          <!-- <mat-icon>add</mat-icon> -->
          + Add
        </button>
        <button
          mat-button
          class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
          (click)="authService.loggedIn() ? navigateToUrl('/jobs') : navigateTo(routesConst.signup)"
        >
          View All
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-8 md:gap-2 md:grid-cols-3">
      <div *ngFor="let job of jobs; let i = index" class="relative h-fit md:h-full">
        <app-job-card
          [isHome]="true"
          [jobList]="job"
          (save)="saveJob(job)"
          (apply)="openApplyDialog(job)"
        ></app-job-card>
        <div
          class="absolute top-0 left-0 right-0 bottom-0 cursor-pointer"
          (click)="jobDetail(job.job._id)"
        ></div>
      </div>
    </div>
    <button
      mat-button
      class="view-rounded-btn-mobile montserrat-medium mt-md button outlined black btn-size mt-4 mx-auto"
      routerLink="/jobs"
    >
      View All
    </button>
  </div>

  <div #homeServices id="homeServices" class="mb-10 pb-10 border-b" *ngIf="talents?.length">
    <div class="flex justify-between md:justify-start mb-4">
      <div>
        <h1 class="text-underline">Services</h1>
        <!-- <p class="heading-underline"></p> -->
      </div>
      <div class="flex ml-4 justify-end md:justify-between w-full">
        <button
          mat-button
          class="flex montserrat-medium mt-md button outlined black btn-size"
          (click)="navigateTo(routesConst.talents)"
        >
          <!-- <mat-icon>add</mat-icon> -->
          + Add
        </button>
        <button
          mat-button
          class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
          (click)="
            authService.loggedIn()
              ? navigateToUrl('/' + routesConst.talents)
              : navigateTo(routesConst.signup)
          "
        >
          View All
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1">
      <div class="grid grid-cols-1 gap-8 md:gap-2 md:grid-cols-3">
        <app-talent-card *ngFor="let talent of talents" [talent]="talent"></app-talent-card>
      </div>
    </div>
    <button
      mat-button
      class="view-rounded-btn-mobile montserrat-medium mt-md button outlined black btn-size mt-4 mx-auto"
      [routerLink]="'/' + routesConst.talents"
    >
      View All
    </button>
  </div>

  <!-- MEMBERS SECTION -->
  <!-- <div class="mb-12 pb-12 border-b" *ngIf="members?.length">
    <div class="flex justify-between md:justify-start mb-4">
      <div>
        <h1 class="text-underline">Companies</h1>
      </div>

      <div class="flex justify-end w-full ml-4">
        <button
          mat-button
          class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
          [routerLink]="'/creators'"
          [queryParams]="{ tab: 'people' }"
        >
          View All
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1">
      <app-members-panel
        *ngIf="members.length > 0"
        [data]="members"
        [searchTypeVal]="'people'"
        [layoutMode]="'grid_view'"
        class="w-full"
      ></app-members-panel>
    </div>
    <button
      mat-button
      class="view-rounded-btn-mobile montserrat-medium mt-md button outlined black btn-size mt-4 mx-auto"
      [routerLink]="'/creators'"
      [queryParams]="{ tab: 'people' }"
    >
      View All
    </button>
  </div> -->

  <div #homeCreators id="homeCreators" class="mb-10 pb-10 border-b" *ngIf="posts?.length">
    <div class="flex justify-between md:justify-start mb-4">
      <div>
        <h1 class="text-underline">Creators</h1>
        <!-- <p class="heading-underline"></p> -->
      </div>

      <div class="flex justify-end md:justify-between w-full ml-4">
        <button
          mat-button
          class="flex montserrat-medium mt-md button outlined black btn-size"
          (click)="navigateTo(routesConst.content)"
        >
          <!-- <mat-icon>add</mat-icon> -->
          + Add
        </button>
        <button
          mat-button
          class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
          (click)="
            authService.loggedIn() ? navigateToUrl('/creators') : navigateTo(routesConst.signup)
          "
        >
          View All
        </button>
      </div>
    </div>
    <div class="home-page-story homepage-post mb-4 grid grid-cols-1">
      <app-post-story [storyPosts]="stories" (listEnd)="onStorySlideChange()"></app-post-story>
    </div>
    <div class="grid masonary-grid-gap grid-cols-1 md:grid-cols-4 homePage-grid">
      <div class="break-inside rounded-lg h-full home-post" *ngFor="let item of posts">
        <app-post-detail-card
          #postDetailCard
          class="h-full block"
          [isHomePost]="true"
          [postID]="item._id"
          [loginedProfile]="profile"
          [loginedFollowers]="followings"
          (postByInterest)="getRelatedPosts(item._id)"
          [appInViewLogger]="{
            type: 'post',
            postId: item._id,
            isMobileView: innerWidth < 768,
            isSingleGrid: innerWidth < 768
          }"
          (viewLogged)="onPostViewLogged($event, postDetailCard)"
        >
        </app-post-detail-card>
      </div>
    </div>
    <button
      mat-button
      class="view-rounded-btn-mobile montserrat-medium mt-md button outlined black btn-size mt-4 mx-auto"
      routerLink="/creators"
    >
      View All
    </button>
  </div>

  <div
    #homeSubscriptions
    id="homeSubscriptions"
    class="mb-10 pb-10 border-b"
    *ngIf="myPlans?.length"
  >
    <div class="flex justify-between md:justify-start mb-4">
      <div style="text-wrap: nowrap">
        <h1 class="text-underline">Subscriptions</h1>
        <!-- <p class="heading-underline"></p> -->
      </div>

      <div class="flex justify-end md:justify-between w-full ml-4">
        <button
          mat-button
          class="flex montserrat-medium mt-md button outlined black btn-size"
          (click)="navigateTo(routesConst.subscription)"
        >
          <!-- <mat-icon>add</mat-icon> -->
          + Add
        </button>
        <button
          mat-button
          class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
          (click)="
            authService.loggedIn()
              ? navigateToUrl('/creators', { tab: 'subscriptions' })
              : navigateTo(routesConst.signup)
          "
        >
          View All
        </button>
      </div>
    </div>
    <div>
      <div class="relative">
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <app-individual-service-card
            *ngFor="let plan of myPlans"
            [routerLink]="'/' + 'profile' + '/' + plan?.profile?.user_name"
            [queryParams]="{ tab: 4 }"
            [service]="plan"
          >
          </app-individual-service-card>
        </div>
      </div>
      <button
        mat-button
        class="view-rounded-btn-mobile montserrat-medium mt-md button outlined black btn-size mt-4 mx-auto"
        [routerLink]="'/creators'"
        [queryParams]="{ tab: 'subscriptions' }"
      >
        View All
      </button>
    </div>
  </div>

  <!-- <div>
    <div class="flex justify-between items-center mb-4">
      <div>
        <h1>Referrals</h1>
        <p class="heading-underline"></p>
      </div>
      <button
        class="button flex white view-all-button"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
      >
        Get Started
      </button>
    </div> -->

  <!-- LAPTOP SCREEN -->
  <!-- <div *ngIf="innerWidth && innerWidth >= 768" class="grid grid-cols-1">
      <div class="referal-cards mx-auto md:px-4 lg:px-0">
        <div
          *ngFor="let item of Refferal"
          class="flex flex-col items-center card-style cursor-pointer"
          [routerLink]="
            '/' + routesConst.dashboard + '/' + routesConst.referrals
          "
        >
          <div>
            <p class="text-center text-lg title" *ngIf="item?.title">
              {{ item.title }}
            </p>
            <div class="mt-4">
              <p class="description text-center" *ngIf="item?.description">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  <!-- MOBILE SCREEN -->
  <!-- <div *ngIf="innerWidth && innerWidth < 768" class="grid grid-cols-1 h-48">
      <div class="grid mx-auto md:px-4 lg:px-0">
        <swiper [pagination]="{ clickable: true }" [config]="config">
          <ng-template *ngFor="let item of Refferal" swiperSlide>
            <div
              class="flex flex-col items-center card-style cursor-pointer"
              [routerLink]="
                '/' + routesConst.dashboard + '/' + routesConst.referrals
              "
            >
              <div>
                <p class="text-center text-lg title" *ngIf="item?.title">
                  {{ item.title }}
                </p>
                <div class="mt-4">
                  <p class="description text-center" *ngIf="item?.description">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div> -->
  <!-- <button
      class="view-all-button button flex md:hidden w-full mt-4"
      [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
    >
      Get Started
    </button> -->
  <!-- </div> -->
</div>
<!-- <ngx-spinner
  bdOpacity="0.9"
  bdColor="black-dark"
  size="medium"
  color="red"
  type="ball-spin-clockwise"
  [fullScreen]="true"
  class="job-list-spinner"
>
</ngx-spinner> -->
