import { Component, Input, OnInit } from '@angular/core';
import { UploadPostModalComponent } from '../upload-post-modal/upload-post-modal.component';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobPostComponent } from '../job-post/job-post.component';
import { CommonService } from 'src/app/core/services/common.service';
import { planTypeConstant, statusConstant, userTypeConstant } from 'src/app/const/appConst';
import { Subject, takeUntil } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { ServiceModalComponent } from '../service-modal/service-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../../services/snack-bar.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { routesConst } from 'src/app/core/const/routers';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SubscriptionPackageModalComponent } from '../subscription-package-modal/subscription-package-modal.component';

@Component({
  selector: 'app-quick-create-component',
  templateUrl: './quick-create-component.component.html',
  styleUrls: ['./quick-create-component.component.scss']
})
export class QuickCreateComponentComponent implements OnInit {
  @Input() sideBarToogle = false;
  userTypeConst = userTypeConstant;
  private unsubscribe$ = new Subject();
  userType: string = userTypeConstant.candidate;
  mySubscriptions: any;

  addExpand = false;
  addHideShow = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private sharedService: SharedService,
    private configuration: ConfigurationService,
    private spinner: NgxSpinnerService,
    private snack: SnackBarService,
    private userPlan: userPlans,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.getLoginedUserType();
      this.getPlateformPlans();
    }
  }

  getLoginedUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      this.userType = type;
      if (type === '') {
        this.sharedService.getUserType();
      }
    });
  }

  getPlateformPlans() {
    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plan: any) => {
        const { platform_subscription } = plan;
        this.mySubscriptions = platform_subscription;
      });
  }

  tweetmenu() {
    this.addExpand = !this.addExpand;
    setTimeout(() => {
      this.addHideShow = !this.addHideShow;
    }, 100);
  }

  itemActive(item: any) {
    this.dialog.closeAll();
    if (item === 'content') {
      this.proceed(item);
    } else {
      this.checkEligibility(item);
    }
  }

  checkEligibility(item: any) {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          this.spinner.hide();
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete Stripe onboarding
              to post jobs, expert services,
              create paid subscriptions, or
              earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceed(item);
        }
      });
  }

  proceed(item: any) {
    if (item === 'content') {
      this.spinner.hide();

      this.dialog.open(UploadPostModalComponent, {
        maxWidth: '100vw',
        width: '536px',
        disableClose: true,
        height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
        data: {}
      });
    } else if (item === 'jobs') {
      this.spinner.hide();

      this.dialog.open(JobPostComponent, {
        width: '536px',
        height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
        maxWidth: '100vw',
        hasBackdrop: false,
        panelClass: 'post-job-dialog-container'
      });
    } else if (item === 'services') {
      this.proceedToNewService();
    } else if (item === 'campaign') {
      this.newSubscriptionPackage();
    } else if (item === 'subscription') {
      this.newSubscriptionPackage();
    }
  }

  proceedToNewService() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    const plan1 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.business;
    const plan2 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.pro
        : planTypeConstant.enterprise;
    if (
      this.mySubscriptions?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan1.toLowerCase()
      ) != -1 ||
      this.mySubscriptions?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan2.toLowerCase()
      ) != -1
    ) {
      this.spinner.show();
      this.configuration
        .configureCustomerPortal()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.spinner.hide();
            this.dialog.open(ServiceModalComponent, {
              maxWidth: '100vw',
              width: '536px',
              height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
              data: { editmode: false },
              disableClose: true
            });
          },
          (error: any) => {
            this.spinner.hide();
            this.snack.showMessage(`${error} `, true);
          }
        );
    } else {
      this.spinner.hide();
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: {
          message: `Pro, Elite, Business or Enterprise Members`,
          header: 'Post Services',
          buttonText: 'Upgrade'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/' + routesConst.planPricing]);
        }
      });
    }
  }

  // postCampaign() {
  //   this.profileService.getpurchasedPlan().subscribe((res: any) => {
  //     let userCurrentPlan = res.currentPlan;
  //     if (
  //       userCurrentPlan?.plan_type == planTypeConstant.elite ||
  //       userCurrentPlan?.plan_type == planTypeConstant.nil ||
  //       userCurrentPlan?.plan_type == planTypeConstant.enterprise
  //     ) {
  //       this.dialog.open(UploadPostModalComponent, {
  //         maxWidth: '100vw',
  //         width: '536px',
  //         disableClose: true,
  //         height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
  //         data: { isCampaign: true },
  //       });
  //     } else {
  //       const dialogRef = this.dialog.open(ConfirmModalComponent, {
  //         width: '500px',
  //         data: {
  //           message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`,
  //           header: 'Campaign Features',
  //         },
  //       });
  //       dialogRef.afterClosed().subscribe((result) => {
  //         if (result) {
  //           this.router.navigate(['/' + routesConst.pricing]);
  //         }
  //       });
  //     }
  //   });
  // }

  newSubscriptionPackage() {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          this.spinner.hide();
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewSubscriptionPackage();
        }
      });
  }

  proceedToNewSubscriptionPackage() {
    let comparePlan =
      this.userType == userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.enterprise;
    this.spinner.show();
    this.configuration
      .configureCustomerPortal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (
          this.mySubscriptions?.findIndex(
            (item: any) => item.plan_type.toLowerCase() == comparePlan.toLowerCase()
          ) != -1 ||
          (this.userType === userTypeConstant.candidate &&
            this.mySubscriptions?.findIndex(
              (item: any) => item.plan_type.toLowerCase() == planTypeConstant.nil.toLowerCase()
            ) != -1)
        ) {
          const redirect_url = this.commonService.location().href
          const refresh_url = this.commonService.location().href
          this.userPlan
            .getStripeUser(redirect_url, refresh_url)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (user: any) => {
                this.spinner.hide();
                if (user.status == 'on_board_required') {
                  if (user?.accountLink?.url) {
                    this.commonService.location().href = user?.accountLink?.url;
                  }
                  this.snack.showMessage(`${user.message} `, true);
                } else {
                  this.dialog.open(SubscriptionPackageModalComponent, {
                    width: '536px',
                    height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
                    maxWidth: '100vw',
                    hasBackdrop: false
                  });
                }
              },
              error => {
                this.spinner.hide();
                this.snack.showMessage(`${error} `, true);
              }
            );
        } else {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Elite or Enterprise Members`,
              header: 'Post Subscriptions',
              buttonText: 'Upgrade'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
          this.spinner.hide();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    // this.dialogRef?.close();
  }
}
