import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { JobService } from 'src/app/core/services/job.service';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
@Component({
  selector: 'app-my-network-tab',
  templateUrl: './my-network-tab.component.html',
  styleUrls: ['./my-network-tab.component.scss']
})
export class MyNetworkTabComponent implements OnInit {
  private unsubscribe$ = new Subject();
  messageText: any = '';
  @Input() color: ThemePalette = 'accent';
  @Input() diameter?: number = 50;
  @ViewChild('scrollChat') private myScrollContainer!: ElementRef;
  @ViewChild('scrollUserList') private myScroll!: ElementRef;
  @ViewChild('scrollUserListMobileView') private myScrollMobileView!: ElementRef;
  @ViewChild('userSearch', { static: true })
  userSearch?: ElementRef;
  @Input() sender_id: any;
  @Input() reciever: any;
  @Input() user: any;
  @Output() myNetworkUnreadMessages: EventEmitter<any> = new EventEmitter();
  sendMessagetext: string;
  chatMessages: any;
  totalCounts: any;
  scrollFlag = false;
  work_images: any;
  recieverId: any;
  plans: any;
  chatFilters = { offset: 0, limit: 10 };
  myControl = new FormControl();
  scrollPosition: any = 0;
  totalUserListCount: any;
  tabCollapse: boolean = true;
  messageFilters = { offset: 0, limit: 20 };
  count: any = 0;
  showSpinner = false;
  chatForm = new FormGroup({
    message_content: new FormControl('', Validators.required)
  });
  userList: any;
  selectedUser: any;
  selectedUserId?: any;

  constructor(
    private sharedService: SharedService,
    private snack: SnackBarService,
    private _jobService: JobService,
    private _commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.sendMessagetext = '';
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((inbox: any) => {
      if (inbox.profile_id) {
        this.selectedUserId = inbox.profile_id;
        this.showMessageHistory(this.selectedUserId);
      }
    });
    this._commonService
      .newNetworkMessageReceived()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message: any) => {
        this._commonService.refreshUnreadCount(true);

        if (message && message.to_id) {
          let isUserExist = false;
          if (this.userList?.length) {
            this.userList.filter((user: any) => {
              if (user?.userList?._id == message.from_id) {
                isUserExist = true;
              }
            });
          }
          if (isUserExist) {
            if (this.selectedUser?.userList?._id === message?.from_id) {
              this.chatMessages[0].data.push(message);
            } else {
              this.userList.filter((user: any) => {
                if (user?.userList?._id === message?.from_id) {
                  user.unread_messages.push(message);
                  this.myNetworkUnreadMessages.emit(this.userList);
                  this.newMessageSound();
                  this.chatFilters.offset = 0;
                  this.getChatUsers(this.selectedUserId);
                }
              });
            }
          } else {
            this._jobService
              .getCandidateById(message.from_id)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((user: any) => {
                this.userList.push({ userList: user.data, unread_messages: [] });
                this.userList.filter((user: any) => {
                  if (user?.userList?._id === message?.from_id) {
                    user.unread_messages.push(message);
                    this.newMessageSound();
                    this.chatFilters.offset = 0;
                    this.getChatUsers(this.selectedUserId);
                  }
                });
              });
          }
        }
      });
    this.scrollToBottom();
    this.getChatUsers();
    this._commonService
      ._filterChatUser(this.userSearch?.nativeElement, this.chatFilters, 'myNetwork')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        if (result.data && result.data.length) {
          this.userList = result?.data;
        } else {
          this.userList = [];
        }
      });
  }

  /**
   * Scroll to bottom
   */
  scrollToBottom(): void {
    this._commonService.getWindow()?.setInterval(() => {
      if (this.count == 0 && this.myScrollContainer) {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight;
      }
    }, 500);
  }

  /**
   * View Profile
   * @param user_name
   */
  viewProfile(user_name: any) {
    this.router.navigate(['/profile' + '/' + user_name]);
  }

  /**
   * Check wether user exist or not
   * @param recieverId
   * @param userList
   */
  checkUserAlreadyExistInUserList(recieverId: any, userList: any) {
    let isExist = false;
    if (userList && userList.length) {
      userList.find((user: any) => {
        if (user?.userList?._id == recieverId) {
          isExist = true;
        }
      });
    }
    if (!isExist) {
      this.userList.unshift({ userList: this.reciever });
      this.showSpinner = false;
    } else {
      if (this.userList?.length) {
        this.selectedUser = this.userList.find((userList: any) => {
          if (userList?.userList !== null) {
            return userList;
          }
        });
      }
      this.getChatMessages(recieverId);
    }
  }

  newMessageSound() {
    let audio = new Audio();
    audio.src = '../../../../../../../assets/audio/jobpros-message.wav';
    audio.load();
    audio.play();
  }

  /**
   * Show Messages History
   * @param id
   */
  showMessageHistory(id: string) {
    this.sendMessagetext = '';
    this.reciever = { _id: id };
    this.selectedUser = this.existingUser(id);
    this.readUnreadMessages(id);
    if (this.selectedUser?.userList?._id) {
      this.getChatMessages(this.selectedUser?.userList?._id);
    }
  }

  /**
   * Read UnRead Messages
   * @param to_id
   */
  readUnreadMessages(to_id: any) {
    this._jobService
      .readNetworkChatMessages(to_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.userList?.filter((user: any) => {
          if (user?.userList?._id === to_id) {
            user.unread_messages = [];
          }
        });
        this.myNetworkUnreadMessages.emit(this.userList);
        this.sharedService.checkIfUnreadMessageRemaining();
        this._commonService.refreshUnreadCount(false);
      });
  }

  /** */

  /**
   * Get Users Chat
   */
  getChatUsers(profile_id?: any) {
    this._jobService
      .getMyNetworkUsers(this.chatFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userResult: any) => {
        if (userResult.status === 'Success') {
          this.userList = userResult?.data;
          if (profile_id) {
            this.showMessageHistory(profile_id);
          }
          this.totalUserListCount = userResult.count;
          this.myNetworkUnreadMessages.emit(this.userList);
          if (this.reciever && this.reciever?._id) {
            this.checkUserAlreadyExistInUserList(this.reciever._id, userResult?.data);
          }
          if (this.userList?.length && this.reciever && this.reciever._id) {
            this.selectedUser = this.existingUser(this.reciever._id);
            if (this.selectedUser?.data?._id) {
              this.getChatMessages(this.selectedUser?.data?._id);
            }
          } else {
            if (this.userList?.length) {
              this.selectedUser = this.userList.find((userList: any) => {
                if (userList.userList !== null) {
                  this.getChatMessages(this.selectedUser?.userList?._id);
                  return userList;
                }
              });
              this.getChatMessages(this.selectedUser?.userList?._id);
            }
          }
        }
        this.readUnreadMessages(this.selectedUser?.userList?._id);
      });
  }

  existingUser(id: any) {
    return this.userList?.find((item: any) => item?.userList?._id === id);
  }

  /**
   * Get selected Chat messages of user
   * @param selectedChatId
   */
  getChatMessages(selectedChatId: any) {
    this.showSpinner = true;
    this.chatMessages = [];
    this.recieverId = selectedChatId;
    if (selectedChatId && this.sender_id) {
      this._jobService
        .getmynetworkMessages(selectedChatId, this.messageFilters)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((chat: any) => {
          this.showSpinner = false;
          if (chat.status == 'Success') {
            this.messageFilters.offset = 0;
            this.count = 0;
            this.scrollToBottom();
            this.chatMessages = chat.data;
          } else {
            this.chatMessages = [];
          }
        });
    }
  }

  /**
   * Send New Messages
   */
  sendMessage(event: any) {
    this.messageText = this.sendMessagetext;
    if ((event.key === 'Enter' && !event.shiftKey) || event.type === 'click') {
      this.sendMessagetext = '';
      event.preventDefault();
      if (this.messageText.trimStart().length) {
        this.messageFilters.offset = 0;
        this.count = 0;
        this.scrollToBottom();
        if (this.recieverId) {
          this.reciever = { _id: this.recieverId };
        }
        if (this.reciever && this.reciever?._id) {
          let sendMessage = {
            to_id: this.reciever._id,
            message_content: this.messageText,
            read_status: false,
            type: 'UNREAD'
          };
          if (this.messageText && this.messageText.trimStart().length && this.sender_id) {
            this._jobService
              .postMyNetworkChat(sendMessage)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((chatMessageRes: any) => {
                if (chatMessageRes.status == 'success') {
                  this.chatForm.reset();
                  this.scrollFlag = true;
                  this.work_images = [];
                  this.sendMessagetext = '';
                  this._jobService
                    .getMyNetworkUsers(this.user.user_type, this.chatFilters)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((userResult: any) => {
                      if (userResult.status === 'Success') {
                        this.userList = userResult.data;
                      }
                      this._jobService
                        .getmynetworkMessages(this.reciever._id, this.messageFilters)
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((chat: any) => {
                          if (chat.status == 'Success') {
                            this.sendMessagetext = '';
                            this.chatMessages = chat.data;
                          } else {
                            this.chatMessages = [];
                          }
                        });
                    });
                } else {
                  this.snack.showMessage(chatMessageRes.message, true);
                }
              });
          }
        }
      }
    }
  }

  onScrollDown(keyword?: any) {
    let scroll =
      keyword == 'mobileView'
        ? this.myScrollMobileView.nativeElement.scrollTop +
            this.myScrollMobileView.nativeElement.clientHeight ==
          this.myScrollMobileView.nativeElement.scrollHeight
        : this.myScroll.nativeElement.scrollTop + this.myScroll.nativeElement.clientHeight ==
          this.myScroll.nativeElement.scrollHeight;
    if (scroll && this.userList.length != this.totalUserListCount) {
      this.chatFilters.offset = this.chatFilters.offset + 10;
      this._jobService
        .getMyNetworkUsers(this.chatFilters)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((userResult: any) => {
          if (userResult.status === 'Success') {
            this.userList = [...this.userList, ...userResult?.data];
          }
        });
    }
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  onScrollUp() {
    this.count = this.count + 1;
    if (this.myScrollContainer.nativeElement.scrollTop == 0) {
      this.messageFilters.offset = this.messageFilters.offset + 10;
      this._jobService
        .getmynetworkMessages(this.recieverId, this.messageFilters)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((chat: any) => {
          if (chat.status == 'Success') {
            this.sendMessagetext = '';
            chat?.data[0]?.data.forEach((element: any) => {
              this.chatMessages[0]?.data.unshift(element);
            });
          } else {
            this.chatMessages = [];
          }
        });
    }
  }

  getProfileImage() {
    return this.selectedUser?.userList?.profile_img_path
      ? this.selectedUser?.userList?.profile_img_path
      : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs';
  }

  getSenderImage() {
    return this.user?.avatar ? this.user?.avatar : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs';
  }

  openTabCollapse() {
    this.tabCollapse = !this.tabCollapse;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
