import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { shareTypeConst, toDisplayConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PostService } from 'src/app/core/services/post.service';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { MentionService } from '../../services/mentions.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { UploadPostModalComponent } from '../upload-post-modal/upload-post-modal.component';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-post-detail-card',
  templateUrl: './post-detail-card.component.html',
  styleUrls: ['./post-detail-card.component.scss']
})
export class PostDetailCardComponent implements OnInit {
  private unsubscribe$ = new Subject();

  isLoading = false;
  activeSlide: number = 1;
  toDisplayConstant = toDisplayConstant;

  @Input() set postID(value: any) {
    this.post_id = value;
    if (value != this.previousValue) {
      this.previousValue = value;
      this.getInfo(value);
    }
  }

  @Input() set commentIdChanged(value: any) {
    this.getInfo(this.post_id);
  }

  @Input() set addView(value: any) {
    this.addViews(value);
  }

  @Input() set interestList(value: any) {
    if (value) {
      this.interest = value;
    }
  }

  @Input() set loginedProfile(value: any) {
    if (value) {
      this.profile = value;
    }
  }

  @Input() set loginedFollowers(value: any) {
    if (value) {
      this.followers = value;
    }
  }

  @Input() isOpenBottomSheet: any = false;
  @Input() isHomePost: any = false;
  @Input() feedCardType: any;

  @Output() postByInterest: EventEmitter<any> = new EventEmitter();

  routesConst = routesConst;
  commentForm: FormGroup;
  isFavor: boolean;
  likeId: string;
  postTitle: string = '';
  postDescription: string = '';
  postOwnerID: string = '';
  postOwnerName: string = '';
  postOwnerAvatar: string = '';
  data: any;
  postInterest?: any[];
  interest: any = [];
  user_id: string;
  images?: any[];
  followers: any[];
  relatedPosts0: any[];
  likeNumber: number;
  postDesclaimer?: any[];
  showDesclaimer: boolean = false;
  isMore: boolean = true;
  isMobile: boolean = true;
  isBigger: boolean = true;
  postW?: number = 0;
  profile: any;
  previousValue: any;
  post_id: any;
  showComment: boolean = false;
  innerWidth = window.innerWidth;
  threadId: any;
  isLoadingLike = false;

  slideOptions = {
    loop: true,
    items: 4,
    margin: 20,
    duration: 600,
    timer: 3000,
    banner: true,
    responsive: [
      { breakPoint: 1200, items: 4 },
      { breakPoint: 900, items: 3 },
      { breakPoint: 0, items: 2 }
    ]
  };

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.isMobile = document.body.clientWidth < 768;
  }

  offset = 0;
  limit = 5;

  @ViewChild('videoPlayer') videoElement: ElementRef<HTMLVideoElement> | undefined;

  constructor(
    private router: Router,
    private snack: SnackBarService,
    private clipboard: Clipboard,
    private datePipe: DatePipe,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private postService: PostService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private commonService: CommonService,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public mentionService: MentionService
  ) {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((comment: any) => {
      if (comment?.comment) {
        this.showComment = true;
        this.threadId = comment.comment;
        setTimeout(() => {
          if (this.isOpenBottomSheet) {
            this.openBottomSheet();
          }
        }, 2000);
      }
    });

    this.commentForm = this.fb.group({
      comment: ['', [Validators.required, Validators.maxLength(256)]]
    });
    this.isFavor = false;
    this.likeId = '';
    this.user_id = '';
    this.followers = [];
    this.relatedPosts0 = [];
    this.likeNumber = 0;
  }

  addViews(postId: any) {
    // if (this.authService.loggedIn()) {
    const payload = {
      content_id: postId,
      type: 'post'
    };
    this.postService
      .updateViews(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (this.data) {
          // If 'views' is not defined, initialize it to 0
          this.data.views = this.data.views || 0;
          this.data.views += 1;
        }
      });
    // }
  }

  wrapStringWithTag(str: any) {
    const mentionRegex = /@([\w.]+)/g;
    const hashtagRegex = /#([\w.]+)/g;
    str = str.replace(
      mentionRegex,
      '<span class="blue cursor-pointer post-description">@$1</span>'
    );

    str = str.replace(
      hashtagRegex,
      '<span class="blue cursor-pointer post-description">#$1</span>'
    );

    return str;
  }

  openBottomSheet(): void {
    if (this.isHomePost) {
      this.router.navigate([routesConst.community + '/' + 'post' + '/' + this.post_id]);
      return;
    }

    if (this.innerWidth <= 768) {
      const bottomSheetRef = this._bottomSheet.open(CommentModalComponent, {
        data: {
          postOwnerID: this.postOwnerID,
          data: this.data,
          profile: this.profile,
          post_id: this.post_id
        }
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        if (result) {
          this.searchByInterest(result);
        }
      });
    }
  }

  ngOnInit(): void {
    this.isMobile = document.body.clientWidth < 768;
    this.postW = this.commonService.getWindow()?.outerWidth;
  }

  onSlideChange(swiper: any) {
    this.activeSlide = swiper.activeIndex + 1;
    this.changeDetector.detectChanges();
  }

  mentionSelect(item: any) {
    this.commentForm.get('comment')?.setValue(this.commentForm.get('comment')?.value + ' ');
  }

  getInfo(postId: any) {
    this.postService
      .getPostDetails(postId, this.offset, this.limit, this.threadId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (postReslt: any) => {
          const { user_name, profile_image } = postReslt;
          this.postOwnerName = user_name;
          this.postOwnerAvatar = profile_image;
          if (postReslt.data) {
            this.data = postReslt.data[0];
            //Show specific comment on top
            if (this.data?.specificCommentDetails?.length) {
              this.data.commentDetails.unshift(this.data.specificCommentDetails[0]);
            }
            this.postID = this.data._id;
            this.postTitle = this.data.title;
            this.postDescription = this.data.description;
            this.postOwnerID = this.data.profile_id;
            this.postDesclaimer = this.data?.desclaimer ? this.data?.desclaimer : [];
            this.images = this.data.contentDetails;
            this.likeNumber = this.data?.likeDetails?.length;
            this.isFavor = this.data.likeDetails?.find(
              (like: any) => like.user_id === this.profile?._id
            )
              ? true
              : false;
            // const interests = this.interest;
            this.postInterest = this.data.category;
            // this.data.category.map((id: string) => {
            //   const interest = interests.find(
            //     (interest: any) => interest?.id === id
            //   );
            //   return interest;
            // });
          }
        },
        () => {
          this.data = null;
        }
      );
  }

  loadMoreComments() {
    this.offset += 5;
    this.isLoading = true;
    this.postService
      .getPostDetails(this.post_id, this.offset, this.limit, this.threadId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((postResult: any) => {
        let moreComments = postResult.data[0].commentDetails;

        this.data.commentDetails = [...this.data.commentDetails, ...moreComments];
        this.isLoading = false;
      });
  }

  isUserLiked() {
    return this.data?.likeDetails?.find((like: any) => like?.user_id === this.profile?._id)
      ? true
      : false;
  }

  public errorHandling = (control: string, error: string) => {
    return this.commentForm.controls[control].hasError(error);
  };

  submitForm() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      if (this.commentForm.valid) {
        if (this.postOwnerID) {
          const options = {
            content_type: 'comment',
            comment: this.commentForm.get('comment')?.value,
            content_id: this.data._id,
            profile_id: this.postOwnerID,
            commentedOn: 'POST'
          };
          if (this.checkWhitespaces(this.commentForm.get('comment')?.value)?.length) {
            this.postService
              .addComment(options)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.status == 'success') {
                  if (this.data.commentDetails) {
                    this.data.totalThreads++;
                    this.data.commentDetails.unshift({
                      ...res.data,
                      userInfo: [
                        {
                          email: this.profile.email,
                          profile_img_path: this.profile.avatar,
                          user_name: this.profile.user_name,
                          userType: this.profile.user_type,
                          _id: this.profile._id
                        }
                      ],
                      likeDetails: [],
                      replyCommentDetails: []
                    });
                  }
                } else {
                  this.snack.showMessage(res.message, true);
                }
              });
            this.commentForm.get('comment')?.setValue('');
          }
        }
      }
    }
  }

  searchByInterest(interest: any) {
    // interest = interest.toString();
    let categroy = [interest];
    this.postByInterest.emit(categroy);
  }

  sharePost() {
    if (this.authService.loggedIn()) {
      const dialogRef = this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px' : '100%',
        maxWidth: window.innerWidth > 600 ? '80vw' : '100vw',
        data: {
          link: this.commonService.location().origin + '/creators/post/' + this.post_id,
          content_id: this.post_id,
          shareType: shareTypeConst.post
        }
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {});
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  likePost() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      this.isLoadingLike = true;
      const isAlreadyLikedPost = this.data?.likeDetails?.find(
        (like: any) => like.user_id === this.profile?._id
      );
      const index = this.data?.likeDetails.findIndex((x: any) => x.user_id === this.profile._id);
      if (isAlreadyLikedPost?._id) {
        this.postService
          .unLikePost(isAlreadyLikedPost._id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.isLoadingLike = false;
            this.data?.likeDetails.splice(index, 1);
            this.isFavor = false;
            this.likeNumber--;
          });
      } else {
        const options = {
          content_id: this.post_id,
          content_type: 'Post',
          profile_id: this.postOwnerID
        };
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((post: any) => {
            this.isLoadingLike = false;
            if (post.status === 'success') {
              this.data?.likeDetails.push({
                user_id: post.data.user_id,
                _id: post.data._id
              });
            }
          });
        this.likeNumber++;
        this.isFavor = true;
      }
    }
  }

  likeComment(user: any) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      const options = {
        user_id: this.profile?._id,
        content_id: user._id,
        content_type: 'like'
      };
      if (this.isFavorComment(user.likeDetails)) {
        const id = user.likeDetails.find((like: any) => like.user_id === this.profile?._id)._id;
        if (id) {
          this.postService
            .unLikePost(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: any) => {
              if (res.status === 'Success') {
                const u = {
                  ...user,
                  likeDetails: user.likeDetails.filter(
                    (like: any) => like.user_id !== this.profile?._id
                  )
                };
                this.data = {
                  ...this.data,
                  commentDetails: this.data.commentDetails.map((c: any) => {
                    if (c._id === u._id) return u;
                    return c;
                  })
                };
                this.data = {
                  ...this.data,
                  contentDetails: this.data.contentDetails.map((content: any) => {
                    if (content._id === this.data._id) return this.data;
                    return content;
                  })
                };
              }
            });
        }
      } else {
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res.status === 'success') {
              this.data = {
                ...this.data,
                commentDetails: this.data.commentDetails.map((comment: any) => {
                  if (comment._id === user._id) {
                    return {
                      ...user,
                      likeDetails: [
                        ...user.likeDetails,
                        {
                          ...res.data,
                          userInfo: [
                            {
                              email: this.profile.email,
                              profile_img_path: this.profile.avatar,
                              user_name: this.profile.user_name,
                              userType: this.profile.user_type,
                              _id: this.profile._id
                            }
                          ]
                        }
                      ]
                    };
                  }
                  return comment;
                })
              };
            }
          });
      }
    }
  }

  // triggerFunction(event: any) {
  //   let extractComment = this.commentForm.get('comment')?.value.split(' ');
  //   let mentionUser = extractComment[extractComment.length - 1];
  //   if (
  //     mentionUser.includes('@') &&
  //     event.keyCode !== 40 &&
  //     event.keyCode !== 38
  //   ) {
  //     let searchUser = mentionUser.replace('@', '');
  //     let params = {
  //       keyword: searchUser,
  //     };
  //     this.postService
  //       .getMentionedCandidate(params)
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((res: any) => {
  //         // this.mentionUsers = res.data;
  //       });
  //   }
  //   if (event.ctrlKey && event.key === 'Enter') {
  //     let comment = this.commentForm.get('comment')?.value;
  //     comment += '\n';
  //     this.commentForm.get('comment')?.setValue(comment);
  //   } else if (event.key === 'Enter') {
  //     event.preventDefault();
  //     this.submitForm();
  //   }
  // }

  showComments() {
    if (this.isHomePost) {
      this.router.navigate([routesConst.community + '/' + 'post' + '/' + this.post_id]);
      return;
    }
    this.showComment = !this.showComment;
    var commentPanel = document.getElementById(this.post_id);
    if (commentPanel !== null) {
      commentPanel.style.marginLeft = this.showComment ? '0%' : '100%';
    }
  }

  showProfile() {
    this.router.navigate(['/profile' + '/' + this.postOwnerName]);
  }

  showPost() {
    if (this.isHomePost) {
      this.router.navigate([routesConst.community + '/' + 'post' + '/' + this.post_id]);
      return;
    }
  }

  isFavorComment(likes: any) {
    return likes?.findIndex((l: any) => l?.user_id === this.profile?._id) !== -1;
  }

  deletePost() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: {
        message: `Are you sure, You want to delete this post?`,
        header: 'Delete Post'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postService
          .deletePost(this.data._id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.snack.showMessage('Post deleted.', false);
            this.router.navigate([`${routesConst.community}`]);
          });
      }
    });
  }

  formatPostDate(previousDate?: any) {
    let currentDate = new Date().getTime();
    let jobDate = new Date(previousDate).getTime();
    let time = (currentDate - jobDate) / 1000;
    let days = Math.floor(time / (3600 * 24));
    let minutes = Math.floor(time / 60);
    let hours = Math.floor(time / (60 * 60));
    let weeks = Math.floor(days / 7);
    let months = Math.floor(days / 30);
    let years = Math.floor(days / 365);

    if (time < 60) {
      return Math.floor(time) + 's';
    } else if (minutes < 60) {
      return minutes + 'm';
    } else if (hours < 24) {
      return hours + 'h';
    } else if (days < 7) {
      return days + 'd';
    } else if (weeks < 4) {
      return weeks + 'w';
    } else if (months < 12) {
      return months + 'mo';
    } else {
      return years + 'y';
    }
  }

  getDescription() {
    const maxLength = (75 * document.body.clientWidth * (!this.isMobile ? 0.5 : 1)) / 370;
    this.isBigger = maxLength < this.postDescription.length;
    return this.isMore && maxLength < this.postDescription.length
      ? this.postDescription.substring(0, maxLength)
      : this.postDescription;
  }

  getFormattedDescription(): SafeHtml {
    // Substring the description to the desired maximum length
    const maxLength = this.isMobile ? 100 : (80 * document.body.clientWidth * 0.5) / 370;
    this.isBigger = maxLength < this.postDescription.length;
    const truncatedDescription =
      this.isMore && maxLength < this.postDescription.length
        ? this.postDescription.substring(0, maxLength)
        : this.postDescription;

    // Wrap the truncated description with spans for clickable usernames
    let formattedDescription = this.wrapStringWithTag(truncatedDescription);
    if (this.isMore && this.isBigger && !this.isHomePost) {
      formattedDescription += `<span class="blue ml-1 cursor-pointer more-btn">more...</span>`;
    } else if (!this.isMore && this.isBigger) {
      formattedDescription += `<span class="ml-1 blue cursor-pointer less-btn">less</span>`;
    }

    const sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(formattedDescription);

    document.addEventListener('click', this.handlePostDescriptionClick);
    return sanitizedHtml;
  }
  handleClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('more-btn')) {
      this.isMore = false;
    } else if (target.classList.contains('less-btn')) {
      this.isMore = true;
    }
  }

  // Define a reusable function to handle the click event
  handlePostDescriptionClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (target.classList.contains('post-description')) {
      const userNameSplit = target.innerText.split('@');
      const username = userNameSplit[1];

      if (target.innerText.includes('@')) {
        this.router.navigate(['/' + routesConst.profile + '/' + username]);
      } else {
        this.searchByInterest(target.innerText);
      }
    }
  };

  // getFormattedDescription(): SafeHtml {
  //   const formattedDescription = this.wrapStringWithTag(this.postDescription);
  //   const sanitizedHtml =
  //     this.sanitizer.bypassSecurityTrustHtml(formattedDescription);

  //   const spanElements = document.getElementsByClassName('post-description');
  //   for (let i = 0; i < spanElements.length; i++) {
  //     spanElements[i].addEventListener('click', (event: any) => {
  //       let userNameSplit = event.target.outerText.split('@');
  //       let username = userNameSplit[1];
  //       this.router.navigate(['/' + routesConst.profile + '/' + username]);
  //     });
  //   }
  //   return sanitizedHtml;
  // }

  copyLink() {
    this.clipboard.copy(
      this.commonService.location().origin + this.commonService.location().pathname
    );
    this.snack.showMessage(`Link Copied`, false);
  }

  editPost(postId: any) {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    let dialogRef = this.dialog.open(UploadPostModalComponent, {
      maxWidth: '100vw',
      width: '536px',
      disableClose: true,
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: { editmode: true, postId: postId }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(post => {
        if (post?._id) {
          const { _id, title, description, desclaimer, category, images } = post;
          this.postID = _id;
          this.postTitle = title;
          this.postDescription = description;
          this.postDesclaimer = desclaimer;
          this.postInterest = category;
          if (images?.length) {
            this.images = images;
          }
        }
      });
  }

  removeDeletedComment(commentId: any) {
    var indexToRemove = this.data.commentDetails.findIndex(
      (comment: any) => comment._id === commentId
    );
    // If the index is valid, remove the object from the array
    if (indexToRemove !== -1) {
      this.data.commentDetails.splice(indexToRemove, 1);
      this.data.totalThreads--;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  checkWhitespaces(text: any) {
    return text?.replace(/\s/g, '');
  }

  onPostViewLogged(event: any) {
    if (event.postId == this.post_id) {
      this.data.views += 1;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
