import { SideMenu } from 'src/app/core/models/model';

export const ContentTypes = [
  {
    id: 'trailer',
    label: '60s',
    text: 'Video',
    icon: '../../../../assets/images/60sec.png',
    duration: 60
  },
  {
    id: 'highlight',
    label: '30s',
    text: 'Video',
    icon: '../../../../assets/images/30sec.png',
    duration: 30
  },
  {
    id: 'clip',
    label: '15s',
    text: 'Video',
    icon: '../../../../assets/images/15sec.png',
    duration: 15
  },
  {
    id: 'moment',
    label: 'Photos',
    text: 'Photos',
    text1: 'Up to',
    icon: '../../../../assets/images/dice.png',
    duration: 0,
    photos: 4
  }
  // {
  //   id: 'all',
  //   label: 'All',
  //   duration: 60,
  // },
];

export const SortOption = [
  {
    id: 'Today',
    label: 'Today'
  },
  {
    id: '1 Month',
    label: '1 Month'
  },
  {
    id: '6 Month',
    label: '6 Month'
  },
  {
    id: 'Over 1 Year',
    label: 'Over 1 Year'
  }
];

export const sortBy = {
  label: 'Sort by: ',
  type: [
    {
      id: 'Today',
      label: 'Today'
    },
    {
      id: '1 Month',
      label: '1 Month'
    },
    {
      id: '6 Month',
      label: '6 Month'
    },
    {
      id: 'Over 1 Year',
      label: 'Over 1 Year'
    }
  ]
};

export const defaultSortByOption = {
  defaultSortBy: 'Over 1 Year'
};

export const JobType = [
  {
    id: 'Full time',
    name: 'Full Time'
  },
  {
    id: 'Part-time',
    name: 'Part Time'
  },
  {
    id: 'Contract',
    name: 'Contract'
  },
  {
    id: 'Temporary',
    name: 'Temporary'
  },
  {
    id: 'Remote',
    name: 'Remote'
  }
];

export const SideMenus: SideMenu[] = [
  {
    url: '/dashboard/account',
    name: 'Account',
    icon: 'person',
    selected: true,
    active: true
  },
  {
    url: '/dashboard/services',
    name: 'Services',
    icon: 'design_services',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/subscriptions',
    name: 'Subscriptions',
    icon: 'campaign',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/jobs',
    name: 'Jobs',
    icon: 'work',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/messages',
    name: 'Messages',
    icon: 'mark_chat_unread',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/referrals',
    name: 'Referrals',
    icon: 'group_work',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/payments',
    name: 'Payments',
    icon: 'account_balance_wallet',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/network',
    name: 'Network',
    icon: 'group',
    selected: false,
    active: true
  },
  // {
  //   url: '/dashboard/events',
  //   name: 'Events',
  //   icon: 'video_camera_front',
  //   selected: false,
  //   active: true,
  // },
  {
    url: '/dashboard/stats',
    name: 'Insights',
    icon: 'equalizer',
    selected: false,
    active: true
  }
];

export const AdminSideMenus: SideMenu[] = [
  {
    url: '/dashboard/account',
    name: 'Account',
    icon: 'person',
    selected: true,
    active: true
  },
  {
    url: '/dashboard/services',
    name: 'Services',
    icon: 'design_services',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/subscriptions',
    name: 'Memberships',
    icon: 'loyalty',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/jobs',
    name: 'Jobs',
    icon: 'work',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/messages',
    name: 'Messages',
    icon: 'mark_chat_unread',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/referrals',
    name: 'Affiliates',
    icon: 'group_work',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/payments',
    name: 'Payments',
    icon: 'account_balance_wallet',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/network',
    name: 'Network',
    icon: 'group',
    selected: false,
    active: true
  },
  {
    url: '/dashboard/users',
    name: 'Users',
    icon: 'people',
    selected: false,
    active: true
  },
  // {
  //   url: '/dashboard/events',
  //   name: 'Events',
  //   icon: 'video_camera_front',
  //   selected: false,
  //   active: true,
  // },
  {
    url: '/dashboard/stats',
    name: 'Stats',
    icon: 'equalizer',
    selected: false,
    active: true
  }
];

export const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const AchievementType = {
  Education: 'Education',
  WorkHistory: 'WorkHistory',
  ClientProject: 'ClientProject',
  Skill: 'Skill',
  ExpLevel: 'ExpLevel'
};
