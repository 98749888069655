import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { routesConst } from 'src/app/core/const/routers';
import { PostService } from 'src/app/core/services/post.service';

@Component({
  selector: 'app-most-viewed-posts',
  templateUrl: './most-viewed-posts.component.html',
  styleUrls: ['./most-viewed-posts.component.scss'],
})
export class MostViewedPostsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  posts = [];
  constructor(private postService: PostService, private router: Router) {}

  ngOnInit(): void {
    this.getPosts();
  }

  getPosts() {
    this.postService
      .getAllPost({ offset: 0, limit: 10, sortBy: 'views' })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'Success') {
          this.posts = res.data;
        }
      });
  }

  postDetail(post: any) {
    this.router.navigate([
      routesConst.community + '/' + 'post' + '/' + post._id,
    ]);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
