<div class="section-wrapper bg-white py-6 px-4 lg:px-10">
  <app-plan-upgrade></app-plan-upgrade>
  <p class="mt-7 black-light montserrat-bold uppercase">Login details</p>
  <p class="mt-2 black-light s">Set the details to log in to your account</p>
  <div class="mt-2">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <form class="flex flex-col w-full my-6" novalidate>
    <h4 class="montserrat-bold mb-2">Email</h4>
    <p class="user-email">{{user?.email}}</p>

    <h4 class="montserrat-bold mt-6 mb-2">Password</h4>
    <p class="s gray-light">Your password</p>
    <input type="password" class="user-email mt-2" [value]="user?.password" disabled />
    <a routerLink="{{'/' + routesConst.resetPassword}}" class="blue s montserrat-bold text-left ml-1 mt-2">Change
      password</a>
  </form>
</div>