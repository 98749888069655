import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-layout-mode',
  templateUrl: './layout-mode.component.html',
  styleUrls: ['./layout-mode.component.scss'],
})
export class LayoutModeComponent implements OnInit {
  @Input() value: string = 'grid_view';
  @Output() setValue: EventEmitter<any> = new EventEmitter();
  gridMode: string = 'grid_view';

  constructor() {}

  ngOnInit(): void {
    this.gridMode = this.value;
  }

  setOption(val: any) {
    this.gridMode = val;
    this.setValue.emit(val);
  }
}
