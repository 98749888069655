import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { notificationTypeConstant } from 'src/app/const/appConst';
import { SharedService } from 'src/app/shared/services/shared.service';
import { routesConst } from '../../const/routers';
import { JobService } from '../../services/job.service';
import { ProfileService } from '../../services/profile.service';
import { NetworkService } from '../../services/network.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  private unsubscribe$ = new Subject();
  messageNotifications: any = [];
  paymentNotifications: any = [];
  socialMediaNotifications: any = [];
  allnotification: any = [];
  clientUnreadMessages: any = [];
  networkUnreadMessages: any = [];
  recruiterUnreadMessages: any = [];
  following: any[] = [];
  currentLoggedInUserId: any;

  thisMonthNotifications: any = [];
  thisWeekNotifications: any = [];
  todayNotifications: any = [];

  thisMonthMessageNotifications: any = [];
  thisWeekMessageNotifications: any = [];
  todayMessageNotifications: any = [];

  thisMonthPaymentNotifications: any = [];
  thisWeekPaymentNotifications: any = [];
  todayPaymentNotifications: any = [];

  thisMonthSocialMediaNotifications: any = [];
  thisWeekSocialMediaNotifications: any = [];
  todaySocialMediaNotifications: any = [];

  constructor(
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<NotificationComponent>,
    private jobService: JobService,
    private profileService: ProfileService,
    private router: Router,
    private networkService: NetworkService
  ) { }

  ngOnInit(): void {
    this.userInfo();
    this.getAllNotifications();
    this.getFollowersAndFollowings();
  }

  followUser(event: any, userId: any, className: any) {
    if (userId) {
      this.networkService
        .followUser(userId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          const elements = document.querySelectorAll('.' + className);
          elements.forEach((element: any) => {
            element.style.display = 'none';
          });
          this.sharedService.getFollowersAndFollowings();
        });
    }
    event?.stopPropagation();
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network) => {
        if (network?.followings || network?.followers) {
          this.following = network.followings;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  checkIsFollow(userId: any) {
    return (
      this.following.some((f: any) => f?.user_id == userId) ||
      this.currentLoggedInUserId == userId
    );
  }

  notification(event: any) {
    event.preventDefault();
  }

  getAllNotifications() {
    this.getUnreadMessages();
    this.getNetworkNotifications();
  }

  getUnreadMessages() {
    const recruiterUnreadMessages$ =
      this.jobService.getRecruierUnreadMessages();
    const networkUnreadMessages$ = this.jobService.getNetworkUnreadMessages();
    const clientUnreadMessages$ = this.jobService.getClientUnreadMessages();
    forkJoin([
      recruiterUnreadMessages$,
      networkUnreadMessages$,
      clientUnreadMessages$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([res1, res2, res3]: any) => {
        this.recruiterUnreadMessages = JSON.parse(
          JSON.stringify(res1.unreadMessages)
        );
        this.networkUnreadMessages = JSON.parse(
          JSON.stringify(res2.unreadMessages)
        );
        this.clientUnreadMessages = JSON.parse(
          JSON.stringify(res3.unreadMessages)
        );
        this.messageNotifications = [
          ...this.recruiterUnreadMessages,
          ...this.networkUnreadMessages,
          ...this.clientUnreadMessages,
        ];
        this.messageNotifications.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to the beginning of the day

        const thisWeekStart = new Date();
        thisWeekStart.setDate(thisWeekStart.getDate() - 7);

        // const thisMonthStart = new Date();
        // thisMonthStart.setDate(thisMonthStart.getDate() - 30);

        const messageNotifications = this.messageNotifications;
        this.todayMessageNotifications = messageNotifications.filter(
          (notification: any) => {
            const createdAt = new Date(notification.created_at);
            return createdAt >= today;
          }
        );

        this.thisWeekMessageNotifications = messageNotifications.filter(
          (notification: any) => {
            const createdAt = new Date(notification.created_at);
            return (
              createdAt >= thisWeekStart &&
              !this.todayMessageNotifications.includes(notification)
            );
          }
        );

        this.thisMonthMessageNotifications = messageNotifications.filter(
          (notification: any) => {
            // const createdAt = new Date(notification.created_at);
            return (
              // createdAt >= thisMonthStart &&
              !this.todayMessageNotifications.includes(notification) &&
              !this.thisWeekMessageNotifications.includes(notification)
            );
          }
        );
      });
  }

  getNetworkNotifications() {
    this.profileService
      .getNetworkNotification()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.socialMediaNotifications = res.data.network;
        this.paymentNotifications = res.data.payment;
        this.allnotification = [
          ...this.messageNotifications,
          ...this.paymentNotifications,
          ...this.socialMediaNotifications,
        ];
        this.paymentNotifications.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        this.socialMediaNotifications.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        this.allnotification.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to the beginning of the day

        const thisWeekStart = new Date();
        thisWeekStart.setDate(thisWeekStart.getDate() - 7);

        // const thisMonthStart = new Date();
        // thisMonthStart.setDate(thisMonthStart.getDate() - 30);

        const socialMediaNotifications = this.socialMediaNotifications;
        this.todaySocialMediaNotifications = socialMediaNotifications.filter(
          (notification: any) => {
            const createdAt = new Date(notification.created_at);
            return createdAt >= today;
          }
        );

        this.thisWeekSocialMediaNotifications = socialMediaNotifications.filter(
          (notification: any) => {
            const createdAt = new Date(notification.created_at);
            return (
              createdAt >= thisWeekStart &&
              !this.todaySocialMediaNotifications.includes(notification)
            );
          }
        );
        this.thisMonthSocialMediaNotifications =
          socialMediaNotifications.filter((notification: any) => {
            // const createdAt = new Date(notification.created_at);
            return (
              // createdAt >= thisMonthStart &&
              !this.todaySocialMediaNotifications.includes(notification) &&
              !this.thisWeekSocialMediaNotifications.includes(notification)
            );
          });

        const paymentNotifications = this.paymentNotifications;
        this.todayPaymentNotifications = paymentNotifications.filter(
          (notification: any) => {
            const createdAt = new Date(notification.created_at);
            return createdAt >= today;
          }
        );

        this.thisWeekPaymentNotifications = paymentNotifications.filter(
          (notification: any) => {
            const createdAt = new Date(notification.created_at);
            return (
              createdAt >= thisWeekStart &&
              !this.todayPaymentNotifications.includes(notification)
            );
          }
        );

        this.thisMonthPaymentNotifications = paymentNotifications.filter(
          (notification: any) => {
            // const createdAt = new Date(notification.created_at);
            return (
              // createdAt >= thisMonthStart &&
              !this.todayPaymentNotifications.includes(notification) &&
              !this.thisWeekPaymentNotifications.includes(notification)
            );
          }
        );

        const allNotifications = this.allnotification;
        this.todayNotifications = allNotifications.filter(
          (notification: any) => {
            const createdAt = new Date(notification.created_at);
            return createdAt >= today;
          }
        );

        this.thisWeekNotifications = allNotifications.filter(
          (notification: any) => {
            const createdAt = new Date(notification.created_at);
            return (
              createdAt >= thisWeekStart &&
              !this.todayNotifications.includes(notification)
            );
          }
        );

        // this.thisMonthNotifications = allNotifications.filter(
        //   (notification: any) => {
        //     // const createdAt = new Date(notification.created_at);
        //     return (
        //       // createdAt >= thisMonthStart &&
        //       this.thisMonthNotifications = 
        //       !this.todayNotifications.includes(notification) &&
        //       !this.thisWeekNotifications.includes(notification)
        //     );
        //   }
        // );

        this.thisMonthNotifications = allNotifications.filter((notification: any) => {
          const createdAt = new Date(notification.created_at);
          const oneMonthAgo = new Date();
          oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

          return (
            createdAt >= oneMonthAgo &&
            !this.todayNotifications.includes(notification) &&
            !this.thisWeekNotifications.includes(notification)
          );
        });
      });
  }

  readMessage(notification: any) {
    this.close();
    this.router.navigate(
      ['/' + routesConst.dashboard + '/' + routesConst.messages],
      {
        queryParams: {
          tab:
            notification.type == 'recruiter'
              ? 1
              : notification.type == 'my-network'
                ? 2
                : 3,
          profile_id: notification.from_id,
        },
      }
    );
    this.refreshNotifications();
  }

  /**
   * Read Notification
   * @param notification
   */
  readNotification(notification: any) {
    this.close();
    if (notification.tab == 'message') {
      this.readMessage(notification);
    } else if (notification.tab == 'network') {
      if (notification.notification_type == 'comment') {
        const queryParams = { comment: notification.comment_id };

        this.router.navigate(
          [
            routesConst.community +
            '/' +
            'post' +
            '/' +
            notification.content_id,
          ],
          {
            queryParams: queryParams,
          }
        );
      }

      if (notification.notification_type == 'created-job') {
        const queryParams = { id: notification.content_id };
        this.router.navigate(['/' + routesConst.jobs], {
          queryParams: queryParams,
        });
      }

      if (notification.notification_type === 'applied-job') {
        const id = notification.content_id;
        const url = `${routesConst.jobs}?id=${id}`;
        this.router.navigateByUrl(url);
      }


      if (notification.notification_type == 'like') {
        this.router.navigate([
          routesConst.community + '/' + 'post' + '/' + notification.content_id,
        ]);
      }

      if (notification.notification_type == 'shared-post') {
        this.router.navigate([
          routesConst.community + '/' + 'post' + '/' + notification.content_id,
        ]);
      }

      if (notification.notification_type == 'shared-job') {
        const queryParams = { id: notification.content_id };
        this.router.navigate(['/' + routesConst.jobs], {
          queryParams: queryParams,
        });
      }

      if (notification.notification_type == 'shared-service') {
        this.router.navigate([
          '/' +
          routesConst.talents +
          '/' +
          routesConst.service +
          '/' +
          notification?.loginUser?.user_name +
          notification.content_id,
        ]);
      }

      if (notification.notification_type == 'follow') {
        this.router.navigate([
          '/' +
          routesConst.profile +
          '/' +
          notification?.user_details?.user_name,
        ]);
      }

      if (notification.notification_type == 'created-post') {
        this.router.navigate([
          routesConst.community + '/' + 'post' + '/' + notification.content_id,
        ]);
      }

      if (notification.notification_type == 'tagged-post') {
        this.router.navigate([
          routesConst.community + '/' + 'post' + '/' + notification.content_id,
        ]);
      }

      if (notification.notification_type == 'subscribe') {
        this.router.navigate([
          '/' +
          routesConst.profile +
          '/' +
          notification?.user_details?.user_name,
        ]);
      }

      if (notification.notification_type == 'purchase') {

        this.router.navigate([
          '/' +
          routesConst.talents +
          '/' +
          routesConst.service +
          '/' +
          notification?.loginUser?.user_name + '/' +
          notification.content_id,
        ]);
      }

      if (notification.notification_type == 'created-service') {
        this.router.navigate([
          '/' +
          routesConst.talents +
          '/' +
          routesConst.service +
          '/' +
          notification?.loginUser?.user_name +
          notification.content_id,
        ]);
      }
      this.markAsRead(notification);
    } else {
      if (notification.notification_type == 'refund-request') {
        const queryParams = { tab: 3 };
        this.router.navigate(
          ['/' + routesConst.dashboard + '/' + routesConst.services],
          {
            queryParams: queryParams,
          }
        );
      }
      if (
        notification.notification_type == 'refund-request-declined' ||
        notification.notification_type == 'refund-request-approved'
      ) {
        const queryParams = { tab: 2 };
        this.router.navigate(
          ['/' + routesConst.dashboard + '/' + routesConst.services],
          {
            queryParams: queryParams,
          }
        );
      }

      if (notification.notification_type == 'dispute') {
        const queryParams = { tab: 2 };
        this.router.navigate(
          ['/' + routesConst.dashboard + '/' + routesConst.services],
          {
            queryParams: queryParams,
          }
        );
      }
      // if (
      //   notification.notification_type == 'created-job' ||
      //   notification.notification_type == 'shared-job'
      // ) {
      //   const job = notification.url.split('?id=');
      //   this.router.navigate([job[0]], { queryParams: { id: job[1] } });
      // }
      // else if (
      //   notification.notification_type == 'created-service' ||
      //   notification.notification_type == 'shared-service'
      // ) {
      //   const service = notification.url.split('?id=');
      //   this.router.navigate([
      //     [service[0]] +
      //       '/' +
      //       notification?.loginUser?.user_name +
      //       '/' +
      //       service[1],
      //   ]);
      // }
      if (notification.notification_type == 'referral-payment') {
        this.router.navigate([notification?.url], { queryParams: { tab: 2 } });
      }
      // else {
      //   const commentId = notification?.comment_id;
      //   const queryParams = { comment: commentId };
      //   notification.notification_type == 'follow' ||
      //   notification.notification_type == 'subscribe' ||
      //   notification.notification_type == 'client-payment' ||
      //   notification.notification_type == 'subscriber-payment'
      //     ? this.router.navigate([
      //         notification.url + '/' + notification?.user_details?.user_name,
      //       ])
      //     : this.router.navigate([notification.url], {
      //         queryParams: queryParams,
      //       });
      // }
      if (notification.notification_type !== 'dispute') {
        this.markAsRead(notification);
      }
    }
    this.refreshNotifications();
  }

  close() {
    this.dialogRef.close();
  }

  formatJobDate(date?: any) {
    const now = moment();
    const duration = moment.duration(now.diff(date));

    if (duration.asWeeks() >= 1) {
      const weeks = Math.floor(duration.asWeeks());
      return weeks + 'w';
    } else if (duration.asDays() >= 1) {
      const days = Math.floor(duration.asDays());
      return days + 'd';
    } else if (duration.asHours() >= 1) {
      const hours = Math.floor(duration.asHours());
      return hours + 'h';
    } else {
      const minutes = Math.floor(duration.asMinutes());
      return minutes + (minutes === 1 ? ' m ' : ' s ');
    }
  }


  markAsRead(notification: any) {
    this.profileService
      .markAsReadNotification({ id: notification._id })
      .subscribe((res: any) => { });
  }

  refreshNotifications() {
    if (this.allnotification.length == 1) {
      this.sharedService.isUnreadNotification$.next(0);
      return;
    }
    this.sharedService.getUnreadNotification();
  }

  notificatiocIcon(type: any) {
    return notificationTypeConstant.filter((item) => item.label === type);
  }

  userInfo() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else {
          const { profileInfo } = information;
          this.currentLoggedInUserId = profileInfo._id;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
