import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { loginSignUpConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
@Component({
  selector: 'app-part-home',
  templateUrl: './part-home.component.html',
  styleUrls: ['./part-home.component.scss']
})
export class PartHomeComponent implements OnChanges {
  private unsubscribe$ = new Subject();
  loginSignUpConstant = loginSignUpConstant;
  @Input() data: any;
  video_url: any
  pause = false;
  @ViewChild('videoElement') videoElement: any;
  constructor(private profileService: ProfileService, public sanitizer: DomSanitizer, private router: Router, private authService: AuthService, private dialog: MatDialog) { }

  ngOnChanges(changes: SimpleChanges): void {    
    if (changes['data'].currentValue.video) {
      this.video_url = this.sanitizer.bypassSecurityTrustResourceUrl(changes['data'].currentValue.video.src)
    }
  }

  navigateTo(url: any, id?: any) {
    if (url) {
      this.router.navigate([url])
    } else {
      if (!this.authService.loggedIn()) {
        this.dialog.open(LoginSignupDialogComponent, {
          width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
          data: { pageToOpen: (id && id == 'onboarding') ? loginSignUpConstant.login : loginSignUpConstant.signup },
        });
      }
      else {
        if (id == 'onboarding') {
          const redirect_url = window.location.href
          const refresh_url = window.location.href
          this.profileService.stripeOnBoard(redirect_url, refresh_url, false).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            if (res.status == 'on_board_required') {
              if (res?.accountLink?.url) {
                window.location.href = res?.accountLink?.url;
              }
            }
          });
        }
        else {
          this.router.navigate(['/' + routesConst.dashboard + '/' + routesConst.referrals]);
        }
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
  toggleVideoPlayback() {
    if (this.videoElement.nativeElement.paused) {
      this.videoElement.nativeElement.play();
    } else {
      this.videoElement.nativeElement.pause();
    }
  }

  pauseIcon(){
    this.pause = false;
    setTimeout(() => {
      this.pause = true
    }, 4000);
  }



}