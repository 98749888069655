<div class="subscribe-modal bg-white" *ngIf="!showResult">
  <div class="flex w-full absolute justify-end pr-0.5">
    <mat-icon class="mr-4 mt-2.5 lg:mt-4 cursor-pointer hover:bg-red-200 rounded-full z-30" mat-dialog-close>cancel</mat-icon>
  </div>
  <div class="subscribe-modal_content flex flex-col lg:flex-row">
    <div class="flex flex-col">
      <div class="about-card border-normal rounded flex flex-col text-center lg:text-left lg:flex-row items-center">
        <img src="../../../../../assets/images/users/icon-live-1.svg" width="87">
        <div class="lg:ml-6 mt-6 lg:mt-0">
          <div class="flex items-center justify-center lg:justify-start">
            <h3>About</h3>
            <h3 class="montserrat-bold ml-2">kl.hairartist</h3>
          </div>
          <p class="mt-1 mb-3" style="max-width: 566px;">Stylist at Beautiful Hair Color Studio ·  Balayage + Color Specialist · Port Orange | Central Florida</p>
          <p class="montserrat-bold s">3.2M followers</p>
        </div>
      </div>
      <div class="flex w-full mt-4">
        <mat-radio-group aria-label="Select an option" class="flex w-full flex-col" [(ngModel)]="mode">
          <div class="border-normal rounded cursor-pointer w-full option-card"
            [class]="mode === 'pro' ? 'checked' : ''"
            (click)="setMode('pro')">
            <mat-radio-button value="pro" checked class="flex w-full items-center">
              <div class="flex w-full items-center">
                <h4 class="montserrat-bold">Pro</h4>
                <h4 class="montserrat-bold ml-auto">$3/</h4>
                <h4 class="">mo</h4>
              </div>
            </mat-radio-button>
            <div class="flex flex-col mt-5">
              <div class="flex items-center">
                <mat-icon class="blue">done</mat-icon>
                <p>Loru blandit cursus risus at ultrices mi tempus imperdiet nulla malesu.</p>
              </div>
              <div class="flex items-center mt-5">
                <mat-icon class="blue">done</mat-icon>
                <p>Loru blandit cursus risus at ultrices mi tempus imperdiet nulla malesu.</p>
              </div>
              <div class="flex items-center mt-5">
                <mat-icon class="blue">done</mat-icon>
                <p>Loru blandit cursus risus at ultrices mi tempus imperdiet nulla malesu.</p>
              </div>
              <div class="flex items-center mt-5">
                <mat-icon class="blue">done</mat-icon>
                <p>Loru blandit cursus risus at ultrices mi tempus imperdiet nulla malesu.</p>
              </div>
            </div>
          </div>
          <div class="border-normal rounded cursor-pointer mt-4 w-full option-card"
            [class]="mode === 'exclusive' ? 'checked' : ''"
            (click)="setMode('exclusive')">
            <mat-radio-button value="exclusive" class="flex w-full items-center">
              <div class="flex w-full items-center">
                <h4 class="montserrat-bold">Exclusive</h4>
                <h4 class="montserrat-bold ml-auto">$5/</h4>
                <h4 class="">mo</h4>
              </div>
            </mat-radio-button>
            <div class="flex flex-col mt-5">
              <div class="flex items-center">
                <mat-icon class="blue">done</mat-icon>
                <p>Loru blandit cursus risus at ultrices mi tempus imperdiet nulla malesu.</p>
              </div>
              <div class="flex items-center mt-5">
                <mat-icon class="blue">done</mat-icon>
                <p>Loru blandit cursus risus at ultrices mi tempus imperdiet nulla malesu.</p>
              </div>
              <div class="flex items-center mt-5">
                <mat-icon class="blue">done</mat-icon>
                <p>Loru blandit cursus risus at ultrices mi tempus imperdiet nulla malesu.</p>
              </div>
              <div class="flex items-center mt-5">
                <mat-icon class="blue">done</mat-icon>
                <p>Loru blandit cursus risus at ultrices mi tempus imperdiet nulla malesu.</p>
              </div>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
    <div class="lg:ml-4 w-full mb-10 lg:mb-0">
      <div class="w-full border-normal rounded flex flex-col justify-start items-start payment-detail">
        <h4 class="montserrat-bold">Payment details</h4>
        <mat-radio-button value="pro" checked class="flex w-full items-center border-normal rounded mt-4 p-4 checked">
          <div class="flex w-full items-center">
            <div class="flex flex-col">
              <p class="montserrat-bold">Mastercard *1234</p>
              <p class="s gray-dark">11/24</p>
            </div>
            <mat-icon class="blue ml-auto">edit</mat-icon>
          </div>
        </mat-radio-button>
        <div class="flex items-center w-full mt-6">
          <p class="montserrat-bold s">Subtotal</p>
          <p class="s ml-auto">${{mode === 'pro' ? '3' : '5'}}</p>
        </div>
        <div class="flex items-center w-full mt-4">
          <p class="montserrat-bold s">Total</p>
          <p class="s montserrat-bold ml-auto">${{mode === 'pro' ? '3' : '5'}}</p>
        </div>
        <p class="s gray-dark mt-4 mb-6">All prices are in USD and all sales are final. You will be charged $3.00 immediately. You will be charged every month thereafter while the subscription is active. Cancel any time.</p>
        <button mat-button class="button" (click)="subscribe()"><span class="white">Subscribe & Checkout</span></button>
      </div>
      <div class="flex items-center justify-center w-full mt-6">
        <mat-icon class="green flex items-center" style="height: 15px; width: 15px; font-size: 15px;">lock</mat-icon>
        <p class="s gray-dark ml-1.5">All your payments are encrypted</p>
      </div>
    </div>
  </div>
</div>
<div class=""></div>
<div class="subscribe-modal flex items-center bg-white" style="height: 100%;" *ngIf="showResult">
  <div class="success flex flex-col justify-center items-center text-center border-normal rounded">
    <mat-icon class="green" style="font-size: 100px; width: 100px; height: 100px;">check_circle</mat-icon>
    <h3 class="mt-4">You are subscribed to</h3>
    <h3 class="montserrat-bold">kl.hairartist</h3>
    <div class="flex flex-col lg:flex-row w-full items-center lg:justify-between mt-6">
      <button mat-button class="button normal" (click)="manageSubscribe()">
        <span class="blue">Manage membership</span>
      </button>
      <button mat-button class="button" (click)="showPostPage()">
        <span class="white">View posts</span>
      </button>
    </div>
  </div>
</div>
