import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { TalentService } from 'src/app/core/services/talent.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-dispute-modal',
  templateUrl: './dispute-modal.component.html',
  styleUrls: ['./dispute-modal.component.scss'],
})
export class DisputeModalComponent implements OnInit {
  private unsubscribe$ = new Subject();

  description: string = '';
  imageUrl: any = '';
  attachments:any[]=[];
  portfolios: any[] = [];

  constructor(
    private snack: SnackBarService,
    private dialogRef: MatDialogRef<DisputeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private talentService: TalentService
  ) {}

  ngOnInit(): void {}

  saveImageUrl(url: string, idx: number) {
    this.imageUrl = url;
    this.portfolios[idx] = {
      url,
      cover_type: idx === 0,
      media_type: 'image',
      order_by: idx,
    };
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input?.files) {
      for (let i = 0; i < input.files.length; i++) {
        this.attachments.push(input.files[i]?.name);
      }
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getArray(size: number) {
    let array = [0];
    for (let i = 1; i < size; i++) {
      array.push(i);
    }
    return array;
  }

  submit() {
    let disputePayload = {
      description: this.description,
      attachments: this.attachments,
      service_id: this.data.service._id,
      creator_role:
        this.data?.service?.serviceType == 'paid-service' ? 'Buyer' : 'Seller',
    };

    this.talentService
      .addDispute(disputePayload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.snack.showMessage(result.message, false);
        this.onClose();
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
