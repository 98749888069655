<div class="dashboard-services-services bg-white lg:p-10">
  <mat-horizontal-stepper labelPosition="bottom" #stepper>
    <mat-step>
      <div class="mx-0">
        <div class="flex justify-between items-center">
          <div>
            <!-- <p class="black-light montserrat-bold">My services</p> -->
            <p class="s black-light">Manage and create services</p>
          </div>
          <div>
            <button
              mat-button
              class="montserrat-medium mt-md button outlined black btn-size"
              (click)="addNewService()"
            >
              + Add
            </button>
          </div>
          <!-- <p
              class="s blue hover:underline mt-2 cursor-pointer"
              (click)="goToPreferenceTab()"
            >
              Don't forget to update {{userType === userTypeConstant.candidate ? "user" :'company'}} info
            </p> -->

          <!-- <div class="mt-4 md:mt-0">
            <button
              mat-button
              class="montserrat-medium mt-md button outlined black"
              (click)="addNewService()"
            >
              + Add
            </button>
            <button
              *ngIf="
                userType == userTypeConstant.candidate &&
                checkUserPlan(planTypeConstant.elite, planTypeConstant.pro)
              "
              [routerLink]="'/' + routesConst.planPricing"
              mat-button
              class="button blue upgrade-button"
            >
              Upgrade
            </button>
            <button
              *ngIf="
                userType == userTypeConstant.company &&
                checkUserPlan(
                  planTypeConstant.business,
                  planTypeConstant.enterprise
                )
              "
              [routerLink]="'/' + routesConst.planPricing"
              mat-button
              class="button blue upgrade-button"
            >
              Upgrade
            </button>
          </div> -->
        </div>
        <div
          class="scroll mt-2 md:mt-4"
          id="talentsContainer"
          infiniteScroll
          [infiniteScrollContainer]="'#talentsContainer'"
          [fromRoot]="true"
          [infiniteScrollThrottle]="50"
          [infiniteScrollDistance]="0.5"
          (scrolled)="onScrollDown()"
        >
          <div class="spinner-wrapper">
            <ngx-spinner
              bdOpacity="0.9"
              bdColor="black-dark"
              size="medium"
              color="red"
              type="ball-spin-clockwise"
              [fullScreen]="true"
              class="job-list-spinner"
            >
            </ngx-spinner>
          </div>
          <div class="grid sm:grid-cols-2 2xl:grid-cols-3 gap-2 my-2">
            <app-individual-service-card
              *ngFor="let service of myServices"
              [service]="service"
              (click)="showDetails(service, stepper)"
            ></app-individual-service-card>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <div class="mx-0 px-0">
        <button
          mat-button
          class="go-back button outlined black"
          matStepperPrevious
          matStepperPrev
        >
          <mat-icon class="blue arrow-back">keyboard_arrow_left</mat-icon>
          <!-- <span class="blue ml-2">Go back to the list</span> -->
        </button>

        <div class="mt-6 text-center items-center flex mb-4">
          <!-- <div class="flex"> -->

          <img
            class="avatar rounded-full mr-2"
            [src]="
              selectedService?.profile?.profile_img_path
                ? selectedService?.profile?.profile_img_path
                : 'https://i.postimg.cc/Hk7vyZZM/no-image.png'
            "
          />
          <p class="montserrat-bold mr-2 break-word username text-center">
            {{ selectedService?.profile?.user_name }}
          </p>
          <!-- </div> -->
        </div>
      </div>
      <div class="individual-service-page" *ngIf="selectedService">
        <!-- <div class="mt-10 px-6 lg:mt-12">
          <p class="individual-service-page_title montserrat-bold text-center mb-1">
            {{selectedService.title}}
          </p>
        </div> -->

        <div class="flex justify-center items-center mb-5">
          <!-- <img class="avatar rounded-full mr-4"
            [src]="selectedService?.profile?.profile_img_path ? selectedService?.profile?.profile_img_path :'https://i.postimg.cc/Hk7vyZZM/no-image.png'" />
          <p class="montserrat-bold mr-2 mx-w-40 truncate text-sm">
            {{ selectedService?.profile?.user_name }}
          </p> -->
          <!-- <div class="mt-10 px-6 lg:mt-12"> -->
          <p
            class="individual-service-page_title w-full montserrat-bold text-center mb-1"
          >
            {{ selectedService?.title }}
          </p>
          <!-- </div> -->
          <p *ngIf="selectedService?.profile?.pay_range?.length">
            · Starting at {{ selectedService.profile.pay_range[0].pay_range }}
          </p>
          <div class="action-wrapper ml-auto">
            <mat-icon
              class="blue cursor-pointer mr-3"
              (click)="editService(selectedService._id)"
            >
              edit
            </mat-icon>
            <mat-icon
              *ngIf="currentServiceData?.is_active"
              class="blue cursor-pointer"
              (click)="activeInactiveTalentService(false)"
            >
              delete
            </mat-icon>
            <p
              *ngIf="!currentServiceData?.is_active"
              class="blue whitespace-nowrap cursor-pointer"
              (click)="activeInactiveTalentService(true)"
            >
              Active Again
            </p>
          </div>
        </div>

        <div class="slider-wrapper" *ngIf="slides">
          <div
            id="service-img"
            class="slide-wrapper relative"
            (click)="prevService()"
          >
            <div
              *ngFor="let item of slides; let idx = index"
              class="w-full flex justify-center bsolute top-0 mx-auto lg:px-1 overflow-hidden"
              [class]="idx === selected ? 'block' : 'hidden'"
            >
              <img [src]="item.path" class="w-full" />
            </div>
            <div
              class="nav-btn-group flex absolute justify-between -mx-5"
              [class]="slides && slides.length > 1 ? 'block' : 'hidden'"
            >
              <button
                mat-icon-button
                class="button outlined black"
                (click)="prev($event)"
              >
                <mat-icon>arrow_back</mat-icon>
              </button>
              <button
                mat-icon-button
                class="button outlined black"
                (click)="next($event)"
              >
                <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div
          class="mb-20 md:px-6 mt-4"
          *ngIf="selectedService?.packages?.length"
        >
          <h3 class="montserrat-bold text-center mb-20">Pricing</h3>
          <div
            class="grid sm:grid-cols-2 2xl:grid-cols-3 dashboard-service-pricing gap-3"
          >
            <div
              class="pricing-wrapper"
              *ngFor="let item of selectedService.packages"
            >
              <app-pricing-package-card
                [isDashboardService]="isDashboardService"
                [pricingPackage]="item"
              >
              </app-pricing-package-card>
            </div>
          </div>
        </div>

        <div class="mb-20 px-6">
          <mat-divider></mat-divider>
        </div>

        <!-- REVIEWS SECTION START -->
        <!-- <app-customer-ratings
          [reviews]="reviews"
          [selectedService]="selectedService"
        ></app-customer-ratings> -->
        <!-- REVIEWS SECTION END -->

        <!-- <div *ngIf="reviews.length" class="mb-20 px-6">
          <mat-divider></mat-divider>
        </div> -->
        <div class="px-6">
          <h4 class="montserrat-bold text-center mb-20">
            Other services by {{ selectedService.profile.user_name }}
          </h4>
          <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div *ngFor="let service of myServices">
              <app-individual-service-card
                (click)="showDetails(service, stepper)"
                [service]="service"
              ></app-individual-service-card>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
