import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private dialog: MatDialog, private authService: AuthService, private router: Router) { }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    const isLoggedIn = this.authService.loggedIn();
    if (isLoggedIn) {
      return true;
    }

    this.router.navigate(['/']);
    this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px':'100vw',
      height: window.innerWidth > 600 ? 'h-fit':'100%',
      maxWidth: window.innerWidth > 600 ? '96%':'100vw',
      maxHeight: window.innerWidth > 600 ? '600':'100vh',
      data: { pageToOpen : "login" },
    });
    return false;
  }
}
