import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { JobService } from 'src/app/core/services/job.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SortOption } from 'src/app/core/const/options';
import { registerValues } from 'src/app/core/const/register';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';
import { MatDialog } from '@angular/material/dialog';
import { AllFiltersComponent } from 'src/app/shared/components/all-filters/all-filters.component';

@Component({
  selector: 'app-dynamic-tabs',
  templateUrl: './dynamic-tabs.component.html',
  styleUrls: ['./dynamic-tabs.component.scss'],
})
export class DynamicTabsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @ViewChild('locationSearchInput', { static: true })
  locationSearchInput?: ElementRef;
  @Input() isSearch: boolean = false;
  @Input() isShowAdvancedSearch: boolean = false;
  isShowAdvancedSearchOptions: boolean = true;
  form!: FormGroup;
  sortOption = SortOption;
  searchInput: string = '';
  locationFilters: string[] = [];
  salaryFilters: string[] = [];
  categoryFilters: string[] = [];
  sortByFilters!: string;
  jobTypeFilters: string[] = [];
  experienceTypeFilters: string[] = [];
  salaryRangeTypeFilter: string[] = [];
  selected = new FormControl(0);
  expandFilterPanelF = false;
  selectedCategoryFilter: any[] = [];
  selectedJobTypeFilter: any[] = [];
  selectedSalryTypeFilter: string[] = [];
  selectedJobExperienceFilter: any[] = [];
  selectedsortByFilter: any[] = [];
  selectedLocationFilter: string;
  keywordSearch: any;
  candidatePreferences: any;
  allCategories: any;
  allJobTypes: any;
  allExperienceLevel: any;
  allPayRange: any;
  selectedCategoryObject: any[] = [];
  selectedJobTypeObject: any[] = [];
  selectedExperienceLevelObject: any[] = [];
  selectedPayRangeObject: any[] = [];
  defaultFormValue: any;
  keywordToSearch: any;
  types: any;
  register = registerValues;

  @Input() set candidateJobPreferences(value: any) {
    if (value) {
      // setTimeout(() => {
      this.candidatePreferences = value;
      this.setCandidateJobPreferenceCategory(value);
      this.setCandidateJobPreferenceJobTypes(value);
      this.setCandidateJobPreferenceExperience(value);
      this.setCandidateJobPreferencePayRange(value);
      this.setCandidateLocationPreference(value);
      // }, 4000);
    }
  }

  @Output() jobcategoryfilter = new EventEmitter<any[] | null>();
  @Output() jobfileTypefilter = new EventEmitter<any[] | null>();
  @Output() locationTypeFilter = new EventEmitter<any | null>();
  @Output() searchInputValue = new EventEmitter<any | null>();
  @Output() dateFilter = new EventEmitter<any | null>();
  @Output() experienceTypeFilter = new EventEmitter<any | null>();
  @Output() sortByFilter = new EventEmitter<any | null>();
  @Output() clearAllFilters = new EventEmitter<any | null>();
  @Output() applyPreferences = new EventEmitter<any>();
  filteredOptions: any[] = [];
  myControl = new FormControl();
  @Input() companyName: any;
  isSearchByParam = false;

  config: SwiperOptions = {
    slidesPerView: 'auto',
    // breakpoints: {
    //   768: {
    //     slidesPerView: 'auto',
    //     // slidesPerGroup: 3,
    //   },
    // },
  };

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    public _JobService: JobService,
    private fb: FormBuilder,
    private _commonService: CommonService,
    public dialog: MatDialog
  ) {
    //  Remove query params on page refresh
    // this.router.navigate([], { queryParams: {} });
    //
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: any) => {
        if (params?.search?.length && router.navigated) {
          this.isSearchByParam = true;
          this.keywordToSearch = params.search;
          this.keywordSearch = params.search;
          this.form?.controls?.['searchInput']?.setValue(params.search);
        } else {
          this.isSearchByParam = false;
        }
      });
    this.selectedLocationFilter = '';
    this.defaultFormValue = {
      jobExperience: null,
      jobType: null,
      jobcategory: null,
      location: null,
      maxsalary: null,
      searchInput: null,
      sortBy: null,
    };
    this.form = this.fb.group({
      jobType: new FormControl(),
      location: new FormControl(),
      maxsalary: new FormControl(),
      jobcategory: new FormControl(),
      jobExperience: new FormControl(),
      sortBy: new FormControl(),
      searchInput: new FormControl(
        this.isSearchByParam ? this.keywordToSearch : ''
      ),
    });
  }

  ngOnInit() {
    this.filterLocation();
    this.getCategories();
    this.getPayRanges();
    this.getExperienceLevel();
    this.getJobTypes();
    this.onsortByChange();
    if (this.authService.loggedIn()) {
      this.getUserType();
    }
    this.isShowAdvancedSearchOptions = true;
  }
  getUserType() {
    this.sharedService.userType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((type: any) => {
        if (type === '') {
          this.sharedService.getUserType();
        } else {
          this.types = type;
        }
      });
  }

  filterLocation() {
    this._commonService
      ._filterLocation(this.locationSearchInput?.nativeElement)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.filteredOptions = result.data;
      });
  }

  getCategories() {
    this.sharedService.jobCategoryList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((jobcategory: any) => {
        if (jobcategory.length) {
          this.allCategories = jobcategory;
        } else {
          this.sharedService.categoriesList();
        }
      });
  }

  getPayRanges() {
    this.sharedService.payRangeList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((payrange: any) => {
        if (payrange.length) {
          this.allPayRange = payrange;
        } else {
          this.sharedService.getPayRangeList();
        }
      });
  }

  getExperienceLevel() {
    this.sharedService.experienceLevelList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((expLevel: any) => {
        if (expLevel.length) {
          this.allExperienceLevel = expLevel;
        } else {
          this.sharedService.getexperienceLevel();
        }
      });
  }

  getJobTypes() {
    this.sharedService.jobTypeList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((jobType: any) => {
        if (jobType.length) {
          this.allJobTypes = jobType;
        } else {
          this.sharedService.jobTypeList();
        }
      });
  }

  searchInputdata(event: any) {
    if (event.key === 'Enter' || event.type === 'click') {
      this.keywordSearch = this.form.controls['searchInput'].value;
      this.searchInputValue.emit(this.form.controls['searchInput'].value);
    }

    if (this.form.controls['searchInput'].value === '') {
      this.keywordSearch = this.form.controls['searchInput'].value;
      this.searchInputValue.emit(this.form.controls['searchInput'].value);
    }
  }

  /**
   * Set candidate Location Preference
   * @param option
   */
  setCandidateLocationPreference(option: any) {
    this.myControl.setValue('');
    if (option?.location?.length) {
      this.selectedLocationFilter =
        option.location[0]?.city_name +
        (option.location[0]?.state_name
          ? `- ${option.location[0]?.state_name} `
          : '') +
        (option.location[0]?.country_code
          ? `- ${option.location[0]?.country_code} `
          : '');
      this.locationFilters = option.location[0]._id;
      this.myControl.setValue(this.selectedLocationFilter);
    }
  }

  /**
   * Trigger While Category Filter value Selected
   */
  setCandidateJobPreferenceCategory(data: any) {
    this.form.controls['jobcategory'].setValue('');

    this.selectedCategoryFilter = [];
    this.categoryFilters = [];
    this.selectedCategoryObject = [];
    if (data?.categories?.length && this.allCategories?.length) {
      this.allCategories.forEach((type: any) => {
        data.categories.forEach((category: any) => {
          if (type.id == category) {
            this.selectedCategoryObject.push(type);
            this.selectedCategoryFilter.push(type.label);
            this.categoryFilters.push(type.id);
          }
        });
      });
      if (data?.categories?.length === this.selectedCategoryObject?.length) {
        this.form.controls['jobcategory'].setValue(this.selectedCategoryObject);
      }
    }
  }

  /**
   * Set Candidate Pay Range Preference
   * @param data
   */
  setCandidateJobPreferencePayRange(data: any) {
    this.form.controls['maxsalary'].setValue('');
    this.selectedSalryTypeFilter = [];
    this.salaryRangeTypeFilter = [];
    this.selectedPayRangeObject = [];
    if (data?.desired_salary?.length && this.allPayRange?.length) {
      this.allPayRange.forEach((type: any) => {
        data.desired_salary.forEach((category: any) => {
          if (type.id == category) {
            this.selectedPayRangeObject.push(type);
            this.selectedSalryTypeFilter.push(type.desired_salary);
            this.salaryRangeTypeFilter.push(type._id);
          }
        });
      });
      if (
        data?.desired_salary?.length === this.selectedPayRangeObject?.length
      ) {
        this.form.controls['maxsalary'].setValue(this.selectedPayRangeObject);
      }
    }
  }

  /**
   * Trigger While Job Types Filter value Selected
   */
  setCandidateJobPreferenceJobTypes(data: any) {
    this.form.controls['jobType'].setValue('');
    this.selectedJobTypeFilter = [];
    this.selectedJobTypeObject = [];
    this.jobTypeFilters = [];
    if (data?.job_type?.length && this.allJobTypes?.length) {
      this.allJobTypes.forEach((type: any) => {
        data.job_type.forEach((category: any) => {
          if (type.id == category) {
            this.selectedJobTypeObject.push(type);
            this.selectedJobTypeFilter.push(type.label);
            this.jobTypeFilters.push(type.id);
          }
        });
      });
      if (data?.job_type?.length === this.selectedJobTypeObject?.length) {
        this.form.controls['jobType'].setValue(this.selectedJobTypeObject);
      }
    }
  }

  /**
   * Set Candidate Experience Level
   * @param data
   */
  setCandidateJobPreferenceExperience(data: any) {
    this.form.controls['jobExperience'].setValue('');
    this.selectedJobExperienceFilter = [];
    this.experienceTypeFilters = [];
    this.selectedExperienceLevelObject = [];
    if (data?.experience_level?.length && this.allExperienceLevel?.length) {
      this.allExperienceLevel.forEach((type: any) => {
        data.experience_level.forEach((category: any) => {
          if (type.id == category) {
            this.selectedExperienceLevelObject.push(type);
            this.selectedJobExperienceFilter.push(type);
            this.experienceTypeFilters.push(type.id);
          }
        });
      });
      if (
        data?.experience_level?.length ===
        this.selectedExperienceLevelObject?.length
      ) {
        this.form.controls['jobExperience'].setValue(
          this.selectedExperienceLevelObject
        );
      }
    }
  }

  /**
   * onSortBy Chage
   */
  onsortByChange() {
    this.sortByFilters =
      this.form.value.sortBy?.length == 0 ? null : this.form.value.sortBy;
    this.sortByFilter.emit(this.sortByFilters);
    this.isShowAdvancedSearchOptions = false;
  }

  /**
   * Trigger While Category Filter value Selected
   */
  onCategoryFilterChange() {
    this.selectedCategoryFilter = [];
    this.categoryFilters = [];
    this.selectedCategoryObject = [];
    if (this.form?.value?.jobcategory?.length) {
      this.form.value.jobcategory.forEach((type: any) => {
        this.selectedCategoryObject.push(type);
        this.selectedCategoryFilter.push(type.label);
        this.categoryFilters.push(type.id);
      });
    }
    if (this.categoryFilters.length) {
      this.jobcategoryfilter.emit(this.categoryFilters);
    } else {
      this.jobcategoryfilter.emit(null);
    }
    this.isShowAdvancedSearchOptions = false;
  }

  /**
   * Trigger while Type Filter selected
   */
  onTypeFilterChange() {
    this.selectedJobTypeFilter = [];
    this.selectedJobTypeObject = [];
    this.jobTypeFilters = [];
    if (this.form?.value?.jobType?.length) {
      this.form.value.jobType.forEach((type: any) => {
        this.selectedJobTypeObject.push(type);
        this.selectedJobTypeFilter.push(type.label);
        this.jobTypeFilters.push(type.id);
      });
    }
    if (this.jobTypeFilters.length > 0) {
      this.jobfileTypefilter.emit(this.jobTypeFilters);
    } else {
      this.jobfileTypefilter.emit(null);
    }
    this.isShowAdvancedSearchOptions = false;
  }

  /**
   * Trigger While jobType Filter Selected
   */
  jobExperienceTypeFilterChange() {
    this.selectedJobExperienceFilter = [];
    this.experienceTypeFilters = [];
    this.selectedExperienceLevelObject = [];
    if (this.form?.value?.jobExperience?.length) {
      this.form.value.jobExperience.forEach((type: any) => {
        this.selectedExperienceLevelObject.push(type);
        this.selectedJobExperienceFilter.push(type.label);
        this.experienceTypeFilters.push(type.id);
      });
    }

    if (this.experienceTypeFilters.length) {
      this.experienceTypeFilter.emit(this.experienceTypeFilters);
    } else {
      this.experienceTypeFilter.emit(null);
    }
    this.isShowAdvancedSearchOptions = false;
  }

  /**
   * Trigger While Salary Change
   */
  onSalaryChange() {
    this.selectedSalryTypeFilter = [];
    this.salaryRangeTypeFilter = [];
    this.selectedPayRangeObject = [];
    if (this.form?.value?.maxsalary?.length) {
      this.form.value.maxsalary.forEach((type: any) => {
        this.selectedPayRangeObject.push(type);
        this.selectedSalryTypeFilter.push(type.label);
        this.salaryRangeTypeFilter.push(type.id);
      });
    }
    if (this.salaryRangeTypeFilter.length > 0) {
      this.dateFilter.emit({ salaryRange: this.salaryRangeTypeFilter });
    } else {
      this.dateFilter.emit({ salaryRange: null });
    }
    this.isShowAdvancedSearchOptions = false;
  }

  /**
   * Remove Selected Category or Job Type Filters
   * @param i
   * @param type
   */
  removefilter(i: number, type: string) {
    if (type === 'category') {
      this.selectedCategoryObject.splice(i, 1);
      this.form.controls['jobcategory'].setValue(this.selectedCategoryObject);
      this.onCategoryFilterChange();
    } else if (type === 'jobType') {
      this.selectedJobTypeObject.splice(i, 1);
      if (this.selectedJobTypeObject)
        this.form.controls['jobType'].setValue(this.selectedJobTypeObject);
      this.onTypeFilterChange();
    } else if (type === 'maxsalary') {
      this.selectedPayRangeObject.splice(i, 1);
      this.form.controls['maxsalary'].setValue(this.selectedPayRangeObject);
      this.onSalaryChange();
    } else if (type === 'experience') {
      this.selectedExperienceLevelObject.splice(i, 1);
      this.form.controls['jobExperience'].setValue(
        this.selectedExperienceLevelObject
      );
      this.jobExperienceTypeFilterChange();
    } else if (type === 'location') {
      this.selectedLocationFilter = '';
      this.myControl.setValue('');
      this.locationTypeFilter.emit(this.selectedLocationFilter);
    } else if (type === 'search') {
      this.keywordSearch = '';
      this.form.controls['searchInput'].setValue(null);
      this.searchInputValue.emit(this.form.controls['searchInput'].value);
    }
  }

  /**
   * Search job
   */
  search() {
    this.searchInputValue.emit(this.form.controls['searchInput'].value);
  }

  /**
   * Clear all Filters
   */
  clearAll() {
    this.selectedLocationFilter = '';
    this.myControl.setValue(null);
    this.keywordSearch = '';
    this.form.controls['searchInput'].setValue(null);
    this.form.controls['jobExperience'].setValue(null);
    this.form.controls['maxsalary'].setValue(null);
    this.form.controls['jobType'].setValue(null);
    this.form.controls['jobcategory'].setValue(null);
    this.companyName = '';
    this.form.reset(this.defaultFormValue);
    this.isShowAdvancedSearchOptions = false;
    this.clearAllFilters.emit();
    this.onsortByChange();
    this.router.navigate(['/jobs']);
  }

  onChange(event: any) {
    if (this.myControl.value === '') {
      if (event.key == 'Delete' || event.key == 'Backspace') {
        this.locationTypeFilter.emit(null);
        this.selectedLocationFilter = '';
      }
    }
  }

  /**
   * Set Selected Location
   * @param option
   */
  setLocation(location: any) {
    this.selectedLocationFilter =
      location?.city_name +
      (location?.state_name ? `- ${location?.state_name} ` : '') +
      (location?.country_code ? `- ${location?.country_code} ` : '');
    this.locationFilters = location._id;
    this.locationTypeFilter.emit(this.locationFilters);
    this.isShowAdvancedSearchOptions = false;
  }

  searchPreferences() {
    this.applyPreferences.emit();
  }

  filterModal() {
    const dialogRef = this.dialog.open(AllFiltersComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      data: {
        filterType: 'jobs',
        allCategories: this.allCategories,
        allJobTypes: this.allJobTypes,
        allExperienceLevel: this.allExperienceLevel,
        allPayRange: this.allPayRange,
        form: this.form,
        showPreferences: this.authService.loggedIn() && this.types === this.register.UserTypes.candidate ? 'Preferences':'',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filter: any) => {
        if (filter) {
          if (filter.searchPreferences === 'Preferences') {
            this.searchPreferences();
          }
          this.form?.controls?.['jobcategory']?.setValue(
            filter.selectedCategories
          );
          if (filter.selectedCategories) this.onCategoryFilterChange();
          this.form?.controls?.['sortBy']?.setValue(filter.selectedDatePosted);
          if (filter.selectedDatePosted) this.onsortByChange();
          this.form?.controls?.['jobExperience']?.setValue(
            filter.selectedExperienceLevels
          );
          if (filter.selectedExperienceLevels)
            this.jobExperienceTypeFilterChange();
          this.form?.controls?.['jobType']?.setValue(filter.selectedJobTypes);
          if (filter.selectedJobTypes) this.onTypeFilterChange();
          this.form?.controls?.['maxsalary']?.setValue(
            filter.selectedPayRanges
          );
          if (filter.selectedPayRanges) this.onSalaryChange();
        } else {
          this.clearAll();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
