import { Component, HostListener, OnInit } from '@angular/core';
import { routesConst } from 'src/app/core/const/routers';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {
  innerWidth = window.innerWidth;
  routesConst = routesConst;
  isAboutUsClose= false;
  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  };
  configButtons: SwiperOptions = {
    slidesPerView: 'auto'
  };
  selectedTab:string = 'About Us';
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }
  constructor() { }

  ngOnInit(): void {
  }
  scroll(element: any) {
    if (element !== '') {
      this.isAboutUsClose= false
      document.querySelector(element).scrollIntoView({ block: 'start', behavior:"smooth" });
    }
  }

}
