import { Component, Input, OnInit } from '@angular/core';
import { routesConst } from 'src/app/core/const/routers';
import { PartWorkFlow } from 'src/app/core/models/work-flow';
import { WorkFlowService } from 'src/app/shared/services/work-flow.service';

@Component({
  selector: 'app-part-offer',
  templateUrl: './part-offer.component.html',
  styleUrls: ['./part-offer.component.scss']
})
export class PartOfferComponent implements OnInit {
  routesConst = routesConst;

  partOffer = [
    {
      section: 'instantPay',
      // styling: {
      //   'max-width': '550px'
      // },
      
      descWidth: 'instant-pay-desc-width',
      imageSize: 'instant-pay-img',
      imagePosition: 'left',
      superHeading: 'HOW WE PAY',
      divider: true,
      mainHeading: 'Get paid in minutes with instant payouts',
      description: { content: 'Instant seller payouts, automated platform fees. Once services and or subscriptions are sold your money is transferred to your Stripe account.' },
      button: [{ text: 'Earn More', buttonLink: 'dashboard/payments' }],
      image: [{
        src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/LV2bU.png',
        redirectUrl: ''
      },
      // {
      //   src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros+%2B+stripe.png',
      //   redirectUrl: ''
      // }
    ]
    },

    // {
    //   romovepadding:'remove-paddding',
    //   imageSize:'img-max-height',
    //   section: 'weEmpowerYou',
    //   imagePosition: 'left',
    //   superHeading: 'WE EMPOWER YOU',
    //   divider: true,
    //   mainHeading: 'Unleash your social influence with JOBPros',
    //   description: {
    //     content: 'Stop giving your content and expertise for free. Move your community to JOBPros where you earn more.'
    //   },
    //   button: [{ text: 'Learn More', buttonLink: routesConst.features }],
    //   image: [{
    //     src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_41.png',
    //     redirectUrl: ''
    //   }]
    // }
  ];

  homeRangeSliderData = {
    style: true,
    superheading: 'Create & Earn Online',
    heading: 'How many clients make you better?',
    description: 'You get a platform to create, share and earn.',
    subheading: 'The cost of access to your experiences?',
    subdescription: 'Create paid content and expert services.',
    income: 'Monthly Income',
    getTotal: 1
  }

  public partWorkFlow: PartWorkFlow[] = [];
  constructor(private workFlowService: WorkFlowService) { }

  ngOnInit(): void {
    this.partWorkFlow = this.workFlowService.getPartWorkFlow();
  }
}