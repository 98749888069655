<div class="section-main-padding">
  <div class="section-2-make-it">
    <div class="section-main-grid">
      <!-- [class]='data?.class' -->
      <h1 class="title-heading md:mx-auto lg:mx-0 mb-5 montserrat-bold black-dark " >
        RE<span class="heading-text-color montserrat-bold black-dark" [class]="data?.bluetextStyle">{{data.blueheading}}</span>{{data.heading}} 
      </h1>
      <p class="title-content-section md:mx-auto lg:mx-0 content-max-width" [ngStyle]="data?.styling">
        {{data.description}}
      </p>
      <button class="mt-4 site-filled-btn mx-auto md:ml-0 block"
        (click)="navigateTo(data?.button?.buttonLink)">{{data.button.text}}</button>
    </div>
    <a class="cursor-pointer flex justify-center">
      <img [src]='data?.image?.src' alt="JobProsOffical-Main-image" />
    </a>
  </div>
</div>