import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { loginSignUpConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';

@Component({
  selector: 'app-sidenav-footer',
  templateUrl: './sidenav-footer.component.html',
  styleUrls: ['./sidenav-footer.component.scss']
})
export class SidenavFooterComponent implements OnInit {
  @Output() closeSideMenu: EventEmitter<string> = new EventEmitter<string>();

  loginSignUpConstant = loginSignUpConstant;
  @Input() isLoggedIn: boolean = false;
  @Input() opened: boolean = false;
  routesConst = routesConst;
  isdisabled: boolean = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openSignUp(pageToOpen: string) {
    this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px':'100vw',
      height: window.innerWidth > 600 ? 'h-fit':'100%',
      maxWidth: window.innerWidth > 600 ? '96%':'100vw',
      maxHeight: window.innerWidth > 600 ? '600':'100vh',
      data: { pageToOpen }
    });
  }

  closedMenu(){
    this.closeSideMenu.emit();
  }
}
