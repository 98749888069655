<div *ngIf="data?.userList" (click)="openTabCollapse()" class="
    message-contact-head
    flex
    w-full
    items-center
    px-4
    py-3.5
    cursor-pointer
  ">
  <img [src]="getProfileImage(data?.userList)" class="rounded-full" />
  <div class="ml-2 flex flex-row">
    <p class="montserrat-medium  mx-w-40 truncate" *ngIf="data?.userList?.user_name">{{ data?.userList?.user_name }}
    </p>
    <mat-icon class="ml-4" *ngIf="getUnreadCount(data.unread_messages)"
      [matBadge]="getUnreadCount(data.unread_messages)" matBadgeColor="warn">

      message</mat-icon>
  </div>
  <img class="block md:hidden ml-auto mr-2 expand-icon" src="../../../../assets/images/arrow-right.svg" />
</div>
