<div class="section-wrapper bg-white px-4 pb-4 pt-0 lg:p-10">
  <p  class="montserrat-bold text-black montserrat-bold">Interests</p>
  <p class="mt-2 black-light s">
    Select categories  that interest you  
  </p>
  <div class="mt-2">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div class="flex flex-wrap justify-center mx-auto mt-5 lg:mt-10">
    <div *ngFor="let item of skills" (click)="item.active = !item.active; saveSkill = true;">
      <p class="m-1 interest-tag" [class]="item.active ? 'filled' : ''">
        #{{ item.name }}
      </p>
    </div>
  </div>
  <div class="mt-2">
    <button mat-button class="button outlined black" [class]="!saveSkill ? 'disabled' : ''" (click)="updateSkills()">
      Save
    </button>
  </div>
</div>