import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { planTypeConstant, userTypeConstant } from 'src/app/const/appConst';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { JobService } from 'src/app/core/services/job.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-footer-pricing-plans',
  templateUrl: './footer-pricing-plans.component.html',
  styleUrls: ['./footer-pricing-plans.component.scss']
})
export class FooterPricingPlansComponent implements OnInit {

  private unsubscribe$ = new Subject();
  @Input() forIndividual: any;
  @Input() isHomePage: any;

  cardheights: any = {
    title: 'h-20',
    desc: 'h-40'
  }
  userType: any;
  elitePlanList: any = [];
  enterprisePlanList: any = [];
  addOns: any = [];

  membersConfig: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3
      }
    },
  };

  constructor(private authService: AuthService, private jobService: JobService, private spinner: NgxSpinnerService, private sharedService: SharedService) { }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.getUserType();

    }
    else {
      this.getPlateformPlansAndAddons();

    }
    // this.getPlateformPlansAndAddons();    
  }

  getPlateformPlansAndAddons() {
    this.jobService
      .getPlanList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {        
        if (this.isHomePage) {
          if (this.userType == userTypeConstant.candidate) {
            this.addOns = result.addOns?.filter(
              (plan: any) => plan.consumer_type === userTypeConstant.candidate
            )?.map((plan: any) => ({
              ...plan,
              isMore: true,
              isBigger: plan.description > 140,
            }));

          } else {
            this.addOns = result.addOns?.filter(
              (plan: any) => plan.consumer_type === userTypeConstant.company
            )?.map((plan: any) => ({
              ...plan,
              isMore: true,
              isBigger: plan.description > 140,
            }));

          }
        } else {
          this.elitePlanList = result.plans?.filter(
            (plan: any) => plan.plan_type === planTypeConstant.elite
          )?.map((plan: any) => ({
            ...plan,
            isMore: true,
            isBigger: plan.description > 140,
          }));
          this.enterprisePlanList = result.plans?.filter(
            (plan: any) => plan.plan_type === planTypeConstant.enterprise
          )?.map((plan: any) => ({
            ...plan,
            isMore: true,
            isBigger: plan.description > 140,
          }));
        }
      });

      
  }

  getUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === '') {
        this.sharedService.getUserType();
      } else {
        this.userType = type;
        this.forIndividual = this.userType == userTypeConstant.candidate;
        this.getPlateformPlansAndAddons();
      }
    });
  }

  isShowSpinner(show: any) {
    show ? this.spinner.show() : this.spinner.hide();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
