<form [formGroup]="uploadPost" class="upload-post-modal-form">
  <div class="upload-post-modal bg-normal relative flex flex-col justify-between">
    <div class="header w-full relative">
      <img
        *ngIf="!isLastStep"
        class="w-10 absolute top-2 left-3"
        src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png "
        alt=""
      />
      <button
        *ngIf="isLastStep"
        class="text-white absolute w-36 left-3 h-full flex items-center"
        (click)="goToPrev(stepper)"
      >
        <mat-icon class="text-white">arrow_back</mat-icon>
      </button>
      <p class="montserrat-bold py-6 text-center text-white items-center flex">
        {{
          post?.campaign_post
            ? 'Edit Campaign'
            : data.isCampaign
            ? 'Create Campaign'
            : 'Post Content'
        }}
      </p>
      <div class="absolute right-0 top-0 mt-1">
        <button mat-icon-button (click)="close()">
          <mat-icon class="text-white">close</mat-icon>
        </button>
      </div>
    </div>
    <div
      class="md:py-4 overflow-auto stepper-wrapper"
      [ngClass]="isLastStep ? 'next-wrapper' : 'stepper-main'"
    >
      <mat-horizontal-stepper labelPosition="bottom" #stepper>
        <mat-step>
          <div class="flex flex-col h-full">
            <div class="flex justify-between items-center">
              <p class="text-sm mb-2 ml-2 h-12 flex items-center montserrat-bold">
                <img
                  *ngIf="
                    contentTypes[selected?.value].id === 'clip' ||
                    contentTypes[selected?.value].id === 'trailer' ||
                    contentTypes[selected?.value].id === 'highlight'
                  "
                  class="h-12"
                  [src]="contentTypes[selected?.value].icon"
                />
                {{
                  contentTypes[selected?.value].id === 'clip' ||
                  contentTypes[selected?.value].id === 'trailer' ||
                  contentTypes[selected?.value].id === 'highlight'
                    ? contentTypes[selected?.value].text
                    : ''
                }}
                <span
                  *ngIf="contentTypes[selected?.value].id === 'moment'"
                  class="montserrat-bold ml-1"
                >
                  {{ contentTypes[selected?.value].text1 }}
                </span>
                <span
                  *ngIf="contentTypes[selected?.value].id === 'moment'"
                  class="blue moment-icon montserrat-bold mx-1"
                >
                  {{ contentTypes[selected?.value].photos }}
                </span>
                {{
                  contentTypes[selected?.value].id === 'moment'
                    ? contentTypes[selected?.value].text
                    : ''
                }}
              </p>
              <button
                mat-button
                class="montserrat-medium mt-md button black btn-size"
                (click)="uploadButtonClick()"
              >
                Upload
              </button>
            </div>
            <label class="image-main" [htmlFor]="'fileInput'">
              <div class="w-full h-full relative slide-wrapper">
                <swiper [config]="config" [pagination]="{ clickable: true }">
                  <ng-template
                    swiperSlide
                    *ngFor="let portfolio of getArray(steperSize); index as i"
                  >
                    <div (click)="imageIndex = i" class="relative w-full h-full">
                      <img
                        *ngIf="allImages[i] && allImages[i]?.media_type == 'moment'"
                        [src]="allImages[i]?.url"
                        class="h-full img-mx-height mx-auto"
                      />
                      <mat-icon
                        (click)="removeImage(i, $event)"
                        *ngIf="allImages[i] && allImages[i]?.media_type == 'moment'"
                        class="text-black absolute top-0 right-0 z-10"
                        >cancel</mat-icon
                      >
                      <div class="video-container" *ngIf="videoFileUploading">
                        <mat-progress-bar
                          *ngIf="uploadProgress > 0"
                          mode="determinate"
                          [value]="uploadProgress"
                        ></mat-progress-bar>
                        <div *ngIf="uploadProgress > 0" class="progress-percentage">
                          {{
                            uploadProgress >= 100
                              ? videoUploadedStatus
                              : uploadProgress.toFixed(0) + '%'
                          }}
                        </div>
                      </div>
                      <video
                        *ngIf="allImages[i] && allImages[i]?.media_type != 'moment'"
                        class="w-full h-full bg-black-dark"
                        [src]="allImages[i]?.url"
                        controls
                        playsinline
                        webkit-playsinline
                      ></video>
                      <mat-icon
                        (click)="removeImage(i, $event, 'video')"
                        *ngIf="allImages[i] && allImages[i]?.media_type != 'moment'"
                        class="text-black absolute top-0 right-0 z-10"
                        >cancel</mat-icon
                      >
                      <div
                        *ngIf="!allImages[i]"
                        class="relative flex items-center w-full h-full border border-dashed border-gray-400"
                      >
                        <img
                          src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/j7m3z.png"
                          class="mx-auto opacity-30 max-widthHeight object-scale-down"
                        />
                      </div>
                    </div>
                  </ng-template>
                </swiper>
              </div>
              <br />
            </label>
          </div>
        </mat-step>
        <mat-step>
          <div class="mt-4 lg:mt-0" [formGroup]="uploadForm">
            <textarea
              placeholder="Write caption"
              appMentionTrigger
              (itemSelected)="mentionSelect($event)"
              [mention]="mentionUsers"
              [mentionConfig]="mentionService.mentionConfig"
              [mentionListTemplate]="mentionListTemplate"
              maxlength="1025"
              class="w-full border-normal rounded px-4 py-3.5 mt-2 resize-none h-32"
              type="text"
              formControlName="description"
              (keyup)="triggerFunction($event)"
            ></textarea>
            <ng-template #mentionListTemplate let-item="item">
              <div class="flex items-center">
                <div class="rounded-full h-10 w-10 overflow-hidden">
                  <img
                    *ngIf="item.isShowImage"
                    class="rounded-full h-full w-full overflow-hidden"
                    onerror="this.src='assets/images/no-image.png'"
                    [src]="item.profile_img_path"
                    alt=""
                  />
                </div>
                &nbsp;
                <span class="ml-2">{{ item.user_name }}</span>
              </div>
            </ng-template>
            <mat-error
              *ngIf="errorHandling('description', 'maxlength')"
              [class]="errorHandling('description', 'maxlength') ? 'pb-4 pt-4' : ''"
            >
              <p class="s red">Description can not exceed 1024 characters!</p>
            </mat-error>
            <div class="flex flex-col gap-2">
              <p class="montserrat-bold">Categories</p>
            <div class="flex flex-col gap-2" *ngIf="
                contentTypes[selected?.value].id === 'clip' ||
                contentTypes[selected?.value].id === 'trailer' ||
                contentTypes[selected?.value].id === 'highlight' ||
                contentTypes[selected?.value].id === 'moment'">
                <mat-checkbox
                formControlName="featured"
                class="pt-1"
                [color]="'primary'"
                (change)="onCheckboxChange('FEATURED',$event)"
                [value]=""
              >
                <p>Featured</p>
              </mat-checkbox>
                <mat-checkbox
                formControlName="highlights"
                class="pt-1"
                [color]="'primary'"
                (change)="onCheckboxChange('HIGHLIGHTS', $event)"
                [value]=""
              >
                <p>Highlights</p>
              </mat-checkbox>
                <mat-checkbox
                formControlName="recommend"
                class="pt-1"
                [color]="'primary'"
                (change)="onCheckboxChange('RECOMMENDED', $event)"
                [value]=""
              >
                <p>Recommend</p>
              </mat-checkbox>
                <mat-checkbox
                formControlName="suggested"
                class="pt-1"
                [color]="'primary'"
                (change)="onCheckboxChange('SUGGESTED', $event)"
                [value]=""
              >
                <p>Suggested</p>
              </mat-checkbox>
            </div>
            </div>
            <div *ngIf="!post?.campaign_post" class="flex mt-4 flex-col pb-4">
              <div *ngIf="!data.isCampaign" class="flex disabled">
                <label class="flex mt-2">
                  <input
                    #isCampaign
                    (change)="sendAs($event)"
                    id="checkbox2"
                    type="checkbox"
                    class="checkbox1 hidden"
                  />
                  <label class="switchbox" for="checkbox2"></label>
                </label>
                <p class="montserrat-bold disabled ml-3.5">Send as subscription</p>
              </div>
              <div *ngIf="isCampaignPost || data?.isCampaign">
                <mat-radio-group aria-label="Select an option" class="flex flex-col mt-4">
                  <mat-radio-button
                    value="1"
                    [checked]="viewOption == 1 && !checkIfAllPlansSelected()"
                    class="text-center"
                    (click)="sendTo('All')"
                  >
                    <p class="ml-2">All subscribers</p>
                  </mat-radio-button>
                </mat-radio-group>
                <div class="scroll">
                  <div *ngIf="myPlans?.length" class="flex flex-col ml-8 mt-2 overflow-auto w-full">
                    <div class="flex flex-col mb-2" *ngFor="let plan of myPlans">
                      <b>{{ plan.title }}</b>
                      <mat-checkbox
                        formControlName="checkBoxControl"
                        *ngFor="let package of plan.packages"
                        [(ngModel)]="checkboxValues[package._id]"
                        [checked]="iewOption == 1"
                        class="pt-1"
                        [color]="'primary'"
                        (change)="change($event)"
                        [value]="package._id"
                      >
                        <p>{{ package.type | titlecase }}</p>
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div *ngIf="isProcessing" class="mt-10">
                <mat-progress-bar
                  mode="determinate"
                  [value]="progress$ | async"
                  style="background-color: red; --mat-progress-bar-fill-color: red;"
                ></mat-progress-bar>
                <p class="mt-2">{{ progress$ | async | number:'1.0-0' }}%</p>
              </div> -->
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="w-full sticky md:relative bg-white -bottom-6 md:bottom-0">
      <div class="px-4 mb-6" *ngIf="!isLastStep">
        <mat-tab-group
          (click)="resetImages()"
          class="post-tab"
          [selectedIndex]="selected.value"
          (selectedIndexChange)="selected.setValue($event)"
        >
          <mat-tab (click)="resetImages()" *ngFor="let type of contentTypes" [label]="type.label">
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="flex justify-between items-center px-6 h-16 border-2">
        <button mat-flat-button class="w-36 button warning black" (click)="close()">Cancel</button>
        <button
          *ngIf="!isLastStep"
          mat-button
          class="button outlined black w-36"
          [class]="allImages.length ? '' : 'disabled'"
          [disabled]="!allImages.length"
          (click)="goToNext(stepper)"
        >
          Next
        </button>
        <button
          *ngIf="isLastStep"
          mat-button
          class="button outlined black w-36"
          (click)="postContent()"
          [disabled]="
            savePost ||
            isProcessing ||
            !uploadForm.valid ||
            (!postImagesPath.length && !data.editmode)
          "
          [class]="
            savePost ||
            isProcessing ||
            !uploadForm.valid ||
            (!postImagesPath.length && !data.editmode)
              ? 'disabled'
              : ''
          "
        >
          {{ data?.isCampaign ? 'Send now' : 'Post' }}
        </button>
      </div>
    </div>
  </div>
</form>
<input
  id="fileInput"
  [accept]="
    selected.value === 3 ? 'image/*' : selected?.value < 3 && selected?.value >= 0 ? 'video/*' : ''
  "
  #file
  multiple
  type="file"
  class="uploadFile img w-0 h-0 overflow-hidden"
  value="Upload Media"
  (click)="clearFileInput(file)"
  (change)="uploadFile(file.files, imageIndex)"
  style="display: none"
/>
<ngx-spinner
  bdOpacity="0.9"
  bdColor="black-dark"
  size="medium"
  color="red"
  type="ball-spin-clockwise"
  [fullScreen]="true"
  class="job-list-spinner"
>
</ngx-spinner>
