<div [ngClass]="feedCardType === 'SERVICE' ? 'max-w-[630px] mx-auto' : ''">
<div class="talent-card mt-[8px] border rounded-md overflow-hidden relative">
  <div class="absolute cursor-pointer top-2 right-2 z-10">
    <button  (click)="showService()"
    class="w-20 flex h-fit justify-center px-2 py-1 font-bold border border-[#2887ed] rounded bg-[#2887ed] text-white hover:bg-white hover:text-[#2887ed] text-sm">
    Pay</button>
  </div>
  <div class="mx-0">
    <swiper [pagination]="talent.portfolios.length > 1">
      <ng-template swiperSlide *ngFor="let portfolio of talent.portfolios">
        <img
          masonryLazy
          [src]="portfolio.url"
          class="w-full talent-portfolio"
          (click)="showService()"
        />
      </ng-template>
    </swiper>
  </div>
  <div class="flex items-center relative text-center userInfo-card p-2">
    <!-- <div  class="relative avatar bg-contain bg-no-repeat bg-center"
              [ngStyle]="{ 'background-image': 'url(' + talent?.profile?.profile_img_path + ')' }"
              onerror="this.src='assets/images/no-image.png'"
            ></div> -->

    <img
      (click)="showProfile()"
      class="avatar object-cover rounded-full cursor-pointer"
      [src]="talent?.profile?.profile_img_path"
      onerror="this.src='assets/images/no-image.png'"
    />
    <div class="width-fitavailable pl-2">
      <div class="w-full flex justify-between">
        <!-- <div class="flex items-center mt-2 z-10" (click)="showProfile()">
        <p class="font-bold w-56 truncate text-lg hover:underline blue">
          {{ talent.profile.user_name }}
        </p> -->
        <!-- <mat-icon class="blue">verified</mat-icon> -->
        <!-- </div> -->
        <p class="font-bold w-full text-left truncate">{{ talent.title }}</p>
        <!-- <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" style="
          border: 1px solid #e5e5e5;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          min-width: 40px; -->
        <!-- "> -->
        <div class="ml-2">
          <mat-icon class="cursor-pointer three-dots" [matMenuTriggerFor]="menu"
            >more_vert</mat-icon
          >
        </div>
        <!-- </button> -->
        <mat-menu #menu="matMenu">
          <div class="flex h-12 items-center px-4">
            <!-- <mat-icon class="mr-4" [class]="isFollow ? '' : 'material-icons-outlined'" style="color: #2887ed">person_add
          </mat-icon> -->
            <app-follow-state
              [following]="followings"
              [style]="'cursor-pointer'"
              [target_user_id]="talent.profile._id"
            >
            </app-follow-state>
          </div>
          <button mat-menu-item (click)="showProfile()">
            <mat-icon style="color: #2887ed">person</mat-icon>
            <span>Profile</span>
          </button>
          <button mat-menu-item (click)="messageUser(talent)">
            <mat-icon style="color: #2887ed">chat</mat-icon>
            <span>Message</span>
          </button>
          <button mat-menu-item [matMenuTriggerFor]="exploreMenu">
            <mat-icon style="color: #2887ed; transform: rotate(-45deg)">send</mat-icon>
            <span>Share</span>
          </button>
          <mat-menu #exploreMenu="matMenu">
            <div class="grid grid-cols-3 gap-2 cursor-pointer">
              <div class="flex flex-col items-center">
                <button
                  class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
                  (click)="copyLink()"
                >
                  <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
                </button>
                <span class="text-xs">Copy</span>
              </div>
              <div class="flex flex-col items-center">
                <button
                  (click)="sharePost()"
                  class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2"
                >
                  <mat-icon>share</mat-icon>
                </button>
                <span class="text-xs">Share</span>
              </div>
              <div class="flex flex-col items-center">
                <button
                  class="w-10 h-10 flex items-center justify-center rounded-full border border-red-400 p-1"
                >
                  <mat-icon class="text-red-400">close</mat-icon>
                </button>
                <span class="text-xs text-red-400">Cancel</span>
              </div>
            </div>
          </mat-menu>
        </mat-menu>
      </div>

      <!-- <div class="flex items-center mt-2" *ngIf="talent?.profile?.location?.length">
      <mat-icon class="blue">room</mat-icon>
      <p class="s black-light">
        {{ talent.profile.location[0]?.city_name }} ·
        {{ talent.profile.location[0]?.state_name }} ·
        {{ talent.profile.location[0]?.country_code }}
      </p>
    </div> -->

      <div class="flex justify-between w-full mt-1">
        <p class="">{{ talent.skill_name }}</p>
        <p class="blue font-bold">${{ getLowestPrice(talent?.packages) }}</p>
        <!-- <button mat-button class="button" (click)="showService()">Details</button> -->
      </div>
    </div>
  </div>
</div>
<app-media-interaction-panel
*ngIf="isShowInteractionPanel"
  [profile]="profile"
  [data]="dataForInteractionPanel"
></app-media-interaction-panel>
</div>