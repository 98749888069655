import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlConst } from 'src/app/const/apiConst';
import { config } from 'src/config/config';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class TwitchService {
  constructor(private _http: HttpClient, private commonService:CommonService) { }

  /**
   * Get User's Previous Streams
   */
  getStreams(profile_id: any) {
    let params = new HttpParams();
    params = params.append('profile_id', profile_id);
    const options = { params: params };
    return this._http.get(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.getLiveStreams, options);
  }

  getStreamsList(filters: any) {
    let params = new HttpParams();
    params = params.append('offset', filters.offset);
    params = params.append('limit', filters.limit);
    const options = { params: params };
    return this._http.get(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.getLiveStreamsList, options);
  }
}