import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { statusConstant } from 'src/app/const/appConst';

@Component({
  selector: 'app-part-intro',
  templateUrl: './part-intro.component.html',
  styleUrls: ['./part-intro.component.scss']
})
export class PartIntroComponent implements OnInit {

  partIntro=[
  {
    headingStyle: {
      'max-width':'570px'
    },
    section:'whoWeSupport',
    imagePosition:'left',
    superHeading: 'WHO WE SUPPORT',
    divider: true,
    mainHeading: 'Creators, entrepreneurs, experts & job seekers',
    description: {content:'We support our diverse community of individuals and companies across the globe.'},
    button:[{text: 'Learn More',buttonLink:'/features'}],
    image: [{
      src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_38.png',
      redirectUrl: ''
    }]
  }
];
  statusConstant = statusConstant;
  private unsubscribe$ = new Subject();
  expertsIcon = '../../../../../assets/images/person-search.svg'
  eventsIcon = '../../../../../assets/images/event.svg'
  routesConst = routesConst;
  isShowMemberFeatures = false;
  isOnBoardProcessing = false;
  onBoardStatus: string = '';
  constructor(private router: Router, private dialog: MatDialog, private authService: AuthService, private sharedService: SharedService, public auth: AuthService, private profile: ProfileService, private spinner: NgxSpinnerService) {
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.unsubscribe$)).subscribe((event: any) => {
    //   if (event?.url.includes(routesConst.features)) {
    //     this.isShowMemberFeatures = true;
    //   }
    // })
  }

  ngOnInit(): void {
    // if (this.authService.loggedIn()) {
    //   this.getOnBoardStatus();
    // }
  }

  // getOnBoardStatus() {
  //   this.sharedService.onBoardStatus$.pipe(takeUntil(this.unsubscribe$)).subscribe((status => {
  //     if (status === '') {
  //       this.sharedService.getOnBoardStatus();
  //     } else {
  //       this.onBoardStatus = status;
  //     }
  //   }));
  // }

  // onBoard() {
  //   this.spinner.show();
  //   this.isOnBoardProcessing = true;
  //   this.profile.stripeOnBoard(window.location.href).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
  //     this.spinner.hide();
  //     this.isOnBoardProcessing = false;
  //     if (res.status == 'on_board_required') {
  //       if (res?.accountLink?.url) {
  //         window.location.href = res?.accountLink?.url;
  //       }
  //     }
  //   });
  // }

  // showVideo(url: string) {
  //   if (url) {
  //     this.dialog.open(VideoPreviewModalComponent, {
  //       maxWidth: '100vw',
  //       data: {
  //         url,
  //       }
  //     })
  //   }
  // }

  // createCampaign() {
  //   if (this.authService.loggedIn()) {
  //     this.router.navigate(['/' + routesConst.campaign]);
  //   } else {
  //     this.dialog.open(LoginSignupDialogComponent, {
  //       width: '400px',
  //       height: 'h-fit',
  //       maxHeight: '600',
  //       data: { pageToOpen: loginSignUpConstant.login }
  //     });
  //   }
  // }

  // navigateTo(url: string) {
  //   if (url == this.router.url) {
  //     this.router.navigateByUrl('/', { skipLocationChange: true })
  //       .then(() => this.router.navigate([url]));
  //   }
  //   else {
  //     this.router.navigate(['/' + url])
  //   }
  // }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
