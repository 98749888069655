<div>
  <!-- <p class="black-light mt-8 lg:mt-16 lg:ml-6 mb-12 ml-4 lg:p-0 lg:mb-10 montserrat-bold uppercase service-header" >Posts</p> -->
  <p class="black-light montserrat-bold uppercase mt-3 text-center" *ngIf="!myPosts?.length">Not Available</p>
  <div class="lg:mt-1" *ngIf="myPosts?.length > 0">
    <app-masonry-panel
  [data]="myPosts"
  [searchTypeVal]="''"
  [layoutMode]="'grid_view'"
  class="w-full"
></app-masonry-panel>
  </div>
</div>
