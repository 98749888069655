import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { JobService } from 'src/app/core/services/job.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit {
  private unsubscribe$=new Subject();
  availablePreferencs: any;
  form: any;

  @Input() userData: any;

  @Input() set preferences(value: any) {
    if (value) {
      this.availablePreferencs = value;
      this.setPreferencesValue(value);
    }
  }

  constructor(
    public authService: AuthService,
    private _jobService: JobService,
    private snack: SnackBarService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      required_bio: new FormControl(Validators.required),
      required_portfolio: new FormControl(),
      required_experience: new FormControl(),
      required_pitch: new FormControl(),
    });
  }

  /**
   * Set Company Preferences Value
   * @param preference
   */
  setPreferencesValue(preference: any) {
    this.form.controls['required_bio'].setValue(preference.required_bio);
    this.form.controls['required_portfolio'].setValue(
      preference.required_portfolio
    );
    this.form.controls['required_experience'].setValue(
      preference.required_experience
    );
    this.form.controls['required_pitch'].setValue(preference.required_pitch);
  }

  /**
   * Update Preferences
   */
  updatePreferences(): void {
    const updatePreferencesParams = {
      _id: this.availablePreferencs?._id ? this.availablePreferencs._id : null,
      required_bio: this.form.controls['required_bio'].value,
      required_portfolio: this.form.controls['required_portfolio'].value,
      required_experience: this.form.controls['required_experience'].value,
      required_pitch: this.form.controls['required_pitch'].value,
      company_id: this.userData.profile._id,
    };
    this._jobService.updatePreferences(updatePreferencesParams).pipe(takeUntil(this.unsubscribe$)).subscribe(
      () => {
        this.snack.showMessage('Preferences Updated',false);
      },
      () => {
        this.snack.showMessage('Failed to Update Preferences',true);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
}
}
