import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { routesConst } from 'src/app/core/const/routers';
import { filter, takeUntil } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { loginSignUpConstant, userTypeConstant } from 'src/app/const/appConst';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent implements OnInit {
  userTypeConstant = userTypeConstant;
  private unsubscribe$ = new Subject();
  @Input() navItems!: any[];
  @Input() isUnread: any;
  @Input() isLoggedIn: any;
  @Input() userType: User['userType'] | undefined | string;
  @Output() closeSidebar: EventEmitter<any> = new EventEmitter();
  @Output() postJob: EventEmitter<any> = new EventEmitter();
  @Output() uploadPost: EventEmitter<any> = new EventEmitter();
  selectedRoute: string = '';
  username: any;

  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    public router: Router,
    private sharedService: SharedService
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: any) => {
        this.selectedRoute = event.url;
      });
  }

  subMode: string = '';
  routesConst = routesConst;

  ngOnInit(): void {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information) => {
        if (this.authService.loggedIn()) {
          if (information === null) {
            this.sharedService.getUserInfo();
          } else {
            const { profileInfo } = information;
            this.username = profileInfo.user_name;
          }
        }
      });
  }

  close() {
    this.closeSidebar.emit();
  }

  goToNext(stepper: MatStepper, mode: string) {
    this.subMode = mode;
    stepper.next();
  }

  openPostJobModal() {
    this.postJob.emit();
    this.closeSidebar.emit();
  }

  postContent() {
    this.uploadPost.emit();
  }

  openSignUp(pageToOpen: string) {
    this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px':'100vw',
      height: window.innerWidth > 600 ? 'h-fit':'100%',
      maxWidth: window.innerWidth > 600 ? '96%':'100vw',
      maxHeight: window.innerWidth > 600 ? '600':'100vh',
      data: { pageToOpen },
    });
  }

  createCampaign() {
    if (this.authService.loggedIn()) {
      this.router.navigate(['/' + routesConst.campaign]);
    } else {
      this.openSignUp(loginSignUpConstant.login);
    }
  }

  navigateTo(url: string) {
    if (url == this.router.url) {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate([url]));
    } else {
      this.router.navigate(['/' + url]);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
