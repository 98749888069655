<div class="flex items-center cursor-pointer" (click)="followUser()">
  <mat-icon
    *ngIf="
      !isFollow &&
      (toDisplay == toDisplayConstant.icon_text ||
        toDisplay == toDisplayConstant.icon) &&
      !showButton
    "
    class="mr-4"
    style="color: #2887ed"
    >person_add</mat-icon
  >
  <img
    *ngIf="
      isFollow &&
      (toDisplay == toDisplayConstant.icon_text ||
        toDisplay == toDisplayConstant.icon) &&
      !showButton
    "
    class="mr-4 h-6"
    src="../../../../../../../assets/images/followed.png"
  />
  <p
    *ngIf="
      (toDisplay == toDisplayConstant.icon_text ||
        toDisplay == toDisplayConstant.text) &&
      !showButton
    "
    [class]="style"
  >
    {{ isFollow ? "Unfollow" : "Follow" }}
  </p>

  <button *ngIf="showButton" class="follow-button flex align-center justify-center ">
    <!-- <mat-icon *ngIf="!isFollow" class="mr-1">add</mat-icon> -->
    {{ isFollow ? "Unfollow" : "Follow" }}
  </button>
</div>
