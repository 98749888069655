import { Component, OnInit } from '@angular/core';
// import { ChartOptions } from '@app-core/models/model';

@Component({
  selector: 'app-dashboard-users-followers',
  templateUrl: './dashboard-users-followers.component.html',
  styleUrls: ['./dashboard-users-followers.component.scss']
})
export class DashboardUsersFollowersComponent implements OnInit {
  // public chartOptions: ChartOptions;

  followerRangeArr = [
    { key: 'thisWeek', value: 'This week' },
    { key: 'thisMonth', value: 'This month' },
    { key: 'thisYear', value: 'This year' },
    { key: 'lastYear', value: 'Last year' },
  ]
  followerRange: any

  constructor() {
    this.followerRange = 'thisYear'
    // this.chartOptions = {
    //   series: [
    //     {
    //       name: "Follower",
    //       data: [7000, 2000, 7500, 14000, 12000, 16000, 10000, 4900, 9000, 7500, 9000, 2500]
    //     },
    //     {
    //       name: "Pro",
    //       data: [6700, 5000, 7000, 8000, 7500, 9000, 7000, 5000, 7000, 5000, 6200, 2500]
    //     },
    //     {
    //       name: "Elite",
    //       data: [5100, 4000, 5100, 7500, 6000, 8000, 6000, 5000, 6000, 3000, 6000, 2500]
    //     }
    //   ],
    //   chart: {
    //     height: 350,
    //     type: "line",
    //     dropShadow: {
    //       enabled: true,
    //       color: "#000",
    //       top: 18,
    //       left: 7,
    //       blur: 10,
    //       opacity: 0.2
    //     },
    //     toolbar: {
    //       show: false
    //     }
    //   },
    //   colors: ["#2887ED", "#E63946"],
    //   dataLabels: {
    //     enabled: false
    //   },
    //   stroke: {
    //     curve: "smooth"
    //   },
    //   title: {
    //     text: "",
    //     align: "right"
    //   },
    //   grid: {
    //     borderColor: "#E5E5E5",
    //     row: {
    //       colors: ["#FFFFFF", "transparent"], // takes an array which will be repeated on columns
    //       opacity: 0.5
    //     }
    //   },
    //   markers: {
    //     size: 3
    //   },
    //   xaxis: {
    //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nob", "Dec"],
    //     title: {
    //     }
    //   },
    //   yaxis: {
    //     title: {
    //       text: ""
    //     },
    //     min: 0,
    //     max: 20000
    //   },
    //   legend: {
    //     position: "top",
    //     horizontalAlign: "right",
    //     floating: true,
    //     offsetY: 0,
    //     offsetX: 0
    //   }
    // };
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const el = document.getElementById('followerChart')
    if (el) {
      // const  w = el.clientWidth
      // this.chart.width = w
    }
  }
}
