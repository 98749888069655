<div
  class="part-footer-main-wrapper"
  [ngClass]="currentRoute === 'earn' || currentRoute === '' ? 'mt-0' : 'mt-0'"
>
  <!-- <div class="py-4"></div>
  <div class="section-main experience-more" *ngIf="currentRoute !== ''">
    <div class="section-2-make">
      <div>
        <p class="how-subscribe">STAY CONNECTED</p>
        <h1 class="titles-heading montserrat-bold black-dark text-3xl">
          JOIN OUR COMMUNITY
          Ready to change the status quo on 
          <span class="span-pros"><b>JOB</b>Pros?</span>
        </h1>
        <p class="mt-2 mb-4 list-text">
          Get exclusive updates and share your network to earn 10% cash rewards
          monthly.
        </p>
        <div class="flex justify-center items-center mt-2">
          <form
            class="w-full flex items-center subcriber-form relative"
            [formGroup]="subscribeForm"
            (ngSubmit)="subscribe()"
            novalidate
          >
            <input
              class="rounded w-full"
              type="text"
              placeholder="Your Email"
              formControlName="email"
            />
            <button type="submit" class="subscribe-button absolute">
              <mat-icon>trending_flat</mat-icon>
            </button>
          </form>
        </div> -->
        <!-- <p class="experiance-more">EXPERIENCE MORE</p>
        <h3 class="main-heading">Get more of what you deserve</h3>
        <p class="section-title-divider"></p> -->
        <!-- <div class="grid md:grid-cols-2 gap-4"> -->
        <!-- <p class="flex my-6 list-text">
              <mat-icon class="check-icons">check</mat-icon>No hidden fees ever
            </p>
            <p class="flex mb-6 list-text">
              <mat-icon class="check-icons">check</mat-icon>No maximum seller
              prices
            </p> -->
        <!-- <div class="img-wrapper">
                <img
                  class="h-9"
                  src="../../../assets/images/home/logo-explore-job.png"
                  alt=""
                />
                <div>
                  <h2>COMMUNITY NETWORK</h2>
                  <p>Industry Experts, Creators & Companies</p>
                </div>
              </div>
              <div class="img-wrapper">
                <img
                  class="h-9"
                  src="../../../assets/images/home/logo-monetize-social-network.png"
                  alt=""
                />
                <div>
                  <h2>MONETIZE EVERYWHERE</h2>
                  <p>Sell Content & Expert Services</p>
                </div>
              </div> -->
        <!-- <p class="flex mb-6 md:my-6 list-text">
              <mat-icon class="check-icons">check</mat-icon>Own 100% of your
              content
            </p>
            <p class="flex mb-6 list-text">
              <mat-icon class="check-icons">check</mat-icon>10% referral revenue
              share
            </p> -->
        <!-- <div class="img-wrapper">
              <img
                class="h-9"
                src="../../../assets/images/home/logo-create-passive-income.png"
                alt=""
              />
              <div>
                <h2>FASTEST PAYOUTS</h2>
                <p>Sellers Paid In Minutes</p>
              </div>
            </div>
            <div class="img-wrapper">
              <img
                class="h-9"
                src="../../../assets/images/home/logo-monetize-service.png"
                alt=""
              />
              <div>
                <h2>BEST REFERRAL BONUSES</h2>
                <p>10% Bonus On Every Monthly Referral</p>
              </div>
            </div>
        </div> -->
        <!-- <p class="flex mb-6"><mat-icon class="check-icons">check</mat-icon>Top multi creator platform</p> -->
      <!-- </div> -->
      <!-- <div class="h-full flex justify-center my-4 lg:my-0">
      <div class="flex flex-col mx-auto">
        <h3 class="flex justify-center text-center members-earn-title">Create your account</h3>
        <h3 class="flex justify-center text-center members-earn-title">in minutes</h3>
        <div class="footer-started-btn">
          <button class="mt-4 main-started-btn title-get-started-btn">Get Started</button>
        </div>
      </div>
    </div> -->
      <!-- <div class="flex flex-col justify-end items-center top-padding"> -->
        <!-- <div>
          <p class="flex my-2 list-text text-center">Get invite only access to updates and member features.
          </p>
        </div> -->

        <!-- <button
          class="main-started-btn site-filled-btn"
          (click)="openSignUp(loginSignUpConstant.signup)"
        >
          Try 3 Months Free
        </button>
      </div>
    </div>
  </div> -->
  <!-- <ng-container *ngIf="faqData "></ng-container> -->

  <!-- <div id="aboutUsFooter" class="background-section" *ngIf="data !== 'sidenavbar'">
    <div class="sections-main pt-8">
      <div class="content-wrapper">
        <div class="section-2-main">
          <div class="flex flex-col w-full mx-auto">
            <p class="join-heading">
              Lets grow together join the fastest growing community
            </p>
            <p class="section-title-divider"></p>
            <p class="description">
              Our platform offers innovative solutions to  <br/>
            help you to earn referrals, clients & income.                            
            </p>
          </div>
  
          <div *ngIf="innerWidth < 768" class="images-main">
            <swiper [pagination]="{ clickable: true }" [config]="config">
              <ng-template swiperSlide>
                <div class="images">
                  <video
                    class="video"
                    controls
                    playsinline
                    webkit-playsinline
                    loop
                    [muted]="'muted'"
                    autoplay
                    src="assets/static/home/videos/promo.mp4"
                  ></video>
                </div>
              </ng-template>
  
              <ng-template swiperSlide>
                <div class="images">
                  <video
                    class="video"
                    controls
                    playsinline
                    webkit-playsinline
                    loop
                    [muted]="'muted'"
                    autoplay
                    src="assets/static/home/videos/community1.mp4"
                  ></video>
                </div>
              </ng-template>
  
              <ng-template swiperSlide>
                <div class="images">
                  <video
                    class="video"
                    controls
                    playsinline
                    webkit-playsinline
                    loop
                    [muted]="'muted'"
                    autoplay
                    src="assets/static/home/videos/community3.mp4"
                  ></video>
                </div>
              </ng-template>
            </swiper>
          </div>
  
          <div *ngIf="innerWidth >= 768" class="images-main">
            <div class="images">
              <video
                playsinline
                webkit-playsinline
                class="video"
                controls
                loop
                [muted]="'muted'"
                autoplay
                src="assets/static/home/videos/promo.mp4"
              ></video>
            </div>
            <div class="images">
              <video
                playsinline
                webkit-playsinline
                class="video"
                controls
                loop
                [muted]="'muted'"
                autoplay
                src="assets/static/home/videos/community1.mp4"
              ></video>
            </div>
            <div class="images">
              <video
                playsinline
                webkit-playsinline
                class="video"
                controls
                loop
                [muted]="'muted'"
                autoplay
                src="assets/static/home/videos/community3.mp4"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

        <!-- new section -->

        <!-- <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 pricing-section place-items-center lg:place-items-start">
          <div class="pricing-card">
            <div class="flex flex-col gap-24">
              <h1 class="pricing-heading">Join Our <br>
                Community
              </h1>
              <div class="flex flex-col gap-4 mb-14">
                <div class="flex justify-between items-center p-4 booking-tab cursor-pointer" (click)="navigateTo(contactObject)">
                  <div class="flex flex-col gap-4">
                    <p class="text-2xl font-medium text-white">Book a 15-min intro call</p>
                    <p class=" text-base font-medium text-white">Learn more about how DesignJoy works and how it can help you.</p>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" viewBox="0 0 24 24"><path d="m18.707 12.707-3 3a1 1 0 0 1-1.414-1.414L15.586 13H6a1 1 0 0 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414z" style="fill:#fff" data-name="Right"/></svg>

                </div>
                <div class="flex justify-between items-center p-4 booking-tab cursor-pointer" (click)="navigateTo({ label: 'Referrals', url: this.routesConst.earnReferrals })">
                  <div class="flex flex-col gap-4">
                    <p class="text-2xl font-medium text-white">Refer a friend & earn</p>
                    <p class=" text-base font-medium text-white">Earn 5% monthly recurring commissions for each referral.</p>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" viewBox="0 0 24 24"><path d="m18.707 12.707-3 3a1 1 0 0 1-1.414-1.414L15.586 13H6a1 1 0 0 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414z" style="fill:#fff" data-name="Right"/></svg>

                </div>
              </div>

            </div>
  
          </div>
          <div class="membership-card w-full">
            <h4 class="membership-heading my-2">Membership</h4>
            <app-footer-pricing-plans></app-footer-pricing-plans>
            <div class="flex gap-4 mt-4 items-center">
              <span class="button outlined" (click)="navigateTo(contactObject)">Get Started</span>
              <span>or <a (click)="navigateTo(contactObject)" class="border-b-4 font-semibold border-black">book a call</a></span>
            </div>
          </div>
  
        </div> -->

  <!-- <div class="border-b divide-black my-4"></div> -->
  <div class="section-main home-footer-part">
    <!-- <div class="w-full">
    <mat-divider class="border-gray-800"></mat-divider>
  </div> -->
    <!-- <div class="flex flex-col justify-center footer-top-main">
    <h3 class="flex justify-center footer-top-title">Create your account</h3>
    <h3 class="flex justify-center footer-top-title">in minutes</h3>
    <div class="footer-top-btn">
      <p class="footer-btn-txt cursor-pointer">Get started</p>
    </div>
  </div> -->
  <div id="aboutUs" class="back-ground-img-margin">
    <div class=" px-0 md:px-6 md:mt-6">
      <div class="grid main-grid-div" [ngClass]="data === 'sidenavbar'?'':'md:grid-cols-6 md:gap-10'">
        <div *ngIf="data !== 'sidenavbar'" class="flex items-center justify-center w-52">
          <button routerLink="/pricing"
            class="flex items-center justify-center my-3 md:my-0 slider-content-font-family rounded montserrat-regular font-[400] text-[17px] w-[150px] py-1 hover:bg-white border-2 border-[#2887ed] hover:text-[#2887ed] text-black">
           Book a Call</button>
        </div>
        <div *ngIf="data !== 'sidenavbar'"
          class="flex md:col-span-2 footer-mobile-display justify-start md:justify-center items-center"
        >
          <div
            class="flex flex-col order-1 md:order-2 md:w-auto w-full"
          >
            <a href="/" class="footer-logo">
              <img
                src="../../../../../assets/images/logo/b-logo-desktop.png"
                alt="jobpros logo"
              />
            </a>

            <!-- <p class="footer-logo-section block md:font-bold">

              JOBPros gives you a platform with the innovative <br class="hidden md:block"> 
              technology needed in today’s fast-paced world to <br class="hidden md:block">
              succeed. Join our mission to your financial future. 
            </p> -->
            <!-- <p class="footer-divider"></p>
          <p class="footer-description">
            Get hired, sell content and expert services. Invite others to your network & earn monthly residual income.  
          </p> -->
          </div>
          <!-- <a href="/" class="footer-email">
            <img
              src="https://assets-global.website-files.com/60ca70f6cb7406afbf948a8e/60d15ad570c7d06ba3bd1b4b_footer_email.svg"
              class="mr-2"
            />
            <div>
              <a
                href="https://accounts.google.com/v3/signin/identifier?dsh=S-83614693%3A1676377807514689&continue=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&emr=1&followup=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&osid=1&passive=1209600&service=mail&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AWnogHeBsfmsRLkDfVdYJ4aWTG-2e6HoX2weruEodUnnOeO8R6ooMWznliOLmdGdcgnDC1eaHCLINg"
                target="_blank"
                class="footer-email-address"
                >support@jobpros.io</a
              >
            </div>
          </a> -->

          <!-- <div class="hidden md:flex justify-center items-center mt-6">
            <form
              class="w-full flex items-center"
              [formGroup]="subscribeForm"
              (ngSubmit)="subscribe()"
              novalidate
            >
              <input
                class="rounded w-3/5 h-10 rounded-r-none"
                type="text"
                placeholder="Your Email"
                formControlName="email"
              />
              <button
                type="submit"
                mat-button
                class="button w-2/5 h-10 subscribe-button"
              >
                Subscribe
              </button>
            </form>
          </div> -->
          <!-- <p class="hidden md:flex black-dark montserrat-semibold capitalize mt-6">
          Get exclusive offers & updates
        </p> -->
        </div>
        <!-- <div class="md:hidden grid grid-cols-6 gap-4 mt-4">
        <div class="link-wrapper flex justify-start" *ngFor="let item of links">
          <a [href]="item.url" [class]="'link link-' + item.class"></a>
        </div>
      </div> -->
        <div [ngClass]="data === 'sidenavbar'?'hidden':'hidden md:grid'"
        class=" nav-group grid-cols-2 md:grid-cols-4 md:col-span-3 gap-2 mt-4 md:mt-0 nav-group-mobile"
      >
        <div class="flex flex-col mt-4 md:mt-0 ">
          <h5 class="montserrat-semibold mb-2 footer-heading" [id]="innerWidth > 767  && data !== 'sidenavbar' ? '#Platform':''">Platform</h5>
          <a
            class="grey-text justify-center lg:justify-start footer-text"
            *ngFor="let service of services"
            [routerLink]="'/' + service.url"
            >{{ service.label }}</a
          >
        </div>
        <div class="flex flex-col mt-4 md:mt-0 ">
          <h5 class="montserrat-semibold mb-2 footer-heading" [id]="innerWidth > 767  && data !== 'sidenavbar' ? '#Company':''">Company</h5>
          <a
            class="cursor-pointer grey-text justify-center lg:justify-start footer-text"
            *ngFor="let expert of experts"
            (click)="navigateTo(expert)"
            >{{ expert?.label }}</a
          >
        </div>
        <div class="flex flex-col mt-6 md:mt-0 ">
          <h5 class="montserrat-semibold mb-2 footer-heading" [id]="innerWidth > 767  && data !== 'sidenavbar' ? '#Integrations':''">
            Integrations
          </h5>
          <a
            *ngFor="let app of apps"
            [href]="app.url"
            class="grey-text justify-center lg:justify-start footer-text"
            target="_blank"
            >{{ app.label }}</a
          >
        </div>
        <div class="flex flex-col mt-6 md:mt-0 ">
          <h5 class="montserrat-semibold mb-2 footer-heading">Legal</h5>
          <a
            *ngFor="let creator of Creators"
            [href]="creator.url"
            class="grey-text cursor-pointer black-dark justify-center lg:justify-start footer-text"
            >{{ creator.label }}</a
          >
          <!-- <a (click)="navigateTo('/' + routesConst.dashboard + '/' + routesConst.network,'2')"
          class="montserrat-semibold cursor-pointer footer-text black-dark">
          Subscribers
        </a> -->
        </div>
      </div>
      <div [ngClass]="data === 'sidenavbar' ? 'grid':'grid md:hidden md:grid-cols-4' "
          class=" nav-group border-b grid-cols-1  md:col-span-3 mt-4 md:mt-0 nav-group-mobile"
        >
        <cdk-accordion class="accordion expandable-section" #accordian  [ngClass]="data === 'sidenavbar'?'block':'block md:hidden'" [multi]="true" [tabIndex]="">
          <cdk-accordion-item  #accordionItemPlatform="cdkAccordionItem" [id]="innerWidth < 768 && data !== 'sidenavbar' ? '#Platform':''"  class="accordion-item block pb-4"
            role="button" tabindex="0" [attr.aria-expanded]="accordionItemPlatform.expanded">
            <div class="accordion-item-header" (click)="accordionItemPlatform.toggle()" [ngClass]="accordionItemPlatform.expanded ? 'opened-acocordian' : ''">
              <span class="accordion-item-description flex justify-between" >
                <h4 class="footer-heading-text">Platform</h4>  <mat-icon>keyboard_arrow_right</mat-icon>
              </span>
            </div>
            <div
              class="accordion-item-body pt-4"
              role="region"
              [style.display]="accordionItemPlatform.expanded ? '' : 'none'">
              <a (click)="accordionItemPlatform.toggle();data === 'sidenavbar'? offSideMenu():'' ; data === 'sidenavbar' ? accordionItemPlatform.close() : '' "
              class="grey-text justify-center lg:justify-start footer-text" [ngStyle]="data === 'sidenavbar' ? {'font-size': '13px'} : {}"
              *ngFor="let service of services"
              [routerLink]="'/' + service.url"
              >{{ service.label }}</a>
            </div>
          </cdk-accordion-item>
          <cdk-accordion-item  #accordionItemCompany="cdkAccordionItem" [id]="innerWidth < 767  && data !== 'sidenavbar' ? '#Company':''"  class="accordion-item block pb-4"
            role="button" tabindex="0" [attr.aria-expanded]="accordionItemCompany.expanded">
            <div class="accordion-item-header" (click)="accordionItemCompany.toggle()" [ngClass]="accordionItemCompany.expanded ? 'opened-acocordian' : ''">
              <span class="accordion-item-description flex justify-between" >
                <h4 class="footer-heading-text">Company</h4>  <mat-icon>keyboard_arrow_right</mat-icon>
              </span>
            </div>
            <div
              class="accordion-item-body pt-4"
              role="region"
              [style.display]="accordionItemCompany.expanded ? '' : 'none'">
              <a
              class="cursor-pointer grey-text justify-center lg:justify-start footer-text"
              *ngFor="let expert of experts" [ngStyle]="data === 'sidenavbar' ? {'font-size': '13px'} : {}"
              (click)="navigateTo(expert);accordionItemCompany.toggle(); data === 'sidenavbar'? offSideMenu():'' ; data === 'sidenavbar' ? accordionItemPlatform.close() : ''"
              >{{ expert?.label }}</a>
            </div>
          </cdk-accordion-item>
          <cdk-accordion-item  #accordionItemIntegrations="cdkAccordionItem" [id]="innerWidth < 767  && data !== 'sidenavbar' ? '#Integrations':''"  class="accordion-item block pb-4"
            role="button" tabindex="0" [attr.aria-expanded]="accordionItemIntegrations.expanded">
            <div class="accordion-item-header" (click)="accordionItemIntegrations.toggle()" [ngClass]="accordionItemIntegrations.expanded ? 'opened-acocordian' : ''">
              <span class="accordion-item-description flex justify-between" >
               <h4 class="footer-heading-text">Integrations</h4>  <mat-icon>keyboard_arrow_right</mat-icon>
              </span>
            </div>
            <div
              class="accordion-item-body pt-4"
              role="region"
              [style.display]="accordionItemIntegrations.expanded ? '' : 'none'">
              <a (click)="accordionItemIntegrations.toggle();data === 'sidenavbar'? offSideMenu():'' ; data === 'sidenavbar' ? accordionItemPlatform.close() : ''"
              *ngFor="let app of apps" [ngStyle]="data === 'sidenavbar' ? {'font-size': '13px'} : {}"
              [href]="app.url"
              class="grey-text justify-center lg:justify-start footer-text"
              target="_blank"
              >{{ app.label }}</a>
            </div>
          </cdk-accordion-item>
          <cdk-accordion-item  #accordionItemLegal="cdkAccordionItem"  class="accordion-item block pb-4"
            role="button" tabindex="0" [attr.aria-expanded]="accordionItemLegal.expanded">
            <div class="accordion-item-header" (click)="accordionItemLegal.toggle()" [ngClass]="accordionItemLegal.expanded ? 'opened-acocordian' : ''">
              <span class="accordion-item-description flex justify-between" >
                <h4 class="footer-heading-text">Legal</h4>  <mat-icon>keyboard_arrow_right</mat-icon>
              </span>
            </div>
            <div
              class="accordion-item-body pt-4"
              role="region"
              [style.display]="accordionItemLegal.expanded ? '' : 'none'">
              <a (click)="accordionItemLegal.toggle();data === 'sidenavbar'? offSideMenu():'' ; data === 'sidenavbar' ? accordionItemPlatform.close() : ''"
                *ngFor="let creator of Creators"
                [href]="creator.url" [ngStyle]="data === 'sidenavbar' ? {'font-size': '13px'} : {}"
                class="grey-text cursor-pointer black-dark justify-center lg:justify-start footer-text"
                >{{ creator.label }}</a>
            </div>
          </cdk-accordion-item>
      </cdk-accordion>
          
          
          
          
        </div>




      </div>
      <!-- <div class="flex md:hidden justify-center items-center mt-6">
        <form
          [formGroup]="subscribeForm"
          class="flex items-center"
          novalidate
        >
          <input
            class="rounded w-3/5 h-10 rounded-r-none"
            type="text"
            placeholder="Your Email"
            formControlName="email"
          />
          <button
            (click)="subscribe()"
            mat-button
            class="button w-2/5 h-10 subscribe-button"
          >
            Subscribe
          </button>
        </form>
      </div> -->
      <!-- <p class="md:hidden black-dark montserrat-semibold capitalize mt-6">
      Get exclusive offers & updates
    </p> -->
    </div>
    <div [ngClass]="data === 'sidenavbar'?'':'lg:flex-row'" 
      class="flex flex-col justify-between items-start md:items-center mt-10 px-0 md:px-6 footer-bottom-text"
    >
      <!-- <p class="black-dark montserrat-semibold capitalize flex items-center">
      Get exclusive offers & updates
    </p> -->
      <!-- <a class="header__logo full" routerLink="{{ routesConst.launchPath }}">
      <span class="nav-icon truncate" class="b-logo"></span>
    </a>
    <div class="flex w-full items-center justify-center md:justify-between mt-2 md:px-20">
      <div class="hidden md:grid grid-cols-6 gap-4">
        <div class="link-wrapper flex justify-center" *ngFor="let item of links">
          <a [href]="item.url" [class]="'link link-' + item.class" target="_blank"></a>
        </div>
      </div>
      <p class="montserrat-bold flex items-center text-sm">
        <mat-icon class="text-sm">copyright</mat-icon>JOBPros All Rights
        Reserved.
      </p>
    </div> -->
      <div
        class="grid grid-cols-5 gap-4 mb-4 lg:mb-0 mr-0 md:mr-4 footer-social-block"
      >
        <div class="flex justify-start order-1" *ngFor="let item of links">
          <a
            [href]="item.url"
            [class]="'link link-' + item.class"
            class="icons-size"
          ></a>
        </div>
      </div>
      <div class="flex items-center footer-email-address order-2 lg:order-3">
        <span class="font-semibold md:text-base text-sm cursor-pointer" (click)="data === 'sidenavbar'? offSideMenu():''" [routerLink]="'/' + routesConst.terms">Terms |</span>
        <span class="mx-1 font-semibold md:text-base text-sm cursor-pointer"(click)="data === 'sidenavbar'? offSideMenu():''"  [routerLink]="'/' + routesConst.privacy">Privacy |</span>
        <span class="font-semibold md:text-base text-sm cursor-pointer" (click)="data === 'sidenavbar'? offSideMenu():''" [routerLink]="'/' + routesConst.cookies" >Cookies</span>
      </div>
      <a href="/" class="footer-email mt-4 lg:mb-0 order-3 lg:order-2">
        <!-- <img
          src="https://assets-global.website-files.com/60ca70f6cb7406afbf948a8e/60d15ad570c7d06ba3bd1b4b_footer_email.svg"
          class="mr-2"
        /> -->
        <mat-icon class="copy-right">copyright</mat-icon>
        <div>
          <a
            href="https://accounts.google.com/v3/signin/identifier?dsh=S-83614693%3A1676377807514689&continue=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&emr=1&followup=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&osid=1&passive=1209600&service=mail&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AWnogHeBsfmsRLkDfVdYJ4aWTG-2e6HoX2weruEodUnnOeO8R6ooMWznliOLmdGdcgnDC1eaHCLINg"
            target="_blank"
            class=" font-semibold md:text-base text-sm "
            >JOBPros Inc.</a
          >
        </div>
      </a>
      
      <!-- <p class="flex items-center footer-email-address order-3">
        <mat-icon class="copy-right">copyright</mat-icon>JOBPros All Rights
        Reserved.
      </p> -->
    </div>
  </div>
  </div>
</div>
<!-- <app-sticky-footer></app-sticky-footer> -->
