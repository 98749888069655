import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { messageTypeConstant } from 'src/app/const/appConst';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageService } from 'src/app/core/services/image.service';
import { JobService } from 'src/app/core/services/job.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
export interface LocalDrive {
  id: number;
  path: any;
  active: boolean;
  name: string;
}
@Component({
  selector: 'app-job-apply-modal',
  templateUrl: './job-apply-modal.component.html',
  styleUrls: ['./job-apply-modal.component.scss'],
})
export class JobApplyModalComponent {
  private unsubscribe$ = new Subject();
  experiencePortfolio: LocalDrive[];
  viewMore = false;
  headerExpanded = false;
  jobHeader0 = 0;
  jobInfo: any;
  applyPart: any;
  coverLetter!: string;
  imgUploading = false;
  applyJob = true;
  loginUser:any;

  constructor(
    private snack: SnackBarService,
    public _jobsService: JobService,
    public dialogRef: MatDialogRef<JobApplyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private imageService: ImageService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private auth: AuthService,
  ) {
    this.experiencePortfolio = [];
    this.jobInfo = {};
  }
  ngOnInit() {
    if (this.auth.loggedIn()) {
      this.dashboard();
    }
  }
  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        this.loginUser = information;
      }
    });
  }
  ngAfterViewInit(): void {
    if (window.innerWidth < 1024) {
      this.jobInfo = document.getElementById('jobInfo');
      this.jobHeader0 = this.jobInfo.clientHeight;
      this.jobInfo.style.height = '134px';
      this.applyPart = document.getElementById('applyPart');
      this.applyPart.style.paddingTop = '134px';
    }
  }

  /**
   * Expand Job Info Header
   */
  expandHeader() {
    this.jobInfo = document.getElementById('jobInfo');
    this.jobInfo.style.height =
      this.jobInfo.clientHeight === 134 ? this.jobHeader0 + 28 + 'px' : '134px';
    this.headerExpanded = !this.headerExpanded;
    this.applyPart = document.getElementById('applyPart');
    this.applyPart.style.paddingTop = this.headerExpanded
      ? this.jobHeader0 + 28 + 'px'
      : '134px';
  }

  /**
   * Uploading Images
   * @param files
   * @returns
   */
  uploadImage(files: FileList) {
    if (this.experiencePortfolio.length === 5) {
      this.snack.showMessage('You Can not add more than 5 piece of your work', true)
      return
    }
    this.imgUploading = true;
    if (files.length === 0) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const mimeType = files[i].type;
      if (mimeType.match(/image\/*/) == null) {
        continue;
      }

      const reader = new FileReader();
      reader.readAsDataURL(files[i]);
      const file: File = files[i];
      reader.addEventListener('load', (event: any) => {
        const id = Math.random();
        this.imageService
          .imageConvertion(new ImageSnippet(event.target.result, file))
          .then((imageUri: any) => {
            this.experiencePortfolio.push({
              id: id,
              path: imageUri[0],
              active: true,
              name: files[i].name.split('.')[0],
            });
            this.imgUploading = false;
          })
      });
    }
  }

  /**
   * Remove Highligted Experience
   * @param id
   */
  removeExperience(id: number) {
    let currentIndex = this.experiencePortfolio.findIndex((item) => item.id == id);
    if (currentIndex > -1) {
      this.experiencePortfolio.splice(currentIndex, 1);
    }
  }

  /**
   * Get Location Data
   * @param job
   * @returns
   */
  getLocation(job: any) {
    return job?.city_name + (job?.state_name ? `, ${job?.state_name} ` : "") + (job?.country_code ? `, ${job?.country_code} ` : "")
  }

  /**
   * on Submission of Application
   */
  sendApplication() {
    if (this.coverLetter?.length === undefined) {
      this.snack.showMessage('Can not Send Blank Application', true)
      return
    }
    this.applyJob = false;

    if (this.data?.job && this.data?.job?.job?.company_id && this.data?.job?.job?._id) {
      const appliedJobParams = {
        applied_status: messageTypeConstant.pending,
        company_id: this.data?.job?.job?.company_id,
        job_id: this.data?.job?.job?._id,
        cover_letter: this.coverLetter,
        highlighted_experiences: this.experiencePortfolio ? this.experiencePortfolio : [],
        status_type: false
      };
      this._jobsService.applyForJobs(appliedJobParams).pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
        if (response.status == 'success') {
          this.data.job.job.isApplied = true;
          this.data.job.job.isSaved = false;
          this.data.job.job.status_type = response.data.status_type;
          this.data.job.job.appliedOn = response.data.created_at;
          setTimeout(() => {
            this.dialogRef.close(true);
          }, 1500);
        }
        else {
          setTimeout(() => {
            this.dialogRef.close(false);
          }, 1500);
        }
        this.snack.showMessage(`${response.message}`, response.status == 'success' ? false : true);

      },
        () => {
          this.snack.showMessage('Failed to apply for this job.', true);
          this.applyJob = true;
        }
      );
    }

  }

  /**
   * Updating Company Id on messaging
   * @param company
   */
  sendCompanyId(company: any) {
    localStorage.setItem('companyId', JSON.stringify(company));
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
