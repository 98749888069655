import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { loginSignUpConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { ShortNumberPipe } from 'src/app/shared/pipes/short-number.pipe';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit {
  loginSignUpConstant = loginSignUpConstant;
  routesConst = routesConst;
  @Input() data: any;
  @Input() page: any;

  annualIncome: any;
  constructor(private router: Router, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void { }

  formatLabel(value: number | null) {
    if (!value) {
      return '$' + 0;
    }
    if (value >= 10000) {
      return '$' + Math.round(value / 1000) + 'k';
    }
    return '$' + value;
  }

  openSignUp(pageToOpen: string) {
    if (!this.authService.loggedIn()) {
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen },
      });
    }
    else {
      if(this.page === 'earn'){
        this.router.navigate(['/' + this.routesConst.dashboard + '/' + this.routesConst.referrals])
      }else{
        this.router.navigate(['/' + this.routesConst.dashboard + '/' + this.routesConst.account])
      }
    }
  }

}
