import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userTypeConstant } from 'src/app/const/appConst';
import { loginValues } from 'src/app/core/const/login';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-auth-sign-up',
  templateUrl: './auth-sign-up.component.html',
  styleUrls: ['./auth-sign-up.component.scss']
})
export class AuthSignUpComponent implements OnInit {
  @Input() isDialog = false;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Output() forsignup = new EventEmitter<string>();
  private unsubscribe$ = new Subject();
  signUpForm!: FormGroup;
  routesConst = routesConst;
  loginValues = loginValues;
  loading: boolean = false;
  userType = userTypeConstant;
  user_type: string = userTypeConstant.candidate;
  hide = { confirm_password: true, password: true };
  submitted: boolean = false;
  referral: string | null = null;

  constructor(
    private route: ActivatedRoute,
    public fb: FormBuilder,
    private authService: AuthService,
    private snack: SnackBarService,
    private commonService: CommonService
  ) {
    this.initializeForm();
  }

  initializeForm() {
    this.signUpForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(128),
          AuthSignUpComponent.noWhitespaceValidator({ hasWhiteSpace: true })
        ]
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.maxLength(79),
          Validators.email,
          Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z, A-Z]{2,3}')
        ]
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(256),
          AuthSignUpComponent.patternValidator(/^\S*$/, { hasSpace: true }),
          AuthSignUpComponent.patternValidator(/\d/, { hasNumber: true }),
          AuthSignUpComponent.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          AuthSignUpComponent.patternValidator(/[a-z]/, { hasSmallCase: true }),
          AuthSignUpComponent.patternValidator(/[^A-Za-z0-9,\s]/, {
            hasSpecialCharacters: true
          })
        ]
      ],
      referralCode: [null],
      acceptTerms: [false, Validators.requiredTrue]
    });

    if (this.user_type === this.userType.candidate) {
      this.signUpForm.addControl('dateOfBirth', this.fb.control('', [Validators.required, this.ageValidator(13)]));
    } else {
      this.signUpForm.removeControl('dateOfBirth');
    }

  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (referral: any) => {
        if (referral?.referralLink) {
          this.signUpForm.get('referralCode')?.setValue(referral?.referralLink)
          localStorage.setItem('referralId', referral?.referralLink);
        }
      },
      error => {
        this.snack.showMessage(`${error} `, true);
      }
    );
    this.referral = localStorage.getItem(JSON.parse(JSON.stringify('referralId'))) ?? null;
  }

  get f() {
    return this.signUpForm.controls;
  }

  public errorHandling = (control: string, error: string) => {
    return this.signUpForm.controls[control].hasError(error);
  };

  submitForm() {
    this.submitted = true;
    if (this.signUpForm.valid) {
      this.loading = true;
      let { name, password, referralCode, dateOfBirth } = this.signUpForm.value;
      const email = this.signUpForm.value.email.toLowerCase();
      referralCode = referralCode?.includes("referralLink") ? referralCode?.split("referralLink=")[1] :referralCode;
      this.authService
        .validateEmail(email)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: () => {
            if (this.referral === null) {
              this.referral = referralCode ? referralCode : null;
            }
            const signUpPayload = {
              name,
              email,
              password,
              dateOfBirth: this.user_type === this.userType.candidate ? dateOfBirth?._d : '',
              user_type: this.user_type,
              referralId: this.referral ? this.referral : null
            };
            this.authService
              .signup(signUpPayload)
              .then(() => {
                localStorage.removeItem('referralId');
                localStorage.removeItem('sign_up');
                localStorage.setItem('verify_email', JSON.stringify(1));
                this.closeDialog.emit();
              })
              .catch(() => {
                localStorage.removeItem('sign_up');
                this.loading = false;
              });
          },
          error: err => {
            this.loading = false;
            this.snack.showMessage(`${err}`, true);
          }
        });
    }
  }

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }
  static noWhitespaceValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace && control.value != '' ? error : null;
    };
  }

  share() {
    this.commonService.location().href = 'mailto:support@jobpros.io';
  }

  forSignUpRedirection(routeName:string) {
    this.forsignup.emit(routeName);
  }

  ngOnDestroy() {
    localStorage.removeItem('referralId')
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
  ageValidator(minAge: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const today = new Date();
      const birthDate = new Date(control.value);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      
      return age >= minAge ? null : { underage: true };
    };
  }
}
