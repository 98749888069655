import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';


@NgModule({
    declarations: [],
    imports: [
        MatBottomSheetModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatRadioModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatMenuModule,
        MatSelectModule,
        MatStepperModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSliderModule,
        MatTooltipModule,
        MatTableModule,
        MatMomentDateModule,
        MatCardModule,
        NgxMaterialTimepickerModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatPaginatorModule
    ],

    providers: [
        MatBottomSheetModule,
        {
            provide: MatDialogRef,
            useValue: {},
        },
    ],

    exports: [
        MatBottomSheetModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatRadioModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatMenuModule,
        MatSelectModule,
        MatStepperModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSliderModule,
        MatTooltipModule,
        MatTableModule,
        MatMomentDateModule,
        MatCardModule,
        NgxMaterialTimepickerModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatPaginatorModule
    ],
})
export class MaterialModule { }
