<div class="flex">
  <div class="sidenav">
    <div>
        <img class="img-wrapper object-cover" src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/SGmCZ.jpeg" alt="">
    </div>
    <div class="flex flex-col" *ngFor="let item of showActiveItems">
      <div class="flex gap-4 p-3 my-[2px] cursor-pointer nav-item items-center" [routerLink]="item.url">
        <mat-icon class="text-[#333]">home</mat-icon>
        <p class="text-[16px] text-[#333] leading-[20px] font-bold sidenav-label">{{item.name}}</p>
      </div>
    </div>
  </div>
  <div class="flex justify-center main-content">
    <div class="news-feed-container">
      <app-feed-filter-panel (onSearch)="onSearch($event)" (onReset)="onSearch($event)"></app-feed-filter-panel>
      <div class="grid grid-cols-1 gap-4">
        <div *ngFor="let item of content index as i;">
          <div class="grid grid-cols-1 gap-4 news-feed">
            <app-post-detail-card
              #postDetailCard
              [isOpenBottomSheet]="true"
              *ngIf="item.type == 'POST' && interestList?.length"
              [postID]="item._id"
              [commentIdChanged]="commentId"
              [loginedProfile]="profile"
              [loginedFollowers]="followers"
              [interestList]="interestList"
              (postByInterest)="getRelatedPosts($event)"
              [addView]="item._id"
              [appInViewLogger]="{
                type: 'post',
                postId: item._id,
                isMobileView: innerWidth < 768,
                isSingleGrid: true
              }"
              (viewLogged)="onPostViewLogged($event, postDetailCard)"
            >
            </app-post-detail-card>
            <app-job-card
              #jobCard
              *ngIf="item.type == 'JOB'"
              [isHome]="true"
              [loginedProfile]="profile"
              [jobList]="item.data"
              (save)="saveJob(item.data)"
              (apply)="openApplyDialog(item.data)"
              [appInViewLogger]="{
                type: 'job',
                postId: item.data.job._id,
                isMobileView: false,
                isSingleGrid: true
              }"
              (viewLogged)="onJobViewLogged($event, jobCard, item)"
              [isShowInteractionPanel]="true"
            ></app-job-card>
            <app-talent-card
              #talentCard
              *ngIf="item.type == 'SERVICE'"
              [talent]="item"
              [loginedProfile]="profile"
              [appInViewLogger]="{
                type: 'service',
                postId: item._id,
                isMobileView: false,
                isSingleGrid: true
              }"
              (viewLogged)="onServiceViewLogged($event, talentCard, item)"
              [isShowInteractionPanel]="true"
            ></app-talent-card>
            <app-individual-service-card
              #subscriptionCard
              *ngIf="item.type == 'SUBSCRIPTION'"
              [service]="item"
              [loginedProfile]="profile"
              [appInViewLogger]="{
                type: 'subscription',
                postId: item._id,
                isMobileView: false,
                isSingleGrid: true
              }"
              (viewLogged)="onSubscriptionViewLogged($event, subscriptionCard, item)"
              [isShowInteractionPanel]="true"
            >
            </app-individual-service-card>
          </div>
      
          <div class="p-5" *ngIf="(i + 1) % 8 === 0">
            <app-content-card 
            [data]="suggestContentList" 
            [contentType]="(i + 1) / 8 == 1 ? 'FEATURED' : 
                          (i + 1) / 8 == 2 ? 'RECOMMENDED' : 
                          (i + 1) / 8 == 3 ? 'SPONSORED' : 
                          (i + 1) / 8 == 4 ? 'HIGHLIGHTS' : 
                          (i + 1) / 8 == 5 ? 'SUGGESTED' : ''">
            </app-content-card>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>



<ngx-spinner
  bdOpacity="0.9"
  bdColor="black-dark"
  size="medium"
  color="red"
  type="ball-spin-clockwise"
  [fullScreen]="true"
  class="job-list-spinner"
>
</ngx-spinner>
