import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  loginSignUpConstant,
  planTypeConstant,
  searchTypeConstant,
  statusConstant,
  userTypeConstant
} from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { JobPostComponent } from 'src/app/shared/components/job-post/job-post.component';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { ServiceModalComponent } from 'src/app/shared/components/service-modal/service-modal.component';
import { SubscriptionPackageModalComponent } from 'src/app/shared/components/subscription-package-modal/subscription-package-modal.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { registerValues } from '../../const/register';
import { AuthService } from '../../services/auth/auth.service';
import { CommonService } from '../../services/common.service';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { JobService } from '../../services/job.service';
import { ProfileService } from '../../services/profile.service';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  loginSignUpConstant = loginSignUpConstant;
  userTypeConstant = userTypeConstant;
  @ViewChild('searchInput', { static: true })
  searchInput?: ElementRef;
  private unsubscribe$ = new Subject();
  @ViewChild('drawer') element?: any;
  scrollPosition = 0;
  routesConst = routesConst;
  expanded = false;
  mouseEnterOpend = false;
  mouseEnterClosed = false;
  iconRotate = false;
  iconsValue = '';
  currentUrl = '';
  isemit = false;
  loginIconColor = false;
  checklogin = false;
  showOptionGroupF = false;
  routerEvent?: Subscription;
  user: any = {};
  emptyUser = {
    avatar: '',
    user_name: '',
    name: ''
  };
  isdisabled: boolean = false;
  registerValues = registerValues;
  type = registerValues.UserTypes.candidate;
  isUnreadMessage: any;
  purchasedPlan: any;
  showFiller = false;
  icon: any;
  SerchClose: boolean = false;
  navList: any[];
  navItems = [{ url: 'login', value: 'Join' }];
  loggedNavItems = [
    { url: routesConst.talents, value: 'Find Talents' },
    { url: 'jobs', value: 'Find Work' },
    { url: 'community', value: 'Community' },
    { url: '/dashboard/messages', value: 'Messages' }
  ];
  opened: boolean = false;
  currentPageUrl: string = '';
  filteredOptions: any[] = [];
  search = [searchTypeConstant.jobs, searchTypeConstant.experts, searchTypeConstant.creators];
  selectedSearcType = searchTypeConstant.jobs.label;
  keywordToSearch = '';
  logo = '/assets/images/recuiter-black-icon.png';
  secLogo = '/assets/images/recuiter-w-icon.png';
  @HostListener('window:scroll')
  checkScroll() {
    this.scrollPosition =
      this.commonService.getWindow()?.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
  }
  connectionRequest = false;
  messageNotifications: any = [];
  paymentNotifications: any = [];
  socialMediaNotifications: any = [];
  allnotification: any = [];
  userType: any;
  clientUnreadMessages: any = [];
  networkUnreadMessages: any = [];
  recruiterUnreadMessages: any = [];
  unreadNotification = 0;
  isUnreadMessages = false;
  isRequestPending = false;
  login = false;
  @Input() sideBarClose: any;
  stripeUserData: any;
  showSubMenu = false;
  constructor(
    private configuration: ConfigurationService,
    private userPlan: userPlans,
    private snack: SnackBarService,
    public jobService: JobService,
    private profileService: ProfileService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private router: Router,
    public authService: AuthService,
    private dialog: MatDialog
  ) {
    this.user = {
      avatar: '',
      name: '',
      userName: '',
      userType: userTypeConstant.candidate,
      email: ''
    };
    this.navList = [];
  }
  jobprosPlan: any;

  @Output() onSidebarToogle = new EventEmitter();

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.stripeUser();
      setTimeout(() => {
        this.commonService
          .newPostNotificationReceived()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((message: any) => {
          });
        this.commonService
          .newNotificationReceived()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res) this.newMessageSound();
          });
      }, 2000);
      this.checkUnreadNotifications();
      this.getPurchasedPlan();
      this.getUserType();
      if (!this.isRequestPending) {
        this.isRequestPending = true;
        this.getPlateformPlans();
      }
    }
    this.loadUserDetails();
    this.currentUrl = this.commonService.location().pathname.replace('/', '');
    this.routerEvent = this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: any) => {
        if (event?.url?.includes(routesConst.dashboard)) {
          if (this.authService.loggedIn()) {
            if (!this.isRequestPending) {
              this.isRequestPending = true;
              this.getPlateformPlans();
            }
            this.checkUnreadNotifications();
            this.stripeUser();
          }
          this.loadUserDetails();
        }
        if (event instanceof NavigationEnd) {
          this.currentUrl = this.commonService.location().pathname.replace('/', '');
        }
      });

    this.icon = document.getElementById('sideNavIcon');
    this.navList = this.authService.loggedIn() ? this.loggedNavItems : this.navItems;
    this.currentPageUrl = this.router.url;

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.currentPageUrl = event.url;
        const routesToShowMenu = ['/', '/'+ routesConst.aboutus, '/'+ routesConst.faq, '/'+ routesConst.pricing, '/'+ routesConst.tutorials];       
        
        if (routesToShowMenu.includes(event.url)) {
          this.showSubMenu = true
        }else{
          this.showSubMenu = false
        }
        
      }
    });
  }

  /**
   * Listen for Incoming Notifications
   */
  incomingNotifications() {
    this.commonService.newNotificationReceived().subscribe((result: any) => {
      if (result) {
        this.sharedService.isUnreadNotification$.next(
          this.sharedService.isUnreadNotification$.value + 1
        );
      }
    });

    this.commonService.newMessageNotificationReceived().subscribe((result: any) => {
      if (result) {
        this.sharedService.isUnreadNotification$.next(
          this.sharedService.isUnreadNotification$.value + 1
        );
      }
      this.sharedService.checkIfUnreadMessageRemaining();
    });

    this.commonService.newPostNotificationReceived().subscribe((result: any) => {
    });
  }

  stripeUser() {
    this.userPlan
      .stripeUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        this.stripeUserData = user.data;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.sideBarClose === true) {
      this.element.toggle();
      this.opened = !this.opened;
      this.onSidebarToogle.emit(this.opened);
    }
  }

  getPurchasedPlan() {
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.purchasedPlan = res.currentPlan;
      });
  }

  getPlateformPlans() {
    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plan: any) => {
        this.isRequestPending = false;
        const { platform_subscription } = plan;
        this.jobprosPlan = platform_subscription;
      });
  }

  unReadMessages() {
    this.commonService.isUnreadMessagesExist
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((unread: any) => {
        this.isUnreadMessage = unread;
      });
  }

  serchClose() {
    this.SerchClose = !this.SerchClose;
  }

  postContent() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
      return;
    }

    this.dialog.open(UploadPostModalComponent, {
      maxWidth: '100vw',
      width: '536px',
      disableClose: true,
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: {}
    });
  }

  openSignUp(pageToOpen: string) {
    const dialogRef = this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px' : '100vw',
      height: window.innerWidth > 600 ? 'h-fit' : '100%',
      maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
      maxHeight: window.innerWidth > 600 ? '600' : '100vh',
      data: { pageToOpen }
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loginIconColor = false;
        this.authService.isLoggedIn.next(res);
        if (res) {
          this.loadUserDetails();
          this.getPurchasedPlan();
        }
      });
  }

  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information: { profileInfo: any } | null) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else if (information != null) {
          const { profileInfo } = information;
          const { _id, name, user_type, avatar, user_name, email } = profileInfo;
          this.user.name = name;
          this.user.email = email;
          this.user.userName = user_name;
          this.user.avatar =
            avatar && avatar.length ? avatar : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs';
          this.type = user_type;
          if (!this.connectionRequest) {
            this.connectionRequest = true;
            this.commonService.connectSocket();
            this.incomingNotifications();
            this.getUnreadMessages();
          }
        }
      });
  }

  getOnBoardStatus() {
    this.sharedService.onBoardStatus$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status: string) => {
        if (status === '') {
          this.sharedService.getOnBoardStatus();
        }
      });
  }

  showLogoutOption() {
    this.showOptionGroupF = !this.showOptionGroupF;
  }

  logout() {
    this.showOptionGroupF = !this.showOptionGroupF;
    this.authService.logout();
  }

  postJob() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/' + routesConst.login]);
    } else {
      this.checkEligibility();
    }
  }

  checkEligibility(): void {
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToAddJob();
        }
      });
  }

  proceedToAddJob() {
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'success') {
          if (!res.currentPlan) {
            this.snack.showMessage('Please purchase a paid plan to post job', true);
            this.router.navigate(['/' + routesConst.planPricing]);
            return;
          }
          if (res.currentPlan.plan_type == planTypeConstant.recruiter) {
            this.jobService
              .checkEligibility()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.result) {
                  const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                      message: `Requires membership upgrade to BUSINESS, ENTERPRISE or NON-PROFIT`,
                      header: 'Unlimited Job Posting'
                    }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/' + routesConst.planPricing]);
                    }
                  });
                } else {
                  this.openJobPostDialog();
                }
              });
          } else {
            this.openJobPostDialog();
          }
        }
      });
  }

  openJobPostDialog() {
    this.dialog.open(JobPostComponent, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      hasBackdrop: false,
      panelClass: 'post-job-dialog-container'
    });
  }

  handleSidebar(evt: any) {
    if (this.element)
      if (this.element._elementRef.nativeElement.contains(evt.target)) {
      } else {
        this.opened = false;
      }
  }

  checkUnReadMessages() {
    return (
      this.authService.loggedIn() &&
      (this.isUnreadMessage?.recruiter ||
        this.isUnreadMessage?.mynetwork ||
        this.isUnreadMessage?.client)
    );
  }

  onSearch(event: any) {
    if (event?.key == 'Enter' && this.keywordToSearch?.trim()?.length) {
      if (this.selectedSearcType == searchTypeConstant.creators?.label) {
        this.router.navigate([routesConst.community], {
          queryParams: { search: this.keywordToSearch }
        });
      }
      if (this.selectedSearcType == searchTypeConstant.jobs.label) {
        this.router.navigate([routesConst.jobs], {
          queryParams: { search: this.keywordToSearch }
        });
      }
      if (this.selectedSearcType == searchTypeConstant.experts.label) {
        this.router.navigate([routesConst.talents], {
          queryParams: { search: this.keywordToSearch }
        });
      }
      this.keywordToSearch = '';
    }
  }

  loadUserDetails() {
    if (this.authService.loggedIn()) {
      this.dashboard();
      this.unReadMessages();
      this.getOnBoardStatus();
    }
  }

  mouseOverOut(e: any) {
    this.logo = e;
  }

  mouseOverrOut(e: any) {
    this.secLogo = e;
  }

  onChange() {
    this.onSearch({ key: 'Enter' });
  }

  createCampaign() {
    this.profileService.getpurchasedPlan().subscribe((res: any) => {
      let userCurrentPlan = res.currentPlan;
      if (
        userCurrentPlan?.plan_type == planTypeConstant.elite ||
        userCurrentPlan?.plan_type == planTypeConstant.nil ||
        userCurrentPlan?.plan_type == planTypeConstant.enterprise
      ) {
        this.dialog.open(UploadPostModalComponent, {
          maxWidth: '100vw',
          width: '536px',
          disableClose: true,
          height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
          data: { isCampaign: true }
        });
      } else {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          width: '500px',
          data: {
            message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`,
            header: 'Send Subscription'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['/' + routesConst.pricing]);
          }
        });
      }
    });
  }

  createContent() {
    if (this.authService.loggedIn()) {
      this.newSubscriptionPackage();
    } else {
      this.openSignUp(loginSignUpConstant.login);
    }
  }

  navigateTo(url: string) {
    if (url == this.router.url) {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate([url]));
    } else {
      this.router.navigate(['/' + url]);
    }
  }

  mouseleaveBtn(menuTrigger: any) {
    this.mouseEnterOpend = false;
    setTimeout(() => {
      this.mouseEnterClosed = false;
      this.share(menuTrigger);
    }, 100);
  }

  mouseenterMenu(menuTrigger: any) {
    this.iconRotate = true;
    this.mouseEnterOpend = true;
    this.share(menuTrigger);
  }

  share(menuTrigger: any) {
    if (this.mouseEnterOpend || this.mouseEnterClosed) {
      menuTrigger.openMenu();
    } else {
      this.iconRotate = false;
      menuTrigger.closeMenu();
    }
  }

  newSubscriptionPackage() {
    // this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          // this.spinner.hide();
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewSubscriptionPackage();
        }
      });
  }

  proceedToNewSubscriptionPackage() {
    // this.spinner.show();
    this.configuration
      .configureCustomerPortal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.profileService.getpurchasedPlan().subscribe((res: any) => {
          let userCurrentPlan = res.currentPlan;
          if (
            userCurrentPlan?.plan_type == planTypeConstant.elite ||
            userCurrentPlan?.plan_type == planTypeConstant.nil ||
            userCurrentPlan?.plan_type == planTypeConstant.enterprise
          ) {
            const redirect_url = this.commonService.location().href;
            const refresh_url = this.commonService.location().href;
            this.userPlan
              .getStripeUser(redirect_url, refresh_url)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                (user: any) => {
                  // this.spinner.hide();
                  if (user.status == 'on_board_required') {
                    if (user?.accountLink?.url) {
                      this.commonService.location().href = user?.accountLink?.url;
                    }
                    this.snack.showMessage(`${user.message} `, true);
                  } else {
                    this.dialog.open(SubscriptionPackageModalComponent, {
                      width: '536px',
                      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
                      maxWidth: '100vw',
                      hasBackdrop: false
                    });
                  }
                },
                error => {
                  // this.spinner.hide();
                  this.snack.showMessage(`${error} `, true);
                }
              );
          } else {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
              width: '500px',
              data: {
                message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`,
                header: 'Create Subscriptions'
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this.router.navigate(['/' + routesConst.planPricing]);
              }
            });
            // this.spinner.hide();
          }
        });
      });
  }

  addNewService() {
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewService();
        }
      });
  }

  proceedToNewService() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    this.profileService.getpurchasedPlan().subscribe((res: any) => {
      let userCurrentPlan = res.currentPlan;
      if (
        userCurrentPlan?.plan_type == planTypeConstant.pro ||
        userCurrentPlan?.plan_type == planTypeConstant.elite ||
        userCurrentPlan?.plan_type == planTypeConstant.business ||
        userCurrentPlan?.plan_type == planTypeConstant.enterprise
      ) {
        this.configuration
          .configureCustomerPortal()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            () => {
              this.dialog.open(ServiceModalComponent, {
                maxWidth: '100vw',
                width: '536px',
                height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
                data: { editmode: false },
                disableClose: true
              });
            },
            (error: any) => {
              this.snack.showMessage(`${error} `, true);
            }
          );
      } else {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          width: '500px',
          data: {
            message: `Requires membership upgrade to PRO, ELITE, BUSINESS or ENTERPRISE`,
            header: 'Create Expert Service'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['/' + routesConst.planPricing]);
          }
        });
      }
    });
  }

  getUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === '') {
        this.sharedService.getUserType();
      } else {
        this.userType = type;
      }
    });
  }

  checkUnreadNotifications() {
    this.sharedService.getUnreadNotification();
    this.sharedService.isUnreadNotification$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.unreadNotification = res;
      });
  }

  getUnreadMessages() {
    this.sharedService.checkIfUnreadMessageRemaining();
    this.sharedService.isUnreadMessageExist$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isUnread: any) => {
        this.isUnreadMessages = isUnread;
      });
  }

  openNotifications() {
    this.dialog.open(NotificationComponent, {
      maxWidth: '100vw',
      width: '536px',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      panelClass: 'notification-dialog-container'
    });
  }

  newMessageSound() {
    let audio = new Audio();
    audio.src = '../../../../assets/audio/notification.wav';
    audio.load();
    audio.play();
  }

  calculateDaysRemaining(futureDate: string): number {
    const currentDate = new Date();
    const targetDate = new Date(futureDate);

    // Get the difference in milliseconds
    const timeDifference = targetDate.getTime() - currentDate.getTime();

    // Convert milliseconds to days (1 day = 24  60  60 * 1000 ms)
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Return remaining days
    return daysRemaining > 0 ? daysRemaining : 0; // Return 0 if the date has passed
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
