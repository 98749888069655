<div *ngIf="data" class="h-full flex items-center justify-center bg-white">
  <div class="relative flex items-center selectForm">
    <!-- <p *ngIf="option" class="montserrat-bold">{{label}}</p> -->
    <!-- <mat-icon>expand_more</mat-icon> -->
    <!-- <mat-select placeholder="Select Range" class="absolute w-full opacity-0" *ngIf="data.type !== undefined"
      [(ngModel)]="option" (selectionChange)="onChange($event)">
      <mat-option class="empty-option" value=""></mat-option>
      <mat-option *ngFor="let item of data.type; trackBy:trackByFunc" [value]="item.id">{{item.label}}</mat-option>
    </mat-select> -->
    <mat-select (selectionChange)="onChange($event)" [(ngModel)]="option" class="s" placeholder="Select Duration">
      <mat-option  *ngFor="let item of data.type" [value]="item.id">
        {{ item.label }}        
      </mat-option>
    </mat-select>
  </div>
</div>