<div class="realtive header-notification w-full">
  <img class="w-10 absolute top-2 left-3" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">

  <p class="text-white montserrat-bold">Notifications</p>
  <mat-icon
    class="text-white absolute right-2.5 top-2 mt-1 cursor-pointer"
    (click)="close()"
    >close</mat-icon
  >
</div>
<mat-tab-group class="notification-tabs">

  <!-- ALL NOTIFICATIONS TAB -->
  <mat-tab label="All">
    <ng-template mat-tab-label>
      <div class="notification-font-size">
        All <span class="notify-length">{{ allnotification?.length | shortNumber }}</span>
      </div>
    </ng-template>

  <!-- TODAY ALL NOTIFICATION -->
    <div *ngIf="todayNotifications.length" class="filter-wise">
      <p class="filter-wrapper">New</p>
      <div
        *ngFor="let allnotify of todayNotifications"
        class="notification-wrapper"
        (click)="readNotification(allnotify)"
      >
        <div class="flex justify-center items-center relative">
          <img
            *ngIf="allnotify?.sender?.profile_img_path; else userProfile"
            class="avatar object-cover rounded-full"
            [src]="allnotify.sender.profile_img_path"
            onerror="this.src='assets/images/no-image.png'"
          />
          <ng-template #userProfile>
            <img
              class="avatar object-cover rounded-full"
              [src]="allnotify.user_details?.profile_img_path"
              onerror="this.src='assets/images/no-image.png'"
            />
          </ng-template>
        </div>
        <div class="flex justify-evenly h-full flex-col">
          <div class="flex items-center">
            <p *ngIf="allnotify?.sender?.name" class="msg text-sm">
              <span class="mr-1 font-bold user-name">{{
                allnotify.sender.name
              }}</span>
              <span class="user-text">{{ allnotify.message_content }}</span>
            </p>
            <p *ngIf="allnotify?.user_details?.name" class="msg text-sm">
              <span class="mr-1 font-bold user-name">{{
                allnotify.user_details.name
              }}</span>
              <span class="user-text">{{ allnotify.description }}</span>
            </p>
          </div>
        </div>

        <div class="grid gap-y-2 pt-2">
          <div
            *ngIf="
              notificatiocIcon(
                allnotify.notification_type
                  ? allnotify.notification_type
                  : allnotify.tab
              )[0]?.icon &&
              checkIsFollow(
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id
              )
            "
            class="notify-badge"
          >
            <mat-icon>{{
              notificatiocIcon(
                allnotify.notification_type
                  ? allnotify.notification_type
                  : allnotify.tab
              )[0]?.icon
            }}</mat-icon>
          </div>
          <div
            *ngIf="
              allnotify.notification_type === 'role'
            "
            class="notify-badge"
          >
            <mat-icon>vpn_key</mat-icon>
          </div>
          <button
            [class]="
              allnotify?.user_details?.user_name
                ? allnotify?.user_details?.user_name
                : allnotify?.sender?.user_name
            "
            *ngIf="
              !checkIsFollow(
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id
              )
            "
            class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
            (click)="
              followUser(
                $event,
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id,
                allnotify?.user_details?.user_name
                  ? allnotify?.user_details?.user_name
                  : allnotify?.sender?.user_name
              )
            "
          >
            Follow
          </button>
          <p class="time-wrapper">{{ formatJobDate(allnotify.created_at) }}</p>
        </div>
      </div>
    </div>

  <!-- ONE WEEK ALL NOTIFICATION -->
    <div *ngIf="thisWeekNotifications.length" class="filter-wise">
      <p class="filter-wrapper">This week</p>
      <div
        *ngFor="let allnotify of thisWeekNotifications"
        class="notification-wrapper"
        (click)="readNotification(allnotify)"
      >
        <div class="flex justify-center items-center relative">
          <img
            *ngIf="allnotify?.sender?.profile_img_path; else userProfile"
            class="avatar object-cover rounded-full"
            [src]="allnotify.sender.profile_img_path"
            onerror="this.src='assets/images/no-image.png'"
          />
          <ng-template #userProfile>
            <img
              class="avatar object-cover rounded-full"
              [src]="allnotify.user_details?.profile_img_path"
              onerror="this.src='assets/images/no-image.png'"
            />
          </ng-template>
        </div>
        <div class="flex justify-evenly h-full flex-col">
          <div class="flex items-center">
            <p *ngIf="allnotify?.sender?.name" class="msg text-sm">
              <span class="mr-1 font-bold user-name">{{
                allnotify.sender.name
              }}</span>
              <span class="user-text">{{ allnotify.message_content }}</span>
            </p>
            <p *ngIf="allnotify?.user_details?.name" class="msg text-sm">
              <span class="mr-1 font-bold user-name">{{
                allnotify.user_details.name
              }}</span>
              <span class="user-text">{{ allnotify.description }}</span>
            </p>
          </div>
        </div>

        <div class="grid gap-y-2 pt-2">
          <div
            *ngIf="
              notificatiocIcon(
                allnotify.notification_type
                  ? allnotify.notification_type
                  : allnotify.tab
              )[0]?.icon &&
              checkIsFollow(
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id
              )
            "
            class="notify-badge"
          >
            <mat-icon>{{
              notificatiocIcon(
                allnotify.notification_type
                  ? allnotify.notification_type
                  : allnotify.tab
              )[0]?.icon
            }}</mat-icon>
          </div>
          <button
            [class]="
              allnotify?.user_details?.user_name
                ? allnotify?.user_details?.user_name
                : allnotify?.sender?.user_name
            "
            *ngIf="
              !checkIsFollow(
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id
              )
            "
            class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
            (click)="
              followUser(
                $event,
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id,
                allnotify?.user_details?.user_name
                  ? allnotify?.user_details?.user_name
                  : allnotify?.sender?.user_name
              )
            "
          >
            Follow
          </button>
          <p class="time-wrapper">{{ formatJobDate(allnotify.created_at) }}</p>
        </div>
      </div>
    </div>

  <!-- ONE MONTH ALL NOTIFICATION -->
    <div *ngIf="thisMonthNotifications.length" class="filter-wise">
      <p class="filter-wrapper">This month</p>
      <div
        *ngFor="let allnotify of thisMonthNotifications"
        class="notification-wrapper"
        (click)="readNotification(allnotify)"
      >
        <div class="flex justify-center items-center relative">
          <img
            *ngIf="allnotify?.sender?.profile_img_path; else userProfile"
            class="avatar object-cover rounded-full"
            [src]="allnotify.sender.profile_img_path"
            onerror="this.src='assets/images/no-image.png'"
          />
          <ng-template #userProfile>
            <img
              class="avatar object-cover rounded-full"
              [src]="allnotify.user_details?.profile_img_path"
              onerror="this.src='assets/images/no-image.png'"
            />
          </ng-template>
        </div>
        <div class="flex justify-evenly h-full flex-col">
          <div class="flex items-center">
            <p *ngIf="allnotify?.sender?.name" class="msg text-sm">
              <span class="mr-1 font-bold user-name">{{
                allnotify.sender.name
              }}</span>
              <span class="user-text">{{ allnotify.message_content }}</span>
            </p>
            <p *ngIf="allnotify?.user_details?.name" class="msg text-sm">
              <span class="mr-1 font-bold user-name">{{
                allnotify.user_details.name
              }}</span>
              <span class="user-text">{{ allnotify.description }}</span>
            </p>
          </div>
        </div>

        <div class="grid gap-y-2 pt-2">
          <div
            *ngIf="
              notificatiocIcon(
                allnotify.notification_type
                  ? allnotify.notification_type
                  : allnotify.tab
              )[0]?.icon &&
              checkIsFollow(
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id
              )
            "
            class="notify-badge"
          >
            <mat-icon>{{
              notificatiocIcon(
                allnotify.notification_type
                  ? allnotify.notification_type
                  : allnotify.tab
              )[0]?.icon
            }}</mat-icon>
          </div>
          <button
            [class]="
              allnotify?.user_details?.user_name
                ? allnotify?.user_details?.user_name
                : allnotify?.sender?.user_name
            "
            *ngIf="
              !checkIsFollow(
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id
              )
            "
            class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
            (click)="
              followUser(
                $event,
                allnotify?.user_id ? allnotify?.user_id : allnotify?.from_id,
                allnotify?.user_details?.user_name
                  ? allnotify?.user_details?.user_name
                  : allnotify?.sender?.user_name
              )
            "
          >
            Follow
          </button>
          <p class="time-wrapper">{{ formatJobDate(allnotify.created_at) }}</p>
        </div>
      </div>
    </div>
  </mat-tab>

  <!-- MESSAGES TAB -->
  <mat-tab label="Messages ">
    <ng-template mat-tab-label>
      <div class="notification-font-size">
        Messages
        <span class="notify-length">{{ messageNotifications.length | shortNumber }}</span>
      </div>
    </ng-template>

    <!-- TODAY MESSAGES NOTIFICATION -->
    <div *ngIf="todayMessageNotifications.length" class="filter-wise">
      <p class="filter-wrapper">New</p>
    <div
      *ngFor="let notification of todayMessageNotifications"
      class="notification-wrapper"
      (click)="readMessage(notification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="notification.sender.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{ notification.sender.name }}</b>
            {{ notification.message_content }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="notification?.sender?.user_name"
          *ngIf="!checkIsFollow(notification?.from_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              notification?.from_id,
              notification?.sender?.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(
              notification.notification_type
                ? notification.notification_type
                : notification.tab
            )[0]?.icon && checkIsFollow(notification?.from_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(
              notification.notification_type
                ? notification.notification_type
                : notification.tab
            )[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">{{ formatJobDate(notification.created_at) }}</p>
      </div>
    </div>
    </div>

    <!-- ONE WEEK MESSAGES NOTIFICATION -->
    <div *ngIf="thisWeekMessageNotifications.length" class="filter-wise">
      <p class="filter-wrapper">This week</p>
    <div
      *ngFor="let notification of thisWeekMessageNotifications"
      class="notification-wrapper"
      (click)="readMessage(notification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="notification.sender.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{ notification.sender.name }}</b>
            {{ notification.message_content }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="notification?.sender?.user_name"
          *ngIf="!checkIsFollow(notification?.from_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              notification?.from_id,
              notification?.sender?.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(
              notification.notification_type
                ? notification.notification_type
                : notification.tab
            )[0]?.icon && checkIsFollow(notification?.from_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(
              notification.notification_type
                ? notification.notification_type
                : notification.tab
            )[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">{{ formatJobDate(notification.created_at) }}</p>
      </div>
    </div>
    </div>

    <!-- ONE MONTH MESSAGES NOTIFICATION -->
    <div *ngIf="thisMonthMessageNotifications.length" class="filter-wise">
      <p class="filter-wrapper">This month</p>
    <div
      *ngFor="let notification of thisMonthMessageNotifications"
      class="notification-wrapper"
      (click)="readMessage(notification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="notification.sender.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{ notification.sender.name }}</b>
            {{ notification.message_content }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="notification?.sender?.user_name"
          *ngIf="!checkIsFollow(notification?.from_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              notification?.from_id,
              notification?.sender?.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(
              notification.notification_type
                ? notification.notification_type
                : notification.tab
            )[0]?.icon && checkIsFollow(notification?.from_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(
              notification.notification_type
                ? notification.notification_type
                : notification.tab
            )[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">{{ formatJobDate(notification.created_at) }}</p>
      </div>
    </div>
    </div>
  </mat-tab>

  <!-- NETWORK TAB -->
  <mat-tab label="Network">
    <ng-template mat-tab-label>
      <div class="notification-font-size">
        Network
        <span class="notify-length">{{ socialMediaNotifications.length | shortNumber }}</span>
      </div>
    </ng-template>

    <!-- TODAY NETWORK NOTIFICATION -->
    <div *ngIf="todaySocialMediaNotifications.length" class="filter-wise">
      <p class="filter-wrapper">New</p>
    <div
      *ngFor="let sociallnotification of todaySocialMediaNotifications"
      class="notification-wrapper cursor-pointer"
      (click)="readNotification(sociallnotification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="sociallnotification.user_details.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{
              sociallnotification.user_details.name
            }}</b>
            {{ sociallnotification.description }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="sociallnotification.user_details.user_name"
          *ngIf="!checkIsFollow(sociallnotification.user_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              sociallnotification.user_id,
              sociallnotification.user_details.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(sociallnotification.notification_type)[0]?.icon &&
            checkIsFollow(sociallnotification.user_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(sociallnotification.notification_type)[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">
          {{ formatJobDate(sociallnotification.created_at) }}
        </p>
      </div>
    </div>
    </div>

    <!-- ONE WEEK NETWORK NOTIFICATION -->
    <div *ngIf="thisWeekSocialMediaNotifications.length" class="filter-wise">
      <p class="filter-wrapper">This week</p>
    <div
      *ngFor="let sociallnotification of thisWeekSocialMediaNotifications"
      class="notification-wrapper cursor-pointer"
      (click)="readNotification(sociallnotification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="sociallnotification.user_details.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{
              sociallnotification.user_details.name
            }}</b>
            {{ sociallnotification.description }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="sociallnotification.user_details.user_name"
          *ngIf="!checkIsFollow(sociallnotification.user_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              sociallnotification.user_id,
              sociallnotification.user_details.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(sociallnotification.notification_type)[0]?.icon &&
            checkIsFollow(sociallnotification.user_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(sociallnotification.notification_type)[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">
          {{ formatJobDate(sociallnotification.created_at) }}
        </p>
      </div>
    </div>
    </div>

    <!-- ONE MONTH NETWORK NOTIFICATION -->
    <div *ngIf="thisMonthSocialMediaNotifications.length" class="filter-wise">
      <p class="filter-wrapper">This month</p>
    <div
      *ngFor="let sociallnotification of thisMonthSocialMediaNotifications"
      class="notification-wrapper cursor-pointer"
      (click)="readNotification(sociallnotification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="sociallnotification.user_details.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{
              sociallnotification.user_details.name
            }}</b>
            {{ sociallnotification.description }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="sociallnotification.user_details.user_name"
          *ngIf="!checkIsFollow(sociallnotification.user_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              sociallnotification.user_id,
              sociallnotification.user_details.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(sociallnotification.notification_type)[0]?.icon &&
            checkIsFollow(sociallnotification.user_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(sociallnotification.notification_type)[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">
          {{ formatJobDate(sociallnotification.created_at) }}
        </p>
      </div>
    </div>
    </div>
   </mat-tab>


  <!-- PAYMENTS TAB -->
  <mat-tab label="Payments">
    <ng-template mat-tab-label>
      <div class="notification-font-size">
        Payments
        <span class="notify-length">{{ paymentNotifications?.length | shortNumber}}</span>
      </div>
    </ng-template>

    <!-- TODAY PAYMENTS NOTIFICATION -->
    <div *ngIf="todayPaymentNotifications.length" class="filter-wise">
      <p class="filter-wrapper">New</p>
    <div
      *ngFor="let paymentnotification of todayPaymentNotifications"
      class="notification-wrapper cursor-pointer"
      (click)="readNotification(paymentnotification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="paymentnotification?.user_details?.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{
              paymentnotification.user_details.name
            }}</b>
            {{ paymentnotification.description }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="paymentnotification?.user_details?.user_name"
          *ngIf="!checkIsFollow(paymentnotification?.user_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              paymentnotification?.user_id,
              paymentnotification?.user_details?.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(paymentnotification.notification_type)[0]?.icon &&
            checkIsFollow(paymentnotification?.user_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(paymentnotification.notification_type)[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">
          {{ formatJobDate(paymentnotification.created_at) }}
        </p>
      </div>
    </div>
    </div>

    <!-- ONE WEEK PAYMENTS NOTIFICATION -->
    <div *ngIf="thisWeekPaymentNotifications.length" class="filter-wise">
      <p class="filter-wrapper">This week</p>
    <div
      *ngFor="let paymentnotification of thisWeekPaymentNotifications"
      class="notification-wrapper cursor-pointer"
      (click)="readNotification(paymentnotification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="paymentnotification?.user_details?.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{
              paymentnotification.user_details.name
            }}</b>
            {{ paymentnotification.description }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="paymentnotification?.user_details?.user_name"
          *ngIf="!checkIsFollow(paymentnotification?.user_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              paymentnotification?.user_id,
              paymentnotification?.user_details?.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(paymentnotification.notification_type)[0]?.icon &&
            checkIsFollow(paymentnotification?.user_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(paymentnotification.notification_type)[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">
          {{ formatJobDate(paymentnotification.created_at) }}
        </p>
      </div>
    </div>
    </div>

    <!-- ONE MONTH PAYMENTS NOTIFICATION -->
    <div *ngIf="thisMonthPaymentNotifications.length" class="filter-wise">
      <p class="filter-wrapper">This month</p>
    <div
      *ngFor="let paymentnotification of thisMonthPaymentNotifications"
      class="notification-wrapper cursor-pointer"
      (click)="readNotification(paymentnotification)"
    >
      <div class="flex justify-center items-center relative">
        <img
          class="avatar object-cover rounded-full"
          [src]="paymentnotification?.user_details?.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
      </div>
      <div class="flex justify-evenly h-full flex-col">
        <div class="flex items-center">
          <p class="msg">
            <b class="mr-1 font-bold">{{
              paymentnotification.user_details.name
            }}</b>
            {{ paymentnotification.description }}
          </p>
        </div>
      </div>
      <div class="pt-2">
        <button
          [class]="paymentnotification?.user_details?.user_name"
          *ngIf="!checkIsFollow(paymentnotification?.user_id)"
          class="w-fit trail-button text-sm px-3 py-1 rounded-full shadow-lg"
          (click)="
            followUser(
              $event,
              paymentnotification?.user_id,
              paymentnotification?.user_details?.user_name
            )
          "
        >
          Follow
        </button>
        <div
          *ngIf="
            notificatiocIcon(paymentnotification.notification_type)[0]?.icon &&
            checkIsFollow(paymentnotification?.user_id)
          "
          class="notify-badge"
        >
          <mat-icon>{{
            notificatiocIcon(paymentnotification.notification_type)[0]?.icon
          }}</mat-icon>
        </div>
        <p class="time-wrapper">
          {{ formatJobDate(paymentnotification.created_at) }}
        </p>
      </div>
    </div>
    </div>
  </mat-tab>
</mat-tab-group>
