<div class="invite-user-modal relative">
  <div class="realtive header-notification w-full">
    <img class="w-10 absolute top-2 left-3"
      src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">

    <p class="text-white montserrat-bold">Assign Role</p>
    <mat-icon class="text-white absolute right-2.5 top-2 mt-1 cursor-pointer" mat-dialog-close>close</mat-icon>
  </div>
  <div class="mb-2 mt-4 px-4 lg:px-20 flex flex-col">
    <div class="relative flex flex-col">
      <label class="montserrat-bold"> User </label>
      <input (keyup)="filtertedUser()" [disabled]="data.user" [(ngModel)]="searchIdx" name="name" class="border rounded w-full" type="text"
        placeholder="Select User" />

      <div class=" pr-1 lg:overflow-y-auto  overflow-x-hidden max-height-members-wrapper px-2">
        <div class="h-2/5 flex items-center justify-center" *ngIf="filteredUser?.length=== 0">
          <h2>No Result Found</h2>
        </div>
        <ng-container *ngIf="filteredUser.length > 0">
          <div *ngFor="let profile of filteredUser">
            <div class="search-user-item flex items-center py-2">
              <img class="w-10 h-10 rounded-full object-contain bg-black"
                [src]="profile?.profile_image ? profile.profile_image : profile.profile_img_path ? profile.profile_img_path : 'https://i.postimg.cc/Hk7vyZZM/no-image.png'">
              <p class="mx-2 truncate profile-name">{{profile.user_name}}</p>
              <!-- <input type="checkbox" [checked]="profile.isSelected" class="w-5 h-5 rounded"> -->
              <!-- <button class="button white ml-auto text-xs" (click)="setActive($event , profile.user_id)">Send</button> -->

              <mat-checkbox [(ngModel)]="profile.selectedUserToSend" class="ml-auto blue"
                (change)="selectedUserChange($event,profile)"></mat-checkbox>
              <!-- <mat-radio-button [value]="profile" class="ml-auto blue"
                (change)="selectedUserChange(profile)"></mat-radio-button> -->
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="sticky bg-white bottom-10">
      <p class="montserrat-bold mt-4 mb-2">Permissions</p>
      <div class="grid grid-cols-2 gap-2">
        <ng-container *ngFor="let role of moderatorRoles">
          <div [ngClass]="getRoleName(role) === 'All' ? 'col-span-2':''">
            <mat-slide-toggle class="flex justify-between blue assign-role-checkbox"
              (change)="selectedUserRole($event, role)" [checked]="isChecked(role)">
              <div class="flex items-center">
                <p class="montserrat-bold text-base">{{ getRoleName(role) }}</p>
              </div>
            </mat-slide-toggle>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
  <div class="w-full">
    <mat-divider></mat-divider>
  </div>
  <div class="flex justify-between px-4 py-3 sticky -bottom-6 bg-white">
    <button mat-button class="button outlined black" mat-dialog-close>Cancel</button>
    <button mat-button class="button" (click)="saveModerator()">Submit</button>
  </div>
</div>