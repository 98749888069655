import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-skill-form',
  templateUrl: './skill-form.component.html',
  styleUrls: ['./skill-form.component.scss']
})
export class SkillFormComponent implements OnInit {
  @Input()isCreate: boolean;
  @Output()cancel: EventEmitter<any> = new EventEmitter();
  skillForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.isCreate = true;
    this.skillForm = this.fb.group({
      industry: ['', [Validators.required]],
      type:  ['', [Validators.required]],
      level:  ['', [Validators.required]],
      payRange:  ['', [Validators.required]],
    })
  }

  ngOnInit(): void {
  }

  onSave() {
    this.onCancel();
  }

  onCancel() {
    this.cancel.emit();
  }

  onDelete() {
    this.onCancel();
  }
}
