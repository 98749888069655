import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-service-category-expansion',
  templateUrl: './service-category-expansion.component.html',
  styleUrls: ['./service-category-expansion.component.scss']
})
export class ServiceCategoryExpansionComponent implements OnInit {
  @Input() group: any;
  @Input() activeList: any[];
  @Output() onChange: EventEmitter<any> = new EventEmitter<string>();
  isExpanded: boolean;

  constructor() {
    this.activeList = [];
    this.isExpanded = false;
  }

  ngOnInit(): void {
  }

  change(item: any) {
    this.onChange.emit(item);
  }

  isActive(id: string) {
    if (this.activeList?.find((item: any) => item?.id === id)) return true;
    return false;
  }

  expand() {
    this.isExpanded = !this.isExpanded;
  }
}
