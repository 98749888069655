import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { CampaignDetailModalComponent } from '../campaign-detail-modal/campaign-detail-modal.component';

@Component({
  selector: 'app-campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.scss']
})
export class CampaignCardComponent implements OnInit {
  @Input() data?: any

  constructor(public dialog: MatDialog, private commonService:CommonService) { }

  ngOnInit(): void { }

  showCampaignModal() {
    let innerWidth = this.commonService.getWindow()?.innerWidth
    this.dialog.open(CampaignDetailModalComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      width: '868px',
      height: innerWidth && innerWidth > 1024 ? '444px' : '100vh',
      data: this.data
    });
  }
}
