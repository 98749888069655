<div class="service-modal h-full bg-white flex flex-col justify-between -m-6">
  <div class="header w-full relative">
    <button *ngIf="isLastStep" (click)="goToPrev(stepper)" class="absolute back-btn">
      <mat-icon class="arrow-back text-white">west</mat-icon>
    </button>
    <img *ngIf="!isLastStep" class="w-10 absolute top-2 left-3" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">
    <h4 class="montserrat-bold py-6 text-center text-white items-center flex service-tittle">
      {{ data?.editmode ? 'Edit' : 'Select' }} Category
    </h4>
    <div class="absolute right-0 top-0 mt-1">
      <button mat-icon-button (click)="onClose()">
        <mat-icon class="text-white">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="service-modal_content px-6 pb-6 pt-2">
    <mat-horizontal-stepper labelPosition="bottom" #stepper>
      <mat-step>
        <div class="">
          <div>
            <input
              id="searchServiceIdx"
              [formControl]="serviceIndex"
              class="outline-none input-form w-full mt-2 px-4"
              placeholder="Services"
            />
          </div>
          <div
            *ngIf="selectedServiceCategory && selectedServiceCategory.length > 0"
            class="flex flex-wrap mt-4"
          >
            <div
              class="tag m-1 flex items-center gap-1"
              *ngFor="let item of selectedServiceCategory"
            >
              {{ item.label }}
              <mat-icon class="text-white close-icon" (click)="removeService(item.id)">cancel</mat-icon>
            </div>
          </div>
          <div class="mt-4" *ngIf="filteredCategories && filteredCategories.length > 0">
            <div *ngFor="let group of filteredCategories" class="mb-2">
              <app-service-category-expansion
                [group]="group"
                (onChange)="updateCategory($event)"
                [activeList]="selectedServiceCategory"
              ></app-service-category-expansion>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step>
        <div class="">
          <ngx-spinner
            bdOpacity="0.9"
            bdColor="black-dark"
            size="medium"
            color="red"
            type="ball-spin-clockwise"
            [fullScreen]="true"
            class="job-list-spinner"
          >
          </ngx-spinner>
          <div class="flex items-center justify-between">
            <div class="flex items-center">
              {{ this.selectedServiceCategory[0]?.label }}
            <p class="blue montserrat-bold cursor-pointer ml-2" (click)="goToPrev(stepper)">
              Change
            </p>
            </div>
            <button
            mat-button
            class="montserrat-medium mt-md button upload-btn-size"
            (click)="uploadButtonClick()"
          >
            Upload
          </button>
          </div>
          <div>
            <input
              maxlength="257"
              (keyup)="updateCheck()"
              [(ngModel)]="title"
              required
              [formControl]="serviceTitle"
              name="serviceTitle"
              class="input-form w-full mt-2 px-4 text-sm"
              placeholder="+ Add Name"
            />
            <mat-error *ngIf="title?.length > 256" class="text-danger pb-2">
              Title cannot exceed more than 256 characters!
            </mat-error>
          </div>

          <div class="mt-4">
            <!-- IMAGES SECTION -->
            <label class="h-full" [htmlFor]="'fileInput'">
              <div class="w-full h-full relative slide-wrapper">
                <swiper [config]="config" [pagination]="{ clickable: true }">
                  <ng-template swiperSlide *ngFor="let portfolio of getArray(4); index as i">
                    <div
                      (click)="imageIndex = i; updateImage = true"
                      class="relative w-full h-full"
                    >
                      <img
                        *ngIf="allImages[i] && allImages[i]?.media_type == 'image'"
                        [src]="allImages[i]?.url"
                        class="h-full img-mx-height mx-auto"
                      />
                      <mat-icon
                        (click)="removeImage(i, $event)"
                        *ngIf="allImages[i] && allImages[i]?.media_type == 'image'"
                        class="text-black absolute top-0 right-0 z-10"
                        >cancel</mat-icon
                      >
                      <ng-container *ngIf="allImages[i] && allImages[i]?.media_type == 'video'">
                        <video
                          [src]="allImages[i]?.url+ '#t=0.1'"  preload="metadata"
                          class="h-full img-mx-height mx-auto"
                          controls
                          playsinline
                          webkit-playsinline
                        ></video>
                        <mat-icon
                          (click)="removeImage($event, i)"
                          class="text-black absolute top-0 right-0 z-10"
                          >cancel</mat-icon
                        >
                      </ng-container>
                      <div
                        *ngIf="!allImages[i]"
                        class="relative w-full h-full border border-dashed border-gray-400 flex items-center"
                      >
                        <img
                          src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/j7m3z.png"
                          class="mx-auto opacity-30 max-widthHeight object-scale-down"
                        />
                      </div>
                    </div>
                  </ng-template>
                </swiper>
              </div>
            </label>
            <!-- IMAGES SECTION END -->

            <div class="mt-2">
              <div class="flex flex-col gap-2">
                <p class="montserrat-bold">Categories</p>
              <div class="flex flex-col gap-2">
                  <mat-checkbox
                  formControlName="featured"
                  class="pt-1"
                  [color]="'primary'"
                  (change)="onCheckboxChange('FEATURED',$event)"
                  [value]=""
                >
                  <p>Featured</p>
                </mat-checkbox>
                  <mat-checkbox
                  formControlName="sponsored"
                  class="pt-1"
                  [color]="'primary'"
                  (change)="onCheckboxChange('SPONSORED', $event)"
                  [value]=""
                >
                  <p>Sponsored</p>
                </mat-checkbox>
              </div>
              </div>
            </div>

            <div *ngIf="!data.editmode">
              <mat-select
                required
                class="input-form mt-2 text-sm"
                [formControl]="priceRange"
                name="priceRange"
                placeholder="Price Range"
                (selectionChange)="validatePrice()"
              >
                <mat-option *ngFor="let payrange of allPayRange" [value]="payrange"
                  >{{ payrange.label }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div *ngIf="!data.editmode" class="mt-1">
            <p class="s black-light mt-4"><span class="text-red-600" >*</span>  Please fill the information for all tabs.</p>
            <mat-tab-group class="service-tab">
              <mat-tab label="Basic">
                <div class="mt-6">
                  <app-package-unit
                    [checkPriceValidation]="checkPrice"
                    [order]="0"
                    (setPackageForm)="savePackageData($event, 0, 'basic')"
                  ></app-package-unit>
                </div>
              </mat-tab>
              <mat-tab label="Standard">
                <div class="mt-6">
                  <app-package-unit
                    [checkPriceValidation]="checkPrice"
                    [order]="1"
                    (setPackageForm)="savePackageData($event, 1, 'standard')"
                  ></app-package-unit>
                </div>
              </mat-tab>
              <mat-tab label="Premium">
                <div class="mt-6">
                  <app-package-unit
                    [checkPriceValidation]="checkPrice"
                    [order]="2"
                    (setPackageForm)="savePackageData($event, 2, 'premium')"
                  ></app-package-unit>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div class="border-2">
    <div class="w-full flex justify-between items-center px-6 h-16">
      <button mat-flat-button class="button warning black btn-size w-36" (click)="onClose()">
        Cancel
      </button>
      <button
        *ngIf="!isLastStep"
        class="next-btn w-36"
        [class]="!selectedServiceCategory.length ? 'disabled' : ''"
        (click)="goToNext(stepper)"
        [disabled]="!selectedServiceCategory.length"
      >
        {{ 'Next' }}
      </button>

      <button
        *ngIf="isLastStep"
        mat-button
        class="button btn-size w-36"
        [class]="checkServiceValidation() || (data.editmode && !updatedata) ? 'disabled' : ''"
        (click)="saveService()"
        [disabled]="checkServiceValidation() || (data.editmode && !updatedata)"
      >
        {{ data.editmode ? 'Update' : 'Post' }}
      </button>
    </div>
  </div>
</div>

<input
  id="fileInput"
  accept="image/*,video/*"
  #file
  multiple
  type="file"
  class="uploadFile img w-0 h-0 overflow-hidden"
  value="Upload Media"
  (click)="clearFileInput(file)"
  (change)="uploadFile(file.files, imageIndex)"
  style="display: none"
/>
