<!-- POST STORY SECTION -->
<div *ngIf="stories?.length" class="story-container flex gap-5 justify-center">
    <swiper
      [navigation]="true"
      [config]="config"
      class="post-detail-swpier-container filter-swipper feed-story common-arrow posts-swipper"
      (reachEnd)="onStorySlideChange($event)"
    >
      <ng-template swiperSlide>
        <div class="relative cursor-pointer">
          <img
            class="story-image"
            [src]="user?.avatar"
            onerror="this.src='assets/images/logo/bird-logo-no-bg.png'"
          />
          <button class="add-post absolute" (click)="postContent()">+</button>
          <p class="text-style mt-1">Your posts</p>
        </div>
      </ng-template>
      <ng-template *ngFor="let item of stories; trackBy: trackByFunc" swiperSlide>
        <div (click)="postDetail(item)" class="cursor-pointer">
          <img
            *ngIf="item?.contentType == 'moment'"
            class="story-image image_border"
            [src]="item?.contentImage"
          />
          <video
            *ngIf="item?.contentType != 'moment'"
            class="story-image image_border"
            [src]="item?.contentImage+ '#t=0.1'"  preload="metadata"
            loop
            [muted]="'muted'"
            playsinline
            webkit-playsinline
          ></video>
          <p class="text-style mt-1">{{ item?.profile?.user_name }}</p>
        </div>
      </ng-template>
    </swiper>
  </div>
  <!-- POST STORY SECTION END -->
  