<div class="job-card h-full" [class]="isAdmin ? 'admin' : ''">
  <!-- <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header class="job-panel">
      <mat-panel-title>
        <div class="w-full">
          <div class="flex items-start md:items-center w-full md:flex-row flex-col">
            <img [src]="job.img" class="mt-1 lg:mt-0 mr-4" />
            <div
              class="flex pt-4 pb-2 flex-col md:flex-row md:justify-between ml-0 mr-0 md:ml-2.5 md:mr-5 lg:mr-0  w-full">
              <div class="justify-between px-2 md:px-0">
                <h4 class="montserrat-bold black-dark truncate job-title-length-fixed">
                  {{ job.job.job_title | titlecase }}
                </h4>
                <div class="flex lg:items-center lg:flex-row flex-col mt-2">
                  <p class="s montserrat-bold job-company-length-fixed truncate mr-2">{{ job.company.name }} *</p>
                  <div class="flex">
                    <p class="s gray-dark mr-2">
                      {{ job.job.job_type | titlecase }} *
                    </p>
                    <p class="s gray-dark">{{ job.job.salary_range }}</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col lg:mr-6">
                <div class="flex items-center px-2 md:px-0">
                  <mat-icon class="gray-dark pb-0.5" style="
                      font-size: 16px;
                      display: flex;
                      color: #2887ed;
                      align-items: center;
                      width: 20px;
                    ">room</mat-icon>
                  <p *ngIf="job?.job?.location" class="s montserrat-bold">
                    {{ getLocation(job.job.location) }}
                  </p>
                </div>
                <div class="w-full text-right px-2 md:px-0">
                  <p class="mt-0 md:mt-2.5"
                    *ngIf="userType == userRole.candidate || (this.purchasedPlan?.plan_type == planTypeConstant.recruiter && !job?.job?.is_active)"
                    [class]="!job.job.is_active ? 'red' : 'green'">
                    {{ job.job.is_active ? 'Active': 'Deleted' }}
                  </p>
                </div>
              </div>
            </div>
            <img class="absolute lg:relative expand-icon h-full w-3 right-2 -mt-4 lg:right-2.5"
              src="../../../../assets/images/arrow-right.svg" />
          </div>
        </div>
        <div class="back-ground-sky px-2 md:px-0 mt-1 md:mt-0 items-center">
          <p>
            Posted: {{ formatJobDate(job.job.created_at) }}
          </p>
          <p *ngIf="job.job.isApplied">
            Applied: {{ formatJobDate(job.created_at) }}
          </p>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="
    job?.job?.preferred_requirements?.length
  " class="lg:mt-0 mr-2 flex-wrap w-full">
      <p class="s montserrat-bold mr-2">Qualifications:</p><br>
      <div class="advanced-filter-item flex items-center px-2.5 py-2 mb-2 mr-2" *ngFor="
      let preferred_requiremets of job.job.preferred_requirements;
      let i = index
    ">
        <p>
          {{ preferred_requiremets }}
        </p>
      </div>
    </div>
    <hr><br>
    <div class="job-content flex flex-col">
      <p class="s montserrat-bold ">Job Category:</p>
      <p class="py-2">
        <li>{{ job.job.job_category }}</li>
      </p>
      <hr><br>
      <p class="s montserrat-bold " *ngIf="job?.job?.experience_required">Experience Required:</p>
      <p class="py-2">
        <li>{{ job.job.experience_required}}</li>
      </p>
      <hr><br>
      <p class="s montserrat-bold">Job Description:</p>
      <p class="py-2">{{ job.job.job_description }}</p>
      <hr><br>
      <div class="flex flex-col md:flex-row">
        <div class="flex flex-col md:flex-row">
          <button *ngIf="(job.job.isSaved || job.job.isApplied) &&userInfo?.profileInfo?._id !== job?.job?.company_id "
            (click)="messageRecuirter(job.company)" mat-stroked-button class="button outlined black w-full md:w-auto">
            Message recruiter
          </button>
          <button *ngIf="job.job.isSaved && job.job.is_active  && job.company._id !==loginedUserId"
            (click)="applyJob(job)" mat-stroked-button class="button outlined black w-full md:w-auto">
            Apply
          </button>
          <button *ngIf="job.job.isSaved" (click)="removeJob(job,index)" mat-stroked-button
            class="button outlined black w-full md:w-auto">
            Unsave
          </button>
          <button *ngIf="userType == userRole.company && !job.job.isSaved && !job.job.isApplied" mat-stroked-button
            class="button outlined black w-full md:w-auto" (click)="reviewApplicant(job.job._id, job.job.job_title)">
            Review Applicants
          </button>
        </div>
        <div class="ml-0 md:ml-2 mt-2 md:mt-0"
          *ngIf="userType == userRole.company && !job.job.isSaved && !job.job.isApplied">
          <button mat-stroked-button class="button normal blue w-full md:w-auto" (click)="editJob(job.job._id)">
            Edit Job
          </button>
        </div>
        <div class="ml-0 md:ml-2 mt-2 md:mt-0"
          *ngIf="userType == userRole.company && job.job.is_active && !job.job.isSaved && !job.job.isApplied">
          <button mat-stroked-button class="button normal red w-full md:w-auto" (click)="jobRemove(job.job,false)">
            Delete
          </button>
        </div>
        <div class="ml-0 md:ml-2 mt-2 md:mt-0"
          *ngIf="userType == userRole.company && !job.job.is_active && !job.job.isSaved">
          <button mat-stroked-button class="button normal blue w-full md:w-auto" (click)="jobRemove(job.job,true)">
            Active Again
          </button>
        </div>
      </div>
    </div>
  </mat-expansion-panel> -->

  <div class="New-Job-Card" (click)="openApplyDialog(job.job._id)">
    <div class="p-4">
      <span
        class="Newest-Feed px-2 py-1"
        *ngIf="
          userType == userRole.candidate ||
          (this.purchasedPlan?.plan_type == planTypeConstant.recruiter && !job?.job?.is_active)
        "
        [class]="!job.job.is_active ? 'red' : 'blue'"
      >
        {{ job.job.is_active ? 'Active' : 'Deleted' }}</span
      >
      <p class="mt-2 montserrat-bold job-tittle">
        {{ job.job.job_title | titlecase }}
      </p>
      <div class="flex flex-row py-2">
        <div class="thumbnail-img w-full rounded-full border border-black overflow-hidden">
          <img *ngIf="job?.company?.profile_img_path" [src]="job?.company?.profile_img_path" />
          <img
            *ngIf="!job?.company?.profile_img_path"
            src="assets/images/no-image.png"
            alt="No Image Found"
          />
        </div>
        <div class="flex items-center job-Overview">
          <div class="flex flex-wrap">
            <span class="job-location ml-1">{{ job.company.name }} *</span>
          </div>
        </div>
      </div>
      <p class="job-location ml-1 mb-2" *ngIf="job?.job?.location">
        {{ getLocation(job.job.location) }}
      </p>
      <div class="flex flex-wrap">
        <span class="job-price">{{ job.job.job_type | titlecase }} *</span>
        <span class="job-price">{{ job.job.salary_range }}</span>
        <ng-container
          *ngFor="
            let preferred_requiremets of job.job.preferred_requirements.slice(0, 5);
            let i = index
          "
        >
          <span class="job-price">{{ preferred_requiremets }}</span>
          <ng-container *ngIf="i === 4 && job.job.preferred_requirements > 5">
            <span class="job-price">See more</span>
          </ng-container>
        </ng-container>
      </div>
      <div class="flex flex-col md:flex-row md:gap-2 justify-between">
        <span class="mt-2 time-posted">Posted: {{ formatPostDate(job.job.created_at) }}</span>
        <span class="mt-2 time-posted" *ngIf="job.job.isApplied"
          >Applied: {{ formatPostDate(job.created_at) }}</span
        >
        <span
          *ngIf="
            job?.job?.create_by &&
            loginedUserId == job?.job?.company_id &&
            loginedUserId !== job?.job?.create_by?._id
          "
          class="mt-2 time-posted"
          >By: {{ '@' + job?.job?.create_by?.user_name }}</span
        >
      </div>
    </div>
  </div>
</div>
