<div [formGroup]="form">
  <div class="flex items-center">
    <div class="w-full flex items-center p-0.5">
      <mat-icon class="search-icon gray-dark z-10">search</mat-icon>
      <input [(ngModel)]="keywordToSearch" maxlength="256" type="text" placeholder="Search by keyword..."
        class="input-form w-full h-12" autocomplete="off" formControlName="searchInput"
        (keyup)="searchByKeyword($event)" />
    </div>
    <div class="
        border-normal
        rounded
        w-48
        flex
        items-center
        p-3
        lg:ml-5
        h-12
        bg-white
      ">
      <mat-select formControlName="category" class="category" multiple placeholder="Categories">
        <mat-option *ngFor="let category of allCategories" (click)="onCategoryChange()" [value]="category.id">
          {{ category.label }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div *ngIf="
  form?.controls?.searchInput?.value && form?.controls?.searchInput?.value?.length"
    class="mt-2 lg:mt-0 mr-2 flex flex-wrap w-full">
    <div class="advanced-filter-item flex break-all items-center px-2.5 my-2">
      <p class="break-all">
        {{this.form.controls.searchInput.value }}
      </p>
      <button mat-icon-button class="material-icons">
        <mat-icon (click)="removefilter()">clear</mat-icon>
      </button>
    </div>
  </div>
  <div class="scroll grid grid-cols-1 gap-8 md:gap-2 md:grid-cols-3 py-4" id="myapplicationContainer" infiniteScroll [infiniteScrollContainer]="'#myapplicationContainer'"
    [fromRoot]="true" [infiniteScrollThrottle]="50" [infiniteScrollDistance]="0.5" (scrolled)="onScrollDown()">

    <div *ngFor="let job of jobs; let i = index">
      <app-my-job-card [job]="job" [index]="i" [type]="userType" [selectedJobStatus]="selectedJobStatus"
      (remove)="remove($event)" (jobapplied)="jobapplied($event)" (removeCompanyJob)="jobRemove($event)"
      (jobedited)="refreshJob($event)">
    </app-my-job-card>
  </div>
</div>

<div class="spinner-wrapper">
  <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
  </ngx-spinner>
</div>