<!-- Feedback Modal -->
<div class="feedback-modal">
  <div class="absolute left-0 top-0 right-0">
    <div class="w-full flex items-center justify-center header relative">
      <img class="w-10 absolute top-2 left-3" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">
      <p class="montserrat-bold text-white w-fit h-fit">Feedback</p>
      <mat-icon class="cursor-pointer text-white rounded-full z-30 absolute right-2 inset-y-auto" mat-dialog-close>close
      </mat-icon>
    </div>
  </div>
  <div class="modal-content mt-6">
    <div class="modal-body">
      <span class="flex my-4 font-bold">
        Ratings:
        <star-rating
          [starType]="'svg'"
          [hoverEnabled]="true"
          [showHalfStars]="true"
          [rating]="rating"
          (starClickChange)="onRatingChanged($event)"
        ></star-rating>
      </span>

      <!-- Feedback Comment -->
      <span class="flex font-bold"> Add comment: </span>

      <textarea
        [(ngModel)]="feedbackComment"
        placeholder="Add your feedback here..."
      ></textarea>
    </div>
    <div class="flex justify-between">
      <button
        mat-button
        class="button warning black"
        (click)="closeFeedbackModal(false)"
      >
        Cancel
      </button>
      <button
        class="button white"
        [class]="!rating || rating == 0 || isProcessing ? 'disabled' : ''"
        [disabled]="!rating || rating == 0 || isProcessing"
        (click)="submitFeedback()"
      >
        Submit
      </button>
    </div>
  </div>
</div>
