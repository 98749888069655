import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SortOption } from 'src/app/core/const/options';

@Component({
  selector: 'app-all-filters',
  templateUrl: './all-filters.component.html',
  styleUrls: ['./all-filters.component.scss'],
})
export class AllFiltersComponent implements OnInit {
  filterValues: any = {
    selectedDatePosted: null,
    selectedCategories: null,
    selectedJobTypes: null,
    selectedExperienceLevels: null,
    selectedPayRanges: null,
    searchPreferences: null,
  };
  datePosted: any = '';
  category: any = [];
  jobType: any = [];
  experience: any = [];
  payRange: any = [];

  priceRange: any = [];
  serviceCategory: any = [];
  serviceType: any = [];

  sortOption = SortOption;
  isFilterUpdated = false;

  constructor(
    private dialogRef: MatDialogRef<AllFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.filterType == 'jobs') {
      this.datePosted = this.data.form.controls['sortBy'].value
        ? this.data.form.controls['sortBy'].value
        : '';
      this.filterValues.selectedDatePosted =
        this.data.form.controls['sortBy'].value;

      this.filterValues.selectedCategories =
        this.data.form.controls['jobcategory'].value;
      this.category = this.data.form.controls['jobcategory'].value
        ? this.data.form.controls['jobcategory'].value
        : [];

      this.filterValues.selectedJobTypes =
        this.data.form.controls['jobType'].value;
      this.jobType = this.data.form.controls['jobType'].value
        ? this.data.form.controls['jobType'].value
        : [];

      this.filterValues.selectedExperienceLevels =
        this.data.form.controls['jobExperience'].value;
      this.experience = this.data.form.controls['jobExperience'].value
        ? this.data.form.controls['jobExperience'].value
        : [];

      this.filterValues.selectedPayRanges =
        this.data.form.controls['maxsalary'].value;
      this.payRange = this.data.form.controls['maxsalary'].value
        ? this.data.form.controls['maxsalary'].value
        : [];
    } else if (this.data.filterType == 'service') {
      this.priceRange = this.data?.form?.controls['newest']?.value
        ? this.data?.form?.controls['newest']?.value
        : [];
      this.serviceCategory = this.data?.form?.controls['serviceCategory']?.value
        ? this.data?.form?.controls['serviceCategory']?.value
        : [];
      this.serviceType = this.data?.form?.controls['serviceType']?.value
        ? this.data?.form?.controls['serviceType']?.value
        : [];
    }
  }

  close(filter: any) {
    this.dialogRef.close(filter);
  }

  checkboxChanged(filterType?: string, item?: any, event?: any) {
    // Jobs filter changes
    if (filterType == 'category') {
      if (event.checked) {
        this.category.push(item);
      } else {
        const index = this.category.findIndex((obj: any) => obj.id === item.id);
        this.category.splice(index, 1);
      }
      this.filterValues.selectedCategories = this.category?.length
        ? this.category
        : null;
    }

    if (filterType == 'jobType') {
      if (event.checked) {
        this.jobType.push(item);
      } else {
        const index = this.jobType.findIndex((obj: any) => obj.id === item.id);
        this.jobType.splice(index, 1);
      }

      this.filterValues.selectedJobTypes = this.jobType?.length
        ? this.jobType
        : null;
    }

    if (filterType == 'experience') {
      if (event.checked) {
        this.experience.push(item);
      } else {
        const index = this.experience.findIndex(
          (obj: any) => obj.id === item.id
        );
        this.experience.splice(index, 1);
      }

      this.filterValues.selectedExperienceLevels = this.experience?.length
        ? this.experience
        : null;
    }

    if (filterType == 'payRange') {
      if (event.checked) {
        this.payRange.push(item);
      } else {
        const index = this.payRange.findIndex((obj: any) => obj.id === item.id);
        this.payRange.splice(index, 1);
      }

      this.filterValues.selectedPayRanges = this.payRange?.length
        ? this.payRange
        : null;
    }

    // Service filter changes

    if (filterType == 'priceRange') {
      if (event.checked) {
        this.priceRange.push(item?.id);
      } else {
        const index = this.priceRange.indexOf(item.id);
        this.priceRange.splice(index, 1);
      }
    }

    if (filterType == 'serviceCategory') {
      if (event.checked) {
        this.serviceCategory.push(item?._id);
      } else {
        const index = this.priceRange.indexOf(item?._id);
        this.serviceCategory.splice(index, 1);
      }
    }

    if (filterType == 'serviceType') {
      if (event.checked) {
        this.serviceType.push(item.id);
      } else {
        const index = this.serviceType.indexOf(item.id);
        this.serviceType.splice(index, 1);
      }
    }

    setTimeout(() => {
      this.filterValues.selectedDatePosted = this.datePosted?.length
        ? this.datePosted
        : null;

      // this.detectChanges();
    }, 1);
  }

  // detectChanges() {
  //   if (
  //     this.data.form.controls['sortBy'].value !==
  //       this.filterValues.selectedDatePosted ||
  //     this.data.form.controls['jobcategory'].value !==
  //       this.filterValues.selectedCategories ||
  //     this.data.form.controls['jobType'].value !==
  //       this.filterValues.selectedJobTypes ||
  //     this.data.form.controls['jobExperience'].value !==
  //       this.filterValues.selectedExperienceLevels ||
  //     this.data.form.controls['maxsalary'].value !==
  //       this.filterValues.selectedPayRanges
  //   ) {
  //     this.isFilterUpdated = true;
  //   } else {
  //     this.isFilterUpdated = false;
  //   }
  // }

  updateFilters(searchPreferences?:string) {
    if (this.data.filterType == 'service') {
      let serviceFilters = {
        serviceType: this.serviceType,
        serviceCategory: this.serviceCategory,
        priceRange: this.priceRange,
      };
      this.close(
        this.serviceType?.length ||
          this.serviceCategory?.length ||
          this.priceRange?.length
          ? serviceFilters
          : false
      );
    } else {
      this.filterValues.searchPreferences = searchPreferences;
      this.close(
        this.filterValues.selectedDatePosted ||
          this.filterValues.selectedCategories ||
          this.filterValues.selectedJobTypes ||
          this.filterValues.selectedExperienceLevels ||
          this.filterValues.selectedPayRanges ||
          this.filterValues.searchPreferences 
          ? this.filterValues
          : false
      );
    }
  }

  reset() {
    this.datePosted = '';
    this.filterValues = {
      selectedDatePosted: null,
      selectedCategories: null,
      selectedJobTypes: null,
      selectedExperienceLevels: null,
      selectedPayRanges: null,
    };

    this.priceRange = [];
    this.serviceCategory = [];
    this.serviceType = [];
  }

  getCheckedFilters(type: string, item: any) {
    if (type == 'category') {
      return this.filterValues.selectedCategories?.some(
        (p: any) => p.id == item.id
      );
    } else if (type == 'jobType') {
      return this.filterValues.selectedJobTypes?.some(
        (p: any) => p.id == item.id
      );
    } else if (type == 'experience') {
      return this.filterValues.selectedExperienceLevels?.some(
        (p: any) => p.id == item.id
      );
    } else if (type == 'payRange') {
      return this.filterValues.selectedPayRanges?.some(
        (p: any) => p.id == item.id
      );
    } else if (type == 'serviceType') {
      return this.serviceType?.includes(item?.id);
    } else if (type == 'serviceCategory') {
      return this.serviceCategory?.includes(item?._id);
    } else if (type == 'priceRange') {
      return this.priceRange?.includes(item?.id);
    } else {
      return false;
    }
  }

  trackByFunc(i: number) {
    return i;
  }
}
