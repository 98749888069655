<div class="campaign-card relative border-normal rounded cursor-pointer bg-white lg:p-4 flex flex-col lg:flex-row items-center justify-between overflow-hidden">
  <div class="flex flex-col lg:flex-row justify-center items-center p-4 lg:p-0">
    <img [src]="data?.content[0]?.url">
    <div class="ml-4">
      <div class="flex items-center lg:h-4">
        <h3 class="montserrat-bold">· </h3>
        <p class="s ml-0.5">POSTED by {{data?.profile?.name}}</p>
      </div>
      <h4 class="montserrat-bold text-center">{{data?.title | titlecase}}</h4>
      <div class="flex items-center mt-2 lg:mt-0">
        <p >Posted {{data?.created_at | date : 'medium'}} · </p>
      </div>
    </div>
  </div>
  <div class="w-full lg:hidden">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div class="hidden lg:block">
    <mat-icon class="forward-icon">arrow_forward_ios</mat-icon>
  </div>
  <div
    class="py-3 px-4 flex items-center w-full justify-between lg:opacity-0 lg:absolute lg:w-full lg:h-full"
    (click)="showCampaignModal()">
    <p class="s montserrat-bold blue">View details</p>
    <mat-icon class="font-bold forward-icon blue">arrow_forward_ios</mat-icon>
  </div>
</div>
