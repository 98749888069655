<form
  [formGroup]="packageForm"
  class="package-unit"
  onsubmit="submit($event)"
>
  <textarea
    maxlength="1025"
    class="input-form description mt-2 w-full px-4 field-font"
    placeholder="Write Description"
    formControlName="description"
  >
  </textarea>
  <mat-error *ngIf="errorHandling('description', 'maxlength')" class="text-danger pb-2">
    Description cannot exceed more than 1024 characters!
  </mat-error>
  <div class="flex relative items-center mt-2">
    <!-- <div class="absolute left-0 px-2 w-10 h-12 border flex items-center bg-blue-400">
      <mat-icon class="text-white">attach_money</mat-icon>
    </div> -->
    <input
      class="input-form w-full pl-4 pr-4 z-0 field-font"
      style="background: none"
      placeholder="+ Add Price"
      formControlName="price"
    />
  </div>
  <div *ngIf="checkPriceValidation.isInValid">
    <p class="s red">{{ checkPriceValidation.message }}.</p>
  </div>
  <mat-select
    class="input-form mt-2 field-font"
    formControlName="billing_type"
    placeholder="Service Type"
  >
    <mat-option *ngFor="let type of serviceType" [value]="type.id">{{ type.label }} </mat-option>
  </mat-select>
  <div class="border-normal rounded mt-2 w-full">
    <ul *ngIf="packageFeature && packageFeature.length > 0" class="py-1">
      <li class="flex p-2" *ngFor="let item of packageFeature">
        <mat-icon class="remove-button cursor-pointer" (click)="removeFeature(item)"
          >remove_circle</mat-icon
        >
        <p class="mt-0.5 break-all ml-2">{{ item }}</p>
      </li>
    </ul>
    <div class="flex flex-col relative">
      <input
        maxlength="1025"
        #feature
        placeholder="Key Features"
        formControlName="feature"
        class="input-form w-full p-2 pr-24 field-font"
        (keydown)="onFeatureFormChange($event)"
      />
      <mat-error *ngIf="errorHandling('feature', 'maxlength')" class="text-danger pb-2">
        Length cannot exceed more than 1024 characters!
      </mat-error>
      <div class="absolute right-0">
        <button
          *ngIf="
            packageForm.get('feature')?.value?.trim()?.length &&
            !errorHandling('feature', 'maxlength')
          "
          mat-button
          class="button outlined black"
          (click)="addFeature()"
        >
          + Add
        </button>
      </div>
    </div>
  </div>
  <p class="s black-light mt-4">Example: 3 rounds of revisions etc.</p>
</form>
