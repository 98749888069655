<div class="communites-main mx-auto">
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="black-dark"
    size="medium"
    color="red"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    class="job-list-spinner"
  >
  </ngx-spinner>
  <mat-tab-group
    [selectedIndex]="selected"
    (selectedIndexChange)="tabSelect($event)"
    class="communites-tabs"
  >
    <mat-tab label="Trends">
      <ng-template mat-tab-label>
        <div>Trends</div>
      </ng-template>
      <div *ngIf="selected == 0" class="content-wrapper">
        <!-- SUGGESTION CARDS -->
        <div class="flex gap-2 m-2">
          <swiper [config]="config" class="swpier-container discover-swipper common-arrow">
            <ng-template swiperSlide *ngFor="let suggestion of profiles; index as i">
              <div
                (click)="
                  scrollToSection(
                    suggestion.type == 'service'
                      ? '#forYouService'
                      : suggestion.type == 'job'
                      ? '#forYouJob'
                      : '#forYouSubscription'
                  )
                "
                class="background w-full p-5 shadow-lg rounded-md relative"
              >
                <div class="absolute top-0 right-0 p-3 cursor-pointer" (click)="removeSuggested(i)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                    />
                  </svg>
                </div>
                <div class="flex items-center justify-center mb-5">
                  <img
                    *ngIf="suggestion.type == 'job'"
                    [src]="suggestion?.profile_img_path"
                    class="w-11 h-11 rounded-full"
                    onerror="this.src='assets/images/no-image.png'"
                  />
                  <img
                    *ngIf="
                      suggestion.type != 'job' &&
                      (getImage(suggestion)?.media_type == 'moment' ||
                        getImage(suggestion)?.media_type == 'image')
                    "
                    [src]="getImage(suggestion)?.url"
                    class="w-11 h-11 rounded-full"
                    onerror="this.src='assets/images/no-image.png'"
                  />

                  <video
                    *ngIf="suggestion.type != 'job' && getImage(suggestion)?.media_type == 'video'"
                    class="w-11 h-11 rounded-full object-cover"
                    [src]="getImage(suggestion)?.url + '#t=0.1'"
                    preload="metadata"
                    loop
                    [muted]="'muted'"
                    playsinline
                    webkit-playsinline
                  ></video>

                  <img
                    *ngIf="
                      suggestion.type != 'job' &&
                      getImage(suggestion)?.media_type != 'moment' &&
                      getImage(suggestion)?.media_type != 'image' &&
                      getImage(suggestion)?.media_type != 'video'
                    "
                    src="assets/images/no-image.png"
                    class="w-11 h-11 rounded-full"
                  />
                </div>
                <div class="mt-2">
                  <div class="max-w-full overflow-x-visible flex justify-center">
                    <p *ngIf="suggestion.type == 'job'" class="username-font mb-1 truncate">
                      {{ suggestion?.name }}
                    </p>
                    <p *ngIf="suggestion.type == 'service'" class="username-font mb-1 truncate">
                      {{ suggestion?.title }}
                    </p>
                    <p
                      *ngIf="suggestion.type == 'subscription'"
                      class="username-font mb-1 truncate"
                    >
                      {{ suggestion?.title }}
                    </p>
                  </div>
                  <div class="max-w-full overflow-x-visible flex justify-center">
                    <p
                      (click)="navigate('/' + routesConst.profile + '/' + suggestion.user_name)"
                      *ngIf="suggestion.type == 'job'"
                      class="name-font mb-3 truncate cursor-pointer"
                    >
                      @{{ suggestion?.user_name }}
                    </p>
                    <p *ngIf="suggestion.type == 'service'" class="name-font mb-3 truncate">
                      {{ suggestion?.skill_name }}
                    </p>
                    <p
                      (click)="navigate('/' + routesConst.profile + '/' + suggestion.user_name)"
                      *ngIf="suggestion.type == 'subscription'"
                      class="name-font mb-3 truncate cursor-pointer"
                    >
                      @{{ suggestion?.user_name }}
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-center">
                  <span
                    *ngIf="!suggestion?.isFollowing && suggestion.type == 'job'"
                    (click)="followUser(suggestion)"
                    class="follow-btn"
                  >
                    Follow
                  </span>

                  <span
                    *ngIf="suggestion.type == 'service'"
                    class="buy-btn"
                    [routerLink]="'/' + routesConst?.profile + '/' + suggestion.user_name"
                    [queryParams]="{ tab: 3 }"
                  >
                    Buy
                  </span>

                  <span
                    *ngIf="suggestion.type == 'subscription'"
                    class="subscribe-btn"
                    [routerLink]="'/' + routesConst?.profile + '/' + suggestion.user_name"
                    [queryParams]="{ tab: 4 }"
                  >
                    Subscribe
                  </span>
                </div>

                <div *ngIf="suggestion.type == 'job'" class="w-full flex justify-center">
                  <span *ngIf="suggestion?.isFollowing" class="following-btn"> Following </span>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
        <!-- SUGGESTION CARDS END -->

        <!-- <div class="flex gap-2 m-2"> -->
        <div class="top-categories w-full">
          <div class="w-full lg:w-fit mx-auto">
            <swiper [config]="config" class="swpier-container discover-swipper common-arrow">
              <ng-template swiperSlide *ngFor="let category of categories; index as i">
                <div
                  class="category-items cursor-pointer"
                  (click)="scrollToSection(category.id); selectedTrends = category.name"
                >
                  <img
                    class="category-image mb-1 object-cover"
                    [ngClass]="{ 'category-img-position': category.name === 'Services' }"
                    [src]="category.image"
                    alt=""
                  />
                  <p
                    class="category-tittle"
                    [ngClass]="selectedTrends === category.name ? 'selected-treds-title' : ''"
                  >
                    {{ category.name }}
                  </p>
                </div>
              </ng-template>
            </swiper>
          </div>
        </div>
        <div id="TrendsJobs">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">Jobs</h2>
            <button
              mat-button
              (click)="selected = 2"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <div class="grid grid-cols-1 gap-8 md:gap-2 md:grid-cols-3">
            <div *ngFor="let job of jobs.slice(0, 3); let i = index" class="relative">
              <app-job-card
                [isHome]="true"
                [jobList]="job"
                (save)="saveJob(job)"
                (apply)="openApplyDialog(job)"
              ></app-job-card>
              <div
                class="absolute top-0 left-0 right-0 bottom-0 cursor-pointer"
                (click)="jobDetail(job.job._id)"
              ></div>
            </div>
          </div>
        </div>

        <div id="TrendsServices" class="mt-8">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">Services</h2>
            <button
              mat-button
              (click)="selected = 3"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <div class="grid grid-cols-1 gap-8 md:gap-2 md:grid-cols-3">
            <app-talent-card
              *ngFor="let talent of services.slice(0, 3)"
              [talent]="talent"
            ></app-talent-card>
          </div>
        </div>

        <div id="TrendsCreators" class="mt-8 trending-posts">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">Creators</h2>
            <button
              mat-button
              [routerLink]="'/' + routesConst.community"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <div class="home-page-story mb-4 grid grid-cols-1">
            <app-post-story
              [storyPosts]="stories"
              (listEnd)="onStorySlideChange()"
            ></app-post-story>
          </div>
          <div
            class="grid masonary-grid-gap gap-2 grid-cols-1 md:grid-cols-4 homePage-grid trends-creators"
          >
            <div
              class="break-inside rounded-lg h-full home-post"
              *ngFor="let item of creators.slice(0, 4)"
            >
              <app-post-detail-card
                #postDetailCard
                class="h-full block"
                [isHomePost]="true"
                [postID]="item._id"
                [loginedProfile]="user"
                [loginedFollowers]="followers"
                (postByInterest)="getRelatedPosts(item._id)"
                [appInViewLogger]="{
                  type:'post', 
                  postId: item._id,
                  isMobileView: innerWidth < 768,
                  isSingleGrid: innerWidth < 768
                }"
                (viewLogged)="onPostViewLogged($event, postDetailCard)"
              >
              </app-post-detail-card>
            </div>
          </div>
        </div>

        <div id="TrendsSubscriptions" class="mt-8">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">Subscriptions</h2>
            <button
              mat-button
              [routerLink]="'/' + routesConst.community"
              [queryParams]="{ tab: 'subscriptions' }"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <app-individual-service-card
              *ngFor="let plan of subscriptions.slice(0, 3)"
              [routerLink]="'/' + 'profile' + '/' + plan?.profile?.user_name"
              [queryParams]="{ tab: 4 }"
              [service]="plan"
            >
            </app-individual-service-card>
          </div>
        </div>

        <div id="TrendsMembers" class="mt-8">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">Members</h2>
            <button
              mat-button
              [routerLink]="'/' + routesConst.community"
              [queryParams]="{ tab: 'people' }"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <div class="">
            <app-members-panel
              *ngIf="members.length > 0"
              [data]="members.slice(0, 3)"
              [searchTypeVal]="searchTypeVal"
              [layoutMode]="'grid_view'"
              class="w-full"
            ></app-members-panel>
          </div>
        </div>

        <div *ngIf="photos?.length" id="TrendsPhotos" class="mt-8 trending-posts">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">Photos</h2>
            <button
              mat-button
              [routerLink]="'/' + routesConst.community"
              [queryParams]="{ tab: 'moment' }"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <!-- <div class="home-page-story mb-4 grid grid-cols-1">
            <app-post-story
              [storyPosts]="stories"
              (listEnd)="onStorySlideChange()"
            ></app-post-story>
          </div> -->
          <div
            class="grid masonary-grid-gap gap-2 grid-cols-1 md:grid-cols-4 homePage-grid trends-creators"
          >
            <div
              class="break-inside rounded-lg h-full home-post"
              *ngFor="let item of photos.slice(0, 4)"
            >
              <app-post-detail-card
                #postDetailCard
                class="h-full block"
                [isHomePost]="true"
                [postID]="item._id"
                [loginedProfile]="user"
                [loginedFollowers]="followers"
                (postByInterest)="getRelatedPosts(item._id)"
                [appInViewLogger]="{
                  type:'post', 
                  postId: item._id,
                  isMobileView: innerWidth < 768,
                  isSingleGrid: innerWidth < 768
                }"
                (viewLogged)="onPostViewLogged($event, postDetailCard)"
              >
              </app-post-detail-card>
            </div>
          </div>
        </div>

        <div *ngIf="Sec15?.length" id="Trends15Secs" class="mt-8 trending-posts">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">15 Sec</h2>
            <button
              mat-button
              [routerLink]="'/' + routesConst.community"
              [queryParams]="{ tab: 'clip' }"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <!-- <div class="home-page-story mb-4 grid grid-cols-1">
            <app-post-story
              [storyPosts]="stories"
              (listEnd)="onStorySlideChange()"
            ></app-post-story>
          </div> -->
          <div
            class="grid masonary-grid-gap gap-2 grid-cols-1 md:grid-cols-4 homePage-grid trends-creators"
          >
            <div
              class="break-inside rounded-lg h-full home-post"
              *ngFor="let item of Sec15.slice(0, 4)"
            >
              <app-post-detail-card
                #postDetailCard
                class="h-full block"
                [isHomePost]="true"
                [postID]="item._id"
                [loginedProfile]="user"
                [loginedFollowers]="followers"
                (postByInterest)="getRelatedPosts(item._id)"
                [appInViewLogger]="{
                  type:'post',
                  postId: item._id,
                  isMobileView: innerWidth < 768,
                  isSingleGrid: innerWidth < 768
                }"
                (viewLogged)="onPostViewLogged($event, postDetailCard)"
              >
              </app-post-detail-card>
            </div>
          </div>
        </div>

        <div *ngIf="Sec30?.length" id="Trends30Secs" class="mt-8 trending-posts">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">30 Sec</h2>
            <button
              mat-button
              [routerLink]="'/' + routesConst.community"
              [queryParams]="{ tab: 'highlight' }"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <!-- <div class="home-page-story mb-4 grid grid-cols-1">
            <app-post-story
              [storyPosts]="stories"
              (listEnd)="onStorySlideChange()"
            ></app-post-story>
          </div> -->
          <div
            class="grid masonary-grid-gap gap-2 grid-cols-1 md:grid-cols-4 homePage-grid trends-creators"
          >
            <div
              class="break-inside rounded-lg h-full home-post"
              *ngFor="let item of Sec30.slice(0, 4)"
            >
              <app-post-detail-card
                #postDetailCard
                class="h-full block"
                [isHomePost]="true"
                [postID]="item._id"
                [loginedProfile]="user"
                [loginedFollowers]="followers"
                (postByInterest)="getRelatedPosts(item._id)"
                [appInViewLogger]="{
                  type:'post',
                  postId: item._id,
                  isMobileView: innerWidth < 768,
                  isSingleGrid: innerWidth < 768
                }"
                (viewLogged)="onPostViewLogged($event, postDetailCard)"
              >
              </app-post-detail-card>
            </div>
          </div>
        </div>

        <div *ngIf="Sec60?.length" id="Trends60Secs" class="mt-8 mb-16 trending-posts">
          <div class="flex justify-between items-center">
            <h2 class="heading mt-6 mb-4">60 Sec</h2>
            <button
              mat-button
              [routerLink]="'/' + routesConst.community"
              [queryParams]="{ tab: 'trailer' }"
              class="rounded-btn montserrat-medium mt-md button outlined black btn-size"
            >
              View All
            </button>
          </div>
          <!-- <div class="home-page-story mb-4 grid grid-cols-1">
            <app-post-story
              [storyPosts]="stories"
              (listEnd)="onStorySlideChange()"
            ></app-post-story>
          </div> -->
          <div
            class="grid masonary-grid-gap gap-2 grid-cols-1 md:grid-cols-4 homePage-grid trends-creators"
          >
            <div
              class="break-inside rounded-lg h-full home-post"
              *ngFor="let item of Sec60.slice(0, 4)"
            >
              <app-post-detail-card
                #postDetailCard
                class="h-full block"
                [isHomePost]="true"
                [postID]="item._id"
                [loginedProfile]="user"
                [loginedFollowers]="followers"
                (postByInterest)="getRelatedPosts(item._id)"
                [appInViewLogger]="{
                  type:'post',
                  postId: item._id,
                  isMobileView: innerWidth < 768,
                  isSingleGrid: innerWidth < 768
                }"
                (viewLogged)="onPostViewLogged($event, postDetailCard)"
              >
              </app-post-detail-card>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Jobs">
      <ng-template mat-tab-label>
        <div>Jobs</div>
      </ng-template>
      <div class="community-jobs" *ngIf="selected == 1">
        <app-jobs></app-jobs>
      </div>
    </mat-tab>
    <mat-tab label="Services">
      <ng-template mat-tab-label>
        <div>Services</div>
      </ng-template>
      <div class="community-talents" *ngIf="selected == 2">
        <app-talents></app-talents>
      </div>
    </mat-tab>
    <mat-tab label="Creators">
      <ng-template mat-tab-label>
        <div class="md:text-base">Creators</div>
      </ng-template>
      <div class="content-wrapper discover-creators" *ngIf="selected == 3">
        <app-users></app-users>
      </div>
    </mat-tab>
    <mat-tab label="Add">
      <ng-template mat-tab-label>
        <div mat-button class="montserrat-medium black flex" (click)="addInfo()">
          <p class="add-post" (click)="postContent()">+</p>
          <span>Post</span>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
