import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';
import { SharedService } from '../../services/shared.service';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import * as moment from 'moment';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { MentionService } from '../../services/mentions.service';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() set commentInfo(value: any) {
    this.data = value;
  }
  @Input()
  contentType!: string;
  @Input()
  post_id!: string;
  @Input() isFavor!: boolean;
  @Output() likeComment: EventEmitter<any> = new EventEmitter();
  @Output() replyComment: EventEmitter<any> = new EventEmitter();
  @Output() deletedCommentId: EventEmitter<any> = new EventEmitter();
  @Output() hashtagClicked: EventEmitter<any> = new EventEmitter();
  showReplyForm: boolean;
  comment: string = '';
  data: any;
  profile: any;

  selectedComment: any;
  isExpanded: boolean = false;
  commentId: any;
  isEditing = false;
  isReplyEditing = false;
  editedComment: any;
  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private authService: AuthService,
    private postService: PostService,
    private router: Router,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private el: ElementRef,
    public mentionService: MentionService,
    private snack: SnackBarService
  ) {
    this.showReplyForm = false;
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((comment: any) => {
      if (comment?.comment) {
        this.commentId = comment.comment;
        this.isExpanded = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
    }
  }

  ngAfterViewInit() {
    this.el.nativeElement.addEventListener('click', (event: any) => {
      if ((event.target as HTMLElement).classList.contains('hashtag-link')) {
        event.preventDefault();
        this.handleHashtagClick((event.target as HTMLElement).innerText);
      }
    });
  }

  handleHashtagClick(hashtag: string) {
    this.hashtagClicked.emit(hashtag);
  }

  toggleEditMode(comment: any) {
    this.isEditing = true;
    this.editedComment = comment.replace(/<\/?[^>]+(>|$)/g, '');
  }

  async saveEditedComment(comment: any) {
    const editedComment = this.editedComment;
    const payload = { _id: comment._id, comment: editedComment };
    this.postService
      .updateComment(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (res: any) => {
        if (res.status == 'success') {
          this.data.comment = this.editedComment;
          this.isEditing = false;
        } else {
          this.snack.showMessage(res.message, true);
        }
      });
  }

  cancelEditMode() {
    this.isEditing = false;
  }

  processComment(comment: string) {
    return comment.replace(/<\/?[^>]+(>|$)/g, '');
  }

  replyToggleEditMode(comment: any, replier: any) {
    replier.isReplyEditing = true;
    this.editedComment = comment.replace(/<\/?[^>]+(>|$)/g, '');
  }

  async replySaveEditedComment(replier: any) {
    const payload = { _id: replier._id, comment: this.editedComment };
    this.postService
      .updateComment(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status == 'success') {
          replier.comment = this.editedComment; // Update the original comment with the edited comment
          replier.isReplyEditing = false;
        } else {
          this.snack.showMessage(res.message, true);
        }
      });
  }

  replyCancelEditMode(replier: any) {
    replier.isReplyEditing = false;
  }

  extractAlphabets(input: string) {
    const regex = /[a-zA-Z]/g;
    const matches = input.match(regex);
    return matches ? matches.join('') : '';
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.profile = profileInfo;
      }
    });
  }

  onLike() {
    if (this.authService.loggedIn()) {
      this.likeComment.emit(this.data);
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  // triggerFunction(event: any) {
  //   console.log('EVENT',event)
  //   if (event.key=='Shift' && event.key === 'Enter') {
  //     this.comment += '\n';
  //   } else if (event.key === 'Enter') {
  //     event.preventDefault();
  //     this.onReply();
  //   }
  // }

  showCommenterProfile(user: any) {
    this.router.navigate(['/profile' + '/' + user.user_name]);
  }

  selectComment(event: any) {
    this.selectedComment = event;
  }

  onReply() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      if (this.comment?.trim()?.length !== 0) {
        const options = {
          parent_id: this.selectedComment?._id,
          thread_id: this.selectedComment?.thread_id
            ? this.selectedComment?.thread_id
            : this.selectedComment._id,
          comment: this.comment,
          content_id: this.post_id,
          comment_user_id: this.selectedComment?.user_id,
          commentedOn: this.contentType
        };
        this.comment = '';
        this.showReplyForm = false;
        this.postService
          .addComment(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res.status === 'success') {
              this.data = {
                ...this.data,
                replyCommentDetails: [
                  ...this.data.replyCommentDetails,
                  {
                    ...res.data,
                    userInfo: [
                      {
                        email: this.profile.email,
                        profile_img_path: this.profile.avatar,
                        user_name: this.profile.user_name,
                        userType: this.profile.user_type,
                        _id: this.profile._id
                      }
                    ]
                  }
                ]
              };
            } else {
              this.snack.showMessage(res.message, true);
            }
          });
      }
    }
  }

  checkWhitespaces(text: string) {
    return text?.replace(/\s/g, '');
  }

  location(): Location {
    return this._doc.location;
  }

  formatJobDate(date?: any) {
    const postTime = moment(date);
    const now = moment();
    const diffInSeconds = now.diff(postTime, 'seconds');

    if (diffInSeconds < 60) {
      return `${diffInSeconds}s`;
    } else if (diffInSeconds < 3600) {
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      return `${diffInMinutes}m`;
    } else if (diffInSeconds < 86400) {
      const diffInHours = Math.floor(diffInSeconds / 3600);
      return `${diffInHours}h`;
    } else if (diffInSeconds < 604800) {
      const diffInDays = Math.floor(diffInSeconds / 86400);
      return `${diffInDays}d`;
    } else {
      return postTime.format('MMM D');
    }
  }

  deleteComment(comment: any, isReplyDelete: any) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: {
        message: `Are you sure, You want to delete this comment?`,
        header: 'Comment Delete'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.postService
          .removeComment(comment?._id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (isReplyDelete) {
              var indexToRemove = this.data.replyCommentDetails.findIndex(
                (reply: any) => reply._id === comment._id
              );
              // If the index is valid, remove the object from the array
              if (indexToRemove !== -1) {
                this.data.replyCommentDetails.splice(indexToRemove, 1);
              }
            } else {
              this.deletedCommentId.emit(comment?._id);
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
