<div class="image-upload absolute top-0 w-full h-full flex items-center justify-center">
	<label class="portfolio-wrapper w-full h-full flex justify-center montserrat-bold blue cursor-pointer">
		<div
			*ngIf="(portfolio === undefined || portfolio === '') && !loading"
			class="insert-photo-button bg-white rounded-full"
		>
			<mat-icon class="gray-dark">add_a_photo</mat-icon>
		</div>
		<div
			*ngIf="(portfolio !== undefined && portfolio !== '') || loading"
			class="relative portfolio w-full h-full bg-contain bg-no-repeat bg-center"
			[class]="loading ? 'spinner' : ''"
			[ngStyle]="{ 'background-image': 'url(' + portfolio + ')' }"
		></div>
		<input
			#avatar
			multiple
			type="file"
			accept="image/*"
			class="uploadFile img w-0 h-0 overflow-hidden"
			value="Upload Media"
			(change)="avatar.files ? uploadImage(avatar.files) : ''"
		/>
	</label>
</div>
