<div class="flex schedule-modal flex-col bg-white relative">
  <mat-icon class="cursor-pointer m-2 absolute right-2 top-2" mat-dialog-close>cancel</mat-icon>
  <h4 class="montserrat-bold m-3.5 pt-2 px-1">{{data.isPreview?'Edit Event' :'Schedule Event'}}</h4>
  <div class="w-full mt-6">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div class="flex flex-col m-3.5 px-2 py-2.5" *ngFor="let event of data?.events">
    <p class="montserrat-bold">Title</p>
    <input maxlength="257" class="border-normal rounded px-4 py-3 w-full" [(ngModel)]="event.title">
    <mat-error *ngIf="event?.title.length>256" [class]="event?.title.length>256? 'pb-4 pt-4' : ''">
      <p class="s red">Title can not exceed 256 characters!</p>
    </mat-error>
    <mat-error *ngIf="!event?.title.trim().length" [class]="!event?.title.trim().length ? 'pb-4 pt-4' : ''">
      <p class="s red">Title cannot be blank</p>
    </mat-error>
    <p class="montserrat-bold mt-2">Start Date</p>
    <div class="w-full mt-3.5 border-normal rounded">
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput class="form-control" [minDate]="today" (ngModelChange)="updateEndDate(event)" type="text"
          mwlFlatpickr [(ngModel)]="event.start" [altInput]="true" [convertModelValue]="true" [enableTime]="true"
          dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i" placeholder="Not set" />
      </mat-form-field>
    </div>
    <mat-error *ngIf="!event?.start" [class]="!event?.start ? 'pb-4 pt-4' : ''">
      <p class="s red">Start date cannot be blank</p>
    </mat-error>
    <p class="montserrat-bold mt-2">End Date</p>
    <div class="w-full mt-3.5 border-normal rounded">
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>End Date</mat-label>
        <input matInput class="form-control" [minDate]="event.start" type="text" mwlFlatpickr [(ngModel)]="event.end"
          [altInput]="true" [convertModelValue]="true" [enableTime]="true" dateFormat="Y-m-dTH:i" altFormat="F j, Y H:i"
          placeholder="Not set" />
      </mat-form-field>
    </div>
    <mat-error *ngIf="!event?.end" [class]="!event?.end ? 'pb-4 pt-4' : ''">
      <p class="s red">End date cannot be blank</p>
    </mat-error>
    <div class="w-full mt-2">
      <p class="montserrat-bold mt-2">Description</p>
      <textarea maxlength="1025" class="w-full border-normal rounded mt-2 mb-6 px-4"
        [(ngModel)]="event.description"></textarea>
      <mat-error *ngIf="event?.description.length>1024" [class]="event?.description.length>1024? 'pb-4 pt-4' : ''">
        <p class="s red">Description can not exceed 1024 characters!</p>
      </mat-error>
    </div>
    <div class="w-full mt-10 pt-0.5 mb-2">
      <button *ngIf="data.isPreview" mat-button
        [class]="event?.description.length>1024 || event?.title.length>256?'disabled button w-full':'button w-full'"
        [disabled]="event?.description.length>1024 || event?.title.length>256" [disabled]="isProcessing"
        (click)="updateEvent(event)">Update
        Event</button>
      <button *ngIf="!data.isPreview" mat-button
        [class]="event?.description.length>1024 || event?.title.length>256 || isProcessing?'disabled button w-full':'button w-full'"
        [disabled]="event?.description.length>1024 || event?.title.length>256 || isProcessing"
        (click)="saveEvent(event)">Add
        Event</button>
    </div>
    <p class="mt-4 black-light flex justify-center cursor-pointer" mat-dialog-close>Cancel</p>
  </div>
</div>