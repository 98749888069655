<div class="section-wrapper bg-white pt-0 pb-4 lg:p-10 ">
  <p class="text-black montserrat-bold">You’re paid services</p>
  <p class="mt-2 black-light s">See what you bought</p>
  <div class="mt-2">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div class="border-normal rounded p-4 mt-6" *ngFor="let service of purchased_services">
    <p class="montserrat-bold">
      {{ service?.serviceDetails?.title }}
    </p>
    <div class="flex items-start justify-between">
      <div>
        <p class="font-bold text-sm text-gray my-1">
          {{ service?.servicePackagesDetails?.price }}/Monthly
        </p>
        <p class="font-bold text-sm text-gray">
          {{ service?.servicePackagesDetails?.type }}
        </p>
      </div>
      <div class="flex justify-evenly">
        <p *ngIf="!isReviewAllowded(service)" (click)="feedback(service)"
          class="s cursor-pointer font-bold text-sm mt-1.5 blue mr-4 underline">
          {{ "Rate & Review" }}
        </p>
        <p (click)="serviceDetails(service)" class="s cursor-pointer font-bold text-sm mt-1.5 underline"
          [class]="service.isCanceled ? 'red' : 'blue'">{{ service.isCanceled ? "Canceled" : "Details" }}</p>
      </div>
    </div>
  </div>
</div>