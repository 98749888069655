import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ImageService,
  ImageSnippet,
} from 'src/app/core/services/image.service';
@Component({
  selector: 'app-subscription-content-upload',
  templateUrl: './subscription-content-upload.component.html',
  styleUrls: ['./subscription-content-upload.component.scss'],
})
export class SubscriptionContentUploadComponent implements OnInit {
  @Input() serviceData?: any[];
  @Input() portfolions?: any[];
  @Input() index?: number;
  @Input() isSubscriptionModel: boolean = false;
  @Output() setImgUrl: EventEmitter<any> = new EventEmitter();
  @Output() isImageUploaded: EventEmitter<any> = new EventEmitter();
  portfolio: any | undefined;
  loading: boolean;
  image!: File;
  contentType!: string;

  constructor(private imageService: ImageService) {
    this.portfolio = '';
    this.loading = false;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.portfolio = changes['portfolions']?.currentValue
      ? changes['portfolions']?.currentValue
      : '';
    this.setImgUrl.emit(this.portfolio);
  }

  uploadImage(files: FileList) {
    if (files.length === 0) {
      return;
    }
    const file: File = files[0];
    this.loading = true;
    this.image = file;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    let media_type = '';

    reader.addEventListener('load', (event: any) => {
      this.imageService
        .imageConvertion(new ImageSnippet(event.target.result, file))
        .then((imageUri: any) => {
          media_type = files[0].type.includes('video') ? 'video' : 'image';

          const contentObject = {
            cover_type: this.index == 0,
            media_type: media_type,
            url: imageUri[0],
          };
          this.portfolio = contentObject;
          this.loading = false;
          this.setImgUrl.emit(this.portfolio);
          this.isImageUploaded.emit(true);
        })
        .catch(() => {});
    });
  }
}
