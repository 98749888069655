import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { statusConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { ProfileService } from 'src/app/core/services/profile.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { StripePaymentCardComponent } from 'src/app/shared/components/stripe-payment-card/stripe-payment-card.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-cards-history',
  templateUrl: './cards-history.component.html',
  styleUrls: ['./cards-history.component.scss']
})
export class CardsHistoryComponent implements OnInit {
  private unsubscribe$ = new Subject();
  displayedColumns: string[] = ['brand', 'last4', 'expiry', 'isDefault', 'makeDefault', 'delete'];
  dataSource: any;

  constructor(
    private stripePaymentService: paymentService,
    private spinner: NgxSpinnerService,
    private snack: SnackBarService,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getPaymentMethods();
  }

  getPaymentMethods() {
    this.spinner.show();

    let paymentMethods = this.stripePaymentService.paymentMethods();
    let settings = this.stripePaymentService.accountSettings();
    forkJoin([paymentMethods, settings]).subscribe((response: any) => {
      const { data } = response[0].list;
      const default_payment_method =
        response[1]?.settings?.invoice_settings?.default_payment_method;
      const result = data.map((paymentMethods: any) => ({
        brand: paymentMethods.card.brand,
        last4: paymentMethods.card.last4,
        expiry: `${paymentMethods.card.exp_month}/${paymentMethods.card.exp_year
          .toString()
          .substr(-2)}`,
        isPrimary: paymentMethods.id === default_payment_method ? true : false,
        id: paymentMethods.id
      }));
      this.dataSource = result;
      this.spinner.hide();
    });
  }

  makeDefault(paymentMethod: any) {
    this.spinner.show();
    this.stripePaymentService.makeCardPrimary(paymentMethod.id).subscribe(() => {
      this.snack.showMessage('Marked as Default', false);
      this.getPaymentMethods();
    });
  }

  deletePaymentMethod(paymentMethod: any) {
    this.spinner.show();
    this.stripePaymentService.deletePaymentMethod(paymentMethod.id).subscribe(() => {
      this.getPaymentMethods();
      this.snack.showMessage('Card Deleted', false);
    });
  }

  addNewCard() {
    // this.spinner.show();
    // this.profileService
    //   .getOnBoardStatus()
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe((user: any) => {
    //     this.spinner.hide();
    //     if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
    //       const dialogRef = this.dialog.open(ConfirmModalComponent, {
    //         width: '500px',
    //         data: {
    //           message: `Complete one time Stripe onboarding
    //         to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
    //           header: 'Stripe Onboarding'
    //         }
    //       });
    //       dialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //           localStorage.setItem(
    //             'purchase_plan_type',
    //             JSON.stringify({ plan_type: 'Recruiter' })
    //           );
    //           this.router.navigate(['/' + routesConst.planPricing]);
    //         }
    //       });
    //     } else {
          const dialogRef = this.dialog.open(StripePaymentCardComponent, {
            width: '536px',
            height: '200px',
            maxWidth: '100vw',
            hasBackdrop: false
          });
          dialogRef.afterClosed().subscribe(saved => {
            if (saved) {
              this.getPaymentMethods();
            }
          });
        // }
      // });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
