<ngx-spinner  bdOpacity="0.9" bdColor="black-dark" size="medium" color="red"
    type="ball-spin-clockwise" [fullScreen]="true" class="job-list-spinner">
</ngx-spinner>
    <div *ngIf="liveStreams?.length"
        class="masonry-col p-4 sm:p-0 box-border mx-auto before:box-inherit after:box-inherit mt-20 lg:mt-5">
        <div *ngFor="let stream of liveStreams " class="user-card flex flex-col relative px-2 py-2.5 mb-4"
            (click)="openVideo(stream.url)">
            <div class="flex items-center">
            </div>
            <div class="flex relative">
                <img [src]="getStreamImage(stream?.thumbnail_url)"
                    class="cursor-pointer post-img" />
            </div>
            <p *ngIf="stream?.title" class="montserrat-bold">{{ stream.title }}</p>
            <p *ngIf="stream?.description" >{{ stream.description }}</p>
        </div>
    </div>
    <div *ngIf="!liveStreams?.length">
        <h2 class="flex justify-center p-5">No Live Streams Available</h2>
    </div>
