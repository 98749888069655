<div class="discover-main">
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="black-dark"
    size="medium"
    color="red"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    class="job-list-spinner"
  >
  </ngx-spinner>
  <mat-tab-group
    [selectedIndex]="selected"
    class="discover-tabs"
    (selectedIndexChange)="tabSelect($event)"
  >
    <mat-tab label="For You">
      <ng-template mat-tab-label>
        <div class="md:text-base">For you</div>
      </ng-template>
      <div class="content-wrapper">
        <!-- SUGGESTION CARDS -->
        <div class="flex gap-2 m-2">
          <swiper
            [config]="config"
            (reachEnd)="onSlideChange($event)"
            class="swpier-container discover-swipper common-arrow posts-swipper"
          >
            <ng-template swiperSlide *ngFor="let suggestion of profiles; index as i">
              <div class="background w-full p-5 shadow-lg rounded-md relative">
                <div class="absolute top-0 right-0 p-3 cursor-pointer" (click)="removeSuggested(i)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                    />
                  </svg>
                </div>
                <div
                  class="flex items-center justify-center mb-5"
                  routerLink="{{ '/' + routesConst.profile + '/' + suggestion.user_name }}"
                >
                  <img
                    [src]="suggestion?.profile_img_path"
                    class="w-14 h-14 rounded-full"
                    onerror="this.src='assets/images/no-image.png'"
                  />
                </div>
                <div class="mt-2">
                  <div class="max-w-full overflow-x-visible flex justify-center">
                    <p class="username-font mb-1 truncate">
                      {{ suggestion?.user_name }}
                    </p>
                  </div>
                  <div class="max-w-full overflow-x-visible flex justify-center">
                    <p class="name-font mb-3 truncate">
                      {{ suggestion?.name }}
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-center">
                  <span
                    *ngIf="!suggestion?.networkMatchScore"
                    (click)="followUser(suggestion)"
                    class="follow-btn"
                  >
                    Follow
                  </span>
                </div>

                <div class="w-full flex justify-center">
                  <span *ngIf="suggestion?.networkMatchScore" class="following-btn">
                    Following
                  </span>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
        <!-- SUGGESTION CARDS END -->

        <h2 class="heading mt-6 mb-4">Suggested for you</h2>
        <div class="block md:grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div
            *ngFor="let post of trendPosts.slice(0, 6)"
            class="trend-section relative flex gap-2 items-center border rounded-lg"
          >
            <img
              (click)="tabsNavigation(3)"
              *ngIf="post?.content?.media_type === 'moment'"
              [src]="post?.content?.url"
              class="min-w-20 w-20 h-20 img-border cursor-pointer"
              onerror="this.src='assets/images/no-image.png'"
            />
            <video
              playsinline
              (click)="tabsNavigation(3)"
              class="min-w-20 w-20 h-20 img-border cursor-pointer"
              *ngIf="post?.content?.media_type !== 'moment'"
              [src]="post?.content?.url + '#t=0.1'"
              preload="metadata"
              [muted]="'muted'"
            ></video>
            <div class="info-block w-full">
              <div class="flex justify-between items-center gap-1">
                <p
                  class="pre-description mt-1 cursor-pointer"
                  [routerLink]="'/' + routesConst.community + '/post' + '/' + post?._id"
                >
                  {{ post?.description }}
                </p>
                <span [matMenuTriggerFor]="menu" class="flex items-centercursor-pointer">
                  <mat-icon>more_vert</mat-icon>
                </span>
              </div>
              <div class="flex justify-between items-center mt-2">
                <div class="flex items-center gap-1">
                  <img
                    [src]="post?.profile?.profile_img_path"
                    class="w-6 h-6 rounded-full border"
                    onerror="this.src='assets/images/no-image.png'"
                  />
                  <p
                    class="description cursor-pointer"
                    [routerLink]="'/' + routesConst?.profile + '/' + post?.profile?.user_name"
                  >
                    @{{ post?.profile?.user_name }}
                  </p>
                </div>
                <p class="description">{{ post?.created_at | date }}</p>
              </div>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="showProfile(post)">
                <mat-icon style="color: #2887ed">person</mat-icon>
                <span>Profile</span>
              </button>
              <button mat-menu-item (click)="readMessage(post?.profile)">
                <mat-icon style="color: #2887ed">chat</mat-icon>
                <span>Message</span>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="exploreMenu">
                <mat-icon style="color: #2887ed; transform: rotate(-45deg)">send</mat-icon>
                <span>Share</span>
              </button>
              <mat-menu #exploreMenu="matMenu">
                <div class="grid grid-cols-3 gap-2 cursor-pointer">
                  <div class="flex flex-col items-center">
                    <button
                      (click)="copyLink(post?._id)"
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
                    >
                      <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
                    </button>
                    <span class="text-xs">Copy</span>
                  </div>
                  <div class="flex flex-col items-center">
                    <button
                      (click)="share()"
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2"
                    >
                      <mat-icon>share</mat-icon>
                    </button>
                    <span class="text-xs">Share</span>
                  </div>
                  <div class="flex flex-col items-center">
                    <button
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-red-400 p-1"
                    >
                      <mat-icon class="text-red-400">close</mat-icon>
                    </button>
                    <span class="text-xs text-red-400">Cancel</span>
                  </div>
                </div>
              </mat-menu>
            </mat-menu>
          </div>
        </div>
        <div *ngIf="posts?.length">
          <h2 class="heading mt-6 mb-4">Posts</h2>

          <div class="block md:grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div
              *ngFor="let post of posts.slice(0, 6)"
              class="trend-section relative flex gap-2 items-center border rounded-lg"
            >
              <img
                (click)="tabsNavigation(3)"
                *ngIf="post?.content?.media_type === 'moment'"
                [src]="post?.content?.url"
                class="min-w-20 w-20 h-20 img-border cursor-pointer"
                onerror="this.src='assets/images/no-image.png'"
              />
              <video
                playsinline
                (click)="tabsNavigation(3)"
                class="min-w-20 w-20 h-20 img-border cursor-pointer"
                *ngIf="post?.content?.media_type !== 'moment'"
                [src]="post?.content?.url + '#t=0.1'"
                preload="metadata"
                [muted]="'muted'"
              ></video>
              <div class="info-block w-full">
                <div class="flex items-center gap-2 justify-between">
                  <p
                    class="pre-description mt-1 cursor-pointer"
                    [routerLink]="'/' + routesConst.community + '/post' + '/' + post?._id"
                  >
                    {{ post?.description }}
                  </p>
                  <span [matMenuTriggerFor]="menu" class="flex items-center cursor-pointer">
                    <mat-icon>more_vert</mat-icon>
                  </span>
                </div>
                <div class="flex items-center justify-between gap-1 mt-1">
                  <div class="flex items-center gap-1">
                    <img
                      [src]="post?.profile?.profile_img_path"
                      class="w-6 h-6 rounded-full border"
                      onerror="this.src='assets/images/no-image.png'"
                    />
                    <p
                      class="description cursor-pointer"
                      [routerLink]="'/' + routesConst?.profile + '/' + post?.profile?.user_name"
                    >
                      @{{ post?.profile?.user_name }}
                    </p>
                  </div>
                  <p class="description">{{ post?.created_at | date }}</p>
                </div>
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="showProfile(post)">
                  <mat-icon style="color: #2887ed">person</mat-icon>
                  <span>Profile</span>
                </button>
                <button mat-menu-item (click)="readMessage(post?.profile)">
                  <mat-icon style="color: #2887ed">chat</mat-icon>
                  <span>Message</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="exploreMenu">
                  <mat-icon style="color: #2887ed; transform: rotate(-45deg)">send</mat-icon>
                  <span>Share</span>
                </button>
                <mat-menu #exploreMenu="matMenu">
                  <div class="grid grid-cols-3 gap-2 cursor-pointer">
                    <div class="flex flex-col items-center">
                      <button
                        (click)="copyLink(post?._id)"
                        class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
                      >
                        <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
                      </button>
                      <span class="text-xs">Copy</span>
                    </div>
                    <div class="flex flex-col items-center">
                      <button
                        (click)="share()"
                        class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2"
                      >
                        <mat-icon>share</mat-icon>
                      </button>
                      <span class="text-xs">Share</span>
                    </div>
                    <div class="flex flex-col items-center">
                      <button
                        class="w-10 h-10 flex items-center justify-center rounded-full border border-red-400 p-1"
                      >
                        <mat-icon class="text-red-400">close</mat-icon>
                      </button>
                      <span class="text-xs text-red-400">Cancel</span>
                    </div>
                  </div>
                </mat-menu>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Trends">
      <ng-template mat-tab-label>
        <div class="md:text-base">Trends</div>
      </ng-template>
      <div *ngIf="selected == 1" class="content-wrapper">
        <!-- SUGGESTION CARDS -->
        <div class="flex gap-2 m-2">
          <swiper
            [config]="config"
            (reachEnd)="onSlideChange($event)"
            class="swpier-container discover-swipper common-arrow posts-swippwer"
          >
            <ng-template swiperSlide *ngFor="let suggestion of profiles; index as i">
              <div class="background w-full p-5 shadow-lg rounded-md relative">
                <div class="absolute top-0 right-0 p-3 cursor-pointer" (click)="removeSuggested(i)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="12"
                    viewBox="0 0 384 512"
                  >
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                    />
                  </svg>
                </div>
                <div
                  class="flex items-center justify-center mb-5"
                  routerLink="{{ '/' + routesConst.profile + '/' + suggestion.user_name }}"
                >
                  <img
                    [src]="suggestion?.profile_img_path"
                    class="w-14 h-14 rounded-full"
                    onerror="this.src='assets/images/no-image.png'"
                  />
                </div>
                <div class="mt-2">
                  <div class="max-w-full overflow-x-visible flex justify-center">
                    <p class="username-font mb-1 truncate">
                      {{ suggestion?.user_name }}
                    </p>
                  </div>
                  <div class="max-w-full overflow-x-visible flex justify-center">
                    <p class="name-font mb-3 truncate">
                      {{ suggestion?.name }}
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-center">
                  <span
                    *ngIf="!suggestion?.networkMatchScore"
                    (click)="followUser(suggestion)"
                    class="follow-btn"
                  >
                    Follow
                  </span>
                </div>

                <div class="w-full flex justify-center">
                  <span *ngIf="suggestion?.networkMatchScore" class="following-btn">
                    Following
                  </span>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
        <!-- SUGGESTION CARDS END -->

        <!-- <div *ngIf="selected == 1">
          <app-post-story
            [storyPosts]="stories"
            (listEnd)="onStorySlideChange()"
          ></app-post-story>
        </div> -->
        <h2 class="heading mt-6 mb-4">Popular trends</h2>

        <div class="block md:grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div
            *ngFor="let post of trendPosts.slice(0, 6)"
            class="trend-section relative flex items-center border rounded-lg gap-2"
          >
            <img
              (click)="tabsNavigation(3)"
              *ngIf="post?.content?.media_type === 'moment'"
              [src]="post?.content?.url"
              class="min-w-20 w-20 h-20 img-border cursor-pointer"
              onerror="this.src='assets/images/no-image.png'"
            />
            <video
              playsinline
              (click)="tabsNavigation(3)"
              class="min-w-20 w-20 h-20 img-border"
              *ngIf="post?.content?.media_type !== 'moment'"
              [src]="post?.content?.url + '#t=0.1'"
              preload="metadata"
              [muted]="'muted'"
            ></video>

            <div class="info-block">
              <div class="flex items-center justify-between">
                <p
                  class="pre-description mt-1 cursor-pointer"
                  [routerLink]="'/' + routesConst.community + '/post' + '/' + post?._id"
                >
                  {{ post?.description }}
                </p>
                <span [matMenuTriggerFor]="menu" class="flex items-center cursor-pointer">
                  <mat-icon>more_vert</mat-icon>
                </span>
              </div>
              <div class="flex justify-between items-center mt-1 gap-1">
                <div class="flex items-center gap-2">
                  <img
                    [src]="post?.profile?.profile_img_path"
                    class="w-6 h-6 rounded-full border"
                    onerror="this.src='assets/images/no-image.png'"
                  />
                  <p
                    class="description cursor-pointer"
                    [routerLink]="'/' + routesConst?.profile + '/' + post?.profile?.user_name"
                  >
                    @{{ post?.profile?.user_name }}
                  </p>
                </div>
                <p class="description">{{ post?.created_at | date }}</p>
              </div>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="showProfile(post)">
                <mat-icon style="color: #2887ed">person</mat-icon>
                <span>Profile</span>
              </button>
              <button mat-menu-item (click)="readMessage(post?.profile)">
                <mat-icon style="color: #2887ed">chat</mat-icon>
                <span>Message</span>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="exploreMenu">
                <mat-icon style="color: #2887ed; transform: rotate(-45deg)">send</mat-icon>
                <span>Share</span>
              </button>
              <mat-menu #exploreMenu="matMenu">
                <div class="grid grid-cols-3 gap-2 cursor-pointer">
                  <div class="flex flex-col items-center">
                    <button
                      (click)="copyLink(post?._id)"
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
                    >
                      <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
                    </button>
                    <span class="text-xs">Copy</span>
                  </div>
                  <div class="flex flex-col items-center">
                    <button
                      (click)="share()"
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2"
                    >
                      <mat-icon>share</mat-icon>
                    </button>
                    <span class="text-xs">Share</span>
                  </div>
                  <div class="flex flex-col items-center">
                    <button
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-red-400 p-1"
                    >
                      <mat-icon class="text-red-400">close</mat-icon>
                    </button>
                    <span class="text-xs text-red-400">Cancel</span>
                  </div>
                </div>
              </mat-menu>
            </mat-menu>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Posts">
      <ng-template mat-tab-label>
        <div class="md:text-base">Posts</div>
      </ng-template>
      <div class="content-wrapper mt-2 mx-auto">
        <!-- POST STORY SECTION -->
        <div *ngIf="selected == 2" class="max-w-[630px] mx-auto px-4">
          <app-post-story [storyPosts]="stories" (listEnd)="onStorySlideChange()"></app-post-story>
        </div>
        <!-- POST STORY SECTION END -->
        <!-- <h2 class="heading post-trend">Trends</h2> -->

        <div
          #postsSection
          infinite-scroll
          [infiniteScrollContainer]="postsSection"
          [infiniteScrollThrottle]="50"
          [infiniteScrollDistance]="0.5"
          (scrolled)="ScrollDown()"
          class="box-border post-detail-wrapper before:box-inherit after:box-inherit pr-1"
          id="postsSection"
        >
          <div class="stories-wrapper mx-auto px-4">
            <div class="break-inside rounded-lg" *ngFor="let item of mostViewdPosts">
              <app-post-detail-card
                #postDetailCard
                *ngIf="interestList?.length"
                [postID]="item._id"
                [loginedProfile]="user"
                [loginedFollowers]="followers"
                [interestList]="interestList"
                [appInViewLogger]="{
                  type:'post',
                  postId: item._id,
                  isMobileView: innerWidth < 768,
                  isSingleGrid: true
                }"
                (postByInterest)="getRelatedPosts($event, true)"
                (viewLogged)="onPostViewLogged($event, postDetailCard)"
              >
              </app-post-detail-card>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Creators">
      <ng-template mat-tab-label>
        <div class="md:text-base">Creators</div>
      </ng-template>
      <div class="content-wrapper discover-creators" *ngIf="selected == 3">
        <app-users></app-users>
      </div>
    </mat-tab>
    <mat-tab label="Add">
      <ng-template mat-tab-label>
        <button
          mat-button
          class="montserrat-medium mt-md button outlined black"
          (click)="addNewCategory()"
        >
          + Add
        </button>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
