<app-part-header [data]="partFeaturesHeaderData"></app-part-header>
<!-- how it works -->

<div class="howitworks-section m-auto">
  <div>
    <div id="comparePlans" class="text-center">
      <p
        class="montserrat-bold black-dark text-2xl md:text-3xl comparePlans-text"
      >
        How It Works
      </p>
      <p class="montserrat-medium black-dark my-4">
        Unlimited Potential
      </p>
    </div>
    <div class="flex mt-3 mb-8 justify-center">
      <div class="p-1 flex items-center bg-gray rounded-full">
        <button
          class="payment-section_tab border-plus-text bg-gray payment-section_tab px-4 py-2 rounded-full mr-4"
          [class]="forIndividual ? 'bg-blu shadow-lg' : ''"
          (click)="forIndividual = true"
        >
          <p
            class="montserrat-medium"
            [class]="!forIndividual ? 'gray-dark' : 'black-dark'"
          >
            For Individuals
          </p>
        </button>
        <button
          class="payment-section_tab payment-section_tab bg-gray border-plus-text payment-section_tab px-4 py-2 rounded-full"
          [class]="!forIndividual ? 'bg-blu shadow-lg' : ''"
          (click)="forIndividual = false"
        >
          <p
            class="montserrat-medium"
            [class]="forIndividual ? 'gray-dark' : 'black-dark'"
          >
            For Companies
          </p>
        </button>
      </div>
    </div>
  </div>

  <!-- FOR INDIVIDUAL AND LAPTOP -->
  <div
    *ngIf="forIndividual && innerWidth && innerWidth >= 768"
    class="hidden md:block"
  >
    <div class="referal-cards mx-auto">
      <div
        *ngFor="let item of individualHowItWorks" [routerLink]="item.route"
        class="flex flex-col items-center card-style cursor-pointer"
      >
        <div>
          <img class="mb-3 howitworks-icon" [src]="item.icon" alt="" />
          <p class="text-lg title">
            {{ item.heading }}
          </p>
          <div class="mt-1">
            <p class="montserrat-medium black-dark description">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FOR INDIVIDUAL AND MOBILE -->
  <div
    *ngIf="forIndividual && innerWidth && innerWidth < 768"
    class="grid grid-cols-1 card-height"
  >
    <div class="grid mx-auto md:px-4 lg:px-0">
      <swiper [pagination]="{ clickable: true }" [config]="config" class="howitworks-swipperr">
        <ng-template
          swiperSlide
          *ngFor="let item of individualHowItWorks"
        >
          <div [routerLink]="item.route"
            class="flex flex-col items-center card-style cursor-pointer"
          >
            <div>
              <img class="mb-3 howitworks-icon" [src]="item.icon" alt="" />
              <p class="text-lg title">
                {{ item.heading }}
              </p>
              <div class="mt-1">
                <p class="montserrat-medium black-dark description">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>

  <!-- FOR COMPANY AND MOBILE -->
  <div
    *ngIf="!forIndividual && innerWidth && innerWidth < 768"
    class="grid grid-cols-1 card-height"
  >
    <div class="grid mx-auto md:px-4 lg:px-0">
      <swiper [pagination]="{ clickable: true }" [config]="config" class="howitworks-swipperr">
        <ng-template
          swiperSlide
          *ngFor="let item of companyHowItWorks"
        >
          <div [routerLink]="item.route"
            class="flex flex-col items-center card-style cursor-pointer"
          >
            <div>
              <img class="mb-3 howitworks-icon" [src]="item.icon" alt="" />
              <p class="text-lg title">
                {{ item.heading }}
              </p>
              <div class="mt-1">
                <p class="montserrat-medium black-dark description">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>

  <!-- FOR COMPANY AND LAPTOP -->
  <div *ngIf="!forIndividual && innerWidth && innerWidth >= 768">
    <div class="referal-cards mx-auto">
      <div
        *ngFor="let item of companyHowItWorks" [routerLink]="item.route"
        class="flex flex-col items-center card-style cursor-pointer"
      >
        <div>
          <img class="mb-3 howitworks-icon" [src]="item.icon" alt="" />
          <p class="text-lg title">
            {{ item.heading }}
          </p>
          <div class="mt-1">
            <p class="montserrat-medium black-dark description">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="outlin-rounded-btn w-full mt-4 md:w-fit mx-auto block">Get Started</button>
</div>
<!-- how it works end -->
  <app-part-intro></app-part-intro>


<app-part-home [data]="partFeatures"></app-part-home>

<!-- REFRRALS SECTION -->
<div class="main-referrals">
  <div class="main-referrals-wrapper">
     <div class="flex justify-between items-center mb-4">
       <div>
         <h3 class="heading">Referrals</h3>
         <p class="heading-underline"></p>
       </div>
       <button
         class="flex view-all-button hidden md:block"
         [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
       >
         Get Started
       </button>
     </div>
  
     <!-- LAPTOP SCREEN -->
     <div *ngIf="innerWidth && innerWidth >= 768" class="grid grid-cols-1">
       <div class="referal-cards mx-auto md:px-4 lg:px-0">
         <div
           *ngFor="let item of Refferal"
           class="flex flex-col items-center card-style cursor-pointer"
           [routerLink]="
             '/' + routesConst.dashboard + '/' + routesConst.referrals
           "
         >
           <div>
             <p class="text-center text-lg title" *ngIf="item?.title">
               {{ item.title }}
             </p>
             <div class="mt-4">
               <p class="description text-center" *ngIf="item?.description">
                 {{ item.description }}
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
     <!-- MOBILE SCREEN -->
     <div *ngIf="innerWidth && innerWidth < 768" class="grid grid-cols-1">
       <div class="grid mx-auto md:px-4 lg:px-0">
         <swiper [pagination]="{ clickable: true }" [config]="config" class="howitworks-swipperr">
           <ng-template *ngFor="let item of Refferal" swiperSlide>
             <div
               class="flex flex-col items-center card-style cursor-pointer"
               [routerLink]="
                 '/' + routesConst.dashboard + '/' + routesConst.referrals
               "
             >
               <div>
                 <p class="text-center text-lg title" *ngIf="item?.title">
                   {{ item.title }}
                 </p>
                 <div class="mt-4">
                   <p class="description text-center" *ngIf="item?.description">
                     {{ item.description }}
                   </p>
                 </div>
               </div>
             </div>
           </ng-template>
         </swiper>
       </div>
     </div>
     <button
       class="view-all-button button flex md:hidden w-full mt-4"
       [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
     >
       Get Started
     </button>
  </div>
</div>

<app-part-home [data]="partFeatures2"></app-part-home>

<!-- <app-pakages-card [data]="items"></app-pakages-card> -->

<!-- PACKAGES SECTION -->

<div class="m-w mx-auto md:px-4 lg:px-0">
    <div *ngFor="let item of items" class="hidden md:flex flex-col items-center card-style">
      <img class="cursor-pointer h-16" (click)="navigate(item)" [src]="item.logo" />
      <div class="mt-2">
        <p class=" text-center text-lg title">{{ item.title }}</p>
        <div class="mt-4">
          <p class="description text-center">
            {{ item.description }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex md:hidden">
      <swiper [pagination]="{ clickable: true }" [config]="config" class="howitworks-swipperr">
        <ng-template
          swiperSlide
          *ngFor="let item of items"
        >
        <div  class="flex flex-col items-center card-style">
          <img class="cursor-pointer h-16" (click)="navigate(item)" [src]="item.logo" />
          <div class="mt-2">
            <p class=" text-center text-lg title">{{ item.title }}</p>
            <div class="mt-4">
              <p class="description text-center">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
        </ng-template>
      </swiper>
    </div>
  </div>
  
  
  
  
  
  
  
  





<div class="footer-wrapper">
<app-part-footer></app-part-footer>
</div>