<mat-card [formGroup]="userForm">
  <h2>Job Location</h2>
  <p>Let us know your preferred job location.</p>

  <mat-form-field class="w-full mat-location-input">
    <mat-label>City</mat-label>
    <input (keyup)='onLocationChange($event)' placeholder="Type City" type="text" #locationSearchInput
      aria-label="Location" matInput [formControl]="myControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions"
        value="{{option.city_name}} - {{option.state_name}} - {{option.country_code}}" (click)="setLocation(option)">
        {{ option.city_name }}{{ option.state_name ? ' - ' + option.state_name : '' }} {{
        option.country_code ? ' - ' + option.country_code : '' }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <h2 class="mt-4">Desired Salary</h2>
  <p>Choose your preferred weekly pay range amount.</p>
  <mat-select formControlName="desired_salary" (selectionChange)="setPayRange()" multiple placeholder="Salary">
    <mat-option *ngFor="let pay of payRanges" [value]="pay.id">{{ pay.label }}
    </mat-option>
  </mat-select>

  <h2 class="mt-4">Job Type</h2>
  <p>Choose your preferred job type.</p>
  <div class="flex flex-wrap justify-center gap-2 mx-auto mt-5 lg:mt-10">
    <div *ngFor="let item of types; trackBy: trackByFunc" (click)="item.active = !item.active; update(types,item)">
      <button class="tag outlined interest-tag" [class]="item.active ? 'filled' : ''">
        {{ item.name }}
      </button>
    </div>
  </div>

  <h2 class="mt-4">Experience Level</h2>
  <p>Select your experience level.</p>
  <div class="flex flex-wrap justify-center gap-2 mx-auto mt-5 lg:mt-10">
    <div *ngFor="let item of experience; trackBy: trackByFunc" (click)="item.active = !item.active; update(experience,item)">
      <button class="tag outlined interest-tag" [class]="item.active ? 'filled' : ''">
        {{ item.name }}
      </button>
    </div>
  </div>

  <h2 class="mt-4">Job Categories</h2>
  <p>Select job categories that interest you.</p>
  <div class="flex flex-wrap justify-center gap-2 mx-auto mt-5 lg:mt-10">
    <div *ngFor="let item of category; trackBy: trackByFunc" (click)="item.active = !item.active; update(category,item)">
      <button class="tag outlined interest-tag" [class]="item.active ? 'filled' : ''">
        {{ item.name }}
      </button>
    </div>
  </div>

  <div class="flex justify-end ml-auto mt-5">
    <button [disabled]="!savePreferences" [class]="!savePreferences ? 'disabled' : ''" mat-button
      class="button outlined black" type="submit" (click)="updatePreferences()">Save</button>
  </div>
</mat-card>