<div *ngIf="reviews.length">
  <h2 class="text-xl font-bold mb-4">Ratings and reviews</h2>
  <div *ngFor="let review of reviews; index as i" class="review">
    <div class="flex items-center">
      <img class="w-8 rounded-full" [src]="review.imageUrl" alt="" />
      <div class="reviewer_name">{{ review.name }}</div>
    </div>
    <div class="rating">{{ review.rating.toFixed(1) }}</div>
    <div class="flex items-center">
      <star-rating
        [starType]="'svg'"
        [showHalfStars]="true"
        [disabled]="true"
        [rating]="review.rating"
      ></star-rating>
      <p class="text-xs ml-2">{{ review.created_at | date : "MMMM d, y" }}</p>
    </div>
    <p class="review_comment">{{ review.comment }}</p>
    <mat-divider
      *ngIf="i !== reviews?.length - 1"
      class="divider"
    ></mat-divider>
  </div>
  <p class="mb-4 p-4 font-bold blue cursor-pointer" (click)="openReviewModal()">
    See all reviews
  </p>
</div>
