<div class="video-preview-modal relative">
  <div class="absolute right-0 top-0">
    <button mat-icon-button (click)="close()"><mat-icon style="width: 40px; font-size: 40px;">cancel</mat-icon></button>
  </div>
  <iframe
    [src]="url"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</div>
