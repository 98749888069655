import { Component, OnInit } from '@angular/core';

export type PlanElement = {
  id: string;
  name: string;
  subscriberN: number;
  price: number;
  commission: number;
};

const PLAN_DATA: PlanElement[] = [
  {
    id: '0',
    name: 'Membership plan1',
    subscriberN: 123000,
    price: 88000,
    commission: 930,
  },
  {
    id: '1',
    name: 'Membership plan2',
    subscriberN: 123000,
    price: 88000,
    commission: 930,
  },
];

export type TotalElement = {
  id: string;
  img: string;
  name: string;
  description: string;
  viewN: number;
  likeN: number;
  commentN: number;
  audienceN: number;
};

const TOTAL_DATA: TotalElement[] = [
  {
    id: '0',
    img: '/assets/images/livestream-avatar.jpg',
    name: 'Cool Acrylic Nail Designs to Compliment Your Style',
    description: 'Post',
    viewN: 123000,
    likeN: 88000,
    commentN: 930,
    audienceN: 848,
  },
  {
    id: '1',
    img: '/assets/images/livestream-avatar.jpg',
    name: 'Cool Acrylic Nail Designs to Compliment Your Style',
    description: 'Post',
    viewN: 123000,
    likeN: 88000,
    commentN: 930,
    audienceN: 848,
  },
];

// import {
//   ApexNonAxisChartSeries,
//   ApexPlotOptions,
//   ApexChart,
//   ApexStroke,
// } from 'ng-apexcharts';

// export type ChartOptions = {
//   series: ApexNonAxisChartSeries;
//   chart: ApexChart;
//   plotOptions: ApexPlotOptions;
//   stroke: ApexStroke;
//   colors: string[];
//   label: string;
// };

@Component({
  selector: 'app-influencer-profile',
  templateUrl: './influencer-profile.component.html',
  styleUrls: ['./influencer-profile.component.scss'],
})
export class InfluencerProfileComponent implements OnInit {
  item = {
    img: '/assets/images/influencers/img-influencer-1.jpg',
    name: 'valentinaacevedo',
    location: 'London, UK',
    postN: '54',
    earningN: '289k',
    commissionN: '28k',
    expectedCommissionN: '44k',
    totalFollowerN: '289k',
    totalPostN: 892,
    totalLikeN: '1.3M',
    totalComments: '849k',
  };

  options = [
    { key: 'opt1', value: 'Option1' },
    { key: 'opt2', value: 'Option2' },
    { key: 'opt3', value: 'Option3' },
  ];
  option: any;
  // public subscriberChartOptions: ChartOptions;
  // public chartOptionsArr: ChartOptions[];

  planColumns: string[] = ['plan', 'subscribers', 'price', 'commission'];
  planDataSource = PLAN_DATA;

  totalColumns: string[] = [
    'content',
    'views',
    'likes',
    'comments',
    'newAudience',
  ];
  totalDataSource = TOTAL_DATA;

  constructor() {
    this.option = 'opt1';
    // this.subscriberChartOptions = {
    //   series: [46],
    //   chart: {
    //     height: 240,
    //     type: 'radialBar',
    //   },
    //   plotOptions: {
    //     radialBar: {
    //       hollow: {
    //         size: '70%',
    //       },
    //       dataLabels: {
    //         show: false,
    //       },
    //     },
    //   },
    //   stroke: {
    //     lineCap: 'round',
    //   },
    //   colors: ['#3FC374'],
    //   label: 'Subscribers',
    // };
    // this.chartOptionsArr = [
    //   {
    //     ...this.subscriberChartOptions,
    //   },
    //   {
    //     ...this.subscriberChartOptions,
    //     series: [46],
    //     colors: ['#333333'],
    //     label: 'Posts',
    //   },
    //   {
    //     ...this.subscriberChartOptions,
    //     series: [75],
    //     colors: ['#ED9E28'],
    //     label: 'Livestreams',
    //   },
    // ];
  }

  ngOnInit(): void {}

  kFormatter(num: number) {
    return num > 999 ? num / 1000 + 'K' : num;
  }
}
