<div *ngIf="post?._id" [ngClass]="layoutMode !== 'grid_view' ? 'user-card' : ''" class="overflow-hidden w-full h-full">
  <div class="justify-between items-center mb-3" *ngIf="layoutMode !== 'grid_view'"
    [ngClass]="layoutMode !== 'grid_view' ? 'flex md:hidden' : 'hidden'">
    <div class="flex items-center">
      <div (click)="showProfile()" [ngClass]="layoutMode !== 'grid_view' ? 'flex-show' : 'hidden'"
        class="w-6 h-6 rounded-full overflow-hidden border object-cover cursor-pointer" [style.background-image]="
            'url(' + getPostUserImage(post?.owner?.profile_image) + ')'
          " [style.background-size]="'cover'" [style.background-position]="'center'"></div>
      <div class="flex items-center" [ngClass]="layoutMode !== 'grid_view' ? 'flex-show' : 'hidden'">
        <p class="montserrat-bold break-all text-sm ml-1 md:ml-2">{{ post?.owner?.user_name }}</p>
      </div>
    </div>
    <app-follow-state *ngIf="profile?._id !== post?.owner?._id" [showButton]="true" [following]="myFollowings"
      [style]="'text-xs blue mr-auto cursor-pointer'" [target_user_id]="post?.owner?._id">
    </app-follow-state>
  </div>
  <div class="md:flex relative w-full md:h-full" [class]="layoutMode === 'grid_view' ? 'h-full' : ''">
    <div *ngIf="
        post?.content?.media_type === 'image' ||
        post?.content?.media_type === 'moment'
      " class="w-full h-full" (click)="showPost()">
      <img masonryLazy [src]="post?.content?.url" class="w-full h-full object-cover cursor-pointer post-img" />
    </div>
    <div class="w-full h-full" *ngIf="
        post?.content?.media_type === 'video' ||
        post?.content?.media_type === 'clip' ||
        post?.content?.media_type === 'highlight' ||
        post?.content?.media_type === 'trailer'
      " (click)="showPost()">
      <video class="w-full h-full object-cover post-img cursor-pointer" [src]="post?.content?.url  + '#t=0.1'"  preload="metadata"
        [muted]="'muted'" webkit-playsinline="true" playsinline="true"></video>
      <!-- <div class="absolute top-1 md:top-4 right-1 md:right-4 play-btn">
        <mat-icon class="white">play_arrow</mat-icon>
      </div> -->
    </div>
    <!-- <div (click)="showPost()">
      <pdf-viewer *ngIf="post.content?.media_type === 'pdf'" class="w-full h-64" [src]="post.content.url" [rotation]="0"
        [original-size]="false" [show-all]="true" [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'"
        [stick-to-page]="false" [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
        [show-borders]="false"></pdf-viewer>
    </div> -->
    <div *ngIf="layoutMode === 'grid_view'" class="flex justify-between w-full items-center" [class]="
        layoutMode === 'grid_view'
          ? 'absolute top-2 items-end -mb-1'
          : 'hidden md:absolute md:items-end md:top-2 mt-1 md:mt-0 mb-2 md:-mb-1'
      ">
      <div (click)="showProfile()" [ngClass]="layoutMode !== 'grid_view' ? 'flex-show' : 'hidden'"
        class="w-6 md:w-10 h-6 md:h-10 rounded-full overflow-hidden border object-cover mr-4 md:ml-1 md:mb-2 cursor-pointer"
        [style.background-image]="
          'url(' + getPostUserImage(post?.owner?.profile_image) + ')'
        " [style.background-size]="'cover'" [style.background-position]="'center'"></div>
      <div class="flex items-center" [ngClass]="layoutMode !== 'grid_view' ? 'flex-show' : 'hidden'">
        <p>{{ post?.owner?.user_name }}</p>
        <p>{{ post?.profile?.user_name }}</p>
      </div>
      <div class="ml-auto flex items-center">
        <div class="ml-2">
          <button class="post-icon" mat-icon-button (click)="onLike(post)" [disabled]="isProcessing">
            <!-- <mat-icon [class]="isUserLikedPost(post) ? 'red' : ''">{{isUserLikedPost(post) ? "favorite" :
              "favorite_border"}}</mat-icon> -->
            <!-- <mat-icon>filter_none</mat-icon> -->
            <img [src]="
                post?.content?.media_type === 'video' ||
                post?.content?.media_type === 'clip' ||
                post?.content?.media_type === 'highlight' ||
                post?.content?.media_type === 'trailer'
                  ? reelsIcon
                  : post?.totalContent?.length > 1
                  ? imagesIcon
                  : ''
              " />
          </button>
        </div>
      </div>
    </div>
    <div class="absolute bottom-2" [ngClass]="layoutMode !== 'grid_view' ? 'hidden' : 'block'">
      <!-- <p>{{post?.owner?.user_name}} </p> -->
    </div>
  </div>
</div>