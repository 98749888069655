import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions } from 'swiper';
import { userTypeConstant, addOnTypeConstant } from '../../../../const/appConst';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-part-plan',
  templateUrl: './part-plan.component.html',
  styleUrls: ['./part-plan.component.scss'],
})
export class PartPlanComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() isReferralPlan: any;
  @Input() set openTab(value: any) {
    this.forIndividual = value;
  }
  @Input() set originPlans(value: any[]) {
    this.plans = value;
  }
  @Input() set originAddOns(value: any[]) {
    this.addOns = value;
  }
  @Input() set isDashboardPlanIndividual(value: any) {
    this.forIndividualA = value;
    this.getAddOnes();
  }

  @Output() isDashboardAddonIndividual = new EventEmitter();
  userType: any;
  forIndividual: boolean = true;
  forIndividualA: boolean = true;
  planList: any = [];
  onsList: any = [];
  plans: any[] = [];
  addOns: any[] = [];
  isShowAddOns: boolean = false;
  isComparePlans: boolean = false;
  cardheights:any={
    title: 'h-20',
    desc:'h-40'
  }

  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
  };

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.unsubscribe$)).subscribe((event: any) => {
      if (event?.url.includes(routesConst.plans)) {
        this.isComparePlans = true;
      }
      if (event?.url.includes(routesConst.addons)) {
        this.isShowAddOns = true;
      }
    })
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.getUserType();
    }
  }

  getUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === '') {
        this.sharedService.getUserType();
      } else {
        this.userType = type;
        this.forIndividual = this.userType==userTypeConstant.candidate;
        this.forIndividualA = this.userType==userTypeConstant.candidate;
        this.getPlanList();
        this.getAddOnes();
      }
    });
  }

  ngOnChanges(): void {
    if (this.isReferralPlan) {
      this.config.breakpoints = {
        768: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
        992: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
      };
    }

    this.getPlanList();
    this.getAddOnes();
  }


  getPlanList() {
    if (this.forIndividual) {
      this.planList = this.plans?.filter(
        (plan: any) => plan.consumer_type === userTypeConstant.candidate
      )?.map((plan: any) => ({
        ...plan,
        isMore: true,
        isBigger: plan.description > 140,
      }));
      this.forIndividualA = true;
    } else {
      this.planList = this.plans?.filter(
        (plan: any) => plan.consumer_type === userTypeConstant.company
      )?.map((plan: any) => ({
        ...plan,
        isMore: true,
        isBigger: plan.description > 140,
      }));
      this.forIndividualA = false;
    }

    this.getAddOnes();
  }

  getAddOnes() {
    let list: any = [];
    this.onsList = [];
    if (this.forIndividualA && !this.isReferralPlan) {
      this.onsList = this.addOns?.filter(
        (plan: any) => plan.consumer_type === userTypeConstant.candidate
      )?.map((plan: any) => ({
        ...plan,
        isMore: true,
        isBigger: plan.description.length > 140,
      }));
      this.forIndividual = true;
      this.planList = this.plans?.filter(
        (plan: any) => plan.consumer_type === userTypeConstant.candidate
      )?.map((plan: any) => ({
        ...plan,
        isMore: true,
        isBigger: plan.description.length > 140,
      }));
    } else if (this.forIndividualA && this.isReferralPlan) {
      this.onsList = this.addOns?.filter(
        (plan: any) =>
          plan.consumer_type === userTypeConstant.candidate 
          // && plan.plan_type === addOnTypeConstant.partner
      )?.map((plan: any) => ({
        ...plan,
        isMore: true,
        isBigger: plan.description.length > 140,
      }));
      this.forIndividual = true;
      this.planList = this.plans?.filter(
        (plan: any) => plan.consumer_type === userTypeConstant.candidate
      )?.map((plan: any) => ({
        ...plan,
        isMore: true,
        isBigger: plan.description.length > 140,
      }));
    } else {
      list = this.addOns?.filter(
        (plan: any) => plan.consumer_type === userTypeConstant.company
      )?.map((plan: any) => ({
        ...plan,
        isMore: true,
        isBigger: plan.description.length > 140,
      }));
      list?.forEach((adon: any) => {
        if (adon.consumer_type === userTypeConstant.company) {
          adon.description = 'For Companies';
          this.onsList.push(adon);
        }
      });
      this.forIndividual = false;
      this.planList = this.plans?.filter(
        (plan: any) => plan.consumer_type === userTypeConstant.company
      )?.map((plan: any) => ({
        ...plan,
        isMore: true,
        isBigger: plan.description.length > 140,
      }));
    }
    this.isDashboardAddonIndividual.emit(this.forIndividual)
  }

  getCellWidth() {
    return document.documentElement.clientWidth;
  }

  getToLowerCase(str: string) {
    return str.toLowerCase();
  }

  isShowSpinner(show: any) {
    show ? this.spinner.show() : this.spinner.hide();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
