<div
  *ngFor="let section of data"
  class="part-workflow mx-auto"
  [id]="section?.id"
>
  <div class="section-main" [ngClass]="section.romovepadding">
    <div
      [class]="
        section.mainGridStyle ? section.mainGridStyle : 'section-2-make-it'
      "
      [ngClass]="section?.video?.thirdVideo ? 'tabs-section-width' : ''"
    >
      <div
        [class]="
          section?.imagePosition === 'left' ? 'lg:order-2' : 'lg:order-1'
        "
        [ngClass]="
          section?.video?.thirdVideo
            ? section?.imagePosition === 'left'
              ? 'order-1'
              : 'order-2'
            : ''
        "
      >
        <p *ngIf="section?.superHeading" class="we-make-it-simple">
          {{ section?.superHeading }}
        </p>
        <h3
          *ngIf="section?.mainHeading"
          class="heading"
          [ngStyle]="section.headingStyle"
          [class]="section?.class"
        >
          {{ section?.mainHeading }}
        </h3>
        <p class="section-title-divider"></p>
        <p
          *ngIf="section?.description?.content"
          class="content-section content-width"
          [ngStyle]="section?.decStyle"
          [class]="section?.descWidth"
        >
          {{ section?.description?.content }}
          <span *ngIf="section?.description && section?.secDescription">
            <br />
            <br />
            {{ section?.secDescription }}</span
          >
        </p>
        <ng-container *ngIf="section?.list">
          <p *ngFor="let item of section?.list" class="flex my-6 list-text">
            <mat-icon class="check-icons">check</mat-icon>{{ item }}
          </p>
        </ng-container>
        <div class="flex justify-start items-center">
          <button
            class="site-filled-btn"
            *ngFor="let item of section?.button"
            (click)="navigateTo(item.buttonLink, item?.id)"
          >
            {{ item.text }}
          </button>
        </div>
      </div>
      <div
        class="flex items-center"
        *ngIf="section?.image && !section?.video"
        [class]="
          section?.imagePosition === 'left' ? 'lg:order-1' : 'lg:order-2'
        "
      >
        <img
          class="mx-auto md-mx-0"
          *ngFor="let pic of section?.image"
          [ngStyle]="section?.imagestyle"
          [class]="section?.imageSize"
          [src]="pic?.src"
          alt=""
        />
      </div>
      <div
        *ngIf="section?.video && !section?.image && !section?.video?.thirdVideo"
        [class]="
          section?.imagePosition === 'left' ? 'lg:order-1' : 'lg:order-2'
        "
      >
        <iframe
          class="youtube-video"
          [src]="video_url"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; loop=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div
        *ngIf="section?.video?.thirdVideo"
        [class]="
          section?.imagePosition === 'left' ? 'lg:order-1' : 'lg:order-2'
        "
        [class]="
          section?.video?.thirdVideo
            ? section?.imagePosition === 'left'
              ? 'order-2'
              : 'order-1'
            : ''
        "
      >
        <div class="thirdVideo-container">
          <video
            (mousemove)="pauseIcon()"
            (click)="pauseIcon()"
            class="video thirdVideo"
            #videoElement
            controls
            loop
            [muted]="'muted'"
            [src]="section?.video?.src + '#t=0.1'"  preload="metadata"
            playsinline
            webkit-playsinline
          ></video>
          <mat-icon
            (click)="toggleVideoPlayback()"
            [ngClass]="pause && !videoElement.paused ? 'icon-hide' : 'flex'"
          >
            {{ videoElement.paused ? "play_arrow" : "pause" }}</mat-icon
          >
        </div>
      </div>
    </div>
  </div>
</div>
