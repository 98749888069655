import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.scss'],
})
export class MenuIconComponent implements OnInit {
  @Input() opened: boolean | undefined;
  @Input() scrollPosition: number;
  icon: any;
  constructor() {
    this.scrollPosition = 0;
  }

  ngOnInit(): void {}

  // ngOnChanges(change: any): void {
  //   if (change.opened && change.opened.currentValue !== change.opened.previousValue) {
  //     const icon = document.getElementById('sideNavIcon')
  //     icon?.classList.toggle("change");
  //   }
  // }
}
