import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { S3UploaderService } from './s3-uploader.service';
import { apiUrlConst } from 'src/app/const/apiConst';
import { ImagesType } from 'src/app/const/appConst';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

export class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  imgaData: any;
  constructor(private s3Service: S3UploaderService, private snack: SnackBarService) {}

  imageConvertion(imagesrc: any) {
    return new Promise((resolve, reject) => {
      const fileUri: Array<any> = [];
      const uriArr: Array<string> = [];
      this.imgaData = imagesrc;
      Object.keys(imagesrc).map(key => {
        if (key == 'file' && imagesrc?.file?.type) {
          let imageName = imagesrc.file.type.split('/');
          fileUri.push({
            file: imagesrc[key],
            name: this.randomIdGenerator() + '.' + imageName[1]
          });
        }
      });
      this.uploadImages(fileUri)
        .then(() => {
          fileUri.forEach(item => {
            uriArr.push(
              item.file.type.includes('video')
                ? apiUrlConst.bucketURL + item.name
                : apiUrlConst.compressedBucketURL + item.name
            );
            resolve(
              item.file.type.includes('video')
                ? [apiUrlConst.compressedBucketURL + item.name]
                : uriArr
            );
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  uploadImages(images: any): Promise<any> {
    let promises_array: Array<any> = [];
    let self = this;
    images.forEach((image: { file: any; name: any }) => {
      promises_array.push(
        new Promise(function (resolve) {
          const isVideo = image.file.type.includes('video');
          self.s3Service.upload(image.file, image.name, isVideo).then((res: any) => {
            resolve(res);
          });
        })
      );
    });
    return Promise.all(promises_array);
  }

  /**
   * Random Id Generator
   * @returns
   */
  randomIdGenerator() {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

  /**
   * Validate Image Type
   * @param type
   * @returns
   */
  validateImageType(type: string) {
    if (
      type == ImagesType.JPEG ||
      type == ImagesType.JPG ||
      type == ImagesType.PNG ||
      type == ImagesType.APNG ||
      type == ImagesType.AVIF ||
      type == ImagesType.GIF ||
      type == ImagesType.WEBP ||
      type == ImagesType.BMP ||
      type == ImagesType.XICON
    ) {
      return true;
    } else {
      this.snack.showMessage(`Invalid File Type Should be Image `, true);
      return false;
    }
  }
}
