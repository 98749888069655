<form [formGroup]="packageForm" class="package-unit rounded" onsubmit="submit($event)">
  <textarea
    maxlength="1025"
    class="input-form mt-2 w-full px-4 description"
    placeholder="Describe Package"
    formControlName="description"
  >
  </textarea>
  <mat-error *ngIf="errorHandling('description', 'maxlength')" class="text-danger pb-2">
    Description cannot exceed more than 1024 characters!
  </mat-error>
  <div class="border-normal rounded mt-2 w-full">
    <ul *ngIf="packageFeature && packageFeature.length > 0" class="py-1">
      <li class="flex p-2" *ngFor="let item of packageFeature">
        <mat-icon class="remove-button cursor-pointer" (click)="removeFeature(item)"
          >remove_circle</mat-icon
        >
        <p class="mt-0.5 break-all ml-2">{{ item }}</p>
      </li>
    </ul>
    <div class="flex flex-col relative">
      <input
        maxlength="1025"
        #feature
        placeholder="Key Features"
        formControlName="feature"
        class="w-full p-2 pr-24"
        (keydown)="onFeatureFormChange($event)"
      />
      <mat-error *ngIf="errorHandling('feature', 'maxlength')" class="text-danger pb-2">
        Length cannot exceed more than 1024 characters!
      </mat-error>
      <div class="absolute right-0 top-1 dashboard-page">
        <button
          *ngIf="
            packageForm.get('feature')?.value?.trim()?.length &&
            !errorHandling('feature', 'maxlength')
          "
          mat-button
          class="button outlined black"
          (click)="addFeature()"
        >
          + Add
        </button>
      </div>
    </div>
  </div>
</form>
