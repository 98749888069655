import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  userTypeConstant,
} from 'src/app/const/appConst';

@Component({
  selector: 'app-contact-details-modal',
  templateUrl: './contact-details-modal.component.html',
  styleUrls: ['./contact-details-modal.component.scss']
})
export class ContactDetailsModalComponent implements OnInit {
  userTypeConstant = userTypeConstant;

  constructor(public dialogRef: MatDialogRef<ContactDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void { }

  onClose() {
    this.dialogRef.close();
  }

}
