<div class="dashboard-referals-connection pb-8 section-wrapper">
  <div class="px-4 pb-4 lg:p-10">
    <div class="flex flex-col lg:flex-row mb-2">
      <div class="mb-2 lg:mb-0">
        <p class="black-dark montserrat-bold">Referral Program</p>
        <p class="s mt-2 black-light">Earn 10% Referral Income</p>
      </div>
      <div class="block lg:hidden">
        <mat-divider class="w-full"></mat-divider>
      </div>
    </div>
    <div class="hidden lg:block">
      <mat-divider class="w-full"></mat-divider>
    </div>
    <p class="mt-4 s">
      Refer connections to our platform and earn cash when they pay their
      monthly memberships.
    </p>
    <p class="mt-4 s">
      Learn more about our referral program, see how it works and our member
      eligibility below.
    </p>
    <div class="mt-10">
      <p class="black-dark montserrat-bold" *ngIf="!purchasedAddOn?.length">How It Works</p>
      <div class="list-item flex flex-col ml-1 mt-6 pb-7" *ngIf="!purchasedAddOn?.length">
        <div class="flex items-center bg-white -ml-1.5">
          <div class="list-item_dot"></div>
          <p class="montserrat-bold black-dark ml-4">
            Pick Referral Program 
          </p>
        </div>
      </div>
      <div class="list-item flex flex-col ml-1 mt-4 pb-7">
        <div class="flex items-center bg-white -ml-1.5">
          <div class="list-item_dot"></div>
          <p class="montserrat-bold black-dark ml-4"> Copy Link</p>
        </div>
        <div class="flex items-center ml-5 mt-4 blok-sm">
          <div class="flex items-center mr-7">
            <p class="s" *ngIf="!purchasedAddOn?.length">
              Purchase a member referral plan to get your individual or company
              referral link
            </p>
            <div *ngIf="purchasedAddOn?.length" class="relative">
              <input
                class="referral-link-form h-9 pl-2.5 pr-8 py-2 w-56 md:w-96 truncate s"
                [value]="generatedRefLink"
                readonly="true"
                #formInputText
              />
              <mat-icon
                [ngxClipboard]="formInputText"
                class="copy-icon blue cursor-pointer absolute top-1.5 -ml-8"
                (click)="copytoClipBoard()"
                >content_copy</mat-icon
              >
            </div>
          </div>
          <button
            class="mt-sm "
            *ngIf="purchasedAddOn?.length"
            mat-button
            [class]="purchasedAddOn?.length ? 'button share-btn' : 'disabled'"
            (click)="copytoClipBoard()"
          >
            Copy
          </button>
        </div>
        <div class="mt-8">
          <app-share-links [referralLink]="generatedRefLink"></app-share-links>
        </div>
      </div>
      <div class="list-item flex flex-col ml-1 mt-4 pb-7">
        <div class="flex items-center bg-white -ml-1.5">
          <div class="list-item_dot"></div>
          <p class="montserrat-bold black-dark ml-4">
            Share Link
          </p>
        </div>
        <!-- <ngx-spinner *ngIf="showSpinner" bdOpacity="0.9" bdColor="black-dark" size="medium" color="red"
          type="ball-spin-clockwise" [fullScreen]="true" class="job-list-spinner">
        </ngx-spinner> -->
        <!-- <p class="ml-4 mt-4">
          Leverage your social influence and share link on other social
          networking platforms.
        </p> -->
        <p class="mt-4 ml-4 s">
          Send personalized messages to friends, family, and business partners.
        </p>
      </div>
      <div class="list-item flex flex-col ml-1 mt-4 pb-7">
        <div class="flex items-center bg-white -ml-1.5">
          <div class="list-item_dot"></div>
          <p class="montserrat-bold black-dark ml-4">
            Get Paid
          </p>
        </div>
        <p class="ml-4 mt-4 s">
          Earn 10% cash rewards each time your referee pays their monthly
          membership.
        </p>
        <p class="ml-4 mt-4 s">
          See all of
          <span class="blue cursor-pointer s" (click)="referral()"
            >Your Referrals</span
          >
          within your community network.
        </p>
      </div>
      <!-- <div class="flex flex-col ml-1 mt-4 pb-7">
        <div class="flex items-center bg-white -ml-1.5">
          <div class="list-item_dot"></div>
          <p class="montserrat-bold black-dark ml-4">Earn Rewards</p>
        </div>
        <p class="ml-4 mt-4 s">
          You’ll receive cash rewards as a thank you for strengthening our
          growing community.
        </p>
      </div> -->
    </div>
  </div>
  <app-part-plan
    (isDashboardAddonIndividual)="isDashboardAddonIndividual($event)"
    [isDashboardPlanIndividual]="forIndividual"
    [isReferralPlan]="isReferralPlan"
    [originPlans]="plans"
    [originAddOns]="addOns"
  ></app-part-plan>
</div>
