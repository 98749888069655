import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleModalComponent } from 'src/app/modules/dashboard/pages/messages/components/schedule-modal/schedule-modal.component';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-profile-schedule',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './profile-schedule.component.html',
  styleUrls: ['./profile-schedule.component.scss']
})
export class ProfileScheduleComponent implements OnInit {
  private unsubscribe$=new Subject();

  @ViewChild('modalContent', { static: true }) modalContent?: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData?: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];
  start_date: any;
  endDateMin: any;
  startDateMax: any;
  startDate: any;
  endDate: any;
  newEvent: any = [];
  profile_id: any;
  loginUserId!: string;
  refresh: Subject<any> = new Subject();
  events: any[] = [];
  activeDayIsOpen: boolean = true;
  isNewEventOpen: boolean = false;

  constructor(private profileService: ProfileService, private snack: SnackBarService, private route: ActivatedRoute, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.startDateMax = '1/' + new Date().getFullYear().toString();
    this.endDateMin = this.startDateMax;

    this.route.queryParams
    .pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
        this.profile_id = params['profile_id'];
      });
  }

  getEvents() {
    this.profileService.getEvents().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.events = res.data
      this.events.forEach((element: any) => {
        element.start = new Date(element.start)
        element.end = new Date(element.end)
      });
    });
    this.refresh.next(true);

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  setStartDate(date: string) {
    this.start_date = date;
    this.endDateMin = date;
  }

  addEvent(): void {
    this.newEvent = [
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        description: 'New Description'
      },
    ];


    const dialogRef = this.dialog.open(ScheduleModalComponent, {
      width: '536px',
      maxWidth: '100vw',
      maxHeight: 'calc(100vh - 30px)',
      hasBackdrop:false,
      data: this.newEvent
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.events = [...this.events, res];
    });
  }

  deleteEvent(eventToDelete: any) {
    this.profileService.removeEvent(eventToDelete._id).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res.status == 'Success') {
        this.snack.showMessage(`Event Deleted`,false);
        let index = this.events.findIndex(x => x._id == eventToDelete._id);
        this.events.splice(index, 1);
      }
    })
    this.isNewEventOpen = false;

  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
}
}
