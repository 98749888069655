<div class="AboutUs">
    <div class="AboutUs_heading-wrapper">
        <div class="content-wrapper flex flex-col items-center">
            <div class="block md:hidden">
                <app-feature-menu></app-feature-menu>
            </div>
            <h1 class="heading my-10 relative text-center">
                <img class="quote absolute" src="assets/images/logo/quote.svg" alt="">
                JOBPros the best place  <br>
                to earn on your own terms 
            </h1>
            <div class="author-detail flex flex-col sm:flex-row gap-5 mt-8 mb-12 items-center">
                <div class="thumbnail-img md:mt-1 w-full rounded-full border border-black overflow-hidden">
                    <img src="assets/images/recuiter.jpg" alt="">
                </div>
                <p class="profile-name mt-px md:mt-1"><a (click)="scroll('#aboutUsFooter')"
                        class="watch-link">Watch</a> and learn more</p>
                        <p class="profile-name"><a href="https://medium.com/@jobpros/what-is-jobpros-df0197b0b82f" target="_blank"
                                class="watch-link">Read</a> about our release</p>
                <!-- <div class="flex justify-start gap-4 md:gap-5 flex-col">
                </div> -->
            </div>
            
            <!-- for mobile screen -->
            <div *ngIf="innerWidth < 768" class="images-main">
                <swiper [pagination]="{ clickable: true }" [config]="config">
                    <ng-template swiperSlide>
                        <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.network">
                            <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/Yr1lN.jpeg" alt="" />
                            <p>Explore Communities</p>
                            <small>Jobs, Services & Creators</small>
                        </div>
                    </ng-template>

                    <ng-template swiperSlide>
                        <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.services">
                            <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/IRjtB.jpeg" alt="" />
                            <p>Changing Perspectives</p>
                            <small>Ideas, Creations & Groups</small>
                        </div>
                    </ng-template>

                    <ng-template swiperSlide>
                        <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.payments">
                            <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/F3Bxn.jpeg" alt="" />
                            <p>Network Of Influence</p>
                            <small>Relationships Matter</small>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.referrals">
                            <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/rfro5.jpeg" alt="" />
                            <p>Referral Programs</p>
                            <small>10% Monthly Bonus</small>
                        </div>
                    </ng-template>
                </swiper>
            </div>
            <!---------->

            <!-- for laptop screen -->
            <div *ngIf="innerWidth >= 768" class="grid grid-cols-4 gap-5">
                <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.network">
                    <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/Yr1lN.jpeg" alt="" />
                    <p>Explore Communities</p>
                    <small>Jobs, Services & Creators</small>
                </div>
                <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.services">
                    <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/IRjtB.jpeg" alt="" />
                    <p>Changing Perspectives</p>
                    <small>Ideas, Creations & Groups</small>
                </div>
                <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.payments">
                    <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/F3Bxn.jpeg" alt="" />
                    <p>Network Of Influence</p>
                    <small>Relationships Matter</small>
                </div>
                <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.referrals">
                    <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/rfro5.jpeg" alt="" />
                    <p>Referral Programs</p>
                    <small>10% Monthly Bonus</small>
                </div>
            </div>
            <!-------->
        </div>
    </div>
    <app-frequently-ask-question [data]="'about-us'"></app-frequently-ask-question>
</div>

