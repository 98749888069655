import { Component, Inject, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routesConst } from 'src/app/core/const/routers';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

export interface Skill {
  _id: string;
  name: any;
  active: boolean;
}

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
})

export class CommunityComponent {
  private unsubscribe$ = new Subject();
  routesConst = routesConst;
  hideProfile = false;
  improvedSearch = false;
  saveSkill = false;
  savePrivacy = false;
  skills: Skill[] = [];
  user: any;

  @Input() set profile(profile: any) {
    this.user = profile;
    this.getSkillsList();
  }

  @Input() set privacy(privacy: any) {
    if (privacy?.length) {
      const { hideProfile, improvedSearch } = privacy[0]
      this.hideProfile = hideProfile ? hideProfile : false
      this.improvedSearch = improvedSearch ? improvedSearch : false
    }
  }

  constructor(private profileService: ProfileService, private snack: SnackBarService) { }

  getSkillsList() {
    this.profileService.skills().pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      const { data: skills } = result;
      this.skills = skills.map((skill: any) => ({
        _id: skill._id,
        name: skill.name,
        active: this.user?.skills?.find((item: string) => item === skill._id) ? true : false,
      }));
    });
  }

  changePrivacy(event: any, target: any) {
    switch (target) {
      case 'hideProfile':
        this.hideProfile = event;
        break;
      default:
        this.improvedSearch = event;
        break;
    }
    if (this.hideProfile !== this.user?.privacy?.hideProfile || this.improvedSearch !== this.user?.privacy?.improvedSearch) {
      this.savePrivacy = true;
    }
  }

  updateSkills() {
    const skills = this.skills.filter((i) => i.active === true).map((item) => item._id)
    this.user.skills = skills
    this.saveSkill = false;
    this.profileService.updateProfile({ skills }).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.snack.showMessage('Interest Updated Successfully',false);
    }, () => {
      this.snack.showMessage('Failed to Update Skills',true)
    });
  }

  updatePrivacy() {
    const options = {
      hideProfile: this.hideProfile,
      improvedSearch: this.improvedSearch,
    };
    this.profileService.updateUserPrivacy(options).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.snack.showMessage('Privacy Setting Updated Sucessfully',false);
      this.savePrivacy = false;

    }, () => {
      this.snack.showMessage('Failed to Update Privacy Setting',true)
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
