<div class="dashboard-account flex flex-col w-full">
  <div class="flex items-center w-full">
    <div class="flex window-mode">
      <h1 class="montserrat-bold text-style whitespace-nowrap w-MX-96 truncate">
        {{ name.trim() }}
      </h1>
    </div>
    <!-- <div class="ml-auto mr-4 lg:mr-0">
        <app-view-profile-button [url]="routesConst.profile" [label]="'View As'">
        </app-view-profile-button>
      </div> -->
  </div>
  <div class="tabs user-dashboard ">
    <div class="mt-4">
      <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container dashboard-services-swipper common-arrow"
    >
      <ng-template swiperSlide>
        <div
          class="flex items-center cursor-pointer tab"
          [class]="tab === 'profile' ? 'selected' : ''"
          (click)="setTab('profile'); selected.value = 0"
        >
          <p [class]="tab === 'profile' ? 'blue text-stroke tab-size' : ''">Profile</p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div
          class="flex items-center cursor-pointer pb-1 tab"
          [class]="tab === 'interest' ? 'selected' : ''"
          (click)="setTab('interest'); selected.value = 1"
        >
          <p class="tab-size" [class]="tab === 'interest' ? 'blue text-stroke ' : ''">Interests</p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div
          class="flex items-center cursor-pointer pb-1 tab"
          [class]="tab === 'skills' ? 'selected' : ''"
          (click)="setTab('skills'); selected.value = 2"
        >
          <p class="tab-size" [class]="tab === 'skills' ? 'blue text-stroke ' : ''">Skills</p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div
          class="flex items-center cursor-pointer pb-1 tab"
          [class]="tab === 'invite' ? 'selected' : ''"
          (click)="setTab('invite'); selected.value = 3"
        >
          <p class="tab-size" [class]="tab === 'invite' ? 'blue text-stroke ' : ''">Invite</p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div
          class="flex items-center cursor-pointer pb-1 tab"
          [class]="tab === 'admin' ? 'selected' : ''"
          (click)="setTab('admin'); selected.value = 4"
        >
          <p class="tab-size" [class]="tab === 'admin' ? 'blue text-stroke ' : ''">Admin</p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div
          class="flex items-center cursor-pointer pb-1 tab"
          [class]="tab === 'Access' ? 'selected' : ''"
          (click)="setTab('Access'); selected.value = 5"
        >
          <p class="tab-size" [class]="tab === 'Access' ? 'blue text-stroke ' : ''">Access</p>
        </div>
      </ng-template>
    </swiper>
    </div>
    <mat-tab-group
      color="#fff0"
      [selectedIndex]="selected.value"
      (selectedIndexChange)="selected.setValue($event)"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="flex items-center tab"
            [class]="tab === 'profile' ? 'selected' : ''"
            (click)="setTab('profile')"
          >
            <p [class]="tab === 'profile' ? 'blue text-stroke' : ''">Profile</p>
          </div>
        </ng-template>
        <app-about-me
          [profile]="profile"
          [achievements]="achievements"
          [experienceLevel]="experienceLevel"
        >
        </app-about-me>
      </mat-tab>
      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="tab === 'social-profile' ? 'selected' : ''"
            (click)="setTab('social-profile')">
            <p [class]="tab === 'social-profile' ? 'blue text-stroke' : ''">
              Social Profiles
            </p>
          </div>
        </ng-template>
        <app-social-profiles [socialProfiles]="socialProfiles"></app-social-profiles>
      </mat-tab> -->

      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="flex items-center pb-1 tab"
            [class]="tab === 'interest' ? 'selected' : ''"
            (click)="setTab('interest')"
          >
            <p [class]="tab === 'interest' ? 'blue text-stroke' : ''">Interests</p>
          </div>
        </ng-template>
        <app-community [profile]="profile" [privacy]="privacy"></app-community>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="flex items-center pb-1 tab"
            [class]="tab === 'skills' ? 'selected' : ''"
            (click)="setTab('skills')"
          >
            <p [class]="tab === 'skills' ? 'blue text-stroke' : ''">Skills</p>
          </div>
        </ng-template>
        <app-skills-tab [achievements]="achievements" [profile]="profile"></app-skills-tab>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="flex items-center pb-1 tab"
            [class]="tab === 'invite' ? 'selected' : ''"
            (click)="setTab('invite')"
          >
            <p [class]="tab === 'invite' ? 'blue text-stroke' : ''">Admin</p>
          </div>
        </ng-template>
        <div class="section-wrapper bg-white px-4 pb-4 pt-0 lg:p-10 flex flex-col gap-4">
          <!-- <div>
           
          </div> -->

          <div class="flex items-center ml-5 mb-8 blok-sm">
            <div class="flex items-center mr-7">
              <p class="s" *ngIf="!purchasedAddOn?.length">
                Purchase a member referral plan to get your individual or company referral link
              </p>
              <div *ngIf="purchasedAddOn?.length" class="relative">
                <input
                  class="referral-link-form pl-2.5 pr-8 py-2 w-56 md:w-96 h-[37px] truncate s"
                  [value]="referralLink"
                  readonly="true"
                  #formInputText
                />
                <mat-icon
                  [ngxClipboard]="formInputText"
                  class="copy-icon blue cursor-pointer absolute top-1.5 -ml-8"
                  (click)="copytoClipBoard()"
                  >content_copy</mat-icon
                >
              </div>
            </div>
            <button
              class="mt-sm"
              *ngIf="purchasedAddOn?.length"
              mat-button
              [class]="purchasedAddOn?.length ? 'button share-btn' : 'disabled'"
              (click)="copytoClipBoard()"
            >
              Copy
            </button>
          </div>
          <app-share-links [referralLink]="referralLink"></app-share-links>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="flex items-center pb-1 tab"
            [class]="tab === 'admin' ? 'selected' : ''"
            (click)="setTab('admin')"
          >
            <p [class]="tab === 'admin' ? 'blue text-stroke' : ''">Admin</p>
          </div>
        </ng-template>
        <app-admin-setting></app-admin-setting>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="flex items-center pb-1 tab"
            [class]="tab === 'Access' ? 'selected' : ''"
            (click)="setTab('Access')"
          >
            <p [class]="tab === 'Access' ? 'blue text-stroke' : ''">Access</p>
          </div>
        </ng-template>
        <app-access></app-access>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
