import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';
import { AllFiltersComponent } from 'src/app/shared/components/all-filters/all-filters.component';
import { MatDialog } from '@angular/material/dialog';

export interface SearchTabType {
  title: string;
  logoDesign: string;
  location: string;
  newest: string;
}

@Component({
  selector: 'app-talent-search-tab-group',
  templateUrl: './talent-search-tab-group.component.html',
  styleUrls: ['./talent-search-tab-group.component.scss'],
})
export class TalentSearchTabGroupComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Output() getFilteredList: EventEmitter<any> = new EventEmitter();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter();
  @Output() isFilterApplied: EventEmitter<any> = new EventEmitter();

  @ViewChild('locationSearchInput', { static: true })
  locationSearchInput?: ElementRef;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    // effect: 'slide'
  };
  filteredLocation: string;
  filteredOptions: any[] = [];
  form: FormGroup;
  selected = new FormControl(0);
  searchTabs: SearchTabType[];
  locationList: any[] = [];
  payRangeList: any[];
  expLevelList: any[];
  expandFilterPanelF = false;
  serviceTypes: any[];
  serviceCategories: any[];
  isShowAdvancedSearchOptions: boolean = false;
  isSearch: boolean = false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private talentService: TalentService,
    private _commonService: CommonService
  ) {
    this.form = fb.group({
      searchIdx: [''],
      location: [''],
      newest: [''],
      expLevel: [''],
      serviceType: [''],
      serviceCategory: [''],
    });
    this.searchTabs = [];
    this.payRangeList = [];
    this.expLevelList = [];
    this.serviceCategories = [];
    this.serviceTypes = [];
    this.filteredLocation = '';
  }

  // toggleOption() {
  //   this.isShowAdvancedSearchOptions = !this.isShowAdvancedSearchOptions;
  //   if (this.isShowAdvancedSearchOptions) {
  //     setTimeout(() => {
  //       let search = document.getElementById('locationSearch')
  //       this.getSearchedLocation(search);
  //     }, 500);
  //   }
  // }

  ngOnInit(): void {
    this.getTalentServiceCategory();
    this.getSearchedLocation();
    this.experienceLevelList();
    this.getBillingType();
    this.getPayRangeList();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: any) => {
        if (params?.search?.length) {
          this.form.controls['searchIdx'].setValue(params.search);
          this.getFilteredTalents();
        } else {
          this.isFilterApplied.emit(false);
        }
      });
  }

  getPayRangeList() {
    this.sharedService.talentServicePayRangeList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((payrangelist) => {
        if (payrangelist.length) {
          this.payRangeList = payrangelist;
        } else {
          this.sharedService.getTalentServicePayRangeList();
        }
      });
  }

  getBillingType() {
    this.sharedService.billingTypeList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((billingType) => {
        if (billingType.length) {
          this.serviceTypes = billingType;
        } else {
          this.sharedService.getBillingTypes();
        }
      });
  }

  experienceLevelList() {
    this.sharedService.experienceLevelList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((experience: any) => {
        if (experience.length) {
          this.expLevelList = experience;
        } else {
          this.sharedService.getexperienceLevel();
        }
      });
  }

  getSearchedLocation(element?: any) {
    this._commonService
      ?._filterLocation(
        element ? element : this.locationSearchInput?.nativeElement
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.filteredOptions = result.data;
      });
  }

  getTalentServiceCategory() {
    this.talentService
      .getTalentServiceCategory()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res?.data) {
          this.serviceCategories = res.data;
          // res.data.map((catGroup: any) => {
          //   this.serviceCategories.push({
          //     id: '-1',
          //     label: catGroup.name,
          //   });
          //   catGroup.servicecategory.map((serviceCat: any) => {
          //     this.serviceCategories.push({
          //       id: serviceCat._id,
          //       label: serviceCat.name,
          //     });
          //   });
          // });
        }
      });
  }

  trackByFunc(i: number) {
    return i;
  }

  remove(index: number) {
    if (this.searchTabs.length > 1) {
      this.searchTabs.splice(index, 1);
    } else {
      this.searchTabs = [];
    }
  }

  clearAll() {
    this.form.reset();
    this.isSearch = false;
    this.isFilterApplied.emit(false);
    this.isShowAdvancedSearchOptions = false;
    this.clearFilter.emit(true);
  }

  onChange(event: any) {
    if (this.form.controls['location'].value === '') {
      if (event.key == 'Delete' || event.key == 'Backspace') {
        this.filteredLocation = '';
        this.getFilteredTalents();
      }
    }
  }

  removeItem(label: string, control: any) {
    control.setValue(
      control.value.filter((service: any) => service.label !== label)
    );
  }

  setLocation(location: any) {
    this.filteredLocation = location._id;
    this.getFilteredTalents();
    this.isShowAdvancedSearchOptions = false;
  }

  searchByKeyword(event: any) {
    if (event?.key === 'Enter' || this.form.get('searchIdx')?.value === '') {
      this.getFilteredTalents();
    }
  }

  getFilteredTalents() {
    let options = {};
    if (this.form.get('serviceType')?.value?.length) {
      Object.assign(options, { billings: this.form.get('serviceType')?.value });
    }
    if (this.form.get(' ')?.value && this.form.get('searchIdx')?.value !== '') {
      Object.assign(options, { keyword: this.form.get('searchIdx')?.value });
    }
    if (this.filteredLocation?.length) {
      Object.assign(options, { location: this.filteredLocation });
    }
    if (this.form.get('serviceCategory')?.value?.length) {
      Object.assign(options, {
        categories: this.form.get('serviceCategory')?.value,
      });
    }
    if (this.form.get('newest')?.value?.length) {
      Object.assign(options, { priceRange: this.form.get('newest')?.value });
    }
    const filterParams = { ...options };
    Object.assign(options, { offset: 0, limit: 10 });
    if (Object.keys(filterParams).length === 0) {
      this.clearAll();
      this.isSearch = false;
      this.isFilterApplied.emit(false);
    } else {
      this.getFilteredList.emit(options);
      this.isSearch = true;
      this.isFilterApplied.emit(true);
    }
    this.isShowAdvancedSearchOptions = false;
  }

  toggle(event: any) {
    const icon: any = event._elementRef.nativeElement;
    icon.style.transform = event._elementRef.nativeElement.style.transform
      ? ''
      : 'rotate(90deg)';
  }

  getAllFilters() {
    const dialogRef = this.dialog.open(AllFiltersComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      data: {
        filterType: 'service',
        serviceCategories: this.serviceCategories,
        serviceTypes: this.serviceTypes,
        payRangeList: this.payRangeList,
        form: this.form,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filter: any) => {
        if (filter) {
          this.form.controls['serviceType'].setValue(filter?.serviceType);
          this.form.controls['serviceCategory'].setValue(
            filter?.serviceCategory
          );
          this.form.controls['newest'].setValue(filter?.priceRange);
          this.getFilteredTalents();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
