import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TalentService } from 'src/app/core/services/talent.service';
import { ServiceDetailModalComponent } from '../service-detail-modal/service-detail-modal.component';
import { Subject, takeUntil } from 'rxjs';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent implements OnInit {
  private unsubscribe$ = new Subject();
  isProcessing = false;
  feedbackComment = '';
  rating = 0;

  constructor(
    private dialogRef: MatDialogRef<FeedbackModalComponent>,
    private talentService: TalentService,
    private snack: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.feedbackComment = this.data?.feedback?.comment;
    this.rating = this.data?.rating;
  }

  submitFeedback() {
    this.isProcessing = true;
    const payload: any = {
      service_id: this.data.serviceDetails._id,
      seller_id: this.data.seller_id,
      buyer_id: this.data.buyer_id,
      service_purchased_id: this.data._id,
      buyer_rating: this.rating,
      buyer_comment: this.feedbackComment,
    };
    if (this.data?.type == 'sold_service') {
      delete payload.buyer_rating;
      delete payload.buyer_comment;
      Object.assign(payload, { seller_rating: this.rating });
      Object.assign(payload, { seller_comment: this.feedbackComment });
    }
    this.talentService
      .addFeedBack(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.isProcessing = false;
        this.snack.showMessage(res.message, false);
        this.closeFeedbackModal(true);
      });
  }

  closeFeedbackModal(status: any) {
    this.dialogRef.close(status);
  }

  onRatingChanged(event: any) {
    this.rating = event.rating;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
