<div class="section-wrapper p-10">
  <div class="flex flex-col lg:flex-row mb-2">
    <div class="mb-2 lg:mb-0">
      <p class="black-light montserrat-bold uppercase mt-1.5">States</p>
      <p class="s mt-2 black-light">How jobs have performed in the site</p>
    </div>
    <div class="block lg:hidden">
      <mat-divider class="w-full"></mat-divider>
    </div>
    <div class="border-normal rounded w-32 flex items-center p-2 lg:p-3 lg:ml-auto mt-6 lg:mt-0">
      <mat-select [value]="stateRange">
        <mat-option
          *ngFor="let item of stateRangeArr"
          [value]="item.key">{{item.value}}</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="hidden lg:block">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div class="px-40 grid grid-cols-3 gap-4 mt-5">
    <div class="flex flex-col lg:items-center">
      <h1 class="montserrat-bold">{{state.jobN}}</h1>
      <p class="gray-dark">Total jobs</p>
    </div>
    <div class="flex flex-col lg:items-center">
      <h1 class="montserrat-bold">{{state.applicationN}}</h1>
      <p class="gray-dark">Applications</p>
    </div>
    <div class="flex flex-col lg:items-center">
      <h1 class="montserrat-bold">{{state.closedN}}</h1>
      <p class="gray-dark">Jobs closed</p>
    </div>
  </div>

  <p class="montserrat-bold uppercase mt-10">By Category</p>
  <div class="">
    <table mat-table [dataSource]="dataSource" class="w-full shadow-none mat-elevation-z8">
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef><p class="black-light montserrat-bold s">Category</p></th>
        <td mat-cell *matCellDef="let element">
          <p class="montserrat-bold w-72">{{element.name}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="jobs">
        <th mat-header-cell *matHeaderCellDef><p class="black-light montserrat-bold s text-right pl-8 hidden lg:block">Jobs</p></th>
        <td mat-cell *matCellDef="let element">
          <div><p class="text-right">{{kFormatter(element.jobN)}}</p></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="applications">
        <th mat-header-cell *matHeaderCellDef><p class="black-light montserrat-bold s text-right hidden lg:block pl-8">Applications</p></th>
        <td mat-cell *matCellDef="let element"><p class="text-right hidden lg:block">{{kFormatter(element.applicationN)}}</p></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
