import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { loginSignUpConstant, planTypeConstant, userTypeConstant } from 'src/app/const/appConst';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import Swiper, { Autoplay, SwiperOptions } from 'swiper';
import { routesConst } from '../../core/const/routers';
import { NgxSpinnerService } from 'ngx-spinner';
import { PostService } from 'src/app/core/services/post.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Clipboard } from '@angular/cdk/clipboard';
Swiper.use([Autoplay]);
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  planTypeConstant = planTypeConstant;
  loginSignUpConstant = loginSignUpConstant;
  private unsubscribe$ = new Subject();
  isdisabled: boolean = true;
  routesConst = routesConst;
  isLogged: boolean;
  plans: any[];
  addOns: any[];
  scrollELement: any = '';
  currentUrl: any;
  previousRoute: any;
  innerWidth = window.innerWidth;
  letsGrowSection = true;
  forIndividual = true;
  selected = new FormControl(0);
  purchasedPlan: any;

  Jobs: any = [];
  services: any = [];
  creators: any = [];
  refferal: any = [];
  members: any = [];
  individualHowItWorks: any = [];
  companyHowItWorks: any = [];
  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  };
  membersConfig: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween:20,
    breakpoints: {
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3
      }
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    }
  };
  networkconfig: SwiperOptions = {
    slidesPerView: 'auto'
  };
  profiles: any = [];
  currentLoggedInUserId: any;
  user: any;
  usersPayload = { offset: 0, limit: 10 };
  purchasedAddOn:any;
  generatedRefLink: any;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  afterLoginHomeRangeSliderData = {
    style: true,
    superheading: 'Create & Earn Online',
    heading: 'What’s your monthly passive income goal?',
    description: 'We pay 10% per paid referral up to 12 months. ',
    subheading: 'Your personal + professional network total.',
    subdescription: 'Invite others to a best-in-class networking experience.',
    income: 'Monthly Income',
    getTotal: 0.1
  }

  constructor(
    private clipboard: Clipboard,
    private snack: SnackBarService,
    private commonService: CommonService,
    public router: Router,
    private profileService: ProfileService,
    public authService: AuthService,
    // private sharedService: SharedService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    // private spinner: NgxSpinnerService,
    // private cdr: ChangeDetectorRef,
    // private postService: PostService,
    // private networkService: NetworkService
  ) {
    this.isLogged = false;
    this.plans = [];
    this.addOns = [];
    // this.getPlateformPlansAndAddons();
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: any) => {
        this.previousRoute = this.currentUrl;
        this.currentUrl = event.url;
        this.navigateTo(event.url);
      });

    // this.individualHowItWorks = [
    //   {
    //     route: '/' + routesConst.dashboard + '/' + routesConst.account,
    //     icon: '../../../assets/images/home/logo-monetize-social-network.png',
    //     heading: 'Monetize everywhere',
    //     description:
    //       'Display what your’e about, share social posts with your network with industry experts, creators & hiring companies.'
    //   },

    //   {
    //     route: '/' + routesConst.dashboard + '/' + routesConst.payments,
    //     icon: '../../../assets/images/home/logo-create-passive-income.png',
    //     heading: 'Fast payouts',
    //     description:
    //       'Sell content & expert services get paid for referrals within minutes. Earn income streams & transfer directly to your bank.'
    //   },

    //   {
    //     route: '/' + routesConst.dashboard + '/' + routesConst.referrals,
    //     icon: '../../../assets/images/home/logo-monetize-service.png',
    //     heading: 'Best referral bonuses',
    //     description:
    //       'Earn 10% on every monthly referral. Share your network with us and get paid. All your referrals are displayed on your dashboard.'
    //   }
    // ];

    // this.companyHowItWorks = [
    //   {
    //     route: '/' + routesConst.dashboard + '/' + routesConst.network,
    //     icon: '../../../assets/images/home/logo-explore-job.png',
    //     heading: 'Community network',
    //     description:
    //       'Post unlimited jobs to hire the best talent. Network with industry experts, creators & athletes. Sell services and access to content.'
    //   },

    //   {
    //     route: '/' + routesConst.dashboard + '/' + routesConst.payments,
    //     icon: '../../../assets/images/home/logo-create-passive-income.png',
    //     heading: 'Fast payouts',
    //     description:
    //       'Sell content & expert services get paid for referrals within minutes. Earn income streams & transfer directly to your bank.'
    //   },

    //   {
    //     route: '/' + routesConst.dashboard + '/' + routesConst.referrals,
    //     icon: '../../../assets/images/home/logo-monetize-service.png',
    //     heading: 'Best referral bonuses',
    //     description:
    //       'Earn 10% on every monthly referral. Share your network with us and get paid. All your referrals are displayed on your dashboard.'
    //   }
    // ];

    // this.Jobs = [
    //   {
    //     section: 'createMemberAccount',
    //     imagePosition: this.innerWidth === 586 ? 'left' : 'right',
    //     descWidth: 'member-account-desc-width',
    //     divider: false,
    //     mainHeading: 'Apply or post verified jobs',
    //     description: {
    //       content: 'Search job posting based on your experience, skills and interests.'
    //     },
    //     button: [{ text: 'See Jobs ', buttonLink: '/jobs' }],
    //     video: {
    //       src: '../../../assets/videos/home/jobs.mp4',
    //       redirectUrl: '',
    //       thirdVideo: true,
    //       preview: '../../../assets/images/home/jobs.png'
    //     }
    //   }
    // ];
    // this.services = [
    //   {
    //     section: 'createMemberAccount',
    //     imagePosition: this.innerWidth === 586 ? 'left' : 'right',
    //     descWidth: 'member-account-desc-width',
    //     divider: false,
    //     mainHeading: 'Find services and get hired',
    //     description: {
    //       content: 'Explore our marketplace of expert member services'
    //     },
    //     button: [{ text: 'See Experts', buttonLink: '/services' }],
    //     video: {
    //       src: '../../../assets/videos/home/experts.mp4',
    //       redirectUrl: '',
    //       thirdVideo: true,
    //       preview: '../../../assets/images/home/experts.png'
    //     }
    //   }
    // ];
    // this.creators = [
    //   {
    //     section: 'createMemberAccount',
    //     imagePosition: this.innerWidth === 586 ? 'left' : 'right',
    //     descWidth: 'member-account-desc-width',
    //     divider: false,
    //     mainHeading: 'Social media community posts',
    //     description: {
    //       content: 'See examples of how our members promote their content, expertise, and network'
    //     },
    //     button: [{ text: 'See Posts', buttonLink: '/creators' }],
    //     video: {
    //       src: '../../../assets/videos/home/creators.mp4',
    //       redirectUrl: '',
    //       thirdVideo: true,
    //       preview: '../../../assets/images/home/creators.png'
    //     }
    //   }
    // ];
    // this.refferal = [
    //   {
    //     section: 'createMemberAccount',
    //     imagePosition: this.innerWidth === 586 ? 'left' : 'right',
    //     descWidth: 'member-account-desc-width',
    //     divider: false,
    //     mainHeading: 'Earn 10% revenue share on referrals',
    //     description: {
    //       content: 'See how you can earn 10% income cash rewards per Elite member referral'
    //     },
    //     button: [{ text: 'Get Started', buttonLink: 'dashboard/referrals' }],
    //     video: {
    //       src: '../../../assets/videos/home/referrals.mp4',
    //       redirectUrl: '',
    //       thirdVideo: true,
    //       preview: '../../../assets/images/home/referrals.png'
    //     }
    //   }
    // ];
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.isLogged = true;
      this.profileService
        .getpurchasedPlan()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.purchasedPlan = res.currentPlan;
          this.purchasedAddOn = res.currentAddOns;
          this.profileService.purchasedPlan.next(res.currentPlan);
          this.profileService.purchasedAddOn.next(res.currentAddOns);
          if (this.purchasedAddOn?.length) {
            this.referralCode();
          }

        });
        // this.getMembers();
        // this.getTopUsers();
    }

    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (params: any) => {
        if (params?.open === 'login') {
          this.openSignUp(loginSignUpConstant.login);
        }
      },
      error => { }
    );


  }

  //  getMembers() {
  //   this.spinner.show();
  //   let payload = 'Sam,Barbers,SelfBrand,worldsmostlit,InvestmentGroupPartners,Lionshare';

  //   this.postService
  //     .getSpecificUsers(payload)
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe(
  //       (res: any) => {          
  //         this.members = res?.data;
  //         this.spinner.hide();
  //       },
  //       () => {
  //         this.spinner.hide();
  //       }
  //     );
  // }

  // resetScrollElement() {
  //   setTimeout(() => {
  //     this.scrollELement = '';
  //   }, 100);
  // }

  ngAfterViewChecked() {
    if (this.scrollELement !== '') {
      this.scroll(this.scrollELement);
    }
  }

  getArray(size: number) {
    let array = [0];
    for (let i = 1; i < size; i++) {
      array.push(i);
    }
    return array;
  }

  openSignUp(pageToOpen: string) {
    this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px' : '100vw',
      height: window.innerWidth > 600 ? 'h-fit' : '100%',
      maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
      maxHeight: window.innerWidth > 600 ? '600' : '100vh',
      data: { pageToOpen }
    });
  }

  redirect(routName: string, params: any) {
    const url = `/${routName}`
    this.router.navigate([url], { queryParams: params });

  }

  scroll(element: any) {
    if (this.scrollELement !== '') {
      document.querySelector(element).scrollIntoView({ block: 'start' });
    }
    // this.resetScrollElement();
  }

  // getPlateformPlansAndAddons() {
  //   this.sharedService.jobprosPlans$.pipe(takeUntil(this.unsubscribe$)).subscribe((plans: any) => {
  //     if (plans.length) {
  //       this.plans = plans;
  //     } else {
  //       this.sharedService.jobProsPlansAndAdOns();
  //     }
  //   });
  //   this.sharedService.jobprosAdOn$.pipe(takeUntil(this.unsubscribe$)).subscribe((addOns: any) => {
  //     if (addOns.length) {
  //       this.addOns = addOns;
  //     } else {
  //       this.sharedService.jobProsPlansAndAdOns();
  //     }
  //   });
  // }

  navigate(url: any, queryParams?: any) {
    let params = { queryParams: {} };
    if (queryParams) {
      Object.assign(params.queryParams, queryParams);
    }
    this.router.navigate([url], params);
  }

  scrollToSection(element: string) {
    document.querySelector(element)?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  navigateTo(url: any) {
    if (this.router.url == url) {
      if (url.includes(routesConst.aboutus)) {
        this.scrollELement = '#aboutUs';
      } else if (url.includes(routesConst.more)) {
        this.scrollELement = '#learnMore';
      } else if (url.includes(routesConst.features)) {
        this.scrollELement = '#memberFeature';
      } else if (url.includes(routesConst.howItWorks)) {
        setTimeout(() => {
          this.scrollELement = '#workflow';
        }, 500);
      } else if (url.includes(routesConst.content)) {
        this.dialog.open(UploadPostModalComponent, {
          maxWidth: '100vw',
          width: '536px',
          height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
          disableClose: true,
          data: {}
        });
      } else if (url.includes(routesConst.campaign)) {
        this.dialog.open(UploadPostModalComponent, {
          disableClose: true,
          maxWidth: '100vw',
          width: '536px',
          height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
          data: { isCampaign: true }
        });
      } else if (url.includes(routesConst.signup)) {
        this.openSignUp(loginSignUpConstant.signup);
      }
    } else {
      this.scroll(this.scrollELement);
      this.router.navigate(['/' + url]);
    }
  }

  // checkIfLogin() {
  //   if (!this.authService.loggedIn()) {
  //     this.openSignUp(loginSignUpConstant.signup);
  //     return;
  //   }
  //   this.router.navigate([`/${this.routesConst.features}`]);
  // }

  // removeSuggested(index: number) {
  //   this.profiles.splice(index, 1);
  // }

  // getTopUsers(swiper?: any) {
  //   if (this.authService.loggedIn()) {
  //     this.sharedService.userInfo$
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((information: { profileInfo: any } | null) => {
  //         if (information === null) {
  //           this.sharedService.getUserInfo();
  //         } else if (information != null) {
  //           // const { profileInfo } = information;
  //           // this.user = profileInfo;
  //           // this.currentLoggedInUserId = this.user?._id;
  //           // Object.assign(this.usersPayload, {
  //           //   userId: this.currentLoggedInUserId
  //           // });
  //           this.postService
  //             .getDiscoverUsers(this.usersPayload)
  //             .pipe(takeUntil(this.unsubscribe$))
  //             .subscribe((res: any) => {
  //               // this.profiles = [...this.profiles, ...res.data];
  //               if (res.status === 'Success') {
  //                 // this.profiles = res.data;

  //                 // Remove duplication
  //                 // this.profiles = Array.from(
  //                 //   new Map(this.profiles.map((obj: any) => [obj._id, obj])).values()
  //                 // );
  //               }

  //               const activeIndex = swiper?.activeIndex || 1;
  //               swiper?.slideTo(activeIndex - 1);
  //               this.cdr.detectChanges();
  //             });
  //         }
  //       });
  //   } else {
  //     this.postService
  //       .getDiscoverUsers(this.usersPayload)
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((res: any) => {
  //         // this.profiles = [...this.profiles, ...res.data];
  //         if (res.status === 'Success') {
  //           // this.profiles = res.data;

  //           // Remove duplication
  //           // this.profiles = Array.from(
  //           //   new Map(this.profiles.map((obj: any) => [obj._id, obj])).values()
  //           // );
  //         }

  //         const activeIndex = swiper?.activeIndex || 1;
  //         swiper?.slideTo(activeIndex - 1);
  //         this.cdr.detectChanges();
  //       });
  //   }
  // }

  // onSlideChange(swiper: any) {
  //   if (swiper.isEnd) {
  //     this.usersPayload.offset = this.usersPayload.offset + 10;
  //     this.getTopUsers(swiper);
  //   }
  // }

  // followUser(user: any) {
  //   if (!this.authService.loggedIn()) {
  //     this.router.navigate(['/']);
  //     this.dialog.open(LoginSignupDialogComponent, {
  //       width: window.innerWidth > 600 ? '400px' : '100vw',
  //       height: window.innerWidth > 600 ? 'h-fit' : '100%',
  //       maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
  //       maxHeight: window.innerWidth > 600 ? '600' : '100vh',
  //       data: { pageToOpen: 'login' }
  //     });
  //     return;
  //   }
  //   let userId = user?._id;
  //   if (userId) {
  //     this.spinner.show();
  //     this.networkService
  //       .followUser(userId)
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((res: any) => {
  //         this.spinner.hide();
  //         user.networkMatchScore = 1;
  //       });
  //   }
  // }

  copytoClipBoard() {
    let isCoppied = this.clipboard.copy(this.generatedRefLink);
    if (isCoppied) {
      this.snack.showMessage(`Referral Link Copied...`,false);
    } else {
    }
  }

  getReferralLink(referralCode: string) {
    this.generatedRefLink = `${this.commonService.location().origin
      }/signup?referralLink=${referralCode}`;
  }

  referralCode() {
    this.profileService
      .getReferralCode()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((referral: any) => {
        this.getReferralLink(referral.code);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
