<div class="filters-main relative">
  <div class="header fixed top-0 right-0 left-0 items-center justify-between">
    <button class="text-white font-bold" (click)="reset()">Reset</button>
    <p class="text-white montserrat-bold">Filter</p>
    <mat-icon class="text-white" (click)="close(false)">close</mat-icon>
  </div>

  <!-- JOBS FILTERS -->

  <div *ngIf="data.filterType == 'jobs'" class="content-wrapper">
        
    <!-- Date Posted Filter -->
    <div class="flex justify-between items-center">
      <p *ngIf="sortOption.length" class="font-bold text-black mb-4 pt-2">Date Posted</p>
      <button
            *ngIf="data.showPreferences === 'Preferences'"
            class="clear-filter-btn block ml-auto"
            (click)="updateFilters('Preferences')"
          >
            Preferences
        </button>
    </div>
    <div *ngIf="sortOption.length" class="pb-2 border-b">
      <div class="flex flex-col">
        <mat-radio-group
          name="radioButtons"
          *ngFor="let sortBy of sortOption; trackBy: trackByFunc"
          aria-label="Select an option"
          [(ngModel)]="datePosted"
          (click)="checkboxChanged()"
        >
          <mat-radio-button
            [value]="sortBy.label"
            [id]="sortBy.id"
            class="text-center"
          >
            {{ sortBy.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <!-- Date Posted Filter End-->

    <!-- Categories Filter -->
    <div *ngIf="data?.allCategories?.length" class="pt-2 pb-2 border-b">
      <p class="font-bold text-black mb-4">Categories</p>
      <div class="flex flex-col">
        <mat-checkbox
          class="filter-checkbox mb-2"
          *ngFor="let category of data?.allCategories; trackBy: trackByFunc"
          [checked]="getCheckedFilters('category', category)"
          (change)="checkboxChanged('category', category, $event)"
          >{{ category?.label }}</mat-checkbox
        >
      </div>
    </div>
    <!-- Categories Filter End-->

    <!-- Job Type Filter -->
    <div *ngIf="data?.allJobTypes?.length" class="pt-2 pb-2 border-b">
      <p class="font-bold text-black mb-4">Job Type</p>
      <div class="flex flex-col">
        <mat-checkbox
          class="filter-checkbox mb-2"
          *ngFor="let type of data?.allJobTypes; trackBy: trackByFunc"
          [checked]="getCheckedFilters('jobType', type)"
          (change)="checkboxChanged('jobType', type, $event)"
          >{{ type?.label }}</mat-checkbox
        >
      </div>
    </div>
    <!-- Job Type Filter End-->

    <!-- Experience Level Filter -->
    <div *ngIf="data?.allExperienceLevel?.length" class="pt-2 pb-2 border-b">
      <p class="font-bold text-black mb-4">Experience Level</p>
      <div class="flex flex-col">
        <mat-checkbox
          class="filter-checkbox mb-2"
          *ngFor="
            let experience of data?.allExperienceLevel;
            trackBy: trackByFunc
          "
          [checked]="getCheckedFilters('experience', experience)"
          (change)="checkboxChanged('experience', experience, $event)"
          >{{ experience?.label }}</mat-checkbox
        >
      </div>
    </div>
    <!-- Experience Level Filter End-->

    <!-- Weekly Pay Range Filter -->
    <div *ngIf="data?.allPayRange?.length" class="pt-2 pb-2 border-b">
      <p class="font-bold text-black mb-4">Weekly Pay Range</p>
      <div class="flex flex-col">
        <mat-checkbox
          class="filter-checkbox mb-2"
          *ngFor="let payRange of data?.allPayRange; trackBy: trackByFunc"
          [checked]="getCheckedFilters('payRange', payRange)"
          (change)="checkboxChanged('payRange', payRange, $event)"
          >{{ payRange?.label }}</mat-checkbox
        >
      </div>
    </div>
    <!-- Weekly Pay Range Filter End -->
  </div>

  <!-- SERVICE FILTERS -->

  <div *ngIf="data.filterType == 'service'" class="content-wrapper">
    <!-- Pay Range Filter -->
    <div *ngIf="data?.payRangeList?.length" class="pt-2 pb-2 border-b">
      <p class="font-bold text-black mb-4">Price Range</p>
      <div class="flex flex-col">
        <mat-checkbox
          class="filter-checkbox mb-2"
          *ngFor="let priceRange of data?.payRangeList; trackBy: trackByFunc"
          [checked]="getCheckedFilters('priceRange', priceRange)"
          (change)="checkboxChanged('priceRange', priceRange, $event)"
          >{{ priceRange?.label }}</mat-checkbox
        >
      </div>
    </div>
    <!-- Pay Range Filter End-->

    <!-- Service Categories Filter -->
    <div *ngIf="data?.serviceCategories?.length" class="pt-2 pb-2 border-b">
      <p class="font-bold text-black mb-4">Service Categories</p>
      <div
        class="flex flex-col"
        *ngFor="
          let serviceGroupCategory of data?.serviceCategories;
          trackBy: trackByFunc
        "
      >
        <p class="font-bold text-black mb-4">{{ serviceGroupCategory.name }}</p>
        <mat-checkbox
          class="filter-checkbox mb-2"
          *ngFor="
            let serviceCategory of serviceGroupCategory?.servicecategory;
            trackBy: trackByFunc
          "
          [checked]="getCheckedFilters('serviceCategory', serviceCategory)"
          (change)="checkboxChanged('serviceCategory', serviceCategory, $event)"
          >{{ serviceCategory?.name }}</mat-checkbox
        >
      </div>
    </div>
    <!-- Service Categories Filter End-->

    <!-- Service Types Filter -->
    <div *ngIf="data?.serviceTypes?.length" class="pt-2 pb-2 border-b">
      <p class="font-bold text-black mb-4">Service Type</p>
      <div class="flex flex-col">
        <mat-checkbox
          class="filter-checkbox mb-2"
          *ngFor="let serviceType of data?.serviceTypes; trackBy: trackByFunc"
          [checked]="getCheckedFilters('serviceType', serviceType)"
          (change)="checkboxChanged('serviceType', serviceType, $event)"
          >{{ serviceType?.label }}</mat-checkbox
        >
      </div>
    </div>
    <!-- Service Types Filter End -->
  </div>
  <div class="sticky left-0 right-0 bottom-0 py-2 flex justify-center bg-white filter-btn">
    <button class="button white" (click)="updateFilters()">Update</button>
  </div>
</div>
