import { Component, OnInit } from '@angular/core';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { JobService } from 'src/app/core/services/job.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {
  // private unsubscribe$ = new Subject();
  routesConst = routesConst;
  partCreateHeaderData = {
    styling: {
      'max-width': '550px',
    },
    class: 'createBrand-heading-width',
    heading: "the way you create & earn online",
    blueheading: 'think ',
    description:
      'Create or establish your brand, or company on our community networking platform designed for you.',
    button: {
      text: this.authService.loggedIn() ? 'Create More' : 'Try 3 Months Free',
      buttonLink: this.authService.loggedIn() ? `${'/' + routesConst.dashboard + '/'+ routesConst.referrals }` : false,
    },
    image: {
      src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/i4mm0.jpeg',
      redirectUrl: '',
    },
  };

  create = [
    {
      section: 'createContent',
      imagePosition: 'right',
      descWidth: 'createContent-desc-width',
      superHeading: 'CREATE CONTENT',
      divider: false,
      mainHeading: 'Share content & network',
      description: {
        content: 'Promote your brand and content by sharing photos & videos',
      },
      list: [
        'Promote your brand and expertise',
        'Let others follow your trends',
        // 'Create member engagement',
        'Turn your followers into subscribers',
        'Network with industry leaders',
      ],
      button: [{ text: 'Post Content', buttonLink: routesConst.content }],
      image: [
        {
          src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_web_photos23.png',
          redirectUrl: '',
        },
      ],
    },

    {
      section: 'createExpertServices',
      descWidth: 'create-expert-desc-width',
      imagePosition: 'left',
      superHeading: 'CREATE SERVICES',
      divider: true,
      mainHeading: 'Get hired for your expertise & experience',
      description: {
        content:
          'Create & earn clients from your profile by displaying expert services',
      },
      list: [
        // 'Market your expertise and skills',
        'Sell expert services from anywhere',
        'No seller price limits',
        'Instant pay to Stripe connect account',
        'See invoices and message clients',
      ],

      button: [
        {
          text: 'Get Clients',
          buttonLink: `/${routesConst.dashboard}/${routesConst.services}`,
        },
      ],
      image: [
        {
          src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_web_photos22.png',
          redirectUrl: '',
        },
      ],
    },

    {
      section: 'createCampaigns',
      imagePosition: 'right',
      descWidth: 'campaign-desc-width',
      superHeading: 'CREATE CAMPAIGNS',
      divider: false,
      mainHeading: 'Create exclusive subscriber access',
      description: {
        content:
          'Instantly share your campaigns with your audience of subscribers.',
      },
      list: [
        'Instantly share content via direct message',
        'Only share campaigns with subscribers',
        'Increase subscriber engagement',
        'Create unlimited campaigns',
        // 'Give exclusive access to campaigns',
      ],
      button: [
        { text: 'Start Campaign', buttonLink: '/' + routesConst.campaign },
      ],
      image: [
        {
          src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/GOT1M.png',
          redirectUrl: '',
        },
      ],
    },

    {
      section: 'createMembershipContent',
      imagePosition: 'left',
      superHeading: 'CREATE SUBSCRIPTIONS',
      divider: false,
      mainHeading: 'Originality pays more',
      description: {
        content:
          'Display and share your subscriber plans, right from your profile',
      },
      button: [
        {
          text: 'Get Subscribers',
          buttonLink: `/${routesConst.dashboard}/${routesConst.subscription}`,
        },
      ],
      image: [
        {
          src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_web_photos20.png',
          redirectUrl: '',
        },
      ],
    },

    // {
    //   section: 'weValueReferrals',
    //   descWidth: 'referrals-desc-width',
    //   imagePosition: 'right',
    //   superHeading: 'WE VALUE REFERRALS',
    //   divider: false,
    //   mainHeading: 'Earn 10% monthly revenue share',
    //   description: {
    //     content:
    //       'Our members can earn 10% referral revenue for memberships sold up to 12 months ongoing',
    //     styling: 'lg:max-w-xl',
    //   },
    //   list: [
    //     'Select referral membership add on',
    //     'Invite your professional and personal networks',
    //     'Share referral link to social and business networks',
    //     'Get consistent revenue per referral memberships paid',
    //   ],
    //   button: [{ text: 'Get Referrals', buttonLink: '' }],
    //   image: [
    //     {
    //       src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_6.png',
    //       redirectUrl: '',
    //     },
    //   ],
    // },
  ];

  createRangeSliderData = {
    superheading: 'Create & Earn Online',
    heading: 'How many customers will you have?',
    description: 'Just estimate and we will give you features and tools to grow.',
    subheading: 'How much will you charge?',
    subdescription: 'Create paid access to photos and videos or expert services. Sell, create & earn on JOBPros.',
    income: 'Monthly Income',
    getTotal: 1
  }

  constructor(public jobService: JobService, public authService: AuthService) {}

  ngOnInit(): void {}
}
