import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video-preview-modal',
  templateUrl: './video-preview-modal.component.html',
  styleUrls: ['./video-preview-modal.component.scss'],
})
export class VideoPreviewModalComponent implements OnInit {
  url: any;
  constructor(
    private dialogRef: MatDialogRef<VideoPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.url + '?autoplay=1'
    );
  }

  close() {
    this.dialogRef.close();
  }
}
