import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-social-profiles',
  templateUrl: './social-profiles.component.html',
  styleUrls: ['./social-profiles.component.scss'],
})
export class SocialProfilesComponent implements OnInit {
  private unsubscribe$ = new Subject();
  connected: any;
  profile: any;
  social!: FormGroup;
  saveSocial = false;
  @Input() set socialProfiles(profile: any) {
    if (profile?.length) {
      const { facebook, instagram, tiktok, twitter, linkedin } = profile[0];
      this.profile = profile[0]
      this.social?.get('facebook')?.setValue(facebook);
      this.social?.get('instagram')?.setValue(instagram);
      this.social?.get('tiktok')?.setValue(tiktok);
      this.social?.get('twitter')?.setValue(twitter);
      this.social?.get('linkedin')?.setValue(linkedin);
    }
  }

  constructor(private fb: FormBuilder, public profileService: ProfileService, private snack: SnackBarService) {
    this.social = this.fb.group({
      facebook: ['https://www.facebook.com/', [ValidateFacebook, Validators.maxLength(512)]],
      instagram: ['https://www.instagram.com/', [Validateinsta, Validators.maxLength(512)]],
      tiktok: ['https://www.tiktok.com/', [Validatetiktok, Validators.maxLength(512)]],
      twitter: ['https://www.twitter.com/', [Validatetwitter, Validators.maxLength(512)]],
      linkedin: ['https://www.linkedin.com/', [Validatelinkedin, Validators.maxLength(512)]],
    });
  }

  ngOnInit(): void {
    this.social.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((form) => {
      if (
        form.facebook !== this.profile?.facebook ||
        form.instagram !== this.profile?.instagram ||
        form.tiktok !== this.profile?.tiktok ||
        form.linkedin !== this.profile?.linkedin ||
        form.twitter !== this.profile?.twitter
      ) {
        this.saveSocial = true;
      } else {
        this.saveSocial = false;
      }
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.social.controls[control].hasError(error);
  };

  saveChanges() {
    if (this.social.valid) {
      this.profileService.updateSocialProfiles(this.social.value).pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
        this.snack.showMessage(response.message,true);
        this.saveSocial = false;
      }, () => {
        this.snack.showMessage('Failed to Update Social Links',true);
        this.saveSocial = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}

function ValidateFacebook(
  control: AbstractControl
): { [key: string]: any } | null {
  if (!control.value.includes('https://www.facebook.com/')) {
    return { formInvalid: true };
  }
  return null;
}

function Validateinsta(
  control: AbstractControl
): { [key: string]: any } | null {
  if (!control.value.includes('https://www.instagram.com/')) {
    return { formInvalid: true };
  }
  return null;
}

function Validatetiktok(
  control: AbstractControl
): { [key: string]: any } | null {
  if (!control.value.includes('https://www.tiktok.com/')) {
    return { formInvalid: true };
  }
  return null;
}

function Validatetwitter(
  control: AbstractControl
): { [key: string]: any } | null {
  if (!control.value.includes('https://www.twitter.com/')) {
    return { formInvalid: true };
  }
  return null;
}

function Validatelinkedin(
  control: AbstractControl
): { [key: string]: any } | null {
  if (!control.value.includes('https://www.linkedin.com/')) {
    return { formInvalid: true };
  }
  return null;


}
