<div class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-6">
    <!-- <div class="flex flex-col items-center gap-4">
      <button (click)="copyLink()">
        <img class="w-10 h-10" src="../../../../../assets/images/share-icons/copy_link.png" alt="Twitter Image" >
      </button>
      <p>Copy link</p>
      </div> -->

    <div class="flex flex-col items-center gap-4">
        <button (click)="shareByEmail()">
            <img class="w-10 h-10" src="../../../../../assets/images/share-icons/email.png" alt="Twitter Image" />
        </button>
        <p>Email</p>
    </div>

    <div class="flex flex-col items-center gap-4">
        <button (click)="shareOnFacebook()">
            <img class="w-10 h-10" src="../../../../../assets/images/share-icons/facebook.png" alt="Twitter Image" />
        </button>
        <p>Facebook</p>
    </div>

    <div class="flex flex-col items-center gap-4">
        <button (click)="shareViaWhatsApp()">
            <img class="w-10 h-10" src="../../../../../assets/images/share-icons/whatsapp.png" alt="Twitter Image" />
        </button>
        <p>WhatsApp</p>
    </div>

    <div class="flex flex-col items-center gap-4">
        <button (click)="shareOnLinkedIn()">
            <img class="w-10 h-10" src="../../../../../assets/images/share-icons/linkedin.png" alt="Twitter Image" />
        </button>
        <p>linkedIn</p>
    </div>

    <!-- NEW LINKS -->
    <div class="flex flex-col items-center gap-4">
        <button (click)="shareOnMessenger()">
            <img class="w-10 h-10" src="../../../../../assets/images/share-icons/messenger.png" alt="Twitter Image" />
        </button>
        <p>Messenger</p>
    </div>

    <div class="flex flex-col items-center gap-4">
        <button (click)="shareOnTwitter()">
            <img class="w-10 h-10" src="../../../../../assets/images/share-icons/twitter.png" alt="Twitter Image" />
        </button>
        <p>X</p>
    </div>
    <div class="flex flex-col items-center gap-4">
        <button (click)="shareOnThreads()">
            <img class="w-10 h-10" src="../../../../../assets/images/share-icons/threads.png" alt="Twitter Image" />
        </button>
        <p>Threads</p>
    </div>
</div>