import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { defaultSortByOption, sortBy } from 'src/app/core/const/options';
import { NetworkService } from 'src/app/core/services/network.service';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.scss'],
})
export class FollowersComponent implements OnInit {
  private unsubscribe$ = new Subject();
  totalcount = 0;
  followersList: any[] = [];
  followerFilter = {
    sort_by: '',
    offset: 0,
    limit: 20
  };
  sortByData = sortBy;
  searchSortValue?: string = defaultSortByOption.defaultSortBy;
  loading: boolean = false;
  totalAvaileList: any;

  constructor(private networkService: NetworkService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.getFollowers(this.searchSortValue)
  }

  setSearchSort(val: string) {
    this.searchSortValue = this.sortByData.type.filter((item: { id: string; label: string }) => item.id === val)[0].label;
    this.getFollowers(this.searchSortValue)
  }

  unFollowUser(item: any) {
    this.networkService.unFollow(item.user_id).pipe(takeUntil(this.unsubscribe$)).subscribe((x: any) => {
      if (x.status == 'Success') {
        this.totalcount = this.totalcount - 1;
      }
      this.followersList = this.followersList.filter(user => user.user_id !== item.user_id)
    })
  }

  getFollowers(searchTypeVal: any) {
    this.spinner.show();
    this.followerFilter.sort_by = searchTypeVal;
    this.followerFilter.offset = 0;
    this.followersList = [];
    this.networkService.getFollowers(this.followerFilter).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.followersList = res.data.followers;
      this.totalcount = res.totalFollowers;
      this.totalAvaileList = this.followersList.length;
      this.spinner.hide();
    })
  }

  onScrollDown() {
    if (this.totalcount != this.totalAvaileList) {
      this.followerFilter.offset = this.followerFilter.offset + 20;
      this.spinner.show();
      this.networkService.getFollowers(this.followerFilter).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        if (res?.data?.followers?.length) {
          this.spinner.hide();
          this.followersList = [...this.followersList, ...res.data.followers];
          this.totalcount = res.totalFollowers;
          this.totalAvaileList = this.followersList.length;
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
