import { Injectable } from '@angular/core';
import {
  EarnHeaderConstants,
  EarnRangeSlider,
  EarnReferrals,
  WhatYouGet,
} from 'src/app/const/earn.constants';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EarnService {
  constructor(public authService: AuthService) {}

  getEarnHeader() {
    return {
      class: EarnHeaderConstants.CLASS,
      heading: EarnHeaderConstants.HEADING,
      blueheading: EarnHeaderConstants.BLUE_HEADING,
      description: EarnHeaderConstants.DESCRIPTION,
      button: {
        text: this.authService.loggedIn() ? 'Earn More' : 'Try 3 Months Free',
        buttonLink: this.authService.loggedIn() ? `${'/' +routesConst.addonPricing }` : false,
      },
      image: EarnHeaderConstants.IMAGE,
    };
  }

  getWhatYouGet() {
    return [
      {
        section: WhatYouGet.SECTION,
        imagePosition: WhatYouGet.IMAGE_POSITION,
        superHeading: WhatYouGet.SUPER_HEADING,
        mainHeading: WhatYouGet.MAIN_HEADING,
        divider: WhatYouGet.DIVIDER,
      },
    ];
  }

  getEarnReferrals() {
    return [
      {
        section: EarnReferrals.SECTION,
        descWidth: EarnReferrals.DESC_WIDTH,
        imagePosition: EarnReferrals.IMAGE_POSITION,
        superHeading: EarnReferrals.SUPER_HEADING,
        divider: EarnReferrals.DIVIDER,
        mainHeading: EarnReferrals.MAIN_HEADING,
        description: EarnReferrals.DESCRIPTION,
        list: EarnReferrals.LIST,
        button: EarnReferrals.BUTTON,
        image: EarnReferrals.IMAGE,
      },
    ];
  }

  getEarnRangeSlider() {
    return {
      superheading: EarnRangeSlider.SUPER_HEADING,
      heading: EarnRangeSlider.HEADING,
      description: EarnRangeSlider.DESCRIPTION,
      subheading: EarnRangeSlider.SUB_HEADING,
      subdescription: EarnRangeSlider.SUB_DESCRIPTION,
      income: EarnRangeSlider.INCOME,
      getTotal: EarnRangeSlider.GETTOTAL,
    };
  }
}
