import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SharedService } from '../../services/shared.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-masonry-panel',
  templateUrl: './masonry-panel.component.html',
  styleUrls: ['./masonry-panel.component.scss']
})
export class MasonryPanelComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() data: any[] = [];
  @Input() layoutMode: string = 'grid_view';
  @Input() searchTypeVal: string = '';
  @Input() interestList: any;
  @Input() network: any;
  @Input() profile: any;
  @Input() isHome: any;
  myFollowings: any;

  masonryImages: any[] = [];
  isHigher: boolean = false;

  innerWidth = this.commonService.getWindow()?.innerWidth;

  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private networkService: NetworkService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.masonryImages = this.data;

    if (this.authService.loggedIn()) {
      this.getFollowings();
      this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else {
          const { profileInfo } = information;
          this.profile = profileInfo;
        }
      });
    }
  }

  getFollowings() {
    this.networkService
      .getFollowings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network: any) => {
        this.myFollowings = network.data;
      });
  }

  ngAfterViewInit(): void {
    const h = document.getElementById('masonryPanel')?.clientHeight ?? 0;
    this.isHigher = h > document.body.clientHeight;
  }

  ngOnChanges(changes: any): void {
    if (changes?.data?.currentValue) this.masonryImages = changes.data.currentValue;
  }

  isImage(item: any) {
    const coverItem = item.content.find((c: any) => c.cover_type === 'true');
    if (coverItem) return coverItem.media_type === 'moment';
    return item.content[0].media_type === 'moment';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
