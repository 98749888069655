import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mentionLink'
})
export class MentionLinkPipe implements PipeTransform {
  transform(comment: string): string {
    let splitCOmment = comment.split(' ');
    const mention = splitCOmment.map((item: any) => {
      if (item.includes('@')) {
        let userName = item.replace('@', '');
        let url = window.location.origin + '/profile/' + userName;
        return item.replace(
          item,
          `${
            `<a href=${url} target="_blank" class="blue cursor-pointer">` +
            item +
            '</a>'
          }`
        );
      } else {
        return item;
      }
    });
    return mention.join(' ');
  }
}
