import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AccountComponent } from 'src/app/modules/dashboard/pages/account/account.component';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { SideMenuComponent } from 'src/app/modules/dashboard/components/side-menu/side-menu.component';
import { AboutMeComponent } from 'src/app/modules/dashboard/pages/account/tabs/about-me/about-me.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AchievementFormComponent } from 'src/app/modules/dashboard/pages/account/components/achievement-form/achievement-form.component';
import { AchievementCardComponent } from 'src/app/modules/dashboard/pages/account/components/achievement-card/achievement-card.component';
import { MyJobsComponent } from 'src/app/modules/dashboard/pages/my-jobs/my-jobs.component';
import { MyApplicationsComponent } from 'src/app/modules/dashboard/pages/my-jobs/tabs/my-applications/my-applications.component';
import { PreferencesComponent } from 'src/app/modules/dashboard/pages/my-jobs/tabs/preferences/preferences.component';
import { MyJobCardComponent } from 'src/app/modules/dashboard/pages/my-jobs/components/my-job-card/my-job-card.component';
import { NetworkComponent } from 'src/app/modules/dashboard/pages/network/network.component';
import { FollowerCardComponent } from 'src/app/modules/dashboard/pages/network/components/follower-card/follower-card.component';
import { FollowersComponent } from 'src/app/modules/dashboard/pages/network/tabs/followers/followers.component';
import { CommunityComponent } from 'src/app/modules/dashboard/pages/account/tabs/community/community.component';
import { SocialProfilesComponent } from 'src/app/modules/dashboard/pages/account/tabs/social-profiles/social-profiles.component';
import { AccountSettingsComponent } from 'src/app/modules/dashboard/pages/account/tabs/account-settings/account-settings.component';
import { NetworkPreferencesComponent } from 'src/app/modules/dashboard/pages/network/tabs/network-preferences/network-preferences.component';
import { DashboardPaymentComponent } from 'src/app/modules/dashboard/pages/dashboard-payment/dashboard-payment.component';
import { DashboardPaymentBalanceComponent } from 'src/app/modules/dashboard/pages/dashboard-payment/tabs/dashboard-payment-balance/dashboard-payment-balance.component';
import { DashboardPaymentHistoryComponent } from 'src/app/modules/dashboard/pages/dashboard-payment/tabs/dashboard-payment-history/dashboard-payment-history.component';
import { TransferFundsModalComponent } from 'src/app/modules/dashboard/pages/dashboard-payment/components/transfer-funds-modal/transfer-funds-modal.component';
import { DashboardLivestreamsComponent } from 'src/app/modules/dashboard/pages/dashboard-livestreams/dashboard-livestreams.component';
import { DashboardLivestreamsStatsComponent } from 'src/app/modules/dashboard/pages/dashboard-livestreams/tabs/dashboard-livestreams-stats/dashboard-livestreams-stats.component';
import { DashboardLivestreamsReplaysComponent } from 'src/app/modules/dashboard/pages/dashboard-livestreams/tabs/dashboard-livestreams-replays/dashboard-livestreams-replays.component';
import { DashboardLivestreamReplayCardComponent } from 'src/app/modules/dashboard/pages/dashboard-livestreams/tabs/dashboard-livestreams-replays/components/dashboard-livestream-replay-card/dashboard-livestream-replay-card.component';
import { DashboardLivestreamsPreferencesComponent } from 'src/app/modules/dashboard/pages/dashboard-livestreams/tabs/dashboard-livestreams-preferences/dashboard-livestreams-preferences.component';
import { LivestreamDetailModalComponent } from 'src/app/modules/dashboard/pages/dashboard-livestreams/components/livestream-detail-modal/livestream-detail-modal.component';
import { DashboardUsersComponent } from 'src/app/modules/dashboard/pages/dashboard-users/dashboard-users.component';
import { DashboardUsersFollowersComponent } from 'src/app/modules/dashboard/pages/dashboard-users/tabs/dashboard-users-followers/dashboard-users-followers.component';
import { DashboardUsersInfluencersComponent } from 'src/app/modules/dashboard/pages/dashboard-users/tabs/dashboard-users-influencers/dashboard-users-influencers.component';
import { DashboardUsersAffiliatesComponent } from 'src/app/modules/dashboard/pages/dashboard-users/tabs/dashboard-users-affiliates/dashboard-users-affiliates.component';
// import { NgApexchartsModule } from 'ng-apexcharts';
import { ReviewInfluencersComponent } from 'src/app/modules/dashboard/pages/dashboard-users/components/review-influencers/review-influencers.component';
import { InfluencerProfileComponent } from 'src/app/modules/dashboard/pages/dashboard-users/components/influencer-profile/influencer-profile.component';
import { AdminJobsOverviewComponent } from 'src/app/modules/dashboard/pages/my-jobs/tabs/admin-jobs-overview/admin-jobs-overview.component';
import { CampaignDetailModalComponent } from 'src/app/modules/dashboard/pages/messages/tabs/campaign-tab/components/campaign-detail-modal/campaign-detail-modal.component';
import { DashboardStatsComponent } from 'src/app/modules/dashboard/pages/dashboard-stats/dashboard-stats.component';
import { CampaignModalComponent } from 'src/app/modules/dashboard/pages/messages/components/campaign-modal/campaign-modal.component';
import { ScheduleModalComponent } from 'src/app/modules/dashboard/pages/messages/components/schedule-modal/schedule-modal.component';
import { ReviewApplicantComponent } from 'src/app/modules/dashboard/pages/my-jobs/tabs/review-applicant/review-applicant.component';
import { UserPrefrencesComponent } from 'src/app/modules/dashboard/pages/my-jobs/tabs/user-prefrences/user-prefrences.component';
import { FollowingListComponent } from 'src/app/modules/dashboard/pages/network/tabs/following-list/following-list.component';
import { SubscribingListComponent } from 'src/app/modules/dashboard/pages/network/tabs/subscribing-list/subscribing-list.component';
import { SubscribingCardComponent } from 'src/app/modules/dashboard/pages/network/components/subscribing-card/subscribing-card.component';
import { SkillFormComponent } from './pages/account/components/skill-form/skill-form.component';
import { DashboardServicesComponent } from './pages/dashboard-services/dashboard-services.component';
import { DashboardServicesServicesComponent } from './pages/dashboard-services/tabs/dashboard-services-services/dashboard-services-services.component';
import { DashboardServicesOrdersComponent } from './pages/dashboard-services/tabs/dashboard-services-orders/dashboard-services-orders.component';
import { DashboardServicesPreferencesComponent } from './pages/dashboard-services/tabs/dashboard-services-preferences/dashboard-services-preferences.component';
import { OperationHourUnitComponent } from './pages/dashboard-services/tabs/dashboard-services-preferences/components/operation-hour-unit/operation-hour-unit.component';
import { ServiceOrderUnitComponent } from './pages/dashboard-services/tabs/dashboard-services-orders/components/service-order-unit/service-order-unit.component';
import { DashboardSubscriptionComponent } from './pages/dashboard-subscription/dashboard-subscription.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdminSettingComponent } from './pages/account/tabs/admin-setting/admin-setting.component';
import { EventDetailModalComponentComponent } from './pages/messages/components/event-detail-modal-component/event-detail-modal-component.component';
import { ManageCardsComponent } from './pages/dashboard-payment/tabs/manage-cards/manage-cards.component';
import { CardsHistoryComponent } from './pages/dashboard-payment/tabs/cards-history/cards-history.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/env';
import { DashboardSoldServicesComponent } from './pages/dashboard-services/tabs/dashboard-sold-services/dashboard-sold-services.component';
import { ServiceDetailModalComponent } from './pages/dashboard-services/tabs/service-detail-modal/service-detail-modal.component';
import { DashboardPaidServicesComponent } from './pages/dashboard-services/tabs/dashboard-paid-services/dashboard-paid-services.component';
import { FeedbackModalComponent } from './pages/dashboard-services/tabs/feedback-modal/feedback-modal.component';
import { StarRatingModule } from 'angular-star-rating';
import { CustomerRatingsComponent } from './pages/customer-ratings/customer-ratings.component';
import { CustomerAllReviewsModalComponent } from './pages/customer-all-reviews-modal/customer-all-reviews-modal.component';
import { SkillsTabComponent } from './pages/account/tabs/skills-tab/skills-tab.component';
import { DashboardServicesReviewsComponent } from 'src/app/shared/components/dashboard-services-reviews/dashboard-services-reviews.component';
import { DashboardPaidSubscriptionComponent } from './pages/dashboard-subscription/tabs/dashboard-paid-subscription/dashboard-paid-subscription.component';
import { DashboardSoldSubscriptionComponent } from './pages/dashboard-subscription/tabs/dashboard-sold-subscription/dashboard-sold-subscription.component';
import { AccessComponent } from './pages/access/access.component';

@NgModule({
  declarations: [
    AccessComponent,
    FeedbackModalComponent,
    DashboardComponent,
    AccountComponent,
    AboutMeComponent,
    SideMenuComponent,
    CommunityComponent,
    SocialProfilesComponent,
    AccountSettingsComponent,
    AchievementFormComponent,
    AchievementCardComponent,
    MyJobsComponent,
    MyApplicationsComponent,
    PreferencesComponent,
    MyJobCardComponent,
    NetworkComponent,
    FollowerCardComponent,
    FollowersComponent,
    NetworkPreferencesComponent,
    DashboardPaymentComponent,
    DashboardPaymentBalanceComponent,
    DashboardPaymentHistoryComponent,
    TransferFundsModalComponent,
    DashboardLivestreamsComponent,
    DashboardLivestreamsStatsComponent,
    DashboardLivestreamsReplaysComponent,
    DashboardLivestreamReplayCardComponent,
    DashboardLivestreamsPreferencesComponent,
    LivestreamDetailModalComponent,
    DashboardUsersComponent,
    DashboardUsersFollowersComponent,
    DashboardUsersInfluencersComponent,
    DashboardUsersAffiliatesComponent,
    ReviewInfluencersComponent,
    InfluencerProfileComponent,
    AdminJobsOverviewComponent,
    CampaignDetailModalComponent,
    DashboardStatsComponent,
    CampaignModalComponent,
    ScheduleModalComponent,
    ReviewApplicantComponent,
    UserPrefrencesComponent,
    FollowingListComponent,
    SubscribingListComponent,
    SubscribingCardComponent,
    SkillFormComponent,
    DashboardServicesComponent,
    DashboardServicesServicesComponent,
    DashboardServicesOrdersComponent,
    DashboardServicesPreferencesComponent,
    OperationHourUnitComponent,
    ServiceOrderUnitComponent,
    DashboardSubscriptionComponent,
    AdminSettingComponent,
    EventDetailModalComponentComponent,
    ManageCardsComponent,
    CardsHistoryComponent,
    DashboardSoldServicesComponent,
    ServiceDetailModalComponent,
    DashboardPaidServicesComponent,
    CustomerRatingsComponent,
    CustomerAllReviewsModalComponent,
    DashboardServicesReviewsComponent,
    SkillsTabComponent,
    DashboardPaidSubscriptionComponent,
    DashboardSoldSubscriptionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedModule,
    ImageCropperModule,
    NgxStripeModule.forRoot(environment.stripePublishKey), // NgApexchartsModule,
    StarRatingModule.forRoot(),
  ],
})
export class DashboardModule {}
