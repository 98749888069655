<div [ngClass]="feedCardType === 'SUBSCRIPTION' ? 'max-w-[630px] mx-auto' : ''">
  <div *ngIf="service" class="border-normal rounded relative">
    <div class="absolute cursor-pointer top-2 right-2 z-10">
      <button  (click)="showService(service)"
      class="flex w-20 h-fit justify-center px-2 py-1 font-bold border border-[#2887ed] rounded bg-[#2887ed] text-white hover:bg-white hover:text-[#2887ed] text-sm">
      Subscribe</button>
    </div>
    <div class="service-portfolio">
      <img
        class="rounded"
        *ngIf="service.portfolios.length && getCoverType(service?.portfolios)?.media_type == 'image'"
        [src]="getCoverType(service?.portfolios)?.url"
        onerror="this.src='assets/images/bckground-placholder.png'"
      />
      <img
        class="rounded"
        *ngIf="!service.portfolios.length"
        [src]="service.portfolios && service.portfolios[0] && service.portfolios[0]?.url"
        onerror="this.src='assets/images/bckground-placholder.png'"
      />
      <video
        *ngIf="service.portfolios && getCoverType(service?.portfolios)?.media_type == 'video'"
        class="w-full cursor-pointer post-content post-img rounded"
        [src]="getCoverType(service?.portfolios)?.url + '#t=0.1'"
        preload="metadata"
        loop
        [muted]="'muted'"
        playsinline
        webkit-playsinline
      ></video>
    </div>
    <div class="absolute bottom-0 w-full p-2">
      <div class="w-full user-infocard rounded p-2 items-center">
        <div class="flex flex-col items-start">
          <img
            (click)="showProfile()"
            class="avatar object-cover rounded-full cursor-pointer"
            [src]="service.profile && service.profile.profile_img_path"
            onerror="this.src='assets/images/no-image.png'"
          />
        </div>
        <div class="flex flex-col justify-between">
          <div class="flex justify-between title">
            <p class="montserrat-Regular font-bold w-full truncate">
              {{ service.title }}
            </p>
            <div class="ml-2">
              <mat-icon
                [matMenuTriggerFor]="menu"
                class="cursor-pointer three-dots"
                (click)="childClick($event)"
                >more_vert</mat-icon
              >
            </div>
            <mat-menu #menu="matMenu">
              <div class="flex h-12 items-center px-4">
                <app-follow-state
                  [following]="followings"
                  [style]="'cursor-pointer'"
                  [target_user_id]="service.profile?._id"
                >
                </app-follow-state>
              </div>
              <button mat-menu-item (click)="showProfile()">
                <mat-icon style="color: #2887ed">person</mat-icon>
                <span>Profile</span>
              </button>
              <button mat-menu-item (click)="messageUser(service)">
                <mat-icon style="color: #2887ed">chat</mat-icon>
                <span>Message</span>
              </button>
              <button mat-menu-item [matMenuTriggerFor]="exploreMenu">
                <mat-icon style="color: #2887ed; transform: rotate(-45deg)">send</mat-icon>
                <span>Share</span>
              </button>
              <mat-menu #exploreMenu="matMenu">
                <div class="grid grid-cols-3 gap-2 cursor-pointer">
                  <div class="flex flex-col items-center">
                    <button
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
                      (click)="copyLink()"
                    >
                      <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
                    </button>
                    <span class="text-xs">Copy</span>
                  </div>
                  <div class="flex flex-col items-center">
                    <button
                      (click)="sharePost()"
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2"
                    >
                      <mat-icon>share</mat-icon>
                    </button>
                    <span class="text-xs">Share</span>
                  </div>
                  <div class="flex flex-col items-center">
                    <button
                      class="w-10 h-10 flex items-center justify-center rounded-full border border-red-400 p-1"
                    >
                      <mat-icon class="text-red-400">close</mat-icon>
                    </button>
                    <span class="text-xs text-red-400">Cancel</span>
                  </div>
                </div>
              </mat-menu>
            </mat-menu>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex">
              <div class="flex mr-auto items-end">
                <p *ngIf="service?.skill_name" class="mt-1 truncate">
                  {{ service?.skill_name }}
                </p>
                <!-- <button
                  *ngIf="!service?.skill_name && service?.packages?.length"
                  class="subcribe-btn rounded mr-2 ml-4"
                >
                  Subscribe
                </button> -->
              </div>
            </div>
            <div class="flex items-end">
              <p
                class="flex items-end justify-end blue font-bold"
                *ngIf="!service?.isUserCreatedPlan && service?.packages?.length"
              >
                ${{ getLowestPrice(service?.packages) }}
              </p>
              <p
                class="flex items-end justify-end blue font-bold"
                *ngIf="service?.isUserCreatedPlan && service?.packages?.length"
              >
                ${{ getLowestPrice(service?.packages) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-media-interaction-panel
  *ngIf="isShowInteractionPanel"
    [profile]="profile"
    [data]="dataForInteractionPanel"
  ></app-media-interaction-panel>
</div>
