<div class="info-card rounded flex flex-col z-10" *ngIf="user">
  <div class="w-full flex justify-end absolute mt-11 -right-3">
    <mat-menu #menu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="exploreMenu">
        <mat-icon class="font-color" style="transform: rotate(-45deg)">send</mat-icon>
        <span>Share</span>
      </button>
      <div class="flex items-center p-2 cursor-pointer" (click)="readMessage()">
        <mat-icon class="mr-2 font-color text-white fill">mark_chat_unread</mat-icon>
        <span>Messages </span>
      </div>
      <div *ngIf="
          currentLoggedInUserId?.length && currentLoggedInUserId === user?._id
        " class="flex items-center p-2 cursor-pointer" routerLink="{{ '/' + routesConst.dashboard }}">
        <mat-icon class="mr-2 font-color text-white fill">edit</mat-icon>
        <span>Profile</span>
      </div>
      <mat-menu #exploreMenu="matMenu">
        <div class="grid grid-cols-3 gap-2 cursor-pointer">
          <div class="flex flex-col items-center">
            <button class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
              (click)="copyLink()">
              <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
            </button>
            <span class="text-xs">Copy</span>
          </div>
          <div class="flex flex-col items-center">
            <button (click)="share()"
              class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2">
              <mat-icon>share</mat-icon>
            </button>
            <span class="text-xs">Share</span>
          </div>
          <div class="flex flex-col items-center">
            <button class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1">
              <mat-icon>close</mat-icon>
            </button>
            <span class="text-xs">Cancel</span>
          </div>
        </div>
      </mat-menu>
    </mat-menu>
  </div>
  <div class="flex flex-row lg:flex-col gap-2 mt-5 mb-2">
    <img class="mt-0.5 w-14 h-14 object-cover rounded-full border thumbnail-img" [src]="
        user?.profile_image?.length
          ? user?.profile_image
          : 'https://i.postimg.cc/Hk7vyZZM/no-image.png'
      " />
    <div class="flex gap-3 mt-6 lg:mt-0 md:gap-5">
      <div class="flex flex-col">
        <div>
          <p class="truncate profile-name ">{{ user.name }}</p>
        </div>
        <div>
          <p class="truncate profile-name ">{{selectedProfile?.profileCategory?.name ?? 'Category'}}</p>
        </div>
        <div>
          <p class="truncate w-full profil-color user-name mt-1 md:mt-0">
            @{{ user.user_name }}
          </p>
        </div>

      </div>
      <div class="flex flex-col">
        <div class="flex">
          <div class="flex mx-width lg:px-0 items-center ">
            <p class="montserrat-bold text-xs  md:text-sm ">{{ followingN | shortNumber }}</p>
            <p class="ml-1 profil-color text-xs md:text-sm ">
              {{ followingN && followingN > 1 ? "Followings" : "Following" }}
            </p>
          </div>
          <div class="flex ml-2 items-center">
            <p class="montserrat-bold text-xs md:text-sm">{{ followerN | shortNumber }}</p>
            <p class="ml-1 profil-color text-xs md:text-sm">
              {{ followerN && followerN > 1 ? "Followers" : "Follower" }}
            </p>
          </div>
        </div>
        <div class="flex items-center">

          <div class="flex items-center cursor-pointer" *ngIf="selectedProfile && averageRating"
            (click)="openReviewModal()"><span class="montserrat-bold text-xs md:text-sm">{{averageRating |
              number:'1.1-1'}}</span>
            <div class="flex">
              <star-rating *ngIf="averageRating" class="flex profile-rating" [starType]="'svg'" [showHalfStars]="true"
                [disabled]="true" [rating]="averageRating"
                [matTooltip]="'Rating: ' + averageRating.toFixed(1)"></star-rating>
            </div><span class="blue text-xs md:text-sm">({{totalRatings?.toFixed(1)}})</span>
          </div>
        </div>
        <div class="flex items-center mb-3">
          <mat-icon class="location-icon flex items-center justify-center blue">room</mat-icon>
          <p class="user-name truncate profil-color whitespace-nowrap user-location " *ngIf="user?.location?.length">
            {{ user.location[0].city_name
            }}{{
            user.location[0].state_name
            ? " - " + user.location[0].state_name
            : ""
            }}
            {{
            user.location[0].country_code
            ? " - " + user.location[0].country_code
            : ""
            }}
          </p>
        </div>

      </div>

    </div>
  </div>
  <div class="flex justify-between gap-2">
    <app-follow-state [showButton]="true" (isFollowed)="increaseFollower($event)" [following]="following"
      [style]="'cursor-pointer'" [target_user_id]="user._id"></app-follow-state>

    <div class="flex items-center p-2 cursor-pointer profile-btn" (click)="readMessage()">
      Messages
    </div>
    <button class="profile-btn flex" (click)="contactDetails()">
      Contact
    </button>
    <button [matMenuTriggerFor]="exploreMenu" class="profile-btn flex">
      <svg class="w-6 h-6 text-gray-800 dark:text-white ml-2" style="margin-top: -5px; transform: rotate(68deg)"
        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9" />
      </svg>
    </button>


    <!-- <button
      *ngIf="userDetails?.profile?.user_type == userTypeConstant.company"
      class="profile-btn flex"
      (click)="contactDetails()"
    >
    <mat-icon>person_add</mat-icon>
  
    </button> -->
    <!-- <button
      *ngIf="
        currentLoggedInUserId?.length && currentLoggedInUserId === user?._id
      "
      class="flex items-center p-2 cursor-pointer profile-btn"
      routerLink="{{ '/' + routesConst.dashboard }}"
    >
      Profile
  </button> -->

  </div>
  <!-- <div class="user-name-follow mt-4 items-center">
    <div class="follow-button flex">
      <button
        class="flex justify-center h-fit rounded-full p-0 mr-2 items-center"
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
        style="background: black; opacity: 0.5"
      >
        <mat-icon class="three-dot items-center justify-center text-white"
          >more_vert</mat-icon
        >
      </button>
      
    </div>
  </div> -->
</div>