import { routesConst } from '../core/const/routers';

export const userTypeConstant = {
  candidate: 'CANDIDATE',
  company: 'COMPANY',
  admin: 'ADMIN',
};

export const planTypeConstant = {
  pro: 'PRO',
  consumer: 'Consumer',
  recruiter: 'Recruiter',
  enterprise: 'Enterprise',
  elite: 'ELITE',
  business: 'Business',
  nil: 'NIL',
  non_profit: 'Non-Profit',
};

export const sideMenuConstant = {
  account: 'Account',
  services: 'Services',
  subscriptions: 'Subscriptions',
  jobs: 'Jobs',
  messages: 'Messages',
  referrals: 'Referrals',
  payments: 'Payments',
  network: 'Network',
  events: 'Events',
  stats: 'Stats',
  affiliates: 'Affiliates',
  users: 'Users',
};

export const statusConstant = {
  active: 'active',
  inactive: 'inactive',
  rejected: 'rejected',
  pending: 'pending',
};

export const toDisplayConstant = {
  icon: 'icon',
  text: 'text',
  icon_text: 'icon-text',
};

export const loginSignUpConstant = {
  login: 'login',
  signup: 'SignUp',
};

export const searchTypeConstant = {
  user: 'User',
  title: 'Title',
  creators: {
    label: 'Social',
    description: 'Social media community',
    icon: 'groups',
  },
  jobs: {
    label: 'Jobs',
    description: 'Apply or post verified jobs',
    icon: 'work',
  },
  experts: {
    label: 'Experts',
    description: 'Find services and get hired',
    icon: 'design_services',
  },
};

export const intervalConstant = {
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
};

export const addOnTypeConstant = {
  expert: 'Expert',
  recruiter_partner: 'Recruiter Partner',
  influencer: 'INFLUENCER',
  partner: 'Partner',
  business_partner: 'Business Partner',
  non_profit: 'NON-PROFIT',
  enterprise_partner: 'Enterprise Partner',
};

export const messageTypeConstant = {
  pending: 'Pending',
};

export const OperationHours = [
  {
    day_name: 'Monday',
    from_time: '',
    to_time: '',
    closed: false,
  },
  {
    day_name: 'Tuesday',
    from_time: '',
    to_time: '',
    closed: false,
  },
  {
    day_name: 'Wednesday',
    from_time: '',
    to_time: '',
    closed: false,
  },
  {
    day_name: 'Thursday',
    from_time: '',
    to_time: '',
    closed: false,
  },
  {
    day_name: 'Friday',
    from_time: '',
    to_time: '',
    closed: false,
  },
  {
    day_name: 'Saturday',
    from_time: '',
    to_time: '',
    closed: false,
  },
  {
    day_name: 'Sunday',
    from_time: '',
    to_time: '',
    closed: false,
  },
];

export const exploreSheetConstant = {
  data: {
    heading: 'Explore',
    items: [
      {
        item: 'Jobs',
        icon: 'work',
        redirect_url: routesConst.jobs,
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Experts',
        icon: 'design_services',
        redirect_url: routesConst.talents,
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Creators',
        icon: 'groups',
        redirect_url: routesConst.community,
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Features',
        icon: 'add_link',
        redirect_url: '/' + routesConst.features,
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'How It Works',
        icon: 'add_link',
        redirect_url: '/' + routesConst.howItWorks,
        role: ['CANDIDATE', 'COMPANY'],
      },
    ],
  },
};

export const createSheetConstant = {
  data: {
    heading: 'Create',
    items: [
      {
        item: 'Content',
        icon: 'panorama',
        redirect_url: routesConst.content,
        openDialog: 'content',
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Post Jobs',
        icon: 'work',
        redirect_url: routesConst.dashboard + '/' + routesConst.jobs,
        openDialog: 'jobs',
        role: ['COMPANY'],
      },
      {
        item: 'Services',
        icon: 'design_services',
        redirect_url: routesConst.dashboard + '/' + routesConst.services,
        openDialog: 'experts',
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Campaigns',
        icon: 'campaign',
        redirect_url: '/' + routesConst.campaign,
        openDialog: 'campaigns',
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Subscriber Plan',
        icon: 'loyalty',
        redirect_url: routesConst.dashboard + '/' + routesConst.subscription,
        openDialog: 'subscriptions',
        role: ['CANDIDATE', 'COMPANY'],
      },
    ],
  },
};

export const earnSheetConstant = {
  data: {
    heading: 'Earn',
    items: [
      {
        item: 'Payments',
        icon: 'paid',
        redirect_url: routesConst.dashboard + '/' + routesConst.payments,
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Total Earnings',
        icon: 'money',
        redirect_url: routesConst.dashboard + '/' + routesConst.stats,
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Referral Program',
        icon: 'settings_accessibility',
        redirect_url: routesConst.dashboard + '/' + routesConst.referrals,
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Membership Plans',
        icon: 'add_link',
        redirect_url: routesConst.planPricing,
        role: ['CANDIDATE', 'COMPANY'],
      },
      {
        item: 'Member Add Ons',
        icon: 'assistant',
        redirect_url: routesConst.addonPricing,
        role: ['CANDIDATE', 'COMPANY'],
      },
    ],
  },
};

export const notificationTypeConstant = [
  {
    label: 'like',
    icon: 'favorite',
  },
  {
    label: 'follow',
    icon: 'person',
  },
  {
    label: 'share',
    icon: 'send',
  },
  {
    label: 'comment',
    icon: 'mode_comment',
  },
  {
    label: 'subscribe',
    icon: 'subscriptions',
  },
  {
    label: 'message',
    icon: 'mail_outline',
  },
];

export const shareTypeConst = {
  referralLink: 'referralLink',
  job: 'job',
  service: 'service',
  profile: 'profile',
  post: 'post',
  subscription: 'subscription',
};

export class App {
  static readonly TitlePrefix = 'JOBPros - ';
  static readonly TitlePostfix = 'Changing the World One Act at a Time';
}

export const ImagesType = {
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PNG: 'image/png',
  APNG: 'image/apng',
  AVIF: 'image/avif',
  GIF: 'image/gif',
  WEBP: 'image/webp',
  BMP: 'image/bmp',
  XICON: 'image/x-icon',
};

export const RefundStatusConst = {
  PENDING: 'PENDING',
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
};

export const BillingTypeConst = {
  ONE_TIME: 'One Time',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
};

export const ModeratorRoles = {
  ALL: 'ALL',
  JOB_MODERATOR: 'JOB_MODERATOR',
  SERVICE_MODERATOR: 'SERVICE_MODERATOR',
  POST_MODERATOR: 'POST_MODERATOR',
  SUBSCRIPTIONS_MODERATOR: 'SUBSCRIPTIONS_MODERATOR'
};

export const ModeratorStatusConst = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};