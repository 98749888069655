import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/config/config';
import { apiUrlConst } from 'src/app/const/apiConst';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class TalentService {
  constructor(
    private _http: HttpClient,
    private commonService: CommonService
  ) { }

  getAllTalents(filters: any) {
    let params = new HttpParams();
    params = params.append('offset', filters.offset.toString());
    params = params.append('limit', filters.limit.toString());
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getAllTalents,
      options
    );
  }

  getSellerReviews(filters: any) {
    let params = new HttpParams();
    params = params.append('offset', filters.offset.toString());
    params = params.append('limit', filters.limit.toString());
    params = params.append('profileId', filters.profileId);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getSellerReviews,
      options
    );
  }

  getBuyerReviews(filters: any) {
    let params = new HttpParams();
    params = params.append('offset', filters.offset.toString());
    params = params.append('limit', filters.limit.toString());
    params = params.append('profileId', filters.profileId);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getBuyerReviews,
      options
    );
  }

  addFeedBack(payload: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.addFeedback,
      payload
    );
  }

  getSoldServices(filters?: any) {
    // let params = new HttpParams();
    // params = params.append('offset', filters.offset.toString());
    // params = params.append('limit', filters.limit.toString());
    // const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getSoldServices
    );
  }

  removeTalentService(_id: any) {
    let params = new HttpParams();
    params = params.append('_id', _id);
    return this._http.delete(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.removeTalentService +
      '?' +
      params
    );
  }

  patchService(payload: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.deactivateTalentService,
      payload
    );
  }
  
  patchSubscription(payload: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.deactivatePlan,
      payload
    );
  }

  getSingleTalentService(serviceId: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getSingleTalentService,
      {
        params: {
          serviceId: serviceId
        }
      }
    );
  }

  getSingleSubscription(subscriptionId: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getSingleSubscription,
      {
        params: {
          subscriptionId: subscriptionId
        }
      }
    );
  }

  getFilteredTalents(filters: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getFilteredTalents,
      filters
    );
  }

  getTalentServiceCategory() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getTalentServiceCategory
    );
  }

  publicTalentService(filters: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.publicTalentService,
      {
        params: {
          user_name: filters.user_name,
          offset: filters.offset,
          limit: filters.limit,
          mode: filters.mode
        }
      }
    );
  }

  dashboardTalentService(filter: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.dashboardTalentService,
      {
        params: { offset: filter.offset, limit: filter.limit }
      }
    );
  }

  getTalentPreferences() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.talentPreferences
    );
  }

  addTalentPreference(options: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.addTalentPreference,
      {
        ...options
      }
    );
  }

  saveService(options: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.addTalentService,
      {
        ...options
      }
    );
  }

  getStripeUser(redirect_url: string, refresh_url:string) {
    let params = new HttpParams();
    params = params.append('redirect_url', redirect_url);
    params = params.append('refresh_url', refresh_url);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getStripeUser,
      options
    );
  }

  getPlatformStripeUser(redirect_url: string, refresh_url: string) {
    let params = new HttpParams();
    params = params.append('redirect_url', redirect_url);
    params = params.append('refresh_url', refresh_url);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getStripeUser,
      options
    );
  }

  getMember() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.getMember
    );
  }

  purchaseProduct(options: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.purchaseProduct,
      {
        ...options
      }
    );
  }

  checkTrialPeriod() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.checkTrialPeriod
    );
  }

  updateService(options: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.updateTalentService,
      {
        ...options
      }
    );
  }

  updateServicePackage(options: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.updateTalentServicePackage,
      {
        ...options
      }
    );
  }

  addDispute(options: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
      apiUrlConst.addDispute,
      {
        ...options
      }
    );
  }
}
