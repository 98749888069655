<div class="">
<app-part-home ></app-part-home>
</div>
  <!-- <app-part-home [data]="partWorkFlow"></app-part-home> -->
<app-range-slider [data]="homeRangeSliderData" [page]="'home'"></app-range-slider>

<!-- <div class="member-earn-background">
  <div class="flex mx-auto flex-col member-more-main">
    <h3 class="members-earn-title mb-4">Members Earn More</h3>
    <div class="flex pt-2 content-div">
      <div class="mr-6 div-width">
        <h4 class="mb-4 content-title">Jobs</h4>
        <p class="mb-4 content">
          Search job posting based on your experience, skills and interests
        </p>
        <h5 class="flex border-b w-fit items-center learn-link">
          <a [routerLink]="'/' + routesConst.jobs" class="flex items-center"
            >Learn More
            <mat-icon class="arrow-color">arrow_forward</mat-icon></a
          >
        </h5>
      </div>
      <div class="mr-6 div-width">
        <h4 class="mb-4 content-title">Experts</h4>
        <p class="mb-4 content">
          Explore our marketplace of expert members services
        </p>
        <h5 class="flex border-b w-fit items-center learn-link">
          <a [routerLink]="'/' + routesConst.talents" class="flex items-center"
            >Learn More
            <mat-icon class="arrow-color">arrow_forward</mat-icon></a
          >
        </h5>
      </div>
      <div class="mr-6 div-width">
        <h4 class="mb-4 content-title">Creators</h4>
        <p class="mb-4 content">
          See example of how our members promote their content
        </p>
        <h5 class="flex border-b w-fit items-center learn-link">
          <a
            [routerLink]="'/' + routesConst.community"
            class="flex items-center"
            >Learn More
            <mat-icon class="arrow-color">arrow_forward</mat-icon></a
          >
        </h5>
      </div>
      <div class="mr-6 div-width">
        <h4 class="mb-4 content-title">Referrals</h4>
        <p class="mb-4 content">
          See how our members earn 10% income per referral
        </p>
        <h5 class="flex border-b w-fit items-center learn-link">
          <a
            [routerLink]="'/' + routesConst.featuresReferrals"
            class="flex items-center"
            >Learn More
            <mat-icon class="arrow-color">arrow_forward</mat-icon></a
          >
        </h5>
      </div>
    </div>
  </div>
</div> -->

          <!-- <div class="background-color">
            <div class="background-color_wrapper">
              <p class="cols-span-all">Integrated Apps</p>
              <div>
                <img
                  class="h-9"
                  src="../../../assets/images/stripe-logo-home.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-9"
                  src="../../../assets/images/azure-home-logo.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-9"
                  src="../../../assets/images/aws-logo-home.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-9"
                  src="../../../assets/images/auth0-home-logo.png"
                  alt=""
                />
              </div>
            </div>
          </div> -->