import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-hashtag',
  templateUrl: './hashtag.component.html',
  styleUrls: ['./hashtag.component.scss'],
})
export class HashtagComponent implements OnInit {
  unsubscribe$ = new Subject();
  relatedPosts: any[] = [];
  interestList: any[] = [];
  followers: any[] = [];
  profile: any;

  constructor(
    private route: ActivatedRoute,
    private postService: PostService,
    private sharedService: SharedService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: any) => {
        if (params.hashtag) {
          this.getRelatedPosts([params.hashtag]);
        }
      });
    this.getInterestList();

    if (this.authService.loggedIn()) {
      this.dashboard();
      this.getFollowersAndFollowings();
    }
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network: { followings: any[]; followers: any }) => {
        if (network?.followings || network?.followers) {
          this.followers = network.followings;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information: { profileInfo: any } | null) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else {
          const { profileInfo } = information;
          this.profile = profileInfo;
        }
      });
  }

  getInterestList() {
    this.sharedService.skillsOrIntrest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((interest: any) => {
        if (interest.length) {
          this.interestList = interest;
        } else {
          this.sharedService.getSkillsOrIntrest();
        }
      });
  }

  getRelatedPosts(category: string[]) {
    this.postService
      .getRelatedPost(category)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.relatedPosts = res.data;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
