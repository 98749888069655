import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { routesConst } from 'src/app/core/const/routers';
import { AuthComponent } from 'src/app/layouts/auth/auth.component';
import { GeneralComponent } from 'src/app/layouts/general/general.component';
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';
import { AccountComponent } from 'src/app/modules/dashboard/pages/account/account.component';
import { DashboardLivestreamsComponent } from 'src/app/modules/dashboard/pages/dashboard-livestreams/dashboard-livestreams.component';
import { DashboardPaymentComponent } from 'src/app/modules/dashboard/pages/dashboard-payment/dashboard-payment.component';
import { DashboardReferalsComponent } from 'src/app/modules/dashboard/pages/dashboard-referals/dashboard-referals.component';
import { DashboardStatsComponent } from 'src/app/modules/dashboard/pages/dashboard-stats/dashboard-stats.component';
import { DashboardUsersComponent } from 'src/app/modules/dashboard/pages/dashboard-users/dashboard-users.component';
import { MessagesComponent } from 'src/app/modules/dashboard/pages/messages/messages.component';
import { MyJobsComponent } from 'src/app/modules/dashboard/pages/my-jobs/my-jobs.component';
import { NetworkComponent } from 'src/app/modules/dashboard/pages/network/network.component';
import { HomeComponent } from 'src/app/modules/home/home.component';
import { JobsComponent } from 'src/app/modules/jobs/jobs.component';
import { HashtagComponent } from 'src/app/modules/users/pages/hashtag/hashtag.component';
import { PostComponent } from 'src/app/modules/users/pages/post/post.component';
import { ProfileComponent } from 'src/app/modules/users/pages/profile/profile.component';
import { UsersComponent } from 'src/app/modules/users/pages/users/users.component';
import { PrivacyComponent } from 'src/app/modules/privacy/privacy.component';
import { TermsComponent } from 'src/app/modules/terms/terms.component';
import { DsarComponent } from 'src/app/modules/dsar/dsar.component';
import { DisclaimerComponent } from 'src/app/modules/disclaimer/disclaimer.component';
import { TalentsComponent } from 'src/app/modules/talents/talents.component';
import { IndividualServiceComponent } from 'src/app/modules/individual-service/individual-service.component';
import { CommunityComponent } from './modules/dashboard/pages/account/tabs/community/community.component';
import { DashboardServicesComponent } from './modules/dashboard/pages/dashboard-services/dashboard-services.component';
import { DashboardSubscriptionComponent } from './modules/dashboard/pages/dashboard-subscription/dashboard-subscription.component';
import { StripePaymentSuccessComponent } from 'src/app/stripe/stripe-payment-success/stripe-payment-success.component';
import { StripePaymentFailedComponent } from 'src/app/stripe/stripe-payment-failed/stripe-payment-failed.component';
import { DsnComponent } from './modules/dsn/dsn.component';
import { ExploreComponent } from './modules/explore/explore.component';
import { EarnComponent } from './modules/earn/earn.component';
import { CreateComponent } from './modules/create/create.component';
import { CookiesPreferencesComponent } from './modules/cookies-preferences/cookies-preferences.component';
import { PageNotFoundComponent } from 'src/app/modules/users/pages/page-not-found/page-not-found.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { PartFeaturesComponent } from './modules/home/components/part-features/part-features.component';
import { PartPricingComponent } from './modules/home/components/part-pricing/part-pricing.component';
import { UserPlanGuard } from './core/guards/userPlan.guard';
import { FrequentlyAskQuestionComponent } from './shared/components/frequently-ask-question/frequently-ask-question.component';
import { DiscoverComponent } from './shared/components/discover/discover.component';
import { CommunitiesComponent } from './shared/components/communities/communities.component';
import { IndividualSubscriptionComponent } from './modules/individual-subscription/individual-subscription/individual-subscription.component';
import { TutorialsComponent } from './shared/components/tutorials/tutorials.component';

const routes: Routes = [
  {
    path: routesConst.launchPath,
    component: GeneralComponent,
    children: [
      {
        path: routesConst.plans,
        component: HomeComponent,
      },
      {
        path: routesConst.discover,
        component: DiscoverComponent,
      },
      {
        path: routesConst.communities,
        component: CommunitiesComponent,
      },
      {
        path: routesConst.features,
        component: PartFeaturesComponent,
      },
      {
        path: routesConst.instantPay,
        component: PartFeaturesComponent,
      },
      {
        path: routesConst.featuresReferrals,
        component: PartFeaturesComponent,
      },
      {
        path: routesConst.pricing,
        component: PartPricingComponent,
      },
      {
        path: routesConst.planPricing,
        component: PartPricingComponent,
      },
      {
        path: routesConst.addonPricing,
        component: PartPricingComponent,
      },
      {
        path: routesConst.checkPricing,
        component: PartPricingComponent,
      },
      {
        path: routesConst.aboutus,
        component: AboutUsComponent,
      },
      {
        path: routesConst.more,
        component: HomeComponent,
      },
      {
        path: routesConst.content,
        canActivate: [AuthGuard],
        component: HomeComponent,
      },
      {
        path: routesConst.campaign,
        canActivate: [AuthGuard, UserPlanGuard],
        component: HomeComponent,
      },
      // {
      //   path: routesConst.features,
      //   component: HomeComponent,
      // },
      {
        path: routesConst.howItWorks,
        component: HomeComponent,
      },
      {
        path: routesConst.subscribers,
        component: HomeComponent,
      },
      // {
      //   path: routesConst.postJob,
      //   component: HomeComponent,
      // },
      {
        path: 'success',
        component: StripePaymentSuccessComponent,
      },
      {
        path: 'failed',
        component: StripePaymentFailedComponent,
      },
      {
        path: 'profile/:user_name',
        component: ProfileComponent,
      },
      {
        path: '404',
        component: PageNotFoundComponent,
      },
      {
        path: routesConst.launchPath,
        component: HomeComponent,
      },
      {
        path: routesConst.jobs,
        component: JobsComponent,
      },
      {
        path: routesConst.talents,
        component: TalentsComponent,
      },
      {
        path: routesConst.individualService,
        component: IndividualServiceComponent,
      },
      {
        path: routesConst.individualSubscriptoin,
        component: IndividualSubscriptionComponent,
      },
      {
        path: routesConst.privacy,
        component: PrivacyComponent,
      },
      {
        path: routesConst.terms,
        component: TermsComponent,
      },
      {
        path: routesConst.dsar,
        component: DsarComponent,
      },
      {
        path: routesConst.cookies,
        component: DsarComponent,
      },
      {
        path: routesConst.disclaimer,
        component: DisclaimerComponent,
      },
      {
        path: routesConst.preferences,
        component: CookiesPreferencesComponent,
      },
      {
        path: routesConst.dsn,
        component: DsnComponent,
      },
      {
        path: routesConst['do-not-sell'],
        component: DsnComponent,
      },
      {
        path: routesConst.community,
        children: [
          {
            path: '',
            component: UsersComponent,
          },
          {
            path: routesConst.post,
            component: PostComponent,
          },
          {
            path: routesConst.hashtag,
            component: HashtagComponent,
          },
        ],
      },
      {
        path: 'profile/:user_name/post/:postId',
        component: PostComponent,
      },
      {
        path: routesConst.dashboard,
        canActivate: [AuthGuard],
        component: DashboardComponent,
        children: [
          {
            path: routesConst.account,
            component: AccountComponent,
          },
          {
            path: routesConst.services,
            component: DashboardServicesComponent,
          },
          {
            path: routesConst.jobs,
            component: MyJobsComponent,
          },
          {
            path: routesConst.messages,
            component: MessagesComponent,
          },
          {
            path: routesConst.users,
            component: DashboardUsersComponent,
          },
          {
            path: routesConst.network,
            component: NetworkComponent,
          },
          {
            path: routesConst.community,
            component: CommunityComponent,
          },
          {
            path: routesConst.referrals,
            component: DashboardReferalsComponent,
          },
          {
            path: routesConst.subscription,
            component: DashboardSubscriptionComponent,
          },
          // {
          //   path: routesConst.events,
          //   component: DashboardLivestreamsComponent,
          // },
          {
            path: routesConst.payments,
            component: DashboardPaymentComponent,
          },
          {
            path: routesConst.stats,
            component: DashboardStatsComponent,
          },
          {
            path: '**',
            redirectTo: routesConst.account,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
  {
    path: routesConst.auth,
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/authentication/authentication.module').then(
            (module) => module.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: routesConst.faq,
    component: FrequentlyAskQuestionComponent,
  },
  {
    path: routesConst.tutorials,
    component: TutorialsComponent,
  },
  // {
  //   path: routesConst.explore,
  //   component: ExploreComponent,
  // },
  {
    path: routesConst.earn,
    component: EarnComponent,
  },
  {
    path: routesConst.earnReferrals,
    component: EarnComponent,
  },
  {
    path: routesConst.create,
    component: CreateComponent,
  },
  {
    path: '**',
    redirectTo: routesConst.launchPath,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
