import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { SharedService } from '../../services/shared.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { CommonService } from 'src/app/core/services/common.service';
import { OperationHours, shareTypeConst } from 'src/app/const/appConst';
import { ContactDetailsModalComponent } from 'src/app/modules/users/pages/profile/modals/contact-details-modal/contact-details-modal.component';

@Component({
  selector: 'app-people-card',
  templateUrl: './people-card.component.html',
  styleUrls: ['./people-card.component.scss'],
})
export class PeopleCardComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() data?: any;
  @Input() followings?: any[] = [];
  @Input() currentLoggedInUserId: any;
  @Output() followMember: EventEmitter<any> = new EventEmitter();
  @Output() unFollowMember: EventEmitter<any> = new EventEmitter();
  public href: string = '';
  posts?: any[];
  avatar: string = '/assets/images/no-image.png';
  isFollow: boolean = false;
  isShare: boolean = false;
  inProgress: boolean = false;
  loginedUser: any;
  following:any = [];

  constructor(
    private router: Router,
    private networkService: NetworkService,
    private clipboard: Clipboard,
    private snackService: SnackBarService,
    private authService: AuthService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private commonService: CommonService
      ) {} 

  async ngOnInit() {
    this.href = this.router.url;
    if (this.data?.profile.image) this.avatar = this.data.profile.image;
    if (this.authService.loggedIn()) {
      await this.dashboard();
      if (this.loginedUser?._id) {
        this.isFollow = !!this.data?.profile.follower_ids.find(
          (f: string) => f === this.loginedUser._id
        );
          this.getFollowersAndFollowings();
      }
    }
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network) => {
        if (network?.followings || network?.followers) {
          this.following = network.followings;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }
  
  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else {
          const { profileInfo } = information;
          this.loginedUser = profileInfo;
          return profileInfo;
        }
      });
  }

  showPostDetail(post: any) {
    this.router.navigate([
      routesConst.community + '/' + 'post' + '/' + post.post_id,
    ]);
  }

  follow() {
    if (this.authService.loggedIn()) {
      if (this.data?.profile._id) {
        if (!this.isFollow) {
          this.inProgress = true;
          this.networkService
            .followUser(this.data?.profile._id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: any) => {
              if (res.status === 'success') {
                this.isFollow = true;
                this.inProgress = false;
                this.data.profile.totalFollowers =
                  this.data?.profile?.totalFollowers + 1;
                this.data?.profile?.follower_ids.push(this.loginedUser._id);
                this.followMember.emit({
                  ...res.data,
                  user_id: res.data.profile_id,
                });
              }
            });
        } else {
          const id = this.data?.profile?.follower_ids.find(
            (f: any) => f === this.loginedUser._id
          );
          if (id) {
            this.inProgress = true;
            this.networkService
              .unFollow(this.data?.profile._id)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.status === 'Success') this.isFollow = false;
                this.inProgress = false;
                this.data.profile.totalFollowers =
                  this.data?.profile?.totalFollowers - 1;
                this.unFollowMember.emit(id);
              });
          }
        }
      }
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
    }
  }

  share() {
    if (this.authService.loggedIn()) {
      this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px': '100%',
        maxWidth:window.innerWidth > 600 ? '80vw': '100vw',
        data: {
          link:
            this.commonService.location().origin +
            '/profile/' +
            this.data?.profile.user_name,
          isProfile: true,
          shareType: shareTypeConst.profile,
        },
      });
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
    }
  }

  copy() {
    this.clipboard.copy(
      this.commonService.location().origin +
        '/profile/' +
        this.data?.profile.user_name
    );
    this.snackService.showMessage(`Link Copied`, false);
  }

  showProfile() {
    this.router.navigate(['/profile' + '/' + this.data?.profile.user_name]);
  }

  getLocation(location: any) {
    return (
      location?.city_name +
      (location?.state_name ? `- ${location?.state_name} ` : '') +
      (location?.country_code ? `- ${location?.country_code} ` : '')
    );
  }

  readMessage() {
    const { email, name, profile_image, user_name, user_type, _id } = this.data?.profile;
    if (_id != this.currentLoggedInUserId) {
      const userInfo = {
        email,
        name,
        profile_img_path: profile_image,
        user_name,
        user_type,
        _id,
      };
      localStorage.setItem('userId', JSON.stringify(userInfo));
    }
    this.router.navigate(
      ['/' + routesConst.dashboard + '/' + routesConst.messages],
      {
        queryParams: {
          tab: 2,
        },
      }
    );
  }

  contactDetails(profile:any) {
  let operationHours: any[] = OperationHours;
  profile?.operationHours.map((item: any) => {
    const idx = operationHours.findIndex(
      (oh: any) => oh.day_name === item.day_name
    );
    delete item.created_at;
    delete item.profile_id;
    delete item.__v;
    operationHours[idx] = {
      ...operationHours[idx],
      ...item,
    };
  });
    this.dialog.open(ContactDetailsModalComponent, {
      width: '400px',
      panelClass: 'custom-dialog-container',
      data: { user: {profile}, operationHours: operationHours },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
