<div class="search-tabs relative">
  <form class="rounded block px-3 md:px-2" [formGroup]="form">
    <div
      class="flex flex-col md:flex-row gap-0 md:gap-2 flex-1 md:py-0 talent-filter-main"
    >
      <mat-form-field class="talent-desktop-filter w-full">
        <mat-label>Expert Name, Title</mat-label>
        <input
          maxlength="256"
          matInput
          class="w-full"
          type="text"
          formControlName="searchIdx"
          (keyup)="searchByKeyword($event)"
        />
        <mat-icon
          class="cursor-pointer search-icon gray-dark absolute"
          (click)="getFilteredTalents()"
          >search</mat-icon
        >
      </mat-form-field>
      <div class="flex items-center w-full" style="height: 60px">
        <mat-form-field class="example-full-width talent-desktop-filter">
          <mat-label>City</mat-label>
          <input
            (keyup)="onChange($event)"
            type="text"
            placeholder="Type City"
            aria-label="Location"
            #locationSearchInput
            matInput
            formControlName="location"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions"
              value="{{ option.city_name }} - {{ option.state_name }} - {{
                option.country_code
              }}"
              (click)="setLocation(option)"
            >
              {{ option.city_name
              }}{{ option.state_name ? " - " + option.state_name : "" }}
              {{ option.country_code ? " - " + option.country_code : "" }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="flex justify-end w-full md:w-fit" *ngIf="isSearch">
        <button class="clear-filter-btn" (click)="clearAll()">Clear All</button>
      </div>
    </div>
    <div class="filter-list-wrapper">
      <div class="advanced-filters hidden md:flex flex-wrap gap-2 items-center">
        <div class="">
          <mat-select
            multiple
            panelClass="serviceCategory-desk mat-serviceCategory-expansion"
            formControlName="serviceCategory"
            placeholder="Service Categories"
          >
            <div
              class="mb-2"
              *ngFor="let category of serviceCategories; trackBy: trackByFunc"
            >
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header (click)="toggle(chevronIcon)">
                  <mat-panel-title>
                    <span class="font-semibold fs-category">
                      {{ category.name }}</span
                    >
                  </mat-panel-title>
                  <mat-icon #chevronIcon class="absolute right-2"
                    >chevron_right</mat-icon
                  >
                </mat-expansion-panel-header>
                <mat-option
                  *ngFor="let categoryname of category.servicecategory"
                  [value]="categoryname._id"
                  [class]="category.id === '-1' ? 'heading-select' : ''"
                  (click)="getFilteredTalents()"
                  >{{ categoryname.name }}
                </mat-option>
              </mat-expansion-panel>
            </div>
          </mat-select>
        </div>
        <div class="">
          <mat-select
            multiple
            placeholder="Service Type"
            formControlName="serviceType"
          >
            <mat-option
              *ngFor="let type of serviceTypes; trackBy: trackByFunc"
              [value]="type.id"
              (click)="getFilteredTalents()"
            >
              {{ type.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select
            multiple
            placeholder="Price Range"
            formControlName="newest"
          >
            <mat-option
              *ngFor="let option of payRangeList; trackBy: trackByFunc"
              [value]="option.id"
              (click)="getFilteredTalents()"
            >
              {{ option.label }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div class="flex md:hidden">
          <div class="filter-icon-wrapper" (click)="getAllFilters()">
            <svg
              width="18"
              height="18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.895 4H14.5a.5.5 0 01.5.5v.3a.5.5 0 01-.5.5H8.889a2 2 0 01-3.778 0H3.5a.5.5 0 01-.5-.5v-.3a.5.5 0 01.5-.5h1.605a2 2 0 013.79 0zM3 8.852a.5.5 0 01.5-.5h5.608a2 2 0 013.784 0H14.5a.5.5 0 01.5.5v.3a.5.5 0 01-.5.5h-1.608a2 2 0 01-3.784 0H3.5a.5.5 0 01-.5-.5v-.3zM3 13.204a.5.5 0 01.5-.5h1.615a2 2 0 013.77 0H14.5a.5.5 0 01.5.5v.3a.5.5 0 01-.5.5H8.9a2 2 0 01-3.8 0h-1.6a.5.5 0 01-.5-.5v-.3z"
                fill="#2D2D2D"
              ></path>
            </svg>
          </div>
        <swiper
          [navigation]="true"
          [config]="config"
          class="swpier-container services-swipper common-arrow posts-swipper"
        >          
          <ng-template swiperSlide>
            <div class="">
              <mat-select
                multiple
                panelClass="serviceCategory-desk mat-serviceCategory-expansion"
                formControlName="serviceCategory"
                placeholder="Service Categories"
              >
                <div
                  class="mb-2"
                  *ngFor="
                    let category of serviceCategories;
                    trackBy: trackByFunc
                  "
                >
                  <mat-expansion-panel hideToggle>
                    <mat-expansion-panel-header (click)="toggle(chevronIcon)">
                      <mat-panel-title>
                        <span class="font-semibold fs-category">
                          {{ category.name }}</span
                        >
                      </mat-panel-title>
                      <mat-icon #chevronIcon class="absolute right-2"
                        >chevron_right</mat-icon
                      >
                    </mat-expansion-panel-header>
                    <mat-option
                      *ngFor="let categoryname of category.servicecategory"
                      [value]="categoryname._id"
                      [class]="category.id === '-1' ? 'heading-select' : ''"
                      (click)="getFilteredTalents()"
                      >{{ categoryname.name }}
                    </mat-option>
                  </mat-expansion-panel>
                </div>
              </mat-select>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="">
              <mat-select
                multiple
                placeholder="Service Type"
                formControlName="serviceType"
              >
                <mat-option
                  *ngFor="let type of serviceTypes; trackBy: trackByFunc"
                  [value]="type.id"
                  (click)="getFilteredTalents()"
                >
                  {{ type.label }}
                </mat-option>
              </mat-select>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="">
              <mat-select
                multiple
                placeholder="Price Range"
                formControlName="newest"
              >
                <mat-option
                  *ngFor="let option of payRangeList; trackBy: trackByFunc"
                  [value]="option.id"
                  (click)="getFilteredTalents()"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>
  </form>
</div>
