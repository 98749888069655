import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-videos-section',
  templateUrl: './footer-videos-section.component.html',
  styleUrls: ['./footer-videos-section.component.scss']
})
export class FooterVideosSectionComponent implements OnInit {

  @Input() pageTitle: string | undefined;
  innerWidth = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  constructor() { }

  ngOnInit(): void {
  }

}
