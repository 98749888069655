import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SwiperOptions } from 'swiper';
import { SharedService } from '../../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NetworkService } from 'src/app/core/services/network.service';
import { MatDialog } from '@angular/material/dialog';
import { PostService } from 'src/app/core/services/post.service';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { CommonService } from 'src/app/core/services/common.service';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { UploadPostModalComponent } from '../upload-post-modal/upload-post-modal.component';

@Component({
  selector: 'app-post-story',
  templateUrl: './post-story.component.html',
  styleUrls: ['./post-story.component.scss'],
})
export class PostStoryComponent implements OnInit {
  stories: any = [];
  swiper: any;

  @Input() set storyPosts(value: any) {
    this.stories = value;
    const activeIndex = this.swiper?.activeIndex;
    this.swiper?.slideTo(activeIndex - 1);
    this.cdr.detectChanges();
  }
  @Output() listEnd: EventEmitter<any> = new EventEmitter();

  routesConst = routesConst;
  private unsubscribe$ = new Subject();

  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
  };

  postStoryFilters = { offset: 0, limit: 10 };

  user: any;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
    }
  }

  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information: any) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else if (information != null) {
          const { profileInfo } = information;
          this.user = profileInfo;
        }
      });
  }

  onStorySlideChange(swiper: any) {
    if (swiper.isEnd) {
      this.swiper = swiper;
      this.listEnd.emit();
    }
  }

  postContent() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    if (!this.authService.loggedIn()) {
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
    } else {
      this.dialog.open(UploadPostModalComponent, {
        maxWidth: '100vw',
        width: '536px',
        height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
        disableClose: true,
        data: {},
      });
    }
  }

  postDetail(post: any) {
    this.router.navigate([
      routesConst.community + '/' + 'post' + '/' + post._id,
    ]);
  }

  trackByFunc(i: number) {
    return i;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
