import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { MentionService } from '../services/mentions.service';

@Directive({
  selector: '[appMentionTrigger]',
})
export class MentionTriggerDirective {
  constructor(private el: ElementRef, public mentionService: MentionService) {}
  triggerChars = ['@', '#'];
  allowConcat = false;
  inputText: string = '';
  @HostListener('input', ['$event']) onInput(event: any): void {
    let char = event.data;

    if (char === '' || char === null || !char || char === undefined) {
      this.allowConcat = false;
      this.inputText = '';
    }

    if (char && this.triggerChars.includes(char)) {
      this.allowConcat = true;
    }

    if (this.allowConcat) {
      this.inputText += char;
      this.mentionService.handleMentionTrigger(this.inputText);
    }
  }
}
