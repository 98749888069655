import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-livestream-detail-modal',
  templateUrl: './livestream-detail-modal.component.html',
  styleUrls: ['./livestream-detail-modal.component.scss']
})
export class LivestreamDetailModalComponent implements OnInit {
  coverImg?: string | ArrayBuffer;
  uploadForm: FormGroup;
  viewOption: any

  constructor(public fb: FormBuilder) {
    this.uploadForm = this.fb.group({
      title: ['', []],
      description: ['', []],
    })
    this.viewOption = 2
  }

  ngOnInit(): void {
  }

  uploadImage(files: FileList) {
    if (files.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.coverImg = reader.result !== null ? reader.result : undefined
    };
  }
}
