import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TransferFundsModalComponent } from '../../../dashboard-payment/components/transfer-funds-modal/transfer-funds-modal.component';

@Component({
  selector: 'app-event-detail-modal-component',
  templateUrl: './event-detail-modal-component.component.html',
  styleUrls: ['./event-detail-modal-component.component.scss']
})
export class EventDetailModalComponentComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TransferFundsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

}
