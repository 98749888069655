<div class="upload-post-modal flex flex-col h-full bg-normal">
  <div class="upload-post-modal_header flex lg:items-center justify-center relative md:pt-10">
    <div class="flex w-full absolute r-0 justify-end pr-0.5">
      <mat-icon class="cursor-pointer hover:bg-red-200 rounded-full close-icon z-30" mat-dialog-close>cancel</mat-icon>
    </div>
    <h1 class="montserrat-bold black-dark mt-0 hidden lg:block">Livestream Details</h1>
    <h1 class="montserrat-bold black-dark mt-11 block lg:hidden">Stream Details</h1>
  </div>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-1/2 flex flex-col justify-center lg:pr-16">
      <label
        class="portfolio-wrapper w-full justify-center items-center montserrat-bold bg-white blue cursor-pointer overflow-hidden"
        [class]="!coverImg ? 'border border-dashed border-gray-400' : ''">
        <div
          *ngIf="coverImg === undefined"
          class="insert-photo-button bg-white rounded-full">
          <img class="mx-auto" width="100" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Upload+Icon.png" />
          <p class="montserrat-bold text-center uppercase black-dark text-2xl md:text-3xl">
            upload content
          </p>
          <p class="gray-dark text-sm uppercase text-center mt-1">
            jpg, png, gif, mp4
            <br>
            Max size: 2MB
          </p>
          <img class="mx-auto" width="300" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png" />
        </div>
        <div
          *ngIf="coverImg !== undefined"
          class="portfolio bg-contain bg-no-repeat bg-center"
          [ngStyle]="{ 'background-image': 'url(' + coverImg + ')'}"></div>
        <input
          #file
          multiple
          type="file"
          accept='image/*'
          class="uploadFile img w-0 h-0 overflow-hidden"
          value="Upload Media"
          (change)="file.files ? uploadImage(file.files) : ''">
      </label>
    </div>
    <div class="w-full lg:w-1/2 lg:pr-48 mt-10 lg:mt-0" [formGroup]="uploadForm">
      <p class="montserrat-bold">Name of the livestream</p>
      <input
        class="w-full input-form px-4 mt-2"
        type="text"
        formControlName="title"
        placeholder="Add a title">
      <p class="montserrat-bold mt-6">Description</p>
      <textarea
        class="w-full border-normal rounded px-4 py-3.5 mt-2 resize-none h-24"
        type="text"
        formControlName="description"></textarea>

      <p class="black-dark montserrat-bold mt-6 mb-2">Available for</p>
      <mat-radio-group
        aria-label="Select an option"
        class="flex flex-col">
        <mat-radio-button value="1" class="text-center" (click)="viewOption = 1">
          <p class="ml-2">All subscribers</p>
        </mat-radio-button>
        <mat-radio-button value="2" checked class="text-center mt-4" (click)="viewOption = 2">
          <p class="ml-2">Paid suscribers only</p>
        </mat-radio-button>
      </mat-radio-group>
      <div *ngIf="viewOption === 2" class="flex flex-col ml-8 mt-4">
        <mat-checkbox>Basic</mat-checkbox>
        <mat-checkbox class="mt-2">Exclusive</mat-checkbox>
      </div>
      <div class="flex mt-7">
        <label class="flex mt-1.5 cursor-pointer">
          <input id="checkbox2" type="checkbox" class="checkbox1 hidden"/>
          <label class="switchbox" for="checkbox2"></label>
        </label>
        <div class="ml-3.5">
          <p class="montserrat-bold">Send as campaign</p>
          <p class="s black-light mt-2"><span class="blue">Upgrade your plan</span> to send a campaign to your subscribers using this post.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="hidden lg:block mt-auto">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div class="mt-6 pb-20 lg:pb-10 flex flex-col lg:flex-row justify-end">
    <button mat-button class="w-full lg:w-28 button normal">Schedule</button>
    <div class="w-full lg:w-28 lg:ml-2 mt-4 lg:mt-0">
      <button mat-button class="button w-full">Post now</button>
    </div>
  </div>
</div>
