import { Component, OnInit, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ImageService, ImageSnippet } from 'src/app/core/services/image.service';
import { PostService } from 'src/app/core/services/post.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ContentPreviewComponent } from 'src/app/shared/components/content-preview/content-preview.component';
import { MatStepper } from '@angular/material/stepper';
import { ContentTypes } from 'src/app/core/const/options';

interface Interest {
  created_at: string;
  icon: string;
  name: string;
  __v: number;
  _id: string;
}

interface Post {
  content: any;
  format: any;
}
@Component({
  selector: 'app-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.scss'],
})
export class CampaignModalComponent implements OnInit {
  selected = new FormControl(0);
  private unsubscribe$ = new Subject();
  isProcessing = false;
  uploadPost!: any;
  coverPostContent: Post = {
    content: undefined,
    format: '',
  };
  postImages: Post[] = [];
  postImagesPath: Post[] = [];
  hashTags0: Interest[];
  hashTags: string[];
  filteredOptions!: Observable<string[]>;
  uploadForm: FormGroup;
  usertags: string[];
  disclaimers: string[] = [];
  isDisabled: boolean = true;
  viewOption: any;
  myPlans: any[] = [];
  selectedPlan: string[] = [];
  isCampaignPost: boolean = false;
  profile: any;
  allImages: any[] = [];
  selectedIndex: number = 0;
  isLastStep: boolean = false;
  contentTypes = ContentTypes;
  features:any;

  constructor(
    private location: Location,
    private snack: SnackBarService,
    public authService: AuthService,
    private sharedService: SharedService,
    public fb: FormBuilder,
    private imageService: ImageService,
    private profileService: ProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userPlan: userPlans,
    private postService: PostService,
    public dialogRef: MatDialogRef<CampaignModalComponent>,
    private dialog: MatDialog
  ) {
    this.hashTags0 = [];
    this.hashTags = [];
    this.uploadForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(256)]],
      description: ['', [Validators.required, Validators.maxLength(1024)]],
      sendTo: ['', [Validators.required]],
      interest: ['', []],
      asCampaign: [true, []],
      desclaimer: ['', [Validators.maxLength(512)]],
    });
    this.usertags = [];
    this.viewOption = 1;
  }

  ngOnInit(): void {
    this.filteredOptions = this.uploadForm.controls['interest'].valueChanges.pipe(startWith(''), map((value) => this._filter(value)));
    this.uploadPost = new FormGroup({ images: new FormArray([]) });
    this.getInterestList();
    if (this.authService.loggedIn()) {
      this.features = ["Photo Content"];
      this.getMyPlans();
    }
    this.handleEditMode();
    this.getHashTags();
  }

  getHashTags() {
    this.profileService.skills().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.hashTags0 = res.data;
      this.hashTags = this.hashTags0.map((tag) => tag.name);
    });
  }

  handleEditMode() {
    if (this.data?.editmode && this.data?.postId) {
      this.profileService.editPost(this.data.postId).pipe(takeUntil(this.unsubscribe$)).subscribe((post: any) => {
        if (post && post.data && post.data.length) {
          const { title, desclaimer, description } = post.data[0];
          this.uploadForm.controls['title'].setValue(title);
          this.uploadForm.controls['desclaimer'].setValue(desclaimer);
          this.uploadForm.controls['description'].setValue(description);
        }
      });
    }
    this.features = ["Photo Content"];
    this.getMyPlans();
    this.uploadForm.controls['sendTo'].setValue(['All']);
  }

  public errorHandling = (control: string, error: string) => {
    return this.uploadForm.controls[control].hasError(error);
  };

  getMyPlans() {
    this.userPlan.mineSubscribedPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plans: any) => {
      if (plans?.data?.length) {
        this.myPlans = plans.data;
      }
      else {
        this.myPlans = [];
      }
    });
  }

  uploadFile(files: FileList, index: number) {
    let format: string;
    if (files.length === 0) {
      return;
    }
    if (files[0].type.includes('video') && this.selected.value === 0) {
      this.snack.showMessage('Please select Image File',true);
      return;
    } else if (
      (files[0].type.includes('image') && (this.selected.value === 1 ||
      this.selected.value === 2 ||
      this.selected.value === 3))
    ) {
      this.snack.showMessage('Please select Video File',true);
      return;
    }

    if (files[0].type.includes('image') || files[0].type.includes('video')) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event: any) => {
        if (files[0].size < 10485760) {
          if (files[0].type.indexOf('image') > -1) {
            format = this.selected.value == 4 ? 'image' : this.contentTypes[this.selected.value??1].id;
            if (index === 0) {
              this.coverPostContent = {
                content: reader.result !== null ? reader.result : undefined,
                format,
              };
            } else {
              this.postImages[index - 1] = {
                content: reader.result !== null ? reader.result : undefined,
                format,
              };
            }
          } else if (files[0].type.indexOf('video') > -1) {
            format = this.selected.value == 4 ? 'video' : this.contentTypes[this.selected.value ?? 2].id;
            const video = document.createElement('video');
            video.src = event.target.result;
            video.onloadedmetadata = () => {
              if (
                video.duration >
                this.contentTypes[this.selected.value ?? 2].duration
              ) {
                this.snack.showMessage(
                  `Please Upload video of ${this.contentTypes[this.selected.value ?? 2].duration
                  } Seconds`,true
                );
              } else {
                if (index === 0) {
                  this.coverPostContent = {
                    content: reader.result !== null ? reader.result : undefined,
                    format,
                  };
                } else {
                  this.postImages[index - 1] = {
                    content: reader.result !== null ? reader.result : undefined,
                    format,
                  };
                }
              }
            };
          }
        }
        else{
          this.snack.showMessage(`Size exceeded`,true);
        }
      };


      const file: File = files[0];
      reader.addEventListener('load', (event: any) => {
        this.postImagesPath[index] = {
          content: event.target.result,
          format,
        };
        const newImagePath = {
          cover_type: index == 0 ? 'true' : 'false',
          media_type: format,
          url: event.target.result,
          file,
        };
        this.allImages[index] = newImagePath;
        // this.imageService
        //   .imageConvertion(new ImageSnippet(event.target.result, file))
        //   .then((imageUri: any) => {
        //     this.postImagesPath[index] = {
        //       content: imageUri[0],
        //       format,
        //     };
        //     const newImagePath = {
        //       cover_type: index == 0 ? 'true' : 'false',
        //       media_type: format,
        //       url: imageUri[0],
        //     };
        //     this.allImages[index] = newImagePath;
        //     this.savePost = false;
        //   });
      });
      const formImagesControl = <FormArray>this.uploadPost.get('images');
      const control = new FormControl(null, Validators.required);
      if (formImagesControl.length < 5 && index == formImagesControl.length) {
        formImagesControl.push(control);
      }
    } else {
      this.snack.showMessage('File Type Not Supported',true);
      return;
    }
  }

  setInterest(interest: string) {
    this.hashTags = this.hashTags.filter((item) => item !== interest);
    this.usertags.push(interest);
    this.uploadForm.get('interest')?.setValue('');
  }

  setDisclaimer() {
    if (
      this.uploadForm.get('desclaimer')?.value.length > 0 &&
      this.uploadForm.get('desclaimer')?.value.replace(/\s/g, '')
    ) {
      if (
        this.disclaimers.find(
          (x) =>
            x.toLowerCase().trim() ===
            this.uploadForm.get('desclaimer')?.value.toLowerCase().trim()
        )
      ) {
        this.snack.showMessage('Disclaimer Already Exist',true);
        return;
      }
      this.disclaimers?.push(this.uploadForm.get('desclaimer')?.value);
      this.uploadForm.get('desclaimer')?.setValue('');
    }
  }

  removeInterest(interest: string) {
    this.usertags = this.usertags.filter((item) => item !== interest);
    this.hashTags.push(interest);
  }

  removeDisclaimer(desclaimer: string) {
    this.disclaimers = this.disclaimers.filter((item) => item !== desclaimer);
  }

  counter(i: number) {
    return new Array(i);
  }

  getInterestList() {
    this.sharedService.skillsOrIntrest$.pipe(takeUntil(this.unsubscribe$)).subscribe((interest: any) => {
      if (interest.length) {
        this.hashTags0 = interest;
      } else {
        this.sharedService.getSkillsOrIntrest();
      }
    });
  }

  async convertImages(item: any, index: any) {
    await new Promise(async resolve => {
      await this.imageService.imageConvertion(new ImageSnippet(item.url, item.file)).then((imageUri: any) => {
        this.postImagesPath[index] = {
          content: imageUri[0],
          format: item.media_type,
        };
        const newImagePath = {
          cover_type: index == 0 ? 'true' : 'false',
          media_type: item.media_type,
          url: imageUri[0],
        };
        this.allImages[index] = newImagePath;
      });
      resolve(true);
    });
  }

  async postContent() {
    this.isProcessing = true;
    let promises: any[] = [];
    this.allImages.forEach(async (item: any, index: any) => {
      promises.push(this.convertImages(item, index));
    });
    Promise.all(promises).then(() => {
      if (!this.authService.loggedIn()) {
        this.snack.showMessage('Please login to Post',true);
        setTimeout(() => {
          this.isProcessing = false;
        }, 1500);
        return;
      }
      else {
        if (
          this.uploadForm.valid &&
          this.postImagesPath[0] &&
          this.postImagesPath[0].content
        ) {
          const title = this.uploadForm.value['title'];
          const description = this.uploadForm.value['description'];
          const desclaimer = this.disclaimers;
          const send_to = this.uploadForm.value['sendTo'];
          const category = this.usertags.map((name) => this.hashTags0.find((tag) => tag.name === name)).map((tag) => tag?._id);
          const campaign_post = this.uploadForm.value['asCampaign'];
          const images = this.postImagesPath.filter((post) => post.content !== '').map((post, index): any => {
            return {
              url: post.content,
              cover_type: index === 0,
              media_type: post.format,
            };
          });
          const payload = {
            title,
            description,
            desclaimer,
            category,
            campaign_post,
            images,
            send_to,
          };
          this.postService.uploadPost(payload).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
            if (res) {
              this.snack.showMessage('Campaign Post successfully Created.',false);
              this.isProcessing = false;
              this.dialogRef.close();
            }
            () => {
              this.snack.showMessage(
                'Error In creating post Please try again later...',true
              );
              this.isProcessing = false;
            };
          });
        }
      }
    })
  }
  sendTo(label: any) {
    this.viewOption = 1;
    let sub = [];
    sub.push(label);
    this.uploadForm.controls['sendTo'].setValue(sub);
  }

  change(event: any) {
    if (event.checked) {
      this.selectedPlan.push(event.source.value);
    } else {
      let index = this.selectedPlan.findIndex(
        (x: any) => x == event.source.value
      );
      if (index > -1) {
        this.selectedPlan.splice(index, 1);
      }
    }
    this.uploadForm.controls['sendTo'].setValue(this.selectedPlan);
  }

  sendAsCampaign(event: any) {
    this.isCampaignPost = event.target.checked;
    this.uploadForm.controls['asCampaign'].setValue(this.isCampaignPost);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (filterValue !== '') {
      return this.hashTags.filter((tag) => tag.toLowerCase().includes(filterValue)).map((tag) => tag);
    }
    return this.hashTags;
  }

  close() {
    // this.location.back()
    this.dialogRef.close();
  }

  preview(image: any, index: any) {
    this.postImages[index] = this.coverPostContent;
    this.coverPostContent = image;
  }

  goToNext(stepper: MatStepper) {
    if (!this.isLastStep) {
      this.isLastStep = true;
      stepper.next();
    }
  }

  goToPrev(stepper: MatStepper) {
    this.isLastStep = false;
    stepper.previous();
  }

  getArray(size: number) {
    let array = [0];
    for (let i = 1; i < size; i++) {
      array.push(i);
    }
    return array;
  }

  resetImages() {
    this.postImages = [];
    this.coverPostContent = {
      content: undefined,
      format: '',
    };
    if (this.selected.value === 0) {
      this.features = ["Photo Content"];
    }
    else if (this.selected.value === 4) {
      this.features = ["Photo Content", "Video Content"];
    }
    else {
      this.features = ["Video Content"];
    }
    this.getMyPlans();
  }

  checkFeatures(planFeatures:any){
    return !planFeatures.some((f: any) => this.features.includes(f))
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
