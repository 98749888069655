import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  EventEmitter,
  Output
} from '@angular/core';
import { Subject, Subscription, takeUntil, timer } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';

@Directive({
  selector: '[appInViewLogger]'
})
export class InViewLoggerDirective implements OnInit, OnChanges, OnDestroy {
  @Input('appInViewLogger') data!: any;
  @Output() viewLogged = new EventEmitter<any>(); // EventEmitter to send data to the component
  private observer!: IntersectionObserver;
  private timerSubscription!: Subscription;
  private timeoutDuration = 1000; // 1 seconds
  private hasLogged = false;
  private unsubscribe$ = new Subject();

  constructor(
    private el: ElementRef,
    private postService: PostService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.initObserver();
  }

  ngOnChanges() {
    this.initObserver();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();

    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private initObserver() {
    this.observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.handleEnterView();
          } else {
            this.handleExitView();
          }
        });
      },
      { threshold: 0.5 }
    ); // Adjust threshold to trigger when element is about 50% in view

    this.observer.observe(this.el.nativeElement);
  }

  private handleEnterView() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerSubscription = timer(this.timeoutDuration).subscribe(() => {
      // if (this.isElementInCenter()) {
      if (this.data.isMobileView || this.data.isSingleGrid) {
        this.addViews(this.data.postId, this.data.type);
        this.hasLogged = true;
      }
      // }
    });
  }

  private handleExitView() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  private isElementInCenter(): boolean {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    const elementCenterY = rect.top + rect.height / 2;
    const elementCenterX = rect.left + rect.width / 2;

    const inCenter =
      elementCenterY >= viewportHeight / 2 - rect.height / 2 &&
      elementCenterY <= viewportHeight / 2 + rect.height / 2 &&
      elementCenterX >= viewportWidth / 2 - rect.width / 2 &&
      elementCenterX <= viewportWidth / 2 + rect.width / 2;
    return inCenter;
  }

  addViews(postId: any, type: string) {
    // if (this.authService.loggedIn()) {
    const payload = {
      content_id: postId,
      type: type
    };
    this.postService
      .updateViews(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.viewLogged.emit({ postId, success: true }); // Emit data on success
      });
    // }
  }
}
