<div class="register-page" [class]="loading ? 'spinner' : ''">
  <!-- <div class="register-page_header flex justify-center items-center bg-black">
    <a routerLink="/" class="flex flex-col items-center" title="Go back to jobpros.io">
      <img src="../../../../assets/images/w-logo.png" class="h-7" />
    </a>
  </div> -->
  <mat-horizontal-stepper labelPosition="bottom" #stepper>
    <!-- <mat-step>
      <h1 class="register-page_title mt-8 lg:mt-10 mx-auto text-center montserrat-bold title">
        What type of account represents you?
      </h1>
      <div class="flex justify-center mt-6 lg:mt-10 step-first">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="type">
          <mat-radio-button [value]="userTypeConstant.company" class="text-center">
            <mat-icon class="blue">business</mat-icon>
            <h3 class="montserrat-bold">Company</h3>
            <p class="option-desc">
              You are creating an account on behalf of your company
            </p>
          </mat-radio-button>
          <mat-radio-button [value]='userTypeConstant.candidate' checked class="text-center ml-0 lg:ml-4 mt-4 lg:mt-0">
            <mat-icon class="blue">person</mat-icon>
            <h3 class="montserrat-bold">Individual</h3>
            <p class="option-desc">
              You are creating an account under your personal information
            </p>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="flex w-full items-center justify-center mt-4 lg:mt-10 mb-16">
        <button mat-button matStepperNext class="button" (click)="focusLocation()">CONTINUE</button>
      </div>
    </mat-step>
    <mat-step>
      <button mat-button matStepperPrevious class="absolute -mt-16 pt-2 lg:pt-0 lg:mt-3 ml-2 lg:ml-24">
        <mat-icon class="blue arrow-back">west</mat-icon>
      </button>
      <h1 class="register-page_title mt-16 lg:mt-10 pt-2 lg:pt-0 mx-auto text-center montserrat-bold title">
        Create Your Profile
      </h1>
      <div class="flex justify-center mt-10 lg:mt-20">
        <div class="flex flex-col lg:flex-row w-full lg:w-auto">
          <div class="flex flex-col">
            <h4 class="montserrat-bold">Add a logo</h4>
            <div class="flex items-center mt-6">
              <label for="input-img" class="portfolio-wrapper rounded-full montserrat-bold blue cursor-pointer">
                <div *ngIf="avatar === undefined" class="insert-photo-button bg-white rounded-full">
                  <mat-icon class="gray-dark">add_a_photo</mat-icon>
                </div>
                <div *ngIf="avatar !== undefined" class="portfolio bg-contain bg-no-repeat bg-center"
                  [ngStyle]="{ 'background-image': 'url(' + avatar + ')' }"></div>
              </label>
              <label class="portfolio-wrapper ml-6 montserrat-bold blue cursor-pointer">
                <p mat-raise-button class=" cursor-pointer button normal">
                  Choose image
                </p>
                <input #file multiple type="file" class="uploadFile hidden img w-0 h-0 overflow-hidden" id="input-img"
                  value="Upload Media" (change)="file.files ? uploadImage(file.files) : ''" />
              </label>
            </div>
          </div>
          <div class="flex flex-col ml-0 lg:ml-20 mt-6 lg:mt-0">
            <mat-label>City
            </mat-label>
            <div class="relative w-full lg:w-fit">
              <mat-icon class="absolute right-2 top-3 cursor-pointer" *ngIf="this.city != ''"
                (click)="changeLocation()">close</mat-icon>
              <input maxlength="256" type="text" placeholder="Type City" #searchLocation [formControl]="location"
                [matAutocomplete]="auto" class="input-form w-full" [readonly]="this.city != ''" />
            </div>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let location of locations"
                value="{{ location.city_name }} - {{ location.state_name }} - {{location.country_code}}"
                (click)="city = location._id">
                {{ location.city_name }}{{ location.state_name ? ' - ' + location.state_name : '' }} {{
                location.country_code ? ' - ' + location.country_code : '' }}
              </mat-option>
            </mat-autocomplete>
            <textarea [(ngModel)]="bio" class="over-view border-normal rounded w-full mt-4"
              placeholder="Write your bio (optional)"></textarea>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full items-center justify-center mt-6 lg:mt-10">
        <button mat-button matStepperNext class="button">
          Continue
        </button>
      </div>
    </mat-step> -->
    <mat-step>
      <button mat-button matStepperPrevious class="absolute -mt-16 pt-2 lg:pt-0 lg:mt-3 ml-2 lg:ml-24">
        <mat-icon class="blue arrow-back">west</mat-icon>
      </button>
      <h1 class="register-page_title mt-16 lg:mt-10 mx-auto text-center montserrat-bold title">
        Select Username
      </h1>
      <h4 class="mx-auto text-center mt-4" style="max-width: 612px">
        Join JOBPros Community
      </h4>
      <div class="flex flex-col items-center justify-center mt-14">
        <input maxlength="129" class="input-form w-full mb-4" type="text" [(ngModel)]="user_name"
          (ngModelChange)="usernameUpdate.next($event)" (keydown)="validate()" />
        <div *ngIf="usernameValidationError && user_name?.trim()?.length">
          <p class="s red">User Name already exist. Please use another username.</p>
        </div>
        <div *ngIf=" user_name && user_name.length > 128">
          <p class="s red">User Name cannot exceed more than 128 characters!</p>
        </div>
        <div *ngIf="hasWhiteSpace(user_name)" [class]="hasWhiteSpace(user_name) ? 'pb-4' : ''">
          <p class="s red">User Name not contain white spaces.</p>
        </div>
        <div class="flex">
          <p class="s gray-dark">
            {{
            !user_name || !user_name?.trim()?.length || hasWhiteSpace(user_name)
            ? "Choose a unique username."
            : "Your public profile will be: https://jobpros.io/profile/"+user_name
            }}
          </p>
        </div>
      </div>
      <div class="flex w-full items-center justify-center mt-10">
        <button mat-button matStepperNext class="button"
          [class]="hasWhiteSpace(user_name) || validationChecking || usernameValidationError || !user_name.trim().length || user_name.length>128 || isSaving ? 'disabled' : '' "
          (click)="register()"
          [disabled]="hasWhiteSpace(user_name) || validationChecking || usernameValidationError || !user_name.trim().length || user_name.length>128 || isSaving">
          CONTINUE
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>