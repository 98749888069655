import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  SwiperOptions,
} from 'swiper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { JobService } from 'src/app/core/services/job.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { Router } from '@angular/router';
import { shareTypeConst } from 'src/app/const/appConst';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-dashboard-referals-connection',
  templateUrl: './dashboard-referals-connection.component.html',
  styleUrls: ['./dashboard-referals-connection.component.scss'],
})
export class DashboardReferalsConnectionComponent implements OnInit {
  private unsubscribe$ = new Subject();
  isReferralPlan = true;
  plans: any;
  addOns: any;
  generatedRefLink: any;
  isOnboardCompleted: any = false;
  purchasedPlan: any;
  purchasedAddOn: any;
  userType: any;
  showSpinner = false;
  forIndividual: boolean = true;
  rewardList: any;

  swiperConfig: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
      },
    },
  };

  @Output() yourReferral = new EventEmitter();

  constructor(
    private clipboard: Clipboard,
    private snack: SnackBarService,
    private profileService: ProfileService,
    private jobService: JobService,
    private authService: AuthService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private commonService: CommonService,
    private router: Router
  ) // private snackService: SnackBarService,
  // private spinner: NgxSpinnerService,
  // private userPlan: userPlans,
  // private talentService: TalentService,
  // private stripePaymentService: paymentService,
  {
    // this.getJobProsPlans();
    this.getPlateformPlansAndAddons();
  }

  ngOnInit(): void {
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userPurchased: any) => {
        this.purchasedPlan = userPurchased.currentPlan;
        this.purchasedAddOn = userPurchased.currentAddOns;
        this.profileService.purchasedPlan.next(userPurchased.currentPlan);
        this.profileService.purchasedAddOn.next(userPurchased.currentAddOns);
        if (userPurchased?.currentAddOns?.length) {
          this.referralCode();
        }
      });
    if (this.authService.loggedIn()) {
      this.getUserType();
    }
    this.profileService.purchasedPlan
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plan: any) => {
        this.purchasedPlan = plan;
      });
  }

  getUserType() {
    this.sharedService.userType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((type: any) => {
        if (type === '') {
          this.sharedService.getUserType();
        } else {
          this.userType = type;
        }
      });
  }

  // getJobProsPlans() {
  //   this.sharedService.jobprosPlans$.pipe(takeUntil(this.unsubscribe$)).subscribe((plans: any) => {
  //     if (plans.length) {
  //       this.plans = plans;
  //       if (this.forIndividual) {
  //         this.rewardList = this.plans.filter(
  //           (reward: any) => reward.consumer_type === userTypeConstant.candidate
  //         );
  //       } else {
  //         this.rewardList = this.plans.filter(
  //           (reward: any) => reward.consumer_type === userTypeConstant.company
  //         );
  //       }
  //     } else {
  //       this.sharedService.jobProsPlansAndAdOns();
  //     }
  //   });
  // }

  getPlateformPlansAndAddons() {
    this.jobService
      .getPlanList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.plans = result.plans;
        this.addOns = result.addOns;
      });
  }

  isDashboardAddonIndividual(value: boolean) {
    this.forIndividual = value;
    // this.getPlanList();
  }

  /**
   * Get Referral Code
   */
  referralCode() {
    this.profileService
      .getReferralCode()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((referral: any) => {
        this.getReferralLink(referral.code);
      });
  }

  /**
   * Get User Referral Link
   * @param referralCode
   */
  getReferralLink(referralCode: string) {
    this.generatedRefLink = `${this.commonService.location().origin
      }/signup?referralLink=${referralCode}`;
  }

  referral() {
    this.yourReferral.emit();
  }

  /**
   * Copy to Clipboard
   */
  copytoClipBoard() {
    let isCoppied = this.clipboard.copy(this.generatedRefLink);
    if (isCoppied) {
      this.snack.showMessage(`Referral Link Copied...`,false);
    } else {
    }
  }

  // getPlanList() {
  //   if (this.forIndividual) {
  //     this.rewardList = this.plans?.filter(
  //       (reward: any) => reward.consumer_type === userTypeConstant.candidate
  //     );
  //   } else {
  //     this.rewardList = this.plans?.filter(
  //       (reward: any) => reward.consumer_type === userTypeConstant.company
  //     );
  //   }
  // }

  // async upgrade(plan: any) {
  //   this.showSpinner = true;
  //   if (!this.authService.loggedIn()) {
  //     this.snackService.showMessage(`Please Login to Purchase....`);
  //     return;
  //   }
  //   if (
  //     this.purchasedPlan?.plan_type == 'ELITE' ||
  //     this.purchasedPlan?.plan_type == 'NIL' ||
  //     this.purchasedPlan?.plan_type == 'Enterprise'
  //   ) {
  //     this.spinner.show();
  //     this.userPlan.mineSubscribedPlan().pipe(takeUntil(this.unsubscribe$)).subscribe((plans: any) => {
  //        // this.spinner.hide();
  //       if (plans?.data?.length) {
  //         var myPlans = plans.data.find((x: any) => x.active == true);
  //       }
  //       if (myPlans?.active) {
  //         const dialogRef = this.dialog.open(ConfirmModalComponent, {
  //           width: '500px',
  //           data: {
  //             message: `your all created plans and subscriptions will be deactivated`,
  //           },
  //         });
  //         dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
  //           if (result) {
  //             this.purchaseSubscription(plan, true);
  //           }
  //         });
  //       } else {
  //         this.purchaseSubscription(plan);
  //       }
  //     });
  //   } else {
  //     this.purchaseSubscription(plan);
  //   }
  // }

  // purchaseSubscription(plan: any, deleteAllPlan?: any) {
  //   this.spinner.show();
  //   this.talentService
  //     .getPlatformStripeUser(this.commonService.location().href).pipe(takeUntil(this.unsubscribe$))
  //     .subscribe((res: any) => {
  //        // this.spinner.hide();
  //       if (res.status == 'on_board_required') {
  //         this.snackService.showMessage(`${res.message}`);
  //         this.commonService.location().href = res.accountLink.url;
  //       } else {
  //         if (plan.consumer_type == this.userType) {
  //           this.spinner.show();
  //           this.stripePaymentService.publicPrices().pipe(takeUntil(this.unsubscribe$)).subscribe(
  //             (res: any) => {
  //                // this.spinner.hide();
  //               if (res?.publicPrice?.length) {
  //                 let avialbleProduct = res.publicPrice.find(
  //                   (price: any) => price.id == plan.stripe_product_id
  //                 );
  //                 if (avialbleProduct) {
  //                   avialbleProduct.features = plan.features;
  //                   avialbleProduct.isAddOn = false;
  //                   const dialogRef = this.dialog.open(
  //                     PaymentCheckoutModalComponent,
  //                     {
  //                       maxWidth: '100vw',
  //                       width: '600px',
  //                       height: '884px',
  //                       data: avialbleProduct,
  //                     }
  //                   );
  //                   dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
  //                     this.showSpinner = false;
  //                     if (result && deleteAllPlan) {
  //                       this.userPlan.deleteAllPlan().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
  //                          // this.spinner.hide();
  //                       });
  //                     }
  //                   });
  //                 } else {
  //                    // this.spinner.hide();
  //                   this.snackService.showMessage(`Plan not Available`);
  //                 }
  //               } else {
  //                  // this.spinner.hide();
  //                 this.snackService.showMessage(`Unable to Find Plan Prices`);
  //               }
  //             },
  //             (err) => {
  //                // this.spinner.hide();
  //               this.snackService.showMessage(`${err}`);
  //             }
  //           );
  //         } else {
  //            // this.spinner.hide();
  //           this.snackService.showMessage(
  //             'You are not able to Purchase this Subscription'
  //           );
  //         }
  //       }
  //     });
  // }

  // share button was changed to copy button... Here is the logic for share button if we want to use in future
  
  // shareReferralLink(referralLink: string) {
  //   if (this.authService.loggedIn()) {
  //     this.dialog.open(ShareModalComponent, {
  //       width: '1040px',
  //       data: {
  //         link: referralLink,
  //         isProfile: true,
  //         shareType: shareTypeConst.referralLink
  //       },
  //     });
  //   } else {
  //     this.router.navigate(['/']);
  //     this.dialog.open(LoginSignupDialogComponent, {
  //       width: '400px',
  //       height: 'h-fit',
  //       maxWidth: '96%',
  //       maxHeight: '600',
  //       data: { pageToOpen: 'login' },
  //     });
  //   }
  // }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
