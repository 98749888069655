<div *ngIf="innerWidth && innerWidth >= 768" class="m-w mx-auto md:px-4 lg:px-0">
  <div *ngFor="let item of data" class="flex flex-col items-start card-style cursor-pointer border-b"
  [ngStyle]="{'background-image': 'url(' + item.img + ')'}" (click)="navigate($event, item)">
    <img class="h-16 block" *ngIf="item?.logo" [src]="item.logo" />
    <div class="mt-auto" [ngClass]="item?.logo ? 'mt-0 md:mt-10':''">
      <p class="text-lg text-center title" *ngIf="item?.title">{{ item.title }}</p>
      <!-- <div class="mt-2 md:mt-3"> -->
        <!-- <p class="description text-center" *ngIf="item?.description">
          {{ item.description }}
        </p> -->
        <!-- <button class="btn-style font-bold rounded-full block mx-auto mt-4" *ngIf="item?.label" (click)="openModal(item)">{{ item.label }}</button>
      </div> -->
    </div>
  </div>
</div>

<div *ngIf="innerWidth && innerWidth < 768" class="m-w mx-auto md:px-4 lg:px-0">
  <swiper [pagination]="{ clickable: true }" [config]="config">
    <ng-template *ngFor="let item of data" swiperSlide>
  <div class="flex flex-col items-start card-style cursor-pointer border-b"
  [ngStyle]="{'background-image': 'url(' + item.img + ')'}" (click)="navigate($event, item)">
 
    <img class="h-16 block" *ngIf="item?.logo" [src]="item.logo" />
    <div class="mt-auto" [ngClass]="item?.logo ? 'mt-0 md:mt-10':''">
      <p class="text-lg text-center title" *ngIf="item?.title">{{ item.title }}</p>
    </div>   
  </div>
</ng-template>
</swiper>
</div>





