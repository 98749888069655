import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav-card',
  templateUrl: './nav-card.component.html',
  styleUrls: ['./nav-card.component.scss']
})
export class NavCardComponent implements OnInit {
  @Input() imgPath: string | undefined;
  @Input() title: string | undefined;
  @Input() description: string | undefined;
  @Input() label: string | undefined;
  @Input() url: string | undefined;
  @Input() disabled: boolean = false;

  constructor() {


  }

  ngOnInit(): void { }
}
