<div class="grid-wrapper gap-5">
  <div
    *ngFor="let item of planList"
    class="payment-option-card w-full relative flex text-left flex-col"
  >
    <ngx-spinner
      bdOpacity="0.9"
      bdColor="black-dark"
      size="medium"
      color="red"
      type="ball-spin-clockwise"
      [fullScreen]="true"
      class="job-list-spinner"
    >
    </ngx-spinner>
    <div
      class="flex justify-center p-2 bg-white hidden"
      style="width: calc(100% + 4px); margin: 0 -2px"
    ></div>
    <h4
      class="break-words font-bold font-style-title border border-gray-300 border-heading text-white text-center capitalize p-3 bg-black"
    >
      {{ item.title.toLowerCase() }}
    </h4>
    <div class="border border-gray-300 border-remove">
      <div
        class="payment-option-card_header flex flex-col justify-between text-center h-30 bg-white px-4"
      >
        <img
          *ngIf="item.isUserCreatedPlan && !item?.contentDetails?.length"
          class="h-52"
          [src]="item?.contentDetails[0]?.url"
          onerror="this.src='assets/images/no-image.png'"
        />
        <img
          class="h-52"
          *ngIf="item?.contentDetails?.length && item?.contentDetails[0]?.media_type == 'image'"
          [src]="item?.contentDetails[0]?.url"
        />
        <video
          class="h-52"
          *ngIf="item?.contentDetails?.length && item?.contentDetails[0]?.media_type == 'video'"
          [src]="item?.contentDetails[0]?.url+ '#t=0.1'"  preload="metadata"
          controls
          [muted]="'muted'"
          playsinline
          webkit-playsinline
        ></video>
        <h3 class="payment-price cards-font flex justify-center items-center pt-2 h-16">
          <sup class="text-color text-base font-bold">$</sup
          ><span class="font-bold cards-font text-color">{{
            item.price != undefined ? item.price : item.monthly_price
          }}</span
          ><sub class="text-color text-base font-bold">/mo</sub>
        </h3>
        <p
          *ngIf="item.isPlan && !item.isUserCreatedPlan"
          [ngClass]="!item.isPlan ? 'flex items-center justify-center' : ''"
          class="font-style text-center cards-font text-lg h-10 mt-2 mb-2 overflow-y-auto"
          [innerHTML]="item.sub_title"
        ></p>
        <p
          *ngIf="item.header_tag && !item.isUserCreatedPlan"
          [ngClass]="!item.isPlan ? 'flex items-center justify-center mt-4' : ''"
          class="font-bold blue uppercase border-lg rounded-lg px-4 mb-4 py-1 bg-gray-100 mx-auto w-fit"
          [innerHTML]="item.header_tag"
        ></p>
      </div>
      <div class="payment-option-card_feature bg-white">
        <div class="overflow-y-auto w-fit" [class]="cardheights?.desc">
          <div class="flex mt-2 items-center" *ngFor="let feature of item.features">
            <mat-icon class="check-box">check</mat-icon>
            <p
            class="ml-2 whitespace-nowrap leading-3 font-style font-normal text-center cards-font text-base"
            [innerHTML]="feature"
            >
              <!-- {{ feature }} -->
            </p>
          </div>
        </div>
      </div>
      <div class="payment-option-card_footer flex justify-center px-6 bg-white disabled">
        <button
        *ngIf="
          item.isPlan &&
          stripeUser?.is_trialing &&
          item.title.toLowerCase() == purchasedPlan?.title?.toLowerCase()" 
            mat-button
          class="button cards-font black success"
        >
        Trial Period
        </button>
        <button
          *ngIf="!item.isPlan && type == 'subscribe'"
          class="cards-font pay-button rounded-full font-bold py-2 px-8"
          matTooltip="Select Service"
          (click)="upgrade(item, false); planClicked = true"
        >
          {{ type === 'subscribe' ? 'Subscribe' : 'Pay' }}
        </button>
        <button
          *ngIf="
            item.isPlan &&
            item.title.toLowerCase() == purchasedPlan?.title?.toLowerCase() &&
            !stripeUser?.is_trialing
          "
          [routerLink]="'/'+routesConst.dashboard +'/'+ routesConst.subscription" [queryParams]="{ tab: 'paid-subscriptions' }"
          mat-button
          class="button cards-font black active-price-btn w-full"
          matTooltip="Activated Plan"
        >
            Your Active Price
        </button>
        <button
          *ngIf="puchasedAddon(item.title.toLowerCase())"
          mat-button
          class="button black active-price-btn w-full"
          matTooltip="Activated Plan"
          [routerLink]="'/'+routesConst.dashboard +'/'+ routesConst.subscription" [queryParams]="{ tab: 'paid-subscriptions' }"
        >
            Your Active Price
        </button>

        <button
          mat-button
          *ngIf="
            (item.isPlan &&
              item.title.toLowerCase() != purchasedPlan?.title?.toLowerCase() &&
              !stripeUser?.is_trialing) ||
            (item.isPlan &&
              stripeUser?.is_trialing &&
              item.title.toLowerCase() != purchasedPlan?.title?.toLowerCase())
          "
          class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
          matTooltip="Select Plan"
          (click)="upgrade(item, false); planClicked = true"
        >
          Get Started
        </button>
        <button
          mat-button
          *ngIf="!item.isPlan && !puchasedAddon(item.title.toLowerCase())"
          class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
          matTooltip="Select Plan"
          (click)="upgrade(item, false); planClicked = true"
        >
          Get Started
        </button>
      </div>
    </div>
  </div>
</div>
