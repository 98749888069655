import { Injectable } from '@angular/core';
import { PartWorkFlowConstants, WorkFlowItemsConstants } from 'src/app/const/work-flow.constants';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class WorkFlowService {
    constructor(private authService: AuthService) { }

    /**
     * Get Work Flow Items
     * @returns 
     */
    getWorkFlows() {
        return [
            // {
            //     logo: WorkFlowItemsConstants.PROFILE.LOGO,
            //     title: this.authService.loggedIn() ?
            //         WorkFlowItemsConstants.PROFILE.TITLE.MANAGE_PROFILE : WorkFlowItemsConstants.PROFILE.TITLE.CREATE_PROFILE,
            //     description: WorkFlowItemsConstants.PROFILE.DESCRIPTION,
            //     img: WorkFlowItemsConstants.PROFILE.IMG,
            //     label: WorkFlowItemsConstants.PROFILE.LABEL,
            //     url: WorkFlowItemsConstants.PROFILE.URL,
            //     type: WorkFlowItemsConstants.PROFILE.TYPE
            // },
            // {
            //     logo: WorkFlowItemsConstants.POST.LOGO,
            //     title: this.authService.loggedIn() ?
            //         WorkFlowItemsConstants.POST.TITLE.CREATE_CONTENT : WorkFlowItemsConstants.POST.TITLE.CREATE_CONTENT,
            //     description: WorkFlowItemsConstants.POST.DESCRIPTION,
            //     icon: WorkFlowItemsConstants.POST.ICON,
            //     label: WorkFlowItemsConstants.POST.LABEL,
            //     url: WorkFlowItemsConstants.POST.URL,
            //     type: WorkFlowItemsConstants.POST.TYPE
            // },
            // {
            //     logo: WorkFlowItemsConstants.SERVICE.LOGO,
            //     title: this.authService.loggedIn() ?
            //         WorkFlowItemsConstants.SERVICE.TITLE.MONETIZE_SERVICES : WorkFlowItemsConstants.SERVICE.TITLE.MONETIZE_SERVICES,
            //     description: WorkFlowItemsConstants.SERVICE.DESCRIPTION,
            //     icon: WorkFlowItemsConstants.SERVICE.ICON,
            //     label: WorkFlowItemsConstants.POST.LABEL,
            //     url: WorkFlowItemsConstants.SERVICE.URL,
            //     type: WorkFlowItemsConstants.SERVICE.TYPE
            // },
            // {
            //     logo: WorkFlowItemsConstants.JOB.LOGO,
            //     title: WorkFlowItemsConstants.JOB.TITLE.EXPLORE_JOBS,
            //     description: WorkFlowItemsConstants.JOB.DESCRIPTION,
            //     icon: WorkFlowItemsConstants.JOB.ICON,
            //     label: WorkFlowItemsConstants.JOB.LABEL,
            //     url: WorkFlowItemsConstants.JOB.URL,
            //     type: WorkFlowItemsConstants.JOB.TYPE
            // },
            // {
            //     logo: WorkFlowItemsConstants.PAYMENT.LOGO,
            //     title: WorkFlowItemsConstants.PAYMENT.TITLE.MANAGE_EARNINGS,
            //     description: WorkFlowItemsConstants.PAYMENT.DESCRIPTION,
            //     icon: WorkFlowItemsConstants.PAYMENT.ICON,
            //     label: WorkFlowItemsConstants.PAYMENT.LABEL,
            //     url: WorkFlowItemsConstants.PAYMENT.URL,
            //     type: WorkFlowItemsConstants.PAYMENT.TYPE
            // },
            {
                // logo: WorkFlowItemsConstants.ShareProfile.LOGO,
                title: WorkFlowItemsConstants.ShareProfile.TITLE.MANAGE_EARNINGS,
                description: WorkFlowItemsConstants.ShareProfile.DESCRIPTION,
                icon: WorkFlowItemsConstants.ShareProfile.ICON,
                label: WorkFlowItemsConstants.ShareProfile.LABEL,
                url: WorkFlowItemsConstants.ShareProfile.URL,
                type: WorkFlowItemsConstants.ShareProfile.TYPE,
                img: WorkFlowItemsConstants.ShareProfile.IMG
            },
            {
                // logo: WorkFlowItemsConstants.AddServices.LOGO,
                title: WorkFlowItemsConstants.AddServices.TITLE.MANAGE_EARNINGS,
                description: WorkFlowItemsConstants.AddServices.DESCRIPTION,
                icon: WorkFlowItemsConstants.AddServices.ICON,
                label: WorkFlowItemsConstants.AddServices.LABEL,
                url: WorkFlowItemsConstants.AddServices.URL,
                type: WorkFlowItemsConstants.AddServices.TYPE,
                img: WorkFlowItemsConstants.AddServices.IMG
            },
            {
                // logo: WorkFlowItemsConstants.GetSubscribers.LOGO,
                title: WorkFlowItemsConstants.GetSubscribers.TITLE.MANAGE_EARNINGS,
                description: WorkFlowItemsConstants.GetSubscribers.DESCRIPTION,
                icon: WorkFlowItemsConstants.GetSubscribers.ICON,
                label: WorkFlowItemsConstants.GetSubscribers.LABEL,
                url: WorkFlowItemsConstants.GetSubscribers.URL,
                type: WorkFlowItemsConstants.GetSubscribers.TYPE,
                img: WorkFlowItemsConstants.GetSubscribers.IMG
            },
            {
                // logo: WorkFlowItemsConstants.InviteOthers.LOGO,
                title: WorkFlowItemsConstants.InviteOthers.TITLE.MANAGE_EARNINGS,
                description: WorkFlowItemsConstants.InviteOthers.DESCRIPTION,
                icon: WorkFlowItemsConstants.InviteOthers.ICON,
                label: WorkFlowItemsConstants.InviteOthers.LABEL,
                url: WorkFlowItemsConstants.InviteOthers.URL,
                type: WorkFlowItemsConstants.InviteOthers.TYPE,
                img: WorkFlowItemsConstants.InviteOthers.IMG
            },
        ];

    }

    /**
     * Get Part Work Flow
     * @returns 
     */
    getPartWorkFlow() {
        return [
            {
                headingStyle: PartWorkFlowConstants.HEADING_STYLE,
                section: PartWorkFlowConstants.SECTION,
                imagePosition: PartWorkFlowConstants.IMAGE_POSITION,
                superHeading: PartWorkFlowConstants.SUPER_HEADING,
                divider: PartWorkFlowConstants.DIVIDER,
                mainHeading: PartWorkFlowConstants.MAIN_HEADING,
                description: PartWorkFlowConstants.DESCRIPTION,
                button: PartWorkFlowConstants.BUTTON,
                image: PartWorkFlowConstants.IMAGE
            },

        ];
    }
}
