<div class="service-category">
	<mat-expansion-panel hideToggle>
		<mat-expansion-panel-header (click)="expand()">
			<mat-panel-title>
				<h4 class="service-category_title">{{group.name}}</h4>
			</mat-panel-title>
			<mat-icon
				class="absolute right-2"
				[class]="isExpanded ? 'expanded' : ''"
			>chevron_right</mat-icon>
		</mat-expansion-panel-header>
		<div class="pt-1">
			<div *ngFor="let item of group.serviceCategory">
				<div class="flex items-center">
					<mat-checkbox
						class="pt-1"
						[color]="'primary'"
						(change)="change(item)"
						[checked]="isActive(item.id)"
						[disabled]="!isActive(item.id) && activeList?.length > 0"
					><p>{{item.label}}</p></mat-checkbox>
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</div>
