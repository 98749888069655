import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';

@Component({
  selector: 'app-dashboard-sold-subscription',
  templateUrl: './dashboard-sold-subscription.component.html',
  styleUrls: ['./dashboard-sold-subscription.component.scss']
})
export class DashboardSoldSubscriptionComponent implements OnInit {
  private unsubscribe$ = new Subject();
  displayedColumns: string[] = [
    'last4',
    'isDefault',
    'expiry',
  ];
  dataSource: any[] = [];

  constructor(private userPlan: userPlans) { }

  ngOnInit(): void {
    this.getMySoldPlans();
  }

  getMySoldPlans() {
    this.userPlan
      .userSoldPlans()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.data?.length) {
          this.dataSource = plans.data;          
        }
      });
  }

}
