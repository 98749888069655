import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  @Output() eventChange = new EventEmitter<string>();

  constructor() { }

  emitNavChangeEvent(value: any) {
    this.eventChange.emit(value);
  }

  getNavChangeEmitter() {
    return this.eventChange;
  }
}
