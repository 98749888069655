import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndividualServiceComponent } from 'src/app/modules/individual-service/individual-service.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ImagePreviewModalComponent } from 'src/app/modules/individual-service/components/image-preview-modal/image-preview-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { StarRatingModule } from 'angular-star-rating';
import { PackageUnitUpdateComponent } from 'src/app/shared/components/service-modal/components/package-unit-update/package-unit-update.component';

@NgModule({
  declarations: [IndividualServiceComponent, ImagePreviewModalComponent,PackageUnitUpdateComponent],
  imports: [
    CommonModule,
    IvyCarouselModule,
    SharedModule,
    StarRatingModule.forRoot(),
  ],
})
export class IndividualServiceModule {}
