<ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
</ngx-spinner>
<button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon">close</mat-icon>
</button>

<h1 mat-dialog-title>Enter Card Details</h1>
<div mat-dialog-content>
    <ngx-stripe-card (change)="onChange($event)" [options]="cardOptions" [elementsOptions]="elementsOptions">
    </ngx-stripe-card>
</div>
<div mat-dialog-actions class="action-buttons">
    <button mat-button class="w-36 button warning black" [mat-dialog-close]="false">
        Cancel
    </button>
    <button [disabled]="!stripeCardValid" mat-button class="button outlined black w-36" (click)="addNewCard()">
        Save
    </button>
</div>