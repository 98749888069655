<div class="header-filter-section grid">
  <div
    class="home-page-story homepage-post mb-4 grid grid-cols-1 mx-auto max-w-[1025px] order-2 md:order-1"
  >
    <app-post-story [storyPosts]="stories" (listEnd)="onStorySlideChange()"></app-post-story>
  </div>
  <div class="users-page mb-4 order-1 md:order-2">
    <div class="flex justify-between items-center order-1 md:order-2">
      <div class="search-filter-group px-4 md:px-0 flex w-full items-center">
        <div class="h-9 flex items-center rounded search-layout w-full lg:w-1/2">
          <mat-icon class="search-icon blue">search</mat-icon>
          <input
            #search
            matInput
            type="text"
            [placeholder]="placeholder"
            class="input-form flex ml-0 lg:ml-2.5 w-full h-full mt-2 lg:mt-0"
            [value]="searchIdx"
            autocomplete="off"
            (keyup)="searchByTitle($event)"
          />
          <!-- <app-layout-mode
            class="flex md:hidden"
            [value]="layoutMode"
            (setValue)="setViewMode($event)"
          ></app-layout-mode> -->
        </div>
        <mat-divider class="hidden md:flex w-1 h-full" [vertical]="true"></mat-divider>
        <mat-radio-group
          class="hidden lg:flex search-type-group items-center justify-center h-full lg:bg-white"
          aria-label="Select an option"
          [value]="searchTypeVal"
          (change)="handleTypeChange($event.value)"
        >
          <mat-radio-button *ngFor="let type of SearchType.type" [value]="type.id">
            <div class="tag" [class]="searchTypeVal == type.id ? 'blue' : ''">
              <p>
                {{ type.label }}
              </p>
            </div>
          </mat-radio-button>
        </mat-radio-group>
        <button class="h-full clear-wrapper" *ngIf="isSearch" mat-text-button (click)="reset()">
          <span class="px-2 text-white"> Clear </span>
        </button>
      </div>
    </div>
  </div>
  <div class="md:hidden flex py-2.5 filter-wrapper order-3 mb-4">
    <swiper
      #swipperFilters
      [navigation]="true"
      [config]="config"
      class="swpier-container filter-swipper-home common-arrow custom-filter"
    >
      <ng-template swiperSlide *ngFor="let item of SearchType.type">
        <p class="filter-element">
          {{ item.label }}
        </p>
      </ng-template>
    </swiper>
  </div>
</div>
