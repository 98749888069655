<div class="member-card h-fit mb-2 border rounded-lg">
  <div class="img-wrapper-h-w-set rounded-lg relative overflow-hidden member-card_bg" style="padding-top: 43%;">
    <div class="absolute top-0 left-0 flex justify-center w-full h-full items-center  object-cover">
      <img *ngIf="data?.profile?.banner_image !== ''" [src]="data?.profile?.banner_image" class="w-full " />
    </div>
  </div>
  <div class="w-24 h-24 relative mx-auto avater-wrapper border-2 border-white rounded-full overflow-hidden">
    <img [src]="avatar" class="w-full h-full object-cover cursor-pointer" (click)="showProfile()" />
  </div>
    <p class="mx-w-40 mx-auto my-3 truncate cursor-pointer h-5" (click)="showProfile()" style="color: rgb(15, 20, 25);">{{data?.profile?.user_name}}</p>
  <div class="flex items-center mx-auto mb-4 justify-center h-6">
    <mat-icon style="color: #2887ed;" *ngIf="data?.profile?.location?.length">room</mat-icon>
    <p class="text-xs w-full text-gray-400 mx-w-40 truncate" *ngIf="data?.profile?.location?.length">
      {{getLocation(data?.profile?.location[0])}}
    </p>
  </div>
  <div class="text-center">
    <!-- <div class="flex justify-around items-center mb-4">
      <p class="text-xs"><span class="montserrat-bold">{{data?.profile?.totalFollowers}}</span> Followers</p>
      <p class="text-xs"><span class="montserrat-bold">{{data?.profile?.totalSubscribers}}</span> Subscribers</p>
      <button class="flex items-center justify-center button-wraper" [matMenuTriggerFor]="exploreMenu">
        <mat-icon style="color: #2887ed;">more_vert</mat-icon>
      </button>
    </div> -->

    <!-- New Buttons Style -->
    <div class="flex justify-between mb-4 mx-2 ">
      <app-follow-state
      [showButton]="true"
      [following]="following"
      [style]="'cursor-pointer'"
      [target_user_id]="data?.profile?._id"
      ></app-follow-state>
    
        <div class="flex items-center p-2 cursor-pointer profile-btn" (click)="readMessage()">
          Messages
        </div>
        <button class="profile-btn items-center flex" (click)="contactDetails(data.profile)">
          Contact
       </button>
        <button  [matMenuTriggerFor]="exploreMenu"  class="profile-btn flex">
          <svg
          class="w-6 h-6 share-icon text-gray-800 dark:text-white ml-2"
          style="margin-top: -5px; transform: rotate(68deg)"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
          />
        </svg>
        </button>
    </div>
    <div class="flex justify-around">

      <mat-menu #exploreMenu="matMenu">
        <div class="grid grid-cols-3 gap-2 cursor-pointer">
          <div class="flex flex-col items-center">
            <button
              class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
              (click)="copy()"
            >
              <mat-icon style="transform: rotate(-45deg)">link</mat-icon>
            </button>
            <span class="text-xs">Copy</span>
          </div>
          <div class="flex flex-col items-center">
            <button
              (click)="share()"
              class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1 mx-2"
            >
              <mat-icon>share</mat-icon>
            </button>
            <span class="text-xs">Share</span>
          </div>
          <div class="flex flex-col items-center">
            <button
              class="w-10 h-10 flex items-center justify-center rounded-full border border-gray-800 p-1"
            >
              <mat-icon>close</mat-icon>
            </button>
            <span class="text-xs">Cancel</span>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
</div>