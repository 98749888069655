import { Component, Input, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-subscribing-card',
  templateUrl: './subscribing-card.component.html',
  styleUrls: ['./subscribing-card.component.scss']
})
export class SubscribingCardComponent implements OnInit {
  @Input()data!: any;
  @Output()unsubscribeUser: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.unsubscribeUser.emit();
  }
}
