import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-message-user-contact-card',
  templateUrl: './message-user-contact-card.component.html',
  styleUrls: ['./message-user-contact-card.component.scss'],
})
export class MessageUserContactCardComponent implements OnInit {
  tabCollapse: boolean = true;
  @Input() data?: any;
  @Input() sender?: any;

  constructor() { }

  ngOnInit(): void { }

  getUnreadCount(unreadMessages: any) {
    if (unreadMessages?.length) {
      return unreadMessages.filter((x: any) => {
        return x.to_id === this.sender
      }).length
    }
  }

  getProfileImage(userList: any) {
    if (userList.profile_img_path) {
      return userList.profile_img_path
    }
    else {
      return 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs'
    }
  }
  openTabCollapse() {
    this.tabCollapse = !this.tabCollapse;
  }
}
