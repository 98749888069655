<div id="aboutUsFooter" class="background-section">
    <div class="sections-main pt-8">
        <div class="content-wrapper">
            <div class="section-2-main">
                <div class="flex flex-col w-full mx-auto items-center">
                    <div>
                        <p class="join-heading text-center" *ngIf="pageTitle !== 'home'">
                            Lets grow together join the <br>
                            fastest growing community
                        </p>
                        <p class="join-heading text-center" *ngIf="pageTitle === 'home'">
                            Become a member of the <br />
                            fastest growing community
                            
                        </p>
                        <p class="section-title-divider"></p>
                    </div>
                    <p class="description" *ngIf="pageTitle !== 'home'">
                        Earn more revenue, clients and referrals
                    </p>
                    <p class="description" *ngIf="pageTitle === 'home'">
                        The best place to earn on your terms
                    </p>
                </div>

                <div *ngIf="innerWidth < 768" class="images-main">
                    <swiper [pagination]="{ clickable: true }" [config]="config">
                        <ng-template swiperSlide>
                            <div class="images">
                                <video class="video" controls playsinline webkit-playsinline loop [muted]="'muted'"
                                    autoplay src="assets/static/home/videos/promo.mp4"></video>
                            </div>
                        </ng-template>

                        <ng-template swiperSlide>
                            <div class="images">
                                <video class="video" controls playsinline webkit-playsinline loop [muted]="'muted'"
                                    autoplay src="assets/static/home/videos/community1.mp4"></video>
                            </div>
                        </ng-template>

                        <ng-template swiperSlide>
                            <div class="images">
                                <video class="video" controls playsinline webkit-playsinline loop [muted]="'muted'"
                                    autoplay src="assets/static/home/videos/community3.mp4"></video>
                            </div>
                        </ng-template>
                    </swiper>
                </div>

                <div *ngIf="innerWidth >= 768" class="images-main">
                    <div class="images">
                        <video playsinline webkit-playsinline class="video" controls loop [muted]="'muted'" autoplay
                            src="assets/static/home/videos/promo.mp4"></video>
                    </div>
                    <div class="images">
                        <video playsinline webkit-playsinline class="video" controls loop [muted]="'muted'" autoplay
                            src="assets/static/home/videos/community1.mp4"></video>
                    </div>
                    <div class="images">
                        <video playsinline webkit-playsinline class="video" controls loop [muted]="'muted'" autoplay
                            src="assets/static/home/videos/community3.mp4"></video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>