import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-follower-card',
  templateUrl: './follower-card.component.html',
  styleUrls: ['./follower-card.component.scss']
})
export class FollowerCardComponent implements OnInit {
  @Input() data?: any;
  @Input() isFollower: any
  @Output() unfollow: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClick() {
    this.unfollow.emit();
  }

  showProfile() {
    this.router.navigate(['/profile' + '/' + this.data?.user_name]);
  }

}
