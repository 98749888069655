import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModeratorRoles, ModeratorStatusConst, userTypeConstant } from 'src/app/const/appConst';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { JobService } from 'src/app/core/services/job.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { PostService } from 'src/app/core/services/post.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { CustomerAllReviewsModalComponent } from 'src/app/modules/dashboard/pages/customer-all-reviews-modal/customer-all-reviews-modal.component';
import { JobApplyModalComponent } from 'src/app/modules/jobs/modals/job-apply-modal/job-apply-modal.component';
import { JobPostComponent } from 'src/app/shared/components/job-post/job-post.component';
import { ServiceModalComponent } from 'src/app/shared/components/service-modal/service-modal.component';
import { SubscriptionPackageModalComponent } from 'src/app/shared/components/subscription-package-modal/subscription-package-modal.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

interface UserInfo {
  email: string;
  name: string;
  profile_image: string;
  user_name: string;
  user_type: string;
  banner_image?: string;
  _id: string;
  moderators: [];
}
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  ModeratorRoles = ModeratorRoles;
  userTypeConstant = userTypeConstant;
  uploadPostModalComponent = UploadPostModalComponent;
  jobPostComponent = JobPostComponent;
  serviceModalComponent = ServiceModalComponent;
  subscriptionPackageModalComponent = SubscriptionPackageModalComponent;
  private unsubscribe$ = new Subject();
  appliedJobs: any[] = [];
  myFollowings: any[] = [];
  preferences$: any;
  reciever: any;
  user: any;
  sender_id: any;
  userProfile: any;
  myNetworkUnread: any[] = [];
  recruiterUnread: any[] = [];
  clientUnread: any[] = [];
  profileId: any;
  type: string = '';
  user_name: any;

  selected = new FormControl(0);
  selectedProfile!: UserInfo;
  jobFilters = { offset: 0, limit: 10 };
  totalAvaileJobs: any;
  totalcount: any;
  jobs?: any[];
  postN: number;
  planN: number;
  serviceN: number;
  followerN: number;
  followingN: number;
  subscriptionN: number;
  isFollow = false;
  currentLoggedInUserId: any;
  averageRating: any;
  totalRatings: any;
  moderatorRoles: [] = [];

  constructor(
    private postService: PostService,
    private networkService: NetworkService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    public jobService: JobService,
    private sharedService: SharedService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private router: Router,
    private commonService: CommonService
  ) {
    this.postN = 0;
    this.followerN = 0;
    this.followingN = 0;
    this.serviceN = 0;
    this.planN = 0;
    this.subscriptionN = 0;
    this.router.events.subscribe((params: any) => {
      this.selected.setValue(0);
    });
    this.getFollowings();
  }

  ngOnInit(): void {
    if (this.auth.loggedIn()) {
      this.userInforMessage();
      this.getUserAppliedJobs();
    }

    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      if (params.tab) {
        setTimeout(() => {
          this.selected.setValue(params.tab);
        }, 1000);
      }
      this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(routeParams => {
        if (routeParams?.['user_name']) {
          const { user_name } = routeParams;
          this.profileService
            .checkUsernameValidity(user_name)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (user: any) => {
                const { type, profile_id } = user;
                this.profileId = profile_id;
                if (this.profileId) {
                  this.addViews(this.profileId);
                }
                this.companyPostedJob(profile_id);
                this.type = type;
                this.user_name = user_name;
                this.getProfileInformation(user_name);
                this.getFollowerFollowingandPost(user_name);
              },
              () => {
                this.router.navigate(['/404']);
              }
            );
        } else {
          this.router.navigate(['/404']);
        }
      });
    });
  }

  addViews(profileId: any) {
    if (this.auth.loggedIn()) {
      const payload = {
        content_id: profileId,
        type: 'profile'
      };
      this.postService
        .updateViews(payload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {});
    }
  }

  getFollowings() {
    this.networkService
      .getFollowings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network: any) => {
        this.myFollowings = network.data;
      });
  }

  getFollowerFollowingandPost(user_name: any) {
    this.profileService
      .getUserFollowInfo(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        const {
          posts,
          followers,
          followings,
          plans,
          services,
          subscriptions,
          averageRating,
          totalRatings
        } = user.data;
        this.postN = posts;
        this.followerN = followers;
        this.followingN = followings;
        this.planN = plans;
        this.serviceN = services;
        this.subscriptionN = subscriptions;
        this.averageRating = averageRating;
        this.totalRatings = totalRatings;
      });
  }

  getProfileInformation(user_name: any) {
    this.profileService
      .getCommunityPageUserProfile(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        const { data } = user;
        this.selectedProfile = data[0].profile;
        const { email, name, profile_image, user_name, user_type, _id } = this.selectedProfile;
        const userInfo = {
          email,
          name,
          profile_img_path: profile_image,
          user_name,
          user_type,
          _id
        };
        if (_id != this.currentLoggedInUserId) {
          this.reciever = userInfo;
        }
        const selectedProfileModeratorObject: any =
          this.selectedProfile?.moderators?.find(
            (item: any) =>
              item.user_id === this.currentLoggedInUserId &&
              item.status === ModeratorStatusConst.ACTIVE
          ) || null;
        this.moderatorRoles = selectedProfileModeratorObject
          ? selectedProfileModeratorObject.roles
          : [];
      });
  }

  userInforMessage() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        const { _id } = profileInfo;
        this.user = profileInfo;
        this.sender_id = _id;
        this.userProfile = profileInfo;
        this.currentLoggedInUserId = profileInfo._id;
      }
    });
  }

  recruitersUnreadMessages(event: any) {
    this.recruiterUnread = [];
    event.forEach((element: any) => {
      if (element.unread_messages.length) {
        this.recruiterUnread.push(element);
      }
    });
    this.checkIsUnreadMessagesLeft();
  }

  myNetworkUnreadMessages(event: any) {
    this.myNetworkUnread = [];
    event.forEach((element: any) => {
      if (element?.unread_messages?.length) {
        this.myNetworkUnread.push(element);
      }
    });
    this.checkIsUnreadMessagesLeft();
  }

  clientUnreadMessages(event: any) {
    this.clientUnread = [];
    event.forEach((element: any) => {
      if (element?.unread_messages?.length) {
        this.clientUnread.push(element);
      }
    });
    this.checkIsUnreadMessagesLeft();
  }

  checkIsUnreadMessagesLeft() {
    let recruiter = this.recruiterUnread.length ? true : false;
    let client = this.clientUnread.length ? true : false;
    let mynetwork = this.myNetworkUnread.length ? true : false;
    this.commonService.isUnreadMessagesExist.next({
      recruiter: recruiter,
      mynetwork: mynetwork,
      client: client
    });
  }

  /**
   * Message to Selected user
   * @param selectedUser
   */
  messageUser() {
    const { email, name, profile_image, user_name, user_type, _id } = this.selectedProfile;
    if (_id != this.currentLoggedInUserId) {
      const userInfo = {
        email,
        name,
        profile_img_path: profile_image,
        user_name,
        user_type,
        _id
      };
      localStorage.setItem('userId', JSON.stringify(userInfo));
    }
  }

  /**
   * Get Company posted job
   */
  companyPostedJob(profile_id: any) {
    this.jobFilters.offset = 0;
    this.jobFilters.limit = 10;
    this.jobService
      .jobListBycompany(profile_id, this.jobFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        const resultant = result.data.map((x: any) => ({
          ...x,
          job: {
            ...x.job,
            isApplied: this.appliedJobs.some(p => p.job_id == x.job._id),
            status: this.appliedJobs.find(j => j.job_id === x.job._id)?.applied_status,
            isSaved: this.appliedJobs.find(j => j.job_id === x.job._id)?.status_type,
            followed: this.myFollowings.some(f => f.following == x.company._id)
          }
        }));
        if (this.jobs?.length) {
          this.jobs = [];
          this.jobs = [...this.jobs, ...resultant];
          this.totalcount = this.jobs.length;
        } else {
          this.jobs = resultant;
          this.totalcount = this.jobs?.length;
        }
        this.totalcount = result.length;
        this.totalAvaileJobs = result.totalJobAvailble;
        if (result?.data?.length) {
          this.preferences$ = result.data[0].preference[0];
        }
      });
  }

  followCount(event: any) {
    if (event == 1) {
      this.myFollowings.push({
        follower_id: this.currentLoggedInUserId,
        following: this.selectedProfile._id
      });
    }
    if (event == -1) {
      this.myFollowings = this.myFollowings.filter((item: any) => {
        return (
          item.follower_id !== this.currentLoggedInUserId &&
          item.following !== this.selectedProfile._id
        );
      });
    }
    this.companyPostedJob(this.profileId);
    this.getFollowing();
  }

  getFollowing() {
    this.getFollowerFollowingandPost(this.user_name);
    this.isFollow = !this.isFollow;
  }

  /**
   * Open Apply Dialog for selected job
   * @param selectedJob
   */
  openApplyDialog(selectedJob: any): void {
    if (selectedJob) {
      this.dialog.open(JobApplyModalComponent, {
        maxWidth: '100%',
        width: '536px',
        height: window.innerWidth && window.innerWidth > 768 ? '650px' : '100vh',
        data: {
          job: selectedJob
        }
      });
    }
  }

  onScrollDown() {}

  saveJob(selectedJob: any) {
    if (
      selectedJob.job &&
      selectedJob.job.company_id &&
      selectedJob.job._id &&
      this.auth.loggedIn()
    ) {
      const appliedJobParams = {
        company_id: selectedJob?.job?.company_id,
        job_id: selectedJob.job._id
      };
      this.jobService
        .saveJob(appliedJobParams)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            this.appliedJobs.push(response?.data);
            this.jobs?.find((x: any) => {
              if (x.job._id == response.data.job_id) {
                x.job.isSaved = response.data.status_type;
              }
            });
            this.snackBar.showMessage('Successfully saved this job.', false);
          },
          () => {
            this.snackBar.showMessage('Failed to save this job.', true);
          }
        );
    }
  }

  getUserAppliedJobs() {
    this.jobService
      .checkForJobsApplied()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data && data.data && data.data.length) {
          this.appliedJobs = data.data;
        }
        this.getFollowings();
      });
  }

  openReviewModal() {
    const data = {
      profile_id: this.profileId,
      profile: {
        profile_img_path: this.selectedProfile.profile_image,
        name: this.selectedProfile.name
      }
    };
    this.dialog.open(CustomerAllReviewsModalComponent, {
      maxWidth: innerWidth && innerWidth > 768 ? '800px' : '100vw',
      width: '100%',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: { selectedService: data, reviewsType: 'Buyer' },
      disableClose: true
    });
  }

  addContentModel(modal: any) {
    this.dialog.open(modal, {
      maxWidth: '100vw',
      width: '536px',
      disableClose: true,
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: { isModeratorMode: true, profile_id: this.profileId }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
