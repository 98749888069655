<div class="hashtag-page">
  <div class="mb-4">
    <app-go-back></app-go-back>
  </div>
  <div class="box-border mx-auto before:box-inherit after:box-inherit">
    <div
      class="break-inside rounded-lg mt-10"
      *ngFor="let item of relatedPosts"
    >
      <app-post-detail-card *ngIf="interestList?.length" [loginedProfile]="profile"  [loginedFollowers]="followers" [postID]="item._id" [interestList]="interestList"></app-post-detail-card>
    </div>
  </div>
</div>
