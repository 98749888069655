import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { sideMenuConstant } from 'src/app/const/appConst'
import { AdminSideMenus, SideMenus } from 'src/app/core/const/options';
import { routesConst } from 'src/app/core/const/routers';
import { SideMenu } from 'src/app/core/models';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { JobService } from 'src/app/core/services/job.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  sideMenuConstant = sideMenuConstant;
  private unsubscribe$ = new Subject();
  sidemenu: SideMenu[];
  activeSidemenu: any[] = [];
  routesConst = routesConst;
  selectedValue?: string;
  isAdmin = false;
  isUnreadMessage: any;
  unreadMessagesCount: any = false;

  constructor(private commonService: CommonService, public router: Router, public authService: AuthService, private jobService: JobService, private sharedService: SharedService) {
    this.isAdmin = this.authService.isAdmin();
    this.sidemenu = this.isAdmin ? AdminSideMenus : SideMenus;
    this.activeSidemenu = this.sidemenu.filter(item => item.active)
    router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.unsubscribe$)).subscribe((event: any) => {
      this.sidemenu.map((menu) => {
        if (event?.url === menu.url) {
          menu.selected = true;
          this.selectedValue = menu.name;
        } else {
          menu.selected = false;
        }
      });
      this.sidemenu.map((menu) => {
        if (event?.urlAfterRedirects.split('?')[0] === menu.url || event?.urlAfterRedirects === menu.url) {
          menu.selected = true;
          this.selectedValue = menu.name;
        } else {
          menu.selected = false;
        }
      });
    });
  }

  ngOnInit(): void {
    this.commonService.isUnreadMessagesExist.pipe(takeUntil(this.unsubscribe$)).subscribe((unread: any) => {
      this.isUnreadMessage = unread;
    },
      () => { }
    );
    this.getUnreadMessages();
  }

  getUnreadMessages() {
    this.sharedService.isUnreadMessageExist$.pipe(takeUntil(this.unsubscribe$)).subscribe((isUnread: any) => {
      this.unreadMessagesCount = isUnread;
    })
  }

  updateMenu(item: SideMenu) {
    this.sidemenu.map((menu) => {
      if (item === menu) {
        menu.selected = true;
        this.selectedValue = item.name;
      } else {
        menu.selected = false;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
