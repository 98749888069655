import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { Subject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';

declare var FB: any;

@Component({
  selector: 'app-share-links',
  templateUrl: './share-links.component.html',
  styleUrls: ['./share-links.component.scss']
})
export class ShareLinksComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() referralLink: string ='';
  postText: string = 'JobPros';
  appId: string = '';
  extraText: any = '';


  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private profileService: ProfileService,
    private commonService: CommonService,
    private clipboard: Clipboard,
    private snack: SnackBarService
  ) {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '474907385364118',
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      });
      FB.AppEvents.logPageView();
    };

    // Load the SDK
    ((d, s, id) => {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  ngOnInit(): void {
  }

  shareViaWhatsApp() {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      this.extraText + ' ' + this.referralLink
    )}`;
    window.open(whatsappUrl, '_blank');
  }

  shareOnFacebook() {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      this.referralLink
    )}`;
    window.open(facebookShareUrl, '_blank');
  }

  shareOnTwitter() {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      this.extraText
    )}&url=${encodeURIComponent(this.referralLink)}`;
    window.open(twitterShareUrl, '_blank');
  }

  shareOnLinkedIn() {
    const linkedInShareUrl = `https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(
      this.referralLink
    )}`;
    window.open(linkedInShareUrl, '_blank');
  }

  shareOnMessenger() {
    FB.ui(
      {
        method: 'send',
        link: this.referralLink
      },
      function (response: any) {
        if (response) {
        } else {
        }
      }
    );
  }

  async shareOnThreads() {
    const encodedUrl = encodeURIComponent(this.referralLink);
    const shareUrl = `https://www.threads.net/intent/post/?text=${encodedUrl}`;

    window.open(shareUrl, '_blank', 'noopener,noreferrer');
  }

  shareByEmail(): void {
    const encodedSubject = encodeURIComponent('Check out JOBPros!');
    const encodedBody = encodeURIComponent(
      `I found this great opportunity on JOBPros. Check out my referral link: ${this.referralLink}`
    );
    const mailtoUrl = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;

    window.open(mailtoUrl, '_self');
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}
