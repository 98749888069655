export const EarnHeaderConstants = {
  CLASS: 'earn-heading-width',
  HEADING: 'the way you earn income streams',
  BLUE_HEADING: 'think ',
  DESCRIPTION:
    'We make it simple for you to monetize every where you are all on one platform.',
  BUTTON: { text: 'Try 3 Months Free', buttonLink: false },
  IMAGE: {
    src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/1eGC9.jpeg',
    redirectUrl: '',
  },
};

export const WhatYouGet = {
  SECTION: 'whatYouGet',
  IMAGE_POSITION: 'right',
  SUPER_HEADING: 'HOW IT WORKS',
  MAIN_HEADING: 'Four ways to Network & earn online',
  DIVIDER: true,
};

export const EarnReferrals = {
  SECTION: 'weValueReferrals',
  DESC_WIDTH: 'referrals-desc-width',
  IMAGE_POSITION: 'left',
  SUPER_HEADING: 'WE VALUE REFERRALS',
  DIVIDER: false,
  MAIN_HEADING: 'Earn 10% monthly revenue share',
  DESCRIPTION: {
    content: 'Our members can earn 10% referral revenue for memberships sold up to 12 months ongoing',
    styling: 'lg:max-w-xl',
  },
  LIST: [
    'Select referral membership add on',
    'Invite your professional and personal networks',
    'Share referral link to social & business networks',
    'Get consistent revenue per referral memberships paid',
  ],
  BUTTON: [{ text: 'Get Referrals', buttonLink: '' }],
  IMAGE: [
    {
      src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_6.png',
      redirectUrl: '',
    },
  ],
};

export const EarnRangeSlider = {
  SUPER_HEADING: 'Turn Referrals Into Income',
 HEADING: 'How many people do you have access to?',
  DESCRIPTION:
    'Combine your totals of networks both in person and any user-based platforms.',
  SUB_HEADING: 'What value would do they place on access to resources?',
  SUB_DESCRIPTION:
    'Earn 10% referral income ongoing up to 12 months',
  INCOME: 'Monthly Income',
  GETTOTAL: 0.1,
};
