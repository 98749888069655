<div
  [class]="loading ? 'spinner' : ''"
  [ngClass]="
    !isDialog
      ? 'sign-up-page relative pt-14 lg:pt-0 sign-up-page-wrapper'
      : 'relative sign-up-page'
  "
>
  <a
    *ngIf="!isDialog"
    routerLink="{{ routesConst.launchPath }}"
    class="h-6 absolute right-0 mr-5 lg:mr-28 mt-2.5 lg:mt-10 rounded-full z-30"
  >
    <mat-icon>cancel</mat-icon>
  </a>
  <div class="sign-up-content">
    <div class="flex flex-col w-full items-center text-center">
      <a
        *ngIf="!isDialog"
        routerLink="{{ routesConst.launchPath }}"
        class="padding-bottom"
      >
        <img
          src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_Black.png"
          class="w-32 h-10 object-cover"
        />
      </a>
      <h3 *ngIf="!isDialog" class="montserrat-bold lg:mt-6 mb-4">Join</h3>
      <h3 *ngIf="isDialog" class="heading mb-2 mt-8">Create your account </h3>
      <p *ngIf="isDialog" class=" accout-link text-left" >Already have an account? <a class="blue cursor-pointer" (click)="forSignUpRedirection('login')">Login to JOBPros</a></p>
      <div class="flex mb-2 justify-center" [ngClass]="isDialog ? 'mt-2':'mt-6'">
        <div class="p-1 flex items-center rounded-full gap-2">
          <button
            (click)="user_type = userType.candidate;initializeForm();signUpForm.get('referralCode')?.setValue(referral)"
            class="signup-signin blue-border"
            [class]="
              user_type === userType.candidate
                ? 'bg-blu shadow-lg text-white'
                : 'text-black'
            "
          >
          Individuals
          </button>
          <button
            (click)="user_type = userType.company;initializeForm();signUpForm.get('referralCode')?.setValue(referral)"
            class="signup-signin blue-border"
            [class]="
              user_type === userType.company
                ? 'bg-blu shadow-lg text-white'
                : 'text-black'
            "
          >
          Companies
          </button>
        </div>
      </div>
      <form
        class="flex flex-col w-full px-4 mb-6 md:mb-0" [ngClass]="isDialog ? 'mt-0':'mt-6'"
        [formGroup]="signUpForm"
        (ngSubmit)="submitForm()"
        novalidate
      >
        <mat-form-field class="border-no rounded">
          <input
            maxlength="129"
            matInput
            [placeholder]="user_type === userType.candidate ? 'Full name': 'Company name'"
            class="border-own"
            formControlName="name"
          />
          <mat-error
            *ngIf="errorHandling('name', 'required')"
            [class]="errorHandling('name', 'required') ? 'pb-4' : ''"
          >
            You must provide a Name.
          </mat-error>
          <mat-error
            *ngIf="errorHandling('name', 'hasWhiteSpace')"
            [class]="errorHandling('name', 'hasWhiteSpace') ? 'pb-4' : ''"
          >
            Please enter a valid Name
          </mat-error>
          <mat-error
            *ngIf="errorHandling('name', 'maxlength')"
            class="text-danger pb-2"
          >
            Name cannot exceed more than 128 characters!.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="border-0 rounded">
          <input
            matInput
            maxlength="80"
            placeholder="Email"
            class="border-own"
            formControlName="email"
          />
          <mat-error
            *ngIf="errorHandling('email', 'maxlength')"
            [class]="errorHandling('email', 'required') ? 'pb-4' : ''"
          >
            Email cannot exceed more than 79 characters!.
          </mat-error>
          <mat-error
            *ngIf="errorHandling('email', 'required')"
            [class]="errorHandling('email', 'required') ? 'pb-4' : ''"
          >
            You must provide an Email.
          </mat-error>
          <mat-error
            *ngIf="errorHandling('email', 'pattern')"
            [class]="errorHandling('email', 'pattern') ? 'pb-4' : ''"
          >
            Please enter a valid Email address.
          </mat-error>
        </mat-form-field>
        <mat-form-field class="border-no rounded">
          <input
            #fpassword
            maxlength="257"
            matInput
            placeholder="Password"
            class="border-own"
            formControlName="password"
            [type]="hide.password ? 'password' : 'text'"
          />
          <mat-icon matSuffix (click)="hide.password = !hide.password">{{
            hide.password ? "visibility" : "visibility_off"
          }}</mat-icon>
          <mat-error
            *ngIf="errorHandling('password', 'required')"
            [class]="errorHandling('password', 'required') ? 'pb-4' : ''"
          >
            You must provide a password.
          </mat-error>
          <mat-error
            *ngIf="errorHandling('password', 'minlength')"
            class="text-danger pb-2"
          >
            Must be at least 8 characters!.
          </mat-error>
          <mat-error
            *ngIf="errorHandling('password', 'maxlength')"
            class="text-danger pb-2"
          >
            Password cannot exceed more than 256 characters!.
          </mat-error>
          <mat-error
            class="text-danger pb-2"
            *ngIf="errorHandling('password', 'hasSpace')"
          >
            Password not include spaces!
          </mat-error>
          <mat-error
            class="text-danger pb-2"
            *ngIf="errorHandling('password', 'hasNumber')"
          >
            Must have at least 1 number!
          </mat-error>
          <mat-error
            class="text-danger pb-2"
            *ngIf="errorHandling('password', 'hasCapitalCase')"
          >
            Must contain at least 1 uppercase letter!
          </mat-error>
          <mat-error
            class="text-danger pb-2"
            *ngIf="errorHandling('password', 'hasSmallCase')"
          >
            Must contain at least 1 lowercase letter!
          </mat-error>
          <mat-error
            class="text-danger pb-2"
            *ngIf="errorHandling('password', 'hasSpecialCharacters')"
          >
            Must contain at least 1 Special Character!
          </mat-error>
        </mat-form-field>

        <mat-form-field class="border-no rounded">
          <input
            maxlength="257"
            matInput
            placeholder="Referral code"
            class="border-own"
            formControlName="referralCode"
          />
        </mat-form-field>

        <mat-form-field *ngIf="user_type === userType.candidate" class="birthday-date">
          <mat-label class="bg-white">DOB</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateOfBirth">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="errorHandling('dateOfBirth', 'required')">You must provide date of birth.</mat-error>
          <mat-error *ngIf="errorHandling('dateOfBirth', 'underage')">You must be at least 13 years old.</mat-error>
        </mat-form-field>
        <!-- <mat-form-field class="border-no border-0 rounded margin-botom">
          <input maxlength="256" class="border-own" #confirmpassword matInput placeholder="Confirm password"
            formControlName="confirm_password" [type]="hide.confirm_password ? 'password' : 'text'" />
          <mat-icon matSuffix (click)="hide.confirm_password = !hide.confirm_password">{{hide.confirm_password ?
            'visibility' : ' visibility_off'}}</mat-icon>
          <mat-error *ngIf="errorHandling('confirm_password', 'required')" class="text-danger pb-2">
            Confirm Password required.
          </mat-error>
        </mat-form-field> -->
        <!-- <span class="spn-style"
          *ngIf="!errorHandling('confirm_password', 'required')&&signUpForm.controls['confirm_password'].value != signUpForm.controls['password'].value && (signUpForm.controls['confirm_password'].dirty || signUpForm.controls['confirm_password'].touched)">
          Confirm Password Must Match with Password.
        </span> -->

        <p class="s gray-dark text-left mt-2 mb-2">
          <mat-checkbox
            class="SignUp"
            formControlName="acceptTerms"
          ></mat-checkbox>
          By continuing you accept our standard
          <a
            [href]="'/' + routesConst.terms"
            class="montserrat-medium link-color"
            >terms</a
          >
          and conditions and our
          <a
            [href]="'/' + routesConst.privacy"
            class="montserrat-medium link-color"
            >privacy policy</a
          >.

          <mat-error *ngIf="submitted && f['acceptTerms'].errors">
            You must accept Ts & Cs.
          </mat-error>
        </p>
        <button type="submit" mat-button class="button outlined black">
          Sign Up
        </button>

        <p *ngIf="!isDialog" class="mb-14">
          Already have an account?<a
            routerLink="{{ '/' + routesConst.login }}"
            class="blue montserrat-bold ml-1"
            >Login</a
          >
        </p>
      </form>
      <div *ngIf="!isDialog" class="mx-auto mt-10 md:mt-0 w-full">
        <div class="flex flex-col items-center text-center">
          <div
            class="flex flex-col items-center text-center cursor-pointer"
            (click)="share()"
          >
            <button
              mat-icon-button
              class="button outlined black"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 4px;
              "
            >
              <mat-icon
                class="blue"
                style="transform: rotate(-45deg); font-size: 30px"
                >send</mat-icon
              >
            </button>
            Contact Us
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
