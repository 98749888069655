<div class="dashboard-referrals-me px-4 lg:px-0" *ngIf="false">
  <div class="section-wrapper p-4 lg:p-10 mb-4">
    <div class="flex flex-col justify-center m-auto">
      <mat-icon class="gray-dark mx-auto" style="font-size: 160px; width: 160px; height: 160px;">
        group_work
      </mat-icon>
      <h3 class="montserrat-bold text-center mt-9">You Haven't Referred Anyone Yet</h3>
      <p class="text-center mt-2">Refer friends and peers to earn rewards.</p>
      <div class="flex justify-center mt-6">
        <button class="button" mat-button>Learn more</button>
      </div>
    </div>
  </div>
</div>
<div class="dashboard-referrals-me">
  <div class="section-wrapper p-4 lg:p-10">
    <div class="border-normal rounded px-6 py-4">
      <p class="montserrat-bold text-black">Referral Income</p>
      <div class="flex items-center mt-2">
        <h1 class="total-earning">${{totalEarning.toFixed(2)}}</h1>
        <p class="s ml-2">USD</p>
      </div>
    </div>
    <!-- <div class="" *ngIf="chartOptions && selected==1">
      <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
        [stroke]="chartOptions.stroke" [colors]="chartOptions.colors" [dataLabels]="chartOptions.dataLabels"
        [legend]="chartOptions.legend" [markers]="chartOptions.markers" [grid]="chartOptions.grid"
        [yaxis]="chartOptions.yaxis" [title]="chartOptions.title"></apx-chart>
    </div> -->
  </div>
  <div class="section-wrapper p-4 lg:p-10 mt-4">
    <p class="montserrat-bold text-black">Referrals</p>
    <div class="flex flex-col lg:flex-row items-center m4-5">
      <div class="input-form w-full lg:w-32 min-w-min flex items-center p-2 lg:p-3 lg:ml-auto">
        <app-select-form [label]="searchSortValue" (set)="setSearchSort($event)" [data]="sortByData">
        </app-select-form>
      </div>
    </div>
    <div class="">
      <table mat-table [dataSource]="dataSource" class="w-full shadow-none mat-elevation-z8">
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>
            <p class="text-black montserrat-bold s">Members</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="flex items-center">
              <img class="user-avatarImg" [src]="element.referral.profile_image"
                onerror="this.src='assets/images/no-image.png'">
              <div class="pl-1 w-24 md:w-full">
                <p class="text-sm truncate user-name-style">@{{element.referral.user_name}}</p>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="joined">
          <th mat-header-cell *matHeaderCellDef>
            <p class="text-black montserrat-bold s md:text-right pl-8 block">Joined</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <p class="text-right block text-sm">{{formatDate(element.referral.joining)}}</p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="earned">
          <th mat-header-cell *matHeaderCellDef>
            <p class="text-black montserrat-bold s md:text-right block pl-8">Earned</p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="text-right block">
              ${{ element.referral.earned_amount !== undefined ? 
                  (element.referral.earned_amount % 1 === 0 ? 
                      element.referral.earned_amount.toFixed(0) : 
                      element.referral.earned_amount.toFixed(2)) : 
                  '0' }}
          </p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>