import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserPlan } from 'src/app/core/models';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { ImageService, ImageSnippet } from 'src/app/core/services/image.service';
// import { MatStepper } from '@angular/material/stepper';
import { SwiperOptions } from 'swiper';
import { SharedService } from '../../services/shared.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-subscription-package-modal',
  templateUrl: './subscription-package-modal.component.html',
  styleUrls: ['./subscription-package-modal.component.scss']
})
export class SubscriptionPackageModalComponent implements OnInit {
  @ViewChild('file') file: any;
  packageDescription: any;
  packageList: any[] = [];
  // price: any = null;

  private unsubscribe$ = new Subject();
  config: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1
  };
  planForm: FormGroup;
  features = ['Photo Content', 'Video Content'];
  selectedFeatures: string[];
  editMode = false;
  isProcessing = false;
  // coverPostContent: any = {
  //   content: undefined,
  // };
  checkPrice = {
    isInValid: false,
    message: ''
  };

  postImagesPath: any[] = [];
  allImages: any[] = [];
  isLastStep: boolean = false;
  portfolios: any[] = [];
  imageUrl: any = '';
  updatedImage = false;
  showImages = false;
  imageIndex: number = 0;
  updateImage = false;
  profile: any;
  serviceCategories: any[] = [];
  filteredCategories: any[] = [];
  serviceIndex: FormControl = new FormControl();
  priceRange: FormControl = new FormControl();
  selectedServiceCategory: any[] = [];
  title: any;
  allPayRange: any[] = [];
  updatedata = false;
  price: any = null;
  packageName = 'Photos';
  swaperUploadSize: number = 4;
  visibility: string[] = [];

  constructor(
    private imageService: ImageService,
    private router: Router,
    public fb: FormBuilder,
    private dialogRef: MatDialogRef<SubscriptionPackageModalComponent>,
    private userPlan: userPlans,
    private snack: SnackBarService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private authService: AuthService,
    private talentService: TalentService
  ) {
    this.planForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(256)]]
    });
    this.selectedFeatures = [];
    this.packageList = [{}, {}, {}];
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
    }
    this.serviceIndex?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.filterService(res);
    });
    this.talentService
      .getTalentServiceCategory()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.data) {
          this.serviceCategories = res.data;
          this.filterService('');
          document.getElementById('searchServiceIdx')?.focus();
        }
      });
    this.getPayRangeList();
  }

  onCheckboxChange(value: string, event: any): void {
    if (event.checked) {
      this.visibility.push(value);
    } else {
      this.visibility = this.visibility.filter(
        (item) => item !== value
      );
    }
  }

  selectedTabValue(event: { tab: { textLabel: string } }) {
    this.packageName = event.tab.textLabel;
    this.swaperUploadSize =
      this.packageName == 'Photos' || this.packageName == 'Multimedia' ? 4 : 1;
    this.allImages = [];
  }

  getAcceptValue(packageName: string): string {
    if (packageName === 'Photos') {
      return 'image/*';
    } else if (packageName === 'Videos') {
      return 'video/*';
    } else if (packageName === 'Multimedia') {
      return 'image/*,video/*';
    }
    return '';
  }

  validatePrice(formData?: any) {
    this.price = formData?.price ? +formData?.price : this.price;
    if (this.priceRange?.value?.label) {
      let extractNumber = /\d+/g;
      let label = this.priceRange?.value?.label;
      let matches = label.match(extractNumber);
      matches = matches.map((x: any) => {
        return +x;
      });
      if (matches.length == 2) {
        if ((this.price >= matches[0] && this.price <= matches[1]) || this.price == null) {
          this.checkPrice.isInValid = false;
        } else {
          this.checkPrice.isInValid = true;
          this.checkPrice.message = `please select price between $${matches[0]} - $${matches[1]}`;
        }
      } else if (matches.length == 1) {
        if (this.price > matches[0] || this.price == null) {
          this.checkPrice.isInValid = false;
        } else {
          this.checkPrice.isInValid = true;
          this.checkPrice.message = `please select price greater than $${matches[0]}`;
        }
      }
    } else {
      this.checkPrice.isInValid = true;
      this.checkPrice.message = 'please select price range first';
    }
  }

  filterService(idx: string) {
    this.filteredCategories = [];
    this.serviceCategories.map((catGroup: any) => {
      let filteredList: any[] = [];
      catGroup.servicecategory.map((serviceCat: any) => {
        if (serviceCat.name.toLowerCase().includes(idx.toLowerCase()) || idx === '') {
          filteredList = [
            ...filteredList,
            {
              id: serviceCat._id,
              label: serviceCat.name
            }
          ];
        }
      });
      if (filteredList.length > 0) {
        this.filteredCategories.push({
          name: catGroup.name,
          serviceCategory: filteredList
        });
      }
    });
  }

  removeService(selectedServiceId: string) {
    this.selectedServiceCategory = this.selectedServiceCategory.filter(
      service => service.id !== selectedServiceId
    );
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.profile = profileInfo;
      }
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.planForm.controls[control].hasError(error);
  };

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  setFeature(feature: string) {
    if (!this.isActive(feature)) this.selectedFeatures.push(feature);
    else this.selectedFeatures = this.selectedFeatures.filter(f => f !== feature);
  }

  removeInterest(feature: string) {
    this.features.push(feature);
    this.selectedFeatures = this.selectedFeatures.filter(f => f !== feature);
  }

  addPlan() {
    if (this.planForm.get('title')?.value.trimStart().length) {
      this.isProcessing = true;
      let promises: any[] = [];
      this.allImages.forEach(async (item: any, index: any) => {
        if (item?.file) {
          promises.push(this.convertImages(item, index));
        }
      });
      Promise.all(promises).then(() => {
        const plan: UserPlan = {
          // yearly_price: this.planForm.get('yearly_price')?.value,
          // monthly_price: this.planForm.get('monthly_price')?.value || null,
          title: this.planForm.get('title')?.value || null,
          visibility: this.visibility,
          skill_id: this.selectedServiceCategory[0].id,
          skill_name: this.selectedServiceCategory[0].label,
          price_range: this.priceRange?.value?.id,
          // features: this.selectedFeatures || null,
          // description: this.planForm.get('description')?.value || null,
          images: this.allImages
            .filter((post: any) => post.url !== '')
            .map((post: any, index: any): any => {
              return {
                url: post.url,
                cover_type: post.cover_type,
                media_type: post.media_type,
                order_by: post.order_by
              };
            }),
          packages: this.packageList.filter(object => Object.entries(object).length !== 0),
          profile_id: this.data?.isModeratorMode ? this.data.profile_id : null
        };
        if (this.editMode) {
          this.updateUserPlan(plan);
        } else {
          this.createdUserPlan(plan);
        }
      });
    }
  }

  updateUserPlan(plan: any) {
    plan._id = this.data._id;

    this.userPlan
      .updateUserPlan(plan)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          // this.router.navigate([
          //   '/' + routesConst.dashboard + '/' + routesConst.subscription,
          // ]);
          this.spinner.hide();
          this.dialogRef.close(true);
          this.snack.showMessage('Plan Updated Successfully', false);
        },
        () => {
          this.snack.showMessage('Error in Updating Plan.', true);
        }
      );
  }

  isActive(feature: string) {
    return !!this.selectedFeatures.find(f => f === feature);
  }

  createdUserPlan(plan: UserPlan) {
    // this.spinner.show();
    this.userPlan
      .addUserPlan(plan)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (plan: any) => {
          this.router.navigate(['/' + routesConst.dashboard + '/' + routesConst.subscription]);
          this.router.navigate([
            'subscriptions/subscription/' + this.profile.user_name + '/' + plan.data._id
          ]);
          this.spinner.hide();
          this.dialogRef.close(true);
          this.snack.showMessage('Plan Created Successfully', false);
          this.isProcessing = false;
        },
        () => {
          this.snack.showMessage('Error in Creating Plan.', true);
        }
      );
  }

  close(event: any) {
    // this.location.back()
    this.dialogRef.close();
    event?.preventDefault();
  }

  uploadFile(files: FileList, index: number) {
    this.spinner.show();
    if (files.length === 0) {
      this.spinner.hide();
      return;
    }
    if (files.length > 4) {
      this.spinner.hide();
      this.snack.showMessage('You can only upload a maximum of 4 files.', true);
      return;
    }

    let videoCount = this.allImages.filter(image => image.media_type === 'video').length;
    let imageCount = this.allImages.filter(image => image.media_type === 'image').length;

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes('image') || files[i].type.includes('video')) {
        if (this.packageName === 'Multimedia') {
          if (files[i].type.includes('video') && videoCount >= 1) {
            this.snack.showMessage('You can only upload a maximum of 1 video.', true);
            this.spinner.hide();
            return;
          }
          if (files[i].type.includes('image') && imageCount >= 3) {
            this.snack.showMessage('You can only upload a maximum of 3 images.', true);
            this.spinner.hide();
            return;
          }
        }

        let format = '';
        var reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (event: any) => {
          if (
            files[i].size < 4294967296 &&
            (files[i].type.includes('image') || files[i].type.includes('video'))
          ) {
            format = files[i].type.includes('image') ? 'image' : 'video';
            const file: File = files[i];
            const newImagePath = {
              cover_type: i == 0,
              media_type: format,
              order_by: i,
              url: event.target.result,
              file
            };

            // Push the new file to allImages only if it meets the criteria
            if (this.packageName === 'Multimedia') {
              if (format === 'video' && videoCount < 1) {
                this.allImages.push(newImagePath);
                videoCount++;
              } else if (format === 'image' && imageCount < 3) {
                this.allImages.push(newImagePath);
                imageCount++;
              }
            } else {
              this.allImages.push(newImagePath);
            }
            this.spinner.hide();
          } else {
            this.snack.showMessage(`Size exceeded`, true);
            this.spinner.hide();
          }
        };
      } else {
        this.spinner.hide();
        this.snack.showMessage('File Type Not Supported', true);
        return;
      }
      if (i + 1 == files.length) {
        setTimeout(() => {
          this.showImages = true;
        }, 50);
      }
    }
  }

  async convertImages(item: any, index: any) {
    await new Promise(async resolve => {
      await this.imageService
        .imageConvertion(new ImageSnippet(item.url, item.file))
        .then((imageUri: any) => {
          this.postImagesPath[index] = {
            content: imageUri[0],
            format: item.media_type
          };
          this.allImages[index] = {
            url: imageUri[0],
            cover_type: index == 0,
            media_type: item.media_type,
            order_by: index
          };
        });
      resolve(true);
    });
  }

  updateCategory(item: any) {
    if (this.selectedServiceCategory && this.selectedServiceCategory.length > 0) {
      if (
        this.selectedServiceCategory.find((selectedService: any) => selectedService.id === item.id)
      ) {
        this.selectedServiceCategory = this.selectedServiceCategory.filter(
          (selectedServiceCategory: any) => selectedServiceCategory.id !== item.id
        );
      } else {
        this.selectedServiceCategory.push(item);
      }
    } else {
      this.selectedServiceCategory = [item];
    }
    this.updateCheck();
  }

  updateCheck() {
    if (this.data?.editmode) {
      if (
        this.updatedImage ||
        this.title !== this.data?.serviceData?.title ||
        this.selectedServiceCategory[0].id !== this.data?.serviceData?.skill_id
      ) {
        this.updatedata = true;
      } else {
        this.updatedata = false;
      }
    }
  }

  getPayRangeList() {
    this.sharedService.talentServicePayRangeList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(payrangelist => {
        if (payrangelist.length) {
          this.allPayRange = payrangelist;
        } else {
          this.sharedService.getTalentServicePayRangeList();
        }
      });
  }

  goToNext(stepper: MatStepper) {
    if (!this.isLastStep) {
      this.isLastStep = true;
      stepper.next();
    }
  }

  goToPrev(stepper: MatStepper) {
    this.isLastStep = false;
    stepper.previous();
  }

  // getArray(size: number) {
  //   let array = [0];
  //   for (let i = 1; i < size; i++) {
  //     array.push(i);
  //   }
  //   return array;
  // }

  // saveImageUrl(portfolio: any, idx: number) {
  //   if (portfolio?.url !== '') {
  //     this.imageUrl = portfolio?.url;
  //     this.allImages[idx] = {
  //       url: portfolio?.url,
  //       cover_type: portfolio.cover_type,
  //       media_type: portfolio.media_type,
  //       order_by: idx,
  //     };
  //   }
  // }

  clearFileInput(fileInput: HTMLInputElement) {
    fileInput.value = '';
  }

  removeImage(event: any, index: number) {
    this.allImages.splice(index, 1);
    event?.preventDefault();
  }

  savePackageData(formData: any, idx: number, packageType: string) {
    Object.assign(formData, { type: packageType });
    this.packageDescription = formData.description;
    this.packageList[idx] = formData;
  }

  checkPackageValidations() {
    let hasValidPackages = false;
    for (let item of this.packageList) {
      let entries = Object.entries(item);
      if (!entries.length) continue;
      if (
        item?.description?.trim()?.length &&
        item?.offers?.trim()?.length &&
        item.billing_type !== null &&
        item?.monthly_price !== null
      ) {
        hasValidPackages = true;
        break;
      } else {
        hasValidPackages = false;
        continue;
      }
    }

    return hasValidPackages;
  }

  uploadButtonClick(): void {
    this.file.nativeElement.click();
  }

  getArray(size: number) {
    let array = [0];
    for (let i = 1; i < size; i++) {
      array.push(i);
    }
    return array;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
