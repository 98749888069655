import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Education } from 'src/app/core/models/education';
@Component({
  selector: 'app-update-education-modal',
  templateUrl: './update-education-modal.component.html',
  styleUrls: ['./update-education-modal.component.scss']
})
export class UpdateEducationModalComponent implements OnInit {
  educForm: FormGroup;
  options: string[];
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;
  minY = 1990;
  maxY = (new Date()).getFullYear()
  result: Education | undefined;

  constructor(public fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateEducationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.educForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', []]
    })

    this.options = []
    for (let i=0; i < (this.maxY - this.minY); i++) {
      this.options = [...this.options, (this.maxY - i).toString()]
    }
    
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.educForm.setValue({
      name: this.data.educ.name,
      description: this.data.educ.description
    })
    this.myControl.setValue(this.data.educ.date)
  }

  public errorHandling = (control: string, error: string) => {
    return this.educForm.controls[control].hasError(error);
  }

  submitForm() {
    if (this.educForm.valid){
      this.result = {
        id: this.data.educ !== undefined ? this.data.educ.id : '-1',
        ...this.educForm.value,
        date: this.myControl.value
      }
      this.dialogRef.close(this.result)
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
}
