import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { startOfDay, endOfDay } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LivestreamDetailModalComponent } from 'src/app/modules/dashboard/pages/dashboard-livestreams/components/livestream-detail-modal/livestream-detail-modal.component';
import { ScheduleModalComponent } from '../messages/components/schedule-modal/schedule-modal.component';

@Component({
  selector: 'app-dashboard-livestreams',
  templateUrl: './dashboard-livestreams.component.html',
  styleUrls: ['./dashboard-livestreams.component.scss']
})
export class DashboardLivestreamsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  ifSchedule: any = true
  selected = new FormControl(0);
  totalStreams = 0;

  constructor(public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void { }

  showLivestreamDetailsModal() {
    this.dialog.open(LivestreamDetailModalComponent, {
      maxWidth: '100vw',
      width: '100vw',
      height: '100%',
      data: {}
    });
  }

  getStreamsCount(count: any) {
    this.totalStreams = count;
  }

  createCampaign() {
    this.router.navigate(['/' + routesConst.campaign]);
  }

  postcontent() {
    this.router.navigate(['/' + routesConst.content]);
  }

  addNewEvent(): void {
    var newDate = new Date()
    newDate.setHours(12)
    newDate.setMinutes(0)
    const newEvent = [
      {
        title: 'New event',
        actions: [],
        start: new Date(),
        end: new Date(),
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        description: 'New Description'
      },

    ];
    const dialogRef = this.dialog.open(ScheduleModalComponent, {
      width: '536px',
      maxWidth: '100vw',
      maxHeight: 'calc(100vh - 30px)',
      data: { events: newEvent, isPreview: false }
    });
    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.selected = new FormControl(0);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
