import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { UsernameselectionComponent } from '../usernameselection/usernameselection.component';
import { AUTHCONSTANTS } from 'src/app/const/auth.constants';

@Component({
  selector: 'app-auth-sign-in',
  templateUrl: './auth-sign-in.component.html',
  styleUrls: ['./auth-sign-in.component.scss'],
})
export class AuthSignInComponent implements OnInit {
  signInForm: FormGroup;
  routesConst = routesConst;
  loading = false;
  hide = { password: true };
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();
  @Input() isDialog = false;
  @Output() forsignup = new EventEmitter<string>();

  constructor(
    public fb: FormBuilder,
    private router: Router,
    private snack: SnackBarService,
    private authService: AuthService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {
    this.signInForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(256),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.checkIfLoggedIn();
  }

  submitForm() {
    if (this.signInForm.invalid) {
      return;
    } else {
      this.loading = true;
      const { password } = this.signInForm.value;
      const email = this.signInForm.value.email.toLowerCase();
      this.authService
        .login({ email, password })
        .then((message) => {
          if (message === AUTHCONSTANTS.LOGINED_SUCCESS) {
            this.checkIfLoggedIn();
          } else if (message === AUTHCONSTANTS.USER_NAME_NULL) {
            this.closeDialog.emit();
            this.dialog.open(UsernameselectionComponent, {
              hasBackdrop: false,
              width: '400px',
              height: 'h-fit',
              maxHeight: '600',
              data: {},
            });
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.snack.showMessage(err.description, true);
          this.loading = false;
        });
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.signInForm.controls[control].hasError(error);
  };

  private checkIfLoggedIn(): void {
    const isLoggedIn = this.authService.loggedIn();
    if (isLoggedIn) {
      this.loading = false;
      this.closeDialog.emit();
      this.router.navigate(['/dashboard']);
    }
  }

  contactUs() {
    this.commonService.location().href = 'mailto:support@jobpros.io';
  }

  forSignUpRedirection(routeName:string) {
    this.forsignup.emit(routeName);
  }
}
