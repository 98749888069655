<app-part-header [data]="earnHeader"></app-part-header>

<!-- <app-part-home [data]="partWorkFlow"></app-part-home> -->

<!-- <app-part-home [data]="whatYouGet"></app-part-home>

<app-pakages-card [data]="workFlows"></app-pakages-card> -->
<div class="background-section">
  <div class="sections-main">
    <div class="section-2-main">
      <div class="flex flex-col w-full mx-auto">
        <p class="lets-grow-together">LET’S GROW TOGETHER</p>
        <p class="join-heading">
          Why become a member of the fastest growing community</p>
        <p class="section-title-divider fastgrowing-divider"></p>
        <p class="description">
          Get hired, sell content and expert services. Invite others to your network & earn monthly residual income.  
        </p>
      </div>

      <!-- for mobile screen -->
      <div class="images-main block md:hidden">
        <swiper [pagination]="{ clickable: true }" [config]="config">
          <ng-template swiperSlide>
            <div class="footer-images" (click)="uploadPostModal(loginSignUpConstant.login)">
              <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/CONTENT+IMAGE.jpeg" alt="">
              <p>CONTENT</p>
             </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/'+routesConst.dashboard + '/'+routesConst.jobs">
              <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBS+IMAGE.jpeg" alt="">
              <p>JOBS</p>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/'+routesConst.dashboard + '/'+routesConst.services">
              <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/SERVICES+IMAGE.jpeg" alt="">
              <p>SERVICES</p>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/'+routesConst.dashboard + '/'+routesConst.referrals">
              <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/REFERRALS+IMAGE.jpeg" alt="">
              <p>REFERRALS</p>
            </div>
          </ng-template>
        </swiper>
      </div>
      <!---------->

      <!-- for laptop screen -->
      <div  class="hidden md:grid grid-cols-4 gap-5">
        <div class="footer-images" (click)="uploadPostModal(loginSignUpConstant.login)">
         <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/CONTENT+IMAGE.jpeg" alt="">
         <p>CONTENT</p>
        </div>
        <div class="footer-images" [routerLink]="'/'+routesConst.dashboard + '/'+routesConst.jobs">
          <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBS+IMAGE.jpeg" alt="">
          <p>JOBS</p>
        </div>
        <div class="footer-images" [routerLink]="'/'+routesConst.dashboard + '/'+routesConst.services">
          <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/SERVICES+IMAGE.jpeg" alt="">
          <p>SERVICES</p>
        </div>
        <div class="footer-images" [routerLink]="'/'+routesConst.dashboard + '/'+routesConst.referrals">
          <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/REFERRALS+IMAGE.jpeg" alt="">
          <p>REFERRALS</p>
        </div>
      </div>
      <!-------->
    </div>
  </div>
</div>

<!-- how it works -->
<div class="howitworks-section m-auto">
  <div>
    <div id="comparePlans" class="text-center">
      <p
        class="montserrat-bold black-dark text-2xl md:text-3xl comparePlans-text"
      >
        How It Works
      </p>
      <p class="montserrat-medium black-dark my-4">
        Unlimited Potential
      </p>
    </div>
    <div class="flex mt-3 mb-8 justify-center">
      <div class="p-1 flex items-center bg-gray rounded-full">
        <button
          class="payment-section_tab border-plus-text bg-gray payment-section_tab px-4 py-2 rounded-full mr-4"
          [class]="forIndividual ? 'bg-blu shadow-lg' : ''"
          (click)="forIndividual = true"
        >
          <p
            class="montserrat-medium"
            [class]="!forIndividual ? 'gray-dark' : 'black-dark'"
          >
            For Individuals
          </p>
        </button>
        <button
          class="payment-section_tab payment-section_tab bg-gray border-plus-text payment-section_tab px-4 py-2 rounded-full"
          [class]="!forIndividual ? 'bg-blu shadow-lg' : ''"
          (click)="forIndividual = false"
        >
          <p
            class="montserrat-medium"
            [class]="forIndividual ? 'gray-dark' : 'black-dark'"
          >
            For Companies
          </p>
        </button>
      </div>
    </div>
  </div>

  <!-- FOR INDIVIDUAL AND LAPTOP -->
  <div
    *ngIf="forIndividual && innerWidth && innerWidth >= 768"
    class="hidden md:block"
  >
    <div class="referal-cards mx-auto">
      <div
        *ngFor="let item of individualHowItWorks" [routerLink]="item.route"
        class="flex flex-col items-center card-style cursor-pointer"
      >
        <div>
          <img class="mb-3 howitworks-icon" [src]="item.icon" alt="" />
          <p class="text-lg title">
            {{ item.heading }}
          </p>
          <div class="mt-1">
            <p class="montserrat-medium description black-dark">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FOR INDIVIDUAL AND MOBILE -->
  <div
    *ngIf="forIndividual && innerWidth && innerWidth < 768"
    class="grid grid-cols-1 card-height"
  >
    <div class="grid mx-auto md:px-4 lg:px-0">
      <swiper [pagination]="{ clickable: true }" [config]="config" class="howitworks-swipperr">
        <ng-template
          swiperSlide
          *ngFor="let item of individualHowItWorks"
        >
          <div [routerLink]="item.route"
            class="flex flex-col items-center card-style cursor-pointer"
          >
            <div>
              <img class="mb-3 howitworks-icon" [src]="item.icon" alt="" />
              <p class="text-lg title">
                {{ item.heading }}
              </p>
              <div class="mt-1">
                <p class="montserrat-medium description black-dark">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>

  <!-- FOR COMPANY AND MOBILE -->
  <div
    *ngIf="!forIndividual && innerWidth && innerWidth < 768"
    class="grid grid-cols-1 card-height"
  >
    <div class="grid mx-auto md:px-4 lg:px-0">
      <swiper [pagination]="{ clickable: true }" [config]="config" class="howitworks-swipperr">
        <ng-template
          swiperSlide
          *ngFor="let item of companyHowItWorks"
        >
          <div [routerLink]="item.route"
            class="flex flex-col items-center card-style cursor-pointer"
          >
            <div>
              <img class="mb-3 howitworks-icon" [src]="item.icon" alt="" />
              <p class="text-lg title">
                {{ item.heading }}
              </p>
              <div class="mt-1">
                <p class="montserrat-medium black-dark description">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>

  <!-- FOR COMPANY AND LAPTOP -->
  <div *ngIf="!forIndividual && innerWidth && innerWidth >= 768">
    <div class="referal-cards mx-auto">
      <div
        *ngFor="let item of companyHowItWorks" [routerLink]="item.route"
        class="flex flex-col items-center card-style cursor-pointer"
      >
        <div>
          <img class="mb-3 howitworks-icon" [src]="item.icon" alt="" />
          <p class="text-lg title">
            {{ item.heading }}
          </p>
          <div class="mt-1">
            <p class="montserrat-medium black-dark description">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button class="outlin-rounded-btn w-full mt-0 md:mt-4 md:w-fit mx-auto block">Get Started</button>
</div>
<!-- REFERRALS SECTION -->
<div class="referral-wrapper">
  <div class="refferal-cards mx-auto">
    <div class="flex justify-between items-center mb-4">
      <div>
        <h1>Referrals</h1>
        <p class="heading-underline"></p>
      </div>
      <button
        class="view-moreBtn hidden md:flex"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
      >
        Get Started
      </button>
    </div>
  
    <!-- LAPTOP SCREEN -->
    <div  *ngIf="innerWidth && innerWidth >= 768" class="grid grid-cols-1">
      <div class="referal-cards mx-auto md:px-4 lg:px-0">
        <div
          *ngFor="let item of Refferal"
          class="flex flex-col items-center card-style cursor-pointer"
          [routerLink]="
            '/' + routesConst.dashboard + '/' + routesConst.referrals
          "
        >
          <div>
            <p class="text-center text-lg title" *ngIf="item?.title">
              {{ item.title }}
            </p>
            <div class="mt-4">
              <p class="description text-center" *ngIf="item?.description">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- MOBILE SCREEN -->  
    <div  *ngIf="innerWidth && innerWidth < 768" class="grid grid-cols-1"> 
      <div class="grid mx-auto md:px-4 lg:px-0">
        <swiper [pagination]="{ clickable: true }" [config]="config" class="refferal-swipperr">
          <ng-template *ngFor="let item of Refferal" swiperSlide>
        <div        
          class="flex flex-col items-center card-style cursor-pointer"
          [routerLink]="
            '/' + routesConst.dashboard + '/' + routesConst.referrals
          "
        >
          <div>
            <p class="text-center text-lg title" *ngIf="item?.title">
              {{ item.title }}
            </p>
            <div class="mt-4">
              <p class="description text-center" *ngIf="item?.description">
                {{ item.description }}
              </p>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
      </div>
    </div>
    <button
      class="view-moreBtn flex md:hidden w-full mt-4"
      [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals"
    >
      Get Started
      
    </button>
  </div>
</div>



<!-- <app-part-home [data]="earnReferrals"></app-part-home> -->

<div id="earnReferrals" class="range-slider">
  <app-range-slider [data]="earnRangeSlider" [page]="'earn'"></app-range-slider>
</div>

<app-part-footer></app-part-footer>