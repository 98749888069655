<div class="dashboard-payment-history section-wrapper bg-white lg:p-10">
  <p class="montserrat-bold text-black ">History</p>
  <div class="flex justify-between items-center">
  </div>
  <div class="mt-4">
    <table mat-table [dataSource]="dataSource" class="w-full shadow-none mat-elevation-z8" *ngIf="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          <p class="text-black montserrat-bold s hidden lg:block">Transaction ID</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <p class="hidden lg:block">{{element.created}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          <p class="text-black montserrat-bold s">Status</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <p>{{element.status | titlecase}}</p>
          <div class="mt-0.5">
            <p class="s gray-dark">{{element.description |titlecase}}</p>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          <p class="text-black montserrat-bold s text-right hidden lg:block">Date</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <p class="text-right hidden lg:block">{{element.created * 1000 | date: 'MM/dd/yyyy'}}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>
          <p class="text-black montserrat-bold s text-right">Amount</p>
        </th>
        <td mat-cell *matCellDef="let element">
          <p class="text-right">{{element.amount/100}}</p>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="flex justify-center text-center mt-6">
      <p class="s gray-dark">You’ve reached the end of the list</p>
    </div>
  </div>
</div>