import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MenuIconComponent } from 'src/app/shared/components/menu-icon/menu-icon.component';
import { MonthPickerComponent } from 'src/app/shared/components/month-picker/month-picker.component';
import { PaymentCheckoutModalComponent } from 'src/app/shared/components/payment-checkout-modal/payment-checkout-modal.component';
import { PaymentOptionCardComponent } from 'src/app/shared/components/payment-option-card/payment-option-card.component';
import { PlanUpgradeComponent } from 'src/app/shared/components/plan-upgrade/plan-upgrade.component';
import { SelectFormComponent } from 'src/app/shared/components/select-form/select-form.component';
import { SlideCheckBoxComponent } from 'src/app/shared/components/slide-check-box/slide-check-box.component';
import { SubscriptionPackageModalComponent } from 'src/app/shared/components/subscription-package-modal/subscription-package-modal.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { environment } from 'src/environments/env';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { SubscriptionContentUploadComponent } from './components/subscription-content-upload/subscription-content-upload.component';
import { IndividualServiceCardComponent } from './components/individual-service-card/individual-service-card.component';
import { JobPostComponent } from './components/job-post/job-post.component';
import { MasonryPanelComponent } from './components/masonry-panel/masonry-panel.component';
import { PricingPackageCardComponent } from './components/pricing-package-card/pricing-package-card.component';
import { PackageUnitComponent } from './components/service-modal/components/package-unit/package-unit.component';
import { ServiceCategoryExpansionComponent } from './components/service-modal/components/service-category-expansion/service-category-expansion.component';
import { ServiceModalComponent } from './components/service-modal/service-modal.component';
import { VideoPreviewModalComponent } from './components/video-preview-modal/video-preview-modal.component';
import { ViewProfileButtonComponent } from './components/view-profile-button/view-profile-button.component';
// import { NgxMasonryModule } from 'ngx-masonry';
import { RouterModule } from '@angular/router';
import { MentionModule } from 'angular-mentions';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ClipboardModule } from 'ngx-clipboard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxStripeModule } from 'ngx-stripe';
import { DashboardReferalsComponent } from 'src/app/modules/dashboard/pages/dashboard-referals/dashboard-referals.component';
import { DashboardReferalsConnectionComponent } from 'src/app/modules/dashboard/pages/dashboard-referals/tabs/dashboard-referals-connection/dashboard-referals-connection.component';
import { DashboardReferalsMeComponent } from 'src/app/modules/dashboard/pages/dashboard-referals/tabs/dashboard-referals-me/dashboard-referals-me.component';
import { MessagesComponent } from 'src/app/modules/dashboard/pages/messages/messages.component';
import { CampaignTabComponent } from 'src/app/modules/dashboard/pages/messages/tabs/campaign-tab/campaign-tab.component';
import { CampaignCardComponent } from 'src/app/modules/dashboard/pages/messages/tabs/campaign-tab/components/campaign-card/campaign-card.component';
import { ClientTabComponent } from 'src/app/modules/dashboard/pages/messages/tabs/client-tab/client-tab.component';
import { MessageClientCardComponent } from 'src/app/modules/dashboard/pages/messages/tabs/client-tab/components/message-client-card/message-client-card.component';
import { MyNetworkTabComponent } from 'src/app/modules/dashboard/pages/messages/tabs/my-network-tab/my-network-tab.component';
import { MessageUserContactCardComponent } from 'src/app/modules/dashboard/pages/messages/tabs/users-tab/components/message-user-contact-card/message-user-contact-card.component';
import { UsersTabComponent } from 'src/app/modules/dashboard/pages/messages/tabs/users-tab/users-tab.component';
import { PartFooterComponent } from 'src/app/modules/home/components/part-footer/part-footer.component';
import { PartHeaderComponent } from 'src/app/modules/home/components/part-header/part-header.component';
import { PartHomeComponent } from 'src/app/modules/home/components/part-home/part-home.component';
import { PartIntroComponent } from 'src/app/modules/home/components/part-intro/part-intro.component';
import { PartOfferComponent } from 'src/app/modules/home/components/part-offer/part-offer.component';
import { PartPlanComponent } from 'src/app/modules/home/components/part-plan/part-plan.component';
import { PartWorkflowComponent } from 'src/app/modules/home/components/part-workflow/part-workflow.component';
import { HomeComponent } from 'src/app/modules/home/home.component';
import { DynamicTabsComponent } from 'src/app/modules/jobs/components/dynamic-tabs/dynamic-tabs.component';
import { JobCardComponent } from 'src/app/modules/jobs/components/job-card/job-card.component';
import { JobsComponent } from 'src/app/modules/jobs/jobs.component';
import { PageNotFoundComponent } from 'src/app/modules/users/pages/page-not-found/page-not-found.component';
import { EducCardComponent } from 'src/app/modules/users/pages/profile/components/educ-card/educ-card.component';
import { HeroComponent } from 'src/app/modules/users/pages/profile/components/hero/hero.component';
import { InfoCardComponent } from 'src/app/modules/users/pages/profile/components/info-card/info-card.component';
import { ProfileAboutComponent } from 'src/app/modules/users/pages/profile/components/profile-about/profile-about.component';
import { ProfileHomeComponent } from 'src/app/modules/users/pages/profile/components/profile-home/profile-home.component';
import { ProfilePlansComponent } from 'src/app/modules/users/pages/profile/components/profile-plans/profile-plans.component';
import { ProfileServicesComponent } from 'src/app/modules/users/pages/profile/profile-services/profile-services.component';
import { ProfileComponent } from 'src/app/modules/users/pages/profile/profile.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperModule } from 'swiper/angular';
import { StickyFooterComponent } from '../core/components/sticky-footer/sticky-footer.component';
import { PakagesCardComponent } from '../modules/home/components/pakages-card/pakages-card.component';
import { PartPricingComponent } from '../modules/home/components/part-pricing/part-pricing.component';
import { RangeSliderComponent } from '../modules/home/components/range-slider/range-slider.component';
import { CommentCardComponent } from './components/comment-card/comment-card.component';
import { CommentModalComponent } from './components/comment-modal/comment-modal.component';
import { ContentPreviewComponent } from './components/content-preview/content-preview.component';
import { EmailVerificationPopupComponent } from './components/email-verification-popup/email-verification-popup.component';
import { FollowStateComponent } from './components/follow-state/follow-state.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { InviteUserModalComponent } from './components/invite-user-modal/invite-user-modal.component';
import { JobModalComponent } from './components/job-modal/job-modal.component';
import { LayoutModeComponent } from './components/layout-mode/layout-mode.component';
import { MembersPanelComponent } from './components/members-panel/members-panel.component';
import { OnBoardModalComponent } from './components/on-board-modal/on-board-modal.component';
import { PeopleCardComponent } from './components/people-card/people-card.component';
import { PostCardComponent } from './components/post-card/post-card.component';
import { PostDetailCardComponent } from './components/post-detail-card/post-detail-card.component';
import { SeoServiceService } from './components/seo-service.service';
import { StripePaymentCardComponent } from './components/stripe-payment-card/stripe-payment-card.component';
import { MaterialModule } from './material.module';
import { RemovewhitespacePipe } from './pipes/removewhitespace.pipe';
import { MentionLinkPipe } from './pipes/mentionLink.pipe';
import { HashtagLinkPipe } from './pipes/hashtagLink.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { EarnService } from './services/earn.service';
import { SnackBarService } from './services/snack-bar.service';
import { AlertToastrService } from './services/toastr.service';
import { WorkFlowService } from './services/work-flow.service';
import { LoginSignupDialogComponent } from './components/login-signup-dialog/login-signup-dialog.component';
import { AuthSignInComponent } from '../modules/authentication/auth-sign-in/auth-sign-in.component';
import { AuthSignUpComponent } from '../modules/authentication/auth-sign-up/auth-sign-up.component';
import { RegisterComponent } from '../modules/authentication/register/register.component';
import { UsernameselectionComponent } from '../modules/authentication/usernameselection/usernameselection.component';
import { QuickCreateComponentComponent } from './components/quick-create-component/quick-create-component.component';
import { ProfileJobsComponent } from '../modules/users/pages/profile/components/profile-jobs/profile-jobs.component';
import { MultimediaDisplayComponent } from '../modules/home/components/multimedia-display/multimedia-display.component';
import { TalentCardComponent } from '../modules/talents/components/talent-card/talent-card.component';
import { MentionTriggerDirective } from './directives/mentions.directive';
import { MentionService } from './services/mentions.service';
import { DisputeModalComponent } from '../modules/dashboard/pages/dashboard-services/tabs/dispute-modal/dispute-modal.component';
import { MostViewedPostsComponent } from './components/most-viewed-posts/most-viewed-posts.component';
import { StarRatingModule } from 'angular-star-rating';
import { FeatureMenuComponent } from '../core/components/feature-menu/feature-menu.component';
import { DiscoverComponent } from './components/discover/discover.component';
import { CommunitiesComponent } from './components/communities/communities.component';
import { TalentsComponent } from '../modules/talents/talents.component';
import { TalentSearchTabGroupComponent } from '../modules/talents/components/talent-search-tab-group/talent-search-tab-group.component';
import { AllFiltersComponent } from './components/all-filters/all-filters.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { PostStoryComponent } from './components/post-story/post-story.component';
import { UsersComponent } from '../modules/users/pages/users/users.component';
import { SubscriptionPackageUnitComponent } from './components/subscription-package-unit/subscription-package-unit.component';
import { TutorialsComponent } from './components/tutorials/tutorials.component';
import { AuthResetPasswordComponent } from '../modules/authentication/auth-reset-password/auth-reset-password.component';
import { SafeUrlPipe } from './pipes/safeurl.pipe';
import { InViewLoggerDirective } from './directives/in-view-logger.directive.';
import { PostComponent } from '../modules/users/pages/post/post.component';
import { FooterPricingPlansComponent } from '../modules/home/components/footer-pricing-plans/footer-pricing-plans.component';
import { HomePartFooterComponent } from '../modules/home/components/home-part-footer/home-part-footer.component';
import { ShareLinksComponent } from './components/share-links/share-links.component';
import { JoinCommunityComponent } from '../modules/home/components/join-community/join-community.component';
import { FooterVideosSectionComponent } from './components/footer-videos-section/footer-videos-section.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FeedComponent } from '../modules/home/components/feed/feed.component';
import { FeedFilterPanelComponent } from '../modules/home/components/feed-filter-panel/feed-filter-panel.component';
import { MediaInteractionPanelComponent } from '../modules/home/components/media-interaction-panel/media-interaction-panel.component';
import { ContentCardComponent } from './components/content-card/content-card.component';
import { RecommendedPostStoryComponent } from './components/recommended-post-story/recommended-post-story.component';

@NgModule({
  declarations: [
    FooterVideosSectionComponent,
    InViewLoggerDirective,
    PostComponent,
    FeedComponent,
    UsersComponent,
    PostStoryComponent,
    AllFiltersComponent,
    CommunitiesComponent,
    DiscoverComponent,
    ContentCardComponent,
    MostViewedPostsComponent,
    MentionTriggerDirective,
    QuickCreateComponentComponent,
    CommentModalComponent,
    StickyFooterComponent,
    PartPricingComponent,
    PakagesCardComponent,
    RangeSliderComponent,
    PartHeaderComponent,
    DynamicTabsComponent,
    RecommendedPostStoryComponent,
    JobsComponent,
    JobCardComponent,
    DashboardReferalsMeComponent,
    DashboardReferalsComponent,
    PartOfferComponent,
    PartIntroComponent,
    PartWorkflowComponent,
    PartHomeComponent,
    PartFooterComponent,
    HomePartFooterComponent,
    DashboardReferalsConnectionComponent,
    HomeComponent,
    FeedFilterPanelComponent,
    MediaInteractionPanelComponent,
    PartPlanComponent,
    FooterPricingPlansComponent,
    JoinCommunityComponent,
    ProfilePlansComponent,
    ProfileServicesComponent,
    InfoCardComponent,
    EducCardComponent,
    ProfileAboutComponent,
    ProfileJobsComponent,
    ProfileHomeComponent,
    ProfileComponent,
    HeroComponent,
    MessageUserContactCardComponent,
    MessageClientCardComponent,
    CampaignCardComponent,
    ClientTabComponent,
    UsersTabComponent,
    CampaignTabComponent,
    MessagesComponent,
    MyNetworkTabComponent,
    ConfirmModalComponent,
    ImageUploadComponent,
    SubscriptionContentUploadComponent,
    IndividualServiceCardComponent,
    JobPostComponent,
    MenuIconComponent,
    MonthPickerComponent,
    PricingPackageCardComponent,
    PaymentOptionCardComponent,
    PaymentCheckoutModalComponent,
    PlanUpgradeComponent,
    PackageUnitComponent,
    SlideCheckBoxComponent,
    ServiceModalComponent,
    SelectFormComponent,
    SubscriptionPackageModalComponent,
    ServiceCategoryExpansionComponent,
    UploadPostModalComponent,
    VideoPreviewModalComponent,
    ViewProfileButtonComponent,
    MasonryPanelComponent,
    PostCardComponent,
    PostDetailCardComponent,
    CommentCardComponent,
    LayoutModeComponent,
    MembersPanelComponent,
    PeopleCardComponent,
    FollowStateComponent,
    PageNotFoundComponent,
    JobModalComponent,
    GoBackComponent,
    InviteUserModalComponent,
    RemovewhitespacePipe,
    MentionLinkPipe,
    HashtagLinkPipe,
    SafeUrlPipe,
    ShortNumberPipe,
    OnBoardModalComponent,
    ContentPreviewComponent,
    StripePaymentCardComponent,
    EmailVerificationPopupComponent,
    LoginSignupDialogComponent,
    AuthSignInComponent,
    AuthSignUpComponent,
    AuthResetPasswordComponent,
    RegisterComponent,
    UsernameselectionComponent,
    MultimediaDisplayComponent,
    TalentCardComponent,
    DisputeModalComponent,
    FeatureMenuComponent,
    TalentsComponent,
    TalentSearchTabGroupComponent,
    SubscriptionPackageUnitComponent,
    TutorialsComponent,
    ShareLinksComponent
  ],
  imports: [
    MatSlideToggleModule,
    CdkAccordionModule,
    // StickyFooterComponent,
    MentionModule,
    ClipboardModule,
    InfiniteScrollModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    // NgxMasonryModule,
    HttpClientModule,
    NgxSpinnerModule,
    MaterialModule,
    RouterModule,
    NgxStripeModule.forRoot(environment.stripePublishKey),
    FlatpickrModule.forRoot(),
    StarRatingModule.forRoot()
  ],

  providers: [
    InViewLoggerDirective,
    SnackBarService,
    SharedService,
    AlertToastrService,
    SeoServiceService,
    WorkFlowService,
    EarnService,
    MentionService
  ],

  exports: [
    PostComponent,
    FeedComponent,
    FooterVideosSectionComponent,
    CommunitiesComponent,
    FeatureMenuComponent,
    QuickCreateComponentComponent,
    CommentModalComponent,
    StickyFooterComponent,
    RangeSliderComponent,
    PartHeaderComponent,
    DynamicTabsComponent,
    InfiniteScrollModule,
    FollowStateComponent,
    GoBackComponent,
    IndividualServiceCardComponent,
    MenuIconComponent,
    MonthPickerComponent,
    PaymentOptionCardComponent,
    PaymentCheckoutModalComponent,
    PlanUpgradeComponent,
    PricingPackageCardComponent,
    SelectFormComponent,
    SlideCheckBoxComponent,
    ViewProfileButtonComponent,
    PostCardComponent,
    PostDetailCardComponent,
    MasonryPanelComponent,
    CommentCardComponent,
    LayoutModeComponent,
    MembersPanelComponent,
    PeopleCardComponent,
    MaterialModule,
    NgxSpinnerModule,
    SwiperModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    PageNotFoundComponent,
    FlatpickrModule,
    RemovewhitespacePipe,
    MentionLinkPipe,
    HashtagLinkPipe,
    SafeUrlPipe,
    OnBoardModalComponent,
    PartIntroComponent,
    PartHomeComponent,
    ShortNumberPipe,
    PartFooterComponent,
    HomePartFooterComponent,
    PakagesCardComponent,
    ContentPreviewComponent,
    StripePaymentCardComponent,
    EmailVerificationPopupComponent,
    LoginSignupDialogComponent,
    AuthSignInComponent,
    AuthResetPasswordComponent,
    AuthSignUpComponent,
    RegisterComponent,
    UsernameselectionComponent,
    MultimediaDisplayComponent,
    TalentCardComponent,
    DisputeModalComponent,
    MentionTriggerDirective,
    ShareLinksComponent
  ]
})
export class SharedModule {}
