import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { userTypeConstant } from 'src/app/const/appConst';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import {
  ImageService,
  ImageSnippet,
} from 'src/app/core/services/image.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { routesConst } from 'src/app/core/const/routers';

export interface Interest {
  _id?: number;
  name?: string;
  active?: boolean;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  userTypeConstant = userTypeConstant;
  private unsubscribe$ = new Subject();
  @ViewChild('searchLocation', { static: true })
  searchLocation?: ElementRef;
  location = new FormControl();
  usernameUpdate = new Subject<string>();
  avatar: any;
  city = '';
  interests: Interest[] = [];
  type: string = userTypeConstant.candidate;
  name!: string;
  user_name!: string;
  email!: string;
  password!: string;
  profile_img_path: any;
  bio: string = '';
  loading: boolean = false;
  usernameValidationError = false;
  validationChecking = true;
  referral: any;
  isSaving = false;

  locations: any;
  constructor(
    private snack: SnackBarService,
    private router: Router,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private authService: AuthService,
    private imageService: ImageService,
    private _commonService: CommonService
  ) {
    this.usernameUpdate
      .pipe(
        debounceTime(1500),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => {
        this.validationChecking = true;
        this.authService.validateUsername(value).then((isValid) => {
          this.usernameValidationError = !isValid;
          this.validationChecking = false;
        });
      });
    this.getInterestList();
  }

  ngOnInit(): void {
    this.checkLogin();
    const storage = localStorage.getItem('sign_up');
    if (storage !== null) {
      const signup = JSON.parse(storage);
      this.name = signup.name;
      this.email = signup.email;
      this.password = signup.password;
    } else {
      this.router.navigate([`/${routesConst.signup}`]);
    }
    this.referral = localStorage.getItem(
      JSON.parse(JSON.stringify('referralId'))
    );
    this.getSearchedLocation();
  }

  focusLocation() {
    this.searchLocation?.nativeElement.focus();
  }

  changeLocation() {
    this.city = '';
    this.location.setValue('');
  }

  validate() {
    (this.usernameValidationError = false), (this.validationChecking = true);
  }

  getSearchedLocation() {
    this._commonService
      ._filterLocation(this.searchLocation?.nativeElement)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((locations: any) => (this.locations = locations.data));
  }

  checkLogin(): void {
    if (this.authService.loggedIn()) {
      this.router.navigate([`/${routesConst.dashboard}`]);
    }
  }

  uploadImage(files: FileList) {
    if (files.length === 0) {
      return;
    }
    if (this.imageService.validateImageType(files[0].type)) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.avatar = reader.result;
      };
      const file: File = files[0];
      this.avatar = file;
      reader.addEventListener('load', (event: any) => {
        if (event?.target?.result) {
          this.imageService
            .imageConvertion(new ImageSnippet(event.target.result, file))
            .then((imageUri: any) => {
              this.profile_img_path = imageUri[0];
            });
        }
      });
    }
  }

  register() {
    this.isSaving = true;
    this.loading = true;
    const payload = {
      name: this.name,
      email: this.email,
      password: this.password,
      profile_img_path: this.profile_img_path,
      user_type: this.type,
      location_id: this.city === '' ? null : this.city,
      bio: this.bio,
      user_name: this.user_name,
      referralId: this.referral ? this.referral : null,
    };
    this.authService
      .signup(payload)
      .then(() => {
        localStorage.removeItem('referralId');
        localStorage.removeItem('sign_up');
        localStorage.setItem('verify_email', JSON.stringify(1));
        this.router.navigate(['/']);
        this.dialog.open(LoginSignupDialogComponent, {
          width: window.innerWidth > 600 ? '400px':'100vw',
          height: window.innerWidth > 600 ? 'h-fit':'100%',
          maxWidth: window.innerWidth > 600 ? '96%':'100vw',
          maxHeight: window.innerWidth > 600 ? '600':'100vh',
          data: { pageToOpen: 'login' },
        });
      })
      .catch(() => {
        localStorage.removeItem('sign_up');
        this.loading = false;
      });
  }

  getInterestList() {
    this.sharedService.skillsOrIntrest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((interest: any) => {
        if (interest.length) {
          this.interests = interest.map((skill: any) => ({
            _id: skill.id,
            name: skill.label,
            active: false,
          }));
        } else {
          this.sharedService.getSkillsOrIntrest();
        }
      });
  }

  hasWhiteSpace(user_name: string) {
    return user_name?.includes(' ');
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
