import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SelectFormType } from 'src/app/modules/users/pages/users/users.component';

@Component({
  selector: 'app-select-form',
  templateUrl: './select-form.component.html',
  styleUrls: ['./select-form.component.scss'],
})
export class SelectFormComponent implements OnInit {
  @Input() data?: SelectFormType;
  @Input() label?: string;
  @Output() set: EventEmitter<any> = new EventEmitter();
  option?: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['label'].currentValue === '') {
      this.option = '';
    }
  }

  trackByFunc(i: number) {
    return i;
  }

  onChange(ob: any) {
    this.set.emit(ob.value);
  }
}
