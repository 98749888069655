<div class="main-slidder">
  <div class="slidder-content mx-auto">
    <!-- <h1 class="heading">{{data?.superheading}}</h1> -->
    <div>
      <h3 class="sub-heading">{{data?.heading}}</h3>
      <p class="content" [ngClass]="data?.style ? 'decrption-maxwidth':'w-full'">{{data?.description}}</p>
      <div class="range-slider">
        <mat-slider [displayWith]="page === 'home' ? null : formatLabel" #monthlyIncome class="w-full" [min]="page === 'home' ? 250 : 2500" [value]="page === 'home' ? 250 : 2500" max="10000" step="1" showTickMarks discrete>
          <input matSliderThumb />
        </mat-slider>
      </div>
    </div>
    <div>
      <h3 class="sub-heading">{{data?.subheading}}</h3>
      <p class="content">{{data?.subdescription}}</p>
      <div class="range-slider">
        <mat-slider [displayWith]="page === 'home' ? formatLabel : null" #annualIncome value="10" class="w-full" min="10" max="100000" step="1" showTickMarks
          discrete>
          <input matSliderThumb />
        </mat-slider>
      </div>
    </div>
    <div>
      <p class="Annual-income-show text-black mb-4 md:mb-2">{{data?.income}}</p>
      <div class="flex flex-col md:flex-row justify-between md:items-center gap-4 md:gap-0">
        <p class="income">${{annualIncome.value*monthlyIncome.value*data.getTotal | shortNumber }}</p>
        <button  mat-button
        class="get-started-btn montserrat-medium mt-md button outlined black btn-size mt-4 mx-auto" (click)="openSignUp(loginSignUpConstant.signup)">Get Started</button>
      </div>
    </div>
  </div>
</div>