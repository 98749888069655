<div class="review-influencers">
  <div class="flex flex-col lg:flex-row items-start lg:items-center">
    <div class="flex items-center">
      <button matStepperPrevious class="mt-1">
          <mat-icon class="arrow-back hover:bg-gray-200 rounded-full">arrow_back</mat-icon>
      </button>
      <h1 class="montserrat-bold black-dark title">Review influencers (4)</h1>
    </div>
    <p class="ml-auto montserrat-bold blue mt-3 mr-4 lg:mr-0 cursor-pointer" (click)="approveAll()">Approve all</p>
  </div>
  <div class="section-wrapper lg:mt-8 p-4 lg:px-10 lg:py-5">
    <div class="influencer-card" *ngFor="let item of influencers">
      <div class="flex items-center py-4">
        <img [src]="item.img">
        <div class="ml-4">
          <div class="flex flex-col lg:flex-row lg:items-center">
            <p class="montserrat-bold">{{item.name}}</p>
            <button matStepperNext class="flex button normal stepper-next-label mt-2 lg:mt-0">
              <span class="montserrat-bold lg:pl-1" style="font-weight: bold;">· View profile</span>
            </button>
          </div>
          <p class="s my-2.5">{{item.location}}</p>
          <p >{{kFormatter(item.followN)}} followers</p>
        </div>
        <div class="flex flex-col justify-end ml-auto">
          <button mat-button class="button" (click)="approve(item.id)">Approve</button>
          <div class="mt-2">
            <button mat-button class="button normal normal w-full" (click)="reject(item.id)">Reject</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
