import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomerAllReviewsModalComponent } from '../customer-all-reviews-modal/customer-all-reviews-modal.component';

@Component({
  selector: 'app-customer-ratings',
  templateUrl: './customer-ratings.component.html',
  styleUrls: ['./customer-ratings.component.scss'],
})
export class CustomerRatingsComponent implements OnInit {
  @Input() reviews: any = [];
  @Input() selectedService: any;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openReviewModal() {
    this.dialog.open(CustomerAllReviewsModalComponent, {
      maxWidth: innerWidth && innerWidth > 768 ? '800px' : '100vw',
      width: '100%',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: { selectedService: this.selectedService,reviewsType: 'Seller' },
      disableClose: true,
    });
  }
}
