import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-order-unit',
  templateUrl: './service-order-unit.component.html',
  styleUrls: ['./service-order-unit.component.scss']
})
export class ServiceOrderUnitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
