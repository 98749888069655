import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { SidenavListComponent } from './components/header/components/sidenav-list/sidenav-list.component';
import { SidenavFooterComponent } from './components/header/components/sidenav-footer/sidenav-footer.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { NotificationComponent } from './components/notification/notification.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    HeaderComponent,
    SidenavListComponent,
    SidenavFooterComponent,
    BottomSheetComponent,
    NotificationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragDropModule,
  ],
  exports: [
    HeaderComponent,
  ],
})
export class CoreModule { }
