<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">close</mat-icon>
</button>

<div *ngIf="coverContent" class="w-full h-3/5 relative cursor">
  <div *ngIf="coverContent.format == 'image'" style="height: 247px" class="w-full bg-contain bg-no-repeat bg-center"
    [ngStyle]="{ 'background-image': 'url(' + coverContent.content + ')' }"></div>
  <video style="height: 247px" *ngIf="coverContent && coverContent.format == 'video'" class="w-full bg-black-dark"
    [src]="coverContent.content+ '#t=0.1'" preload="metadata" controls></video>
</div>

<div class="grid grid-cols-5 gap-2">
  <div *ngFor="let post of data.posts; let i = index"
    class="flex relative h-16 border border-current rounded bg-white cursor" (click)="preview(post)">
    <div *ngIf="post.format === 'image'" class="w-full h-full bg-contain bg-no-repeat bg-center"
      [ngStyle]="{ 'background-image': 'url(' + post.content + ')' }"></div>
    <video *ngIf="post.format === 'video'" class="w-full h-full bg-black-dark" [src]="post.content+ '#t=0.1'"
      preload="metadata" disablePictureInPicture (click)="preview(post)" playsinline webkit-playsinline></video>
  </div>
</div>