<div class="applicant-main relative" *ngIf="candidateDetails && candidateDetails.length > 0">
  <div class="realtive header-notification w-full">
    <img class="w-10 absolute top-2 left-3" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">
  
    <p class="text-white montserrat-bold mb-0">Review applicants</p>
    <mat-icon
      class="text-white absolute right-2.5 top-2 mt-1 cursor-pointer"
      mat-dialog-close
      >close</mat-icon
    >
  </div>
  <div class="modal-body p-0 d-flex flex-nowrap h-full">
    <div class="flex main-conversation-wrapper relative">
      <h3 class="montserrat-bold" *ngIf="reviewApplicantData && reviewApplicantData.length === 0">
        No Applicant Found
      </h3>
      <aside class="overflow-auto" *ngIf="reviewApplicantData && reviewApplicantData.length" [ngClass]="isActive ? 'active h-full' : 'inactive h-full'">
        <button *ngFor="let applicantData of reviewApplicantData"
        (click)="toggleAside()"
          [class]="applicantData.candidate._id === selectedUser ? 'activeUser' : ''"
          class="application-item w-full flex items-center px-1 py-4"
          (click)="changeCandidate(applicantData.candidate._id); goForward(stepper);">
          <img src="{{ applicantData.candidate.profile_img_path }}" class="applicant-avatar mr-2" onerror="this.src='assets/images/no-image.png'" />
          <div class="applicant-name-wrapper">
            <p class="text-left applicant-name whitespace-nowrap truncate">
              {{ applicantData.candidate.name | titlecase }}
            </p>
            <p class="s text-left applicant-status"
              [class]="applicantData.jobApplied.applied_status === 'Approved' ? 'green' : 'red'">
              {{ applicantData.jobApplied.applied_status | titlecase }}
            </p>
          </div>
          <img class="md:hidden" src="/assets/images/arrow-right.svg" alt="">
        </button>
      </aside>

      <div class="modal-content overflow-auto" [ngClass]="isActive ? 'active-modal-content modal-content' : 'inactive-modal-content modal-content'" *ngIf="candidateDetails && candidateDetails.length > 0">
        <div class="flex justify-between my-2 px-4 gap-2">
          <p class="montserrat-bold mt-2 text-black">{{data?.title}}</p>
          <div class="flex items-center">
            <img mat-dialog-close
                class="applicant-avatar object-cover rounded-full cursor-pointer"
                [src]="loginUser.profileInfo.avatar"
                onerror="this.src='assets/images/no-image.png'"
              />
          </div>
        </div>
        <div *ngFor="let applicant of candidateDetails">
          <div class="user-title px-4 md:px-7 py-2.5 bg-white-dark flex" *ngIf="applicant?.candidate?.user_name">
            <mat-icon (click)="toggleAside()" class="mr-1 md:hidden arrow-back">arrow_back</mat-icon>
            <div>
            <div class="mb-1" *ngIf="applicant?.candidate?.user_name">
              <p class="montserrat-medium break-all">{{ applicant.candidate.user_name }}</p>
            </div>
            <p class="s montserrat-medium">Applied {{formatAppliedDate(applicant.jobApplied.created_at)}} <a
                class="blue"  mat-dialog-close routerLink="/profile/{{applicant.candidate.user_name}}">View
                profile</a></p>
            </div>
          </div>
          <div class="px-6 py-10 lg:py-6">
            <div class="mb-4">
              <p class="montserrat-bold text-black" *ngIf="applicant?.candidate?.bio">About</p>
            </div>
            <div class="mb-10 md:mb-4">
              <p *ngIf="applicant?.candidate?.bio" class="break-words">
                {{ applicant.candidate.bio }}
              </p>
            </div>
            <p *ngIf="applicant?.jobApplied?.cover_letter" class="text-black montserrat-bold ">Applicant details</p>
            <div *ngIf="applicant?.jobApplied?.cover_letter" class="mt-4 mb-4">
              <p class="break-words">
                {{ applicant.jobApplied.cover_letter }}
              </p>
            </div>
            <div class="mb-4">
              <p class="montserrat-bold text-black" *ngIf="applicant?.candidate?.profileCategory">Category</p>
            </div>
            <div *ngIf="applicant?.candidate?.profileCategory" class="mb-10 md:mb-4">
              <div class="school-name">
                <p class="break-all">{{ applicant?.candidate?.profileCategory?.name }}</p>
              </div>
            </div>
            <div class="mb-4">
              <p class="montserrat-bold text-black" *ngIf="applicant?.achievements?.length">Experience Level</p>
            </div>
            <div *ngIf="applicant?.achievements?.length" class="mb-10 md:mb-4">
              <div class="school-name" *ngFor="let Experience of applicant?.achievements">
                <div *ngIf="Experience.type== AchievementType.ExpLevel">
                  <p class="break-all">{{ Experience.title }}</p>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <p class="montserrat-bold text-black" *ngIf="applicant?.candidate?.profileSkills">Preferred Skills</p>
            </div>
            <div *ngIf="applicant?.candidate?.profileSkills" class="mb-10 md:mb-4 flex flex-wrap gap-1.5">
              <p *ngFor="let skills of applicant?.candidate?.profileSkills" class="tag">{{ skills.name }}</p>
            </div>
            <div class="mb-4">
              <p class="montserrat-bold text-black" *ngIf="applicant?.achievements?.length">Client Project</p>
            </div>

            <div *ngIf="applicant?.achievements?.length" class="mb-10 md:mb-4">
              <div class="school-name" *ngFor="let clientProject of applicant?.achievements">
                <div *ngIf="clientProject.type == AchievementType.ClientProject">
                  <p class="montserrat-bold break-all">{{ clientProject.title }}</p>
                  <div class="mt-0.5 mb-1.5">
                    <p class="s gray-dark break-all">{{getFormattedDate(clientProject.start_date)}} - {{getFormattedDate(clientProject.end_date)}}</p>
                  </div>
                  <p class="break-all">{{ clientProject.description }}</p>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <p class="montserrat-bold text-black" *ngIf="applicant?.achievements?.length">Education</p>
            </div>

            <div *ngIf="applicant?.achievements?.length" class="mb-10 md:mb-4">
              <div class="school-name" *ngFor="let education of applicant?.achievements">
                <div *ngIf="education.type== AchievementType.Education">
                  <p class="montserrat-bold break-all">{{ education.title }}</p>
                  <div class="mt-0.5 mb-1.5">
                    <p class="s gray-dark break-all">{{getFormattedDate(education.start_date)}} - {{getFormattedDate(education.end_date)}}</p>
                  </div>
                  <p class="break-all">{{ education.description }}</p>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <p class="montserrat-bold text-black" *ngIf="applicant?.achievements?.length">Work History</p>
            </div>
            <div *ngIf="applicant?.achievements?.length" class="mb-10 md:mb-4">
              <div class="school-name" *ngFor="let workhistory of applicant?.achievements">
                <div *ngIf="workhistory.type== AchievementType.WorkHistory">
                  <p class="montserrat-bold break-all">{{ workhistory.title }}</p>
                  <div class="mt-0.5 mb-1.5">
                    <p class="s gray-dark break-all">{{getFormattedDate(workhistory.start_date)}} - {{getFormattedDate(workhistory.end_date)}}</p>
                  </div>
                  <p class="break-all">{{ workhistory.description }}</p>
                </div>
              </div>
            </div>
            <div class="mb-6 md:text-center" *ngIf="applicant?.jobApplied?.highlighted_experiences.length > 0">
              <p class="black-light montserrat-bold uppercase">
                highlighted experience
              </p>
            </div>
            <div class="w-full overflow-auto" *ngIf="applicant?.jobApplied?.highlighted_experiences.length > 0">
              <div class="experience-list">
                <div class="flex" *ngFor="let image of applicant?.jobApplied.highlighted_experiences">
                  <div class="experience-card mr-2">
                    <img class="experience-card_img" [src]="image.path" />
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <p *ngIf="applicant?.candidate?.skills?.length" class="montserrat-bold text-black break-all">Interests</p>
            </div>
            <div class="flex items-center mb-10 md:mb-4 flex-wrap" *ngIf="applicant?.candidate?.skills?.length">
              <div class="px-1" *ngFor="let skill of applicant?.candidate?.skills">
                <button class="tag my-1 break-all">
                  #{{ getSkillsName(skill) | titlecase }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between gap-5 sticky pt-2 mt-4 bg-white bottom-0 px-4" *ngIf="candidateDetails[0]?.jobApplied" align="start">
          <button *ngIf="candidateDetails[0]?.jobApplied?.applied_status !== 'Approved'"
            (click)="changeApplicantStatus('Approved',candidateDetails[0]?.jobApplied?.candidate_id,candidateDetails[0]?.jobApplied?._id)"
            mat-stroked-button class="button outlined black w-36">
            Approve
          </button>
          <button mat-stroked-button *ngIf="candidateDetails[0]?.jobApplied?.applied_status === 'Approved'"
            class="button outlined black w-36">
            Approved
          </button>
          <button *ngIf="candidateDetails[0]?.jobApplied?.applied_status !== 'Rejected'"
            (click)="changeApplicantStatus('Rejected',candidateDetails[0]?.jobApplied.candidate_id,candidateDetails[0]?.jobApplied?._id)"
            mat-stroked-button class="button warning black w-36 ">
            Reject
          </button>
          <button mat-stroked-button *ngIf="candidateDetails[0]?.jobApplied?.applied_status === 'Rejected'"
            class="button warning black w-36">
            Rejected
          </button>
        </div>
      </div>
    </div>
    <div class="section-wrapper hidden w-full h-full">
      <mat-horizontal-stepper labelPosition="bottom" #stepper>
        <mat-step>
          <div class="px-4" *ngIf="reviewApplicantData && reviewApplicantData.length"
            [class]="isActive ? 'active' : 'inactive'">
            <button (click)="toggleAside()" class="application-item w-full flex items-center px-1 py-4"
              (click)="changeCandidate(applicantData.candidate._id); goForward(stepper);"
              *ngFor="let applicantData of reviewApplicantData">
              <img src="{{ applicantData.candidate.profile_img_path }}" class="applicant-avatar mr-2" onerror="this.src='assets/images/no-image.png'"/>
              <div class="applicant-name-wrapper">
                <p class="montserrat-bold text-left whitespace-nowrap truncate applicant-name">
                  {{ applicantData.candidate.name | titlecase }}
                </p>
                <p class="s text-left applicant-status"
                  [class]="applicantData.jobappliedStaus === 'Approved' ? 'green' : 'red'">
                  {{ applicantData.jobappliedStaus | titlecase }}
                </p>
              </div>
              <mat-icon class="ml-auto">chevron_right</mat-icon>
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>