<div class="relative pt-6" [class]="loading ? 'spinner' : ''">
  <mat-icon class="text-3xl absolute close-icon -top-4 -right-3 cursor-pointer text-black" mat-dialog-close>close</mat-icon>
<a routerLink="/" class="absolute -left-3 -top-4">
  <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png"
    class="w-9 object-cover" />
</a>
  <h1
    class="register-page_title mx-auto mt-4 text-center heading"
  >
    Select Username
  </h1>
  <p class="mx-auto text-center mt-2 text-sm">
    Network, Share & Earn 
  </p>
  <div class="flex flex-col items-center justify-center mt-4">
    <input
      maxlength="129"
      class="input-form w-full mb-6"
      type="text"
      [(ngModel)]="user_name"
      (ngModelChange)="usernameUpdate.next($event)"
      (keydown)="validate()"
    />
    <div *ngIf="usernameValidationError && user_name?.trim()?.length">
      <p class="s red">User Name already exist. Please use another username.</p>
    </div>
    <div *ngIf="user_name && user_name.length > 128">
      <p class="s red">User Name cannot exceed more than 128 characters!</p>
    </div>
    <div
      *ngIf="hasWhiteSpace(user_name)"
      [class]="hasWhiteSpace(user_name) ? 'pb-4' : ''"
    >
      <p class="s red">User Name not contain white spaces.</p>
    </div>
    <div class="flex">
      <p class="s gray-dark">
        {{
          !user_name || !user_name?.trim()?.length || hasWhiteSpace(user_name)
            ? "Choose a unique username."
            : "Your public profile will be: https://jobpros.io/profile/" +
              user_name
        }}
      </p>
    </div>
  </div>
  <div class="flex w-full items-center justify-center mt-6">
    <button
      mat-button
      class="button"
      [class]="
        hasWhiteSpace(user_name) ||
        validationChecking ||
        usernameValidationError ||
        !user_name.trim().length ||
        user_name.length > 128 ||
        isSaving
          ? 'disabled'
          : ''
      "
      (click)="update()"
      [disabled]="
      loading ||
        hasWhiteSpace(user_name) ||
        validationChecking ||
        usernameValidationError ||
        !user_name.trim().length ||
        user_name.length > 128 ||
        isSaving
      "
    >
      CONTINUE
    </button>
  </div>
</div>
