<div class="flex flex-col justify-between relative">
  <div class="flex w-full absolute justify-end pr-0.5">
    <mat-icon
      class="mr-5 lg:mr-24 mt-2.5 lg:mt-9 black-light cursor-pointer hover:bg-red-200 rounded-full z-30 close-icon"
      mat-dialog-close>cancel</mat-icon>
  </div>
  <div class="flex flex-col items-center justify-center mt-10 pt-0.5 pb-5 md:w-96 mx-auto">
    <h3 class="montserrat-bold text-center mb-0.5">Transfer Funds</h3>
    <p class="montserrat-bold my-8 lg:my-10 pb-2 lg:pb-0 lg:pt-6">
      How much do you want to transfer?
    </p>
    <input [(ngModel)]="fundsAmount" (input)="setFundsAmount($event)" matInput numbersOnly decimals="3" digits="35"
      class="w-full text-center funds-form border-none outline-none mb-2.5 black" />
    <mat-divider class="w-full"></mat-divider>
    <div class="flex flex-col w-full mt-3 bg-white-dark p-4">
      <div class="flex justify-between items-center">
        <p >Available funds after transfer</p>
        <p class="s montserrat-bold">${{data?.balance}}</p>
      </div>
    </div>
    <p class="s mt-4 mb-10 text-center lg:text-left">
      Transfers made on weekends or holidays take a little longer. All transfers
      are subject to review and could be delayed or stopped if we identify a
      problem.<span class="blue montserrat-bold cursor-pointer"><a href="https://stripe.com/docs/payouts" target="_blank"
        class="montserrat-medium">
          transfer policy.
        </a></span>
    </p>
    <button mat-button class="button outlined black w-full">
      Transfer Funds
    </button>
  </div>
</div>