<div class="month-picker">
  <mat-form-field appearance="fill">
    <input
      matInput
      [matDatepicker]="dp"
      [formControl]="date"
      [min]="minDate"
      [max]="maxDate"
      class="input-form"
      placeholder="MM / YY">
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)"
      panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>
</div>