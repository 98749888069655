import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SharedService } from '../../services/shared.service';


@Component({
  selector: 'app-view-profile-button',
  templateUrl: './view-profile-button.component.html',
  styleUrls: ['./view-profile-button.component.scss'],
})
export class ViewProfileButtonComponent implements OnInit {
  private unsubscribe$=new Subject();
  @Input() url: string;
  @Input() label: string;
  username = '';
  constructor(private router: Router, private auth: AuthService, private sharedService: SharedService) {
    this.url = '';
    this.label = '';
  }

  ngOnInit(): void {
    if (this.auth.loggedIn()) {
      this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((information => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else {
          const { profileInfo } = information;
          this.username = profileInfo.user_name;
        }
      }));
    }
  }

  viewCurrentProfile() {
    let url = '';
    if (this.url === 'profile') {
      url = this.url + '/' + this.username
    } else {
      url = this.url;
    }
    this.router.navigate([url]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
}
}
