import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { statusConstant } from 'src/app/const/appConst';
import { ProfileService } from 'src/app/core/services/profile.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { SwiperOptions } from 'swiper';
@Component({
  selector: 'app-dashboard-payment',
  templateUrl: './dashboard-payment.component.html',
  styleUrls: ['./dashboard-payment.component.scss']
})
export class DashboardPaymentComponent implements OnInit {
  private unsubscribe$ = new Subject();
  selected = new FormControl(0);
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5
  };

  constructor(
    private router: Router,
    private commonService: CommonService,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private stripePaymentService: paymentService,
    private snack: SnackBarService,
    private spinner: NgxSpinnerService,
    private userPlan: userPlans
  ) {}

  ngOnInit(): void {}

  payments() {
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.getPyamentLink();
        }
      });
  }

  getPyamentLink() {
    // this.spinner.show();
    const redirect_url = this.commonService.location().href
    const refresh_url = this.commonService.location().href
    this.userPlan
      .getStripeUser(redirect_url,refresh_url)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status == 'on_board_required') {
            if (res?.accountLink?.url) {
              this.commonService.location().href = res?.accountLink?.url;
            }
            this.snack.showMessage(`${res.message} `, true);
          } else {
            this.stripePaymentService
              .getLoginLink()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                this.spinner.hide();
                if (res.status == 'Success') {
                  this.commonService.getWindow()?.open(res.link.url, '_blank');
                } else {
                  this.router.navigate(['/' + routesConst.planPricing]);
                  this.snack.showMessage(`Please Upgrade Your Membership`, true);
                }
              });
          }
        },
        error => {
          this.spinner.hide();
          this.snack.showMessage(`${error} `, true);
        }
      );
  }

  ngOnDestroy() {
    this.spinner.hide();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
