import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from 'src/config/config';
import { apiUrlConst } from 'src/app/const/apiConst';
import { CommonService } from './common.service';
export interface PostImage {
  url: string;
  cover_type: boolean;
  media_type: string;
}

export interface UploadPost {
  // title: string;
  description: string;
  category: (string | undefined)[];
  campaign_post: boolean;
  images: PostImage[];
}

@Injectable({
  providedIn: 'root',
})
export class PostService {
  constructor(public _http: HttpClient, private commonService: CommonService) {}

  uploadPost(data: UploadPost) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.uploadPost,
      data
    );
  }

  removeS3Object(data: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.removes3Object,
      data
    );
  }

  updateViews(data: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.updateViews,
      data
    );
  }

  editPost(data: UploadPost) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.editPost,
      data
    );
  }

  likePost(data: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.likePost,
      data
    );
  }

  unLikePost(_id: string) {
    return this._http.delete(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.unlikePost,
      {
        params: {
          _id: _id,
        },
      }
    );
  }

  replyComment(options: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.replyComment,
      options
    );
  }

  deletePost(_id: string) {
    return this._http.delete(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.deletePost,
      {
        params: {
          id: _id,
        },
      }
    );
  }

  deletePostContent(_id: string) {
    return this._http.delete(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.deletePostContent,
      {
        params: {
          _id: _id,
        },
      }
    );
  }

  addComment(options: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.addComment,
      {
        ...options,
      }
    );
  }

  updateComment(options: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.updateComment,
      {
        ...options,
      }
    );
  }

  removeComment(_id: any) {
    return this._http.delete(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.removeComment,
      {
        params: {
          _id: _id,
        },
      }
    );
  }

  getAllPost(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getAllPost,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getFeedContent(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getFeedContent,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getFeedContentList(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getFeedContentList,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getDiscoverUsers(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getDiscoverUsers,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getCommunitiesUsers(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getCommunitiesUsers,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getNetworkPosts(filters: any) {
    const params: any = filters;
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getNetworkPosts,
      {
        params: params,
      }
    );
  }

  getPopularPosts(filters: any) {
    const params: any = filters;
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getPopularPosts,
      {
        params: params,
      }
    );
  }

  getPostCategory(postId: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getPostCategory,
      {
        params: {
          postId,
        },
      }
    );
  }

  getCampaignPost() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getCampaignPost
    );
  }
  
  getAllFeedPost() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getFeedPosts
    );
  }

  getAllPostByCandidate(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getAllPostByCandidate,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getMentionedCandidate(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getMentionUser,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getRelatedPost(categories: string[]) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getRelatedPost,
      {
        categories: categories,
      }
    );
  }

  getTrendingPosts(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getTrendingPosts,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getCommunitiesData(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getCommunitiesData,
      {
        params: {
          ...params,
        },
      },
    );
  }

  searchPost(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.searchPost,
      {
        params: {
          ...params,
        },
      }
    );
  }

  getIntrestList() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.skills
    );
  }

  getPostDetails(
    post_id: string,
    offset: any,
    limit: any,
    comment_id?: string
  ) {
    const params: any = {
      post_id: post_id,
      offset: offset,
      limit: limit,
    };
    // Check if thread_id exists and has a value before adding it to the params object
    if (comment_id !== undefined && comment_id !== null) {
      params.comment_id = comment_id;
    }

    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getPostDetails,
      {
        params: params,
      }
    );
  }

  getPublicPosts(user_name: string) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getPublicPosts,
      {
        params: {
          user_name: user_name,
        },
      }
    );
  }
  
  getSpecificUsers(user_name: string) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.specificUsers,
      {
        params: {
          users: user_name,
        },
      }
    );
  }

  likeComment(options: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.likePost,
      options
    );
  }

  sharePost(options: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.sharePost,
      options
    );
  }

  getSpecificUserAllPosts(user_name:any,post_id:any,limit:any,offset:any){
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getSpecificUserPosts,
      {
        params: {
          user_name: user_name,
          post_id:post_id,
          offset:offset,
          limit:limit
        },
      }
    );
  }
}
