<div class="admin-setting section-wrapper bg-white px-4 pb-4 pt-0 lg:p-10">
  <p class="montserrat-bold text-black montserrat-bold">Access</p>
  <div *ngIf="users?.length" class="flex flex-col mt-4">
    <div *ngFor="let user of users" class="flex gap-2 items-center justify-between border-b p-2">
      <div class="flex items-center gap-2">
        <img
          class="w-10 h-10 rounded-full object-contain bg-black"
          [src]="user?.userDetails?.profile_img_path"
          onerror="this.src='assets/images/no-image.png'"
        />
        <p class="mx-2 truncate profile-name">{{ user?.userDetails?.name }}</p>
      </div>
      <button mat-button [matMenuTriggerFor]="menu" mat-button class="button black outlined">
        +
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngFor="let item of getAccessRoles(user.roles); trackBy: trackByFunc"
          mat-menu-item
          (click)="openModal(item.modal, user.profile_id)"
        >
          {{ item.label }}
        </button>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="!users?.length" class="flex flex-col mt-4">
    <p class="mx-2 truncate profile-name">No users found</p>
  </div>
</div>
