<div class="search-tabs px-3 md:px-3">
  <!-- <div *ngIf="isShowAdvancedSearch" class="job-filter-form-mobile w-full flex-col items-center overflow-hidden p-0"
    [formGroup]="form">
    <div class="search-form-wrapper flex items-center bg-white rounded">
      <input maxlength="256" matInput type="text" class="w-full input-form" placeholder="Search"
        formControlName="searchInput" (keyup)="searchInputdata($event)" />
      <mat-icon class="cursor-pointer search-icon gray-dark" (click)="searchInputdata($event)">search</mat-icon>
      <mat-icon class="expand-more" (click)="isShowAdvancedSearchOptions = !isShowAdvancedSearchOptions">
        {{isShowAdvancedSearchOptions ? 'expand_less' : 'expand_more'}}
      </mat-icon>
    </div>
    <div class="w-full flex flex-col justify-between" *ngIf="isShowAdvancedSearchOptions">
      <div class="grid gap-2 flex-1 px-2 py-2 bg-white shadow-lg  ">
        <div class="-my-6">
          <mat-form-field class="example-full-width">
            <mat-label>City</mat-label>
            <input (keyup)='onChange($event)' type="text" aria-label="Location" #locationSearchInput matInput
              [formControl]="myControl" [matAutocomplete]="auto" />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions" value="{{ option.city_name }} - {{ option.state_name }} - {{
                  option.country_code
                }}" (click)="setLocation(option)">

                {{ option.city_name }}{{ option.state_name ? ' - ' + option.state_name : '' }} {{
                option.country_code ? ' - ' + option.country_code : '' }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="">
          <mat-select formControlName="sortBy" placeholder="Date Posted ">
            <mat-option *ngFor="let sortBy of sortOption" (click)="onsortByChange()" [value]="sortBy.label">
              {{ sortBy.id }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select multiple formControlName="jobcategory" placeholder="Categories">
            <mat-option *ngFor="let jobCategory of allCategories" (click)="onCategoryFilterChange()"
              [value]="jobCategory">{{ jobCategory.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select multiple formControlName="jobType" placeholder="Job Type">
            <mat-option *ngFor="let type of allJobTypes" (click)="onTypeFilterChange()" [value]="type">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select multiple formControlName="jobExperience" placeholder="Experience Level">
            <mat-option *ngFor="let experience of allExperienceLevel" (click)="jobExperienceTypeFilterChange()"
              [value]="experience">
              {{ experience.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select formControlName="maxsalary" multiple placeholder="Weekly Pay Range">
            <mat-option *ngFor="let payrange of allPayRange" (click)="onSalaryChange()" [value]="payrange">{{
              payrange.label }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="flex justify-end clear-btn bg-white">
        <button *ngIf="authService.loggedIn() && types === register.UserTypes.candidate" mat-button
          class="mr-2 button search-preference outlined black" (click)="searchPreferences()">
          Search Preferences
        </button>
        <button *ngIf="isSearch" mat-button class="button outlined black clearAll-btn" (click)="clearAll()">
          Clear All
        </button>
      </div>
    </div>
  </div> -->
  <div class="job-filter-form-desktop rounded bg-white lg:mr-0" [formGroup]="form">
    <div class="flex flex-col md:flex-row gap-0 md:gap-2 items-center pb-2 md:pb-0 justify-between">
      <mat-form-field class="dynamic-desktop-filter">
        <mat-label>Title, Description, Skills</mat-label>
        <input
          maxlength="256"
          matInput
          type="text"
          formControlName="searchInput"
          (keyup)="searchInputdata($event)"
        />
        <mat-icon
          class="cursor-pointer search-icon gray-dark absolute"
          (click)="searchInputdata($event)"
          >search
        </mat-icon>
      </mat-form-field>
      <div class="flex items-center w-full" style="height: 60px">
        <mat-form-field class="example-full-width dynamic-desktop-filter">
          <mat-label>City</mat-label>
          <input
            (keyup)="onChange($event)"
            type="text"
            aria-label="Location"
            #locationSearchInput
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptions"
              value=" {{ option.city_name }}{{
                option.state_name ? ' - ' + option.state_name : ''
              }} {{ option.country_code ? ' - ' + option.country_code : '' }}"
              (click)="setLocation(option)"
            >
              {{ option.city_name }}{{ option.state_name ? ' - ' + option.state_name : '' }}
              {{ option.country_code ? ' - ' + option.country_code : '' }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div
        *ngIf="(authService.loggedIn() && types === register.UserTypes.candidate) || isSearch"
        class="flex justify-end w-full md:w-fit gap-2"
      >
        <button
          *ngIf="authService.loggedIn() && types === register.UserTypes.candidate"
          class="clear-filter-btn hidden md:flex"
          (click)="searchPreferences()"
        >
          Preferences
        </button>
        <button class="clear-filter-btn" (click)="clearAll()" *ngIf="isSearch">Reset</button>
      </div>
    </div>
    <div class="filter-list-wrapper p-relative">
      <div class="advanced-filters hidden md:flex flex-wrap gap-2 items-center p-relative">
        <div>
          <mat-select formControlName="sortBy" placeholder="Date Posted ">
            <mat-option
              *ngFor="let sortBy of sortOption"
              (click)="onsortByChange()"
              [value]="sortBy.label"
            >
              {{ sortBy.id }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select multiple formControlName="jobcategory" placeholder="Categories">
            <mat-option
              *ngFor="let jobCategory of allCategories"
              (click)="onCategoryFilterChange()"
              [value]="jobCategory"
              >{{ jobCategory.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select multiple formControlName="jobType" placeholder="Job Type">
            <mat-option
              *ngFor="let type of allJobTypes"
              (click)="onTypeFilterChange()"
              [value]="type"
            >
              {{ type.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select multiple formControlName="jobExperience" placeholder="Experience Level">
            <mat-option
              *ngFor="let experience of allExperienceLevel"
              (click)="jobExperienceTypeFilterChange()"
              [value]="experience"
            >
              {{ experience.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="">
          <mat-select formControlName="maxsalary" multiple placeholder="Weekly Pay Range">
            <mat-option
              *ngFor="let payrange of allPayRange"
              (click)="onSalaryChange()"
              [value]="payrange"
              >{{ payrange.label }}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="flex md:hidden">
        <div class="filter-icon-wrapper" (click)="filterModal()">
          <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.895 4H14.5a.5.5 0 01.5.5v.3a.5.5 0 01-.5.5H8.889a2 2 0 01-3.778 0H3.5a.5.5 0 01-.5-.5v-.3a.5.5 0 01.5-.5h1.605a2 2 0 013.79 0zM3 8.852a.5.5 0 01.5-.5h5.608a2 2 0 013.784 0H14.5a.5.5 0 01.5.5v.3a.5.5 0 01-.5.5h-1.608a2 2 0 01-3.784 0H3.5a.5.5 0 01-.5-.5v-.3zM3 13.204a.5.5 0 01.5-.5h1.615a2 2 0 013.77 0H14.5a.5.5 0 01.5.5v.3a.5.5 0 01-.5.5H8.9a2 2 0 01-3.8 0h-1.6a.5.5 0 01-.5-.5v-.3z"
              fill="#2D2D2D"
            ></path>
          </svg>
        </div>
        <swiper [navigation]="true" [config]="config" class="swpier-container jobs-swipper common-arrow posts-swipper">
          <ng-template swiperSlide>
            <div>
              <mat-select formControlName="sortBy" placeholder="Date Posted ">
                <mat-option
                  *ngFor="let sortBy of sortOption"
                  (click)="onsortByChange()"
                  [value]="sortBy.label"
                >
                  {{ sortBy.id }}
                </mat-option>
              </mat-select>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="">
              <mat-select multiple formControlName="jobcategory" placeholder="Categories">
                <mat-option
                  *ngFor="let jobCategory of allCategories"
                  (click)="onCategoryFilterChange()"
                  [value]="jobCategory"
                  >{{ jobCategory.label }}
                </mat-option>
              </mat-select>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="">
              <mat-select multiple formControlName="jobType" placeholder="Job Type">
                <mat-option
                  *ngFor="let type of allJobTypes"
                  (click)="onTypeFilterChange()"
                  [value]="type"
                >
                  {{ type.label }}
                </mat-option>
              </mat-select>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="">
              <mat-select multiple formControlName="jobExperience" placeholder="Experience Level">
                <mat-option
                  *ngFor="let experience of allExperienceLevel"
                  (click)="jobExperienceTypeFilterChange()"
                  [value]="experience"
                >
                  {{ experience.label }}
                </mat-option>
              </mat-select>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="">
              <mat-select formControlName="maxsalary" multiple placeholder="Weekly Pay Range">
                <mat-option
                  *ngFor="let payrange of allPayRange"
                  (click)="onSalaryChange()"
                  [value]="payrange"
                  >{{ payrange.label }}
                </mat-option>
              </mat-select>
            </div>
          </ng-template>
        </swiper>
      </div>
    </div>
    <div
      *ngIf="
        (form && form.value && form.value.jobExperience && form.value.jobExperience.length) ||
        (form && form.value && form.value.maxsalary && form.value.maxsalary.length) ||
        (form && form.value && form.value.jobcategory && form.value.jobcategory.length) ||
        (form && form.value && form.value.jobType && form.value.jobType.length) ||
        companyName ||
        selectedLocationFilter?.length ||
        keywordSearch
      "
      class="flex gap-2 flex-wrap items-start py-2"
    >
      <div *ngIf="selectedLocationFilter?.length" class="advanced-filter-item flex items-center">
        <p>
          {{ selectedLocationFilter }}
        </p>
        <mat-icon (click)="removefilter(1, 'location')">clear</mat-icon>
      </div>
      <div *ngIf="keywordSearch" class="advanced-filter-item flex items-center">
        <p class="overflow-hidden break-all">
          {{ keywordSearch }}
        </p>
        <mat-icon (click)="removefilter(1, 'search')">clear</mat-icon>
      </div>
      <div *ngIf="companyName" class="advanced-filter-item flex items-center">
        <p>
          {{ companyName }}
        </p>
        <mat-icon (click)="clearAll()">clear</mat-icon>
      </div>
      <ng-container *ngIf="form && form.value && form.value.jobType && form.value.jobType.length">
        <div
          class="advanced-filter-item flex items-center"
          *ngFor="let jobTypeFilter of form.value.jobType; let i = index"
        >
          <p>
            {{ jobTypeFilter.label }}
          </p>
          <mat-icon (click)="removefilter(i, 'jobType')">clear</mat-icon>
        </div>
      </ng-container>
      <ng-container
        *ngIf="form && form.value && form.value.jobcategory && form.value.jobcategory.length"
      >
        <div
          class="advanced-filter-item flex items-center"
          *ngFor="let jobCategoryFilter of form.value.jobcategory; let i = index"
        >
          <p>
            {{ jobCategoryFilter.label }}
          </p>
          <mat-icon (click)="removefilter(i, 'category')">clear</mat-icon>
        </div>
      </ng-container>
      <ng-container
        *ngIf="form && form.value && form.value.maxsalary && form.value.maxsalary.length"
      >
        <div
          class="advanced-filter-item flex items-center"
          *ngFor="let maxSalaryFilter of form.value.maxsalary; let i = index"
        >
          <p>
            {{ maxSalaryFilter.label }}
          </p>
          <mat-icon (click)="removefilter(i, 'maxsalary')">clear</mat-icon>
        </div>
      </ng-container>
      <ng-container
        *ngIf="form && form.value && form.value.jobExperience && form.value.jobExperience.length"
      >
        <div
          class="advanced-filter-item flex items-center"
          *ngFor="let experienceFilter of form.value.jobExperience; let i = index"
        >
          <p>
            {{ experienceFilter.label }}
          </p>
          <mat-icon (click)="removefilter(i, 'experience')">clear</mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
</div>
