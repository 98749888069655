
<!-- <div *ngIf="data !== 'about-us'" class="section-main-padding"></div> -->
<div class="background-section" *ngIf="data !== 'about-us'">
  <div class="sections-main">
    <div class="section-2-main">
      <div class="flex flex-col w-full mx-auto items-center">
        <div class="block md:hidden">
          <app-feature-menu></app-feature-menu>
        </div>
        <div>
          <p class="join-heading text-center">
            The answers to our most <br>
            frequently asked questions 
          </p>
          <p class="divider w-full"></p>
        </div>
        <div class="search-wrapper-home mb-4 mt-6">
          <div class="relative search-bar-wrapper w-full md:w-[500px]">
            <div class="search-input-wrapper w-full">
              <input placeholder="Search" class="search-input text-sm" />
              <mat-icon class="absolute search-icon">search</mat-icon>
            </div>
          </div>
        </div>
        <p class="section-title-divider fastgrowing-divider"></p>
      </div>

      <!-- for mobile screen & Individuals -->
      <div *ngIf="innerWidth < 768" class="images-main">
        <swiper [pagination]="{ clickable: true }" [config]="config">
          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network">
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/dSLeZ.png" alt="" />
              <p>Best -In-Class Networking</p>
              <small>Individual & Companies</small>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services">
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/BAxni.png" alt="" />
              <p>Explore Our Pricing</p>
              <small>Network, Share & Earn</small>
            </div>
          </ng-template>

          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments">
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/fcTRw.png" alt="" />
              <p>Referral Program</p>
              <small>Earn Passive Income</small>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals">
              <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/qSEJy.png" alt="" />
              <p>Stripe Integration</p>
              <small>Embedded Fintech</small>
            </div>
          </ng-template>
        </swiper>
      </div>
      <!---------->

      <!-- for laptop screen & Individuals -->
      <div *ngIf="innerWidth >= 768" class="grid grid-cols-4 gap-5">
        <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network">
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/dSLeZ.png" alt="" />
          <p>Best -In-Class Networking</p>
          <small>Individual & Companies</small>
        </div>
        <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services">
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/BAxni.png" alt="" />
          <p>Explore Our Pricing</p>
          <small>Network, Share & Earn</small>
        </div>
        <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments">
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/fcTRw.png" alt="" />
          <p>Referral Program</p>
          <small>Earn Passive Income</small>
        </div>
        <div class="footer-images" [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals">
          <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/qSEJy.png" alt="" />
          <p>Stripe Integration</p>
          <small>Embedded Fintech</small>
        </div>
      </div>
      <!-------->
    </div>
  </div>
</div>
<div class="freuently-asked-swipper mx-auto">
  <div class="mt-6">
    <p class="montserrat-bold black-dark text-center text-xl comparePlans-text">{{data === "about-us" ? 'We Are JOBPros' : 'Frequently Asked Questions'}}</p>
    <p class="font-style black-dark text-center my-4">{{data ==="about-us" ? 'Learn more about us' : 'Search for answers'}}</p>
  </div>
  <div class="mt-2">
    <swiper [navigation]="true" [config]="configButtons" class="swpier-container frequently-wrapper common-arrow">
      <ng-template swiperSlide>
        <div class="feature-main tag grow" [class]="selectedTab === 'About Us' || selectedTab === 'Community'?'feature-blue':''"
          (click)="selectedTab = data === 'about-us' ? 'Community':'About Us' ; scroll(data === 'about-us' ? '#Community' :'#about-us')">
          <p class="text-lg title text-center">
            {{data === "about-us" ? 'Community' : 'About Us'}}
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="feature-main tag grow" [class]="selectedTab === 'Pricing' || selectedTab === 'Network'?'feature-blue':''"
          (click)="selectedTab = data === 'about-us'? 'Network':'Pricing' ; scroll(data === 'about-us' ? '#Network':'#Pricing')">
          <p class="text-lg title text-center">
            {{data === "about-us" ? 'Network' : 'Pricing'}}
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="feature-main tag grow" [class]="selectedTab === 'Referrals' || selectedTab === 'Influence'?'feature-blue':''"
          (click)="selectedTab = data === 'about-us' ? 'Influence' : 'Referrals' ; scroll(data === 'about-us' ? '#Influence ':'#Referrals')">
          <p class="text-lg title text-center">
            {{data === "about-us" ? 'Influence' : 'Referrals'}}
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="feature-main tag grow" [class]="selectedTab === 'Payments' || selectedTab === 'Value'?'feature-blue':''"
          (click)="selectedTab = data === 'about-us' ? 'Value' : 'Payments' ; scroll(data === 'about-us' ? '#Value' :'#Payments')">
          <p class="text-lg title text-center">
            {{data === "about-us" ? 'Value' : 'Payments'}}
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="feature-main tag grow" [class]="selectedTab === 'Stripe' || selectedTab === 'Referral'?'feature-blue':''"
          (click)="selectedTab = data === 'about-us' ? 'Referral' : 'Stripe' ; scroll(data === 'about-us' ? '#Referral' :'#Stripe')">
          <p class="text-lg title text-center">
            {{data === "about-us" ? 'Referral' : 'Stripe'}}
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="feature-main tag grow" [class]="selectedTab === 'Refund' || selectedTab === 'Discover'?'feature-blue':''"
          (click)="selectedTab = data === 'about-us' ? 'Discover' :'Refunds' ; scroll(data === 'about-us' ? '#Discover' :'#Refunds')">
          <p class="text-lg title text-center">
            {{data === "about-us" ? 'Discover' : 'Refunds'}}
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="feature-main tag grow" [class]="selectedTab === 'Tutorials' || selectedTab === 'Mission'?'feature-blue':''"
          (click)="selectedTab = data === 'about-us' ? 'Mission' :'Tutorials' ; scroll(data === 'about-us' ? '#Mission  ' :'#Tutorials')">
          <p class="text-lg title text-center">
            {{data === "about-us" ? 'Mission' : 'Tutorials'}}
          </p>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
<main class="faq-page AboutUs">
  <section class="AboutUs_gradien-card-wrapper">
    <div class="about-subtitle-container">
      <mat-accordion class="Faq-accordion" [multi]="false">
        <div class="flex flex-col faq-wrapper">
          <mat-expansion-panel [disabled]="data === 'about-us' ? true : false" [id]="data === 'about-us'? 'Community':'about-us'" [hideToggle]="true" [expanded]="selectedTab === 'About Us'">
            <mat-expansion-panel-header >
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" [src]="selectedTab === 'Community'? '/assets/images/recuiter-blue-icons.png':'https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png'" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading montserrat-semibold">{{data === 'about-us' ? 'Our platform offers a place for communities and individuals to create, share & earn.' : 'What Is JOBPros?' }}</h3>
                  <mat-icon *ngIf="data !== 'about-us'" class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer" *ngIf="data !== 'about-us'" >
                <p>The fastest growing community to network, share & earn income.</p>
                <a [routerLink]="['/about-us']" >https://www.jobpros.io/about-us</a>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [disabled]="data === 'about-us' ? true : false" [id]="data === 'about-us'? 'Network':'Pricing'" [hideToggle]="true" [expanded]="selectedTab === 'Pricing'">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" [src]="selectedTab === 'Network'? '/assets/images/recuiter-blue-icons.png':'https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png'" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">{{data === 'about-us' ? 'We reimagined the way you network. We made it easier.' : 'JOBPros vs other sites?' }}</h3>
                  <mat-icon *ngIf="data !== 'about-us'" class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer" *ngIf="data !== 'about-us'">
                <p>We allow our members to monetize on their own terms.</p>
                <a [routerLink]="['/pricing']" >https://www.jobpros.io/pricing</a>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [disabled]="data === 'about-us' ? true : false" [id]="data === 'about-us'? 'Influence':'Referrals'" [hideToggle]="true" [expanded]="selectedTab === 'Referrals'">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" [src]="selectedTab === 'Influence'? '/assets/images/recuiter-blue-icons.png':'https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png'" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">{{data === 'about-us' ? 'We believe that you gain access to influential people through strong relationships.' : 'Do you offer a referral program?' }}</h3>
                  <mat-icon *ngIf="data !== 'about-us'" class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer" *ngIf="data !== 'about-us'">
                <p>Yes! Invite others to your network and earn income monthly.</p>
                <a [routerLink]="['/referrals']">https://jobpros.io/dashboard/referrals</a>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [disabled]="data === 'about-us' ? true : false" [id]="data === 'about-us'? 'Value':'Payments'" [hideToggle]="true" [expanded]="selectedTab === 'Payments'">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" [src]="selectedTab === 'Value'? '/assets/images/recuiter-blue-icons.png':'https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png'" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">{{data === 'about-us' ? "We value the ideas shared through content, exclusive services and subscriptions." : 'How fast do members get paid?' }}</h3>
                  <mat-icon *ngIf="data !== 'about-us'" class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer" *ngIf="data !== 'about-us'">
                <p>Members get paid instantly via their Stripe account.</p>
                <a [routerLink]="['/dashboard/payments']">https://jobpros.io/dashboard/payments</a>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [disabled]="data === 'about-us' ? true : false" [id]="data === 'about-us'? 'Referral':'Stripe'" [hideToggle]="true" [expanded]="selectedTab === 'Stripe'">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" [src]="selectedTab === 'Referral'? '/assets/images/recuiter-blue-icons.png':'https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png'" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">{{data === 'about-us' ? 'We reward our members with a 10% cash bonus per paid membership referral.' : 'How are seller fees collected via Stripe?' }}</h3>
                  <mat-icon *ngIf="data !== 'about-us'" class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer" *ngIf="data !== 'about-us'">
                <p>Stripe transaction fees are paid via the seller account.</p>
                <a target="_blank" href="https://stripe.com/pricing">https://stripe.com/pricing</a>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [id]="data === 'about-us'? 'Discover':''" [disabled]="data === 'about-us' ? true : false" [hideToggle]="true">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" [src]="selectedTab === 'Discover'? '/assets/images/recuiter-blue-icons.png':'https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png'" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">{{data === 'about-us' ? 'We invite you to become a member who shares ideas and explores opportunities.' : 'How much are the Stripe transaction fees?' }}</h3>
                  <mat-icon *ngIf="data !== 'about-us'" class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer" *ngIf="data !== 'about-us'">
                <p>2.9% + 30 cents per successful domestic charge.</p>
                <a target="_blank" href="https://stripe.com/pricing">https://stripe.com/pricing</a>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [id]="data === 'about-us'? 'Mission':''" [disabled]="data === 'about-us' ? true : false" [hideToggle]="true">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" [src]="selectedTab === 'Mission'? '/assets/images/recuiter-blue-icons.png':'https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png'" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">{{data === 'about-us' ? 'Join the fastest growing community JOBPros the best place to earn on your own terms.' : 'How are members verified on the platform?' }}</h3>
                  <mat-icon *ngIf="data !== 'about-us'" class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer" *ngIf="data !== 'about-us'">
                <p>All members must complete identity verification to earn income.</p>
                <a target="_blank"
                href="https://support.stripe.com/topics/verification">https://support.stripe.com/topics/verification</a>
              </div>
            </div>
          </mat-expansion-panel>
          <ng-container *ngIf="data !== 'about-us'">
          <mat-expansion-panel [disabled]="data === 'about-us' ? true : false" [hideToggle]="true">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">What documentation is used to verify members?</h3>
                  <mat-icon *ngIf="data !== 'about-us'" class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer">
                <p>Social Security Number /Driver’s License / Passport / Tax ID / EIN</p>
                <a target="_blank"
                  href="https://support.stripe.com/topics/verification">https://support.stripe.com/topics/verification</a>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [disabled]="data === 'about-us' ? true : false" id="Refunds" [hideToggle]="true" [expanded]="selectedTab === 'Refunds'">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">Are sellers able to refund clients for services?</h3>
                  <mat-icon class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer">
                <p>Yes! All refund requests are processed by seller.</p>
                <a [routerLink]="['/dashboard/services']">https://jobpros.io/dashboard/services</a>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [disabled]="data === 'about-us' ? true : false" id="Tutorials" [hideToggle]="true" [expanded]="selectedTab === 'Tutorials'">
            <mat-expansion-panel-header>
              <div class="flex items-center gap-2 w-full">
                <div *ngIf="data === 'about-us'; else faqIcon">
                  <img routerLink="/"
                  class="w-9" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png" alt="">
                </div>
                <ng-template #faqIcon>
                  <i class="fa fa-solid fa-question question-mark"></i>
                </ng-template>
                <div class="flex items-center justify-between w-full">
                  <h3 class="heading">Do you offer membership support tutorials?</h3>
                  <mat-icon class="font-bold blue expand-icon">expand_more</mat-icon>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div class="faq-section">
              <div class="answer">
                <p>Yes! We give access to membership tutorials.</p>
                <a [routerLink]="['/tutorials']">https://jobpros.io/tutorials</a>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
        </div>
      </mat-accordion>
    </div>
  </section>
</main>
<div class="about-footer">
  <app-part-footer [faqData] = "'faq_footer'"></app-part-footer>
</div>