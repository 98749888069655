import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { routesConst } from '../../const/routers';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-feature-menu',
  templateUrl: './feature-menu.component.html',
  styleUrls: ['./feature-menu.component.scss']
})
export class FeatureMenuComponent implements OnInit {
  @ViewChild(SwiperComponent, { static: false }) swiper?: SwiperComponent;
  @Input() scrollUnit: number = 150;

  innerWidth = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  config: SwiperOptions = {
    slidesPerView: 'auto'
  };
  showActiveItems:any = []
  data = [
    this.authService.loggedIn() ? { name: 'Feed',  url: '', isLogin: true, params: {} } : {}, 
    { name: 'Jobs', url: this.authService.loggedIn() ? routesConst.jobs : '#homeJobs', isLogin:true, params: {} },
    { name: 'Services', url: this.authService.loggedIn() ? routesConst.services:'#homeServices', isLogin:true, params: {} },
    { name: 'Creators', url: this.authService.loggedIn() ? routesConst.community: '#homeCreators', isLogin:true, params: {} },
    {
      name: 'Subscriptions',
      url: this.authService.loggedIn() ? routesConst.community : '#homeSubscriptions', isLogin:true,
      params: { tab: 'subscriptions' }
    },
    { name: 'Pricing', url: routesConst.pricing, isLogin:this.authService.loggedIn() ?false:true ,params: {} },
    { name: 'Use CASE', url: '', isLogin:false ,params: {} },
    { name: 'Discover ', url: routesConst.discover, isLogin: true, params: {} },
    { name: 'Community', url: routesConst.communities, isLogin:true, params: {} },
    { name: 'Referrals', url: routesConst.dashboard + '/' + routesConst.referrals, isLogin:this.authService.loggedIn() ?true:false, params: {} },
    { name: 'Members', url: routesConst.community, isLogin:this.authService.loggedIn() ?true:false, params: { tab: 'people' } },
    { name: 'Tutorials', url: routesConst.tutorials, isLogin:this.authService.loggedIn() ?true:false, params: {} },
    { name: 'About Us', url: routesConst.aboutus, isLogin:this.authService.loggedIn() ?true:false, params: {} },
    { name: 'FAQ', url: routesConst.faq, isLogin:true, params: {} },
    { name: 'Platform', url: '#Platform', isLogin:false, params: {} },
    { name: 'Company', url: '#Company', isLogin:false, params: {} },
    { name: 'Integrations', url: '#Integrations', isLogin:false, params: {} },

  ];
  currentRoute: any = '';

  constructor(
    private router: Router,
    public authService: AuthService,
    private sharedService: SharedService,
    private route: ActivatedRoute
  ) {
    this.showActiveItems = this.data.filter(item => item.isLogin ===true)
    this.route.url.subscribe(urlSegments => {
      const previousRoute = localStorage.getItem('Curent Route');

      const segments = urlSegments.map(segment => segment.path);      
      setTimeout(() => {
        this.slideToSpecificElement();
      }, 100);
      this.currentRoute = segments?.length
        ? segments[0]
        : previousRoute == 'Feed'
          ? previousRoute
          : '';

      // if (this.currentRoute === '') {
      //   this.currentRoute = 'Benefit';
      // }
      this.sharedService.setIndex(
        this.showActiveItems.findIndex(
          (item:any) =>
            item.url.toLowerCase() == this.currentRoute.toLowerCase() ||
            item.name.toLowerCase() == this.currentRoute.toLowerCase()
        )
      );
      if (this.currentRoute != 'Feed') {
        this.sharedService.userFeedData.next(false);
      }
      localStorage.setItem('Curent Route', this.currentRoute);
    });
  }

  ngOnInit(): void { }

  slideToSpecificElement() {
    let activeIndex: any = this.sharedService.getIndex();
    this.swiper?.swiperRef?.slideTo(this.currentRoute == '' ? 0 : activeIndex);
  }

  navigate(url: string, params: any, name: string) {
    this.sharedService.setIndex(this.swiper?.swiperRef?.activeIndex);
    if (!url.includes('#')) {
      this.router.navigate([url], {
        queryParams: params
      });
    }
    if (name == 'Benefit') {
      this.currentRoute = name;
    }

    if (name == 'Feed') {
      this.currentRoute = name;
      this.sharedService.userFeedData.next(true);
    } else {
      this.sharedService.userFeedData.next(false);
    }

    if (name && !(name == 'Platform' || name == 'Company' || name == 'Integrations') && url.includes('#')) {
      this.currentRoute = name;
      this.sharedService.featureMenuToggle.next(url);
      document.querySelector(url)?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    if (name && (name == 'Platform' || name == 'Company' || name == 'Integrations')) {
      this.currentRoute = name;
      this.sharedService.featureMenuToggle.next(url);
      this.scroll(url);
    }
    localStorage.setItem('Curent Route', this.currentRoute);
  }

  scroll(element: string) {
    if (element === null || element.trim() === '') return;
    const targetElement = document.getElementById(element);
    if (!targetElement) {
      console.error(`Element with selector '${element}' not found.`);
      return;
    }
    const offset = targetElement.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: offset,
      behavior: "smooth"
    });
  }


}
