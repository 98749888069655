import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Optional } from '@angular/core';
import * as _ from 'lodash';
import { JobService } from 'src/app/core/services/job.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/core/services/common.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Job } from 'src/app/core/models/jobs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routesConst } from 'src/app/core/const/routers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
  styleUrls: ['./job-post.component.scss']
})
export class JobPostComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  @ViewChild('locationSearchInput', { static: true })
  locationSearchInput?: ElementRef;
  filteredOptions: any[] = [];
  jobForm: FormGroup;
  job: Job = {
    _id: '',
    document_path: '',
    experience_required: '',
    job_title: '',
    job_type: '',
    job_category: '',
    job_description: '',
    location_id: '',
    salary_range: '',
    is_active: true,
    visibility: []
  };
  categories: any = [];
  jobTypes: any = [];
  loader: boolean = false;
  isJobEdit: boolean = false;
  selectedFeatures: string[];
  locationLists: any = [];
  payRange: any = [];
  experienceLists: any = [];
  myControl = new FormControl();
  update = false;
  editJob: any = '';
  visibility: string[] = [];

  constructor(
    private _jobsService: JobService,
    public dialogRef: MatDialogRef<JobPostComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
    private _commonService: CommonService,
    public fb: FormBuilder,
    private sharedService: SharedService,
    private snack: SnackBarService,
    private router: Router
  ) {
    this.dialogRef.disableClose = false;
    this.jobForm = this.fb.group({
      job_title: ['', [Validators.required, Validators.maxLength(256)]],
      job_category: [''],
      job_description: ['', [Validators.required, Validators.maxLength(1052)]],
      newFeature: ['', [Validators.maxLength(128)]],
      location_id: ['', Validators.required],
      salary_range: ['', Validators.required],
      job_type: ['', Validators.required],
      experience_required: ['', Validators.required]
    });
    this.selectedFeatures = [];
    this.dialogRef.disableClose = false;
  }

  /**
   * Error Handling
   * @param control
   * @param error
   * @returns
   */
  public errorHandling = (control: string, error: string) => {
    return this.jobForm.controls[control].hasError(error);
  };

  /**
   * Remove Feature
   * @param feature
   */
  removeFeature(feature: string) {
    this.selectedFeatures = this.selectedFeatures.filter(f => f !== feature);
    this.checkIfChange();
  }

  addSkills(event: any) {
    if (event.keyCode == 13) {
      this.addNewFeature();
    }
  }

  onCheckboxChange(value: string, event: any): void {
    if (event.checked) {
      this.visibility.push(value);
    } else {
      this.visibility = this.visibility.filter(
        (item) => item !== value
      );
    }
  }

  /**
   * Add New Feature
   */
  addNewFeature() {
    if (
      this.jobForm.get('newFeature')?.value.replace(/\s/g, '').toLowerCase().length &&
      this.jobForm.get('newFeature')?.value.replace(/\s/g, '').toLowerCase()?.length < 129
    ) {
      if (
        this.selectedFeatures.includes(
          this.jobForm.get('newFeature')?.value.replace(/\s/g, '').toLowerCase()
        )
      ) {
        this.snack.showMessage('Skills already exist', true);
      } else {
        this.selectedFeatures.push(this.jobForm.get('newFeature')?.value);
        this.jobForm.get('newFeature')?.setValue('');
      }
    }
  }

  ngOnInit() {
    this._commonService
      ._filterLocation(this.locationSearchInput?.nativeElement)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.filteredOptions = result.data;
      });
    this.getRequiredFieldData();
    if (this.data?.jobId) {
      this.isJobEdit = true;
      this.getJobDetail(this.data?.jobId);
    }

    this.jobForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.checkIfChange();
    });
  }

  /**
   * Get Required Data of job
   */
  getRequiredFieldData() {
    this.getPayRangeList();
    this.experienceLevelList();
    this.getJobTypeList();
    this.getCategoriesList();
  }

  getPayRangeList() {
    this.sharedService.payRangeList$.pipe(takeUntil(this.unsubscribe$)).subscribe(payrangelist => {
      if (payrangelist.length) {
        this.payRange = payrangelist;
      } else {
        this.sharedService.getPayRangeList();
      }
    });
  }

  experienceLevelList() {
    this.sharedService.experienceLevelList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(experiencelevel => {
        if (experiencelevel.length) {
          this.experienceLists = experiencelevel;
        } else {
          this.sharedService.getexperienceLevel();
        }
      });
  }

  getJobTypeList() {
    this.sharedService.jobTypeList$.pipe(takeUntil(this.unsubscribe$)).subscribe((jobtype: any) => {
      if (jobtype.length) {
        this.jobTypes = jobtype;
      } else {
        this.sharedService.jobTypeList();
      }
    });
  }

  getCategoriesList() {
    this.sharedService.jobCategoryList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((jobcategory: any) => {
        if (jobcategory.length) {
          this.categories = jobcategory;
        } else {
          this.sharedService.categoriesList();
        }
      });
  }

  /**
   * Get Job Details
   * @param jobId
   */
  getJobDetail(jobId: string) {
    this.loader = true;
    this._jobsService
      .getJobById(jobId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (job: any) => {
          this.selectedFeatures = job.data.preferred_requirements;
          this.editJob = JSON.parse(JSON.stringify(job));
          this.jobForm.get('job_title')?.setValue(job.data.job_title);
          this.jobForm.get('job_category')?.setValue(job.data.job_category_id);
          this.jobForm.get('job_description')?.setValue(job.data.job_description);
          this.myControl.setValue(
            job.data.location.city_name +
              '-' +
              job.data.location.state_name +
              '-' +
              job.data.location.country_code
          );
          this.jobForm.controls['location_id'].setValue(job.data.location._id);
          this.jobForm.get('salary_range')?.setValue(job.data.salary_range_id);
          this.jobForm.get('job_type')?.setValue(job.data.job_type_id);
          this.jobForm.get('experience_required')?.setValue(job.data.experience_required_id);
          this.jobForm.get('newFeature')?.setValue('');
          this.job._id = job.data._id;
        },
        () => {
          this.loader = false;
        }
      );
    this.checkIfChange();
  }

  checkIfChange() {
    if (
      this.editJob?.data?.job_title !== this.jobForm.get('job_title')?.value ||
      this.editJob.data.job_category_id !== this.jobForm.get('job_category')?.value ||
      this.editJob.data.job_description !== this.jobForm.get('job_description')?.value ||
      this.editJob.data.salary_range_id !== this.jobForm.get('salary_range')?.value ||
      this.editJob.data.job_type_id !== this.jobForm.get('job_type')?.value ||
      this.editJob.data.experience_required_id !== this.jobForm.get('experience_required')?.value ||
      this.editJob?.data?.location_id !== this.jobForm.get('location_id')?.value ||
      this.editJob?.data?.preferred_requirements?.toString() !== this.selectedFeatures.toString()
    ) {
      this.update = true;
    } else {
      this.update = false;
    }
  }

  /**
   * On Job Submission
   */
  submitJob() {
    if (this.jobForm.valid) {
      const job: Job = {
        experience_required: this.jobForm.get('experience_required')?.value,
        job_description: this.jobForm.get('job_description')?.value,
        job_title: this.jobForm.get('job_title')?.value,
        job_category: this.jobForm.get('job_category')?.value,
        job_type: this.jobForm.get('job_type')?.value,
        location_id: this.jobForm.get('location_id')?.value,
        preferred_requirements: this.selectedFeatures,
        salary_range: this.jobForm.get('salary_range')?.value,
        is_active: true,
        visibility: this.visibility,
        profile_id: this.data?.isModeratorMode ? this.data.profile_id : null
      };
      if (this.isJobEdit) {
        job._id = this.job._id;
        this._jobsService
          .updateJobData(job)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            () => {
              this.snack.showMessage(`Successfully updated the job`, false);
              this.dialogRef.close(true);
            },
            () => {
              this.snack.showMessage(`Failed to update the job.`, true);
            }
          );
      } else {
        this._jobsService
          .postNewJob(job)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (res: any) => {
              if (res.status == 'success') {
                this.router.navigate(['/' + routesConst.jobs]);
              }
              this.snack.showMessage(`${res.message}`, false);
              this.dialogRef.close(true);
            },
            () => {
              this.snack.showMessage(`Failed to add a new job`, true);
            }
          );
      }
    } else {
      return;
    }
  }

  /**
   * Discard
   */
  dismiss() {
    this.dialogRef.close();
  }

  /**
   * Set Location
   * @param option
   */
  setLocation(option: any) {
    this.jobForm.controls['location_id'].setValue(option._id);
  }

  /**
   * On Destory Dismiss
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.dismiss();
  }
}
