<div class="profile-services section-wrapper bg-white py-0 px-4">
  <mat-horizontal-stepper labelPosition="bottom" #stepper>
    <mat-step>
      <div>
        <!-- <h3 *ngIf="!myServices?.length" class="site-color text-center montserrat-bold">No Active Services</h3> -->
        <!-- <p class="black-light mt-5 mb-8 lg:p-0 lg:mb-10 montserrat-bold uppercase service-header" >Services</p> -->
        <p
          *ngIf="!myServices?.length"
          class="black-light montserrat-bold mt-3 md:my-3 uppercase text-center"
        >
          Not Available
        </p>
        <div class="spinner-wrapper">
          <!-- <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
            [fullScreen]="true" class="job-list-spinner">
          </ngx-spinner> -->
        </div>
        <div
          *ngIf="myServices?.length"
          class="grid sm:grid-cols-2 2xl:grid-cols-3 gap-2 my-2"
        >
          <app-individual-service-card
            *ngFor="let service of myServices"
            [service]="service"
            (click)="showDetails(service, stepper)"
          ></app-individual-service-card>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <!-- <div class="ml-0 flex mb-6">
        <button class="button outlined black h-fit-btn" mat-flat-button matStepperPrevious matStepperPrev>
          <mat-icon class="blue arrow-back">west</mat-icon>
        </button> -->
      <!-- <div class="flex justify-center items-center w-full">
          <div class="flex justify-center items-center cursor-pointer">
            <img class="w-16 h-16 rounded-full mr-4 object-cover"
              [src]="selectedService?.profile?.profile_img_path ? selectedService?.profile?.profile_img_path :'https://i.postimg.cc/Hk7vyZZM/no-image.pngs' " />
            <p class="montserrat-bold mr-2">{{ selectedService?.profile?.user_name }}</p>
          </div>
        </div> -->
      <!-- </div> -->
      <div class="individual-service-page mt-5" *ngIf="selectedService">
        <button
          class="button outlined black h-fit-btn back-btn"
          mat-flat-button
          matStepperPrevious
          matStepperPrev
        >
          <mat-icon class="blue arrow-back">west</mat-icon>
        </button>

        <div class="">
          <h4
            class="individual-service-page_title text-center mb-6 truncate w-full service-name"
          >
            {{ selectedService?.title }}
          </h4>
        </div>
        <div class="mt-4 px-0 md:px-28" *ngIf="slides.length">
          <div id="service-img" class="relative">
            <span *ngIf="slides?.length && slides?.length > 1" class="slide-number-services">{{
              activeSlide + "/" + slides?.length
            }}</span>
            <swiper
              [pagination]="slides.length > 1"
              (slideChange)="onSlideChange($event)" class="services-slider"
            >
              <ng-template swiperSlide *ngFor="let portfolio of slides">
                <img
                  *ngIf="portfolio?.media_type == 'image'"
                  [src]="portfolio.path"
                  class="w-full object-contain cursor-pointer"
                  style="max-height: calc(100vh - 100px)"
                  (click)="prevService()"
                />
                <video
                  *ngIf="portfolio?.media_type == 'video'"
                  class="w-full cursor-pointer post-content post-img"
                  [src]="portfolio?.path+ '#t=0.1'"  preload="metadata"
                  loop
                  [muted]="'muted'"
                  playsinline
                  webkit-playsinline
                ></video>
              </ng-template>
            </swiper>
          </div>
        </div>
        <div class="mb-20">
          <!-- <h3 class="montserrat-bold text-center mb-6 mt-20">Pricing</h3> -->
          <div
            [ngClass]="
              isProfileSubscription
                ? 'flex justify-center'
                : 'grid sm:grid-cols-2 lg:grid-cols-2 gap-set'
            "
            class="mt-5"
          >
            <div class="" *ngFor="let item of pricingPackages">
              <app-pricing-package-card
                [isProfileSubscription]="isProfileSubscription"
                [service]="item.service_id"
                [serviceOwner]="selectedService?.profile?.user_name"
                [pricingPackage]="item"
              ></app-pricing-package-card>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
