import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PostService } from 'src/app/core/services/post.service';

@Component({
  selector: 'app-campaign-tab',
  templateUrl: './campaign-tab.component.html',
  styleUrls: ['./campaign-tab.component.scss']
})
export class CampaignTabComponent implements OnInit {
  private unsubscribe$=new Subject();
  campaigns: any;

  constructor(private postService:PostService) { }

  ngOnInit(): void {
    this.getCampaignPosts();
  }

  getCampaignPosts(){
    this.postService.getCampaignPost().pipe(takeUntil(this.unsubscribe$)).subscribe((res:any)=>{
      this.campaigns = res.data;
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
}

}
