<div id="container">
    <div id="error-box">
        <div class="face2">
            <div class="eye"></div>
            <div class="eye right"></div>
            <div class="mouth sad"></div>
        </div>
        <div class="shadow move"></div>
        <div class="message">
            <h1 class="alert">Error!</h1>
            <p>oh no, something went wrong.
        </div>
        <button class="button-box" [routerLink]="['/'+routesConst.talents]">
            <h1 class="red">try again</h1>
        </button>
    </div>
</div>