<div class="education-modal">
  <div class="flex w-full justify-between px-4 py-3">
    <h4 class="montserrat-bold flex items-center">Add Education</h4>
    <button mat-icon-button class="ml-auto" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="p-4">
    <form class="flex flex-col w-full px-4 mb-6" [formGroup]="educForm" (ngSubmit)="submitForm()" novalidate>
      <mat-form-field>
        <input matInput class="border-normal rounded" placeholder="School name" formControlName="name">
        <mat-error *ngIf="errorHandling('name', 'required')" [class]="errorHandling('name', 'required') ? 'pb-4' : ''">
          <p >You must provide a name.</p>
        </mat-error>
      </mat-form-field>
      <div class="flex w-full mt-4">
        <mat-form-field class="example-full-width">
          <mat-label class="bg-white">Date</mat-label>
          <input type="text" placeholder="Pick one" aria-label="Number" matInput class="border-normal rounded"
            [formControl]="myControl" [matAutocomplete]="auto">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="w-full mt-4">
        <textarea id="bioInput" matInput class="border-normal rounded description-form" cdkTextareaAutosize
          placeholder="Description" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="7"
          formControlName="description"></textarea>
      </div>
      <mat-divider></mat-divider>
      <div class="flex w-full justify-end mt-6">
        <button mat-button class="button w-24 outlined" mat-dialog-close>Cancel</button>
        <div class="ml-2">
          <button mat-button type="submit" class="button w-24 ml-2 cursor-pointer">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>
