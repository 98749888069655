import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-client-card',
  templateUrl: './message-client-card.component.html',
  styleUrls: ['./message-client-card.component.scss']
})
export class MessageClientCardComponent implements OnInit {
  @Input() data?: any;
  @Input() sender?: any;

  constructor() { }

  ngOnInit(): void {
  }

  getUnreadCount(unreadMessages: any) {
    if (unreadMessages?.length) {
      return unreadMessages.filter((x: any) => {
        return x.to_id === this.sender
      }).length
    }
  }


  getProfileImage(userList: any) {
    if (userList.profile_img_path) {
      return userList.profile_img_path
    }
    else {
      return 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs'
    }
  }

}
