<div *ngIf="!isReferralPlan" class="home-plan-part" [class]="isComparePlans?'scroll-height':''">
  <div id="comparePlans" class="text-center">
    <p class="montserrat-bold black-dark text-xl comparePlans-text mt-6">Compare all memberships</p>
    <p class="font-style black-dark my-4">Save 15% billed annual</p>
  </div>
  <div class="flex mt-3 mb-1 justify-center">
    <div class=" p-1 flex items-center rounded-full">
      <button class="payment-section_tab border-plus-text bg-gray payment-section_tab px-4 py-1 rounded-full mr-4"
        [class]="forIndividual ? 'bg-blu shadow-lg' : ''" (click)="forIndividual = true; getPlanList()">
        <p class="montserrat-medium" [class]="!forIndividual ? 'gray-dark' : 'black-dark'">Individuals</p>
      </button>
      <button
        class="payment-section_tab payment-section_tab bg-gray border-plus-text payment-section_tab px-4 py-1 rounded-full"
        [class]="!forIndividual ? 'bg-blu shadow-lg' : ''" (click)="forIndividual = false; getPlanList()">
        <p class="montserrat-medium" [class]="forIndividual ? 'gray-dark' : 'black-dark'">Companies</p>
      </button>
    </div>
  </div>

  <!-- Home Plans -->
  <div class="m-auto block unit-wrapper w-m-m md:px-4">
    <div class="card-border individual-plans max-w-[340px] md:max-w-[700px] lg:max-w-[1060px] mx-auto">
      <app-payment-option-card [getFromLocalStorage]="true" [planList]="planList" [cardheights]="cardheights" (showSpinner)="isShowSpinner($event)"
        [user_type]="userType" class="payment-option-card">
      </app-payment-option-card>
    </div>
  </div>
  <!---->
</div>
<div class="home-addons-part pt-8" [class]="isShowAddOns?'scroll-shown':''">
  <div id="planAddOns" class="text-center">
    <p class="montserrat-bold text-xl black-dark mt-4 px-6 md:px-0">Membership add ons</p>
    <p class="font-style black-dark my-4 px-5">Member Referral Programs</p>
  </div>
  <div class="flex mt-3 mb-1 justify-center">
    <div class="p-1 flex items-center rounded-full">
      <button class="payment-section_tab payment-section_tab border-plus-text px-4 py-1 rounded-full mr-4"
        [class]="forIndividualA ? 'bg-blu shadow-lg' : ''" (click)="forIndividualA = true; getAddOnes()">
        <p class="montserrat-medium" [class]="!forIndividualA ? 'gray-dark' : 'black-dark'">Individuals</p>
      </button>
      <button
        class="payment-section_tab payment-section_tab border-plus-text payment-section_tab px-4 py-1 rounded-full"
        [class]="!forIndividualA ? 'bg-blu shadow-lg' : ''" (click)="forIndividualA = false; getAddOnes()">
        <p class="montserrat-medium" [class]="forIndividualA ? 'gray-dark' : 'black-dark'">Companies</p>
      </button>
    </div>
  </div>

  <!-- Home Addons -->
  <div *ngIf="!isReferralPlan && onsList.length" class="m-auto block add-ons unit-wrapper w-m-m md:px-4"
    >
    <div class="card-border individual-plans max-w-[340px] md:max-w-[700px] lg:max-w-[1060px] mx-auto" >
      <app-payment-option-card [planList]="onsList" (showSpinner)="isShowSpinner($event)" [user_type]="userType" class="payment-option-card">
      </app-payment-option-card>
    </div>
  </div>

  <!-- Individual Dashboard Addons -->
  <div *ngIf="isReferralPlan && onsList?.length && forIndividualA"
    class="m-auto block unit-wrapper add-ons w-m-m md:px-4">
    <div class="card-border individual-dashboard-addons max-w-[340px] md:max-w-[700px] lg:max-w-[1060px] mx-auto">

      <app-payment-option-card [getFromLocalStorage]="true" [planList]="onsList" [user_type]="userType" class="payment-option-card">
      </app-payment-option-card>
    </div>
  </div>

  <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
  </ngx-spinner>

  <!-- Company Dashboard Addons -->
  <div *ngIf="isReferralPlan && onsList?.length && !forIndividualA"
    class="m-auto block unit-wrapper add-ons w-m-m md:px-4 company-dashboard-addons">
    <div class="card-border max-w-[340px] md:max-w-[700px] lg:max-w-[1060px] mx-auto">
      <app-payment-option-card [getFromLocalStorage]="true" [planList]="onsList" [user_type]="userType" class="payment-option-card">
      </app-payment-option-card>
    </div>
  </div>
</div>