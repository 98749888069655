<form [formGroup]="skillForm" class="bg-normal p-6">
  <div class="mb-6">
    <p class="montserrat-bold">Industry</p>
    <input class="mt-2 w-full input-form px-4" type="text" formControlName="industry">
  </div>
  <div class="mb-6">
    <p class="montserrat-bold">Job Type</p>
    <input class="mt-2 w-full input-form px-4" type="text" formControlName="type">
  </div>
  <div class="mb-6">
    <p class="montserrat-bold">Experience Level</p>
    <input class="mt-2 w-full input-form px-4" type="text" formControlName="level">
  </div>
  <div class="mb-6">
    <p class="montserrat-bold">Weekly Pay Range</p>
    <input class="mt-2 w-full input-form px-4" type="text" formControlName="payRange">
  </div>

  <div class="flex items-center">
    <button mat-button class="button outlined black" (click)="onSave()">Save</button>
    <div class="mx-2">
      <button mat-flat-button class="button outlined black"(click)="onCancel()">Cancel</button>
    </div>
    <button mat-flat-button class="button warning" (click)="onDelete()">Delete</button>
  </div>
</form>
