import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleModalComponent } from '../../../messages/components/schedule-modal/schedule-modal.component';
import { startOfDay, endOfDay } from 'date-fns';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'app-dashboard-livestreams-preferences',
  templateUrl: './dashboard-livestreams-preferences.component.html',
  styleUrls: ['./dashboard-livestreams-preferences.component.scss'],
})
export class DashboardLivestreamsPreferencesComponent implements OnInit {

  constructor(public profileService: ProfileService, private dialog: MatDialog) { }

  ngOnInit(): void { }

  addNewEvent(): void {
    var newDate = new Date()
    newDate.setHours(12)
    newDate.setMinutes(0)
    const newEvent = [
      {
        title: 'New event',
        actions: [],
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
        description: 'New Description'
      },

    ];
    this.dialog.open(ScheduleModalComponent, {
      width: '536px',
      maxWidth: '100vw',
      maxHeight: 'calc(100vh - 30px)',
      hasBackdrop: false,
      data: { events: newEvent, isPreview: false }
    });
  }
}
