<div
  class="relative"
  [ngClass]="currentUrl[0].path === 'profile' ? 'sepecific-user-page' : 'post-page'"
>
  <!-- POST STORY SECTION -->
  <div *ngIf="stories?.length" class="story-container flex gap-5 justify-center">
    <swiper
      [navigation]="true"
      [config]="config"
      class="post-detail-swpier-container filter-swipper common-arrow posts-swipper"
      (reachEnd)="onSlideChange($event)"
      #swiperRef
    >
      <ng-template swiperSlide>
        <div class="relative cursor-pointer">
          <img
            class="story-image"
            [src]="profile?.avatar"
            onerror="this.src='assets/images/logo/bird-logo-no-bg.png'"
          />
          <button class="add-post absolute" (click)="postContent()">+</button>
          <p class="text-style mt-1">Your posts</p>
        </div>
      </ng-template>
      <ng-template *ngFor="let item of stories; trackBy: trackByFunc" swiperSlide>
        <div (click)="postDetail(item)" class="cursor-pointer">
          <img
            *ngIf="item?.content?.media_type == 'moment'"
            class="story-image image_border"
            [src]="item?.content?.url"
          />
          <video
            *ngIf="item?.content?.media_type != 'moment'"
            class="story-image image_border"
            [src]="item?.content?.url + '#t=0.1'"
            preload="metadata"
            loop
            [muted]="'muted'"
            playsinline
            webkit-playsinline
          ></video>
          <p class="text-style mt-1">{{ item?.profile?.user_name }}</p>
        </div>
      </ng-template>
    </swiper>
  </div>
  <!-- POST STORY SECTION END -->

  <!-- <div class="mb-4 lg:absolute left-4 go-back-btn-wrapper">
    <app-go-back></app-go-back>
  </div> -->
  <app-post-detail-card
    [isOpenBottomSheet]="true"
    *ngIf="interestList?.length"
    [postID]="postID"
    [commentIdChanged]="commentId"
    [loginedProfile]="profile"
    [loginedFollowers]="followers"
    [interestList]="interestList"
    (postByInterest)="getRelatedPosts($event, true)"
    [addView]="postID"
    [appInViewLogger]="{type:'post', postId: postID, isMobileView: innerWidth < 768, isSingleGrid: true }"
  >
  </app-post-detail-card>

  <div
    #relatedPosts
    infinite-scroll
    [infiniteScrollContainer]="relatedPosts"
    [infiniteScrollThrottle]="50"
    [infiniteScrollDistance]="0.5"
    (scrolled)="currentUrl[0].path === 'profile' ? ScrollDown() : ''"
    [ngClass]="currentUrl[0].path === 'profile' ? 'specific-user-post overflow-y-auto' : ''"
    class="box-border mx-auto before:box-inherit after:box-inherit"
    id="relatedPosts"
  >
    <div [ngClass]="currentUrl[0].path === 'profile' ? 'max-w-[630px] mx-auto px-4' : ''">
      <div
        class="break-inside rounded-lg mt-10"
        [ngClass]="currentUrl[0].path === 'profile' ? 'mt-0' : 'mt-10'"
        *ngFor="let item of relatedPosts0"
      >
        <app-post-detail-card
          #postDetailCard
          *ngIf="interestList?.length || currentUrl[0].path === 'profile'"
          [postID]="item._id"
          [loginedProfile]="profile"
          [loginedFollowers]="followers"
          [interestList]="interestList"
          (postByInterest)="getRelatedPosts($event, true)"
          [appInViewLogger]="{
            type:'post', 
            postId: item._id,
            isMobileView: innerWidth < 768,
            isSingleGrid: true
          }"
          (viewLogged)="onPostViewLogged($event, postDetailCard)"
        >
        </app-post-detail-card>
      </div>
    </div>
  </div>
</div>

<div class="spinner-wrapper">
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="black-dark"
    size="medium"
    color="red"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    class="job-list-spinner"
  >
  </ngx-spinner>
</div>
