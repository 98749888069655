import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { RefundStatusConst, statusConstant } from 'src/app/const/appConst';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
// import { DisputeModalComponent } from '../dispute-modal/dispute-modal.component';
// import { ProfileService } from 'src/app/core/services/profile.service';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';

@Component({
  selector: 'app-service-detail-modal',
  templateUrl: './service-detail-modal.component.html',
  styleUrls: ['./service-detail-modal.component.scss']
})
export class ServiceDetailModalComponent implements OnInit {
  RefundStatusConst = RefundStatusConst;
  private unsubscribe$ = new Subject();
  selectedService: any;
  slides: any = [];
  pricingPackages!: any[];
  activeSlide: number = 1;
  isRefundable = false;
  selectedPlan = 'Basic';
  selectedPackage: any;
  loginedUser: any;

  constructor(
    private dialogRef: MatDialogRef<ServiceDetailModalComponent>,
    private talentService: TalentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private snack: SnackBarService,
    private stripePaymentService: paymentService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private userPlan: userPlans,
    private commonService: CommonService,
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService // private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    const createdAtDate: any = new Date(this.data.service.created_at);
    const currentDate: any = new Date();
    const timeDifference = currentDate - createdAtDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    this.isRefundable = hoursDifference < 24;
    this.getServiceById();
    // this.getNetworkNotifications();
    if (this.authService.loggedIn()) {
      this.dashboard();
    }
  }

  onSlideChange(swiper: any) {
    this.activeSlide = swiper.activeIndex + 1;
    this.changeDetector.detectChanges();
  }
  refundService() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: {
        message: `Are you sure you want to raise the refund request`,
        header: 'Confirm refund request'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        let { service_id, _id } = this.data;
        let payload = { package_id: service_id, purchased_id: _id };
        this.stripePaymentService
          .sendRefundRequest(payload)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            this.selectedService['refund'] = [res.data];
            this.snack.showMessage(res.message, false);
            this.spinner.hide();
            this.dialogRef.close(true);
          });
      }
    });
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.loginedUser = profileInfo;
      }
    });
  }

  message() {
    if (this.selectedService.profile._id) {
      const talentInfo = {
        email: this.selectedService.profile.email,
        name: this.selectedService.profile.name,
        profile_img_path: this.selectedService.profile.profile_img_path,
        user_name: this.selectedService.profile.user_name,
        user_type: this.selectedService.profile.user_type,
        _id: this.selectedService.profile._id
      };
      localStorage.setItem('talentInfo', JSON.stringify(talentInfo));
      this.router.navigate(['/dashboard/messages'], {
        queryParams: { tab: 3 }
      });
    }
  }

  async purchaseService(serviceSelectedPackage: any, purchasedType: string) {
    if (this.authService.loggedIn()) {
      if (!serviceSelectedPackage?.profile?.user_name) {
        this.snack.showMessage('UnKnown Service', true);
        return;
      }
      if (serviceSelectedPackage?.profile?.user_name == this.loginedUser?.user_name) {
        this.snack.showMessage("You can't purchase your own Service", true);
        return;
      }
      this.profileService
        .getOnBoardStatus()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((user: any) => {
          if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
            const dialogRef = this.dialog.open(ConfirmModalComponent, {
              width: '500px',
              data: {
                message: `Complete one time Stripe onboarding
                to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
                header: 'Stripe Onboarding'
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                localStorage.setItem(
                  'purchase_plan_type',
                  JSON.stringify({ plan_type: 'Recruiter' })
                );
                this.router.navigate(['/' + routesConst.planPricing]);
              }
            });
          } else {
            this.purchase(serviceSelectedPackage, purchasedType);
          }
        });
    } else {
      this.spinner.hide();
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  purchase(serviceSelectedPackage: any, purchasedType: any) {
    // this.spinner.show();
    const redirect_url = this.commonService.location().href;
    const refresh_url = this.commonService.location().href;
    this.talentService
      .getStripeUser(redirect_url, refresh_url)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.status == 'on_board_required') {
            this.snack.showMessage(`${res.message}`, false);
            this.spinner.hide();
            this.commonService.location().href = res.accountLink.url;
          } else {
            let params: any = {
              mode:
                this.selectedPackage?.billing_type == '61e1a8fa54f7e11728dcfc86'
                  ? 'payment'
                  : 'subscription',
              cancel_url: `${window.origin}/failed`,
              success_url: `${window.origin}/success`,
              line_items: [
                {
                  price: this.selectedPackage?.price_id,
                  quantity: 1
                }
              ]
            };
            // this.spinner.show();
            this.profileService
              .getCommunityPageUserProfile(serviceSelectedPackage?.profile?.user_name)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((x: any) => {
                if (x?.data && x?.data[0]) {
                  const { _id, user_name } = x.data[0].profile;
                  this.stripePaymentService
                    .purchaseProduct(
                      params,
                      _id,
                      user_name,
                      this.loginedUser._id,
                      serviceSelectedPackage._id,
                      purchasedType,
                      this.selectedPackage?.billing_type,
                      this.selectedPackage._id
                    )
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                      (res: any) => {
                        this.spinner.hide();
                        if (res && res?.accountLink?.url) {
                          this.commonService.location().href = res?.accountLink?.url;
                        } else {
                          this.spinner.hide();
                          this.snack.showMessage(
                            'Unable to connect to Stripe at the moment, Please try again later',
                            true
                          );
                        }
                      },
                      error => {
                        this.spinner.hide();
                        this.snack.showMessage(`${error}`, true);
                      }
                    );
                }
              });
          }
        },
        error => {
          this.spinner.hide();
          this.snack.showMessage(`${error} `, true);
        }
      );
  }

  getServiceById() {
    if (this.data?.service_id) {
      this.talentService
        .getSingleTalentService(this.data.service_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.selectedService = result.data;
          this.slides = this.selectedService?.portfolios.map((portfolio: any) => {
            return {
              path: portfolio.url
            };
          });

          this.pricingPackages = this.selectedService?.packages.map((pkg: any) => {
            return {
              title: pkg.name,
              price: pkg.price,
              description: pkg.description,
              features: pkg?.offers?.split(','),
              price_id: pkg.price_id,
              billing_type: pkg.billing_type,
              isMore: true,
              isBigger: pkg.description.length > 140,
              has_purchased_plan: pkg.has_purchased_plan,
              _id: pkg._id,
              type: pkg?.type ? pkg.type : ''
            };
          });
          this.selectedPackage =
            this.pricingPackages.find(obj => obj.type === 'basic') || this.pricingPackages[0];
        });
    }
  }

  getDescription(item: any) {
    const maxLength = 140;
    item.isBigger = maxLength < item.description.length;
    return item.isMore && maxLength < item.description.length
      ? item.description.substring(0, maxLength)
      : item.description;
  }

  close(event: any) {
    this.dialogRef.close(false);
    event?.preventDefault();
  }

  updateRefundRequest(status: any) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: {
        message: `Are you sure you want to ${
          status == RefundStatusConst.DECLINED ? 'decline ' : 'approve '
        } the refund request`,
        header: 'Confirm refund request'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        let { _id } = this.data?.service?.refund[0];
        let payload = {
          refund_id: _id,
          refund_request_status: status,
          paymentIntentId: this.data?.service.payment_intent_id
        };
        this.stripePaymentService
          .updateRefundRequest(payload)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            this.snack.showMessage(res.message, false);
            this.spinner.hide();
            this.dialogRef.close(true);
          });
      }
    });
  }

  checkDate(refundDate: any) {
    const currentDate = new Date();
    const parsedDate = new Date(refundDate);
    const date24HoursAgo = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
    return parsedDate < date24HoursAgo;
  }

  stripeExpress(service: any) {
    this.spinner.show();
    let { seller_account_id, customer } = service;
    let payload = { account_id: seller_account_id, customer_id: customer };
    this.userPlan
      .createBillingPortal(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.commonService.getWindow()?.open(res.billingSession.url, '_blank');
        this.spinner.hide();
      });
  }

  onAddReview(selectedService: any, event: any) {
    const service = {
      serviceDetails: { _id: selectedService?.service?._id },
      seller_id: selectedService.seller_id,
      buyer_id: selectedService.buyer_id,
      _id: selectedService._id,
      type: 'sold_service'
    };
    const dialogRef = this.dialog.open(FeedbackModalComponent, {
      width: '440px',
      data: service
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
    event.stopPropagation();
  }

  selectPackage(planType: string) {
    this.selectedPlan = planType;
    if (this.selectedPlan == 'Basic') {
      this.selectedPackage =
        this.pricingPackages?.find(obj => obj.type === 'basic') || this.pricingPackages[0];
    }
    if (this.selectedPlan == 'Standard') {
      this.selectedPackage =
        this.pricingPackages?.find(obj => obj.type === 'standard') || this.pricingPackages[1];
    }
    if (this.selectedPlan == 'Premium') {
      this.selectedPackage =
        this.pricingPackages?.find(obj => obj.type === 'premium') || this.pricingPackages[2];
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
