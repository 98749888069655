import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/config/config';
import { apiUrlConst } from 'src/app/const/apiConst';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class paymentService {
  constructor(
    private _http: HttpClient,
    private commonService: CommonService
  ) {}

  purchaseSubscription(params: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.createCustomerAndSubscription,
      params
    );
  }

  cancelPlatformSubscription(params: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.cancelPlatformSubscription,
      params
    );
  }

  cancelPlatformSubscriptionAddon(product_id: any) {
    let product = { product: product_id };
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.cancelPlatformSubscriptionAddon,
      product
    );
  }

  purchaseAddOn(params: any, product_id: string) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.purchaseAddOn +
        `?product=${product_id}`,
      {
        ...params,
      }
    );
  }

  getBalance() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getBalance
    );
  }

  getLoginLink() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getLink
    );
  }

  publicPrices() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.publicPrices
    );
  }

  connectAccount() {
    const params = {
      country: 'US',
      business_type: 'individual',
    };
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.connectAccount,
      params
    );
  }

  purchaseProduct(
    options: any,
    sellerId: any,
    user_name: any,
    buyerId: any,
    serviceId: any,
    purchasedType: string,
    billing_type: any,
    packageId?: any,
  ) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.purchaseProduct +
        `?profileId=${sellerId}&user_name=${user_name}&buyerId=${buyerId}&serviceId=${serviceId}&packageId=${packageId}&purchasedType=${purchasedType}&billingType=${billing_type}`,
      {
        ...options,
      }
    );
  }

  purchaseUserCreatedPlan(
    options: any,
    sellerId: any,
    buyerId: any,
    serviceId: any,
    plan: any,
    interval: any,
    purchasedType: string
  ) {
    let encodedPlanNameWithSpecialChar = encodeURIComponent(plan);
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.purchaseUserCreatedPlan +
        `?profileId=${sellerId}&buyerId=${buyerId}&serviceId=${serviceId}&plan=${encodedPlanNameWithSpecialChar}&interval=${interval}&purchasedType=${purchasedType}`,
      {
        ...options,
      }
    );
  }

  getPaymentList() {
    let params = {
      limit: 100,
      customer: '',
    };
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getPaymentList,
      params
    );
  }

  totalEarning(filter: any) {
    let params = new HttpParams();
    params = params.append('filter', filter);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getTotalEarning,
      options
    );
  }

  networkStats(filter: any) {
    let params = new HttpParams();
    params = params.append('filter', filter);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.networkStats,
      options
    );
  }

  paymentMethods() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.minePaymentsMehthods
    );
  }

  accountSettings() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.settings
    );
  }

  deletePaymentMethod(paymentMethodId: string) {
    let params = new HttpParams();
    params = params.append('paymentMethodId', paymentMethodId);
    const options = { params: params };
    return this._http.delete(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.deletePaymentMethod,
      options
    );
  }

  makeCardPrimary(paymentMethodId: string) {
    const payload = { paymentMethodId };
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.makeCardPrimary,
      payload
    );
  }

  addNewCard(paymentMethodId: string) {
    const payload = { paymentMethodId };
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.newCard,
      payload
    );
  }

  sendRefundRequest(payload: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.sendRefundRequest,
      payload
    );
  }

  updateRefundRequest(payload: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.updateRefundRequest,
      payload
    );
  }
}
