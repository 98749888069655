import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';
import { JobCardComponent } from 'src/app/modules/jobs/components/job-card/job-card.component';
import { TalentCardComponent } from 'src/app/modules/talents/components/talent-card/talent-card.component';
import { IndividualServiceCardComponent } from 'src/app/shared/components/individual-service-card/individual-service-card.component';
import { PostDetailCardComponent } from 'src/app/shared/components/post-detail-card/post-detail-card.component';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {
  feedContentFilters = { offset: 0, limit: 10, text: '' };
  feedContentListFilters = { offset: 0, limit: 10};
  private unsubscribe$ = new Subject();
  content: any[] = [];
  suggestContentList: any[] = [];
  followers: any[] = [];
  interestList: any[] = [];
  profile: any;
  commentId: string = '';
  textInput = '';

  showActiveItems:any = []
  data = [
    this.authService.loggedIn() ? { name: 'Feed',  url: '', isLogin: true, params: {} } : {}, 
    { name: 'Jobs', url: this.authService.loggedIn() ? routesConst.jobs : '#homeJobs', isLogin:true, params: {} },
    { name: 'Services', url: this.authService.loggedIn() ? routesConst.services:'#homeServices', isLogin:true, params: {} },
    { name: 'Creators', url: this.authService.loggedIn() ? routesConst.community: '#homeCreators', isLogin:true, params: {} },
    {
      name: 'Subscriptions',
      url: this.authService.loggedIn() ? routesConst.community : '#homeSubscriptions', isLogin:true,
      params: { tab: 'subscriptions' }
    },
    { name: 'Pricing', url: routesConst.pricing, isLogin:this.authService.loggedIn() ?false:true ,params: {} },
    { name: 'Use CASE', url: '', isLogin:false ,params: {} },
    { name: 'Discover ', url: routesConst.discover, isLogin: true, params: {} },
    { name: 'Community', url: routesConst.communities, isLogin:true, params: {} },
    { name: 'Referrals', url: routesConst.dashboard + '/' + routesConst.referrals, isLogin:this.authService.loggedIn() ?true:false, params: {} },
    { name: 'Members', url: routesConst.community, isLogin:this.authService.loggedIn() ?true:false, params: { tab: 'people' } },
    { name: 'Tutorials', url: routesConst.tutorials, isLogin:this.authService.loggedIn() ?true:false, params: {} },
    { name: 'About Us', url: routesConst.aboutus, isLogin:this.authService.loggedIn() ?true:false, params: {} },
    { name: 'FAQ', url: routesConst.faq, isLogin:true, params: {} },
    { name: 'Platform', url: '#Platform', isLogin:false, params: {} },
    { name: 'Company', url: '#Company', isLogin:false, params: {} },
    { name: 'Integrations', url: '#Integrations', isLogin:false, params: {} },

  ];

  constructor(
    private spinner: NgxSpinnerService,
    private postService: PostService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    this.showActiveItems = this.data.filter(item => item.isLogin ===true)
  }

  ngOnInit(): void {
    this.getFeedContent();
    this.getFeedContentList();
    if (this.authService.loggedIn()) {
      this.getFollowersAndFollowings();
      this.dashboard();
    }
    this.getInterestList();
  }

  getInterestList() {
    this.sharedService.skillsOrIntrest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((interest: any) => {
        if (interest.length) {
          this.interestList = interest;
        } else {
          this.sharedService.getSkillsOrIntrest();
        }
      });
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.profile = profileInfo;
      }
    });
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(network => {
        if (network?.followings || network?.followers) {
          this.followers = network.followings;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  getFeedContent() {
    this.spinner.show();
    this.postService
      .getFeedContent(this.feedContentFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.content = [
            // ...this.content,
            ...res?.data?.posts,
            ...res?.data?.jobs,
            ...res?.data?.services,
            ...res?.data?.subscriptions
          ].sort(() => Math.random() - 0.5);
          
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getFeedContentList() {
    this.spinner.show();
    this.postService
      .getFeedContentList(this.feedContentListFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.suggestContentList = [
            ...res?.data?.posts,
            ...res?.data?.jobs,
            ...res?.data?.services,
            ...res?.data?.subscriptions
          ].sort(() => Math.random() - 0.5);
          
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  onSearch(text: any) {
    this.feedContentFilters.text = text;
    this.getFeedContent();
  }

  getRelatedPosts(event: any) {}

  onPostViewLogged(data: any, postDetailCard: PostDetailCardComponent) {
    if (data?.success) {
      postDetailCard.onPostViewLogged(data);
    }
  }

  onJobViewLogged(data: any, jobCard: JobCardComponent, item: any) {
    if (data?.success) {
      jobCard.onJobViewLogged(data);
      item.data.job.views += 1;
    }
  }

  onServiceViewLogged(data: any, talentCard: TalentCardComponent, item: any) {
    if (data?.success) {
      talentCard.onServiceViewLogged(data);
      item.views += 1;
    }
  }

  onSubscriptionViewLogged(data: any, subscriptionCard: IndividualServiceCardComponent, item: any) {
    if (data?.success) {
      subscriptionCard.onSubscriptionViewLogged(data);
      item.views += 1;
    }
  }

  ngOnDestroy(): void {
    this.spinner.hide();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
