import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NavCardComponent } from 'src/app/modules/home/nav-card/nav-card.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PartEarningComponent } from './components/part-earning/part-earning.component';
import { PartFeaturesComponent } from './components/part-features/part-features.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

@NgModule({
  declarations: [
    NavCardComponent,
    PartEarningComponent,
    PartFeaturesComponent,
    ContactUsComponent
  ],
  imports: [
    CommonModule,
    IvyCarouselModule,
    RouterModule,
    SharedModule,
  ],
  exports: [NavCardComponent],
})
export class HomeModule { }
