import { Component, OnInit } from '@angular/core';
import { PartWorkFlow, WorkFlow } from 'src/app/core/models/work-flow';
import { WorkFlowService } from 'src/app/shared/services/work-flow.service';

@Component({
  selector: 'app-part-workflow',
  templateUrl: './part-workflow.component.html',
  styleUrls: ['./part-workflow.component.scss'],
})
export class PartWorkflowComponent implements OnInit {

  public workFlows: WorkFlow[] = [];
  public partWorkFlow: PartWorkFlow[] = [];
  
  constructor(private workFlowService: WorkFlowService) { }

  ngOnInit(): void {
    this.partWorkFlow = this.workFlowService.getPartWorkFlow();
    this.workFlows = this.workFlowService.getWorkFlows();
  }

  public swiperConfig = {
    breakpoints: {
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3
      }
    }
  }
}
