<br><br>
<div class="flex flex-col lg:flex-row items-center justify-between my-20 lg:my-5">
  <div class="flex items-center rounded overflow-hidden">
    <button class="w-28 lg:w-auto button round-zero" mat-button mwlCalendarPreviousView [view]="view"
      [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
      <span class="white">Previous</span>
    </button>
    <button mat-button class="w-28 lg:w-auto button normal round-zero" mwlCalendarToday [(viewDate)]="viewDate">
      <span>Today</span>
    </button>
    <button class="w-28 lg:w-auto button round-zero" mat-button mwlCalendarNextView [view]="view"
      [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
      <span class="white">Next</span>
    </button>
  </div>
  <h3 class="my-2 lg:my-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  <div class="flex items-center rounded overflow-hidden">
    <button mat-button class="w-24 lg:w-auto button round-zero" (click)="setView(CalendarView.Month)"
      [class.active]="view === CalendarView.Month">
      <span class="white">Month</span>
    </button>
    <button mat-button class="w-24 lg:w-auto button round-zero" (click)="setView(CalendarView.Week)"
      [class.active]="view === CalendarView.Week">
      <span class="white">Week</span>
    </button>
    <button mat-button class="w-24 lg:w-auto button round-zero" (click)="setView(CalendarView.Day)"
      [class.active]="view === CalendarView.Day">
      <span class="white">Day</span>
    </button>
  </div>
</div>
<div [ngSwitch]="view">
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
    [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view>
</div>
<div class="table-responsive" *ngIf="events.length">
    <h2>Saved Events</h2>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Title</th>
          <th>Starts at</th>
          <th>Ends at</th>
          <th>Remove</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of events">
          <td>
            <input type="text" class="form-control" [(ngModel)]="event.title" (keyup)="refresh.next()" />
          </td>
          <td>
            <input [(ngModel)]="event.start" (ngModelChange)="refresh.next()" #myDate [matDatepicker]="date" />
          </td>
          <mat-datepicker-toggle class="mt-4" id="puple-icon" matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>

          <td>
            <input [(ngModel)]="event.end" (ngModelChange)="refresh.next()" #myDate [matDatepicker]="date1" />
            <mat-datepicker-toggle class="mt-4" id="puple-icon" matSuffix [for]="date1"></mat-datepicker-toggle>
            <mat-datepicker #date1></mat-datepicker>
          <td>
            <button class="btn btn-danger" (click)="deleteEvent(event)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>