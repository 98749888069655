import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SharedModule } from 'src/app/shared/shared.module';
import { IndividualSubscriptionComponent } from './individual-subscription/individual-subscription.component';
import { SubscriptionPackageUnitUpdateComponent } from 'src/app/shared/components/service-modal/subscription-package-unit-update/subscription-package-unit-update.component';

@NgModule({
  declarations: [IndividualSubscriptionComponent, SubscriptionPackageUnitUpdateComponent],
  imports: [CommonModule, IvyCarouselModule, SharedModule]
})
export class IndividualSubscriptionModule {}
