<div class="tweet-option">
    <div *ngIf="addHideShow" [ngClass]="addExpand ? 'menu-show':'menu-hide'" class="ease">
     <div class="flex items-center justify-between mb-4 cursor-pointer" (click)="itemActive('content');tweetmenu()">
         <p class="text-lg font-bold mr-4">Content</p>
         <div class="h-10 w-10 rounded-full icon-wrapper flex items-center justify-center shadow-lg mr-2"><mat-icon>panorama</mat-icon></div>
     </div>
     <div *ngIf=" this.userType == userTypeConst.company" (click)="itemActive('jobs'); tweetmenu()" class="flex items-center justify-between mb-4 cursor-pointer">
         <p class="text-lg font-bold mr-4">Jobs</p>
         <div class="h-10 w-10 rounded-full icon-wrapper flex items-center justify-center shadow-lg mr-2"><mat-icon>work</mat-icon></div>
     </div>
     <div (click)="itemActive('services');tweetmenu()" class="flex items-center justify-between mb-4 cursor-pointer">
         <p class="text-lg font-bold mr-4">Services</p>
         <div class="h-10 w-10 rounded-full icon-wrapper flex items-center justify-center shadow-lg mr-2"><mat-icon>design_services</mat-icon></div>
     </div>
     <div (click)="itemActive('campaign');tweetmenu()" class="flex items-center justify-between mb-4 cursor-pointer">
         <p class="text-lg font-bold mr-4">Subscriptions</p>
         <div class="h-10 w-10 rounded-full icon-wrapper flex items-center justify-center shadow-lg mr-2"><mat-icon>campaign</mat-icon></div>
     </div>
     <!-- <div (click)="itemActive('subscription');tweetmenu()" class="flex items-center justify-between mb-4 cursor-pointer">
         <p class="text-lg font-bold mr-4">Paid Access</p>
         <div class="h-10 w-10 rounded-full icon-wrapper flex items-center justify-center shadow-lg mr-2"><mat-icon>loyalty</mat-icon></div>
     </div> -->
    </div>
     <div class="flex items-center justify-end cursor-pointer">
         <!-- <p *ngIf="addHideShow" class="text-lg font-bold mr-4 ease" [ngClass]="addExpand ? 'menu-show':'menu-hide'"></p> -->
         <div class="h-12 w-12 rounded-full  flex items-center justify-center shadow-lg" [ngClass]="addHideShow ? 'bg-blue':sideBarToogle ? 'bg-btn-white' :'bg-blue'" (click)="tweetmenu()"><mat-icon>add</mat-icon></div>
     </div>
     <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
  </ngx-spinner>
 </div>
 <div class="backdrop" (click)="tweetmenu()"*ngIf="addHideShow">

 </div>