import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-stripe-card-modal',
  templateUrl: './stripe-card-modal.component.html',
  styleUrls: ['./stripe-card-modal.component.scss'],
})
export class StripeCardModalComponent implements OnInit {
  private unsubscribe$ = new Subject();
  stripeCardValid: boolean = false;
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;

  features = ['Photo Content', 'Video Content', 'Livestream Events'];
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private dialogRef: MatDialogRef<StripeCardModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stripService: StripeService,
    private stripePaymentService: paymentService,
    private snack: SnackBarService
  ) {}

  ngOnInit(): void {}

  subscribeAndCheckout() {
    // this.spinner.show();
    this.stripService
      .createPaymentMethod({
        type: 'card',
        card: this.card.element,
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((stripeElement) => {
        let stripePurchase;
        if (stripeElement) {
          stripePurchase = {
            new_payment_method_id: stripeElement?.paymentMethod?.id,
            plan: this.data?.default_price,
            product_id: this.data.id,
          };
        } else {
          //  // this.spinner.hide();
        }
        // this.spinner.show();
        this.stripePaymentService
          .purchaseSubscription(stripePurchase)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (res: any) => {
              if (res.result) {
                //  // this.spinner.hide();
                // this.spinner.show();
                this.stripePaymentService
                  .connectAccount()
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe(() => {
                    // this.spinner.hide();
                    this.dialogRef.close();
                  });
                this.snack.showMessage(
                  `Thank you For Purchasing ${this.data.name} Plan`,
                  false
                );
              }
            },
            (err) => {
              //  // this.spinner.hide();
              this.dialogRef.close();
              this.snack.showMessage(`${err}`, true);
            }
          );
      });
  }

  onChange(event: any) {
    this.stripeCardValid = event.complete;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
