import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routesConst } from 'src/app/core/const/routers';
import { Portfolio } from 'src/app/core/models';
import { CommonService } from 'src/app/core/services/common.service';
import { SubscribeModalComponent } from 'src/app/modules/users/modals/subscribe-modal/subscribe-modal.component';
import { config } from 'src/config/config';

@Component({
  selector: 'app-livestream',
  templateUrl: './livestream.component.html',
  styleUrls: ['./livestream.component.scss'],
})
export class LivestreamComponent implements OnInit {
  private unsubscribe$ = new Subject();
  routesConst = routesConst;
  commentForm: FormGroup;
  isFavor: boolean;
  data?: Portfolio;
  video: any;
  video_url: any;
  origin: string = '';

  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    private commonService: CommonService
  ) {
    this.commentForm = this.fb.group({
      comment: ['', [Validators.required]],
    });
    this.isFavor = false;
    this.origin = config.environmentMode(
      this.commonService.location().hostname
    ).twitch_url;
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      this.video = `${x['video']}&parent=${this.origin}&autoplay=false`;
      this.video_url = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.video
      );
    });
  }

  public errorHandling = (control: string, error: string) => {
    return this.commentForm.controls[control].hasError(error);
  };

  submitForm() {}

  subscribe() {
    this.dialog.open(SubscribeModalComponent, {
      maxWidth: '100vw',
      width: '100vw',
      height: '100vh',
      data: {},
    });
  }

  trackByFunc(i: number) {
    return i;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
