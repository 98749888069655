import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { PostService } from 'src/app/core/services/post.service';

@Injectable({
  providedIn: 'root',
})
export class MentionService {
  private unsubscribe$ = new Subject();
  public refreshMention$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(public _http: HttpClient, private postService: PostService) {}
  public mentionConfig = {
    mentions: [
      {
        items: [],
        triggerChar: '@',
        labelKey: 'user_name',
      },
      {
        items: [],
        triggerChar: '#',
        labelKey: 'user_name',
      },
    ],
  };

  handleMentionTrigger(input: any): void {
    if (input.includes('@')) {
      this.getMentionUsers(input);
    } else if (input.includes('#')) {
      this.getIntrstList();
    }
  }

  getMentionUsers(input: any) {
    let extractComment = input?.split(' ');
    let mentionUser = extractComment[extractComment.length - 1];
    let searchUser = mentionUser.replace('@', '');
    let params = {
      keyword: searchUser,
    };
    this.postService
      .getMentionedCandidate(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        const list = res.data.map((users: any) => {
          return { ...users, isShowImage: true, trigger: '@' };
        });
        this.mentionConfig = {
          mentions: [
            {
              items: list,
              triggerChar: '@',
              labelKey: 'user_name',
            },
            {
              items: [],
              triggerChar: '#',
              labelKey: 'user_name',
            },
          ],
        };
      });
  }

  getIntrstList() {
    this.postService
      .getIntrestList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x: any) => {
        const list = x.data.map((skill: any) => {
          return {
            user_name: skill.name,
            id: skill._id,
            isShowImage: false,
            trigger: '#',
          };
        });
        this.mentionConfig = {
          mentions: [
            {
              items: [],
              triggerChar: '@',
              labelKey: 'user_name',
            },
            {
              items: list,
              triggerChar: '#',
              labelKey: 'user_name',
            },
          ],
        };
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
