<div class="dashboard-subscription flex flex-col w-full">
	<div class="flex items-center w-full">
		<div class="flex window-mode">
			<h1 class="montserrat-bold text-style">Subscriptions</h1>
		</div>
	</div>
	<div class="mt-4 px-4 lg:px-0">
		<swiper [navigation]="true" [config]="config" class="swpier-container common-arrow dashboard-subscription-swipper">
			<ng-template swiperSlide>
				<div class="flex items-center tab swiper-tab cursor-pointer" (click)="selected.value = 0"
					[class]="selected.value === 0 ? 'selected' : ''">
					<p class="tab-size" [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
						Subscriptions 
					</p>
				</div>
			</ng-template>

			<ng-template swiperSlide>
				<div (click)="getPayment()" class="flex items-center tab swiper-tab cursor-pointer" (click)="selected.value = 1"
					[class]="selected.value === 1 ? 'selected' : ''">
					<p class="tab-size" [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
						Orders
					</p>
				</div>
			</ng-template>
			<ng-template swiperSlide>
				<div class="flex items-center tab swiper-tab cursor-pointer" (click)="selected.value = 2"
					[class]="selected.value === 2 ? 'selected' : ''">
					<p class="tab-size" [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">
						Paid
					</p>
				</div>
			</ng-template>
			<ng-template swiperSlide>
				<div class="flex items-center tab swiper-tab cursor-pointer" (click)="selected.value = 3"
					[class]="selected.value === 3 ? 'selected' : ''">
					<p class="tab-size" [class]="selected.value === 3 ? 'blue montserrat-bold' : ''">
						Sold
					</p>
				</div>
			</ng-template>
			<!-- <ng-template swiperSlide>
				<div class="flex items-center tab swiper-tab cursor-pointer" (click)="selected.value = 4"
					[class]="selected.value === 4 ? 'selected' : ''">
					<p class="tab-size" [class]="selected.value === 4 ? 'blue montserrat-bold' : ''">
						Reviews
					</p>
				</div>
			</ng-template> -->
		</swiper>
	</div>
	<div class="tabs">
		<mat-tab-group class="subscription-tabs" color="#fff0" [selectedIndex]="selected.value"
			(selectedIndexChange)="selected.setValue($event)">
			<mat-tab>
				<ng-template mat-tab-label>
					<div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
						<p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
							Services
						</p>
					</div>
				</ng-template>
				<div class="bg-white pt-0 px-4 pb-4 lg:p-10">
					<div class="flex justify-between items-center">
						<p class="black-light s">Manage and create subscriptions</p>
						<button mat-button
							class="montserrat-medium hover:text-white font-style-md cursor-pointer button outlined black"
							(click)="newSubscriptionPackage()">
							+ Add
						</button>
					</div>
					<!-- <p class="text-black montserrat-bold">Subscriptions</p> -->
					<div class="">
						<ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red"
							type="ball-spin-clockwise" [fullScreen]="true" class="job-list-spinner">
						</ngx-spinner>
						<!-- <mat-divider class="w-full"></mat-divider> -->
					</div>
					<div class="dashboard-subscription-services mt-2 pr-1">
						<div class="grid md:mt-6 sm:grid-cols-2 2xl:grid-cols-3 gap-2 my-2">
							<app-individual-service-card *ngFor="let service of myServices" [service]="service"
								(click)="showDetails(service, stepper)"></app-individual-service-card>
						</div>
					</div>
					<div class="grid sm:grid-cols-2 2xl:grid-cols-3 gap-2 my-2">
					</div>
					<div class="mt-8 flex flex-row">
						<button mat-button *ngIf="
				  userType == userTypeConstant.candidate &&
				  checkUserPlan(planTypeConstant.elite, planTypeConstant.nil)
				" [routerLink]="'/' + routesConst.planPricing" class="button outlined black upgrade-button">
							Upgrade
						</button>
						<button mat-button *ngIf="
				  userType == userTypeConstant.company &&
				  checkUserPlan(planTypeConstant.enterprise)
				" [routerLink]="'/' + routesConst.planPricing" class="button outlined black upgrade-button">
							Upgrade
						</button>
					</div>
				</div>
				<!-- <div class="section-wrapper bg-white p-4 lg:p-10 mt-4">
					<p class="text-black montserrat-bold">You’re buyer feedback</p>
					<p class="mt-2 black-light s">Your rating and reviews</p>
					<div class="mt-2">
						<mat-divider class="w-full"></mat-divider>
					</div> -->
					<!-- <div class="flex items-center border-normal rounded p-4 mt-6"
						*ngFor="let mySubscription of platform_subscription">
						<div class="flex flex-col lg:flex-row lg:items-center">
							<p class="montserrat-bold">
								{{ mySubscription?.title }}
							</p>
							<p *ngIf="stripe_user?.is_old_user" class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">
								${{
								mySubscription.interval == "month" && mySubscription.isPlan
								? mySubscription?.old_monthly_price
								: mySubscription.interval == "year" && mySubscription.isPlan
								? mySubscription?.old_yearly_price
								: mySubscription?.price
								}}/Monthly
							</p>

							<p *ngIf="!stripe_user?.is_old_user" class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">
								${{
								mySubscription.interval == "month" && mySubscription.isPlan
								? mySubscription?.monthly_price
								: mySubscription.interval == "year" && mySubscription.isPlan
								? mySubscription?.yearly_price
								: mySubscription?.price
								}}/Monthly
							</p>
							<p class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">{{mySubscription.isPlan ? 'JOBPros Membership'
								:'JOBPros Add On'}}</p>
						</div>
						<div class="ml-auto">
							<button mat-flat-button (click)="subscriptionCheckOut(mySubscription)">
								<span class="s blue cursor-pointer montserrat-bold">Details</span>
							</button>
						</div>
					</div> -->
					<!-- <div class="flex items-center border-normal rounded p-4 mt-6" *ngFor="let plan of purchased_plans">
						<div class="flex flex-col lg:flex-row lg:items-center">
							<p class="montserrat-bold">
								{{ plan?.planDetails?.title }}
							</p>
							<p class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">
								{{ plan?.planDetails?.type }}
							</p>
						</div>
						<div class="ml-auto">
							<button mat-flat-button (click)="unsubscribeService(plan)">
								<span class="s blue cursor-pointer montserrat-bold">Details</span>
							</button>
						</div>
					</div> -->
					<!-- <div class="flex items-center border-normal rounded p-4 mt-6" *ngFor="let service of purchased_services">
					<div class="flex flex-col lg:flex-row lg:items-center">
						<p class="montserrat-bold">
							{{ service?.serviceDetails?.title }}
						</p>
						<p class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">
							{{ service?.serviceDetails?.type }}
						</p>
					</div>
					<div class="ml-auto flex">
						<p *ngIf="service?.serviceDetails?.type === 'Service' && service?.refund?.length" class="montserrat-bold m-auto">{{service?.refundDetails[0]?.refund_request_status}}</p>
						<button mat-flat-button class="refund-btn" *ngIf="service?.serviceDetails?.type === 'Service' && !service?.refund?.length" (click)="refundService(service)">
							Refund
						</button>
						<button mat-flat-button (click)="unsubscribeService(service)">
							<span class="s cursor-pointer montserrat-bold" [class]="service.isCanceled?'red':'blue'">{{
								service.isCanceled ? "Canceled" : "Details"
								}}</span>
						</button>
					</div>
				</div> -->
				<!-- </div> -->
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
					<div class="flex items-center pb-1 tab" [class]="selected.value === 1 ? 'selected' : ''">
						<p [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
							Orders
						</p>
					</div>
				</ng-template>

			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
					<div class="flex items-center pb-1 tab" [class]="selected.value === 2 ? 'selected' : ''">
						<p [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">
							Paid
						</p>
					</div>
				</ng-template>
				<app-dashboard-paid-subscription></app-dashboard-paid-subscription>
			</mat-tab>
			<mat-tab>
				<ng-template mat-tab-label>
					<div class="flex items-center pb-1 tab" [class]="selected.value === 3 ? 'selected' : ''">
						<p [class]="selected.value === 3 ? 'blue montserrat-bold' : ''">
							Sold
						</p>
					</div>
				</ng-template>
				<app-dashboard-sold-subscription></app-dashboard-sold-subscription>
			</mat-tab>
			<!-- <mat-tab>
				<ng-template mat-tab-label>
					<div class="flex items-center pb-1 tab" [class]="selected.value === 4 ? 'selected' : ''">
						<p [class]="selected.value === 4 ? 'blue montserrat-bold' : ''">
							Reviews
						</p>
					</div>
				</ng-template>
				<app-dashboard-services-reviews></app-dashboard-services-reviews>

			</mat-tab> -->

		</mat-tab-group>
	</div>
</div>