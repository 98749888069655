import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, takeWhile } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Router } from '@angular/router';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ImageService, ImageSnippet } from 'src/app/core/services/image.service';
import { PostService } from 'src/app/core/services/post.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { MatStepper } from '@angular/material/stepper';
import { ContentTypes } from 'src/app/core/const/options';
import { MentionService } from '../../services/mentions.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { planTypeConstant } from 'src/app/const/appConst';
import { SwiperOptions } from 'swiper';
import { NgxSpinnerService } from 'ngx-spinner';

interface Interest {
  created_at: string;
  icon: string;
  name: string;
  __v: number;
  _id: string;
}

interface Post {
  content: any;
  format: any;
}
@Component({
  selector: 'app-upload-post-modal',
  templateUrl: './upload-post-modal.component.html',
  styleUrls: ['./upload-post-modal.component.scss']
})
export class UploadPostModalComponent implements OnInit {
  @ViewChild('file') file: any;
  checkboxValues: { [key: string]: boolean } = {};
  config: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1
  };
  showImages = false;
  private unsubscribe$ = new Subject();
  isProcessing = false;
  uploadPost!: any;
  coverPostContent: Post = {
    content: undefined,
    format: ''
  };
  contentTypes = ContentTypes;
  selected = new FormControl(3);
  editMode: boolean = false;
  postImages: Post[] = [];
  postImagesPath: any[] = [];
  hashTags0: Interest[];
  hashTags: string[];
  filteredOptions: Observable<string[]>;
  uploadForm: FormGroup;
  usertags: string[];
  disclaimers: string[] = [];
  isDisabled: boolean = true;
  myPlans: any;
  viewOption: any = 0;
  isCampaignPost = false;
  selectedPlan: string[] = [];
  post: any;
  savePost = true;
  allImages: any[] = [];
  selectedIndex: number = 0;
  imageIndex: number = 0;
  steperSize: number = 4;
  @ViewChild('isCampaign') private isCampaign!: ElementRef;
  videoUploadedStatus = 'Processing';

  serviceIndex: FormControl = new FormControl();
  isLastStep: boolean = false;
  features: any;
  visibility: string[] = [];

  mentionUsers: any[] = [];
  mentionConfig = {
    items: this.mentionUsers,
    labelKey: 'user_name',
    triggerChar: '@'
  };

  searchByUser: any = '';
  postFilters = { offset: 0, limit: 10 };
  mentiondUsers: any = [];
  private progressSubject = new BehaviorSubject<number>(0);
  progress$ = this.progressSubject.asObservable();
  private totalFileSize = 0; // Total size of all files in bytes
  videoFileUploading = false;
  constructor(
    private snack: SnackBarService,
    public fb: FormBuilder,
    private imageService: ImageService,
    private profileService: ProfileService,
    private postService: PostService,
    private router: Router,
    public dialog: MatDialog,
    private userPlan: userPlans,
    public authService: AuthService,
    public dialogRef: MatDialogRef<UploadPostModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public mentionService: MentionService,
    private spinner: NgxSpinnerService
  ) {
    this.viewOption = 0;
    this.hashTags0 = [];
    this.hashTags = [];
    this.uploadForm = this.fb.group({
      // title: ['', [Validators.required, Validators.maxLength(256)]],
      description: ['', [Validators.required, Validators.maxLength(1024)]],
      interest: ['', []],
      asCampaign: [false, []],
      desclaimer: ['', [Validators.maxLength(512)]],
      sendTo: [['All'], []],
      checkBoxControl: [true, Validators.required]
    });
    this.filteredOptions = this.uploadForm.controls['interest'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.usertags = [];
  }

  ngOnInit(): void {
    // this.spinner.show();
    this.uploadPost = new FormGroup({ images: new FormArray([]) });
    if (this.data.editmode && this.data.postId) {
      this.editMode = true;
      this.profileService
        .editPost(this.data.postId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((post: any) => {
          let hashtags = post.data[0].category.join(' ');
          if (post && post.data && post.data.length) {
            this.post = JSON.parse(JSON.stringify(post.data[0]));
            // this.uploadForm.controls['title'].setValue(post.data[0].title);
            this.disclaimers = post.data[0].desclaimer;
            this.usertags = post.data[0].category;
            this.uploadForm.controls['description'].setValue(
              post.data[0].description + ' ' + hashtags
            );
            let coverPost = post.data[0].postContent.find((x: any) => x.cover_type === 'true');
            this.allImages = post?.data[0]?.postContent;
            this.showImages = true;
            this.coverPostContent = {
              content: coverPost.url,
              format: coverPost.media_type
            };
            if (post?.data[0]?.postContent?.length > 1) {
              let otherPostImages = post.data[0].postContent.filter(
                (x: any) => x.cover_type != 'true'
              );
              otherPostImages.forEach((image: any) => {
                this.postImages.push({
                  content: image.url,
                  format: image.media_type
                });
              });
              this.uploadPost.get('images').controls = otherPostImages;
            }
            if (post.data[0].campaign_post) {
              this.isCampaign.nativeElement.checked = true;
              this.sendAs(this.isCampaign);
            }
          }
        });
    }
    this.getHashTags();
    this.features = ['Photo Content'];
    this.getMyPlans();
    this.uploadForm.valueChanges.subscribe(form => {
      if (
        // form.title !== this.post?.title ||
        form.description !== this.post?.description ||
        form.asCampaign !== this.post?.campaign_post ||
        this.usertags?.toString() !== this.post?.category?.toString() ||
        this.disclaimers?.toString() !== this.post?.desclaimer?.toString() ||
        form.sendTo?.toString() !== this.post?.send_to?.toString()
      ) {
        this.savePost = false;
      } else {
        this.savePost = true;
      }
    });
  }

  mentionSelect(item: any) {
    this.searchByUser = item.user_name;
  }

  triggerFunction(event: any) {
    let extractComment = this.uploadForm.get('description')?.value.split(' ');
    let mentionUser = extractComment[extractComment.length - 1];
    if (mentionUser.includes('@') && event.keyCode !== 40 && event.keyCode !== 38) {
      let searchUser = mentionUser.replace('@', '');
      let params = {
        keyword: searchUser
      };
      this.postService
        .getMentionedCandidate(params)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.mentionUsers = res.data;
        });
    }
    // if (event.ctrlKey && event.key === 'Enter') {
    //   let comment = this.uploadForm.get('description')?.value;
    //   comment += '\n';
    //   this.uploadForm.get('description')?.setValue(comment);
    // } else if (event.key === 'Enter') {
    //   event.preventDefault();
    //   this.submitForm();
    // }
  }
  uploadProgress: number = 0;

  uploadFile(files: FileList, index: number) {
    let videos: any = [];
    this.spinner.show();

    let format: string;
    if (files.length === 0) {
      this.spinner.hide();
      return;
    }
    if (files.length > 4) {
      this.snack.showMessage('You can only upload a maximum of 4 files.', true);
      this.spinner.hide();
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes('video') && this.selected.value === 3) {
        this.snack.showMessage('Please select Image File', true);
        this.spinner.hide();
        return;
      } else if (
        files[i].type.includes('image') &&
        (this.selected.value === 0 || this.selected.value === 1 || this.selected.value === 2)
      ) {
        this.snack.showMessage('Please select Video File', true);
        this.spinner.hide();
        return;
      }

      if (files[i].type.includes('image') || files[i].type.includes('video')) {
        var reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (event: any) => {
          if (
            (files[i].size < 10485760 && files[i].type.includes('image')) ||
            files[i].type.includes('video')
          ) {
            if (files[i].type.indexOf('image') > -1) {
              format = this.contentTypes[this.selected.value ?? 3].id;
              if (i === 0) {
                this.coverPostContent = {
                  content: event.target.result !== null ? event.target.result : undefined,
                  format
                };
              } else {
                this.postImages[i - 1] = {
                  content: event.target.result !== null ? event.target.result : undefined,
                  format
                };
              }
              const file: File = files[i];
              this.postImagesPath[i] = {
                content: event.target.result,
                format
              };
              const newImagePath = {
                cover_type: i == 0 ? 'true' : 'false',
                media_type: format,
                url: event.target.result,
                file
              };
              this.allImages.push(newImagePath);
              this.spinner.hide();

              const formImagesControl = <FormArray>this.uploadPost.get('images');
              const control = new FormControl(null, Validators.required);
              if (formImagesControl.length < 5 && i == formImagesControl.length) {
                formImagesControl.push(control);
              }
            } else if (files[i].type.indexOf('video') > -1) {
              this.videoFileUploading = true;
              format = this.contentTypes[this.selected.value ?? 2].id;
              const file = files[i];
              const reader = new FileReader();
              this.spinner.show();
              this.uploadProgress = 0;
              reader.onprogress = event => {
                if (event.lengthComputable) {
                  this.uploadProgress = (event.loaded / event.total) * 100;
                }
              };
              let errorMessage = '';
              reader.onload = (event: any) => {
                const videoBlob = event.target.result;
                const videoElement = document.createElement('video');
                videoElement.src = URL.createObjectURL(file);
                videoElement.onloadedmetadata = () => {
                  if (
                    Math.floor(videoElement.duration) >
                    this.contentTypes[this.selected.value ?? 2].duration
                  ) {
                    this.snack.showMessage(
                      `Please Upload video of ${
                        this.contentTypes[this.selected.value ?? 1].duration
                      } Seconds`,
                      true
                    );
                    this.videoFileUploading = false;

                    this.spinner.hide();
                    return;
                  }

                  let maxSize = 4294967296; // 4 GB
                  if (file.size > maxSize) {
                    this.snack.showMessage(
                      `Please Upload video under ${this.bytesToGigabytes(maxSize)} GB`,
                      true
                    );
                    this.videoFileUploading = false;

                    this.spinner.hide();
                    return;
                  }

                  if (i === 0) {
                    this.coverPostContent = {
                      content: videoBlob,
                      format
                    };
                  } else {
                    this.postImages[i - 1] = {
                      content: videoBlob,
                      format
                    };
                  }

                  const postImagePath = {
                    content: videoBlob,
                    format
                  };
                  const newImagePath = {
                    cover_type: i === 0 ? 'true' : 'false',
                    media_type: format,
                    url: videoBlob,
                    file
                  };
                  this.postImagesPath.push(postImagePath);
                  videos.push(newImagePath);

                  // this.allImages.push(newImagePath);
                  this.spinner.show();
                  // Calculate the total file size
                  this.totalFileSize = this.allImages.reduce(
                    (acc, item) => acc + (item?.file?.size || 0),
                    0
                  );
                  let promises: any[] = [];
                  videos.forEach(async (item: any, index: any) => {
                    if (item?.file) {
                      promises.push(this.convertImages(item, index));
                    }
                  });
                  this.startFakeProgress();
                  Promise.all(promises).then(() => {
                    if (!this.authService.loggedIn()) {
                      this.snack.showMessage('Please login to Post', true);
                      this.spinner.hide();
                      setTimeout(() => {
                        this.isProcessing = false;
                      }, 1500);
                      return;
                    }
                    this.videoUploadedStatus = 'Completed';
                    this.snack.showMessage('Upload complete!', false);
                    this.allImages = videos;
                    this.spinner.hide();
                  });
                };

                videoElement.onerror = e => {
                  console.error('Video loading error:', e);
                  errorMessage =
                    'Error loading video. Please ensure the video format is supported.';
                  this.uploadProgress = 0;
                  this.snack.showMessage(errorMessage, true);
                  this.spinner.hide();
                };
              };

              reader.onerror = error => {
                console.error('FileReader error:', error);
                errorMessage = 'Error reading file. Please try again.';
                this.snack.showMessage(errorMessage, true);
                this.uploadProgress = 0;
                this.spinner.hide();
              };

              reader.readAsDataURL(file);
            }
          } else {
            this.snack.showMessage(`Size exceeded`, true);
            this.spinner.hide();
          }
        };
      } else {
        this.snack.showMessage('File Type Not Supported', true);
        this.spinner.hide();
        return;
      }
      if (i + 1 == files.length) {
        setTimeout(() => {
          this.showImages = true;
        }, 50);
      }
    }
  }

  bytesToMegabytes(bytes: number) {
    return bytes / (1024 * 1024);
  }
  bytesToGigabytes(bytes: number) {
    return bytes / (1024 * 1024 * 1024);
  }

  public errorHandling = (control: string, error: string) => {
    return this.uploadForm.controls[control].hasError(error);
  };

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (filterValue !== '') {
      this.usertags.forEach((interest: any) => {
        this.hashTags = this.hashTags.filter(item => item !== interest);
      });
      return this.hashTags
        .filter(tag => tag.toLowerCase().includes(filterValue.toLowerCase()))
        .map(tag => tag);
    }
    return this.hashTags;
  }

  setInterest(interest: string) {
    if (this.usertags.length < 10) {
      this.hashTags = this.hashTags.filter(item => item !== interest);
      this.usertags.push(interest);
      this.uploadForm.get('interest')?.setValue('');
      this.checkUserTags();
    } else {
      this.snack.showMessage('you cannot select more than 10 tags', false);
    }
  }

  setDisclaimer() {
    if (
      this.uploadForm.get('desclaimer')?.value.length > 0 &&
      this.uploadForm.get('desclaimer')?.value.replace(/\s/g, '')
    ) {
      if (
        this.disclaimers.find(
          x =>
            x.toLowerCase().trim() === this.uploadForm.get('desclaimer')?.value.toLowerCase().trim()
        )
      ) {
        this.snack.showMessage('Disclaimer Already Exist', true);
        return;
      }
      this.disclaimers?.push(this.uploadForm.get('desclaimer')?.value);
      this.uploadForm.get('desclaimer')?.setValue('');
      this.checkDesclaimer();
    }
  }

  removeInterest(interest: string) {
    this.usertags = this.usertags.filter(item => item !== interest);
    this.hashTags.push(interest);
    this.checkUserTags();
  }

  removeDisclaimer(desclaimer: string) {
    this.disclaimers = this.disclaimers.filter(item => item !== desclaimer);
    this.checkDesclaimer();
  }

  checkUserTags() {
    if (this.usertags.toString() == this.post?.category.toString()) {
      this.savePost = true;
    } else {
      this.savePost = false;
    }
  }

  checkDesclaimer() {
    if (this.disclaimers.toString() == this.post?.desclaimer.toString()) {
      this.savePost = true;
    } else {
      this.savePost = false;
    }
  }

  counter(i: number) {
    return new Array(i);
  }

  getHashTags() {
    this.profileService
      .skills()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.hashTags0 = res.data;
        this.hashTags = this.hashTags0.map(tag => tag.name);
      });
  }

  async convertImages(item: any, index: any) {
    await new Promise(async resolve => {
      await this.imageService
        .imageConvertion(new ImageSnippet(item.url, item.file))
        .then((imageUri: any) => {
          this.postImagesPath[index] = {
            content: imageUri[0],
            format: item.media_type
          };
          const newImagePath = {
            cover_type: index == 0 ? 'true' : 'false',
            media_type: item.media_type,
            url: imageUri[0]
          };
          this.allImages[index] = newImagePath;
          this.savePost = false;
        });
      resolve(true);
    });
  }

  async postContent() {
    this.spinner.show();
    this.isProcessing = true;
    if (this.selected.value !== 3) {
      this.proceedToUploadPost();
    } else {
      // Calculate the total file size
      this.totalFileSize = this.allImages.reduce((acc, item) => acc + (item?.file?.size || 0), 0);
      let promises: any[] = [];
      this.allImages.forEach(async (item: any, index: any) => {
        if (item?.file) {
          promises.push(this.convertImages(item, index));
        }
      });
      this.startFakeProgress();
      Promise.all(promises).then(() => {
        if (!this.authService.loggedIn()) {
          this.snack.showMessage('Please login to Post', true);
          this.spinner.hide();
          setTimeout(() => {
            this.isProcessing = false;
          }, 1500);
          return;
        }
        this.proceedToUploadPost();
      });
      setTimeout(() => {
        this.dialogRef.close();
      }, 200);
    }
  }

  proceedToUploadPost() {
    setTimeout(() => {
      this.dialogRef.close();
    }, 200);
    if (this.uploadForm.valid) {
      // const title = this.uploadForm.value['title'];
      const description = this.uploadForm.value['description'];
      const campaign_post =
        this.uploadForm.value['asCampaign'] || this.data?.isCampaign ? true : false;
      const send_to =
        this.isCampaignPost || this.data?.isCampaign ? this.uploadForm.value['sendTo'] : [];
      const desclaimer = this.disclaimers;
      const category = this.usertags
        .map(name => this.hashTags0.find(tag => tag.name === name))
        .map(tag => tag?._id);
      const allImages = this.allImages;
      const images = this.postImagesPath
        .filter((post: any) => post.content !== '')
        .map((post: any, index: any): any => {
          return {
            url: post.content,
            cover_type: index === 0,
            media_type: post.format,
            order_by: index
          };
        });
      const mentiondUsers = [];
      let match: any = description?.match(/(^|\s)\@(\w+)/g)?.map((v: any) => {
        return v.trim();
      });
      for (let index = 0; index < match?.length; index++) {
        const element = match[index];
        const name = element.split('@');
        mentiondUsers.push(name[1]);
      }
      let payload = {
        profile_id: this.data?.isModeratorMode ? this.data.profile_id : null,
        description: this.extractDescription(description),
        desclaimer,
        category: this.extractHashtags(description),
        campaign_post,
        images,
        visibility: this.visibility,
        send_to: !this.checkIfAllPlansSelected() ? ['All'] : this.getTrueKeys(),
        mentiondUsers: mentiondUsers
      };
      if (this.data.editmode && this.data.postId) {
        let editPayload = {
          description: this.extractDescription(description),
          desclaimer,
          category: this.extractHashtags(description),
          campaign_post,
          images: allImages,
          send_to,
          _id: this.post._id
        };
        this.postService
          .editPost(editPayload)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            () => {
              this.snack.showMessage('Post Updated', false);
              this.dialogRef.close(editPayload);
              this.isProcessing = false;
              this.spinner.hide();
            },
            () => {
              this.isProcessing = false;
              this.snack.showMessage('Failed to Update Post.', true);
              this.spinner.hide();
            }
          );
      } else {
        this.spinner.show();
        this.postService
          .uploadPost(payload)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res) {
              if (res.status == 'Failed') {
                this.increaseProgressTo100(); // Ensure progress is set to 100% on fail
                this.isProcessing = false;
                this.spinner.hide();
                this.snack.showMessage(res.message, true);
                return;
              }
              if (!this.isCampaignPost && !this.data.isCampaign) {
                // setTimeout(() => {
                this.snack.showMessage(
                  this.data.isCampaign || this.isCampaignPost
                    ? 'Campaign Post successfully Created.'
                    : 'Post successfully Created.',
                  false
                );
                this.unsubscribe$.next(true);
                this.unsubscribe$.complete();
                this.spinner.hide();
                this.increaseProgressTo100([
                  routesConst.community + '/' + 'post' + '/' + res?.postResult?._id
                ]); // Ensure progress is set to 100% on success
                // setTimeout(() => {

                //   this.router.navigate([
                //     routesConst.community + '/' + 'post' + '/' + res?.postResult?._id
                //   ]);
                // }, 500);
                // }, 7000);
              }
            }
            () => {
              this.increaseProgressTo100(); // Ensure progress is set to 100% on success

              this.isProcessing = false;
              this.snack.showMessage('Error while creating post Please try again later...', true);
              this.spinner.hide();
            };
          });
      }
    }
  }

  getTrueKeys() {
    return Object.keys(this.checkboxValues).filter(key => this.checkboxValues[key]);
  }

  change(event: any) {
    if (event.checked) {
      this.selectedPlan.push(event.source.value);
    } else {
      let index = this.selectedPlan.findIndex((x: any) => x == event.source.value);
      if (index > -1) {
        this.selectedPlan.splice(index, 1);
      }
    }
    this.uploadForm.controls['sendTo'].setValue(this.selectedPlan);
  }

  onCheckboxChange(value: string, event: any): void {
    if (event.checked) {
      this.visibility.push(value);
    } else {
      this.visibility = this.visibility.filter(
        (item) => item !== value
      );
    }
  }

  getMyPlans() {
    this.userPlan
      .mineSubscribedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.data?.length) {
          this.myPlans = plans.data.filter((item: any) => {
            return item?.packages?.length;
          });
        }
      });
  }

  sendAs(event: any) {
    if (event?.target?.checked || event?.nativeElement?.checked) {
      event.target.checked = false;
      this.profileService.getpurchasedPlan().subscribe((res: any) => {
        const userCurrentPlan = res.currentPlan;

        if (
          userCurrentPlan?.plan_type == planTypeConstant.elite ||
          userCurrentPlan?.plan_type == planTypeConstant.nil ||
          userCurrentPlan?.plan_type == planTypeConstant.enterprise
        ) {
          event.target.checked = true;
          this.isCampaignPost = true;
          this.uploadForm.controls['asCampaign'].setValue(true);
        } else {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Elite or Enterprise Members`,
              header: 'Send Subscription'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.close();
              this.router.navigate(['/' + routesConst.pricing]);
            }
          });
        }
      });
    } else {
      this.isCampaignPost = false;
      this.uploadForm.controls['asCampaign'].setValue(false);
    }
  }

  close() {
    if (this.postImagesPath.length > 0) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: {
          message: `Are you sure you want to discard your changes?`,
          header: 'Discard Changes'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.spinner.show();
          const key = this.postImagesPath[0].content.split('/').pop();
          const payload = { key: key, bucketName: 'jobpros-comppressed-media' };
          this.postService
            .removeS3Object(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
              this.spinner.hide();
              this.dialogRef.close();
            });
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  sendTo(label: any) {
    this.viewOption = 1;
    let sub = [];
    sub.push(label);
    this.uploadForm.controls['sendTo'].setValue(sub);
    this.checkboxValues = Object.fromEntries(
      Object.entries(this.checkboxValues).map(([key, value]) => [key, true])
    );
  }

  preview(image: any, index: any) {
    this.postImages[index] = this.coverPostContent;
    this.coverPostContent = image;
  }

  goToNext(stepper: MatStepper) {
    if (!this.isLastStep) {
      this.isLastStep = true;
      stepper.next();
    }
  }

  goToPrev(stepper: MatStepper) {
    this.isLastStep = false;
    stepper.previous();
  }

  getArray(size: number) {
    let array = [0];
    for (let i = 1; i < size; i++) {
      array.push(i);
    }
    return array;
  }

  resetImages() {
    this.allImages = [];
    this.postImages = [];
    this.coverPostContent = {
      content: undefined,
      format: ''
    };
    // if select the photo then steper-size assignee 4 other wise vidoes select stepper size 1
    this.steperSize = this.selected.value == 3 ? 4 : 1;
    if (this.selected.value === 0) {
      this.features = ['Photo Content'];
    } else if (this.selected.value === 4) {
      this.features = ['Photo Content', 'Video Content'];
    } else {
      this.features = ['Video Content'];
    }
    this.getMyPlans();
  }

  checkFeatures(planFeatures: any) {
    return !planFeatures.some((f: any) => this.features.includes(f));
  }

  clearFileInput(fileInput: HTMLInputElement) {
    fileInput.value = '';
  }

  // extractHashtags from string and return hashtags array
  extractHashtags(inputString: any) {
    const regex = /#[A-Za-z0-9_]+/g;
    const matches = inputString.match(regex);
    if (matches) {
      return matches;
    } else {
      return [];
    }
  }

  // remove hashtags from string and return description
  extractDescription(inputString: any) {
    const regex = /#[A-Za-z0-9_]+/g;
    const matches = inputString.match(regex);
    return matches ? inputString.replace(regex, '').trim() : inputString.trim();
  }

  removeImage(index: number, event: any, type = ' image') {
    if (type === 'video') {
      this.uploadProgress = 0;
      this.videoUploadedStatus = 'Processing';
      const key = this.postImagesPath[index].content.split('/').pop();
      const payload = { key: key, bucketName: 'jobpros-comppressed-media' };
      this.postService
        .removeS3Object(payload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {});
    }
    this.postImagesPath.splice(index, 1);
    this.allImages.splice(index, 1);

    event.preventDefault();
  }

  uploadButtonClick(): void {
    this.file.nativeElement.click();
  }

  checkIfAllPlansSelected() {
    const isAnyCheckboxUnchecked = Object.values(this.checkboxValues).some(value => !value);
    return isAnyCheckboxUnchecked;
  }

  private startFakeProgress() {
    let progress = 0;

    interval(200)
      .pipe(
        takeUntil(this.unsubscribe$),
        takeWhile(() => progress < 95), // Stop at 95% to wait for actual completion
        map(() => {
          progress += this.calculateIncrement(progress);
          return progress;
        })
      )
      .subscribe(value => {
        this.progressSubject.next(value);
      });
  }

  private calculateIncrement(currentProgress: number): number {
    // Determine the total progress to be made based on total file size
    const totalIncrement = 100 - currentProgress;
    const sizeRatio = this.totalFileSize ? totalIncrement / this.totalFileSize : 0;

    if (currentProgress < 10) {
      return Math.min(sizeRatio * 40000, 1); // Very slow progress at the very beginning
    } else if (currentProgress < 20) {
      return Math.min(sizeRatio * 25000, 2); // Slow progress between 10% and 20%
    } else if (currentProgress < 30) {
      return Math.min(sizeRatio * 50000, 3); // Slightly faster progress between 20% and 30%
    } else if (currentProgress < 40) {
      return Math.min(sizeRatio * 40000, 4); // Faster progress between 30% and 40%
    } else if (currentProgress < 50) {
      return Math.min(sizeRatio * 45000, 5); // Moderate progress between 40% and 50%
    } else if (currentProgress < 60) {
      return Math.min(sizeRatio * 20000, 6); // Faster progress between 50% and 60%
    } else if (currentProgress < 70) {
      return Math.min(sizeRatio * 15000, 7); // Moderate progress between 60% and 70%
    } else if (currentProgress < 80) {
      return Math.min(sizeRatio * 12000, 8); // Slower progress between 70% and 80%
    } else if (currentProgress < 90) {
      return Math.min(sizeRatio * 9000, 9); // Slow progress between 80% and 90%
    } else if (currentProgress < 95) {
      return Math.min(sizeRatio * 3000, 10); // Very slow progress between 90% and 95%
    } else {
      return 0; // No additional progress until the final step
    }
  }

  private increaseProgressTo100(route?: any) {
    let progress = this.progressSubject.value; // Get the current progress
    const increment = 1; // Small increment to reach 100%

    interval(1)
      .pipe(
        takeUntil(this.unsubscribe$),
        takeWhile(() => progress < 100), // Continue until 100%
        map(() => {
          progress = Math.min(100, progress + increment); // Ensure progress doesn't exceed 100%
          return progress;
        })
      )
      .subscribe(value => {
        this.progressSubject.next(value);
        if (value == 100 && route) {
          this.dialogRef.close();
          this.router.navigate(route);
          this.isProcessing = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
    // this.unsubscribe$.next(true);
    // this.unsubscribe$.complete();
  }
}
