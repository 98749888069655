<form [formGroup]="form" (ngSubmit)="updatePreferences()">

  <div class="section-wrapper lg:p-10">
    <div class="mb-1">
      <p class="montserrat-medium">APPLICATION REUIREMENTS</p>
    </div>
    <div class="mb-2">
      <p class="s black-light">Specify the type of applications you want to receive</p>
    </div>
    <div class="mb-4">
      <mat-divider></mat-divider>
    </div>
    <div class="container">
      <div class="flex requirements mb-4">
        <div class="pr-3">
          <label class="switch">
            <input type="checkbox" checked formControlName="required_bio" />
            <span class="slider round"></span>
          </label>
        </div>
        <div class="col-11">
          <div class="mb-1">
            <p class="montserrat-medium">Require bio</p>
          </div>
          <div class="mb-2">
            <p class="s black-light">Only people with bio can apply to your jobs.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="flex requirements mb-4">
        <div class="pr-3">
          <label class="switch">
            <input type="checkbox" checked formControlName="required_portfolio" />
            <span class="slider round"></span>
          </label>
        </div>
        <div>
          <div class="mb-1">
            <p class="montserrat-medium">Require portfolio</p>
          </div>
          <div class="mb-2">
            <p class="s black-light">Only people with portfolios can apply to your jobs.</p>
          </div>
        </div>
      </div>
      <div class="flex requirements mb-4">
        <div class="pr-3">
          <label class="switch">
            <input type="checkbox" checked formControlName="required_experience" />
            <span class="slider round"></span>
          </label>
        </div>
        <div>
          <div class="mb-1">
            <p class="montserrat-medium">Require highligthed experience</p>
          </div>
          <div class="mb-2">
            <p class="s black-light">Require applicants to select up to 5 pieces of their work when submitting
              applications.</p>
          </div>
        </div>
      </div>

      <div class="flex requirements mb-4">
        <div class="pr-3">
          <label class="switch">
            <input type="checkbox" checked formControlName="required_pitch" />
            <span class="slider round"></span>
          </label>
        </div>
        <div>
          <div class="mb-1">
            <p class="montserrat-medium">Require pitch</p>
          </div>
          <div class="mb-2">
            <p class="s black-light">Require applicants to write a pitch when submitting applications.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="ml-auto">
      <button mat-button type="submit" class="button outlined black">Save Changes </button>
    </div>
  </div>
</form>