import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { planTypeConstant, statusConstant, userTypeConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { JobService } from 'src/app/core/services/job.service';
import { PostService } from 'src/app/core/services/post.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { JobModalComponent } from 'src/app/shared/components/job-modal/job-modal.component';
import { JobPostComponent } from 'src/app/shared/components/job-post/job-post.component';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { PostDetailCardComponent } from 'src/app/shared/components/post-detail-card/post-detail-card.component';
import { ServiceModalComponent } from 'src/app/shared/components/service-modal/service-modal.component';
import { SubscriptionPackageModalComponent } from 'src/app/shared/components/subscription-package-modal/subscription-package-modal.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-multimedia-display',
  templateUrl: './multimedia-display.component.html',
  styleUrls: ['./multimedia-display.component.scss']
})
export class MultimediaDisplayComponent implements OnInit {
  postStoryFilters = { offset: 0, limit: 15 };
  private unsubscribe$ = new Subject();
  routesConst = routesConst;
  userTypeConstant = userTypeConstant;
  jobs: [] = [];
  talents: any[] = [];
  posts: any[] = [];
  profile: any;
  Refferal: any = [
    {
      title: 'Referral Program',
      description:
        'Refer connections to our platform & earn cash each time they pay their memberships. Send messages to your friends, family, and business partners.'
    },
    {
      title: 'Earn 10% Rewards',
      description:
        'Earn 10% cash reward each time your referee pays their memberships. See all your referrals within your network displayed on referral dashboard.'
    },
    {
      title: 'Lets Grow Together',
      description:
        'Get an individual or company member referral plan then copy your link. You receive cash rewards as appreciation for sharing your community and network.'
    }
  ];
  userType: string = userTypeConstant.company;
  mySubscriptions: any;

  myPlans: any;
  postFilters = { offset: 0, limit: 3 };

  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  };

  innerWidth = this.commonService.getWindow()?.innerWidth;
  // members: any = [];
  followings: any = [];
  followers: any = [];
  userPreferences: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = this.commonService.getWindow()?.innerWidth;
  }
  stories: any = [];
  constructor(
    private userPlan: userPlans,
    public _jobsService: JobService,
    private talentService: TalentService,
    private postService: PostService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private authService: AuthService,
    private profileService: ProfileService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private snack: SnackBarService,
    private configuration: ConfigurationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getUserFeedData();
    this.getAllJobs();
    this.getTalents();
    // this.getMembers();
    this.getPosts();
    this.getPlans(this.postFilters);
    if (this.authService.loggedIn()) {
      this.dashboard();
      // this.getLoginedUserType();
      this.getPlateformPlans();
      this.getFollowersAndFollowings();
      this.getNetworkPosts();
    } else {
      this.getPopularPosts();
    }
  }

  getUserFeedData() {
    this.sharedService.userFeedData.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res === true) {
        this.getCandidateJobPreferences();
        this.getAllFeedPost();
      } else {
        this.getAllJobs();
        this.getPosts();
      }
    });
  }

  getNetworkPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getNetworkPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getPopularPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getPopularPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(network => {
        if (network?.followings || network?.followers) {
          this.followings = network.followings;
          this.followers = network.followers;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  // getLoginedUserType() {
  //   this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
  //     this.userType = type;
  //     if (type === '') {
  //       this.sharedService.getUserType();
  //     }
  //   });
  // }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.profile = profileInfo;
        this.userType = profileInfo.user_type;
      }
    });
  }

  getAllJobs() {
    this._jobsService
      .getAllJobs({ offset: 0, limit: 3 })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.jobs = res.data;
      });
  }

  getTalents() {
    this.talentService
      .getAllTalents({ offset: 0, limit: 3 })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.talents = res?.data;
      });
  }

  getPosts() {
    this.postService
      .getAllPost({ offset: 0, limit: 4 })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'Success') {
          this.posts = res.data;
        }
      });
  }

  jobDetail(jobId: any) {
    const dialogRef = this.dialog.open(JobModalComponent, {
      width: innerWidth && innerWidth > 768 ? '650px' : '100vw',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      data: {
        id: jobId
      }
    });
  }

  getPlans(payload: any) {
    this.userPlan
      .publicAllPlans(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.plans) {
          if (this.postFilters.offset === 0) this.myPlans = plans.plans;
          else
            this.myPlans = [
              ...this.myPlans,
              ...plans.plans.map((plan: any) => ({
                ...plan,
                isMore: true,
                isBigger: plan.description.length > 140
              }))
            ];
          // this.myPlans = [...this.myPlans, ...plans.plans.map((plan: any) => ({
          //   ...plan,
          //   isMore: true,
          //   isBigger: plan.description.length > 140,
          // }))];
        }
      });
  }

  navigateToUrl(url: any, queryParams?: any){
    this.router.navigate([url], {queryParams} );
  }
  navigateTo(openDialog: any) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'signup' }
      });
    }
     else {
      if (openDialog == routesConst.content) {
        this.dialog.open(UploadPostModalComponent, {
          maxWidth: '100vw',
          width: '536px',
          disableClose: true,
          height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
          data: {}
        });
      }

      switch (openDialog) {
        case routesConst.subscription:
          this.newSubscriptionPackage();
          break;
        case routesConst.jobs:
          this.checkEligibility();
          break;
        case routesConst.talents:
          this.addNewService();
          break;
        default:
          break;
      }
    }
  }

  checkEligibility(): void {
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToAddJob();
        }
      });
  }

  proceedToAddJob() {
    this.spinner.show();
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.spinner.hide();
          if (!res.currentPlan) {
            this.snack.showMessage('Please purchase a paid plan to post job', true);
            this.router.navigate(['/' + routesConst.planPricing]);
            return;
          }
          if (res.currentPlan.plan_type == planTypeConstant.recruiter) {
            this._jobsService
              .checkEligibility()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.result) {
                  const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                      message: `Requires membership upgrade to BUSINESS, ENTERPRISE or NON-PROFIT`,
                      header: 'Unlimited Job Posting'
                    }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/' + routesConst.planPricing]);
                    }
                  });
                } else {
                  this.openJobPostDialog();
                }
              });
          } else {
            this.openJobPostDialog();
          }
        }
      });
  }

  openJobPostDialog() {
    this.dialog.open(JobPostComponent, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      hasBackdrop: false,
      panelClass: 'post-job-dialog-container'
    });
  }

  addNewService() {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        this.spinner.hide();
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewService();
        }
      });
  }

  proceedToNewService() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    const plan1 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.business;
    const plan2 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.pro
        : planTypeConstant.enterprise;
    if (
      this.mySubscriptions?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan1.toLowerCase()
      ) != -1 ||
      this.mySubscriptions?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan2.toLowerCase()
      ) != -1
    ) {
      this.configuration
        .configureCustomerPortal()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.spinner.hide();
            this.dialog.open(ServiceModalComponent, {
              maxWidth: '100vw',
              width: '536px',
              height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
              data: { editmode: false },
              disableClose: true
            });
          },
          (error: any) => {
            this.spinner.hide();
            this.snack.showMessage(`${error} `, true);
          }
        );
    } else {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: {
          message: `Requires membership upgrade to PRO, ELITE, BUSINESS or ENTERPRISE`,
          header: 'Create Expert Service'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/' + routesConst.planPricing]);
        }
      });
      this.spinner.hide();
    }
  }

  getPlateformPlans() {
    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plan: any) => {
        const { platform_subscription } = plan;
        this.mySubscriptions = platform_subscription;
      });
  }

  newSubscriptionPackage() {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          this.spinner.hide();
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewSubscriptionPackage();
        }
      });
  }

  proceedToNewSubscriptionPackage() {
    let comparePlan =
      this.userType == userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.enterprise;
    this.spinner.show();
    this.configuration
      .configureCustomerPortal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (
          this.mySubscriptions?.findIndex(
            (item: any) => item.plan_type.toLowerCase() == comparePlan.toLowerCase()
          ) != -1 ||
          (this.userType === userTypeConstant.candidate &&
            this.mySubscriptions?.findIndex(
              (item: any) => item.plan_type.toLowerCase() == planTypeConstant.nil.toLowerCase()
            ) != -1)
        ) {
          const redirect_url = this.commonService.location().href;
          const refresh_url = this.commonService.location().href;
          this.userPlan
            .getStripeUser(redirect_url, refresh_url)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (user: any) => {
                this.spinner.hide();
                if (user.status == 'on_board_required') {
                  if (user?.accountLink?.url) {
                    this.commonService.location().href = user?.accountLink?.url;
                  }
                  this.snack.showMessage(`${user.message} `, true);
                } else {
                  this.dialog.open(SubscriptionPackageModalComponent, {
                    width: '536px',
                    height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
                    maxWidth: '100vw',
                    hasBackdrop: false
                  });
                }
              },
              error => {
                this.spinner.hide();
                this.snack.showMessage(`${error} `, true);
              }
            );
        } else {
          this.spinner.hide();
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`,
              header: 'Create Subscriptions'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        }
      });
  }

  // getMembers() {
  //   this.spinner.show();
  //   let payload = { offset: 0, limit: 3, userType: userTypeConstant.company };

  //   this.postService
  //     .getAllPostByCandidate(payload)
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe(
  //       (res: any) => {
  //         this.members = res?.data;
  //         this.spinner.hide();
  //       },
  //       () => {
  //         this.spinner.hide();
  //       }
  //     );
  // }

  getRelatedPosts(postId: string) {
    this.router.navigate([routesConst.community + '/' + 'post' + '/' + postId]);
  }

  onStorySlideChange(swiper: any) {
    this.postStoryFilters.offset = this.postStoryFilters.offset + 10;
    if (this.authService.loggedIn()) {
      this.getNetworkPosts(swiper);
    } else {
      this.getPopularPosts(swiper);
    }
  }

  getAllFeedPost() {
    this.postService
      .getAllFeedPost()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((posts: any) => {
        this.posts = posts.data[0].allPosts;
      });
  }

  getCandidateJobPreferences() {
    this._jobsService
      .getCandidateJobPreferences()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((candidate: any) => {
        if (candidate && candidate?.data) {
          this.userPreferences = {
            locationFilters: candidate?.data?.location[0]?._id
              ? candidate?.data?.location[0]?._id
              : null,
            categoryFilters: candidate?.data?.categories?.length
              ? candidate?.data?.categories
              : null,
            typesFilters: candidate?.data?.job_type?.length ? candidate?.data?.job_type : null,
            salaryFilters: candidate?.data?.desired_salary?.length
              ? candidate?.data?.desired_salary
              : null,
            experienceFilter: candidate?.data?.experience_level?.length
              ? candidate?.data?.experience_level
              : null
          };
          this.getJobsByFilters();
        }
      });
  }

  getJobsByFilters() {
    this.spinner.show();
    this._jobsService
      .getJobsByFilter(
        0,
        this.userPreferences.locationFilters,
        this.userPreferences.categoryFilters,
        this.userPreferences.typesFilters,
        this.userPreferences.salaryFilters,
        this.userPreferences.experienceFilter
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          this.jobs = response.data;
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  onPostViewLogged(data: any, postDetailCard: PostDetailCardComponent) {
    if (data?.success) {
      postDetailCard.onPostViewLogged(data);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
