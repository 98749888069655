import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { planTypeConstant, statusConstant, userTypeConstant } from 'src/app/const/appConst';
import { WorkFlowItemsConstants } from 'src/app/const/work-flow.constants';
import { routesConst } from 'src/app/core/const/routers';
import { UserPlan } from 'src/app/core/models';
import { WorkFlow } from 'src/app/core/models/work-flow';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { ServiceModalComponent } from 'src/app/shared/components/service-modal/service-modal.component';
import { SubscriptionPackageModalComponent } from 'src/app/shared/components/subscription-package-modal/subscription-package-modal.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-pakages-card',
  templateUrl: './pakages-card.component.html',
  styleUrls: ['./pakages-card.component.scss']
})
export class PakagesCardComponent implements OnInit {
  @Input() data!: WorkFlow[];
  private unsubscribe$ = new Subject();
  userType: any;
  jobprosPlan: any;

  platform_subscription: any;
  myPlans: UserPlan[] = [];

  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  };

  innerWidth = this.commonService.getWindow()?.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = this.commonService.getWindow()?.innerWidth;
  }

  constructor(
    private userPlan: userPlans,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private talentService: TalentService,
    private snack: SnackBarService,
    private configuration: ConfigurationService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {}

  /**
   * Navigate
   * @param workFlow
   * @returns
   */
  navigate(event: any, workFlow: WorkFlow) {
    const { type } = workFlow;
    switch (type) {
      case WorkFlowItemsConstants?.POST?.TYPE:
        if (!this.authService.loggedIn()) {
          this.loginSignUp();
          return;
        }
        this.uploadContent();
        break;
      // case WorkFlowItemsConstants?.AddServices?.TYPE:
      //   // if (!this.authService.loggedIn()) { this.loginSignUp(); return }
      //   // this.addService();
      //   break;
      default:
        this.router.navigate([workFlow.url]);
        break;
    }

    event.preventDefault();
  }

  uploadContent() {
    this.dialog.open(UploadPostModalComponent, {
      maxWidth: '100vw',
      width: '536px',
      disableClose: true,
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: {}
    });
  }

  openModal(item: any) {
    if (item?.type == WorkFlowItemsConstants?.GetSubscribers?.TYPE) {
      this.newSubscriptionPackage();
    }
    if (item?.type == WorkFlowItemsConstants?.AddServices?.TYPE) {
      this.addNewService();
    }
  }

  addNewService() {
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
                to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewService();
        }
      });
  }

  proceedToNewService() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    const plan1 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.business;
    const plan2 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.pro
        : planTypeConstant.enterprise;
    if (
      this.jobprosPlan?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan1.toLowerCase()
      ) != -1 ||
      this.jobprosPlan?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan2.toLowerCase()
      ) != -1
    ) {
      this.configuration
        .configureCustomerPortal()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.dialog.open(ServiceModalComponent, {
              maxWidth: '100vw',
              width: '536px',
              height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
              data: { editmode: false },
              disableClose: true
            });
          },
          (error: any) => {
            this.spinner.hide();
            this.snack.showMessage(`${error} `, true);
          }
        );
    } else {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: {
          message: `Requires membership upgrade to PRO, ELITE, BUSINESS or ENTERPRISE`,
          header: 'Create Expert Service'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/' + routesConst.planPricing]);
        }
      });
      this.spinner.hide();
    }
  }

  newSubscriptionPackage() {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          this.spinner.hide();
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
                to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewSubscriptionPackage();
        }
      });
  }

  proceedToNewSubscriptionPackage() {
    let comparePlan =
      this.userType == userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.enterprise;
    this.spinner.show();
    this.configuration
      .configureCustomerPortal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (
          this.platform_subscription?.findIndex(
            (item: any) => item.plan_type.toLowerCase() == comparePlan.toLowerCase()
          ) != -1 ||
          (this.userType === userTypeConstant.candidate &&
            this.platform_subscription?.findIndex(
              (item: any) => item.plan_type.toLowerCase() == planTypeConstant.nil.toLowerCase()
            ) != -1)
        ) {
          const redirect_url = this.commonService.location().href
          const refresh_url = this.commonService.location().href
          this.userPlan
            .getStripeUser(redirect_url, refresh_url)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (user: any) => {
                this.spinner.hide();
                if (user.status == 'on_board_required') {
                  if (user?.accountLink?.url) {
                    this.commonService.location().href = user?.accountLink?.url;
                  }
                  this.snack.showMessage(`${user.message} `, true);
                } else {
                  const dialogRef = this.dialog.open(SubscriptionPackageModalComponent, {
                    width: '536px',
                    height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
                    maxWidth: '100vw',
                    hasBackdrop: false
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.getMyPlans();
                    }
                  });
                }
              },
              error => {
                this.spinner.hide();
                this.snack.showMessage(`${error} `, true);
              }
            );
        } else {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`,
              header: 'Create Subscriptions'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
          this.spinner.hide();
        }
      });
  }

  getMyPlans() {
    this.userPlan
      .mineSubscribedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.data?.length) {
          this.myPlans = plans.data;
        }
      });
  }

  /**
   * Upload New Post
   */
  uploadPost() {
    this.dialog.open(UploadPostModalComponent, {
      maxWidth: '100vw',
      width: '536px',
      disableClose: true,
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: {}
    });
  }
  /**
   * Login or signup
   */
  loginSignUp() {
    this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px' : '100vw',
      height: window.innerWidth > 600 ? 'h-fit' : '100%',
      maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
      maxHeight: window.innerWidth > 600 ? '600' : '100vh',
      data: { pageToOpen: 'login' }
    });
  }
}
