import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { config } from '../../../config/config';
import * as S3 from 'aws-sdk/clients/s3';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from './common.service';
import { apiUrlConst } from 'src/app/const/apiConst';
import { environment } from 'src/environments/env';

@Injectable({
  providedIn: 'root'
})
export class S3UploaderService {
  private unsubscribe$ = new Subject();
  signingURI = config.environmentMode(this.commonService.location().hostname).endPoint + '/signing';

  constructor(public _http: HttpClient, private commonService: CommonService) {}

  // Function to check if video in second-bucket is ready
  checkVideoStatus(key: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('key', key);
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getS3MediaUploadStatus +
        '?' +
        params
    );
  }

  upload(imageURI: any, fileName: any, isVideo: boolean) {
    return new Promise((resolve, reject) => {
      this._http
        .post(this.signingURI, { fileName: fileName, isVideo: isVideo })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (data: any) => {
            const bucket = new S3({
              accessKeyId: data.access_key,
              secretAccessKey: data.secret_key,
              region: data.region
            });
            const params = {
              Bucket: data.bucket,
              Key: data.key,
              Body: imageURI,
              ACL: data.acl,
              Metadata: {
                environment: environment.bucketEnv
              }
            };
            bucket.upload(params, (_err: any, data: any) => {
              if (_err) {
                reject(_err);
              } else {
                const key = data.Key;
                const interval = setInterval(async () => {
                  try {
                    const status = await this.checkVideoStatus(key).toPromise();
                    if (status.ready) {
                      clearInterval(interval);
                      resolve(data);
                      return true;
                    }
                  } catch (error) {
                    console.error('Error checking video status:', error);
                  }
                }, 4000);
              }
            });
          },
          err => {
            reject(err);
          }
        );
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
