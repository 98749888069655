import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-plan-upgrade',
  templateUrl: './plan-upgrade.component.html',
  styleUrls: ['./plan-upgrade.component.scss'],
})
export class PlanUpgradeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
