import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-livestream-replay-card',
  templateUrl: './dashboard-livestream-replay-card.component.html',
  styleUrls: ['./dashboard-livestream-replay-card.component.scss']
})
export class DashboardLivestreamReplayCardComponent implements OnInit {
  @Input() data?: any;

  constructor() { }

  ngOnInit(): void { }

  getStreamImage(url: string) {
    if (url) {
      return url.replace('%{width}x%{height}', '256x165');
    }
    else {
      return url = "../../../../../../../../../assets/images/twitch.png"
    }
  }

  openVideo(url: any) {

  }
}
