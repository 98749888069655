import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UpdateEducationModalComponent } from 'src/app/modules/users/pages/profile/modals/update-education-modal/update-education-modal.component';
import { OperationHours } from 'src/app/const/appConst';
import { ContactDetailsModalComponent } from '../../modals/contact-details-modal/contact-details-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userTypeConstant } from 'src/app/const/appConst';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AchievementType } from 'src/app/core/const/options';
import { Achievement } from 'src/app/core/models';
import * as moment from 'moment';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-profile-about',
  templateUrl: './profile-about.component.html',
  styleUrls: ['./profile-about.component.scss'],
})
export class ProfileAboutComponent implements OnInit {
  userTypeConstant = userTypeConstant;
  private unsubscribe$ = new Subject();
  @Input() set user_name(name: any) {
    if (name) {
      this.getUserInfo(name);
    }
  }

  allExperienceLevel: any;
  userInfo: any;
  operationHours: any[] = OperationHours;
  userSkills: any = ([] = []);
  educations: any[] = [];
  workHistory: any[] = [];
  projects: any[] = [];
  expLevel: any;
  user: any;
  constructor(
    public dialog: MatDialog,
    private sharedService: SharedService,
    private profileService: ProfileService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getExperienceLevel();
  }

  getUserInfo(user_name: any) {
    this.profileService
      .getUserDetails(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        const { data } = user;
        this.user = data[0];
        this.userInfo = this.user;
        this.sortData();
      });
  }

  sortData() {
    this.userInfo?.operationHours.map((item: any) => {
      const idx = this.operationHours.findIndex(
        (oh: any) => oh.day_name === item.day_name
      );
      delete item.created_at;
      delete item.profile_id;
      delete item.__v;
      this.operationHours[idx] = {
        ...this.operationHours[idx],
        ...item,
      };
    });

    const achievements = this.userInfo?.profile?.achievements;
    if (achievements.length) {
      this.educations = this.commonService.sortAchievements(achievements.filter((a: Achievement) => a.type === AchievementType.Education))
      this.workHistory = this.commonService.sortAchievements(achievements.filter((a: Achievement) => a.type === AchievementType.WorkHistory))
      this.projects = this.commonService.sortAchievements(achievements.filter((a: Achievement) => a.type === AchievementType.ClientProject))
      this.userSkills = achievements.filter((a: Achievement) => a.type === AchievementType.Skill);
      this.expLevel = achievements.find((a: Achievement) => a.type === AchievementType.ExpLevel);

    }
  }

  update(id: string | undefined) {
    const dialogRef = this.dialog.open(UpdateEducationModalComponent, {
      width: '1040px',
      data: {
        educ: this.educations.find((educ) => educ._id === id),
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result.id === '-1') {
          if (!this.educations.length) {
            result.id === '0';
          } else if (this.educations.length > 0) {
            result.id = this.educations[this.educations.length - 1]._id;
            result.id = (parseInt(result.id) + 1).toString;
          }
          this.educations.push(result);
        } else {
          this.educations[
            this.educations.findIndex((item) => item._id === result.id)
          ] = result;
        }
      });
  }

  getExperienceLevel() {
    this.sharedService.experienceLevelList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((experience: any) => {
        if (experience.length) {
          this.allExperienceLevel = experience;
        } else {
          this.sharedService.getexperienceLevel();
        }
      });
  }

  contactDetails() {
    this.dialog.open(ContactDetailsModalComponent, {
      width: '400px',
      panelClass: 'custom-dialog-container',
      data: { user: this.user, operationHours: this.operationHours },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
