import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ImagePreviewModalComponent } from 'src/app/modules/individual-service/components/image-preview-modal/image-preview-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userTypeConstant, planTypeConstant, statusConstant } from 'src/app/const/appConst';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { ServiceModalComponent } from 'src/app/shared/components/service-modal/service-modal.component';
import { CommonService } from 'src/app/core/services/common.service';
import { routesConst } from 'src/app/core/const/routers';

@Component({
  selector: 'app-dashboard-services-services',
  templateUrl: './dashboard-services-services.component.html',
  styleUrls: ['./dashboard-services-services.component.scss']
})
export class DashboardServicesServicesComponent implements OnInit {
  reviews = [];
  @ViewChild('stepper') stepper!: MatStepper;
  routesConst = routesConst;
  userTypeConstant = userTypeConstant;
  planTypeConstant = planTypeConstant;
  private unsubscribe$ = new Subject();
  myServices: any[] = [];
  talentFilters = { offset: 0, limit: 10 };
  selectedService: any;
  slides?: any[];
  selected: number = 0;
  serviceId?: any;
  currentServiceData: any;
  currentServiceId: any;
  userType: any;
  jobprosPlan: any;
  isDashboardService = true;

  @Input() set user_type(user_type: string) {
    this.userType = user_type;
  }

  @Input() set platformPlans(plans: any[]) {
    this.jobprosPlan = plans;
  }

  @Input() set goBack(isBack: any) {
    if (isBack) {
      this.stepper.previous();
      this.isStepperOpen.emit(false);
    }
  }

  @Output() isStepperOpen: EventEmitter<any> = new EventEmitter();

  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private talentService: TalentService,
    private snack: SnackBarService,
    private configuration: ConfigurationService,
    private router: Router,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.getTalentService();
  }

  getTalentService() {
    this.talentService
      .dashboardTalentService(this.talentFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((services: any) => {
        const { data: service } = services;
        if (service) {
          this.myServices = [...this.myServices, ...service];
        }
      });
  }

  addNewService() {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        this.spinner.hide();
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewService();
        }
      });
  }

  proceedToNewService() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    const plan1 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.business;
    const plan2 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.pro
        : planTypeConstant.enterprise;
    if (
      this.jobprosPlan?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan1.toLowerCase()
      ) != -1 ||
      this.jobprosPlan?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan2.toLowerCase()
      ) != -1
    ) {
      this.spinner.show();
      this.configuration
        .configureCustomerPortal()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.spinner.hide();
            const dialogRef = this.dialog.open(ServiceModalComponent, {
              maxWidth: '100vw',
              width: '536px',
              height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
              data: { editmode: false },
              disableClose: true
            });
            dialogRef
              .afterClosed()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(result => {
                if (result?.data) {
                  this.myServices.push({
                    ...result.data,
                    portfolios: result.data.portfolio
                  });
                }
              });
          },
          (error: any) => {
            this.spinner.hide();
            this.snack.showMessage(`${error} `, true);
          }
        );
    } else {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: {
          message: `Requires membership upgrade to PRO, ELITE, BUSINESS or ENTERPRISE`,
          header: 'Create Expert Service'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/' + routesConst.planPricing]);
        }
      });
      this.spinner.hide();
    }
  }

  onScrollDown() {
    this.talentFilters.offset = this.talentFilters.offset + 10;
    this.getTalentService();
  }

  showDetails(service: any, stepper: MatStepper) {
    this.commonService.getWindow()?.scroll(0, 0);
    this.currentServiceId = service._id;
    this.currentServiceData = service;
    stepper.next();
    this.selectedService = service;
    this.selectedService.packages.map((pkg: any) => {
      (pkg.title = pkg.name),
        (pkg.price = pkg.price),
        (pkg.description = pkg.description),
        (pkg.features = pkg.offers.split(',')),
        (pkg.price_id = pkg.price_id),
        (pkg.billing_type = pkg.billing_type);
    });

    this.reviews = this.selectedService.purchasedDetails
      .filter((item: any) => item.feedback)
      .map((item: any) => {
        const { feedback } = item;
        return {
          name: item.buyerDetails.name,
          imageUrl: item.buyerDetails.profile_img_path,
          rating: feedback.rating,
          comment: feedback.comment,
          created_at: feedback.created_at
        };
      });

    this.slides = this.selectedService?.portfolios.map((portfolio: any) => {
      return { path: portfolio.url };
    });
    this.isStepperOpen.emit(true);
  }

  prevService() {
    this.dialog.open(ImagePreviewModalComponent, {
      data: { service: this.selectedService, selected: this.selected }
    });
  }

  prev(e: any) {
    e.stopPropagation();
    if (this.slides) {
      this.selected = Math.abs(this.selected - 1 + this.slides.length) % this.slides.length;
    }
  }

  next(e: any) {
    e.stopPropagation();
    if (this.slides) {
      this.selected = (this.selected + 1) % this.slides.length;
    }
  }

  activeInactiveTalentService(isActive: any) {
    const plan1 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.business;
    const plan2 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.pro
        : planTypeConstant.enterprise;
    if (
      this.jobprosPlan?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan1.toLowerCase()
      ) != -1 ||
      this.jobprosPlan?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan2.toLowerCase()
      ) != -1
    ) {
      let payload = {
        _id: this.currentServiceId,
        is_active: isActive
      };
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: {
          message: `Are you sure to ${isActive ? 'Activated' : ' deactivate'} this service?`
        }
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(result => {
          if (result) {
            this.talentService
              .patchService(payload)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                () => {
                  this.selectedService.is_active = isActive;
                  this.snack.showMessage(
                    this.selectedService.is_active
                      ? 'Service Activated Sucessfully'
                      : 'Service Deactivated Sucessfully',
                    false
                  );
                },
                () => {
                  this.snack.showMessage(
                    `Failed to ${
                      this.selectedService.is_active ? ' Active ' : ' Delete '
                    } Selected Service`,
                    true
                  );
                }
              );
          }
        });
    } else {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: {
          message: `Requires membership upgrade to PRO, ELITE, BUSINESS or ENTERPRISE`,
          header: 'Create Expert Service'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/' + routesConst.planPricing]);
        }
      });
      this.spinner.hide();
    }
  }

  editService(service_id: any) {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    this.talentService
      .getSingleTalentService(service_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        const dialogRef = this.dialog.open(ServiceModalComponent, {
          maxWidth: '100vw',
          width: '536px',
          height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
          data: { serviceData: result.data, editmode: true },
          disableClose: true
        });
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(result => {
            if (result) {
              let idx = this.myServices.findIndex((item: any) => item._id === result.data._id);
              this.myServices[idx].portfolios = result.data.portfolio;
              this.myServices[idx].skill_id = result.data.skill_id;
              this.myServices[idx].skill_name = result.data.skill_name;
              this.myServices[idx].title = result.data.title;
              this.slides = result.data.portfolio.map((portfolio: any) => {
                return { path: portfolio.url };
              });
            }
          });
      });
  }

  goToPreferenceTab() {
    this.router.navigate(['/dashboard/services'], { queryParams: { tab: 1 } });
  }

  checkUserPlan(plan_type: any, plan: any) {
    return (this.jobprosPlan?.length &&
      this.jobprosPlan?.findIndex(
        (item: any) => item?.plan_type?.toLowerCase() == plan_type?.toLowerCase()
      ) != -1) ||
      (this.jobprosPlan?.length &&
        this.jobprosPlan?.findIndex(
          (item: any) => item?.plan_type?.toLowerCase() == plan?.toLowerCase()
        ) != -1)
      ? false
      : true;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
