import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hashtagLink',
})
export class HashtagLinkPipe implements PipeTransform {
  transform(comment: string): string {
    let splitComment = comment.split(' ');
    const hashtag = splitComment.map((item: any) => {
      if (item.includes('#')) {
        return item.replace(
          item,
          `${
            `<span class="blue cursor-pointer hashtag-link">` +
            item +
            '</span>'
          }`
        );
      } else {
        return item;
      }
    });
    return hashtag.join(' ');
  }
}
