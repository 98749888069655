
<div class="section-main-intro">
  <div class="plans-comparing mx-auto">
    <div>
      <h1 class="compare-title-section-sub">How we compare</h1>
      <h1 class="compare-title-section">JOBPros VS Other Sites</h1>
      <div class="divider"></div>
      <p class="description">See how our complete platform compares to the  other social, job and freelancer sites.</p>
      <button class="btn-pricing" [routerLink]="routesConst.planPricing">Compare Plans</button>
    </div>
    <div class="cards-compare-sites grid">
      <div class="hidden xl:block common-heading">
        <p class="site-compare-heading"></p>
        <p class="items-compare common-color">Instant Payouts</p> 
        <p class="items-compare common-color">No Max Pricing</p>
        <p class="items-compare common-color">Income Streams</p>
        <p class="items-compare common-color">Platform Fees</p>
        <p class="items-compare common-color">Job Posting</p>
        <p class="items-compare common-color">Referral Program</p>
      </div>
      <div>
          <p class="site-compare-heading"><img class="h-1/2" src="../../../../../assets/images/logo/b-logo-desktop.png"></p>
          <p class="items-compare flex justify-center"><mat-icon class="font-semibold">check</mat-icon></p>
          <p class="items-compare justify-center common-color xl:hidden flex">Instant Payouts</p>               
          <p class="items-compare flex justify-center"><mat-icon class="font-semibold">check</mat-icon></p>
          <p class="items-compare justify-center common-color xl:hidden flex">No Max Pricing</p>
          <p class="items-compare flex justify-center"><mat-icon class="font-semibold">check</mat-icon></p>
          <p class="items-compare justify-center common-color xl:hidden flex">Income Streams</p>
          <p class="items-compare flex justify-center">5% - 10%</p>
          <p class="items-compare justify-center common-color xl:hidden flex">Platform Fees</p>
          <p class="items-compare flex justify-center">$34.99 Unlimited</p>
          <p class="items-compare justify-center common-color xl:hidden flex">Job Posting</p>
          <p class="items-compare flex justify-center">10% Cash Rewards</p>
          <p class="items-compare justify-center common-color xl:hidden flex">Referral Program</p>
      </div>
      <div class="border cards-divider my-3 md:my-4 "></div>
      <div>
          <p class="site-compare-heading">Other Sites</p>
          <p class="items-compare flex justify-center"><mat-icon class="close-icon">close</mat-icon></p>
          <p class="items-compare justify-center common-color xl:hidden flex">Instant Payouts</p>               
          <p class="items-compare flex justify-center"><mat-icon class="close-icon">close</mat-icon></p>
          <p class="items-compare justify-center common-color xl:hidden flex">No Max Pricing</p>
          <p class="items-compare flex justify-center"><mat-icon class="close-icon">close</mat-icon></p>
          <p class="items-compare justify-center common-color xl:hidden flex">Income Streams</p>
          <p class="items-compare flex justify-center">8% - 20%</p>
          <p class="items-compare justify-center common-color xl:hidden flex">Platform Fees</p>
          <p class="items-compare flex justify-center">$300 Per Post</p>
          <p class="items-compare justify-center common-color xl:hidden flex">Job Posting</p>
          <p class="items-compare flex justify-center">One $50 Bonus</p>
          <p class="items-compare justify-center common-color xl:hidden flex">Referral Program</p>
      </div>
    </div>
  </div>
</div>
<!-- <app-part-home [data]="partIntro"></app-part-home> -->