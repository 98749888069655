import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { statusConstant } from 'src/app/const/appConst'

export type FollowerElement = {
  id: string;
  img: string;
  name: string;
  level: string;
  postN: number;
  likeN: number;
  commentN: number;
};

const FOLLOWER_DATA: FollowerElement[] = [
  {
    id: '0',
    img: '/assets/images/livestream-avatar.jpg',
    name: 'JOBPros',
    level: 'Pro',
    postN: 123000,
    likeN: 88000,
    commentN: 930,
  },
  {
    id: '1',
    img: '/assets/images/livestream-avatar.jpg',
    name: 'Ahsan',
    level: 'Elite',
    postN: 123000,
    likeN: 88000,
    commentN: 930,
  },
  {
    id: '2',
    img: '/assets/images/livestream-avatar.jpg',
    name: '@username',
    level: 'Pro',
    postN: 123000,
    likeN: 88000,
    commentN: 930,
  },
];

@Component({
  selector: 'app-dashboard-users',
  templateUrl: './dashboard-users.component.html',
  styleUrls: ['./dashboard-users.component.scss'],
})
export class DashboardUsersComponent implements OnInit {
  selected = new FormControl(0);

  searchFollowerOptions = [
    { key: 'active', value: 'Most active' },
    { key: 'commit', value: 'Highest commission' },
  ];

  searchFollowerOption: string;

  displayedColumns: string[] = ['livestream', 'posts', 'likes', 'comments'];
  dataSource0 = FOLLOWER_DATA;
  dataSource = FOLLOWER_DATA;

  constructor() {
    this.searchFollowerOption = statusConstant.active;
  }

  ngOnInit(): void { }

  kFormatter(num: number) {
    return num > 999 ? num / 1000 + 'K' : num;
  }

  searchUser(e: any) {
    if (e.target.value)
      this.dataSource = this.dataSource0.filter((user: FollowerElement) =>
        user.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
    else this.dataSource = this.dataSource0;
  }
}
