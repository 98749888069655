<div class="sticky-footer px-4 py-2 left-0 right-0 fixed block bottom-0 md:hidden"
 [ngClass]="scrollPosition >= 50 ? 'bg-black-dark':'bg-white'" >
  <div class="flex justify-around h-full items-center">
    <div
      (click)="colorVariable = 'home'; closeAll()"
      [routerLink]="routesConst.launchPath"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="main-icon"
    >
      <!-- <mat-icon class="mat-icon-font-sze">home</mat-icon> -->

      <svg [ngStyle]="{ 'fill': scrollPosition >= 50 ? 'white':'#333333'}"
        class="svg-font-sze svg-color"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="100"
        height="100"
        viewBox="0 0 48 48"
      >
        <path
          d="M39.5,43h-9c-1.381,0-2.5-1.119-2.5-2.5v-9c0-1.105-0.895-2-2-2h-4c-1.105,0-2,0.895-2,2v9c0,1.381-1.119,2.5-2.5,2.5h-9	C7.119,43,6,41.881,6,40.5V21.413c0-2.299,1.054-4.471,2.859-5.893L23.071,4.321c0.545-0.428,1.313-0.428,1.857,0L39.142,15.52	C40.947,16.942,42,19.113,42,21.411V40.5C42,41.881,40.881,43,39.5,43z"
        ></path>
      </svg>

      <!-- <p class="text-size">Home</p> -->
    </div>
    <!-- <div
      [class]="isActiveRoute('/' + routesConst.profile+ '/' + username)? 'active' : ''"
      *ngIf="authService.loggedIn()"
      [routerLink]="'/' + routesConst.profile+ '/' + username"
      class="main-icon"
    >
      <img class="avatar circle" [src]="avatar" />
      <p class="text-size">Profile</p>
    </div> -->
    <!-- <div
      *ngIf="!authService.loggedIn()"
      class="main-icon"
      (click)="
        openSignUp(loginSignUpConstant.login); colorVariable = 'account-person'
      "
      [class]="isActiveRoute('/')? 'active' : ''"
    >
      <mat-icon class="mat-icon-font-sze">account_circle</mat-icon>
      <mat-icon fontIcon="home"></mat-icon>

      <p class="text-size">Profile</p>
    </div> -->
    
    <div
      [routerLink]="'/' + routesConst.discover"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="colorVariable = 'discover'; closeAll()"
      class="main-icon"
    >
      <mat-icon class="mat-icon-font-sze" [ngClass]="scrollPosition >= 50 ? 'text-white':'text-[#333333]'">search</mat-icon>
      <!-- <p class="text-size">Jobs</p> -->
    </div>

    <div
      [routerLink]="'/' + routesConst.communities"
      (click)="colorVariable = 'communities'; closeAll()"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="main-icon"
    >
      <mat-icon class="attach-money" [ngClass]="scrollPosition >= 50 ? 'text-white':'text-[#333333]'">group</mat-icon>
      <!-- <p class="text-size">Social</p> -->
    </div>

    <div (click)="colorVariable = 'payments'; closeAll()"  class="main-icon relative"
    [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments"
    routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      >
    
      <!-- <mat-icon class="add-icon">add</mat-icon> -->
      <!-- <i class="fa-solid fa-handshake mat-icon-font-sze"></i> -->

      <mat-icon class="attach-money" [ngClass]="scrollPosition >= 50 ? 'text-white':'text-[#333333]'">attach_money</mat-icon>

      <!-- <p class="text-size">Experts</p> -->
    </div>

    <div
      (click)="colorVariable = 'message'; closeAll()"
      [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.messages"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      class="main-icon relative"
    >
      <span *ngIf="totalUnReadMessages > 0" class="badge absolute">
        {{ totalUnReadMessages }}</span
      >
      <mat-icon class="attach-money" [ngClass]="scrollPosition >= 50 ? 'text-white':'text-[#333333]'">mail_outline </mat-icon>

      <!-- <p class="text-size">Messages</p> -->
    </div>

    <!-- <div
      (click)="colorVariable = 'profile'"
      [class]="colorVariable == 'profile' ? 'active' : ''"
      *ngIf="authService.loggedIn()"
      [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.account"
      class="main-icon"
    >
      <img class="avatar circle" [src]="avatar" />
      <p class="text-size">Profile</p>
    </div> -->

    <!-- <div
      *ngIf="!authService.loggedIn()"
      class="main-icon"
      (click)="
        openSignUp(loginSignUpConstant.login); colorVariable = 'account-person'
      "
      [class]="colorVariable == 'account-person' ? 'active' : ''"
    >
      <mat-icon class="mat-icon-font-sze">account_circle</mat-icon>
      <p class="text-size">Profile</p>
    </div> -->
  </div>
</div>

<mat-menu class="sticky-mat-menu" #exploreMenu="matMenu">
  <span class="p-4 block">
    <div class="explore-menu-items mt-2 cursor-pointer">
      <a [routerLink]="'/' + routesConst.community" class="menu-font ml-2"
        >Creators</a
      >
    </div>
    <div class="explore-menu-items mt-2 cursor-pointer mt-4">
      <a [routerLink]="'/' + routesConst.jobs" class="menu-font ml-2">Jobs</a>
    </div>
    <div class="explore-menu-items mt-2 cursor-pointer mt-4">
      <a [routerLink]="'/' + routesConst.talents" class="menu-font ml-2"
        >Experts</a
      >
    </div>
  </span>
</mat-menu>
<mat-menu class="sticky-mat-menu" #creatMenu="matMenu">
  <span class="p-4 block">
    <div
      *ngIf="type === userTypeConstant.company"
      class="explore-menu-items mt-2 cursor-pointer"
    >
      <a
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.jobs"
        class="menu-font"
        >Job Posting</a
      >
    </div>
    <div class="explore-menu-items mt-2 cursor-pointer" (click)="postContent()">
      <span class="menu-font">Content</span>
    </div>
    <div
      class="explore-menu-items mt-2 cursor-pointer"
      routerLink="{{ routesConst.dashboard + '/' + routesConst.services }}"
    >
      <span class="menu-font">Services</span>
    </div>
    <div
      class="explore-menu-items mt-2 cursor-pointer"
      (click)="createCampaign()"
    >
      <span class="menu-font">Campaigns</span>
    </div>
    <div
      class="explore-menu-items mt-2 cursor-pointer"
      [routerLink]="routesConst.dashboard + '/' + routesConst.subscription"
    >
      <span class="menu-font">Subscriber Plan</span>
    </div>
  </span>
</mat-menu>
