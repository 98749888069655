import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { apiUrlConst } from 'src/app/const/apiConst';
import { config } from 'src/config/config';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    public _http: HttpClient
  ) {}



  purchasedPlan = new BehaviorSubject({});
  purchasedAddOn = new BehaviorSubject([]);

  storeUserCredentials(token: any) {
    if (this.getWindow()?.localStorage) {
      this.getWindow()?.localStorage.setItem('token', token);
    }
  }

  loadUserCredentials() {
    if (this.getWindow()?.localStorage) {
      return this.getWindow()?.localStorage.getItem('token');
    }
    return null;
  }

  destroyUserCredentials() {
    if (this.getWindow()?.localStorage) {
      this.getWindow()?.localStorage.clear();
    }
  }

  /**
   * Update Candidate Cover
   * @param data
   * @returns
   */
  updateCandidateCover(data: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.candidateUpdateCover,
      data
    );
  }

  updateMooderatorStatus(data: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateMooderatorStatus,
      data
    );
  }

  /**
   * Create Authorization Headers for Twitch
   * @param bearerToken
   * @returns
   */
  createAuthorizationHeader(bearerToken: string): HttpHeaders {
    const headerDict = {
      Authorization: 'Bearer ' + bearerToken,
    };
    return new HttpHeaders(headerDict);
  }

  getUserInfo() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getUserDetails
    );
  }

  checkUserExistence(email: any) {
    let params = new HttpParams();
    params = params.append('email', email);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.checkUserExistence,
      options
    );
  }

  getOnBoardStatus() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.on_baord_status
    );
  }

  stripeOnBoard(redirect_url: string, refresh_url:string, create_customer:boolean) {
    let params = new HttpParams();
    params = params.append('redirect_url', redirect_url);
    params = params.append('refresh_url', refresh_url);
    params = params.append('create_customer', create_customer);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.stripeOnBoard,
      options
    );
  }

  getReferralCode() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.referralCode
    );
  }

  getUserDetails(user_name: any) {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getUserInfo,
      {
        params: {
          user_name,
        },
      }
    );
  }

  dashboardOperationHours() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.talentOperationHours
    );
  }

  getCommunityPageUserProfile(user_name: any) {
    let params = new HttpParams();
    params = params.append('user_name', user_name);
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getCommunityprofileInfo +
        '?' +
        params
    );
  }

  editPost(post_id: any) {
    let params = new HttpParams();
    params = params.append('post_id', post_id);
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.editpost +
        '?' +
        params
    );
  }

  saveAchievement(options: any) {
    return this._http.post(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.addAchievement,
      options
    );
  }

  updateAchievement(options: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateAchievement,
      options
    );
  }

  deleteAchievement(_id: string) {
    return this._http.delete(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.deleteAchievement,
      {
        params: {
          _id: _id,
        },
      }
    );
  }

  getUserType() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getLoginedUserType
    );
  }

  updateUserPrivacy(options: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updatePrivacy,
      options
    );
  }

  updateProfile(options: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateProfileDetails,
      options
    );
  }

  sendMail(payload: any[]) {
    return this._http.post(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.sendMail,
      {
        email: payload,
      }
    );
  }

  updateSocialProfiles(options: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateSocialProfiles,
      options
    );
  }

  skills() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.skills
    );
  }
  profileSkills() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.profileSkills
    );
  }

  updateSkills(options: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateProfileDetails,
      options
    );
  }

  /**
   * Update Candidate Preferences
   * @param options
   * @returns
   */
  updateCandidatePreference(options: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateProfileDetails,
      options
    );
  }

  /**
   * Update Candidate Image
   * @param options
   * @returns
   */
  updateImage(options: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateUserImage,
      options
    );
  }

  getUserFollowInfo(user_name: string) {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.publicFollowersPostsCount,
      {
        params: {
          user_name,
        },
      }
    );
  }

  checkUsernameValidity(user_name: any) {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.validatiy_username,
      {
        params: {
          user_name,
        },
      }
    );
  }

  addOperationHours(payload: any[]) {
    return this._http.post(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.addOperationHours,
      {
        operationHours: payload,
      }
    );
  }

  updateOperationHours(payload: any[]) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateOperationHours,
      {
        operationHours: payload,
      }
    );
  }

  getpurchasedPlan() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getPurchasedPlan
    );
  }

  getEvents() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getEvents
    );
  }

  saveEvent(event: any) {
    return this._http.post(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.saveEvent,
      event
    );
  }

  addModerator(payload: any) {
    return this._http.post(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.addModerator,
        payload
    );
  }

  getModerators(payload: any) {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getModerators,
        {
          params: {
            payload,
          },
        }
    );
  }
  
  getAccessModerators(payload: any) {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getModeratorsAccess,
        {
          params: {
            payload,
          },
        }
    );
  }

  updateEvent(event: any) {
    return this._http.put(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.updateEvent,
      event
    );
  }

  removeEvent(eventId: any) {
    return this._http.delete(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.removeEvent +
        '?_id=' +
        eventId
    );
  }

  subscribeEmail(email: any) {
    const options = { email };
    return this._http.post(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.subscribeEmail,
      options
    );
  }

  getNetworkNotification() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getNetworkNotification
    );
  }
  getProfileCategory() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.getProfileCategories
    );
  }

  markAsReadNotification(id: any) {
    return this._http.patch(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.markAsReadNotification,
      id
    );
  }

  checkIfUnreadNotification() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.unreadNotificationLength
    );
  }

  getUnreadMessageCount() {
    return this._http.get(
      config.environmentMode(this.location().hostname).endPoint +
        apiUrlConst.unread_messages
    );
  }

 

  getWindow(): Window | null {
    return this._doc.defaultView;
  }

  location(): Location {
    return this._doc.location;
  }
}
