import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { JobService } from 'src/app/core/services/job.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { JobApplyModalComponent } from 'src/app/modules/jobs/modals/job-apply-modal/job-apply-modal.component';
import { JobModalComponent } from 'src/app/shared/components/job-modal/job-modal.component';
import { SeoServiceService } from 'src/app/shared/components/seo-service.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @ViewChild('jobApplyModal', { static: false }) jobApplyModal: any;

  appliedJobs: any[] = [];
  totalcount: number = 0;
  totalAvaileJobs: any;
  locationFilters: string[] | null = null;
  categoryFilters: string[] | null = null;
  skillsFilters: string[] | null = null;
  typesFilters: string[] | null = null;
  salaryFilters: string[] | null = null;
  fileTypeFilters: string[] | null = null;
  locationfilervalue: string[] | null = null;
  experienceFilter: string[] | null = null;
  isFilterSelected: boolean = false;
  isShowAdvancedSearch: boolean = false;
  maxSalary: string;
  keywordToSearch: any;
  candidatePreferences: any;
  filterFunction?: boolean;
  selectedCompany: any;
  companyName: any;
  myFollowings: any[] = [];
  sortBy: any = '';
  jobs: any[] = [];
  jobFilters: any = { offset: 0, limit: 10 };
  isAllJobs = true;

  constructor(
    public dialog: MatDialog,
    public _jobsService: JobService,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private networkService: NetworkService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private seo: SeoServiceService
  ) {
    this.maxSalary = '';
    if (this.authService.loggedIn()) {
      this.getUserAppliedJobs();
      this.getFollowings();
    }
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    this.router.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params: any) => {
        if (!Object.keys(params).length) return;
        this.isAllJobs = params.company && params.name ? false : true;
        this.selectedCompany = params.company ? params.company : null;
        this.companyName = params.name ? params.name : null;
        this.jobFilters.company = params.company ? params.company : null;
        setTimeout(() => {
          const dialogRef = this.dialog.open(JobModalComponent, {
            width: innerWidth && innerWidth > 768 ? '650px' : '100vw',
            height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
            maxWidth: '100vw',
            maxHeight: '100vh',
            hasBackdrop: false,
            data: {
              id: params.id,
            },
          });
          dialogRef
            .afterClosed()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((singleJob: any) => {
              if (this.authService.loggedIn()) {
                this.getUserAppliedJobs();
              }
              const idx = this.jobs.findIndex(
                (job) => job?.job?._id === singleJob?._id
              );
              if (idx != -1) {
                this.jobs[idx].job = singleJob;
              }
            });
        }, 500);

        if (params?.search?.length) {
          this.keywordToSearch = params.search;
          this.getAllJobsByFilter();
        }
      });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.getWindow()?.scroll(0, 0);
    }, 1000);
    this.seo.generateTags({
      title: 'Its Job page page',
      description:
        'Contact me through this awesome search engine optimized Angular component',
      image: 'https://instafire-app.firebaseapp.com/assets/meerkat.jpeg',
      slug: 'contact-page',
    });
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  getFollowings() {
    this.networkService
      .getFollowings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network: any) => {
        this.myFollowings = network.data;
      });
  }

  /**
   * Filteration by Salary
   */
  salaryFilterEvent(salary: any) {
    this.getJobFilters();
    this.salaryFilters =
      salary.salaryRange && salary.salaryRange.length == 0
        ? null
        : salary.salaryRange;
    this.getAllJobsByFilter();
  }

  /**
   * Filtration by Experience
   * @param experience
   */
  experienceTypeFilterEvent(experience: any) {
    this.getJobFilters();
    this.experienceFilter = experience;
    this.getAllJobsByFilter();
  }

  getJobsList() {
    if (this.isFilterSelected || this.companyName) {
      this.jobs = [] = [];
      this.jobFilters.company = null;
      this.companyName = null;
      this.keywordToSearch = null;
      this.sortBy = null;
      this.locationFilters = null;
      this.categoryFilters = null;
      this.skillsFilters = null;
      this.typesFilters = null;
      this.salaryFilters = null;
      this.fileTypeFilters = null;
      this.locationfilervalue = null;
      this.experienceFilter = null;
      this.isFilterSelected = false;
      this.jobFilters.offset = 0;
      this.jobFilters.limit = 10;
      // this.getAllJobs();
    }
  }
  /**
   * Get all Jobs list
   */
  getAllJobs() {
    this.keywordToSearch = null;
    this.sortBy = null;
    this.locationFilters = null;
    this.categoryFilters = null;
    this.skillsFilters = null;
    this.typesFilters = null;
    this.salaryFilters = null;
    this.fileTypeFilters = null;
    this.locationfilervalue = null;
    this.experienceFilter = null;
    this.isFilterSelected = false;
    this.spinner.show();
    this.jobFilters.offset = 0;
    this.jobFilters.limit = 10;
    this._jobsService
      .getAllJobs(this.jobFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.totalAvaileJobs = data.totalJobAvailble;
        if (data && data?.data?.length) {
          const resultant = data.data.map((x: any) => ({
            ...x,
            job: {
              ...x.job,
              isApplied: this.appliedJobs.some((p) => p.job_id == x.job._id),
              status: this.appliedJobs.find((j) => j.job_id === x.job._id)
                ?.applied_status,
              isSaved: this.appliedJobs.find((j) => j.job_id === x.job._id)
                ?.status_type,
              followed: this.myFollowings.some(
                (f) => f.following == x.company._id
              ),
              appliedOn: this.appliedJobs.some((p) => p.job_id == x.job._id)
                ? this.appliedJobs.find((j) => j.job_id === x.job._id)
                    .created_at
                : null,
            },
          }));
          if (this.jobs?.length) {
            this.jobs = [];
            this.jobs = [...this.jobs, ...resultant];
            this.totalcount = this.jobs.length;
          } else {
            this.jobs = resultant;
            this.totalcount = this.jobs.length;
          }
        }
        this.spinner.hide();
      });
  }

  /**
   * Get filtered Selected Job
   */
  getAllJobsByFilter() {
    this.filterFunction =
      (this.locationFilters && this.locationFilters.length > 0) ||
      (this.categoryFilters && this.categoryFilters.length > 0) ||
      (this.skillsFilters && this.skillsFilters.length > 0) ||
      (this.typesFilters && this.typesFilters.length > 0) ||
      (this.salaryFilters && this.salaryFilters.length > 0) ||
      (this.experienceFilter && this.experienceFilter.length > 0) ||
      (this.sortBy && this.sortBy.length > 0) ||
      (this.keywordToSearch && this.keywordToSearch.length > 0)
        ? true
        : false;
    if (this.filterFunction) {
      this.isFilterSelected = true;
      setTimeout(() => {
        this.spinner.show();
        this.getJobsByFilters();
      }, 1000);
    } else {
      this.isFilterSelected = false;
      if (this.selectedCompany) {
        this.jobFilters.company = this.selectedCompany;
      }
      if (!this.authService.loggedIn()) {
        this.getAllJobs();
      }
    }
  }

  /**
   * Get Jobs by filter
   */
  getJobsByFilters() {
    this.spinner.show();
    this._jobsService
      .getJobsByFilter(
        this.jobFilters.offset,
        this.locationFilters,
        this.categoryFilters,
        this.typesFilters,
        this.salaryFilters,
        this.experienceFilter,
        this.sortBy,
        this.keywordToSearch,
        this.selectedCompany
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          if (response && response.data) {
            this.jobs = response.data;
            this.totalcount = this.jobs.length;
            this.spinner.hide();
            if (response && response?.data?.length) {
              this.spinner.hide();
              const resultant = response.data.map((x: any) => ({
                ...x,
                job: {
                  ...x.job,
                  isApplied: this.appliedJobs.some(
                    (p) => p.job_id == x.job._id
                  ),
                  status: this.appliedJobs.find((j) => j.job_id === x.job._id)
                    ?.applied_status,
                  isSaved: this.appliedJobs.find((j) => j.job_id === x.job._id)
                    ?.status_type,
                  followed: this.myFollowings.some(
                    (f) => f.following == x.company._id
                  ),
                  appliedOn: this.appliedJobs.some((p) => p.job_id == x.job._id)
                    ? this.appliedJobs.find((j) => j.job_id === x.job._id)
                        .created_at
                    : null,
                },
              }));
              if (this.jobs?.length) {
                this.jobs = [];
                this.jobs = [...this.jobs, ...resultant];
                this.totalcount = this.jobs.length;
              } else {
                this.jobs = resultant;
                this.totalcount = this.jobs.length;
              }
              this.totalAvaileJobs = response.totalJobAvailble;
              this.spinner.hide();
            }
          } else {
            this.spinner.hide();
          }
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  /**
   * Search on the basis of keywords
   * @param keyword
   */
  SearchingValueEvent(keyword: any) {
    this.getJobFilters();
    this.keywordToSearch = keyword;
    this.getAllJobsByFilter();
  }

  /**
   * Jobs Sort By Date
   * @param sortyBy
   */
  sorByFilters(sortBy: any) {
    this.getJobFilters();
    this.sortBy = sortBy;
    this.getAllJobsByFilter();
  }

  /**
   * Open Apply Dialog for selected job
   * @param selectedJob
   */
  openApplyDialog(selectedJob: any): void {
    if (selectedJob) {
      this.dialog.open(JobApplyModalComponent, {
        maxWidth:'100%',
        width: '536px',
        height: window.innerWidth && window.innerWidth > 768 ? '650px' : '100vh',
        data: {
          job: selectedJob,
        },
      });
    }
  }

  saveJob(selectedJob: any) {
    if (
      selectedJob.job &&
      selectedJob.job.company_id &&
      selectedJob.job._id &&
      this.authService.loggedIn()
    ) {
      const appliedJobParams = {
        company_id: selectedJob?.job?.company_id,
        job_id: selectedJob.job._id,
      };
      this._jobsService
        .saveJob(appliedJobParams)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response: any) => {
            this.appliedJobs.push(response?.data);
            this.jobs.find((x: any) => {
              if (x.job._id == response.data.job_id) {
                x.job.isSaved = response.data.status_type;
              }
            });
            this.snackBar.showMessage('Successfully saved this job.', false);
          },
          () => {
            this.snackBar.showMessage('Failed to save this job.', true);
          }
        );
    }
  }

  /**
   * Location Filter Job Search
   * @param $event
   */
  locationFilterEvent($event: any) {
    this.getJobFilters();
    this.locationFilters = $event;
    this.getAllJobsByFilter();
  }

  /**
   * Category Filter Job Search
   * @param $event
   */
  jobCategoryFilterEvent($event: any) {
    this.getJobFilters();
    this.categoryFilters = $event;
    this.getAllJobsByFilter();
  }

  /**
   * File Type Filter Job Search
   * @param $event
   */
  jobFiletypeFilterEvent($event: any) {
    this.getJobFilters();
    this.typesFilters = $event;
    this.getAllJobsByFilter();
  }

  /**
   * On ScrollDown Shows more jobsList
   *  Apply infinite scroll
   */
  onScrollDown() {
    if (this.totalcount != this.totalAvaileJobs) {
      this.jobFilters.offset = this.jobFilters.offset + 10;
      this.spinner.show();
      if (this.isFilterSelected) {
        this._jobsService
          .getJobsByFilter(
            this.jobFilters.offset,
            this.locationFilters,
            this.categoryFilters,
            this.typesFilters,
            this.salaryFilters,
            this.experienceFilter,
            this.sortBy,
            this.keywordToSearch,
            this.selectedCompany
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((jobs: any) => {
            if (jobs && jobs?.data?.length) {
              const resultant = jobs.data.map((x: any) => ({
                ...x,
                job: {
                  ...x.job,
                  isApplied: this.appliedJobs.some(
                    (p) => p.job_id == x.job._id
                  ),
                  status: this.appliedJobs.find((j) => j.job_id === x.job._id)
                    ?.applied_status,
                  isSaved: this.appliedJobs.find((j) => j.job_id === x.job._id)
                    ?.status_type,
                  followed: this.myFollowings.some(
                    (f) => f.following == x.company._id
                  ),
                  appliedOn: this.appliedJobs.some((p) => p.job_id == x.job._id)
                    ? this.appliedJobs.find((j) => j.job_id === x.job._id)
                        .created_at
                    : null,
                },
              }));
              this.spinner.hide();
              let job = [...this.jobs, ...resultant];
              this.jobs = job;
              this.totalcount = job.length;
              this.totalAvaileJobs = jobs.totalJobAvailble;
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
      } else {
        this._jobsService
          .getAllJobs(this.jobFilters)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((data) => {
            this.totalAvaileJobs = data.totalJobAvailble;
            if (data && data?.data?.length) {
              const resultant = data.data.map((x: any) => ({
                ...x,
                job: {
                  ...x.job,
                  isApplied: this.appliedJobs.some(
                    (p) => p.job_id == x.job._id
                  ),
                  status: this.appliedJobs.find((j) => j.job_id === x.job._id)
                    ?.applied_status,
                  isSaved: this.appliedJobs.find((j) => j.job_id === x.job._id)
                    ?.status_type,
                  followed: this.myFollowings.some(
                    (f) => f.following == x.company._id
                  ),
                  appliedOn: this.appliedJobs.some((p) => p.job_id == x.job._id)
                    ? this.appliedJobs.find((j) => j.job_id === x.job._id)
                        .created_at
                    : null,
                },
              }));
              this.spinner.hide();
              let job = [...this.jobs, ...resultant];
              this.jobs = job;
              this.totalcount = job.length;
              this.totalAvaileJobs = data.totalJobAvailble;
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
      }
    }
  }

  /**
   * Get Candidate Job Preferences
   */
  getCandidateJobPreferences() {
    this._jobsService
      .getCandidateJobPreferences()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((candidate: any) => {
        this.candidatePreferences = candidate.data;
        if (candidate && candidate?.data) {
          if (candidate?.data?.location?.length) {
            this.locationFilters = candidate?.data?.location[0]?._id
              ? candidate?.data?.location[0]?._id
              : null;
          }
          this.categoryFilters = candidate?.data?.categories?.length
            ? candidate?.data?.categories
            : null;
          this.typesFilters = candidate?.data?.job_type?.length
            ? candidate?.data?.job_type
            : null;
          this.salaryFilters = candidate?.data?.desired_salary?.length
            ? candidate?.data?.desired_salary
            : null;
          this.experienceFilter = candidate?.data?.experience_level?.length
            ? candidate?.data?.experience_level
            : null;
          // setTimeout(() => {
          this.getJobsByFilters();
          this.isFilterSelected = true;
          // }, 3000);
        }
      });
  }

  /**
   * Get User's Applied Jobs
   */
  getUserAppliedJobs() {
    this._jobsService
      .checkForJobsApplied()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: any) => {
        if (data && data.data && data.data.length) {
          this.appliedJobs = data.data;
        }
        this.getAllJobs();
      });
  }

  getJobFilters() {
    return (this.jobFilters = { offset: 0, limit: 10 });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
