export const apiUrlConst = {
  bucketURL: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/',
  compressedBucketURL: 'https://jobpros-comppressed-media.s3.us-west-1.amazonaws.com/',
  redirectUri: '/login',
  specificUsers: '/api/candidate/users/home',
  candidateRegister: '/api/candidate/register',
  checkEmailExistance: '/api/candidate/validate/email',
  addConversation: '/chat/new',
  addNetwork: '/api/v1/networks',
  getUserId: '/api/auth/user/username/check',
  checkLoginedUserExistance: '/api/auth/check/user/existance',
  updateMultiChainAddress: '/api/v1/getnewaddress',
  getAllJobs: '/api/jobs',
  getJobsByFilter: '/api/jobs/filter',
  getCompanyFilterJob: '/api/jobs/filterbycompany',
  checkTwitchUser: '/api/twitch/check',
  updateTwitchUser: '/api/twitch/add',
  unlinkTwitch: '/api/twitch/unlink',
  getJobsBySearch: '/api/jobs/jobsearch',
  getChatUsers: '/api/chat',
  getMyNetworkUsers: '/api/mynetwork',
  getClientUsers: '/api/client',
  removechat: '/api/chat/removechat',
  getChatMessages: '/api/chat/chatmessage',
  getMyNetworkMessages: '/api/mynetwork/chatmessage',
  getClientMessages: '/api/client/chatmessage',
  postChat: '/api/chat/add',
  postmynetworkChat: '/api/mynetwork/add',
  postclientChat: '/api/client/add',
  addNewJobs: '/api/jobs/add',
  checkEligibility: '/api/jobs/check/eligibility',
  applyForJobs: '/api/jobsapplied/add',
  saveJob: '/api/jobs/save',
  updateAppliedJob: '/api/jobsapplied/update',
  checkForJobApplied: '/api/jobsapplied/check',
  getCandidateById: '/api/candidate/getUserById',
  referralCode: '/api/candidate/referral/code',
  deleteAllUserPlan: '/api/user/plan/delete/all',
  deleteMultipleUserPlan: '/api/user/plan/delete/multiple',
  readUnreadMessages: '/api/chat/read',
  getRecruiterUnreadMessages: '/api/chat/unread/messages',
  getNetworkUnreadMessages: '/api/mynetwork/unread/messages',
  getClientUnreadMessages: '/api/client/unread/messages',
  readNetworkUnreadMessages: '/api/mynetwork/read',
  readClientUnreadMessages: '/api/client/read',
  getCandidateJobPreference: '/api/candidatepreferences/',
  getCandidateProfileJobPreference: '/api/candidatepreferences/profile',
  getJob: '/api/jobs/getJob',
  getSingleJob: '/api/jobs/singlejob',
  updateCandidateJobPreference: '/api/candidatepreferences/update',
  getCategories: '/api/category',
  getJobTypes: '/api/types',
  updateProfileDetails: '/api/candidate/update',
  updateUserImage: '/api/candidate/image/remove',
  publicFollowersPostsCount: '/api/network/public',
  getPurchasedPlan: '/api/candidate/plan/mine',
  locationSearch: '/api/locations/search',
  updateJobs: '/api/jobs/update',
  updateJobStatus: '/api/jobs/update-status',
  getPayRangeList: '/api/payrange',
  getTalentServicePayRangeList: '/api/service/payrange',
  getAppliedJobsByUserId: '/api/jobsapplied/userjoblist',
  savedJobs: '/api/jobsapplied/saved',
  getAppliedJobsByUserIdFilter: '/api/jobsapplied/userjoblistfilter',
  getAppliedJobsByUserIdBySearch: '/api/jobsapplied/search',
  companyjobsearch: '/api/jobs/companyjobsearch',
  getApplicantsByJobId: '/api/jobsapplied/applicants',
  uploadImages: '/api/candidate/updateimages',
  candidateUpdateCover: '/api/candidate/candidateupdatecover',
  updateprefrences: '/api/preference/update',
  getJobListByCompany: '/api/jobs/byCompany',
  getAllJobListByCompany: '/api/jobs/mine',
  removeAppliedJobs: '/api/jobsapplied/removeappliedjob',
  removeJob: '/api/jobs/removejob',
  userReferral: '/api/candidate/referral/mine',
  getUserDetails: '/api/candidate/details',
  checkUserExistence: '/api/candidate/exist',
  addAchievement: '/api/achievement/add',
  updateAchievement: '/api/achievement/update',
  deleteAchievement: '/api/achievement/remove',
  updatePrivacy: '/api/privacy/update',
  updateSocialProfiles: '/api/social/update',
  updateInterests: '/api/intrest/add',
  getUserInfo: '/api/candidate/preference/information',
  talentOperationHours: '/api/candidate/dashboard/talent/operationhours',
  validatiy_username: '/api/candidate/username/validity',
  getCommunityprofileInfo: '/api/candidate/profile/public',
  skills: '/api/skills',
  profileSkills: '/api/skills/profile',
  uploadPost: '/api/post/add',
  editPost: '/api/post/update',
  likePost: '/api/likes/add',
  unlikePost: '/api/likes/unlike',
  getAllPost: '/api/post',
  getDiscoverUsers: '/api/post/discover/users',
  getCommunitiesUsers: '/api/post/communities/users',
  getFeedPosts: '/api/post/feed',
  getNetworkPosts: '/api/post/network',
  getPopularPosts: '/api/post/popular',
  getPostCategory: '/api/post/category',
  getAllPostByCandidate: '/api/candidate/users',
  getMentionUser: '/api/candidate/mention/users',
  getRelatedPost: '/api/post/relatedPost',
  getTrendingPosts: '/api/post/discover',
  getCommunitiesData: '/api/post/communities',
  searchPost: '/api/post/searchByUsername',
  getPublicPosts: '/api/post/public',
  getSpecificUserPosts: '/api/post/specificUserPosts',
  getPostDetails: '/api/post/getPostDetails',
  editpost: '/api/post/editpost',
  deletePost: '/api/post/removepost',
  sharePost: '/api/mynetwork/sharepost',
  deletePostContent: '/api/postcontent/removepostcontent',
  addComment: '/api/comments/add',
  replyComment: '/api/comments/add',
  updateComment: '/api/comments/update',
  removeComment: '/api/comments/remove',
  followUser: '/api/network/follow',
  unfollowUser: '/api/network/unfollow',
  getFollowersandFollowings: '/api/network/mine',
  getFollowers: '/api/network/mine/followers',
  getFollowings: '/api/network/mine/followings',
  getSubscribers: '/api/network/mine/subscribers',
  getFollowing: '/api/network/following',
  getNetworkNotification: '/api/network/getNotifications',
  getProfileCategories: '/api/profile-categories',
  markAsReadNotification: '/api/network/notification/read',
  unreadNotificationLength: '/api/network/notification/unread',
  getBillingTypes: '/api/billingtype',
  addSubscriber: '/api/subscriber/add',
  unsubscribeUser: '/api/subscriber/unsubscribe',
  stripeUser: '/api/candidate/stripe_user',
  getPlanList: '/api/plan/plateform',
  addPlan: '/api/plan/add',
  updatePlan: '/api/plan/update',
  deletePlan: '/api/plan/remove',
  addExperienceLevel: '/api/experiencelevel/add',
  experienceLevel: '/api/experiencelevel',
  updateExperienceLevel: '/api/experiencelevel/update',
  deleteExperienceLevel: '/api/experiencelevel/remove',
  addJobType: '/api/types/add',
  updateJobType: '/api/types/update',
  deleteJobType: '/api/types/remove',
  addPayRange: '/api/payrange/add',
  updatePayRange: '/api/payrange/update',
  deletePayRange: '/api/payrange/remove',
  getAllTalents: '/api/talentservice/talents',
  getSellerReviews: '/api/talentservice/getReviews/seller',
  getBuyerReviews: '/api/talentservice/getReviews/buyer',
  getFilteredTalents: '/api/talentservice/find',
  getTalentServiceCategory: '/api/categorygroup/gettalentcategory',
  addTalentService: '/api/talentservice/add',
  updateTalentService: '/api/talentservice/update',
  updateTalentServicePackage: '/api/talentservice/update/package',
  updateSubscriptionPackage: '/api/user/plan/update/package',
  getSingleSubscription: '/api/user/plan/singlesubscription',
  // updateTalentServiceContent: '/api/talentservice/update/content',
  deactivatePlan: '/api/user/plan/delete',

  publicTalentService: '/api/talentservice/public',
  dashboardTalentService: '/api/talentservice/dashboard',
  getSingleTalentService: '/api/talentservice/singletalent',
  removeTalentService: '/api/talentservice/remove',
  deactivateTalentService: '/api/talentservice/deactivate',
  talentPreferences: '/api/talent/preferences',
  addTalentPreference: '/api/talent/update',
  addOperationHours: '/api/operationhours/add',
  sendMail: '/api/candidate/email',
  updateOperationHours: '/api/operationhours/update',
  getLiveStreams: '/api/twitch/streams',
  getLiveStreamsList: '/api/twitch',
  createUserPlan: '/api/user/plan/create',
  userSoldPlan: '/api/user/plan/sold',
  minePlan: '/api/user/plan/created/mine',
  getPlans: '/api/user/plan/userPlan',
  publicPlans: '/api/user/plan/public',
  publicAllPlans: '/api/user/plan/public/all',
  updateUserPlan: '/api/user/plan/update',
  getPlateformPlan: '/api/user/plan/mine/subscription',
  deleteUserPlan: '/api/user/plan/delete',
  getCampaignPost: '/api/post/campaign/mine',
  getStripeUser: '/api/talentservice/configuration',
  purchaseProduct: '/api/stripe/purchase',
  checkTrialPeriod: '/api/stripe/checkTrialPeriod',
  purchaseUserCreatedPlan: '/api/stripe/purchase/usercreatedplan',
  publicPrices: '/api/stripe/price',
  publicAccountPrices: '/api/stripe/price/account',
  getPlanPrice: '/api/user/plan/product/price',
  createCustomerAndSubscription: '/api/stripe/purchase/platform',
  cancelPlatformSubscription: '/api/stripe/purchase/platform/cancel',
  cancelPlatformSubscriptionAddon: '/api/stripe/purchase/platform/addon/cancel',
  purchaseAddOn: '/api/stripe/purchase/platform/addon',
  getMember: '/api/stripe/getmember',
  getPaymentList: '/api/stripe/getpayments',
  getBalance: '/api/stripe/getbalance',
  getLink: '/api/stripe/getlink',
  configureCustomerPortal: '/api/configure/customer/portal',
  billingPortal: '/api/configure/customer/billing/session',
  getEvents: '/api/schedule/events',
  removeEvent: '/api/schedule/remove',
  saveEvent: '/api/schedule/add',
  updateEvent: '/api/schedule/update',
  ValidateUsername: '/api/auth/user/username/check',
  change_password: '/dbconnections/change_password',
  connectAccount: '/api/platform/account',
  getTotalEarning: '/api/candidate/totalEarning',
  on_baord_status: '/api/candidate/on_baord_status',
  networkStats: '/api/candidate/stats',
  getLoginedUserType: '/api/candidate/type',
  stripeOnBoard: '/api/candidate/onboard',
  subscribeEmail: '/api/candidate/subscribe',
  minePaymentsMehthods: '/api/card/payment/methods/mine',
  settings: '/api/card/account/settings',
  deletePaymentMethod: '/api/card/payment/methods/delete',
  makeCardPrimary: '/api/card/make-primary',
  newCard: '/api/card/new',
  sendRefundRequest: '/api/refund/request',
  updateRefundRequest: '/api/refund/request/update',
  getSoldServices: '/api/talentservice/sold',
  check_user_existance: '/api/candidate/check/user/name',
  update_user_name: '/api/candidate/username/update',
  unread_messages: '/api/network/count/unread/messages',
  addDispute: '/api/dispute/add',
  updateViews: '/api/views/add',
  addFeedback: '/api/talentservice/rating',
  addModerator: '/api/moderator/add',
  getModerators: '/api/moderator/all',
  getModeratorsAccess: '/api/moderator/access',
  updateMooderatorStatus: '/api/moderator/status',
  getS3MediaUploadStatus: '/api/s3/check-media-upload-status',
  removes3Object: '/api/s3/removeObject',
  getFeedContent: '/api/feed/content',
  getFeedContentList: '/api/feed/list',
};
