import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-manage-cards',
  templateUrl: './manage-cards.component.html',
  styleUrls: ['./manage-cards.component.scss'],
})
export class ManageCardsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
