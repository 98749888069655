<div
  class="image-upload absolute top-0 w-full h-full flex items-center justify-center"
>
  <label
    class="portfolio-wrapper w-full h-full flex justify-center montserrat-bold blue cursor-pointer"
  >
    <div
      *ngIf="
        (portfolio === undefined || portfolio === '' || !portfolio) && !loading
      "
      class="insert-photo-button bg-white rounded-full"
    >
      <mat-icon *ngIf="index!==0 || !isSubscriptionModel" class="gray-dark">add_a_photo</mat-icon>
      <img *ngIf="isSubscriptionModel && index==0"
      class="mx-auto opacity-30 max-widthHeight object-scale-down gray-dark"
      src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/j7m3z.png"
    />
    </div>
    <div
      *ngIf="
        (portfolio !== undefined &&
          portfolio !== '' &&
          portfolio &&
          portfolio?.media_type == 'image') ||
        loading
      "
      class="relative portfolio w-full h-full bg-contain bg-no-repeat bg-center"
      [class]="loading ? 'spinner' : ''"
      [ngStyle]="{ 'background-image': 'url(' + portfolio?.url + ')' }"
    ></div>

    <video
      *ngIf="
        portfolio !== undefined &&
        portfolio !== '' &&
        portfolio &&
        portfolio?.media_type == 'video' &&
        !loading
      "
      class="w-full h-full bg-black-dark"
      [src]="portfolio?.url+ '#t=0.1'"  preload="metadata"
      controls
      playsinline
      webkit-playsinline
    ></video>
    <input
      #avatar
      multiple
      type="file"
      accept="image/*,video/*"
      class="uploadFile img w-0 h-0 overflow-hidden"
      value="Upload Media"
      (change)="avatar.files ? uploadImage(avatar.files) : ''"
    />
  </label>
</div>
