import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-stream-card',
  templateUrl: './user-stream-card.component.html',
  styleUrls: ['./user-stream-card.component.scss'],
})
export class UserStreamCardComponent implements OnInit {
  @Input() liveStreams: any;
  @Input() set isShowSpinner(showSpinner: boolean) {
    showSpinner ? this.spinner.show() : this.spinner.hide();
  }

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {}

  getStreamImage(url: string) {
    if (url) {
      return url.replace('%{width}x%{height}', '256x165');
    } else {
      return (url = '../../../../../assets/images/twitch.png');
    }
  }

  openVideo(url: any) {}
}
