<div class="section-wrapper bg-white px-4 lg:p-10">
  <p class="text-black montserrat-bold">You’re paid subscriptions</p>
  <p class="mt-2 black-light s">See what you bought</p>
  <div class="mt-2">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div
    class="flex items-center justify-between border-normal rounded p-4 mt-6"
    *ngFor="let plan of purchased_Plans"
  >
    <div>
      <p class="montserrat-bold">
        {{ plan?.planDetails?.title }}
      </p>
      <p class="font-bold text-sm text-gray my-1 active-price-color">
        Active price
      </p>
      <p class="font-bold text-sm text-gray">
        {{ plan?.subscriptionPackagesDetails?.type }}
      </p>
    </div>
    <div class="flex justify-evenly">
      <button>
        <span class="s cursor-pointer montserrat-bold truncate whitespace-nowrap" [class]="plan.isCanceled ? 'red' : 'blue'">{{
          plan.isCanceled ? 'Canceled' : 'Rate & Review Details'
        }}</span>
      </button>
    </div>
  </div>
  <div
    class="flex items-center border-normal rounded p-4 mt-6"
    *ngFor="let mySubscription of platform_subscription"
  >
    <div class="flex flex-col lg:flex-row lg:items-center">
      <p class="montserrat-bold">
        {{ mySubscription?.title }}
      </p>

      <p class="montserrat-bold ml-2 active-price-color">
        Active price
      </p>
      <!-- <p *ngIf="stripe_user?.is_old_user" class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">
        ${{
          mySubscription.interval == 'month' && mySubscription.isPlan
            ? mySubscription?.old_monthly_price
            : mySubscription.interval == 'year' && mySubscription.isPlan
            ? mySubscription?.old_yearly_price * 12
            : mySubscription?.price * 12
        }}/{{ mySubscription.interval == 'month' ? 'Monthly' : 'Annual ' }}
      </p>

      <p *ngIf="!stripe_user?.is_old_user" class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">
        ${{
          mySubscription.interval == 'month' && mySubscription.isPlan
            ? mySubscription?.monthly_price
            : mySubscription.interval == 'year' && mySubscription.isPlan
            ? mySubscription?.yearly_price * 12
            : mySubscription?.price
        }}/{{ mySubscription.interval == 'year' ? 'Annual ' : 'Monthly' }}
      </p> -->
      <p class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">
        {{ mySubscription.isPlan ? 'JOBPros Membership' : 'JOBPros Add On' }}
      </p>
    </div>
    <div class="ml-auto">
      <button mat-flat-button (click)="subscriptionCheckOut(mySubscription)">
        <span class="s blue cursor-pointer montserrat-bold">Details</span>
      </button>
    </div>
  </div>
  <div
    class="flex items-center border-normal rounded p-4 mt-6"
    *ngFor="let plan of platFormPurchased_plans"
  >
    <div class="flex flex-col lg:flex-row lg:items-center">
      <p class="montserrat-bold">
        {{ plan?.planDetails?.title }}
      </p>
      <p class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">
        {{ plan?.planDetails?.type }}
      </p>
    </div>
    <div class="ml-auto">
      <button mat-flat-button (click)="unsubscribeService(plan)">
        <span class="s blue cursor-pointer montserrat-bold">Details</span>
      </button>
    </div>
  </div>
</div>
