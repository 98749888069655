import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { intervalConstant } from 'src/app/const/appConst';
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { PlanDetailComponent } from 'src/app/modules/users/pages/profile/components/plan-detail/plan-detail.component';
import { PaymentCheckoutModalComponent } from 'src/app/shared/components/payment-checkout-modal/payment-checkout-modal.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-dashboard-paid-subscription',
  templateUrl: './dashboard-paid-subscription.component.html',
  styleUrls: ['./dashboard-paid-subscription.component.scss']
})
export class DashboardPaidSubscriptionComponent implements OnInit {
  private unsubscribe$ = new Subject();
  purchased_Plans:any;
  platFormPurchased_plans:any;
  platform_subscription:any;
  stripe_user:any;
  constructor(private userPlan: userPlans,private snack: SnackBarService,private dialog: MatDialog,private profileService: ProfileService,private stripePaymentService: paymentService,private spinner: NgxSpinnerService,private commonService: CommonService) { }

  ngOnInit(): void {
    this.getPurchasedServices();
    this.getPlateformPlan()
  }

  getPurchasedServices() {    
    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscription: any) => {
        const { purchased_plans } = subscription.data;
        this.purchased_Plans = purchased_plans;                        
      });
  }
  getPlateformPlan() {
    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscription: any) => {
        const { platform_subscription } = subscription;
        const { purchased_plans, stripe_user } = subscription.data;
        this.platFormPurchased_plans = purchased_plans;
        this.platform_subscription = platform_subscription;
        this.stripe_user = stripe_user;
      });
  }

  unsubscribeService(service: any) {
    this.spinner.show();
    let { seller_account_id, customer } = service;
    let payload = { account_id: seller_account_id, customer_id: customer };
    this.userPlan
      .createBillingPortal(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.commonService.getWindow()?.open(res.billingSession.url, '_blank');
        this.spinner.hide();
      });
  }

  subscriptionCheckOut(plan: any) {
    this.spinner.show();
    if (plan && plan.isPlatform == false) {
      this.userPlan
        .getUserPlans(plan.profile_id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((userplan: any) => {
          this.spinner.hide();
          if (userplan && userplan.plans.length) {
            plan.account_id = userplan.plans[0].account_id;
            plan.viewMode = true;
            this.dialog.open(PlanDetailComponent, {
              maxWidth: '100vw',
              width: '1099px',
              height: '884px',
              data: plan
            });
          }
        });
    } else {
      this.stripePaymentService
        .publicPrices()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            this.spinner.hide();
            if (res?.publicPrice?.length) {
              let avialbleProduct = res.publicPrice.find(
                (plans: any) => plans.id == plan.stripe_product_id
              );
              let customerSubscription = res.customerSubscription;
              if (avialbleProduct) {
                avialbleProduct.features = plan.features;
                avialbleProduct.isAddOn = !plan.isPlan;
                avialbleProduct.viewMode = true;
                avialbleProduct.interval = plan?.interval ? plan.interval : intervalConstant?.month;
                const dialogRef = this.dialog.open(PaymentCheckoutModalComponent, {
                  maxWidth: '100vw',
                  width: '536px',
                  height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
                  data: {avialbleProduct, currentSubscription: customerSubscription}
                });
                dialogRef
                  .afterClosed()
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe((res: any) => {
                    if (res?.cancelSubs?.result) {
                      const idx = this.platform_subscription.findIndex((item: any) => {
                        return item.stripe_product_id == res.product;
                      });
                      if (res.freePlan) {
                        this.platform_subscription[idx] = res.freePlan;
                      } else {
                        this.platform_subscription.splice(idx, 1);
                      }
                    }
                  });
              } else {
                this.snack.showMessage(`Cannot Find Such a Specific Plan`, true);
              }
            } else {
              this.snack.showMessage(`Cannot Find Plans...`, true);
            }
          },
          err => {
            this.snack.showMessage(`${err}`, true);
          }
        );
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
