import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-part-earning',
  templateUrl: './part-earning.component.html',
  styleUrls: ['./part-earning.component.scss']
})
export class PartEarningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
