import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { config } from 'src/config/config';
import { apiUrlConst } from 'src/app/const/apiConst';
import { CommonService } from 'src/app/core/services/common.service';
import { UserPlan } from 'src/app/core/models';

@Injectable({
  providedIn: 'root'
})
export class userPlans {
  constructor(
    private _http: HttpClient,
    private commonService: CommonService
  ) {}

  addUserPlan(userPlan: UserPlan) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.createUserPlan,
      userPlan
    );
  }

  updateSubscriptionPackage(options: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.updateSubscriptionPackage,
      {
        ...options
      }
    );
  }

  createBillingPortal(configuration: any) {
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.billingPortal,
      configuration
    );
  }

  mineSubscribedPlan(features?: any) {
    // let params = new HttpParams();
    // if (features) {
    //   params = params.append('features', features);
    // }
    // const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.minePlan
    );
  }
  userSoldPlans() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.userSoldPlan
    );
  }

  getUserPlans(id: any) {
    let params = new HttpParams();
    params = params.append('profile_id', id);
    const options = { params: params };

    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getPlans,
      options
    );
  }

  publicPlans(user_name: any) {
    let params = new HttpParams();
    params = params.append('user_name', user_name);
    const options = { params: params };

    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.publicPlans,
      options
    );
  }

  publicAllPlans(params: any) {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.publicAllPlans,
      {
        params: {
          ...params
        }
      }
    );
  }

  updateUserPlan(params: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.updateUserPlan,
      params
    );
  }

  getUserPlanPrice(account_id: any) {
    const plan = {
      limit: 100,
      expand: ['data.product']
    };
    let params = new HttpParams();
    params = params.append('account_id', account_id);
    const options = { params: params };
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getPlanPrice,
      plan,
      options
    );
  }

  publicAccountPrices(account_id: any) {
    const plan = {
      limit: 100,
      expand: ['data.product']
    };
    let params = new HttpParams();
    params = params.append('account_id', account_id);
    const options = { params: params };
    return this._http.post(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.publicAccountPrices,
      plan,
      options
    );
  }

  userPlanSubscription() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getPlateformPlan
    );
  }

  getStripeUser(redirect_url: string, refresh_url:string) {
    let params = new HttpParams();
    params = params.append('redirect_url', redirect_url);
    params = params.append('refresh_url', refresh_url);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.getStripeUser,
      options
    );
  }

  stripeUser() {
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.stripeUser,
    );
  }

  deletePlan(plan: any) {
    let updatedPlan = {
      _id: plan._id,
      stripe_product_id: plan.stripe_product_id,
      active: plan.active ? false : true
    };
    return this._http.patch(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.deleteUserPlan,
      updatedPlan
    );
  }

  deleteAllPlan() {
    let updatedPlan = {
      active: false
    };
    return this._http.patch(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.deleteAllUserPlan,
      updatedPlan
    );
  }

  deleteMultiplePlan(plans: any) {
    return this._http.put(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.deleteMultipleUserPlan,
      plans
    );
  }
}
