import { Component, OnInit } from '@angular/core';

export type Influencer = {
  id: string,
  img: string,
  name: string,
  location: string,
  followN: number
}

@Component({
  selector: 'app-review-influencers',
  templateUrl: './review-influencers.component.html',
  styleUrls: ['./review-influencers.component.scss']
})
export class ReviewInfluencersComponent implements OnInit {
  influencers: Influencer[] = [
    {
      id: '0',
      img: '/assets/images/influencers/img-influencer-1.jpg',
      name: 'valentinaacevedo',
      location: 'London, UK',
      followN: 329000
    },
    {
      id: '1',
      img: '/assets/images/influencers/img-influencer-2.jpg',
      name: 'valentinaacevedo',
      location: 'California',
      followN: 1329000
    },
    {
      id: '2',
      img: '/assets/images/influencers/img-influencer-3.jpg',
      name: 'valentinaacevedo',
      location: 'California',
      followN: 838
    },
    {
      id: '3',
      img: '/assets/images/influencers/img-influencer-4.jpg',
      name: 'valentinaacevedo',
      location: 'California',
      followN: 90000
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  approve(id: string) {
    this.influencers = this.influencers.filter(item => item.id !== id)
  }

  reject(id: string) {
    this.influencers = this.influencers.filter(item => item.id !== id)
  }

  approveAll() {
    this.influencers = []
  }

  kFormatter(num: number) {
    return num > 999 ? (num/1000 + 'K') : num
  }
}
