import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { routesConst } from 'src/app/core/const/routers';
import { loginSignUpConstant } from 'src/app/const/appConst';

@Component({
  selector: 'app-join-community',
  templateUrl: './join-community.component.html',
  styleUrls: ['./join-community.component.scss']
})
export class JoinCommunityComponent implements OnInit {

  forIndividual: boolean = true;
  routesConst = routesConst;
  loginSignUpConstant = loginSignUpConstant;
  contactObject={
    label: 'Contact',
    url: routesConst.pricing,
    ismodal: false,
    modalToOPen: ContactUsComponent
  }
  constructor(    private dialog: MatDialog,    private _router: Router ,    public authService: AuthService,  ) { }

  ngOnInit(): void {
  }

  navigateTo(item: any, param?: string) {
    if (item?.url.includes('https')) {
      window.open(item?.url, '_blank');
    } else if (item?.ismodal) {
      this.dialog.open(item.modalToOPen, {
        width: '536px',
        height: innerWidth && innerWidth < 768 ? '100%' : 'fit-content',
        maxHeight: innerWidth && innerWidth < 768 ? '100vh' : '650px',
        maxWidth: '100vw',
        hasBackdrop: false
      });
    } else {
      this._router.navigate([item?.url], {
        queryParams: {
          tab: param
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  openSignUp(pageToOpen: string) {
    if (!this.authService.loggedIn()) {
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen }
      });
    } else {
      this._router.navigate(['/' + this.routesConst.dashboard + '/' + this.routesConst.account]);
    }
  }
}
