<div class="about-me section-wrapper bg-white p-4 lg:p-10">
    <div>
        <h5 class="montserrat-bold mt-6 lg:mt-8">Title</h5>
        <input class="input-form w-full my-2 px-4" [value]="data.title" readonly type="text" />
    </div>
    <div class="flex flex-row">
        <h5 class="montserrat-bold mt-6">start</h5>
        <p>{{data.start | date:"short"}}</p>
        <h5 class="montserrat-bold mt-6">end</h5>
        <p>{{data.end | date:"short"}}</p>
    </div>
    <div>
        <h5 class="montserrat-bold mt-6">Description</h5>
        <textarea class="w-full border-normal rounded mt-2 mb-6 px-4">{{data.description}}</textarea>
    </div>
</div>