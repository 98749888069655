import { Directive, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'

/** 
 * This class is used to manage Observable subscriptions in components.
 * It unsubscribes from obervables to avoid any memory leakage casued by incompleted subscriptions.
 *
 * How to use?
 * - Each component that includes an observable subscription should implement SubscriptionManagment
 * - Each .subscription should be proceeded with pipe(takeUnitl(this.componetDestroyed))
 * - If the subscription is already procedded with a pipe, then takeUnitl(this.componetDestroyed) should be the last operator
 *
 * @remark @Directive() is needed because the class will be extended by components.
 * Source: https://angular.io/guide/migration-undecorated-classes
 */

@Directive()
export abstract class SubscriptionManagmentDirective implements OnDestroy {
  componetDestroyed = new Subject<void>()

  constructor() {}

  ngOnDestroy(): void {
    this.componetDestroyed.next()
    this.componetDestroyed.unsubscribe()
  }
}
