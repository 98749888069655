<div class="apply-modal">
  <div class="flex w-full header absolute justify-end pr-0.5">
  <img class="w-10 absolute top-2 left-3" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">
    <p class="text-white montserrat-bold ">Application</p>
    <mat-icon
    class="text-white absolute right-2.5 top-2 mt-1 cursor-pointer"
    mat-dialog-close
    >close</mat-icon>
  </div>
  <!-- <h3
      class="job-title black-dark montserrat-bold mr-4 mt-2 text-center"
      *ngIf="data?.job?.job?.job_title"
    >
      {{ data.job.job.job_title }}
    </h3> -->
  <!-- <div
    id="jobInfo"
    class="
      job-info
      fixed
      lg:relative
      flex flex-col
      justify-start
      overflow-y-auto
      p-3.5
      lg:p-6
      z-20
    "
  >
    <div
      class="
        absolute
        bottom-4
        right-0
        flex
        lg:hidden
        expand-header-icon
        ml-auto
        items-center
        cursor-pointer
        -mt-10
        mr-4
      "
      [class]="headerExpanded ? 'expanded' : ''"
      (click)="expandHeader()"
    >
      <mat-icon>chevron_right</mat-icon>
    </div>
    <p class="s montserrat-bold uppercase">apply to</p>
    <div class="flex items-center my-2 lg:my-4">
      <div class="thumb-img mt-1 lg:mt-0 mr-4" *ngIf="data?.job?.company?.profile_img_path">
        <img
          [src]="data?.job?.company?.profile_img_path"
          class="mr-1"
        />
      </div>
      <h4 class="blue montserrat-bold break-all" *ngIf="data?.job?.company?.name">
        {{ data.job.company.name }}
      </h4>
    </div>
    <h3
      class="job-title black-dark montserrat-bold mr-4"
      *ngIf="data?.job?.job?.job_title"
    >
      {{ data.job.job.job_title }}
    </h3>
    <div class="flex items-center mt-4">
      <mat-icon class="gray-dark pb-0.5 mr-2.5 blue" style="margin-left: 1px"
        >room</mat-icon
      >
      <p *ngIf="data?.job?.job">
        {{ getLocation(data.job.job) }}
      </p>
    </div>
    <div class="flex items-center mt-4">
      <mat-icon
        class="gray-dark pb-0.5 ml-0.5 mr-3"
        style="font-size: 20px; width: 20px; height: 20px"
      >
        account_balance_wallet</mat-icon
      >
      <p *ngIf="data.job.job.salary_range">
        {{ data.job.job.salary_range }}
      </p>
    </div>
    <div class="flex items-center mt-4">
      <mat-icon
        class="gray-dark pb-0.5 ml-0.5 mr-3"
        style="font-size: 20px; width: 20px; height: 20px"
        >watch_later
      </mat-icon>
      <p>{{ data.job.job.job_type }}</p>
    </div>
    <p class="s montserrat-bold uppercase mt-10">recruiter</p>
    <div class="flex items-center mt-4 flex-wrap">
      <p placeholder="imagg"></p>
      <a
        class="s montserrat-bold blue cursor-pointer"
        (click)="sendCompanyId(data.job.company)"
        routerLink="/dashboard/messages"
        >Message</a
      >
    </div>
  </div> -->
  <div
    id="applyPart"
    class="
      apply-side-wrapper
      overflow-auto
      flex flex-col
      justify-start
      mt-4
      px-4
    "
  >
    <p class="text-black lg:black lg:s montserrat-bold mb-1.5">
      You’re application
    </p>
    <p class="flex mb-2">
      Experience and skills
    </p>
    <div class="flex items-center gap-2 mt-2" [routerLink]="'/profile/'+loginUser.profileInfo.user_name">
      <img mat-dialog-close
          class="w-10 h-10 object-cover rounded-full cursor-pointer"
          [src]="loginUser.profileInfo.avatar"
          onerror="this.src='assets/images/no-image.png'"
        />
        <div>
          <p class="montserrat-bold break-all text-black mx-1.5 cursor-pointer" mat-dialog-close>
            @{{ loginUser.profileInfo.user_name }}
          </p>
          <p class="break-all black-light mx-1.5 cursor-pointer mt-1" mat-dialog-close>
            {{ loginUser?.profileInfo?.location[0]?.city_name}} {{ loginUser?.profileInfo?.location[0]?.state_name}} 
            {{ loginUser?.profileInfo?.location[0]?.country_name}}
          </p>

        </div>
    </div>
    <!-- <h4 class="montserrat-bold black-dark mt-6 mb-2 lg:mb-3">
      Highlight your experience
    </h4>
    <p class="mb-2.5 lg:mb-4">
      Select up to 5 pieces of your work to send the recruiter along with your
      application.
    </p>
    <div
      class="flex flex-col lg:flex-row mt-6 lg:mt-3"
      *ngIf="experiencePortfolio.length === 0"
    >
      <p class="mt-1">You haven’t uploaded work to your portfolio.</p>
      <label class="montserrat-bold blue ml-0 lg:ml-2 cursor-pointer">
        Upload your first piece now.
        <input
          #file
          multiple
          type="file"
          accept="image/*"
          class="uploadFile img w-0 h-0 overflow-hidden"
          value="Upload Media"
          (change)="file.files ? uploadImage(file.files) : ''"
        />
      </label>
    </div>
    <div
      *ngIf="experiencePortfolio.length > 0"
      class="portfolio-list flex flex-wrap mt-3"
    >
      <div
        *ngFor="let image of experiencePortfolio"
        class="relative w-1/2 lg:w-1/3 p-0.5"
      >
        <mat-icon
          *ngIf="image.active"
          class="
            cursor-pointer
            red
            bg-white
            rounded-full
            absolute
            right-1
            top-1
            z-10
          "
          (click)="removeExperience(image.id)"
          >cancel</mat-icon
        >
        <div
          [id]="image.id"
          class="portfolio h-52 w-full bg-contain bg-no-repeat bg-center"
          [class]="imgUploading ? 'spinner' : ''"
          [ngStyle]="{ 'background-image': 'url(' + image.path + ')' }"
        ></div>
      </div>
    </div>
    <div class="flex justify-between">
      <label *ngIf="experiencePortfolio.length > 0 "class="mt-1 ml-2 cursor-pointer">
        <mat-icon class="cursor-pointer upload hover:bg-gray-300 rounded-full"
          >file_upload</mat-icon
        >
        <input
          #file
          multiple
          type="file"
          accept="image/*"
          class="uploadFile img w-0 h-0 overflow-hidden"
          value="Upload Media"
          (change)="file.files ? uploadImage(file.files) : ''"
        />
      </label>
      <div
        *ngIf="experiencePortfolio.length > 6 && !viewMore"
        class="ml-auto flex items-center cursor-pointer"
        (click)="viewMore = !viewMore"
      >
        <p class="blue">View more</p>
        <mat-icon class="blue">arrow_right_alt</mat-icon>
      </div>
    </div> -->
    <p class="montserrat-bold lg:black text-black mt-6 mb-1">Tell us more about you</p>
    <p>Why are you a good fit for this position</p>
    <textarea
      [(ngModel)]="coverLetter"
      class="cover-letter-form border-normal rounded w-full mt-4"
    ></textarea>
    <!-- <p class="s gray-dark my-4">
      Recruiters will see the information you submit here as well as your bio.
      Make sure it is up to date so it increases your chances of getting hired.
    </p> -->
    <p class="montserrat-bold lg:black text-black mt-6 mb-1">
      Reminder
    </p>
    <p class="mb-2.5 lg:mb-4">
      Your name, about, category, experience level, skills, client projects, work history, education
  will be on your submitted application.

    </p>
  </div>
  <div class="mt-8 sticky flex justify-center py-2 items-center bottom-0 bg-white ">
    <button mat-button class="button custom-border  mb-3" [disabled]="!applyJob" (click)="sendApplication()">
      Submit
    </button>
  </div>
</div>
