<!-- <div
  class="create-page bg-gradient-to-b from-white md:from-gray-100 via-gray-300 md:via-gray-100 to-white md:to-gray-100 py-16 flex flex-col md:justify-center">
  <div class="absolute left-2 top-4 md:left-10 md:top-10">
    <app-go-back></app-go-back>
  </div>
  <div class="flex items-center justify-center">
    <mat-icon class="blue" style="font-size: 40px; height: 40px; width: 40px">add_circle</mat-icon>
    <span class="montserrat-bold text-xl uppercase ml-3">Create</span>
  </div>

  <div class="flex flex-col items-center create-page_content mt-6">
    <div class="w-full flex justify-evenly">
      <div class="w-full md:w-3/4 lg:w-2/3 2xl:w-1/2 flex justify-evenly link-border-bottom">
        <a *ngIf="type === userTypeConstant.company"
          class="cursor-pointer text- md:mx-3 sm--text md:border-b-2 md:border-gray-100 md:text-lg" (click)="postJob()"
          routerLinkActive="router-link-active"> Post Job</a>
        <a class="cursor-pointer text- md:mx-3 sm--text md:border-b-2 md:border-gray-100 md:text-lg"
          (click)="uploadPost()">Content</a>
        <a class="text- md:mx-3 sm--text md:border-b-2 md:border-gray-100 md:text-lg" [routerLink]="
            '/' + routesConst.dashboard + '/' + routesConst.services
          " routerLinkActive="router-link-active">Services</a>
        <a class="text- md:mx-3 sm--text md:border-b-2 md:border-gray-100 md:text-lg" [routerLink]="
            '/' + routesConst.dashboard + '/' + routesConst.subscription
          " routerLinkActive="router-link-active">Subscriptions</a>
      </div>
    </div>
    <div class="w-full md:w-3/4 lg:w-2/3 2xl:w-1/2 relative intro-video bg-gray-700 mt-8">
      <iframe class="absolute w-full h-full top-0 left-0"
        src="https://www.youtube.com/embed/Zum3608zxYI?autoplay=1&mute=1&loop=1&playlist=Zum3608zxYI,sn1cMbJj350,EnUEXZh2Gjo"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
    <div class="mt-4">
      <p class="text-2xl montserrat-medium mt-4 text-center">Follow Us On</p>
      <div class="grid grid-cols-6 gap-4 mt-4">
        <div class="link-wrapper flex justify-center" *ngFor="let item of links">
          <a [href]="item.url" [class]="'link link-' + item.class"></a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoggedIn" class="flex items-center mt-20 mx-auto">
    <a (click)="openSignUp('login')" class="cursor-pointer border-2 btn-border rounded black-dark montserrat-semibold px-4 py-1">Log In</a>
    <a (click)="openSignUp('SignUp')" class="cursor-pointer border-2 btn-border btn-bg rounded text-white montserrat-semibold px-4 py-1 ml-3">Sign Up</a>
  </div>
</div> -->

<app-part-header [data]="partCreateHeaderData"></app-part-header>

<div class="mt-2">
  <app-range-slider [data]="createRangeSliderData" [page]="'create'"></app-range-slider>
</div>

<app-part-home [data]="create"></app-part-home>

<!-- <div>
  <div class="section-main">
    <div class="section-2-main">
      <div class="flex flex-col w-full mx-auto">
        <p class="lets-grow-together">LET’S GROW TOGETHER</p>
        <p class="join-heading">Join our growing community of diverse global members</p>
        <p class="section-title-divider"></p>
        <p class="description">Come experience financial freedom with some
          of the best creators, experts, and companies in 
          your industry.</p>
      </div>
      <div class="images-main">
        <div class="images"><img src="../../../../../assets/images/home/img-hero.png" alt=""></div>
        <div class="images"><img src="../../../../../assets/images/home/img-hero.png" alt=""></div>
        <div class="images"><img src="../../../../../assets/images/home/img-hero.png" alt=""></div>
      </div>
    </div>
  </div>
</div> -->

<app-part-footer></app-part-footer>