<div class="my-jobs-page">
  <div class="hidden lg:flex items-center justify-between pt-md-3 px-5 lg:px-0">
    <h1 class="montserrat-bold black-dark text-style window-mode">Jobs</h1>
    <!-- <div class="ml-auto">
      <button mat-button *ngIf="type === registerValues.UserTypes.company" (click)="checkEligibility()"
        class="button outlined black txt-size">
        Post Job
      </button>
    </div> -->
    <!-- <button mat-button routerLink="{{ '/' + routesConst.jobs }}"
      *ngIf="type === registerValues.UserTypes.candidate" class="montserrat-medium button outlined black">
      Search  -->
      <!-- <i class="fa fa-long-arrow-alt-right ml-1"></i> -->
    <!-- </button> -->
  </div>
  <div class="mt-4 mx-4 lg:mx-0 dashboard-jobs">
    <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container jobs-swipper common-arrow">
      <ng-template swiperSlide *ngIf="isAdmin === true">
        <div  class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 0 " [class]="selected.value === 0 ? 'selected' : ''">
          <p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
            Overview
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngIf="type == userRole.company">
        <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = isAdmin === true ? 1 : (isAdmin===false && type == userRole.company)? 0 : 1" [class]="
            (isAdmin===true && selected.value === 1) ||
            (isAdmin===false && type == userRole.company && selected.value === 0)
              ? 'selected'
              : ''
          ">
          <p class="tab-size" [class]="
              (isAdmin===true && selected.value === 1) ||
              (isAdmin===false && type == userRole.company && selected.value === 0)
                ? 'blue montserrat-bold'
                : ''
            ">
            {{isAdmin ? "Active Jobs" : "Jobs"}}
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value= isAdmin?2:!isAdmin && type== userRole.company?1:(!isAdmin && type!= userRole.company)?0:1" [class]="
            (isAdmin && selected.value === 2) ||
            (!isAdmin && type== userRole.company && selected.value === 1)||
            (!isAdmin && type!= userRole.company && selected.value === 0)
              ? 'selected'
              : ''
          ">
          <p class="tab-size" [class]="(isAdmin && selected.value === 2) || (!isAdmin && type== userRole.company && selected.value === 1)||
              (!isAdmin && type!= userRole.company && selected.value === 0) ? 'blue montserrat-bold' : ''">
            {{isAdmin ? "Active Jobs" : "Applications"}}
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngIf="type!= userRole.company">
        <div  class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = isAdmin?2:1" [class]="
            (isAdmin === true && selected.value === 2) ||
            (isAdmin === false && selected.value === 1)
              ? 'selected'
              : ''
          ">
          <p class="tab-size" [class]="
              (isAdmin === true && selected.value === 2) ||
              (isAdmin === false && selected.value === 1)
                ? 'blue montserrat-bold'
                : ''
            ">
            Preferences
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = isAdmin?3:2" [class]="
            (isAdmin === true && selected.value === 3) ||
            (isAdmin === false && selected.value === 2)
              ? 'selected'
              : ''
          ">
          <p class="tab-size" [class]="
              (isAdmin === true && selected.value === 3) ||
              (isAdmin === false && selected.value === 2)
                ? 'blue montserrat-bold'
                : ''
            ">
            Saved
          </p>
        </div>
      </ng-template>
      <ng-template swiperSlide *ngIf="type == userRole.company">
        <div
          class="flex items-center pb-1 tab cursor-pointer"
          (click)="selected.value = isAdmin ? 4 : 3; checkEligibility()"
          [class]="
            (isAdmin === true && selected.value === 4) ||
            (isAdmin === false && selected.value === 3)
              ? ''
              : ''
          "
        >
          <button class="button outlined tab-size"
            [class]="
              (isAdmin === true && selected.value === 4) ||
              (isAdmin === false && selected.value === 3)
                ? 'blue montserrat-bold'
                : ''
            "
          >
            + Add
          </button>
        </div>
      </ng-template>
    </swiper>
    <mat-tab-group color="#fff0" [selectedIndex]="selected.value"
      (selectedIndexChange)="selected.setValue($event)">
      <mat-tab *ngIf="isAdmin === true">
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
            <p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
              Overview
            </p>
          </div>
        </ng-template>
        <app-admin-jobs-overview></app-admin-jobs-overview>
      </mat-tab>
      <mat-tab *ngIf="type == userRole.company">
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="
              (isAdmin===true && selected.value === 1) ||
              (isAdmin===false && type == userRole.company && selected.value === 0)
                ? 'selected'
                : ''
            ">
            <p class="tab-size" [class]="
                (isAdmin===true && selected.value === 1) ||
                (isAdmin===false && type == userRole.company && selected.value === 0)
                  ? 'blue montserrat-bold'
                  : ''
              ">
              {{isAdmin ? "Active Jobs" : "My Jobs"}}
            </p>
          </div>
        </ng-template>
        <app-my-applications *ngIf="profile?._id" [selected]="selected.value" [userId]="profile._id"
          [selectedJobId]="selectedJobId" [jobFilter]="jobFilters" [totalAvaileJobs]="totalAvaileJobs"
          [totalcount]="totalcount" [jobs0]="jobs" [type]="profile.user_type" (removejob)="removejob($event)"
          (removeCompanyJob)="removeCompanyJob($event)" (jobselectedCategory)="jobselectedCategory($event)"
          (searchInputValue)="searchByKeyword($event)" (refreshJobs)="refreshJobs($event)">
        </app-my-applications>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="
              (isAdmin && selected.value === 2) ||
              (!isAdmin && type == userRole.company && selected.value === 1) ||
              (!isAdmin && type != userRole.company && selected.value === 0)
                ? 'selected'
                : ''
            ">
            <p class="tab-size" [class]="(isAdmin && selected.value === 2) || (!isAdmin && type== userRole.company && selected.value === 1)||
                (!isAdmin && type!= userRole.company && selected.value === 0) ? 'blue montserrat-bold' : ''">
              {{isAdmin ? "Active Jobs" : "Applications"}}
            </p>
          </div>
        </ng-template>
        <app-my-applications *ngIf="profile?._id" [selected]="selected.value" [userId]="profile._id"
          [selectedJobId]="selectedJobId" [jobFilter]="jobFilters" [totalAvaileJobs]="totalAvaileJobs"
          [totalcount]="totalcount" [jobs0]="appliedJobs" [type]="profile.user_type" (removejob)="removejob($event)"
          (removeCompanyJob)="removeCompanyJob($event)" (jobselectedCategory)="jobselectedCategory($event)"
          (searchInputValue)="searchByKeyword($event)" (refreshJobs)="refreshJobs($event)">
        </app-my-applications>
      </mat-tab>
      <mat-tab *ngIf="type!= userRole.company">
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="
              (isAdmin === true && selected.value === 2) ||
              (isAdmin === false && selected.value === 1)
                ? 'selected'
                : ''
            ">
            <p class="tab-size" [class]="
                (isAdmin === true && selected.value === 2) ||
                (isAdmin === false && selected.value === 1)
                  ? 'blue montserrat-bold'
                  : ''
              ">
              Preferences
            </p>
          </div>
        </ng-template>
        <!-- <app-preferences *ngIf="profile?.user_type && profile.user_type== userRole.company" [userData]="profile"
          [preferences]="preferences$"></app-preferences> -->
        <app-user-prefrences *ngIf="profile?.user_type &&profile.user_type == userRole.candidate" [userData]="profile"
          [candidateJobPreference]="candidateJobPreference" (updatedUserPreferences)="updateUserPreferences($event)">
        </app-user-prefrences>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="
              (isAdmin === true && selected.value === 3) ||
              (isAdmin === false && selected.value === 2)
                ? 'selected'
                : ''
            ">
            <p class="tab-size" [class]="
                (isAdmin === true && selected.value === 3) ||
                (isAdmin === false && selected.value === 2)
                  ? 'blue montserrat-bold'
                  : ''
              ">
              Saved
            </p>
          </div>
        </ng-template>
        <app-my-applications *ngIf="profile?._id" [selected]="selected.value" [userId]="profile._id"
          [jobFilter]="jobFilters" [totalAvaileJobs]="totalAvaileJobs" [totalcount]="totalcount" [jobs0]="savedJobs"
          [type]="profile.user_type" (removejob)="removejob($event)" (removeCompanyJob)="removeCompanyJob($event)"
          (jobselectedCategory)="jobselectedCategory($event)" (searchInputValue)="searchByKeyword($event)"
          (refreshJobs)="refreshJobs($event)">
        </app-my-applications>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="flex items-center pb-1 tab"
            [class]="
              (isAdmin === true && selected.value === 4) ||
              (isAdmin === false && selected.value === 3)
                ? 'selected'
                : ''
            "
          >
            <p
              [class]="
                (isAdmin === true && selected.value === 4) ||
                (isAdmin === false && selected.value === 3)
                  ? 'blue montserrat-bold'
                  : ''
              "
            >
              + Add
            </p>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
  [fullScreen]="true" class="job-list-spinner">
</ngx-spinner>