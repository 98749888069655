import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModule } from 'src/app/modules/home/home.module';
import { JobsModule } from 'src/app/modules/jobs/jobs.module';
import { UsersModule } from 'src/app/modules/users/users.module';
import { DashboardModule } from 'src/app/modules/dashboard/dashboard.module';
import { PrivacyComponent } from 'src/app/modules/privacy/privacy.component';
import { TermsComponent } from 'src/app/modules/terms/terms.component';
import { DsarComponent } from 'src/app/modules/dsar/dsar.component';
import { TalentsModule } from 'src/app/modules/talents/talents.module';
import { IndividualServiceModule } from 'src/app/modules/individual-service/individual-service.module';
import { DsnComponent } from './dsn/dsn.component';
import { ExploreModule } from './explore/explore.module';
import { CreateModule } from './create/create.module';
import { EarnModule } from './earn/earn.module';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CookiesPreferencesComponent } from './cookies-preferences/cookies-preferences.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { IndividualSubscriptionModule } from './individual-subscription/individual-subscription.module';
import { FrequentlyAskQuestionComponent } from '../shared/components/frequently-ask-question/frequently-ask-question.component';
@NgModule({
  declarations: [
    PrivacyComponent,
    TermsComponent,
    DsarComponent,
    DsnComponent,
    DisclaimerComponent,
    CookiesPreferencesComponent,
    AboutUsComponent,
    FrequentlyAskQuestionComponent
  ],
  imports: [
    CommonModule,
    CreateModule,
    ExploreModule,
    EarnModule,
    HomeModule,
    JobsModule,
    UsersModule,
    DashboardModule,
    TalentsModule,
    IndividualSubscriptionModule,
    IndividualServiceModule,
    SharedModule
  ]
})
export class ModulesModule {}
