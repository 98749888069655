import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { loginSignUpConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';

@Component({
  selector: 'app-part-header',
  templateUrl: './part-header.component.html',
  styleUrls: ['./part-header.component.scss']
})
export class PartHeaderComponent implements OnInit {
  loginSignUpConstant = loginSignUpConstant;
  @Input() data: any;

  constructor(private router: Router, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void { }

  navigateTo(url: any) {
    if (url) {
      this.router.navigate([url])
    } else {
      if (!this.authService.loggedIn()) {
        this.dialog.open(LoginSignupDialogComponent, {
          width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
          data: { pageToOpen: loginSignUpConstant.signup },
        });
      }
      else {
        this.router.navigate(['/' + routesConst.dashboard + '/' + routesConst.account])
      }
    }
  }

}

