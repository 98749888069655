import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    document.body.appendChild(script);
  }
}
