<div class="flex flex-col lg:flex-row items-center justify-between my-20 lg:my-5">
  <div class="flex items-center rounded overflow-hidden">
    <button [class.active]="isPrev" mat-flat-button class="md:w-28  w-24 lg:w-auto button round-zero" mat-flat-button
      mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay('prev')">
      <span class="white">Previous</span>
    </button>
    <button [class.active]="isCurrent" mat-flat-button class="md:w-28  w-24 mx-1 lg:w-auto button normal round-zero"
      mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay('current')">
      <span>Today</span>
    </button>
    <button [class.active]="isNext" mat-flat-button class="md:w-28  w-24 lg:w-auto button round-zero" mat-flat-button
      mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay('next')">
      <span class="white">Next</span>
    </button>
  </div>
  <p class="my-2 lg:my-0"><b>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</b></p>
  <div class="flex items-center rounded overflow-hidden">
    <button mat-flat-button class="w-24 lg:w-auto button round-zero" (click)="setView(CalendarView.Month)"
      [class.active]="view === CalendarView.Month">
      <span class="white">Month</span>
    </button>
    <button mat-flat-button class="w-24 mx-1 lg:w-auto button round-zero" (click)="setView(CalendarView.Week)"
      [class.active]="view === CalendarView.Week">
      <span class="white">Week</span>
    </button>
    <button mat-flat-button class="w-24 lg:w-auto button round-zero" (click)="setView(CalendarView.Day)"
      [class.active]="view === CalendarView.Day">
      <span class="white">Day</span>
    </button>
  </div>
</div>
<div [ngSwitch]="view">
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
    [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view>
</div>

<div class="md:mt-8 mt-4 md:p-8 bg-white mb-16" *ngIf="events.length">
  <p class="uppercase black-light montserrat-bold mb-4">Events</p>
  <hr class="border-1 my-4">
  <table class="w-full tr-style">
    <tr>
      <th>Name</th>
      <th>Start Date</th>
      <th>End Date</th>
      <th>Description</th>
      <th>Delete</th>
    </tr>
    <tr class="h-16" *ngFor="let item of events">
      <td ><div class="titles-event">{{item.title}}</div></td>
      <td>{{item.start | date: 'short'}}</td>
      <td>{{item.end | date: 'short'}}</td>
      <td >
          <div class="description-event">{{item.description}}</div>
      </td>
      <td>
        <i class=" cursor-pointer fas fa-fw fa-trash-alt" (click)="deleteEvent(item)"></i>
      </td>
    </tr>
  </table>
</div>


<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>