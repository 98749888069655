import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { planTypeConstant, statusConstant, userTypeConstant } from 'src/app/const/appConst';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { JobPostComponent } from 'src/app/shared/components/job-post/job-post.component';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { ServiceModalComponent } from 'src/app/shared/components/service-modal/service-modal.component';
import { SubscriptionPackageModalComponent } from 'src/app/shared/components/subscription-package-modal/subscription-package-modal.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { routesConst } from '../../const/routers';
import { AuthService } from '../../services/auth/auth.service';
import { CommonService } from '../../services/common.service';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { JobService } from '../../services/job.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit {
  userTypeConstant = userTypeConstant;
  colorVariable: string = '';
  private unsubscribe$ = new Subject();
  public dialogRef!: MatDialogRef<ConfirmModalComponent>;
  userType: string = userTypeConstant.candidate;
  mySubscriptions: any;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private configuration: ConfigurationService,
    private userPlan: userPlans,
    private commonService: CommonService,
    private sharedService: SharedService,
    public jobService: JobService,
    private snack: SnackBarService,
    private spinner: NgxSpinnerService,
    private profileService: ProfileService,
    private authService: AuthService,
    private dialog: MatDialog,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sharedService.bottomSheetElement$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((element: any) => {
        this.colorVariable = element;
      });
    if (this.authService.loggedIn()) {
      this.getLoginedUserType();
      this.getPlateformPlans();
    }
  }

  onDragMoved(event: any) {
    if (event?.distance?.y < 0) {
      event.source._dragRef.reset();
      event.event.preventDefault();
    }
  }

  onDragEnded(event: any) {
    if (event.distance.y < 150) {
      event.source._dragRef.reset();
      event.event.preventDefault();
    } else {
      this._bottomSheetRef.dismiss();
    }
  }

  closeSheet(event: any) {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  navigateTo(event: any, url: string, openDialog?: any) {
    if (openDialog) {
      if (!this.authService.loggedIn()) {
        this.router.navigate(['/']);
        this.dialog.open(LoginSignupDialogComponent, {
          width: window.innerWidth > 600 ? '400px' : '100vw',
          height: window.innerWidth > 600 ? 'h-fit' : '100%',
          maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
          maxHeight: window.innerWidth > 600 ? '600' : '100vh',
          data: { pageToOpen: 'login' }
        });
        this.closeSheet(event);
      } else {
        if (openDialog == routesConst.content) {
          this.dialog.open(UploadPostModalComponent, {
            maxWidth: '100vw',
            width: '536px',
            disableClose: true,
            height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
            data: {}
          });
          this.closeSheet(event);
        }

        switch (openDialog) {
          case routesConst.subscription:
            this.newSubscriptionPackage(event);
            break;
          case routesConst.jobs:
            this.checkEligibility(event);
            break;
          case routesConst.talents:
            this.addNewService(event);
            break;
          case routesConst.campaign:
            this.postCampaign(event);
            break;
          default:
            break;
        }
      }
    } else {
      this.closeSheet(event);
      if (url == this.router.url) {
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => this.router.navigate([url]));
      } else {
        this.router.navigate([url]);
      }
    }
  }

  checkEligibility(event: any): void {
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.closeSheet(event);
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToAddJob(event);
        }
      });
  }

  proceedToAddJob(event: any) {
    this.spinner.show();
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.spinner.hide();
          if (!res.currentPlan) {
            this.closeSheet(event);
            this.snack.showMessage('Please purchase a paid plan to post job', true);
            this.router.navigate(['/' + routesConst.planPricing]);
            return;
          }
          if (res.currentPlan.plan_type == planTypeConstant.recruiter) {
            this.jobService
              .checkEligibility()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.result) {
                  const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                      message: `Requires membership upgrade to BUSINESS, ENTERPRISE or NON-PROFIT`,
                      header: 'Unlimited Job Posting'
                    }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/' + routesConst.planPricing]);
                    }
                  });
                } else {
                  this.openJobPostDialog(event);
                }
              });
          } else {
            this.openJobPostDialog(event);
          }
        }
      });
  }

  openJobPostDialog(event: any) {
    this.closeSheet(event);
    this.dialog.open(JobPostComponent, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      hasBackdrop: false,
      panelClass: 'post-job-dialog-container'
    });
  }

  getLoginedUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      this.userType = type;
      if (type === '') {
        this.sharedService.getUserType();
      }
    });
  }

  addNewService(event: any) {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewService(event);
        }
      });
  }

  proceedToNewService(event: any) {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    const plan1 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.business;
    const plan2 =
      this.userType === userTypeConstant.candidate
        ? planTypeConstant.pro
        : planTypeConstant.enterprise;
    if (
      this.mySubscriptions?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan1.toLowerCase()
      ) != -1 ||
      this.mySubscriptions?.findIndex(
        (item: any) => item.plan_type.toLowerCase() == plan2.toLowerCase()
      ) != -1
    ) {
      this.configuration
        .configureCustomerPortal()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.spinner.hide();
            this.closeSheet(event);
            this.dialog.open(ServiceModalComponent, {
              maxWidth: '100vw',
              width: '536px',
              height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
              data: { editmode: false },
              disableClose: true
            });
          },
          (error: any) => {
            this.spinner.hide();
            this.snack.showMessage(`${error} `, true);
          }
        );
    } else {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: {
          message: `Requires membership upgrade to PRO, ELITE, BUSINESS or ENTERPRISE`,
          header: 'Create Expert Service'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate(['/' + routesConst.planPricing]);
        }
      });
      this.spinner.hide();
    }
  }

  getPlateformPlans() {
    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plan: any) => {
        const { platform_subscription } = plan;
        this.mySubscriptions = platform_subscription;
      });
  }

  postCampaign(event: any) {
    this.profileService.getpurchasedPlan().subscribe((res: any) => {
      let userCurrentPlan = res.currentPlan;
      this.closeSheet(event);
      if (
        userCurrentPlan?.plan_type == planTypeConstant.elite ||
        userCurrentPlan?.plan_type == planTypeConstant.nil ||
        userCurrentPlan?.plan_type == planTypeConstant.enterprise
      ) {
        this.dialog.open(UploadPostModalComponent, {
          maxWidth: '100vw',
          width: '536px',
          disableClose: true,
          height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
          data: { isCampaign: true }
        });
      } else {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          width: '500px',
          data: {
            message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`,
            header: 'Campaign Features'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['/' + routesConst.pricing]);
          }
        });
      }
    });
  }

  newSubscriptionPackage(event: any) {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          this.spinner.hide();
          this.closeSheet(event);
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewSubscriptionPackage(event);
        }
      });
  }

  proceedToNewSubscriptionPackage(event: any) {
    let comparePlan =
      this.userType == userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.enterprise;
    this.spinner.show();
    this.configuration
      .configureCustomerPortal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (
          this.mySubscriptions?.findIndex(
            (item: any) => item.plan_type.toLowerCase() == comparePlan.toLowerCase()
          ) != -1 ||
          (this.userType === userTypeConstant.candidate &&
            this.mySubscriptions?.findIndex(
              (item: any) => item.plan_type.toLowerCase() == planTypeConstant.nil.toLowerCase()
            ) != -1)
        ) {
          const redirect_url = this.commonService.location().href
          const refresh_url = this.commonService.location().href
          this.userPlan
            .getStripeUser(redirect_url,refresh_url)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (user: any) => {
                this.spinner.hide();
                if (user.status == 'on_board_required') {
                  if (user?.accountLink?.url) {
                    this.commonService.location().href = user?.accountLink?.url;
                  }
                  this.snack.showMessage(`${user.message} `, true);
                } else {
                  this.closeSheet(event);
                  this.dialog.open(SubscriptionPackageModalComponent, {
                    width: '536px',
                    height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
                    maxWidth: '100vw',
                    hasBackdrop: false
                  });
                }
              },
              error => {
                this.spinner.hide();
                this.snack.showMessage(`${error} `, true);
              }
            );
        } else {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`,
              header: 'Create Subscriptions'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
          this.spinner.hide();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    // this.dialogRef?.close();
  }
}
