import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';
import { SwiperOptions } from 'swiper';
import { SharedService } from '../../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NetworkService } from 'src/app/core/services/network.service';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { shareTypeConst } from 'src/app/const/appConst';
import { PostDetailCardComponent } from '../post-detail-card/post-detail-card.component';

@Component({
  selector: 'app-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent implements OnInit {
  routesConst = routesConst;
  private unsubscribe$ = new Subject();
  @ViewChild('postsSection', { read: ElementRef }) postsSection!: ElementRef;
  config: SwiperOptions = {
    slidesPerView: 'auto'
  };
  storyConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20
  };
  user: any;
  followers: any = [];

  allTrendPosts: any = [];
  profiles: any = [];
  trendPosts: any = [];
  posts: any = [];
  postFilters = {
    offset: 0,
    limit: 10
  };
  postStoryFilters = { offset: 0, limit: 10 };
  totalcount: any = 0;
  mostviewPostOffset: any = 0;
  totalAvailePost: any;
  stories: any = [];
  selected = 0;
  relatedPosts0 = [];
  interestList: any[] = [];
  mostViewdPosts: any[] = [];
  postSize: number = 0;
  currentLoggedInUserId: any;
  usersPayload = { offset: 0, limit: 10 };
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private postService: PostService,
    private authService: AuthService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private networkService: NetworkService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private snackService: SnackBarService,
    private clipboard: Clipboard
  ) {}
  ngOnInit(): void {
    this._route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      if (params.tab === 'creators') {
        this.selected = 3;
      }
    });
    this.getInterestList();
    if (this.authService.loggedIn()) {
      this.dashboard();
      this.getNetworkPosts();
      this.getFollowersAndFollowings();
    } else {
      this.getTrends();
      this.getPopularPosts();
    }

    this.getTopUsers();
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(network => {
        if (network?.followings || network?.followers) {
          this.followers = network.followings;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  getInterestList() {
    this.sharedService.skillsOrIntrest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((interest: any) => {
        if (interest.length) {
          this.interestList = interest;
        } else {
          this.sharedService.getSkillsOrIntrest();
        }
      });
  }

  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information: { profileInfo: any } | null) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else if (information != null) {
          const { profileInfo } = information;
          this.user = profileInfo;
          this.currentLoggedInUserId = this.user?._id;
          this.getTrends();
        }
      });
  }

  getNetworkPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getNetworkPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex - 1);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  ScrollDown() {
    if (this.totalcount !== this.totalAvailePost) {
      this.mostviewPostOffset = this.mostviewPostOffset + 10;
      this.getMostViewdPosts();
    }
  }

  getPopularPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getPopularPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex - 1);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getTrends(swiper?: any) {
    this.spinner.show();
    if (this?.user?._id) {
      Object.assign(this.postFilters, {
        userId: this.user?._id
      });
    }
    this.postService
      .getTrendingPosts(this.postFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.status === 'Success') {
            this.trendPosts = [
              ...this.trendPosts,
              ...res.data.hashtagPosts,
              ...res.data.trendingPosts,
              ...res.data.posts
            ];

            // Remove duplication
            this.trendPosts = Array.from(
              new Map(this.trendPosts.map((obj: any) => [obj._id, obj])).values()
            );

            this.posts = [
              ...this.posts,
              ...res.data.posts,
              ...res.data.hashtagPosts,
              ...res.data.trendingPosts
            ];
            // Remove duplication
            this.posts = Array.from(new Map(this.posts.map((obj: any) => [obj._id, obj])).values());

            this.allTrendPosts = [
              ...this.allTrendPosts,
              ...res.data.hashtagPosts.concat(res.data.posts, res.data.trendingPosts)
            ];
            // let profiles = this.allTrendPosts.map((item: any) => {
            //   return {
            //     ...item.profile,
            //     isFollowing: item?.isFollowing
            //   };
            // });
            // Remove duplication
            // this.profiles = Array.from(
            //   new Map(profiles.map((obj: any) => [obj._id, obj])).values()
            // );

            // this.profiles = this.profiles.sort(() => Math.random() - 0.5);
            // const activeIndex = swiper?.activeIndex || 1;
            // swiper?.slideTo(activeIndex - 1);
            // this.cdr.detectChanges();
          }

          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }
  addNewCategory() {
    const queryParams = { tab: 1 };
    this.router.navigate(['/' + routesConst.dashboard + '/' + routesConst.account], {
      queryParams: queryParams
    });
  }

  followUser(user: any) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
      return;
    }
    let userId = user?._id;
    if (userId) {
      this.spinner.show();
      this.networkService
        .followUser(userId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.spinner.hide();
          user.networkMatchScore = 1;
        });
    }
  }

  onSlideChange(swiper: any) {
    if (swiper.isEnd) {
      this.usersPayload.offset = this.usersPayload.offset + 10;
      this.getTopUsers(swiper);
    }
  }

  onStorySlideChange(swiper: any) {
    this.postStoryFilters.offset = this.postStoryFilters.offset + 10;
    if (this.authService.loggedIn()) {
      this.getNetworkPosts(swiper);
    } else {
      this.getPopularPosts(swiper);
    }
  }

  postDetail(post: any) {
    this.router.navigate([routesConst.community + '/' + 'post' + '/' + post._id]);
  }

  trackByFunc(i: number) {
    return i;
  }

  tabSelect(event: any) {
    setTimeout(() => {
      this.selected = event;
    }, 1);
    if (event == 2) this.getMostViewdPosts();
  }

  tabsNavigation(index: any) {
    setTimeout(() => {
      this.selected = index;
    }, 10);
  }

  getRelatedPosts(category: string[], isScroll: boolean) {
    this.posts = [];
    this.postService
      .getRelatedPost(category)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((post: any) => {
        this.posts = post.data;
        if (isScroll && post?.data?.length) {
          setTimeout(() => {
            this.postsSection.nativeElement.scrollIntoView({
              block: 'start',
              behavior: 'smooth'
            });
          }, 1000);
        }
      });
  }

  removeSuggested(index: number) {
    this.profiles.splice(index, 1);
  }

  onScrollDown() {
    this.postFilters.offset += 10;
    this.filterPost();
  }

  filterPost() {
    let payload = {};
    Object.assign(payload, { ...this.postFilters });
    this.searchPost(payload);

    if (this.postFilters.offset === 0 && this.posts.length < 20) {
      setTimeout(() => {
        this.onScrollDown();
      }, 1000);
    }
  }

  searchPost(payload: any) {
    if (this.postFilters.offset === 0) {
      this.posts = [];
    }
    this.spinner.show();
    this.postService
      .searchPost(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.data) {
            if (this.postFilters.offset === 0) this.posts = res.data;
            else this.posts = [...this.posts, ...res.data];
            this.postSize = res.totalAvialblesPosts;
          }
          if (this.postFilters.offset === 0 && this.posts.length < 20 && this.posts.length > 0)
            this.onScrollDown();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  readMessage(userProfile: any) {
    const { email, name, profile_image, user_name, user_type, _id } = userProfile;
    if (_id != this.currentLoggedInUserId) {
      const userInfo = {
        email,
        name,
        profile_img_path: profile_image,
        user_name,
        user_type,
        _id
      };
      localStorage.setItem('userId', JSON.stringify(userInfo));
    }
    this.router.navigate(['/' + routesConst.dashboard + '/' + routesConst.messages], {
      queryParams: {
        tab: 2
      }
    });
  }

  copyLink(postId: string) {
    this.clipboard.copy(this.commonService.location().origin + '/creators/post/' + postId);
    this.snackService.showMessage(`Profile Link Copied`, false);
  }

  share() {
    if (this.authService.loggedIn()) {
      this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px' : '100%',
        maxWidth: window.innerWidth > 600 ? '80vw' : '100vw',
        data: {
          link: this.commonService.location().origin + '/profile/' + this.user.user_name,
          shareType: shareTypeConst.profile
        }
      });
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  showProfile(post: any) {
    this.router.navigate(['/profile' + '/' + post?.profile?.user_name]);
  }

  getMostViewdPosts() {
    this.spinner.show();
    this.postService
      .getAllPost({ offset: this.mostviewPostOffset, limit: 6, sortBy: 'views' })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'Success') {
          this.totalcount = this.totalcount + res.total;
          this.totalAvailePost = res.totalAvialblesPosts;
          this.mostViewdPosts = [...this.mostViewdPosts, ...res.data];
          this.spinner.hide();
        }
      });
  }

  getTopUsers(swiper?: any) {
    if (this.authService.loggedIn()) {
      this.sharedService.userInfo$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((information: { profileInfo: any } | null) => {
          if (information === null) {
            this.sharedService.getUserInfo();
          } else if (information != null) {
            const { profileInfo } = information;
            this.user = profileInfo;
            this.currentLoggedInUserId = this.user?._id;
            Object.assign(this.usersPayload, {
              userId: this.currentLoggedInUserId
            });
            this.postService
              .getDiscoverUsers(this.usersPayload)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                this.profiles = [...this.profiles, ...res.data];
                if (res.status === 'Success') {
                  // this.profiles = res.data;

                  // Remove duplication
                  this.profiles = Array.from(
                    new Map(this.profiles.map((obj: any) => [obj._id, obj])).values()
                  );
                }

                const activeIndex = swiper?.activeIndex || 1;
                swiper?.slideTo(activeIndex - 1);
                this.cdr.detectChanges();
              });
          }
        });
    } else {
      this.postService
        .getDiscoverUsers(this.usersPayload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.profiles = [...this.profiles, ...res.data];
          if (res.status === 'Success') {
            // this.profiles = res.data;

            // Remove duplication
            this.profiles = Array.from(
              new Map(this.profiles.map((obj: any) => [obj._id, obj])).values()
            );
          }

          const activeIndex = swiper?.activeIndex || 1;
          swiper?.slideTo(activeIndex - 1);
          this.cdr.detectChanges();
        });
    }
  }

  onPostViewLogged(data: any, postDetailCard: PostDetailCardComponent) {
    if (data?.success) {
      postDetailCard.onPostViewLogged(data);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.spinner.hide();
  }
}
