import { Component, Input } from '@angular/core';
import { routesConst } from 'src/app/core/const/routers';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent {
  routesConst = routesConst;
  user: any;

  @Input() set profile(profile: any) {
    if (profile) {
      const { email, password } = profile;
      const user = { email, password };
      this.user = user;
    }
  }

}
