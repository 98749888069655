import { Component, ViewChild, TemplateRef, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, } from 'angular-calendar';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleModalComponent } from 'src/app/modules/dashboard/pages/messages/components/schedule-modal/schedule-modal.component';
import { isSameDay, isSameMonth } from 'date-fns';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-dashboard-livestreams-stats',
  templateUrl: './dashboard-livestreams-stats.component.html',
  styleUrls: ['./dashboard-livestreams-stats.component.scss']
})
export class DashboardLivestreamsStatsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  @Input() set ifSchedule(value: any) {
    if (value == 0) {
      this.getEvents();
    }
  };
  isCurrent = true;
  isPrev = false;
  isNext = false;

  @ViewChild('modalContent', { static: true }) modalContent?: TemplateRef<any>;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData?: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: (event: any): void => {
        this.deleteEvent(event.event)
      },
    },
  ];
  start_date: any;
  endDateMin: any;
  startDateMax: any;
  startDate: any;
  endDate: any;
  newEvent: any = [];
  profile_id: any;
  loginUserId!: string;
  refresh: Subject<any> = new Subject();
  events: any[] = [];
  activeDayIsOpen: boolean = false;
  isNewEventOpen: boolean = false;

  constructor(private profileService: ProfileService, private snack: SnackBarService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.startDateMax = '1/' + new Date().getFullYear().toString();
    this.endDateMin = this.startDateMax;
  }

  getEvents() {
    this.profileService.getEvents().pipe(takeUntil(this.unsubscribe$)).subscribe((events: any) => {
      this.events = events.data;
      events.data.forEach((event: any) => {
        event.actions = this.actions
        event.start = new Date(event.start)
        event.end = new Date(event.end)
      });
    });
    this.refresh.next(true);
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && (this.activeDayIsOpen === true)) ||
        (!events.length)
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    const today = new Date().getTime();
    const start = new Date(newStart).getTime();
    if (start < today) {
      this.snack.showMessage(`You can not add event in past date`,true);
      return
    }
    let selectedEvent = JSON.parse(JSON.stringify(event))
    selectedEvent.start = new Date(selectedEvent.start);
    selectedEvent.end = new Date(selectedEvent.end);
    this.events = this.events.map((iEvent: any) => {
      if (iEvent === event) {
        return {
          ...event,
          start: new Date(newStart),
          end: newEnd ? new Date(newEnd) : newEnd,
        };
      }
      return iEvent;
    });
    event.start = new Date(newStart);
    event.end = newEnd ? new Date(newEnd) : newEnd;
    if (selectedEvent.start.toString() !== newStart.toString() || selectedEvent.end.toString() !== newEnd?.toString()) {
      this.updateEvents(event);
    }
    this.handleEvent('Dropped or resized', event);
  }

  updateEvents(event: any) {
    this.profileService.updateEvent(event).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res.status == 'success') {
        this.snack.showMessage(`Event Updated`,false);
        this.refresh.next(true);
      }
    });
  }

  handleEvent(action: string, event: CalendarEvent): void {
    if (action == 'Edited') {
      const dialogRef = this.dialog.open(ScheduleModalComponent, {
        width: '536px',
        maxWidth: '100vw',
        maxHeight: 'calc(100vh - 30px)',
        data: { events: [event], isPreview: true }
      });
      dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        if (res) {
          let index = this.events.findIndex(x => x?._id == res?._id);
          res.actions = this.actions;
          res.start = new Date(res?.start);
          res.end = new Date(res?.end);
          this.events[index] = res
          this.refresh.next(true);
        }
      });
    }
    this.modalData = { event, action };
  }

  setStartDate(date: string) {
    this.start_date = new Date(date)
    this.endDateMin = new Date(date)
  }



  deleteEvent(item: any) {
    this.profileService.removeEvent(item._id).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res.status == 'Success') {
        this.events = this.events.filter((iEvent) => iEvent._id !== item._id);
        if ((isSameDay(this.viewDate, item.start) && (this.activeDayIsOpen === true)) || (!this.events.length)) {
          let activeDay = item.start.getDate();
          const idx = this.events.findIndex((a) => a.start.getDate() === activeDay);
          if (idx == -1) {
            this.activeDayIsOpen = false;
          }
        }
        this.snack.showMessage(`${item.title} Deleted`,false);
      }
    })
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay(event: any) {
    if (event === 'next') {
      this.isNext = true;
      this.isPrev = false;
      this.isCurrent = false;
    }
    else if (event === 'prev') {
      this.isNext = false;
      this.isPrev = true;
      this.isCurrent = false;
    }
    else {
      this.isNext = false;
      this.isPrev = false;
      this.isCurrent = true;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
