import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SortOption } from 'src/app/core/const/options';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PostService } from 'src/app/core/services/post.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { MentionService } from 'src/app/shared/services/mentions.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

const SearchType = {
  label: '',
  type: [
    { id: 'all', label: 'All' },
    { id: 'moment', label: 'Photos' },
    { id: 'clip', label: '15 sec' },
    { id: 'highlight', label: '30 sec' },
    { id: 'trailer', label: '60 sec' },
    { id: 'subscriptions', label: 'Subscriptions' },
    { id: 'people', label: 'Members' }
  ]
};

const LocationType = [{ id: 'all', value: 'All' }];

const SortByType = [{ id: 'all', value: 'All' }];

export interface SelectFormType {
  label: string;
  type: any[];
}
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild('search', { static: true }) search!: ElementRef;
  @ViewChild(SwiperComponent, { static: false }) swiper?: SwiperComponent;
  private unsubscribe$ = new Subject();
  scrollPosition = 0;
  userId: any;
  routesConst = routesConst;
  interestList: any[] = [];
  searchIdx: string;
  SearchType = SearchType;
  searchTypeVal: string = 'all';
  LocationType = LocationType;
  SortByType = SortByType;
  sortByData: SelectFormType = {
    label: 'Sort by: ',
    type: SortOption
  };
  selected = new FormControl(0);
  posts: any[] = [];
  people: any[] = [];
  searchInterestValue: string;
  loading: boolean;
  pageEvent?: PageEvent;
  postSize: number;
  postFilters = { offset: 0, limit: 10 };
  postStoryFilters = { offset: 0, limit: 10 };
  isSearch: boolean = false;
  isShowAdvancedSearchOptions: boolean = false;
  layoutMode: string = 'grid_view';
  followings: any[] = [];
  currenLoginUser: any;
  network: any;
  placeholder: string = '@username #tags';
  searchByUser: any = '';
  myPlans: any[] = [];

  mentionUsers: any[] = [];
  mentionConfig = {
    items: this.mentionUsers,
    labelKey: 'user_name',
    triggerChar: '@'
    // maxItems: 10
    //mentionSelect: this.mentionSelect
  };

  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20 // Adjust this value to set the desired gap between slides
    // effect: 'slide'
  };

  @HostListener('window:scroll')
  checkScroll() {
    this.scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }
  title = 'JOBPros creators page';

  stories: any = [];
  constructor(
    private spinner: NgxSpinnerService,
    private postService: PostService,
    private _route: ActivatedRoute,
    private _router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private metaTagService: Meta,
    private commonService: CommonService,
    private userPlan: userPlans,
    public mentionService: MentionService,
    private cdr: ChangeDetectorRef
  ) {
    this.searchTypeVal = '';
    this.searchInterestValue = '';
    this.loading = false;
    this.posts = [];
    this.searchIdx = '';
    this.postSize = 0;
    this.route.queryParams.subscribe((param: any) => {
      if (param?.search?.length) {
        this.searchIdx = param.search;
        this.postFilters.offset = 0;
        this.filterPost();
      }
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.commonService.getWindow()?.scroll(0, 0);
    }, 1000);

    // this.titleService.setTitle(this.title);
    this.metaTagService.updateTag({ name: 'title', content: this.title });
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'Welcome to creators page Sell, Create & Earn, Monetize everything across all platforms, Revolutionize the multi creator community on JOBPros.'
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: 'https://web-dev.jobpros.io'
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content:
        'Welcome to creators page Sell, Create & Earn, Monetize everything across all platforms, Revolutionize the multi creator community on JOBPros.'
    });
    this.metaTagService.updateTag({
      property: 'og:image',
      content: 'https://web-dev.jobpros.io/assets/images/logo/w-logo-desktop.png'
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: this.title
    });

    this.getInterestList();
    if (this.authService.loggedIn()) {
      this.dashboard();
      this.getFollowersAndFollowings();
      this.getNetworkPosts();
    } else {
      this.getPopularPosts();
    }
    this._route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      if (params.tab) {
        this.filterByType(params.tab);
      } else {
        this.filterByType('all');
      }
    });
  }

  postContent() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    if (!this.authService.loggedIn()) {
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      this.dialog.open(UploadPostModalComponent, {
        maxWidth: '100vw',
        width: '536px',
        height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
        disableClose: true,
        data: {}
      });
    }
  }

  postDetail(post: any) {
    this._router.navigate([routesConst.community + '/' + 'post' + '/' + post._id]);
  }

  onSlideChange(swiper: any) {
    if (swiper.isEnd) {
      this.postStoryFilters.offset = this.postStoryFilters.offset + 10;
      if (this.authService.loggedIn()) {
        this.getNetworkPosts(swiper);
      } else {
        this.getPopularPosts(swiper);
      }
    }
  }

  trackByFunc(i: number) {
    return i;
  }

  getNetworkPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getNetworkPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getPopularPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getPopularPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.currenLoginUser = profileInfo;
        this.userId = profileInfo._id;
      }
    });
  }

  getInterestList() {
    this.sharedService.skillsOrIntrest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((interest: any) => {
        if (interest.length) {
          this.interestList = interest;
        } else {
          this.sharedService.getSkillsOrIntrest();
        }
      });
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(network => {
        if (network?.followings || network?.followers) {
          this.network = network;
          this.followings = network.followings;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  getPosts() {
    this.spinner.show();
    this.postService
      .getAllPost(this.postFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.status === 'Success') {
            if (this.postFilters.offset === 0) this.posts = res.data;
            else this.posts = [...this.posts, ...res.data];
            this.postSize = res.totalAvialblesPosts;
          }
          if (
            this.postFilters.offset === 0 &&
            this.posts.length < 20 &&
            this.posts.length > 0 &&
            this.layoutMode === 'grid_view'
          )
            this.onScrollDown();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  onScrollDown() {
    this.postFilters.offset += 10;
    this.filterPost();
  }

  reset() {
    this.search.nativeElement.value = '';
    this.searchByUser = '';
    this.searchIdx = '';
    this.searchInterestValue = '';
    this.searchTypeVal = 'all';
    this.postFilters.offset = 0;
    this.getPosts();
    this.isShowAdvancedSearchOptions = false;
    this.isSearch = false;
    this.handleTypeChange('all');
  }

  handleTypeChange(tab: string) {
    let currentRoute = this.commonService.location().pathname;
    this._router.navigate([currentRoute], { queryParams: { tab } });
  }

  filterByType(type: any) {
    this.placeholder = type == 'people' ? '@username' : '@username #tags';
    this.searchTypeVal = type;
    this.isShowAdvancedSearchOptions = false;
    this.postFilters.offset = 0;
    switch (type) {
      case 'all':
      case 'people':
      case 'moment':
      case 'clip':
      case 'highlight':
      case 'trailer':
      case 'subscriptions':
        this.filterPost();
        setTimeout(() => {
          this.swiper?.swiperRef?.slideTo(type == 'subscriptions' ? 6 : 0);
        }, 100);
        break;
      default:
        this.reset();
        break;
    }
  }

  getUserId(flag: boolean) {
    if (this.authService.loggedIn()) {
      return this.userId;
    } else {
      if (flag) {
        this._router.navigate(['/']);
        this.dialog.open(LoginSignupDialogComponent, {
          width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
          data: { pageToOpen: 'login' }
        });
      } else {
        return null;
      }
    }
  }

  mentionSelect(item: any) {
    if (item.trigger == '@') {
      this.searchByUser = item.user_name;
      this.postFilters.offset = 0;
      this.filterPost();
    }
    if (item.trigger == '#') {
      Object.assign(item, { value: item.id });
      this.filterByInterest(item);
    }
  }

  searchByTitle(event: any) {
    // let extractComment = event?.target?.value.split(' ');
    // let mentionUser = extractComment[extractComment.length - 1];
    // if (mentionUser.includes('@')) {
    //   this.searchIdx = '';
    //   let searchUser = mentionUser.replace('@', '');
    //   let params = {
    //     keyword: searchUser,
    //   };
    //   this.postService
    //     .getMentionedCandidate(params)
    //     .pipe(takeUntil(this.unsubscribe$))
    //     .subscribe((res: any) => {
    //       this.mentionUsers = res.data;
    //     });
    //   return;
    // }
    // this.searchByUser = '';
    // this.searchIdx = event.target.value;
    // if (event.key === 'Enter') {
    //   this.postFilters.offset = 0;
    //   this.filterPost();
    // }
    if (!event.target.value?.length && event.key === 'Backspace') {
      this.searchByUser = '';
      this.searchIdx = '';
      this.searchInterestValue = '';
      this.postFilters.offset = 0;
      this.filterPost();
    }
  }

  filterByInterest(event: any) {
    this.searchInterestValue = event.value;
    this.isShowAdvancedSearchOptions = false;
    this.postFilters.offset = 0;
    this.filterPost();
  }

  filterPost() {
    let payload = {};
    if (this.searchIdx !== '') Object.assign(payload, { keyword: this.searchIdx });
    if (this.searchByUser !== '') Object.assign(payload, { user_name: this.searchByUser });
    if (this.searchInterestValue !== '')
      Object.assign(payload, { interest: this.searchInterestValue });
    if (
      this.searchTypeVal !== '' &&
      (this.searchTypeVal === 'clip' ||
        this.searchTypeVal === 'moment' ||
        this.searchTypeVal === 'trailer' ||
        this.searchTypeVal === 'highlight')
    )
      Object.assign(payload, { ContentType: this.searchTypeVal });
    const filterParams = { ...payload };
    Object.assign(payload, { ...this.postFilters });
    // if (Object.keys(filterParams).length === 0) {
    if (Object.keys(filterParams).length) {
      this.isSearch = true;
    }
    if (this.searchTypeVal !== 'people' && this.searchTypeVal !== 'subscriptions') {
      this.searchPost(payload);
    } else if (this.searchTypeVal == 'people') {
      this.getMembers(payload);
    } else if (this.searchTypeVal == 'subscriptions') {
      this.getPlans(payload);
    }
    // } else {
    //   this.searchPost(payload);
    //   this.isSearch = true;
    // }
    if (this.postFilters.offset === 0 && this.posts.length < 20) {
      setTimeout(() => {
        this.onScrollDown();
      }, 1000);
    }
  }

  getPlans(payload: any) {
    this.userPlan
      .publicAllPlans(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.plans) {
          if (this.postFilters.offset === 0) this.myPlans = plans.plans;
          else
            this.myPlans = [
              ...this.myPlans,
              ...plans.plans.map((plan: any) => ({
                ...plan,
                isMore: true,
                isBigger: plan?.description?.length > 140
              }))
            ];
          // this.myPlans = [...this.myPlans, ...plans.plans.map((plan: any) => ({
          //   ...plan,
          //   isMore: true,
          //   isBigger: plan.description.length > 140,
          // }))];
        }
      });
  }

  searchPost(payload: any) {
    if (this.postFilters.offset === 0) {
      this.posts = [];
    }
    this.spinner.show();
    this.postService
      .searchPost(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.data) {
            if (this.postFilters.offset === 0) this.posts = res.data;
            else this.posts = [...this.posts, ...res.data];
            this.postSize = res.totalAvialblesPosts;
          }
          if (
            this.postFilters.offset === 0 &&
            this.posts.length < 20 &&
            this.posts.length > 0 &&
            this.layoutMode === 'grid_view'
          )
            this.onScrollDown();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getMembers(payload: any) {
    this.isSearch = true;
    this.spinner.show();
    if (payload.offset === 0) {
      this.people = [];
    }
    this.postService
      .getAllPostByCandidate(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          const user_id = this.getUserId(false);
          if (res.status === 'Success') {
            let posts = [];
            if (payload.offset === 0)
              posts = user_id
                ? res.data.filter((item: any) => {
                    return item.profile._id != user_id;
                  })
                : res.data;
            else
              posts = [
                ...this.people,
                ...(user_id
                  ? res.data.filter((item: any) => {
                      return item.profile._id != user_id;
                    })
                  : res.data)
              ];
            this.people = posts;
          }
          if (res.totalResult == 0 && payload.offset === 0) {
            this.people = [];
          }
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  setViewMode(evt: any) {
    this.layoutMode = evt;
  }

  followMember(data: any) {
    if (this.followings.length > 0) this.followings = [...this.followings, data];
    else this.followings = [data];
  }

  unFollowMember(id: string) {
    this.followings = this.followings.filter((f: any) => f._id !== id);
  }

  ngOnDestroy(): void {
    this.spinner.hide();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
