import { routesConst } from '../core/const/routers';

export const WorkFlowItemsConstants = {
  PROFILE: {
    TITLE: {
      MANAGE_PROFILE: 'Manage Profile',
      CREATE_PROFILE: 'Create Profile',
    },
    DESCRIPTION: 'Display brand expertise & interests',
    LABEL: 'manage profile',
    TYPE: 'Profile',
    LOGO: '../../../assets/images/home/logo-create-account.png',
    IMG: '../../../assets/images/recruiter-w-icon.png',
    URL: `/${routesConst.dashboard}/${routesConst.account}`,
  },
  POST: {
    TITLE: { POST_CONTENT: 'Post Content', CREATE_CONTENT: 'Promote Content' },
    DESCRIPTION: 'Post original photo & video content',
    LABEL: 'post content',
    TYPE: 'Post',
    ICON: 'image',
    LOGO: '../../../assets/images/home/logo-create-original-content.png',
    URL: '',
  },
  SERVICE: {
    TITLE: {
      MONETIZE_SERVICES: 'Monetize Services',
      ADD_SEVICES: 'Add Services',
    },
    DESCRIPTION: 'Sell content, remote & local services',
    LABEL: 'add services',
    TYPE: 'Service',
    ICON: 'design_services',
    LOGO: '../../../assets/images/home/logo-monetize-service.png',
    URL: `/${routesConst.talents}`,
  },
  JOB: {
    TITLE: { EXPLORE_JOBS: 'Explore Jobs' },
    DESCRIPTION: 'Post your jobs or get hired by others',
    LABEL: 'find jobs',
    TYPE: 'Job',
    ICON: 'work',
    LOGO: '../../../assets/images/home/logo-explore-job.png',
    URL: `/${routesConst.jobs}`,
  },

  PAYMENT: {
    TITLE: { MANAGE_EARNINGS: 'Manage Earnings' },
    DESCRIPTION: 'Referrals, memberships & services',
    LABEL: 'payments',
    TYPE: 'Payment',
    ICON: 'Payment',
    LOGO: '../../../assets/images/home/logo-create-passive-income.png',
    URL: `/${routesConst.dashboard}/${routesConst.payments}`,
  },
  ShareProfile: {
    TITLE: { MANAGE_EARNINGS: 'CONTENT' },
    DESCRIPTION: 'Grow your network & audience',
    LABEL: 'Manage Profile',
    TYPE: 'Post',
    ICON: 'Post',
    IMG: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/CONTENT+IMAGE.jpeg',
    LOGO: '../../assets/images/home/image.png',
    URL: ``,
  },
  AddServices: {
    TITLE: { MANAGE_EARNINGS: 'JOBS' },
    DESCRIPTION: 'Create & sell expert services',
    LABEL: 'Create Services',
    TYPE: 'Service',
    ICON: 'Payment',
    IMG: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBS+IMAGE.jpeg',
    LOGO: '../../assets/images/home/image (5).png',
    URL: `/${routesConst.dashboard}/${routesConst.jobs}`,
  },
  GetSubscribers: {
    TITLE: { MANAGE_EARNINGS: 'SERVICES' },
    DESCRIPTION: 'Create & sell exclusive content',
    LABEL: 'Add Subscriptions',
    TYPE: 'Subscription',
    ICON: 'Payment',
    IMG: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/SERVICES+IMAGE.jpeg',
    LOGO: '../../assets/images/home/image (6).png',
    URL: `/${routesConst.dashboard}/${routesConst.services}`,
  },
  InviteOthers: {
    TITLE: { MANAGE_EARNINGS: 'REFERRALS' },
    DESCRIPTION: 'Share your referral link',
    LABEL: 'Get Referrals',
    TYPE: 'Payment',
    ICON: 'Payment',
    IMG: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/REFERRALS+IMAGE.jpeg',
    LOGO: '../../assets/images/home/image (3).png',
    URL: `/${routesConst.dashboard}/${routesConst.referrals}`,
  },
};

export const PartWorkFlowConstants = {
  HEADING_STYLE: { 'max-width': '550px' },
  SECTION: 'weMakeItSimple',
  IMAGE_POSITION: 'right',
  SUPER_HEADING: 'WE MAKE IT SIMPLE',
  DIVIDER: true,
  MAIN_HEADING: 'Create income streams & manage earnings',
  DESCRIPTION: {
    content: 'Sell services, create content and earn paid subscribers all in one place.',
  },
  BUTTON: [{ text: 'Get Started', buttonLink: false }],
  IMAGE: [
    {
      src: 'assets/static/home/images/section7.png',
      redirectUrl: '',
    },
  ],
};
