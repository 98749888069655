import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PostService } from 'src/app/core/services/post.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { ImagePreviewModalComponent } from 'src/app/modules/individual-service/components/image-preview-modal/image-preview-modal.component';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';

@Component({
  selector: 'app-profile-services',
  templateUrl: './profile-services.component.html',
  styleUrls: ['./profile-services.component.scss'],
})
export class ProfileServicesComponent implements OnInit, OnChanges {
  private unsubscribe$ = new Subject();
  @Input() user_name: any;
  @Input() isProfileSubscription: any;
  selectedService: any;
  slides?: any[];
  selected: number;
  talentFilters = { offset: 0, limit: 10 };
  myServices: any[] = [];
  pricingPackages?: any[];
  activeSlide: number = 1;

  // myPlans:any;

  constructor(
    private userPlan: userPlans,
    private dialog: MatDialog,
    private talentService: TalentService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private postService: PostService
  ) {
    this.selected = 0;
  }

  ngOnInit(): void {}

  onSlideChange(swiper: any) {
    this.activeSlide = swiper.activeIndex + 1;
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isProfileSubscription']?.currentValue === true) {
      this.plans(changes['user_name']?.currentValue);
    } else {
      this.getTalentService(changes['user_name']?.currentValue);
    }
  }

  plans(user_name: any) {
    if(!user_name) return;
    this.userPlan
      .publicPlans(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.plans) {
          this.myServices = plans.plans.map((plan: any) => ({
            ...plan,
            isMore: true,
            isBigger: plan?.description?.length > 140,
          }));
        }
      });
  }

  getTalentService(user_name: any) {
    const payload = { user_name, ...this.talentFilters, mode: 'Info' };
    this.talentService
      .publicTalentService(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((service: any) => {
        if (service?.data) {
          this.myServices = [...this.myServices, ...service.data];
        }
      });
  }

  showDetails(service: any, stepper: MatStepper) {
    if(service?.type=='service'){
      this.addViews(service._id);
    }
    stepper.next();
    this.selectedService = service;
    this.slides = this.selectedService?.portfolios.map((portfolio: any) => {
      return {
        path: portfolio.url,
        media_type: portfolio.media_type
      };
    });
    this.pricingPackages = this.selectedService?.packages.map((pkg: any) => {
      return {
        title: pkg.name,
        price: pkg.price,
        description: pkg.description,
        features: pkg?.offers?.split(','),
        price_id: pkg.price_id,
        billing_type: pkg.billing_type,
        service_id: pkg.talent_service_id,
      };
    });
  }

  addViews(serviceId:any){
    if (this.authService.loggedIn()) {
    const payload = {
      content_id:serviceId,
      type:'service'
    }
    this.postService.updateViews(payload).pipe(takeUntil(this.unsubscribe$))
    .subscribe((res: any) => {})
  }
  }

  prevService() {
    if(this.selectedService?.isUserCreatedPlan) return;
    this.dialog.open(ImagePreviewModalComponent, {
      data: {
        service: this.selectedService,
        selected: this.selected,
      },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
