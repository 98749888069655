<div class="home-page">
  <div id="heroSection" class="back-ground-img overflow-hidden relative">
    <div class="grid mx-auto">
      <div class="z-0">
        <div class="flex flex-col">
          <!-- <div class="section-main-padding">
          </div> -->

          <div class="background-section" [ngClass]="authService.loggedIn() ? 'menu-wrapper':''">
            <div class="sections-main">
              <div class="section-2-main">
                <div class="flex flex-col items-center w-full mx-auto">
                  <div class="block md:hidden">
                    <app-feature-menu></app-feature-menu>
                  </div>

                  <!-- <p class="lets-grow-together">LET’S GROW TOGETHER</p> -->
                  <p class="join-heading text-center" *ngIf="!authService.loggedIn()">
                    We reimagined the way you <br>
                    network. We made it easier. 
                  </p>
                  <!-- <p class="section-title-divider fastgrowing-divider w-full"></p> -->
                  <p class="description text-center font-semibold" *ngIf="!authService.loggedIn()">
                    Earn more revenue, clients and referrals
                  </p>
                  <div class="my-2 flex items-center justify-center w-full gap-4" *ngIf="!authService.loggedIn()">
                    <span
                      class="bg-[#377df7] cursor-pointer rounded-full text-center text-white py-2 px-7 text-sm button-font-family font-semibold"
                      (click)="openSignUp(loginSignUpConstant.signup)">Get Started</span>
                    <div>
                      <a href="https://calendly.com/jobpros-info/15min" target="_blank"
                        class="text-white button-font-family font-semibold cursor-pointer">Book A Call</a>
                      <p class="section-title-divider fastgrowing-divider"></p>
                    </div>

                  </div>
                </div>

                <!-- for mobile screen -->
                <div *ngIf="innerWidth < 768 && !authService.loggedIn()" class="images-main">
                  <swiper [pagination]="{ clickable: true }" [config]="config">
                    <ng-template swiperSlide>
                      <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.network">
                        <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/3nowA.jpeg" alt="" />
                        <p>Community Network</p>
                        <small>Experts, Creators & Companies</small>
                      </div>
                    </ng-template>

                    <ng-template swiperSlide>
                      <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.services">
                        <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/8fEla.jpeg" alt="" />
                        <p>Monetize Everywhere</p>
                        <small>Promote Content & Services</small>
                      </div>
                    </ng-template>

                    <ng-template swiperSlide>
                      <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.payments">
                        <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/bwh7Y.jpeg" alt="" />
                        <p>Fastest Payouts</p>
                        <small>Sellers Paid In Minutes</small>
                      </div>
                    </ng-template>
                    <ng-template swiperSlide>
                      <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.referrals">
                        <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/3rN34.jpeg" alt="" />
                        <p>Best Referral Bonus</p>
                        <small>10% Per Monthly Referral</small>
                      </div>
                    </ng-template>
                  </swiper>
                </div>
                <!---------->

                <!-- for laptop screen -->
                <div *ngIf="innerWidth >= 768 && !authService.loggedIn()" class="grid grid-cols-4 gap-5">
                  <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.network">
                    <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/3nowA.jpeg" alt="" />
                    <p>Community Network</p>
                    <small>Experts, Creators & Companies</small>
                  </div>
                  <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.services">
                    <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/8fEla.jpeg" alt="" />
                    <p>Monetize Everywhere</p>
                    <small>Promote Content & Services</small>
                  </div>
                  <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.payments">
                    <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/bwh7Y.jpeg" alt="" />
                    <p>Fastest Payouts</p>
                    <small>Sellers Paid In Minutes</small>
                  </div>
                  <div class="footer-images" [routerLink]="routesConst.dashboard + '/' + routesConst.referrals">
                    <img src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/3rN34.jpeg" alt="" />
                    <p>Best Referral Bonus</p>
                    <small>10% Per Monthly Referral</small>
                  </div>
                </div>
                <!-------->
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="flex justify-center py-0 xl:px-5 marquee-padding y-1 mb-12" *ngIf="!authService.loggedIn()">
      <div class="custom-max-width bg-[#000000] rounded-[40px] overflow-hidden">
        <div class="marquee" behavior="scroll" scrollamount="20">
          <div class="flex items-center justify-between gap-5 w-max p-4">
            <ng-container *ngFor="let item of getArray(100)">
              <img class="w-[14px] md:w-[10px] h-[14px] md:hover:-[10px]"
                src="https://cdn.prod.website-files.com/5837424ae11409586f837994/65dbefb4433c528b462fbcba_60.svg"
                alt="">
              <div class="flex items-center gap-3 w-fit">
                <img class="w-[22px] h-[22px] md:w-[53px] md:h-[53px] rounded-full"
                  src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/4riOl.jpeg" alt="">
                <p class="text-[#f5f5f5] font-black text-base md:text-xl text-nowrap">SelfBrand</p>
              </div>
              <img class="w-[14px] md:w-[10px] h-[14px] md:hover:-[10px]"
                src="https://cdn.prod.website-files.com/5837424ae11409586f837994/65dbefb4433c528b462fbcba_60.svg"
                alt="">
              <div class="flex items-center gap-3 w-fit">
                <img class="w-[22px] h-[22px] md:w-[53px] md:h-[53px] rounded-full"
                  src="https://jobpros-comppressed-media.s3.us-west-1.amazonaws.com/qCZW2.jpeg" alt="">
                <p class="text-[#f5f5f5] text-base font-black md:text-xl text-nowrap">GrahamFamilyFilms</p>
              </div>
              <img class="w-[14px] md:w-[10px] h-[14px] md:hover:-[10px]"
                src="https://cdn.prod.website-files.com/5837424ae11409586f837994/65dbefb4433c528b462fbcba_60.svg"
                alt="">
              <div class="flex items-center gap-3 w-fit">
                <img class="w-[22px] h-[22px] md:w-[53px] md:h-[53px] rounded-full bg-white"
                  src="https://jobpros-comppressed-media.s3.us-west-1.amazonaws.com/rIb0N.jpeg" alt="">
                <p class="text-[#f5f5f5] font-black text-base md:text-xl text-nowrap">Lionshare Mgmt</p>
              </div>
              <img class="w-[14px] md:w-[10px] h-[14px] md:hover:-[10px]"
                src="https://cdn.prod.website-files.com/5837424ae11409586f837994/65dbefb4433c528b462fbcba_60.svg"
                alt="">
              <div class="flex gap-3 items-center w-fit">
                <img class="w-[22px] h-[22px] md:w-[53px] md:h-[53px] rounded-full"
                  src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/f8UOq.jpeg" alt="">
                <p class="text-[#f5f5f5] font-black text-base md:text-xl text-nowrap">Investment Group Partners</p>
              </div>
              <img class="w-[14px] md:w-[10px] h-[14px] md:hover:-[10px]"
                src="https://cdn.prod.website-files.com/5837424ae11409586f837994/65dbefb4433c528b462fbcba_60.svg"
                alt="">
              <div class="flex items-center gap-3 w-fit">
                <img class="w-[22px] h-[22px] md:w-[53px] md:h-[53px] rounded-full"
                  src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/KpjIt.jpeg" alt="">
                <p class="text-[#f5f5f5] font-black text-base md:text-xl text-nowrap">Worldsmostlit</p>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="md:mt-4 top-categories w-full feed-section" *ngIf="authService.loggedIn()">
      <app-feed></app-feed>
    </div>
    <!-- <div class="top-categories w-full" *ngIf="authService.loggedIn()">
      <div class="mt-3 xl:mt-8">
        <p class="category-heading pb-3">Featured</p>
        <div class="grid mb-0 xl:mb-4" *ngIf="members.length > 0">
          <swiper [config]="membersConfig" class="home-page-member">
            <ng-template swiperSlide *ngFor="let item of members">
              <app-people-card [data]="item" [followings]="followings" (followMember)="onFollow($event)"
                (unFollowMember)="onUnFollow($event)"></app-people-card>
            </ng-template>
          </swiper>
        </div>
      </div>
    </div> -->
    <div class="top-categories w-full" *ngIf="!authService.loggedIn()">
      <p class="category-heading pb-3">Top Categories</p>
      <div class="flex gap-2 md:gap-3 xl:gap-4 overflow-x-auto">
        <div class="category-items cursor-pointer" (click)="scrollToSection('#homeJobs')">
          <img class="category-image mb-1 object-cover"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/gCUDj.png" alt="" />
          <p class="category-tittle">Jobs</p>
        </div>
        <div class="category-items cursor-pointer" (click)="scrollToSection('#homeServices')">
          <img class="category-image mb-1 object-cover object-right"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/JHSJh.jpeg" alt="" />
          <p class="category-tittle">Services</p>
        </div>
        <div class="category-items cursor-pointer" (click)="scrollToSection('#homeCreators')">
          <img class="category-image mb-1 object-cover"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/ATLyI.png" alt="" />
          <p class="category-tittle">Creators</p>
        </div>
        <div class="category-items cursor-pointer" (click)="scrollToSection('#homeSubscriptions')">
          <img class="category-image mb-1 object-cover"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/zxBvF.png" alt="" />
          <p class="category-tittle truncate">Subscriptions</p>
        </div>
        <div class="category-items cursor-pointer"
          (click)="authService.loggedIn() ? navigate(routesConst.community, { tab: 'people' }) : openSignUp(loginSignUpConstant.signup)">
          <img class="category-image mb-1 object-cover"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/ucDsY.png" alt="" />
          <p class="category-tittle">Members</p>
        </div>
        <div class="category-items cursor-pointer"
          (click)="authService.loggedIn() ? navigate(routesConst.community, { tab: 'moment' }) : openSignUp(loginSignUpConstant.signup)">
          <img class="category-image mb-1 object-cover"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/fVSjK.png" alt="" />
          <p class="category-tittle">Photos</p>
        </div>
        <div class="category-items cursor-pointer"
          (click)="authService.loggedIn() ? navigate(routesConst.community, { tab: 'clip' }) : openSignUp(loginSignUpConstant.signup)">
          <img class="category-image mb-1 object-cover"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/hk4yA.png" alt="" />
          <p class="category-tittle">15 Sec</p>
        </div>
        <div class="category-items cursor-pointer"
          (click)="authService.loggedIn() ? navigate(routesConst.community, { tab: 'highlight' }) : openSignUp(loginSignUpConstant.signup)">
          <img class="category-image mb-1 object-cover"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/Vlwiy.png" alt="" />
          <p class="category-tittle">30 Sec</p>
        </div>
        <div class="category-items cursor-pointer"
          (click)="authService.loggedIn() ? navigate(routesConst.community, { tab: 'trailer' }) : openSignUp(loginSignUpConstant.signup)">
          <img class="category-image mb-1 object-cover"
            src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/6N0Zp.png" alt="" />
          <p class="category-tittle">60 Sec</p>
        </div>
      </div>
    </div>
    <div class="mt-8" *ngIf="!authService.loggedIn()">
      <app-footer-videos-section [pageTitle]="'home'"></app-footer-videos-section>
    </div>
    <div *ngIf="!authService.loggedIn()">
      <app-multimedia-display></app-multimedia-display>
      <div class="top-categories trends-section w-full">
        <div class="border-b pb-12">
          <p class="category-heading pb-3">Popular Trends</p>
          <div class="flex gap-2 md:gap-3 xl:gap-4 overflow-x-auto">
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: 'Jobs' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/MAl97.jpeg" alt="" />
              <p class="category-tittle">Jobs</p>
            </div>
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: 'Services' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/JHSJh.jpeg" alt="" />
              <p class="category-tittle">Services</p>
            </div>
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: 'Creators' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/L1hVR.jpeg" alt="" />
              <p class="category-tittle">Creators</p>
            </div>
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: 'Subscriptions' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/kiga3.jpeg" alt="" />
              <p class="category-tittle truncate">Subscriptions</p>
            </div>
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: 'Members' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/jtC1v.jpeg" alt="" />
              <p class="category-tittle">Members</p>
            </div>
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: 'Photos' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/Qfot2.jpeg" alt="" />
              <p class="category-tittle">Photos</p>
            </div>
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: '15 Sec' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/5RKf4.jpeg" alt="" />
              <p class="category-tittle">15 Sec</p>
            </div>
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: '30 Sec' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/f7Dij.jpeg" alt="" />
              <p class="category-tittle">30 Sec</p>
            </div>
            <div class="category-items popular-items cursor-pointer"
              (click)="navigate(routesConst.communities, { tab: 1, trends: '60 Sec' })">
              <img class="category-image mb-1 object-cover"
                src="https://wellnestlifeimages.s3.us-west-1.amazonaws.com/NqeiQ.jpeg" alt="" />
              <p class="category-tittle">60 Sec</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-join-community *ngIf="!authService.loggedIn()"></app-join-community>
    <div class="top-categories" *ngIf="!authService.loggedIn()">
      <div class="flex justify-center">
        <div class="mb-10">
          <p class="montserrat-bold black-dark invite-section-join-heading comparePlans-text text-center">
            Perks so good you will never <br> need to go anywhere else
          </p>
          <p class="section-title-divider fastgrowing-divider w-full"></p>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
        <div routerLink="/pricing" class="flex items-center px-8 py-2 booking-tab cursor-pointer">
          <div class="flex flex-col gap-2">
            <p class="font-black text-black text-base md:text-lg">Choose a plan</p>
            <p class="text-sm md:text-base font-bold text-black">Explore memberships and select the right plan</p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="arrow-icon" viewBox="0 0 24 24"><path d="m18.707 12.707-3 3a1 1 0 0 1-1.414-1.414L15.586 13H6a1 1 0 0 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414z" style="fill:#2887ED" data-name="Right"/></svg>
        </div>
        <a href="https://calendly.com/jobpros-info/15min" target="_blank" class="flex justify-between items-center px-8 py-2 booking-tab cursor-pointer">
          <div class="flex flex-col gap-2">
            <p class="font-black text-black text-base md:text-lg">Book a 15 min intro call</p>
            <p class="text-sm md:text-base font-bold text-black">Learn more about JOBPros and how we can help</p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="arrow-icon" viewBox="0 0 24 24"><path d="m18.707 12.707-3 3a1 1 0 0 1-1.414-1.414L15.586 13H6a1 1 0 0 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414z" style="fill:#2887ED" data-name="Right"/></svg>
        </a>
        <div class="flex justify-between items-center py-2 px-8 booking-tab cursor-pointer" (click)="openSignUp(loginSignUpConstant.signup)">
          <div class="flex flex-col gap-2">
            <p class="font-black text-black text-base md:text-lg">Refer a friend & earn</p>
            <p class="text-sm md:text-base font-bold text-black">The best referral programs earn 10% per referral</p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" class="arrow-icon" viewBox="0 0 24 24"><path d="m18.707 12.707-3 3a1 1 0 0 1-1.414-1.414L15.586 13H6a1 1 0 0 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414z" style="fill:#2887ED" data-name="Right"/></svg>
        </div>
      </div>
      <div class="block mt-10 mb-0 md:mb-4 lg:mb-10">
        <mat-divider class="w-full"></mat-divider>
      </div>
    </div>
    <!-- <div class="top-categories w-full" *ngIf="authService.loggedIn()">
      <div class="mt-0 md:mt-6 xl:mt-10">
        <div class="flex justify-between md:justify-start mb-4">
          <div>
            <h1 class="text-underline">Network</h1>
          </div>
          <div class="flex ml-4 w-full justify-end md:justify-between">
            <button [routerLink]="'/'+ routesConst.discover" mat-button [queryParams]="{ tab: 'creators' }"
              class="flex montserrat-medium mt-md button outlined black btn-size">
              + Add
            </button>
            <button mat-button class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
              [routerLink]="'/'+ routesConst.dashboard+'/'+  routesConst.network">
              View All
            </button>
          </div>
        </div>
        <div class="flex gap-2 ">
          <swiper [config]="networkconfig" (reachEnd)="onSlideChange($event)"
            class="swpier-container discover-swipper home-network common-arrow posts-swipper">
            <ng-template swiperSlide *ngFor="let suggestion of profiles; index as i">
              <div class="background w-full p-5 shadow-lg rounded-md relative">
                <div class="absolute top-0 right-0 p-3 cursor-pointer" (click)="removeSuggested(i)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512">
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </div>
                <div class="flex items-center justify-center mb-5"
                  routerLink="{{ '/' + routesConst.profile + '/' + suggestion.user_name }}">
                  <img [src]="suggestion?.profile_img_path" class="w-14 h-14 rounded-full"
                    onerror="this.src='assets/images/no-image.png'" />
                </div>
                <div class="mt-2">
                  <div class="max-w-full overflow-x-visible flex justify-center">
                    <p class="username-font mb-1 truncate">
                      {{ suggestion?.user_name }}
                    </p>
                  </div>
                  <div class="max-w-full overflow-x-visible flex justify-center">
                    <p class="name-font mb-3 truncate">
                      {{ suggestion?.name }}
                    </p>
                  </div>
                </div>
                <div class="w-full flex justify-center">
                  <span *ngIf="!suggestion?.networkMatchScore" (click)="followUser(suggestion)" class="follow-btn">
                    Follow
                  </span>
                </div>

                <div class="w-full flex justify-center">
                  <span *ngIf="suggestion?.networkMatchScore" class="following-btn">
                    Following
                  </span>
                </div>
              </div>
            </ng-template>
          </swiper>
        </div>
        <button mat-button
          class="view-rounded-btn-mobile montserrat-medium mt-md button outlined black btn-size mt-4 mx-auto"
          routerLink="/dashboard/network">
          View All
        </button>
      </div>
    </div> -->
    <div [ngClass]="authService.loggedIn() ? 'feed-footer-upper-section' : ''">
      <div class="block mt-10 mb-0 md:mb-4 lg:mb-10">
        <mat-divider class="w-full"></mat-divider>
      </div>
      <div class="top-categories w-full" *ngIf="authService.loggedIn()">
        <div class="flex flex-col mb-2">
          <div class="flex justify-center">
            <div class="mb-10">
              <p class="montserrat-bold black-dark invite-section-join-heading comparePlans-text text-center">
                We reimagined the way you <br> network. We made it easier.
              </p>
              <p class="section-title-divider fastgrowing-divider w-full"></p>
            </div>
          </div>
          <!-- <div class="mb-2 lg:mb-0 flex justify-between lg:justify-start items-start">
            <p class="black-dark montserrat-bold referral-program-heading mr-4">
              Get Paid
            </p>
          </div> -->
  
        </div>
        <div class="flex items-center bg-white mb-4">
          <div class="w-[10px] h-[10px] rounded-[50%] bg-[#2887ed]"></div>
          <p class="montserrat-bold black-dark ml-4">Invite </p>
        </div>
        <p class="sections-heading pl-8">
          Friends, family and business partners.
        </p>
        <div class="mt-4">
          <div class=" flex-col pb-7">
            <div class="flex items-center bg-white">
              <div class="w-[10px] h-[10px] rounded-[50%] bg-[#2887ed]"></div>
              <p class="montserrat-bold black-dark ml-4">Earn</p>
            </div>
            <p class="sections-heading pl-8 mt-4">
              10% per paid monthly membership fee.
            </p>
            <div class="flex items-center ml-5 mt-4 blok-sm">
              <div class="flex items-center mr-7">
                <div *ngIf="purchasedAddOn?.length" class="relative">
                  <input class="referral-link-form pl-2.5 pr-8 py-2 w-56 md:w-96 truncate s" [value]="generatedRefLink"
                    readonly="true" #formInputText />
                  <mat-icon [ngxClipboard]="formInputText" class="copy-icon blue cursor-pointer absolute top-1.5 -ml-8"
                    (click)="copytoClipBoard()">content_copy</mat-icon>
                </div>
              </div>
              <button class="mt-sm " *ngIf="purchasedAddOn?.length" mat-button
                [class]="purchasedAddOn?.length ? 'button share-btn' : 'disabled'" (click)="copytoClipBoard()">
                Copy
              </button>
            </div>
            <div class="mt-8">
              <app-share-links [referralLink]="generatedRefLink"></app-share-links>
            </div>
          </div>
        </div>
        <div class="block mt-10 mb-0 md:mb-4 lg:mb-10">
          <mat-divider class="w-full"></mat-divider>
        </div>
      </div>
      <div class="top-categories w-full" *ngIf="authService.loggedIn()">
        <div class="mt-0 md:mt-6 xl:mt-10 mb-10">
          <div class="flex justify-between md:justify-start mb-4">
            <div>
              <h1 class="text-underline">Compare</h1>
            </div>
            <div class="flex ml-4 w-full justify-end md:justify-between">
              <button [routerLink]="'/dashboard/referrals'" mat-button class="flex montserrat-medium mt-md button outlined black btn-size">
                + Add
              </button>
              <button mat-button class="view-rounded-btn-desktop montserrat-medium mt-md button outlined black btn-size"
                [routerLink]="'/'+ routesConst.addonPricing">
                View All
              </button>
            </div>
          </div>
          <app-footer-pricing-plans [isHomePage]="true"></app-footer-pricing-plans>
          <button mat-button
            class="view-rounded-btn-mobile montserrat-medium mt-md button outlined black btn-size mt-4 mx-auto"
            [routerLink]="'/'+ routesConst.addonPricing">
            View All
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div 
  *ngIf="
    !authService.loggedIn() ||
    !purchasedPlan ||
    purchasedPlan?.plan_type == planTypeConstant.consumer ||
    purchasedPlan?.plan_type == planTypeConstant.recruiter
  "
  >

    <app-part-offer></app-part-offer>
  </div> -->
  <!-- <div
   *ngIf="
    authService.loggedIn() ||
    purchasedPlan ||
    purchasedPlan?.plan_type == planTypeConstant.consumer ||
    purchasedPlan?.plan_type == planTypeConstant.recruiter
  "
  >
    <div id="earnReferrals" class="range-slider">
      <app-range-slider [data]="afterLoginHomeRangeSliderData" [page]="'earn'"></app-range-slider>
    </div>
  </div> -->

  <div [ngClass]="authService.loggedIn() ? 'feed-footer-section' : ''">
    <app-part-footer [data]="letsGrowSection"></app-part-footer>
  </div>
</div>