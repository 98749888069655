import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PostService } from 'src/app/core/services/post.service';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
// import { MentionService } from 'src/app/shared/services/mentions.service';
import { SwiperOptions } from 'swiper';

const SearchType = {
  label: '',
  type: [
    { id: 'all', label: 'All' },
    { id: 'featured ', label: 'Featured ' },
    { id: 'highlights', label: 'Highlights' },
    { id: 'recommend', label: 'Recommend' },
    { id: 'suggested', label: 'Suggested' },
    { id: 'sponsored ', label: 'Sponsored ' }
  ]
};

@Component({
  selector: 'app-feed-filter-panel',
  templateUrl: './feed-filter-panel.component.html',
  styleUrls: ['./feed-filter-panel.component.scss']
})
export class FeedFilterPanelComponent implements OnInit {
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onReset: EventEmitter<any> = new EventEmitter();
  private unsubscribe$ = new Subject();
  searchByUser: any = '';
  postFilters = { offset: 0, limit: 10 };
  searchIdx: string;
  searchInterestValue: string;
  searchTypeVal: string = 'all';
  isSearch: boolean = false;
  posts: any[] = [];
  isShowAdvancedSearchOptions: boolean = false;
  myPlans: any[] = [];
  postSize: number;
  layoutMode: string = 'grid_view';
  people: any[] = [];
  SearchType = SearchType;
  userId: any;
  placeholder: string = '#tags description title';
  postStoryFilters = { offset: 0, limit: 15 };
  stories: any = [];
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20 // Adjust this value to set the desired gap between slides
    // effect: 'slide'
  };
  constructor(
    // public mentionService: MentionService,
    private userPlan: userPlans,
    private spinner: NgxSpinnerService,
    private postService: PostService,
    private authService: AuthService,
    private _router: Router,
    private dialog: MatDialog,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef
  ) {
    this.searchIdx = '';
    this.searchInterestValue = '';
    this.postSize = 0;
  }

  ngOnInit(): void {
    this.onStorySlideChange();
  }

  // mentionSelect(item: any) {
  //   if (item.trigger == '@') {
  //     this.searchByUser = item.user_name;
  //     this.postFilters.offset = 0;
  //     this.filterPost();
  //   }
  //   if (item.trigger == '#') {
  //     Object.assign(item, { value: item.id });
  //     this.filterByInterest(item);
  //   }
  // }

  onStorySlideChange(swiper?: any) {
    this.postStoryFilters.offset = this.postStoryFilters.offset + 10;
    if (this.authService.loggedIn()) {
      this.getNetworkPosts(swiper);
    } else {
      this.getPopularPosts(swiper);
    }
  }

  getNetworkPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getNetworkPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getPopularPosts(swiper?: any) {
    this.spinner.show();
    this.postService
      .getPopularPosts(this.postStoryFilters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.stories = [...this.stories, ...res?.data];
          const activeIndex = swiper?.activeIndex;
          swiper?.slideTo(activeIndex);
          this.cdr.detectChanges();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  filterPost() {
    let payload = {};
    if (this.searchIdx !== '') Object.assign(payload, { keyword: this.searchIdx });
    if (this.searchByUser !== '') Object.assign(payload, { user_name: this.searchByUser });
    if (this.searchInterestValue !== '')
      Object.assign(payload, { interest: this.searchInterestValue });
    if (
      this.searchTypeVal !== '' &&
      (this.searchTypeVal === 'clip' ||
        this.searchTypeVal === 'moment' ||
        this.searchTypeVal === 'trailer' ||
        this.searchTypeVal === 'highlight')
    )
      Object.assign(payload, { ContentType: this.searchTypeVal });
    const filterParams = { ...payload };
    Object.assign(payload, { ...this.postFilters });
    if (Object.keys(filterParams).length) {
      this.isSearch = true;
    }
    if (this.searchTypeVal !== 'people' && this.searchTypeVal !== 'subscriptions') {
      this.searchPost(payload);
    } else if (this.searchTypeVal == 'people') {
      this.getMembers(payload);
    } else if (this.searchTypeVal == 'subscriptions') {
      this.getPlans(payload);
    }
    if (this.postFilters.offset === 0 && this.posts.length < 20) {
      setTimeout(() => {
        this.onScrollDown();
      }, 1000);
    }
  }

  filterByInterest(event: any) {
    this.searchInterestValue = event.value;
    this.isShowAdvancedSearchOptions = false;
    this.postFilters.offset = 0;
    this.filterPost();
  }

  getPlans(payload: any) {
    this.userPlan
      .publicAllPlans(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.plans) {
          if (this.postFilters.offset === 0) this.myPlans = plans.plans;
          else
            this.myPlans = [
              ...this.myPlans,
              ...plans.plans.map((plan: any) => ({
                ...plan,
                isMore: true,
                isBigger: plan?.description?.length > 140
              }))
            ];
        }
      });
  }

  searchPost(payload: any) {
    if (this.postFilters.offset === 0) {
      this.posts = [];
    }
    this.spinner.show();
    this.postService
      .searchPost(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.data) {
            if (this.postFilters.offset === 0) this.posts = res.data;
            else this.posts = [...this.posts, ...res.data];
            this.postSize = res.totalAvialblesPosts;
          }
          if (
            this.postFilters.offset === 0 &&
            this.posts.length < 20 &&
            this.posts.length > 0 &&
            this.layoutMode === 'grid_view'
          )
            this.onScrollDown();
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  getMembers(payload: any) {
    this.isSearch = true;
    this.spinner.show();
    if (payload.offset === 0) {
      this.people = [];
    }
    this.postService
      .getAllPostByCandidate(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          const user_id = this.getUserId(false);
          if (res.status === 'Success') {
            let posts = [];
            if (payload.offset === 0)
              posts = user_id
                ? res.data.filter((item: any) => {
                    return item.profile._id != user_id;
                  })
                : res.data;
            else
              posts = [
                ...this.people,
                ...(user_id
                  ? res.data.filter((item: any) => {
                      return item.profile._id != user_id;
                    })
                  : res.data)
              ];
            this.people = posts;
          }
          if (res.totalResult == 0 && payload.offset === 0) {
            this.people = [];
          }
          this.spinner.hide();
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  onScrollDown() {
    this.postFilters.offset += 10;
    this.filterPost();
  }

  getUserId(flag: boolean) {
    if (this.authService.loggedIn()) {
      return this.userId;
    } else {
      if (flag) {
        this._router.navigate(['/']);
        this.dialog.open(LoginSignupDialogComponent, {
          width: window.innerWidth > 600 ? '400px' : '100vw',
          height: window.innerWidth > 600 ? 'h-fit' : '100%',
          maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
          maxHeight: window.innerWidth > 600 ? '600' : '100vh',
          data: { pageToOpen: 'login' }
        });
      } else {
        return null;
      }
    }
  }

  searchByTitle(event: any) {
    const searchValue = event.target.value;
    if (event.key === 'Enter' && searchValue?.trim()?.length) {
      this.onSearch.emit(searchValue);
    }
    if (!searchValue?.trim()?.length && event.key === 'Backspace') {
      this.onReset.emit(searchValue);
    }
  }

  setViewMode(evt: any) {
    this.layoutMode = evt;
  }

  handleTypeChange(tab: string) {
    this.searchTypeVal = tab;
    // this.onSearch.emit();
  }
}
