import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { PostService } from 'src/app/core/services/post.service';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { CommentModalComponent } from 'src/app/shared/components/comment-modal/comment-modal.component';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MentionService } from 'src/app/shared/services/mentions.service';

@Component({
  selector: 'app-media-interaction-panel',
  templateUrl: './media-interaction-panel.component.html',
  styleUrls: ['./media-interaction-panel.component.scss']
})
export class MediaInteractionPanelComponent implements OnInit {
  @Input() data: any;
  @Input() profile: any;

  innerWidth = window.innerWidth;
  private unsubscribe$ = new Subject();
  commentForm: FormGroup;
  isLoadingLike = false;
  isFavor: boolean = false;
  likeNumber: number = 0;
  showComment: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private snackService: SnackBarService,
    private _bottomSheet: MatBottomSheet,
    private fb: FormBuilder,
    private postService: PostService,
    private commonService: CommonService,
    private clipboard: Clipboard,
    public mentionService: MentionService
  ) {
    this.commentForm = this.fb.group({
      comment: ['', [Validators.required, Validators.maxLength(256)]]
    });
  }

  ngOnInit(): void {}

  likePost() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      this.isLoadingLike = true;
      const isAlreadyLikedPost = this.data?.likeDetails?.find(
        (like: any) => like.user_id === this.profile?._id
      );
      const index = this.data?.likeDetails.findIndex((x: any) => x.user_id === this.profile._id);
      if (isAlreadyLikedPost?._id) {
        this.postService
          .unLikePost(isAlreadyLikedPost._id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.isLoadingLike = false;
            this.data?.likeDetails.splice(index, 1);
            this.isFavor = false;
            this.likeNumber--;
          });
      } else {
        const options = {
          content_id: this.data._id,
          content_type: 'Subscription',
          profile_id: this.data.profile_id
        };
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((post: any) => {
            this.isLoadingLike = false;
            if (post.status === 'success') {
              this.data?.likeDetails.push({
                user_id: post.data.user_id,
                _id: post.data._id
              });
            }
          });
        this.likeNumber++;
        this.isFavor = true;
      }
    }
  }

  showComments() {
    this.showComment = !this.showComment;
    var commentPanel = document.getElementById(this.data._id);
    if (commentPanel !== null) {
      commentPanel.style.marginLeft = this.showComment ? '0%' : '100%';
    }
  }

  openBottomSheet(): void {
    if (this.innerWidth <= 768) {
      const bottomSheetRef = this._bottomSheet.open(CommentModalComponent, {
        data: {
          postOwnerID: this.data.profile_id,
          data: this.data,
          profile: this.profile,
          post_id: this.data._id
        }
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        // if (result) {
        //   this.searchByInterest(result);
        // }
      });
    }
  }

  sharePost() {
    if (this.authService.loggedIn()) {
      this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px' : '100%',
        maxWidth: window.innerWidth > 600 ? '80vw' : '100vw',
        data: {
          link:
            this.commonService.location().origin +
            '/services/service/' +
            this.data.profile.user_name +
            '/' +
            this.data._id,
          content_id: this.data._id,
          shareType: this.data.shareType
        }
      });
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  isUserLiked() {
    return this.data?.likeDetails?.find((like: any) => like?.user_id === this.profile?._id)
      ? true
      : false;
  }

  formatPostDate(previousDate?: any) {
    let currentDate = new Date().getTime();
    let jobDate = new Date(previousDate).getTime();
    let time = (currentDate - jobDate) / 1000;
    let days = Math.floor(time / (3600 * 24));
    let minutes = Math.floor(time / 60);
    let hours = Math.floor(time / (60 * 60));
    let weeks = Math.floor(days / 7);
    let months = Math.floor(days / 30);
    let years = Math.floor(days / 365);

    if (time < 60) {
      return Math.floor(time) + 's';
    } else if (minutes < 60) {
      return minutes + 'm';
    } else if (hours < 24) {
      return hours + 'h';
    } else if (days < 7) {
      return days + 'd';
    } else if (weeks < 4) {
      return weeks + 'w';
    } else if (months < 12) {
      return months + 'mo';
    } else {
      return years + 'y';
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.commentForm.controls[control].hasError(error);
  };

  checkWhitespaces(text: any) {
    return text?.replace(/\s/g, '');
  }

  submitForm() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      if (this.commentForm.valid) {
        if (this.profile._id) {
          const options = {
            content_type: 'comment',
            comment: this.commentForm.get('comment')?.value,
            content_id: this.data._id,
            profile_id: this.data.profile_id,
            commentedOn: this.data.contentType
          };
          if (this.checkWhitespaces(this.commentForm.get('comment')?.value)?.length) {
            this.postService
              .addComment(options)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.status == 'success') {
                  if (this.data.commentDetails) {
                    this.data.totalThreads++;
                    this.data.commentDetails.unshift({
                      ...res.data,
                      userInfo: [
                        {
                          email: this.profile.email,
                          profile_img_path: this.profile.avatar,
                          user_name: this.profile.user_name,
                          userType: this.profile.user_type,
                          _id: this.profile._id
                        }
                      ],
                      likeDetails: [],
                      replyCommentDetails: []
                    });
                  }
                } else {
                  this.snackService.showMessage(res.message, true);
                }
              });
            this.commentForm.get('comment')?.setValue('');
          }
        }
      }
    }
  }

  isFavorComment(likes: any) {
    return likes?.findIndex((l: any) => l?.user_id === this.profile?._id) !== -1;
  }

  likeComment(user: any) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      const options = {
        user_id: this.profile?._id,
        content_id: user._id,
        content_type: 'like'
      };
      if (this.isFavorComment(user.likeDetails)) {
        const id = user.likeDetails.find((like: any) => like.user_id === this.profile?._id)._id;
        if (id) {
          this.postService
            .unLikePost(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: any) => {
              if (res.status === 'Success') {
                const u = {
                  ...user,
                  likeDetails: user.likeDetails.filter(
                    (like: any) => like.user_id !== this.profile?._id
                  )
                };
                this.data = {
                  ...this.data,
                  commentDetails: this.data.commentDetails.map((c: any) => {
                    if (c._id === u._id) return u;
                    return c;
                  })
                };
                // this.data = {
                //   ...this.data,
                //   contentDetails: this.data.contentDetails.map((content: any) => {
                //     if (content._id === this.data._id) return this.data;
                //     return content;
                //   })
                // };
              }
            });
        }
      } else {
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res.status === 'success') {
              this.data = {
                ...this.data,
                commentDetails: this.data.commentDetails.map((comment: any) => {
                  if (comment._id === user._id) {
                    return {
                      ...user,
                      likeDetails: [
                        ...user.likeDetails,
                        {
                          ...res.data,
                          userInfo: [
                            {
                              email: this.profile.email,
                              profile_img_path: this.profile.avatar,
                              user_name: this.profile.user_name,
                              userType: this.profile.user_type,
                              _id: this.profile._id
                            }
                          ]
                        }
                      ]
                    };
                  }
                  return comment;
                })
              };
            }
          });
      }
    }
  }

  onSubscriptionViewLogged(event: any) {
    if (event.postId == this.data._id) {
      this.data.views += 1;
    }
  }

  copyLink() {
    this.clipboard.copy(window.location.origin + '/jobs?id=' + this.data._id);
    this.snackService.showMessage(`Link Copied`, false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
