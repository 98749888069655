import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies-preferences',
  templateUrl: './cookies-preferences.component.html',
  styleUrls: ['./cookies-preferences.component.scss'],
})
export class CookiesPreferencesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    document.body.appendChild(script);
  }
}
