import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { loginSignUpConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import {
  EarnHeader,
  EarnRangeSlider,
  EarnReferrals,
  WhatYOUGET,
} from 'src/app/core/models/earn';
import { PartWorkFlow, WorkFlow } from 'src/app/core/models/work-flow';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { EarnService } from 'src/app/shared/services/earn.service';
import { WorkFlowService } from 'src/app/shared/services/work-flow.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-earn',
  templateUrl: './earn.component.html',
  styleUrls: ['./earn.component.scss'],
})
export class EarnComponent implements OnInit {
  private unsubscribe$ = new Subject();
  routesConst = routesConst;
  workFlows: WorkFlow[] = [];
  earnHeader!: EarnHeader;
  whatYouGet: WhatYOUGET[] = [];
  earnReferrals: EarnReferrals[] = [];
  earnRangeSlider!: EarnRangeSlider;
  public partWorkFlow: PartWorkFlow[] = [];
  forIndividual = true;
  individualHowItWorks: any = [];
  companyHowItWorks: any = [];
  loginSignUpConstant = loginSignUpConstant;
  scrollELement: any = '';

  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
    },
  };

  innerWidth = this.commonService.getWindow()?.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = this.commonService.getWindow()?.innerWidth;
  }

  Refferal: any = [
    {
      title: 'Referral Program',
      description:
        'Refer connections to our platform & earn cash each time they pay their memberships. Send messages to your friends, family, and business partners.',
    },
    {
      title: 'Earn 10% Rewards',
      description:
        'Earn 10% cash reward each time your referee pays their memberships. See all your referrals within your network displayed on referral dashboard.',
    },
    {
      title: 'Lets Grow Together',
      description:
        'Get an individual or company member referral plan then copy your link. You receive cash rewards as appreciation for sharing your community and network.',
    },
  ];

  constructor(
    private commonService: CommonService,
    private router: Router,
    private workFlowService: WorkFlowService,
    private earnService: EarnService,
    public authService: AuthService,
    private dialog: MatDialog,
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: any) => {
        this.navigateTo(event.url);
      });

      this.individualHowItWorks = [{
        route: '/'+routesConst.dashboard + '/' + routesConst.account,
        icon:'../../../assets/images/home/logo-monetize-social-network.png',
        heading: 'Monetize everywhere',
        description: 'Display what your’e about, share social posts with your network with industry experts, creators & hiring companies.'
      },

      {
        route: '/'+routesConst.dashboard + '/' + routesConst.payments,
        icon:'../../../assets/images/home/logo-create-passive-income.png',
        heading: 'Fast payouts',
        description: 'Sell content & expert services get paid for referrals within minutes. Earn income streams & transfer directly to your bank.'
      },

      {
        route: '/'+routesConst.dashboard + '/' + routesConst.referrals,
        icon:'../../../assets/images/home/logo-monetize-service.png',
        heading: 'Best referral bonuses',
        description: 'Earn 10% on every monthly referral. Share your network with us and get paid. All your referrals are displayed on your dashboard.'
      }
      ]

      this.companyHowItWorks = [{
        route: '/'+routesConst.dashboard + '/' + routesConst.network,
        icon:'../../../assets/images/home/logo-explore-job.png',
        heading: 'Community network',
        description: 'Post unlimited jobs to hire the best talent. Network with industry experts, creators & athletes. Sell services and access to content.'
      },

      {
        route: '/'+routesConst.dashboard + '/' + routesConst.payments,
        icon:'../../../assets/images/home/logo-create-passive-income.png',
        heading: 'Fast payouts',
        description: 'Sell content & expert services get paid for referrals within minutes. Earn income streams & transfer directly to your bank.'
      },

      {
        route: '/'+routesConst.dashboard + '/' + routesConst.referrals,
        icon:'../../../assets/images/home/logo-monetize-service.png',
        heading: 'Best referral bonuses',
        description: 'Earn 10% on every monthly referral. Share your network with us and get paid. All your referrals are displayed on your dashboard.'
      }
      ]
  }

  ngOnInit(): void {
    this.earnRangeSlider = this.earnService.getEarnRangeSlider();
    this.earnReferrals = this.earnService.getEarnReferrals();
    this.whatYouGet = this.earnService.getWhatYouGet();
    this.earnHeader = this.earnService.getEarnHeader();
    this.workFlows = this.workFlowService.getWorkFlows();
    this.partWorkFlow = this.workFlowService.getPartWorkFlow();
  }

  scroll(element: any) {
    if (this.scrollELement !== '') {
      document.querySelector(element).scrollIntoView({ block: 'start' });
    }
    this.resetScrollElement();
  }

  navigateTo(url: any) {
    if (this.router.url == url) {
      if (url.includes(routesConst.earnReferrals)) {
        this.scrollELement = '#earnReferrals';
      }
    } else {
      this.scroll(this.scrollELement);
      this.router.navigate(['/' + url]);
    }
  }

  resetScrollElement() {
    setTimeout(() => {
      this.scrollELement = '';
    }, 100);
  }

  ngAfterViewChecked() {
    if (this.scrollELement !== '') {
      this.scroll(this.scrollELement);
    }
  }

  uploadPostModal(pageToOpen: string){
    if (!this.authService.loggedIn()) {
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen },
      });
    } else {
    this.dialog.open(UploadPostModalComponent, {
      maxWidth: '100vw',
      width: '536px',
      disableClose: true,
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: {},
    });
  }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
