import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-educ-card',
  templateUrl: './educ-card.component.html',
  styleUrls: ['./educ-card.component.scss']
})
export class EducCardComponent implements OnInit {
  @Input()school?: any;
  @Input()editable?: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  isTextOverflow() {
    const elem = document.getElementById('descWrapper');
    if (elem) {
        return (elem.offsetWidth < elem.scrollWidth);
    }
    else {
        return false;
    }
  }

  getFormattedMonth(date: string) {
    if (!date) {
      return 1 + '-' + new Date().getFullYear();
    }
    let dateArr = date.split('-');
    const dateStr = dateArr[1] + '/' + dateArr[0];
    return dateStr;
  }
}
