<div class="dashboard-users">
  <mat-horizontal-stepper labelPosition="bottom" #stepper>
    <mat-step>
      <div class="px-4 lg:px-0 pt-0.5 lg:pt-0">
        <div class="flex items-center w-full window-mode">
          <h1 class="montserrat-bold mr-auto">Users</h1>
        </div>
        <div class="tabs mt-4 md:mt-9">
          <mat-tab-group
            color="#fff0"
            [selectedIndex]="selected.value"
            (selectedIndexChange)="selected.setValue($event)"
          >
            <mat-tab>
              <ng-template mat-tab-label>
                <div
                  class="flex items-center pb-1 tab"
                  [class]="selected.value === 0 ? 'selected' : ''"
                >
                  <p
                    [class]="selected.value === 0 ? 'blue montserrat-bold' : ''"
                  >
                    Followers & Subscribers
                  </p>
                </div>
              </ng-template>
              <app-dashboard-users-followers></app-dashboard-users-followers>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div
                  class="flex items-center pb-1 tab"
                  [class]="selected.value === 1 ? 'selected' : ''"
                >
                  <p
                    [class]="selected.value === 1 ? 'blue montserrat-bold' : ''"
                  >
                    Influencers
                  </p>
                  <p
                    class="flex items-center justify-center s ml-1 w-4 h-4 bg-red rounded-full text-white"
                  >
                    4
                  </p>
                </div>
              </ng-template>
              <app-dashboard-users-influencers></app-dashboard-users-influencers>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div
                  class="flex items-center pb-1 tab"
                  [class]="selected.value === 2 ? 'selected' : ''"
                >
                  <p
                    [class]="selected.value === 2 ? 'blue montserrat-bold' : ''"
                  >
                    Affiliates
                  </p>
                </div>
              </ng-template>
              <app-dashboard-users-affiliates></app-dashboard-users-affiliates>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div class="section-wrapper bg-white lg:p-10 -mt-2">
          <p class="montserrat-bold black-light">Search users</p>
          <div class="flex flex-col lg:flex-row items-center mt-5">
            <div class="flex w-full">
              <input
                type="text"
                class="input-form w-full"
                placeholder="Search by username, name"
                (change)="searchUser($event)"
              />
            </div>
            <div
              class="input-form w-full lg:w-40 min-w-min flex items-center p-2 lg:p-3 lg:ml-5 mt-4 lg:mt-0"
            >
              <mat-select [value]="searchFollowerOption">
                <mat-option
                  *ngFor="let item of searchFollowerOptions"
                  [value]="item.key"
                  >{{ item.value }}</mat-option
                >
              </mat-select>
            </div>
          </div>
          <div class="">
            <table
              mat-table
              [dataSource]="dataSource"
              class="w-full shadow-none mat-elevation-z8"
            >
              <ng-container matColumnDef="livestream">
                <th mat-header-cell *matHeaderCellDef>
                  <p class="black-light montserrat-bold s">Livestream</p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex items-center">
                    <img [src]="element.img" />
                    <div class="ml-3">
                      <p class="montserrat-bold w-72">{{ element.name }}</p>
                      <p class="s black-light mt-1">{{ element.level }}</p>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="posts">
                <th mat-header-cell *matHeaderCellDef>
                  <p
                    class="black-light montserrat-bold s text-right pl-8 hidden lg:block"
                  >
                    Posts
                  </p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <div>
                    <p class="text-right hidden lg:block">
                      {{ kFormatter(element.postN) }}
                    </p>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="likes">
                <th mat-header-cell *matHeaderCellDef>
                  <p
                    class="black-light montserrat-bold s text-right hidden lg:block pl-8"
                  >
                    Likes
                  </p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <p class="text-right hidden lg:block">
                    {{ kFormatter(element.likeN) }}
                  </p>
                </td>
              </ng-container>

              <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef>
                  <p
                    class="black-light montserrat-bold s text-right pl-8 hidden lg:block"
                  >
                    Comments
                  </p>
                </th>
                <td mat-cell *matCellDef="let element">
                  <p class="text-right hidden lg:block">
                    {{ kFormatter(element.commentN) }}
                  </p>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <app-review-influencers></app-review-influencers>
    </mat-step>
    <mat-step>
      <app-influencer-profile></app-influencer-profile>
    </mat-step>
  </mat-horizontal-stepper>
</div>
