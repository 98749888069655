import { apiUrlConst } from 'src/app/const/apiConst';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from 'src/config/config';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(public _http: HttpClient, private commonService: CommonService) { }

  /**
   * Configuration of Customer Portal
   * @returns
   */
  configureCustomerPortal() {
    return this._http.get(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.configureCustomerPortal);
  }
}
