<div class="individual-service-page" *ngIf="selectedSubscription">
  <div class="main-grid-wrapper">
    <div class="user-profile-section">
      <div>
        <div>
          <p
            *ngIf="!selectedSubscription?.editTitle"
            type="text"
            class="individual-service-page_title pb-4 px-0 w-full pt-0"
            readonly
          >
            {{ selectedSubscription.title }}
            <mat-icon
              *ngIf="selectedSubscription?.profile_id == loginedUser?._id"
              class="blue text-lg"
              (click)="
                selectedSubscription.editTitle = true; updatedTitle = selectedSubscription.title
              "
              >edit</mat-icon
            >
          </p>
        </div>

        <div *ngIf="selectedSubscription?.editTitle" class="flex">
          <textarea
            class="input-form pb-4 px-0 w-full pt-0 title"
            placeholder="Title"
            [(ngModel)]="updatedTitle"
          >
          </textarea>
          <mat-icon class="blue" (click)="updateTitle()">check</mat-icon>
        </div>
        <div class="mb-4 flex flex-col md:flex-row md:gap-3 cursor-pointer" (click)="viewProfile()">
          <div class="flex gap-3">
            <img
              class="avatar-profile rounded-full object-cover border"
              [src]="
                selectedSubscription?.profile?.profile_img_path
                  ? selectedSubscription?.profile?.profile_img_path
                  : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs'
              "
            />
            <div>
              <div>
                <p class="user-name mb-1">{{ selectedSubscription?.profile?.name }}</p>
                <p class="user-second-name">@{{ selectedSubscription?.profile?.user_name }}</p>
              </div>
            </div>
          </div>
          <span
            *ngIf="
              selectedSubscription?.create_by &&
              loginedUser?._id == selectedSubscription?.profile?._id &&
              loginedUser?._id !== selectedSubscription?.create_by?._id
            "
            class="ml-[48px] md:ml-auto md:mt-1 moderator-name text-sm"
            >By: @{{ selectedSubscription?.create_by?.user_name }}</span
          >
        </div>
      </div>

      <!-- Images View Mode -->
      <div class="mt-4 px-0" *ngIf="images?.slides && !images?.editMode">
        <p
          *ngIf="selectedSubscription?.profile_id == loginedUser?._id"
          class="blue cursor-pointer font-bold flex justify-end text-sm mb-4"
          (click)="images.editMode = true"
        >
          Edit
        </p>
        <div id="service-img" class="slide-wrapper relative">
          <swiper [pagination]="images?.slides.length > 1" (slideChange)="onSlideChange($event)">
            <ng-template swiperSlide *ngFor="let portfolio of images?.slides">
              <img
                *ngIf="portfolio.type == 'image'"
                [src]="portfolio?.path"
                class="w-full cursor-pointer img-width"
              />
              <video
                *ngIf="portfolio.type == 'video'"
                [src]="portfolio?.path + '#t=0.1'"
                preload="metadata"
                class="w-full cursor-pointer img-width"
                controls
                [muted]="'muted'"
                playsinline
                webkit-playsinline
              ></video>
            </ng-template>
          </swiper>
        </div>
      </div>

      <!-- Images Edit Mode -->
      <div class="mt-4 px-0" *ngIf="images?.slides && images?.editMode">
        <swiper
          class="edit-swipper max-h-[231px] md:max-h-full"
          [pagination]="images?.slides.length > 1"
          (slideChange)="onSlideChange($event)"
        >
          <ng-template swiperSlide *ngFor="let portfolio of getArray(4); let i = index">
            <div
              class="flex items-center relative choose-img-wrapper border border-dashed border-current rounded bg-white max-h-[231px] md:max-h-full"
            >
              <img
                *ngIf="allImages[i] && allImages[i].content && allImages[i]?.format === 'image'"
                [src]="allImages[i].content"
                class="w-full h-full bg-contain bg-no-repeat bg-center max-h-[231px] md:max-h-full"
              />
              <video
                *ngIf="allImages[i] && allImages[i].content && allImages[i]?.format === 'video'"
                [src]="allImages[i].content + '#t=0.1'"
                preload="metadata"
                class="w-full h-full bg-contain bg-no-repeat bg-center max-h-[231px] md:max-h-full"
                controls
                [muted]="'muted'"
                playsinline
                webkit-playsinline
              ></video>
              <label
                class="w-full flex justify-center"
                [htmlFor]="'fileInput'"
                *ngIf="!allImages[i] && !allImages[i]?.content"
                (click)="selectedIndex = i + 1"
              >
                <mat-icon class="gray-dark icon-align">add_a_photo</mat-icon>
              </label>
              <div
                style="height: 36px"
                *ngIf="allImages[i] && allImages[i].content"
                class="absolute right-0 top-0 flex items-center justify-center p-1 border-2 bg-black rounded-full"
              >
                <label [htmlFor]="'fileInput'" (click)="selectedIndex = i + 1">
                  <mat-icon class="white" style="margin-top: 2px">edit</mat-icon>
                </label>
              </div>
            </div>

            <input
              id="fileInput"
              [accept]="'image/*,video/*'"
              #file
              multiple
              type="file"
              class="uploadFile img w-0 h-0 overflow-hidden"
              value="Upload Media"
              (change)="uploadFile(file.files, i)"
              style="display: none"
            />
          </ng-template>
        </swiper>
        <button
          class="py-1.5 px-4 text-center hover:bg-[#2887ed] text-black w-full hover:text-white border-2 border-[#2887ed] rounded mt-9 md:mt-0"
          (click)="updateSubscriptionContent()"
        >
          Save
        </button>
      </div>
    </div>
    <div class="mb-6 pricingPackage">
      <div class="grid grid-cols-3">
        <div
          class="plans-btn border"
          (click)="selectPackage('Photos')"
          [ngClass]="selectedPlan === 'Photos' ? 'selected-plan' : ''"
        >
          Photos
        </div>
        <div
          class="plans-btn border-y"
          (click)="selectPackage('Videos')"
          [ngClass]="selectedPlan === 'Videos' ? 'selected-plan' : ''"
        >
          Videos
        </div>
        <div
          class="plans-btn border"
          (click)="selectPackage('Multimedia')"
          [ngClass]="selectedPlan === 'Multimedia' ? 'selected-plan' : ''"
        >
          Multimedia
        </div>
      </div>

      <!-- PACKAGE VIEW MODE -->
      <div
        *ngIf="selectedPackage && !selectedPackage?.editMode"
        class="border-x border-b px-6 pb-5 pt-8"
      >
        <p
          *ngIf="selectedSubscription?.profile_id == loginedUser?._id"
          class="blue cursor-pointer font-bold flex justify-end text-sm mb-4"
          (click)="selectedPackage.editMode = true"
        >
          Edit
        </p>
        <div>
          <div class="flex justify-between">
            <p class="packge-name">{{ selectedSubscription.title }}</p>
            <p class="pakge-price">${{ selectedPackage?.price }}</p>
          </div>
          <p class="package-description">{{ selectedPackage?.description }}</p>
          <p class="points-heading">What's Included</p>
          <div class="services-items mb-5">
            <div
              class="item flex items-center mt-2 gap-3"
              *ngFor="let item of selectedPackage?.features"
            >
              <mat-icon class="item-icon font-bold">check</mat-icon>
              <p class="item-text font-bold">{{ item }}</p>
            </div>
          </div>
          <button class="package-btn bg-[#404145]" (click)="pay()">Subscribe</button>
          <button
            *ngIf="
              selectedSubscription?.profile_id === loginedUser?._id &&
              selectedSubscription?.active != true
            "
            class="py-1.5 message-btn blue"
            (click)="activeInactiveSubscription(true)"
          >
            Activate
          </button>
          <button
            *ngIf="
              selectedSubscription?.profile_id === loginedUser?._id &&
              selectedSubscription?.active == true
            "
            class="py-1.5 deactivate-btn"
            (click)="activeInactiveSubscription(false)"
          >
            Deactivate
          </button>
          <button
            *ngIf="selectedSubscription?.profile_id != loginedUser?._id"
            class="py-1 message-btn"
            (click)="message(selectedSubscription)"
          >
            Message
          </button>
        </div>
      </div>

      <!-- NO PACKAGE FOUND -->
      <div *ngIf="!selectedPackage" class="border-x border-b px-6 pb-5 pt-8">
        <p class="flex justify-center font-bold">No package found</p>
      </div>

      <!-- PACKAGE EDIT MODE -->
      <div *ngIf="selectedPackage?.editMode" class="border-x border-b px-6 pb-5 pt-4">
        <p
          *ngIf="selectedSubscription?.profile_id == loginedUser?._id"
          class="blue cursor-pointer font-bold flex justify-end text-sm mb-4"
          (click)="selectedPackage.editMode = false"
        >
          Cancel
        </p>
        <div>
          <div class="mt-6" *ngFor="let item of getArray(1)">
            <app-subscription-package-unit-update
              [pricingPackages]="selectedPackage"
              [checkPriceValidation]="true"
              [order]="getSelectedPackageIndex()"
              (setPackageForm)="savePackageData($event)"
            ></app-subscription-package-unit-update>
          </div>
          <button
            class="package-btn bg-[#2887ed]"
            (click)="updatePackage(selectedPackage)"
            [class]="!isUpdated ? 'disabled' : ''"
            [disabled]="!isUpdated"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="my-4">
    <mat-divider></mat-divider>
  </div>
  <ngx-spinner
    bdOpacity="0.9"
    bdColor="black-dark"
    size="medium"
    color="red"
    type="ball-spin-clockwise"
    [fullScreen]="true"
    class="job-list-spinner"
  >
  </ngx-spinner>
  <div class="" *ngIf="subscriptions?.length">
    <h4 class="user-name mb-3 text-center">
      Other subscriptions by {{ selectedSubscription?.profile?.user_name }}
    </h4>
    <div class="grid sm:grid-cols-2 md:grid-cols-3">
      <div *ngFor="let subscription of subscriptions">
        <app-individual-service-card [service]="subscription"></app-individual-service-card>
      </div>
    </div>
  </div>
</div>
