import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  messageType = '';
  constructor(private snackBar: MatSnackBar, private ngZone: NgZone) {}

  showMessage(message: string,isError: boolean) {
    this.ngZone.run(() => {
    isError ? this.messageType = 'warning-snackbar': this.messageType = 'success-snackbar'
    this.snackBar.open(`${message}`, '', {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: [this.messageType,'normal-snackbar'],
    });
  });
  }
}
