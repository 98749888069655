import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReferralService } from 'src/app/modules/services/referral-services/referral.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { defaultSortByOption, sortBy } from 'src/app/core/const/options';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-dashboard-referals',
  templateUrl: './dashboard-referals.component.html',
  styleUrls: ['./dashboard-referals.component.scss']
})
export class DashboardReferalsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  selected = new FormControl(0);
  referralData: any;
  plans: any;
  totalEarning: any;
  searchSortValue?: string;
  sortByData = sortBy;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5,
  };

  constructor(private referralService: ReferralService, private route: ActivatedRoute) {
    this.getReferrals(defaultSortByOption.defaultSortBy)
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((referal: any) => {
      if (referal.tab) {
        setTimeout(() => {
          this.selected.setValue(parseInt(referal.tab));
        }, 100);
      } else {
        this.selected.setValue(0);
      }
    });
  }

  getReferrals(sortBy: any) {
    this.referralService.mineReferrals(sortBy).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
      this.referralData = result.data;
      this.totalEarning = result.totalEarning;
    });
  }

  sortBy(sortBy: any) {
    this.searchSortValue = this.sortByData.type.filter((item: { id: string; label: string }) => item.id === sortBy)[0].label;
    this.getReferrals(this.searchSortValue);
  }

  goToReferral() {
    this.selected.setValue(1)
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
