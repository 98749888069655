import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { defaultSortByOption, sortBy } from 'src/app/core/const/options';
import { registerValues } from 'src/app/core/const/register';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';

// export type PieChartOptions = {
//   series: ApexNonAxisChartSeries;
//   chart: ApexChart;
//   plotOptions: ApexPlotOptions;
//   stroke: ApexStroke;
//   colors: string[];
//   label: string;
// };

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.scss']
})
export class DashboardStatsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  selected = new FormControl(0);
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5,
  };
  total: any;
  totalReferralEarning: number = 0;
  totalServicesEarning: number = 0;
  totalSubscriptionsEarning: number = 0;
  subscribers:number=0;
  services:number=0;
  followers:number=0;
  paidPosts:number=0;
  referrals:number=0;
  searchSortValueEarning?: string = defaultSortByOption.defaultSortBy;
  searchSortValueStats?: string = defaultSortByOption.defaultSortBy;
  sortByData = sortBy;
  // public subscriberChartOptions: PieChartOptions
  // public chartOptionsArr: PieChartOptions[]
  // public chartOptionsEarning!: ChartOptions;
  // public chartOptions: ChartOptions;
  statsChartOptionArr = [{ key: 'all', value: 'All users' }]
  statsChartOption: any;
  registerValues = registerValues;
  type = registerValues.UserTypes.candidate;
  isAdmin = false;

  constructor(public authService: AuthService, private sharedService: SharedService, private PaymentService: paymentService) {
    this.getReferralEarning(this.searchSortValueEarning);
    this.getNetworkStats(this.searchSortValueStats);
    this.statsChartOption = 'all'
    // this.subscriberChartOptions = {
    //   series: [46], chart: { height: 240, type: "radialBar" }, plotOptions: {
    //     radialBar: {
    //       hollow: { size: "70%", }, dataLabels: {
    //         show: false
    //       }
    //     }
    //   },
    //   stroke: {
    //     lineCap: "round"
    //   },
    //   colors: ['#3FC374'],
    //   label: 'Memberships'
    // }
    // this.chartOptionsArr = [
    //   {
    //     ...this.subscriberChartOptions,
    //     series: [1 / 8],
    //     colors: ['#3FC374'],
    //     label: 'Memberships'
    //   },
    //   {
    //     ...this.subscriberChartOptions,
    //     series: [1 / 8],
    //     colors: ['#333333'],
    //     label: 'Services'
    //   },
    //   {
    //     ...this.subscriberChartOptions,
    //     series: [1 / 8],
    //     colors: ['#ED9E28'],
    //     label: 'Referrals'
    //   }
    // ]

    // this.chartOptions = {
    //   series: [
    //   ],
    //   chart: {
    //     height: 350,
    //     type: "line",
    //     dropShadow: {
    //       enabled: true,
    //       color: "#000",
    //       top: 18,
    //       left: 7,
    //       blur: 10,
    //       opacity: 0.2
    //     },
    //     toolbar: {
    //       show: false
    //     }
    //   },
    //   colors: ["#2887ED", "#E63946"],
    //   dataLabels: {
    //     enabled: false
    //   },
    //   stroke: {
    //     curve: "smooth"
    //   },
    //   title: {
    //     text: "",
    //     align: "right"
    //   },
    //   grid: {
    //     borderColor: "#E5E5E5",
    //     row: {
    //       colors: ["#FFFFFF", "transparent"],
    //       opacity: 0.5
    //     }
    //   },
    //   markers: {
    //     size: 3
    //   },
    //   xaxis: {
    //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    //     title: {
    //     }
    //   },
    //   yaxis: {
    //     title: {
    //       text: ""
    //     },
    //     min: 0,
    //   },
    //   legend: {
    //     position: "top",
    //     horizontalAlign: "right",
    //     floating: true,
    //     offsetY: -5,
    //     offsetX: -0
    //   }
    // };
  }

  kFormatter(num: number) {
    return num > 999 ? (num / 1000 + 'K') : num
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.userType();
    }
    this.isAdmin = this.authService.isAdmin();
  }


  userType(): void {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === "") {
        this.sharedService.getUserType();
      } else {
        this.type = type;
      }
    });

  }

  getReferralEarning(sortBy: any) {
    this.PaymentService.totalEarning(sortBy).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      // this.chartOptionsEarning = {
      //   series: [],
      //   chart: {
      //     height: 350,
      //     type: "line",
      //     dropShadow: {
      //       enabled: true,
      //       color: "#000",
      //       top: 18,
      //       left: 7,
      //       blur: 10,
      //       opacity: 0.2
      //     },
      //     toolbar: {
      //       show: false
      //     }
      //   },
      //   colors: ["#2887ED", "#E63946"],
      //   dataLabels: {
      //     enabled: false
      //   },
      //   stroke: {
      //     curve: "smooth"
      //   },
      //   title: {
      //     text: "",
      //     align: "right"
      //   },
      //   grid: {
      //     borderColor: "#E5E5E5",
      //     row: {
      //       colors: ["#FFFFFF", "transparent"], // takes an array which will be repeated on columns
      //       opacity: 0.5
      //     }
      //   },
      //   markers: {
      //     size: 3
      //   },
      //   xaxis: {
      //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      //     title: {
      //     }
      //   },
      //   yaxis: {
      //     title: {
      //       text: ""
      //     },
      //     min: 0,
      //   },
      //   legend: {
      //     position: "top",
      //     horizontalAlign: "right",
      //     floating: true,
      //     offsetY: -5,
      //     offsetX: -0
      //   }
      // };
      // this.chartOptionsEarning.series = [{ name: "", data: res.data.byMonths }]
      // this.chartOptionsEarning.yaxis.max = (Math.round(Math.max(...res.data.byMonths) / 100) * 100) + 100
      this.total = res.data.results
      this.totalReferralEarning = res.data.results[0]?.referralPaymentDetails.reduce((init: number, { amount }: any) => init += +(amount || 0), 0);
      this.totalServicesEarning = res.data.results[0]?.servicePaymentDetails.reduce((init: number, { amount }: any) => init += +(amount || 0), 0);
      this.totalSubscriptionsEarning = res.data.results[0]?.subscriptionPaymentDetails.reduce((init: number, { amount }: any) => init += +(amount || 0), 0);
      this.getPercentAge();
    })
  }

  getNetworkStats(sortBy: any) {
    this.PaymentService.networkStats(sortBy).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      // this.chartOptions.series = [{ name: "followers", data: res.data.followersbyMonth }, { name: "subscribers", data: res.data.subscribersbyMonth }]
      this.paidPosts = res.data.paidPosts
      this.followers = res.data.followersbyMonth.reduce((init: any, currentValue: any) => init + currentValue);
      this.subscribers = res.data.subscribersbyMonth.reduce((init: any, currentValue: any) => init + currentValue);
      this.services = res.data.servicesbyMonth.reduce((init: any, currentValue: any) => init + currentValue);
      this.referrals= res.data.referralsbyMonth.reduce((init: any, currentValue: any) => init + currentValue);

    })
  }

  getPercentAge(chartOptions?: any) {
    const totalEarning = (this.totalSubscriptionsEarning + this.totalServicesEarning + this.totalReferralEarning)
    if (chartOptions) {
      return Math.round(chartOptions == 'Memberships' ? ((this.totalSubscriptionsEarning * 100) / totalEarning) : chartOptions == 'Services' ? ((this.totalServicesEarning * 100) / totalEarning) : chartOptions == 'Referrals' ? ((this.totalReferralEarning * 100) / totalEarning) : 0)
    }
    else {
      if (totalEarning !== 0) {
        // this.chartOptionsArr[0].series = [((this.totalSubscriptionsEarning * 100) / totalEarning)]
        // this.chartOptionsArr[1].series = [((this.totalServicesEarning * 100) / totalEarning)]
        // this.chartOptionsArr[2].series = [((this.totalReferralEarning * 100) / totalEarning)]
      }
      else {
        // this.chartOptionsArr[0].series = [1 / 8]
        // this.chartOptionsArr[1].series = [1 / 8]
        // this.chartOptionsArr[2].series = [1 / 8]
      }
      return ''
    }
  }

  setSearchSort(val: string, check: string) {
    if (check == 'earning') {
      this.searchSortValueEarning = this.sortByData.type.filter((item: { id: string; label: string }) => item.id === val)[0].label;
      this.getReferralEarning(this.searchSortValueEarning);
    }
    else {
      this.searchSortValueStats = this.sortByData.type.filter((item: { id: string; label: string }) => item.id === val)[0].label;
      this.getNetworkStats(this.searchSortValueStats);
    }
  }

  getEarningByPlanType(chartOptions?: any) {
    return Math.round(chartOptions == 'Memberships' ? this.totalSubscriptionsEarning : chartOptions == 'Services' ? this.totalServicesEarning : chartOptions == 'Referrals' ? this.totalReferralEarning : 0);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
