import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { routesConst } from 'src/app/core/const/routers';

@Component({
  selector: 'app-login-signup-dialog',
  templateUrl: './login-signup-dialog.component.html',
  styleUrls: ['./login-signup-dialog.component.scss'],
})
export class LoginSignupDialogComponent implements OnInit {
  matSelectIndex = 0;
  loginSignUp = 'login';
  routesConst = routesConst;
  constructor(
    public dialogRef: MatDialogRef<LoginSignupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loginSignUp = this.data.pageToOpen === 'login' ? 'login' : 'SignUp';
  }

  ngOnInit(): void {}

  close(isLoggedIn: boolean) {
    this.dialogRef.close(isLoggedIn);
  }

  signInSignUp(event: string){
    this.loginSignUp = event
  }
}
