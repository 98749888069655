import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { apiUrlConst } from 'src/app/const/apiConst';
import { config } from 'src/config/config';
import { CommonService } from 'src/app/core/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  constructor(public _http: HttpClient, private commonService: CommonService) {}

  /**
   * Mine Referrals
   * @returns
   */
  mineReferrals(filter: any) {
    let params = new HttpParams();
    params = params.append('filter', filter);
    const options = { params: params };
    return this._http.get(
      config.environmentMode(this.commonService.location().hostname).endPoint +
        apiUrlConst.userReferral,
      options
    );
  }
}
