<div class="stats-page">
  <div class="flex items-center w-full window-mode mb-5">
    <h1 class="montserrat-bold mr-auto text-style">Insights</h1>
  </div>
  <div class="mt-4 px-4 lg:px-0">
    <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container dashboard-insights-swipper common-arrow"
    >
        <ng-template swiperSlide>
          <div class="flex items-center tab swiper-tab cursor-pointer" (click)="selected.value = 0" [class]="selected.value === 0 ? 'selected' : ''">
            <p  class="tab-size" [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
              Insights 
            </p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="flex items-center tab swiper-tab cursor-pointer" (click)="selected.value = 1" [class]="selected.value === 1 ? 'selected' : ''">
            <p  class="tab-size" [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
              Earnings 
            </p>
          </div>
        </ng-template>
    </swiper>
    <div class="tabs">
      <mat-tab-group class="insights-tabs" color="#fff0" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
              <p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
                Insights 
              </p>
            </div>
          </ng-template>
          <div class="section-wrapper px-0 pt-2 pb-4 lg:p-10 mb-4">
            <div class="flex flex-col lg:flex-row mb-2">
              <div class="mb-2 lg:mb-0">
                <!-- <p class="black-light montserrat-bold mt-1.5">Insights</p> -->
                <p class="s lg:mt-2 black-light">See site statistics</p>
              </div>
              <div class="block lg:hidden">
                <mat-divider class="w-full"></mat-divider>
              </div>
            </div>
            <div class="hidden lg:block">
              <mat-divider class="w-full"></mat-divider>
            </div>
            <div class="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
              <!-- <div class="flex flex-col lg:items-center">
                <h1 class="montserrat-bold">{{ paidPosts }}</h1>
                <p class="gray-dark">Paid Posts</p>
              </div> -->
              <div class="flex flex-col lg:items-center">
                <h1 class="stats-price">{{ followers }}</h1>
                <p class="black-light s">Followers</p>
              </div>
              <div class="flex flex-col lg:items-center">
                <h1 class="stats-price">{{ referrals }}</h1>
                <p class="black-light s">Referrals</p>
              </div>
              <div class="flex flex-col lg:items-center">
                <h1 class="stats-price">{{ subscribers }}</h1>
                <p class="black-light s">Subscribers</p>
              </div>
              <div class="flex flex-col lg:items-center">
                <h1 class="stats-price">{{ services }}</h1>
                <p class="black-light s">Services </p>
              </div>
            </div>
            <div
              class="border-normal rounded w-32 flex items-center px-3 py-2 lg:ml-auto mt-6 fit"
            >
              <app-select-form
                [label]="searchSortValueStats"
                (set)="setSearchSort($event, 'stats')"
                [data]="sortByData"
              >
              </app-select-form>
            </div>
            <!-- <div class="mt-1" *ngIf="chartOptions">
              <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                [stroke]="chartOptions.stroke" [colors]="chartOptions.colors" [dataLabels]="chartOptions.dataLabels"
                [legend]="chartOptions.legend" [markers]="chartOptions.markers" [grid]="chartOptions.grid"
                [yaxis]="chartOptions.yaxis" [title]="chartOptions.title"></apx-chart>
            </div> -->
          </div>
        </mat-tab>         
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 1 ? 'selected' : ''">
              <p [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
                Earnings
              </p>
            </div>
          </ng-template>
          <div class="section-wrapper px-0 pt-2 pb-4 lg:p-10">
            <div class="mb-2">
              <div class="mb-2">
                <!-- <p class="black-light montserrat-bold mt-1.5">Earnings</p> -->
                <p class="s black-light">See site earnings</p>
              </div>
              <mat-divider class="w-full"></mat-divider>
              <div
                class="border-normal rounded w-32 flex items-center px-3 py-2 lg:ml-auto mt-6 fit"
              >
                <app-select-form
                  [label]="searchSortValueEarning"
                  (set)="setSearchSort($event, 'earning')"
                  [data]="sortByData"
                >
                </app-select-form>
              </div>
            </div>
            <!-- <div class="grid md:grid-cols-3 gap-5  mt-6>">
              <div class="flex flex-col items-center justify-center" *ngFor="let chartOptions of chartOptionsArr">
                <div class="flex flex-col items-center mt-8 md:mt-0">
                  <h1 class="montserrat-bold">${{getEarningByPlanType(chartOptions.label)}}</h1>
                  <p class="text-center">{{chartOptions.label}}</p>
                </div>
                <div class="flex items-center justify-center">
                  <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                    [plotOptions]="chartOptions.plotOptions" [stroke]="chartOptions.stroke" [colors]="chartOptions.colors">
                  </apx-chart>
                  <div class="flex absolute items-center justify-center flex-col">
                    <h4 class="montserrat-bold">{{getPercentAge(chartOptions.label) || 0 }}%</h4>
                    <div class="flex items-center -ml-1">
                    </div>
                  </div>
                </div>
                <p class="text-center">{{chartOptions.label}}</p>
              </div>
            </div> -->
        
            <!-- <div class="" *ngIf="chartOptionsEarning">
              <apx-chart [series]="chartOptionsEarning.series" [chart]="chartOptionsEarning.chart"
                [xaxis]="chartOptionsEarning.xaxis" [stroke]="chartOptionsEarning.stroke" [colors]="chartOptionsEarning.colors"
                [dataLabels]="chartOptionsEarning.dataLabels" [legend]="chartOptionsEarning.legend"
                [markers]="chartOptionsEarning.markers" [grid]="chartOptionsEarning.grid" [yaxis]="chartOptionsEarning.yaxis"
                [title]="chartOptionsEarning.title"></apx-chart>
            </div> -->
          </div>
        </mat-tab>         
      </mat-tab-group>
    </div>
  </div>
</div>



  
