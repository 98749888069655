<div class="profile-about">
  <div class="bio-wrapper border-b-4 md:border rounded w-full md:pb-9 mb-3">
    <div class="flex items-center">
      <p class="montserrat-bold text-black mb-2">About</p>
    </div>
    <p class="over-flow-anywhere text-sm" *ngIf="user?.profile?.bio">
      {{ user.profile.bio }}
    </p>
    <p class="over-flow-anywhere" *ngIf="!user?.profile?.bio">Not Available</p>
  </div>
  <div class="bio-wrapper border-b-4 md:border rounded w-full lg:mt-7 md:pb-9">
    <!-- <p class="montserrat-bold black-light">Career Highlights</p> -->
    <div class="mt-5 flex items-center w-full mb-2">
      <p class="montserrat-bold text-black">Category</p>
    </div>
    <p  class="text-sm">{{ user?.profile?.category[0]?.name}}</p>
    <div class="mt-5 flex items-center w-full mb-2">
      <p class="montserrat-bold text-black">Experience Level</p>
    </div>
    <li *ngIf="expLevel?.title" class="text-sm">{{ expLevel?.title | removewhitespace }}</li>
    <p *ngIf="!expLevel?.title">Not Available</p>
    <div class="mt-3 flex items-center w-full mb-2">
      <p class="montserrat-bold text-black">Preferred Skills</p>
    </div>
    <div class="flex flex-col items-start lg:items-center w-full skills-tags">
      <div class="flex flex-wrap w-full pointer-events-none">
        <div
          *ngFor="let item of userSkills"
          class="mr-2 whitespace-nowrap truncate mt-2 tags-wraper text-sm"
        >
          {{ item.title }}
        </div>
        <p *ngIf="!userSkills?.length">Not Available</p>
      </div>
    </div>
    <div>
      <div class="flex items-center w-full mb-2 mt-3">
        <p class="montserrat-bold text-black">Client Project</p>
      </div>
      <app-educ-card
        *ngFor="let item of projects"
        [school]="item"
        [editable]="false"
      ></app-educ-card>
      <p *ngIf="!projects?.length">Not Available</p>
    </div>
    <div *ngIf="user?.profile?.user_type == userTypeConstant.candidate">
      <div class="flex items-center w-full mb-2 mt-3">
        <p class="montserrat-bold text-black">Work History</p>
      </div>
      <app-educ-card
        *ngFor="let item of workHistory"
        [school]="item"
        [editable]="false"
      ></app-educ-card>
      <p *ngIf="!workHistory?.length">Not Available</p>
    </div>
    <div *ngIf="user?.profile?.user_type == userTypeConstant.candidate">
      <div class="flex items-center w-full mb-1 mt-3">
        <p class="montserrat-bold text-black">Education</p>
      </div>
      <app-educ-card
        *ngFor="let item of educations"
        [school]="item"
        [editable]="false"
      ></app-educ-card>
      <p *ngIf="!educations?.length">Not Available</p>
    </div>
    <div class="mt-3 flex flex-col items-start w-full interests-header">
      <p class="montserrat-bold text-black">Interests</p>
      <div class="flex flex-col items-start lg:items-center w-full">
        <div class="flex flex-wrap w-full mt-2 pointer-events-none">
          <div
            *ngFor="let item of user?.profile?.skills"
            class="tags-wraper mr-2 mt-2 text-sm"
          >
            #{{ item.name }}
          </div>
          <p *ngIf="!user?.profile?.skills?.length">Not Available</p>
        </div>
      </div>
    </div>
  </div>
</div>
