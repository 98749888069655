<div *ngIf="replays?.length">
  <div class="border-normal rounded w-40 flex items-center p-3 bg-white">
    <mat-select [value]="replayOption">
      <mat-option *ngFor="let item of replayOptionArr" [value]="item.key">{{item.value}}</mat-option>
    </mat-select>
  </div>
  <div class="grid grid-cols-2 lg:grid-cols-3 gap-x-3.5 gap-y-2 mt-4">
    <app-dashboard-livestream-replay-card *ngFor="let item of replays" [data]="item">
    </app-dashboard-livestream-replay-card>
  </div>
</div>
<!-- <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
  [fullScreen]="true" class="job-list-spinner">
</ngx-spinner> -->
<div *ngIf="!replays?.length">
  <h4>{{message}}</h4>
</div>