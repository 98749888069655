<div class="job-post-modal bg-white m-0 md:-m-6 relative">
  <div class="header z-10 fixed left-0 top-0 right-0 md:relative">
    <div class="absolute right-2">
      <mat-icon class="cursor-pointer m-2 mt-4 text-white" (click)="dismiss()">close</mat-icon>
    </div>
    <img class="w-10 absolute top-2 left-3" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">
    <h4 class="montserrat-bold text-center items-center text-white flex job-posting">{{ isJobEdit ? "Update Job" : "Job Posting" }}</h4>
    <mat-divider></mat-divider>
  </div>
  <div class="row px-4 md:px-12 overflow-section triger-heiht">
    <form [formGroup]="jobForm" class="col-md-8 col-lg-9">
      <mat-form-field class="w-full mt-3.5">
        <mat-label>Category</mat-label>
        <mat-select class="border-normal rounded px-3  h-12" formControlName="job_category">
          <mat-option *ngFor="let item of categories" [value]="item.id">{{ item.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field class="w-full">
        <input matInput maxlength="257" class="w-full border-normal rounded px-3 py-2 mb-4" placeholder="Job Title"
          formControlName="job_title" />
        <mat-error *ngIf="errorHandling('job_title', 'required')"
          [class]="errorHandling('job_title', 'required') ? 'pb-4' : ''">
          <p class="s red">You must provide a title.</p>
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="errorHandling('job_title', 'maxlength')"
        [class]="errorHandling('job_title', 'maxLength') ? 'pb-4' : ''">
        <p class="s red">You cannot excced more than 256 chracters.</p>
      </mat-error>

      <mat-form-field class="w-full">
        <textarea matInput maxlength="1053" class="border-normal rounded"
          placeholder="Describe what you are looking for.. " formControlName="job_description"
          id="job-description"></textarea>
        <mat-error *ngIf="errorHandling('job_description', 'required')"
          [class]="errorHandling('job_description', 'required') ? 'pb-4' : ''">
          <p class="s red">You must provide a description.</p>
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="errorHandling('job_description', 'maxlength')"
        [class]="errorHandling('job_description', 'maxLength') ? 'pb-4' : ''">
        <p class="s red">You cannot exceed more than 1052 chracters.</p>
      </mat-error>

      <div class="grid lg:grid-cols-2 md:grid-cols-1 gap-x-2">
        <div class="location">
          <mat-form-field class="w-full mat-location-input mat-label-align">
            <mat-label>Job 's Location</mat-label>
            <input required placeholder="Type City" type="text" #locationSearchInput aria-label="Location" matInput
              [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions"
                value="{{option.city_name}} - {{option.state_name}} - {{option.country_code}}"
                (click)="setLocation(option)">
                {{ option.city_name }}{{ option.state_name ? ' - ' + option.state_name : '' }} {{
                option.country_code ? ' - ' + option.country_code : '' }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="triger-heiht">
          <mat-form-field class="w-full">
            <mat-label>Weekly Salary</mat-label>
            <mat-select class="border-normal rounded px-3 h-12" formControlName="salary_range">
              <mat-option *ngFor="let item of payRange" [value]="item.id">{{ item.label }}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div class="padding-set triger-heiht">
          <mat-form-field class="w-full mat-label-align">
            <mat-label>Job type</mat-label>
            <mat-select class="border-normal rounded px-3  mb-4 h-12" formControlName="job_type">
              <mat-option *ngFor="let type of jobTypes" [value]="type.id">{{type.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="padding-set triger-heiht">
          <mat-form-field class="w-full mat-label-align">
            <mat-label>Experience Level</mat-label>
            <mat-select class="border-normal rounded px-3  mb-4 h-12" formControlName="experience_required">
              <mat-option *ngFor="let item of experienceLists" [value]="item.id">{{ item.label }} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="mt-2">
          <div class="flex flex-col gap-2">
            <p class="montserrat-bold">Categories</p>
          <div class="flex flex-col gap-2">
              <mat-checkbox
              formControlName="featured"
              class="pt-1"
              [color]="'primary'"
              (change)="onCheckboxChange('FEATURED',$event)"
              [value]=""
            >
              <p>Featured</p>
            </mat-checkbox>
              <mat-checkbox
              formControlName="sponsored"
              class="pt-1"
              [color]="'primary'"
              (change)="onCheckboxChange('SPONSORED', $event)"
              [value]=""
            >
              <p>Sponsored</p>
            </mat-checkbox>
          </div>
          </div>
        </div>
      </div>

      <div class="-mt-6" [ngClass]="jobForm.get('newFeature')?.value.length > 0 ? 'Preferred-Skills-section' : ''">
        <mat-form-field class="w-full">
          <input matInput maxlength="129" type="text" (keypress)="addSkills($event)"
            placeholder="+ Preferred Skills" class="input-form" formControlName="newFeature" />
        </mat-form-field>
        <div class="my-auto" *ngIf="jobForm.get('newFeature')?.value">
          <button mat-button type="button" class="button" *ngIf="jobForm.get('newFeature')" (click)="addNewFeature()">
            +
          </button>
        </div>
        <mat-error class="col-span-2" *ngIf="errorHandling('newFeature', 'maxlength')"
          [class]="errorHandling('newFeature', 'maxLength') ? 'pb-4' : ''">
          <p class="s red">You cannot exceed more than 128 chracters.</p>
        </mat-error>
      </div>
      <div class="mb-2">
        <div class="flex items-center flex-wrap">
          <div *ngFor="let feature of selectedFeatures" class="items-center break-all job-feature-tag mb-2">
            <mat-icon class="mr-2" (click)="removeFeature(feature)">close</mat-icon>
            {{ feature }}
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="fixed bg-white md:relative left-0 right-0 bottom-12 md:bottom-0">
    <div class="mb-4">
      <mat-divider></mat-divider>
    </div>
    <div class="flex justify-between items-center px-4 pb-4">
      <button mat-flat-button class="button warning black w-36" (click)="dismiss()">Cancel</button>
      <button [class]="(isJobEdit?!jobForm.valid || !update : !jobForm.valid)?'disabled':''" mat-button
         class="button black w-36" (click)="submitJob()"
        [disabled]="isJobEdit?!update && !jobForm.valid : !jobForm.valid">
        {{ isJobEdit ? "Update" : "Post" }}
        <!-- <mat-icon>arrow_right_alt</mat-icon> -->
      </button>
    </div>
  </div>
</div>