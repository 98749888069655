<div *ngIf="data !== undefined" class="p-2 border-normal rounded bg-white">
  <mat-icon class="cursor-pointer">more_horiz</mat-icon>
  <div class="flex relative cursor-pointer" (click)="openVideo(data.url)">
    <div *ngIf="data.paid"
      class="bg-red rounded text-white uppercase absolute left-0 top-0 px-4 py-1.5 montserrat-bold">paid</div>
    <img [src]="getStreamImage(data.thumbnail_url)" class="w-full h-44 rounded">
  </div>
  <p class="montserrat-bold my-2">{{data.title}}</p>
  <div class="flex flex-wrap" *ngIf="data.interests !== undefined">
    <p class="s blue mr-2" *ngFor="let item of data.interests">#{{item}}</p>
  </div>
</div>