<div class="post-page">
  <div
    class="flex overflow-x-auto justify-between gap-2 relative images-scroll-wrapper"
    #imagesWrapper
  >
    <!-- <div class="fixed left-arrow rounded-full" (click)="scroll('left')">left</div> -->
    <div
      *ngFor="let post of posts"
      class="post-wrapper cursor-pointer"
      (click)="postDetail(post)"
    >
      <img
        *ngIf="post?.content?.media_type === 'moment'"
        [src]="post.content.url"
        alt=""
      />
      <video
        *ngIf="post?.content?.media_type !== 'moment'"
        [src]="post.content.url+ '#t=0.1'"  preload="metadata"
      ></video>
    </div>
    <!-- <div class="fixed right-arrow right-0 rounded-full" (click)="scroll('right')">left</div> -->
  </div>
</div>
