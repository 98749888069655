<div class="profile-about">
  <div class="bio-wrapper border-b-4 md:border rounded w-full md:pb-9 mb-3">
    <div class="flex items-center">
      <p class="montserrat-bold black-light">Preferences</p>
    </div>
  </div>
  <div class="bio-wrapper border-b-4 md:border rounded w-full lg:mt-7 md:pb-9">
    <div class="mt-5 flex items-center w-full mb-2">
      <p class="montserrat-bold black-light">Experience Level</p>
    </div>
    <li *ngFor="let item of candidateJobPreference?.experience_level_details">
      {{ item.level + "(" + item?.job_experience + ")" | removewhitespace }}
    </li>
    <p *ngIf="!candidateJobPreference?.experience_level_details?.length">
      Not Available
    </p>

    <div class="mt-3 flex items-center w-full mb-2">
      <p class="montserrat-bold black-light">Categories</p>
    </div>
    <div class="flex flex-col items-start lg:items-center w-full">
      <div class="flex flex-wrap w-full pointer-events-none">
        <div
          *ngFor="let item of candidateJobPreference?.categories_details"
          class="mr-2 whitespace-nowrap truncate tag mt-2 skills-tags"
        >
          {{ item.name }}
        </div>
        <p *ngIf="!candidateJobPreference?.categories_details?.length">
          Not Available
        </p>
      </div>
    </div>
    <div class="mt-5 flex items-center w-full mb-2">
      <p class="montserrat-bold black-light">Job Type</p>
    </div>
    <li *ngIf="candidateJobPreference?.jobtype_details">
      {{ candidateJobPreference?.jobtype_details[0]?.name | removewhitespace }}
    </li>
    <p *ngIf="!candidateJobPreference?.jobtype_details">Not Available</p>

    <div class="mt-5 flex items-center w-full mb-2">
      <p class="montserrat-bold black-light">Work Location</p>
    </div>
    <li *ngIf="candidateJobPreference?.location">
      {{
        candidateJobPreference?.location[0]?.city_name +
          ", " +
          candidateJobPreference?.location[0]?.state_name +
          ", " +
          candidateJobPreference?.location[0]?.country_name +
          "(" +
          candidateJobPreference?.location[0]?.country_code +
          ")"
      }}
    </li>
    <p *ngIf="!candidateJobPreference?.location">Not Available</p>
    <div class="mt-3 flex flex-col items-start w-full interests-header">
      <p class="montserrat-bold black-light">Desired Weekly Pay</p>
      <div class="flex flex-col items-start lg:items-center w-full">
        <div class="flex flex-wrap w-full mt-2 pointer-events-none">
          <div
            *ngFor="let item of candidateJobPreference?.desired_salary_details"
            class="tag mr-2 mt-2 skills-tags"
          >
            {{ item.pay_range }}
          </div>
          <p *ngIf="!candidateJobPreference?.desired_salary_details?.length">
            Not Available
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
