import { Component, Inject, OnInit } from '@angular/core';
import { ModeratorRoles, userTypeConstant } from 'src/app/const/appConst';
import { SharedService } from '../../services/shared.service';
import { Subject, takeUntil } from 'rxjs';
import { findIndex } from 'lodash';
import { ProfileService } from 'src/app/core/services/profile.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../services/snack-bar.service';

@Component({
  selector: 'app-invite-user-modal',
  templateUrl: './invite-user-modal.component.html',
  styleUrls: ['./invite-user-modal.component.scss']
})
export class InviteUserModalComponent implements OnInit {
  ModeratorRoles = ModeratorRoles
  private unsubscribe$ = new Subject();
  followings: any[] = [];
  filteredUser: any[] = [];
  searchTerm:string = '';

  searchIdx = '';

  userTypeConstant = userTypeConstant;
  type:string = 'admin'

  selectedUser:string[] = [];
  selectedRoles:string[] = [];

  moderatorRoles = [
    ModeratorRoles.ALL,
    ModeratorRoles.JOB_MODERATOR,
    ModeratorRoles.SERVICE_MODERATOR,
    ModeratorRoles.POST_MODERATOR,
    ModeratorRoles.SUBSCRIPTIONS_MODERATOR
  ];


  constructor(private sharedService: SharedService,@Inject(MAT_DIALOG_DATA) public data: any, private profileService: ProfileService, private snackService: SnackBarService,
    public dialog: MatDialogRef<InviteUserModalComponent>,) { }

  ngOnInit(): void {
    if (this.data.user) {
      this.selectedRoles = this.data.user.roles;  
      this.selectedUser.push(this.data?.user?.user_id)  
    }
    this.getFollowersAndFollowings();
  }

  getFollowersAndFollowings() {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((network) => {
        if (network?.followings || network?.followers) {
          let share = network.followings.concat(network.followers);
          // Assuming share is your original array of items
        this.followings = Array.from(new Map(share.map((item: any) => [item['user_name'],
         { ...item, selectedUserToSend: this.data.user ? true :false }])).values());          
        this.filtertedUser()
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  filtertedUser(){
    if (this.data?.user) {      
      this.filteredUser = this.followings?.filter((profile) =>
        profile?.user_id
          .toLocaleLowerCase()
          .includes(this.data?.user?.user_id)
      );      
    }else{
      this.filteredUser = this.followings?.filter((profile) =>
        profile?.user_name
          .toLocaleLowerCase()
          .includes(this.searchIdx.toLocaleLowerCase())
      );
    }
  }

  selectedUserChange(event:any,user:any){
  let userId = user.user_id
  if (event.checked) {
    this.selectedUser.push(userId);
  } else {
    this.selectedUser = this.selectedUser.filter((r: string) => r !== userId);
  }
  }
  
  selectedUserRole(event: any, role: string) {
    if (role === ModeratorRoles.ALL) {
      if (event.checked) {
        this.selectedRoles = [ModeratorRoles.ALL];
      } else {
        this.selectedRoles = [];
      }
    } else {
      const allIndex = this.selectedRoles.indexOf(ModeratorRoles.ALL);
      if (allIndex > -1) {
        this.selectedRoles.splice(allIndex, 1);
      }

      if (event.checked) {
        this.selectedRoles.push(role);
      } else {
        this.selectedRoles = this.selectedRoles.filter((r: string) => r !== role);
      }
    }
  }

  isChecked(role: string): boolean {
    return this.selectedRoles.includes(role);
  }

  getRoleName(role: string): string {
    switch (role) {
      case ModeratorRoles.ALL:
        return 'All';
      case ModeratorRoles.JOB_MODERATOR:
        return 'Jobs';
      case ModeratorRoles.SERVICE_MODERATOR:
        return 'Services';
      case ModeratorRoles.POST_MODERATOR:
        return 'Content';
      case ModeratorRoles.SUBSCRIPTIONS_MODERATOR:
        return 'Subscriptions';
      default:
        return '';
    }
  }
  

  saveModerator() {

    if(!this.selectedUser.length || !this.selectedRoles.length){
      let messageText = !this.selectedUser.length ? 'users' : 'roles'
      this.snackService.showMessage(`please select the ${messageText}.`, true);
      return
    }
    const params = {
      userIds: this.selectedUser,
      roles: this.selectedRoles
    }



    this.profileService
      .addModerator(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'Success') {
          this.snackService.showMessage(res.message, false);
          this.dialog.close()
        }
      },
      (err) => {
        this.snackService.showMessage(err, true);
        // this.dialog.close()
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
