<div class="users-page flex flex-col" [class]="loading ? 'spinner' : ''">
  <div class="flex justify-between items-center order-1 md:order-2">
    <div class="search-filter-group px-4 md:px-0 flex w-full items-center">
      <div class="h-9 flex items-center rounded search-layout w-full lg:w-1/2">
        <mat-icon class="search-icon blue">search</mat-icon>
        <input
          #search
          appMentionTrigger
          [mentionConfig]="mentionService.mentionConfig"
          (itemSelected)="mentionSelect($event)"
          [mentionListTemplate]="mentionListTemplate"
          matInput
          type="text"
          [placeholder]="placeholder"
          class="input-form flex ml-0 lg:ml-2.5 w-full h-full mt-2 lg:mt-0"
          [value]="searchIdx"
          autocomplete="off"
          (keyup)="searchByTitle($event)"
        />
        <app-layout-mode
          class="flex md:hidden"
          [value]="layoutMode"
          (setValue)="setViewMode($event)"
        ></app-layout-mode>
      </div>
      <ng-template #mentionListTemplate let-item="item">
        <div class="flex items-center">
          <div class="rounded-full h-10 w-10 overflow-hidden">
            <img
              *ngIf="item.isShowImage"
              class="rounded-full h-full w-full overflow-hidden"
              onerror="this.src='assets/images/no-image.png'"
              [src]="item.profile_img_path"
              alt=""
            />
          </div>
          &nbsp;
          <span class="ml-2">{{ item.user_name }}</span>
        </div>
      </ng-template>
      <mat-divider
        class="hidden md:flex w-1 h-full"
        [vertical]="true"
      ></mat-divider>
      <!-- <div class="flex h-full md:p-4 items-center interest-width">
        <mat-select
          [value]="searchInterestValue"
          placeholder="# hashtags"
          (selectionChange)="filterByInterest($event)"
        >
          <mat-option
            *ngFor="let category of interestList"
            [value]="category.id"
          >
            {{ category.label }}
          </mat-option>
        </mat-select>
      </div>
      <mat-divider
        class="hidden md:flex w-1 h-full"
        [vertical]="true"
      ></mat-divider> -->
      <mat-radio-group
        class="hidden lg:flex search-type-group items-center justify-center h-full lg:bg-white"
        aria-label="Select an option"
        [value]="searchTypeVal"
        (change)="handleTypeChange($event.value)"
      >
        <mat-radio-button
          *ngFor="let type of SearchType.type"
          [value]="type.id"
        >
          <div class="tag" [class]="searchTypeVal == type.id ? 'blue' : ''">
            <p>
              {{ type.label }}
            </p>
          </div>
        </mat-radio-button>
      </mat-radio-group>
      <!-- <div class="flex h-12 p-2 md:hidden items-center border-normal rounded">
        <mat-select
          [value]="searchTypeVal"
          placeholder="All"
          (selectionChange)="handleTypeChange($event.value)"
        >
          <mat-option *ngFor="let type of SearchType.type" [value]="type.id">
            {{ type.label }}
          </mat-option>
        </mat-select>
      </div> -->
      <button
        class="h-full clear-wrapper"
        *ngIf="isSearch"
        mat-text-button
        (click)="reset()"
      >
        <span class="px-2 text-white"> Clear </span>
      </button>
    </div>
  </div>

  <!-- POST STORY SECTION -->
  <div
    *ngIf="stories?.length"
    class="story-container flex gap-5 justify-center order-2 md:order-1"
  >
    <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container filter-swipper common-arrow posts-swipper"
      (reachEnd)="onSlideChange($event)"
    >
      <ng-template swiperSlide>
        <div class="relative cursor-pointer">
          <img
            class="story-image"
            [src]="currenLoginUser?.avatar"
            onerror="this.src='assets/images/logo/bird-logo-no-bg.png'"
          />
          <button class="add-post absolute" (click)="postContent()">+</button>
          <p class="text-style mt-1">Your posts</p>
        </div>
      </ng-template>
      <ng-template
        swiperSlide
        *ngFor="let item of stories; trackBy: trackByFunc"
      >
        <div (click)="postDetail(item)" class="cursor-pointer">
          <img
            *ngIf="item?.content?.media_type == 'moment'"
            class="story-image image_border"
            [src]="item?.content?.url"
          />
          <video
            *ngIf="item?.content?.media_type != 'moment'"
            class="story-image image_border"
            preload="metadata"
            [src]="item?.content?.url+ '#t=0.1'"  preload="metadata"
            loop
            [muted]="'muted'"
            playsinline
            webkit-playsinline
          ></video>
          <p class="text-style mt-1">{{ item?.profile?.user_name }}</p>
        </div>
      </ng-template>
    </swiper>
  </div>
  <!-- POST STORY SECTION END -->

  <div class="md:hidden flex mx-5 py-2.5 filter-wrapper order-3">
    <swiper
      #swipperFilters
      [navigation]="true"
      [config]="config"
      class="swpier-container filter-swipper common-arrow custom-filter"
    >
      <ng-template swiperSlide *ngFor="let item of SearchType.type">
        <p
          class="filter-element"
          (click)="handleTypeChange(item.id)"
          [class]="searchTypeVal == item.id ? 'active' : ''"
        >
          {{ item.label }}
        </p>
      </ng-template>
    </swiper>
  </div>
  <!-- <form class="post-search-form-mobile w-full flex flex-col md:hidden items-center overflow-hidden p-0"
    (keydown.enter)="$event.preventDefault()">
    <div class="flex mt-4 px-3">
      <div class="search-form-wrapper h-12 p-2 flex items-center bg-white rounded">
        <input #search (itemSelected)="mentionSelect($event)" [mention]="mentionUsers" [mentionConfig]="mentionConfig"
        [mentionListTemplate]="mentionListTemplate" matInput type="text" [placeholder]="placeholder"
          class="input-form flex lg:hidden ml-0 lg:ml-2.5 w-full h-full mt-2 px-2 lg:mt-0 lg:w-1/2" [value]="searchIdx"
          autocomplete="off" (keyup)="searchByTitle($event)"/>
        <app-layout-mode [value]="layoutMode" (setValue)="setViewMode($event)"></app-layout-mode> -->
  <!-- <mat-icon class="expand-more" (click)="isShowAdvancedSearchOptions = !isShowAdvancedSearchOptions">
          {{ isShowAdvancedSearchOptions ? "expand_less" : "expand_more" }}
        </mat-icon> -->
  <!-- </div>
      <div class="flex border-normal rounded h-12 p-2 items-center">
        <mat-select [value]="searchInterestValue" placeholder="Interest" (selectionChange)="filterByInterest($event)">
          <mat-option *ngFor="let category of interestList" [value]="category.id">
            {{ category.label }}
          </mat-option>
        </mat-select>
      </div>
      <div class="flex h-12 p-2 items-center border-normal rounded">
        <mat-select [value]="searchTypeVal" placeholder="All" (selectionChange)="handleTypeChange($event.value)">
          <mat-option *ngFor="let type of SearchType.type" [value]="type.id">
            {{ type.label }}
          </mat-option>
        </mat-select>
      </div>
    </div> -->
  <!-- <div class="w-full flex" *ngIf="isShowAdvancedSearchOptions">
      <div class="grid gap-2 flex-1 px-2 py-2 bg-white" [ngClass]="isSearch ? 'grid-cols-dynimically' : 'grid-cols-2'">
        <div class="flex border-normal rounded h-12 p-2 items-center">
          <mat-select [value]="searchInterestValue" placeholder="Interest" (selectionChange)="filterByInterest($event)">
            <mat-option *ngFor="let category of interestList" [value]="category.id">
              {{ category.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="flex h-12 p-2 items-center border-normal rounded">
          <mat-select [value]="searchTypeVal" placeholder="All" (selectionChange)="handleTypeChange($event.value)">
            <mat-option *ngFor="let type of SearchType.type" [value]="type.id">
              {{ type.label }}
            </mat-option>
          </mat-select>
        </div>
          <button mat-button *ngIf="isSearch" class="clearAll-btn" (click)="reset()">
            Clear
          </button>
      </div>
    </div> -->
  <!-- </form> -->

  <div class="scroll-wrapper post-wrapper md:px-0 mt-4 order-4">
    <div
      *ngIf="searchTypeVal !== 'livestreams'"
      class="scroll"
      id="postsContainer"
      infiniteScroll
      [infiniteScrollContainer]="'#postsContainer'"
      [fromRoot]="true"
      (scrolled)="onScrollDown()"
    >
      <div
        *ngIf="searchTypeVal !== 'people' && searchTypeVal !== 'subscriptions'"
      >
        <app-masonry-panel
          *ngIf="posts.length > 0"
          [interestList]="interestList"
          [network]="network"
          [data]="posts"
          [searchTypeVal]="searchTypeVal"
          [layoutMode]="layoutMode"
          class="w-full"
        ></app-masonry-panel>
      </div>
      <div *ngIf="searchTypeVal === 'people'">
        <app-members-panel
          *ngIf="people.length > 0"
          [data]="people"
          [searchTypeVal]="searchTypeVal"
          [layoutMode]="layoutMode"
          class="w-full"
        ></app-members-panel>
      </div>

      <div *ngIf="searchTypeVal === 'subscriptions'">
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <app-individual-service-card
            *ngFor="let plan of myPlans"
            [routerLink]="'/' + 'profile' + '/' + plan?.profile?.user_name"
            [queryParams]="{ tab: 4 }"
            [service]="plan"
          >
          </app-individual-service-card>
        </div>
        <!-- <app-members-panel
          *ngIf="people.length > 0"
          [data]="people"
          [searchTypeVal]="searchTypeVal"
          [layoutMode]="layoutMode"
          class="w-full"
        ></app-members-panel> -->
      </div>
    </div>
    <div class="spinner-wrapper">
      <ngx-spinner
        bdOpacity="0.9"
        bdColor="black-dark"
        size="medium"
        color="red"
        type="ball-spin-clockwise"
        [fullScreen]="true"
        class="job-list-spinner"
      >
      </ngx-spinner>
    </div>

    <!-- <div
      *ngIf="searchTypeVal !== 'livestreams'"
      class="scroll"
      id="postsContainer"
      infiniteScroll
      [infiniteScrollContainer]="'#postsContainer'"
      [fromRoot]="true"
      (scrolled)="onScrollDown()"
    >
      <div *ngIf="searchTypeVal === 'subscriptions'">
        <app-members-panel
          *ngIf="people.length > 0"
          [data]="people"
          [searchTypeVal]="searchTypeVal"
          [layoutMode]="layoutMode"
          class="w-full"
        ></app-members-panel>
      </div>
    </div> -->
  </div>
</div>
