import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ImageService,
  ImageSnippet,
} from 'src/app/core/services/image.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @Input() serviceData?: any[];
  @Input() portfolions?: any[];
  @Output() setImgUrl: EventEmitter<any> = new EventEmitter();
  @Output() isImageUploaded: EventEmitter<any> = new EventEmitter();
  portfolio: string;
  loading: boolean;
  image!: File;

  constructor(private imageService: ImageService) {
    this.portfolio = '';
    this.loading = false;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.portfolio = changes['portfolions']?.currentValue?.url
      ? changes['portfolions']?.currentValue?.url
      : '';
    this.setImgUrl.emit(this.portfolio);
  }

  uploadImage(files: FileList) {
    if (files.length === 0) {
      return;
    }
    const file: File = files[0];
    this.loading = true;
    this.image = file;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.addEventListener('load', (event: any) => {
      this.imageService
        .imageConvertion(new ImageSnippet(event.target.result, file))
        .then((imageUri: any) => {
          this.portfolio = imageUri[0];
          this.loading = false;
          this.setImgUrl.emit(this.portfolio);
          this.isImageUploaded.emit(true);
        })
        .catch(() => {});
    });
  }
}
