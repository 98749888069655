<div class="lg:fixed flex-col side-menu pt-2">
  <div class="side-menu_container">
    <div class="flex items-center" *ngFor="let item of sidemenu">
      <a routerLink="{{ item.url }}" routerLinkActive="active-menu"
        class="flex items-center cursor-pointer py-2 pr-2 pl-3 mb-6" (click)="updateMenu(item)">
        <mat-icon *ngIf="item.name!= sideMenuConstant.events" rounded class="material-icons-round"
          [class]="item.selected ? 'blue' : 'black-light'">{{ item.icon }}
        </mat-icon>
        <svg class="filled" *ngIf="item.name==sideMenuConstant.events" xmlns="http://www.w3.org/2000/svg" height="24"
          viewBox="0 0 24 24" width="24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
        </svg>
        <button class="ml-2" [class]="item.selected ? 'blue font-bold' : 'black-light'">
          {{ item.name }}
        </button>&nbsp;
        <span class="badge" *ngIf="item.name== sideMenuConstant.messages && unreadMessagesCount"></span>
        <p *ngIf="item.url === '/dashboard/users'"
          class="flex items-center justify-center s ml-2 w-4 h-4 bg-red rounded-full text-white">
          4
        </p>
      </a>
    </div>
  </div>
</div>
<!-- <div class="fixed top-12 md:top-16 lg:top-20 mt-1.5 lg:mt-3 bg-gray mobile-mode px-4 bg-gray z-10">
  <mat-select name="side" [(ngModel)]="selectedValue" class="dashboard-nav">
    <mat-option *ngFor="let item of activeSidemenu" [value]="item.name">
      <div class="flex w-full h-full" [class]="!item.active ? 'disabled-menu-wrapper' : ''">
        <a [routerLink]="[item.url]" routerLinkActive="router-link-active" class="w-full flex items-center"
          [class]="!item.active  ? 'disabled-menu' : ''">
          {{ item.name }}
        </a>
        <span class="badge" *ngIf="item.name==sideMenuConstant.messages && unreadMessagesCount"></span>
      </div>
    </mat-option>
  </mat-select>
</div> -->