import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnInit {
  @Input() data?: any;
  name?: string;
  defaultImage = '../../../../../../../assets/images/banner_image.png';
  constructor() {
    this.name = this.data?.user_name?.replace('_', ' ');
  }
  ngOnInit(): void { }
}
