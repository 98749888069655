import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dsn',
  templateUrl: './dsn.component.html',
  styleUrls: ['./dsn.component.scss']
})
export class DsnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    document.body.appendChild(script);
  }

}
