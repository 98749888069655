import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { OperationHours, shareTypeConst } from 'src/app/const/appConst';
import { SwiperOptions } from 'swiper';
import { ContactDetailsModalComponent } from 'src/app/modules/users/pages/profile/modals/contact-details-modal/contact-details-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';

@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss']
})
export class ContentCardComponent implements OnInit {

  @Input() data?: any;
  @Input() contentType?: any;
  @Input() followings?: any[] = [];
  @Input() currentLoggedInUserId: any;
  @Output() followMember: EventEmitter<any> = new EventEmitter();
  @Output() unFollowMember: EventEmitter<any> = new EventEmitter();
  following:any = [];
  avatar: string = '/assets/images/no-image.png';

  config: SwiperOptions = {
    spaceBetween: 10,
    pagination: false,
    slidesPerView: 3,
    slidesPerGroup: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024:{
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1,
      }
    }
  };
  categoryConfig: SwiperOptions = {
    spaceBetween: 10,
    pagination: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      1024:{
        slidesPerView: 6,
        slidesPerGroup: 1,
      },
      768: {
        slidesPerView: 4,
        slidesPerGroup: 1,
      }
    }
  };

  filterRecords: any;
  constructor(
  private router: Router,
  public dialog: MatDialog,
  private clipboard: Clipboard,
  private commonService: CommonService,
  private authService: AuthService,
  private snackService: SnackBarService,
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.filterRecords = this.data.filter((x: any) => {
        return x?.visibility.includes(this.contentType)
      })
    }}


  showProfile() {
    this.router.navigate(['/profile' + '/']);
  }

  getLocation(location: any) {
    return (
      location?.city_name +
      (location?.state_name ? `- ${location?.state_name} ` : '') +
      (location?.country_code ? `- ${location?.country_code} ` : '')
    );
  }

  copy() {
    this.clipboard.copy(
      this.commonService.location().origin +
        '/profile/' +
        this.data?.profile.user_name
    );
    this.snackService.showMessage(`Link Copied`, false);
  }

  share() {
    if (this.authService.loggedIn()) {
      this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px': '100%',
        maxWidth:window.innerWidth > 600 ? '80vw': '100vw',
        data: {
          link:
            this.commonService.location().origin +
            '/profile/' +
            this.data?.profile.user_name,
          isProfile: true,
          shareType: shareTypeConst.profile,
        },
      });
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
    }
  }

  readMessage() {
    const { email, name, profile_image, user_name, user_type, _id } = this.data?.profile;
    if (_id != this.currentLoggedInUserId) {
      const userInfo = {
        email,
        name,
        profile_img_path: profile_image,
        user_name,
        user_type,
        _id,
      };
      localStorage.setItem('userId', JSON.stringify(userInfo));
    }
    this.router.navigate(
      ['/' + routesConst.dashboard + '/' + routesConst.messages],
      {
        queryParams: {
          tab: 2,
        },
      }
    );
  }

  contactDetails(profile:any) {
    let operationHours: any[] = OperationHours;
    profile?.operationHours.map((item: any) => {
      const idx = operationHours.findIndex(
        (oh: any) => oh.day_name === item.day_name
      );
      delete item.created_at;
      delete item.profile_id;
      delete item.__v;
      operationHours[idx] = {
        ...operationHours[idx],
        ...item,
      };
    });
      this.dialog.open(ContactDetailsModalComponent, {
        width: '400px',
        panelClass: 'custom-dialog-container',
        data: { user: {profile}, operationHours: operationHours },
      });
    }
}
