export class config {
  public static environmentMode(hostname: string) {
    var environment: string;
    var data = {
      endPoint: 'https://api-prod.jobpros.io',
      twitch_url: 'jobpros.io',
    };
    environment = hostname;
    switch (environment) {
      case 'localhost':
        data = {
          // endPoint: 'http://localhost:9000',
          // endPoint:'https://api-prod.jobpros.io',
          endPoint: 'https://api-dev.jobpros.io',
          twitch_url: 'web-dev.jobpros.io',
        };
        break;
      case 'www.jobpros.io':
        data = {
          endPoint: 'https://api-prod.jobpros.io',
          twitch_url: 'jobpros.io',
        };
        break;
      case 'jobpros.io':
        data = {
          endPoint: 'https://api-prod.jobpros.io',
          twitch_url: 'jobpros.io',
        };
        break;
      case 'web-dev.jobpros.io':
        data = {
          endPoint: 'https://api-dev.jobpros.io',
          twitch_url: 'web-dev.jobpros.io',
        };
        break;
      case 'web-qa.jobpros.io':
        data = {
          endPoint: 'https://api-qa.jobpros.io',
          twitch_url: 'web-qa.jobpros.io',
        };
        break;
      default:
        data = {
          endPoint: 'https://api-prod.jobpros.io',
          twitch_url: 'jobpros.io',
        };
    }
    return data;
  }
}
