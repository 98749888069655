import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Plan } from 'aws-sdk/clients/ssmcontacts';
import { routesConst } from 'src/app/core/const/routers';

@Component({
  selector: 'app-network-preferences',
  templateUrl: './network-preferences.component.html',
  styleUrls: ['./network-preferences.component.scss']
})
export class NetworkPreferencesComponent implements OnInit {
  plans?: Plan[];
  user: any;

  constructor(public dialog: MatDialog, private router: Router) { }

  ngOnInit(): void { }

  createNewSubscriptionPackageModal() {
    this.router.navigate(['/' + routesConst.subscribers]);
  }

}
