import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
})
export class NetworkComponent implements OnInit {
  private unsubscribe$ = new Subject();
  selected = new FormControl(0);
  followers: any
  followings: any
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5,
  };

  constructor(private authService: AuthService, private shardService: SharedService, private _route: ActivatedRoute, private _router: Router) { }

  ngOnInit(): void {
    this._route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      if (params.tab) this.selected.setValue(params.tab * 1);
    });

    if (this.authService.loggedIn()) {
      this.getFollowersAndFollowings();
    }
  }


  getFollowersAndFollowings() {
    this.shardService.followersAndFollowings$.pipe(takeUntil(this.unsubscribe$)).subscribe(network => {
      if (network?.followings || network?.followers) {
        this.followers = network.followers
        this.followings = network.followings
      } else {
        this.shardService.getFollowersAndFollowings();
      }
    });
  }

  createCampaign() {
    this._router.navigate(['/' + routesConst.campaign]);
  }

  handleTabChange(tab: any) {
    this._router.navigate([], { queryParams: { tab }, queryParamsHandling: 'merge' });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
