<div class="sidenav-list pt-6">
  <!-- <div *ngIf="!isLoggedIn" class="flex items-center py-2 ml-6">
    <mat-icon class="text-white">login</mat-icon>
    <a (click)="close()" data-toggle="collapse" data-target="#navbarTogglerDemo01" (click)="openSignUp('SignUp')"
      routerLinkActive="active-link" class="ml-2 cursor-pointer">
      <span class="text-white uppercase">Join</span>
    </a>
  </div> -->
  <div class="block">
    <mat-horizontal-stepper linear #stepper>
      <mat-step>
        <!-- <div
        [routerLink]="'/' + routesConst.features" (click)="close()"
          [class]="selectedRoute.includes(routesConst.features) ?'selectedItem':''"
          class="cursor-pointer flex items-center py-2 hov"> -->
          <!-- <mat-icon class="text-white fill">explore</mat-icon> -->
          <!-- <mat-icon class="text-white fill">add_link</mat-icon>
          <a  class="textHoverColor font-semibold text-white ml-2">Features</a> -->
          <!-- <mat-icon class="text-white fill ml-auto" (click)="goToNext(stepper, 'explore')">chevron_right</mat-icon> -->
        <!-- </div> -->
        <!-- <div
        [routerLink]="'/' + routesConst.explore" (click)="close()"
          [class]="selectedRoute.includes(routesConst.explore) || selectedRoute.includes(routesConst.community) || selectedRoute.includes(routesConst.jobs) || selectedRoute.includes(routesConst.talents)?'selectedItem':''"
          class="cursor-pointer flex items-center py-2 hov"> -->
          <!-- <mat-icon class="text-white fill">explore</mat-icon> -->
          <!-- <mat-icon class="text-white fill">search</mat-icon>
          <a  class="textHoverColor font-semibold text-white ml-2">Explore</a> -->
          <!-- <mat-icon class="text-white fill ml-auto" (click)="goToNext(stepper, 'explore')">chevron_right</mat-icon> -->
        <!-- </div> -->
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.account" (click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">person</mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Account</a>
        </div>
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.services" (click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">design_services </mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Services</a>
        </div>
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.subscription" (click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">campaign</mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Subscriptions</a>
        </div>
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.jobs" (click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">work </mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Jobs</a>
        </div>
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.messages" (click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">mark_chat_unread  </mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2"> Messages </a>
        </div>
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.referrals" (click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">group_work</mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Referrals</a>
        </div>
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.payments" (click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">account_balance_wallet </mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Payments</a>
        </div>
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.network"(click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">group</mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2"> Network </a>
        </div>
        <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.dashboard + '/' + routesConst.stats" (click)="close()"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">equalizer </mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">  Insights  </a>
        </div>







        <!-- <div *ngIf="authService.loggedIn()"
        [routerLink]="'/' + routesConst.profile+ '/' + username" (click)="close()"
          [class]="selectedRoute.includes('/' + routesConst.profile+ '/' + username) ?'selectedItem':''"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">account_circle</mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Profile</a> -->
          <!-- <mat-icon class="text-white fill ml-auto" (click)="goToNext(stepper, 'create')">chevron_right</mat-icon> -->
        <!-- </div> -->
        <!-- <div
        [routerLink]="'/' + routesConst.create" (click)="close()"
          [class]="selectedRoute.includes(routesConst.create) || selectedRoute.includes(routesConst.services) || selectedRoute.includes(routesConst.content) || selectedRoute.includes(routesConst.subscription) || selectedRoute.includes(routesConst.campaign) ?'selectedItem':''"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">add_box</mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Create</a> -->
          <!-- <mat-icon class="text-white fill ml-auto" (click)="goToNext(stepper, 'create')">chevron_right</mat-icon> -->
        <!-- </div>
        <div
        [routerLink]="'/' + routesConst.earn" (click)="close()"
          [class]="selectedRoute.includes(routesConst.earn) || selectedRoute.includes(routesConst.howItWorks) || selectedRoute.includes(routesConst.referrals)?'selectedItem':''"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">paid</mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">Earn</a> -->
          <!-- <mat-icon class="text-white fill ml-auto" (click)="goToNext(stepper, 'earn')">chevron_right</mat-icon> -->
        <!-- </div>
        <div
        [routerLink]="'/' + routesConst.aboutus" (click)="close()"
          [class]="selectedRoute.includes(routesConst.instantPay) || selectedRoute.includes(routesConst.aboutus)?'selectedItem':''"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill">info</mat-icon>
          <a class="textHoverColor font-semibold text-black ml-2">About</a> -->
          <!-- <mat-icon class="text-white fill ml-auto" (click)="goToNext(stepper, 'about')">chevron_right</mat-icon> -->
        <!-- </div>
        <div
        [routerLink]="'/' + routesConst.pricing" (click)="close()"
          [class]="selectedRoute.includes(routesConst.pricing) || selectedRoute.includes(routesConst.addons) || selectedRoute.includes(routesConst.plans)?'selectedItem':''"
          class="cursor-pointer flex items-center py-2 hov">
          <mat-icon class="text-black fill money-icon">money</mat-icon>
          <a class="textHoverColor font-semibold text-white ml-2">Pricing</a> -->
          <!-- <mat-icon class="text-white fill ml-auto" (click)="goToNext(stepper, 'pricing')">chevron_right</mat-icon> -->
        <!-- </div> -->
      </mat-step>
      <mat-step>
        <div class="flex items-center py-2 -ml-2" (click)="stepper.previous()" id="createMenu">
          <mat-icon class="text-white">chevron_left</mat-icon>
          <span class="uppercase cursor-pointer text-white ml-2" *ngIf="subMode === 'earn'">
            Earn
          </span>
          <span class="uppercase cursor-pointer text-white ml-2" *ngIf="subMode !== 'earn'">
            {{subMode}}
          </span>
        </div>
        <div *ngIf="subMode === 'explore'" class="">
          <div class="flex items-center py-2" [class]="selectedRoute.includes(routesConst.community)?'selectedItem':''">
            <mat-icon class="text-white fill">groups</mat-icon>
            <a routerLink="{{ routesConst.community }}" (click)="close(); stepper.previous()"><span
                class="text-white ml-2 fill">Creators</span></a>
          </div>
          <div class="flex items-center py-2" [class]="selectedRoute.includes(routesConst.jobs)?'selectedItem':''">
            <mat-icon class="text-white fill">work</mat-icon>
            <a routerLink="{{ routesConst.jobs }}" (click)="close(); stepper.previous()"><span
                class="text-white ml-2 fill">Jobs</span></a>
          </div>
          <div class="flex items-center py-2" [class]="selectedRoute.includes(routesConst.talents)?'selectedItem':''">
            <svg class="white-icon fill" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
              height="24" viewBox="0 0 24 24" width="24">
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g>
                  <circle cx="10" cy="8" r="4" />
                  <path d="M10.35,14.01C7.62,13.91,2,15.27,2,18v2h9.54C9.07,17.24,10.31,14.11,10.35,14.01z" />
                  <path
                    d="M19.43,18.02C19.79,17.43,20,16.74,20,16c0-2.21-1.79-4-4-4s-4,1.79-4,4c0,2.21,1.79,4,4,4c0.74,0,1.43-0.22,2.02-0.57 L20.59,22L22,20.59L19.43,18.02z M16,18c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C18,17.1,17.1,18,16,18z" />
                </g>
              </g>
            </svg> <a routerLink="{{ routesConst.talents }}" (click)="close(); stepper.previous()"><span
                class="text-white ml-2 fill">Experts</span></a>
          </div>
        </div>
        <div *ngIf="subMode === 'create'" class="scroll-wrrapper">
          <div *ngIf="userType === userTypeConstant.company" class="flex cursor-pointer items-center py-2"
            (click)="openPostJobModal(); close(); stepper.previous()">
            <mat-icon class="text-white">work</mat-icon>
            <span class="text-white ml-2">Job Posting</span>
          </div>
          <div [class]="selectedRoute.includes(routesConst.content)?'selectedItem':''"
            class="cursor-pointer flex items-center py-2" (click)="postContent(); stepper.previous()">
            <mat-icon class="text-white fill">panorama</mat-icon>
            <span class="text-white ml-2 fill">Content</span>
          </div>
          <a [class]="selectedRoute.includes(routesConst.services)?'selectedItem':''" class="flex items-center py-2"
            routerLink="{{
              routesConst.dashboard + '/' + routesConst.services
            }}" (click)="close(); stepper.previous()">
            <mat-icon class="text-white fill">design_services</mat-icon>
            <span class="text-white ml-2 fill">Services</span>
          </a>
          <a [class]="selectedRoute.includes(routesConst.campaign)?'selectedItem':''"
            class="cursor-pointer flex items-center py-2" (click)="close(); stepper.previous(); createCampaign()">
            <mat-icon class="text-white fill">campaign</mat-icon>
            <span class="text-white ml-2 fill">Campaigns</span>
          </a>
          <a [class]="selectedRoute.includes(routesConst.subscription)?'selectedItem':''" class="flex items-center py-2"
            [routerLink]="
              routesConst.dashboard + '/' + routesConst.subscription
            " (click)="close(); stepper.previous()">
            <mat-icon class="text-white fill">loyalty</mat-icon>
            <span class="text-white ml-2 fill">Paid Content</span>
          </a>
          <!-- <a [class]="selectedRoute.includes(routesConst.events)?'selectedItem':''" class="flex items-center py-2"
            [routerLink]="routesConst.dashboard + '/' + routesConst.events" (click)="close(); stepper.previous()">
            <svg class="white-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
            </svg>
            <span class="text-white ml-2 fill">Events</span>
          </a> -->
        </div>
        <div *ngIf="subMode === 'earn'" class="">
          <!-- <a [class]="selectedRoute.includes(routesConst.plans)?'selectedItem':''"
            (click)="navigateTo('/' + routesConst.plans) ; close()" class="cursor-pointer flex items-center py-2">
            <mat-icon class=" text-white fill">next_plan</mat-icon>
            <span class="text-white ml-2 fill">Compare Plans</span>
          </a> -->
          <a [class]="selectedRoute.includes(routesConst.howItWorks)?'selectedItem':''"
            (click)="navigateTo('/' + routesConst.howItWorks) ; close()" class="cursor-pointer flex items-center py-2">
            <mat-icon class=" text-white fill">add_link</mat-icon>
            <span class="text-white ml-2 fill">How It Works</span>
          </a>
          <a [class]="selectedRoute.includes(routesConst.referrals)?'selectedItem':''" class="flex items-center py-2"
            routerLink="{{
              routesConst.dashboard + '/' + routesConst.referrals
            }}" (click)="close(); stepper.previous()">
            <mat-icon class=" text-white fill">settings_accessibility</mat-icon>
            <span class="text-white ml-2 fill">Member Referrals</span>
          </a>
        </div>
        <div *ngIf="subMode === 'about'" class="">

          <a [class]="selectedRoute == '/'+routesConst.features?'selectedItem':''"
            (click)="navigateTo('/' + routesConst.features) ; close()" class="cursor-pointer flex items-center py-2">
            <mat-icon class=" text-white fill">add_link</mat-icon>
            <span class="text-white ml-2 fill">Features</span>
          </a>
          <a [class]="selectedRoute == '/'+routesConst.instantPay?'selectedItem':''"
            (click)="navigateTo('/' + routesConst.instantPay) ; close()" class="cursor-pointer flex items-center py-2">
            <mat-icon class="text-white fill"> assistant</mat-icon>
            <span class="text-white ml-2 fill">Instant Pay</span>
          </a>
          <a [class]="selectedRoute.includes(routesConst.aboutus)?'selectedItem':''"
            (click)="navigateTo('/' + routesConst.aboutus) ; close()" class="cursor-pointer flex items-center py-2">
            <mat-icon class="text-white fill">videocam</mat-icon>
            <span class="text-white ml-2 fill">About Us</span>
          </a>
        </div>
        <div *ngIf="subMode === 'pricing'" class="">
          <a [class]="selectedRoute.includes(routesConst.planPricing)?'selectedItem':''"
            (click)="navigateTo('/' + routesConst.planPricing) ; close()" class="cursor-pointer flex items-center py-2">
            <mat-icon class=" text-white fill">add_link</mat-icon>
            <span class="text-white ml-2 fill">Membership Plans</span>
          </a>
          <a [class]="selectedRoute.includes(routesConst.addonPricing)?'selectedItem':''"
            (click)="navigateTo('/' + routesConst.addonPricing) ; close()" class="cursor-pointer flex items-center py-2">
            <mat-icon class="text-white fill"> assistant</mat-icon>
            <span class="text-white ml-2 fill">Member Add Ons</span>
          </a>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>