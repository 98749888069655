import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { userTypeConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-usernameselection',
  templateUrl: './usernameselection.component.html',
  styleUrls: ['./usernameselection.component.scss'],
})
export class UsernameselectionComponent implements OnInit {
  userTypeConstant = userTypeConstant;
  private unsubscribe$ = new Subject();
  usernameUpdate = new Subject<string>();
  user_name!: string;
  loading: boolean = false;
  usernameValidationError = false;
  validationChecking = true;
  isSaving = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private snack: SnackBarService,
    private profileService: ProfileService,
    private talentService: TalentService,
    public dialogRef: MatDialogRef<UsernameselectionComponent>
  ) {
    this.usernameUpdate
      .pipe(
        debounceTime(1500),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => {
        this.validationChecking = true;
        this.authService.validateUsername(value).then((isValid) => {
          this.usernameValidationError = !isValid;
          this.validationChecking = false;
        });
      });
  }

  ngOnInit(): void {}

  validate() {
    (this.usernameValidationError = false), (this.validationChecking = true);
  }

  update() {
    this.loading = true;

    this.authService.updateUserName(this.user_name).subscribe(
      () => {
        this.authService.setToken();
        this.checkIfLoggedIn();
      },
      () => {
        this.snack.showMessage(
          `There is an Error in Updating UserName. Please try again later.`,
          true
        );
      }
    );
  }

  private checkIfLoggedIn(): void {
    const isLoggedIn = this.authService.loggedIn();
    if (isLoggedIn) {
      // this.loading = false;
      // this.dialogRef.close();
      this.launchStripeOnboarding();
      // this.router.navigate(['/dashboard']);
    }
  }

  hasWhiteSpace(user_name: string) {
    return user_name?.includes(' ');
  }

  launchStripeOnboarding(){
    const origin = window.location.origin
    const redirect_url = origin + '/' + routesConst.planPricing
    const refresh_url = origin + '/' + routesConst.dashboard
    this.talentService.getStripeUser(redirect_url, refresh_url).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.loading = false;
      if (res.status == 'on_board_required') {
        if (res?.accountLink?.url) {
          window.location.href = res?.accountLink?.url;
          localStorage.setItem('isNewUser', 'true');
          this.snack.showMessage(
            res.message,
            false
          );
        }
      }
    },
    (error: any) => {
      this.loading = false;
      this.snack.showMessage(
        `Error during Stripe onboarding`,
        true
      );
      this.dialogRef.close();     
  }
  );
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
