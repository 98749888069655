<div class="payment-checkout-modal -m-6">
  <div class="w-full flex items-center sticky -top-6 justify-center payment-checkout-modal_header">
    <img class="w-10 absolute top-2 left-3" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_white.png " alt="">

    <p class="montserrat-bold text-white w-fit h-fit">Checkout</p>
    <mat-icon class="cursor-pointer text-white rounded-full z-30 absolute right-2 inset-y-auto" mat-dialog-close>close
    </mat-icon>
  </div>
  <!-- <div class="w-full">
    <mat-divider class="w-full"></mat-divider>
  </div> -->
  <div class="payment-checkout-modal_content flex flex-col justify-between">
    <div class="w-full content-wrapper mb-2 lg:pr-2.5 mx-auto">
      <div class="border-normal rounded py-1 px-4 mt-2">
        <h3 class="montserrat-bold text-lg text-sixe">{{ data?.avialbleProduct.name }}</h3>
        <mat-radio-group *ngIf="!data?.avialbleProduct.viewMode" aria-label="Select an option" class="grid" [class]="!data?.avialbleProduct.viewMode??'gap-4 mt-4'">
          <mat-radio-button class="mb-2" [checked]=" data?.avialbleProduct.viewMode? item?.recurring?.interval == data?.avialbleProduct.interval:  item?.recurring?.interval == intervalConstant?.month" [value]="item.unit_amount"
            *ngFor="let item of data?.avialbleProduct.prices" (change)="selectPaymentInterval(item)">
            <div class="flex justify-between flex-row items-start lg:items-center">
              <p class="montserrat-bold text-lg text-sizee">
                {{
                item?.recurring?.interval == intervalConstant.year
                ? "Annual Plan"
                : item?.recurring?.interval == intervalConstant.month
                ? "Monthly Plan"
                : ""
                }}
              </p>
              <div
                class="flex justify-between items-center lg:flex-col lg:items-end text-left lg:text-right lg:ml-auto">
                <p class="text-sizee text-lg">
                  <span class="montserrat-bold">${{
                    item?.recurring?.interval == intervalConstant.year
                    ? (
                    (item.unit_amount / 100 - item.nickname) /
                    12
                    ).toFixed(2)
                    : (item.unit_amount / 100 - item.nickname).toFixed(2)
                    }}/</span>mo
                </p>
                <p class="s montserrat-medium window-mode">
                  ${{ (item.unit_amount / 100 - item.nickname).toFixed(2) }}
                  {{
                  item?.recurring?.interval == intervalConstant.year
                  ? "billed once a year"
                  : item?.recurring?.interval == intervalConstant.month
                  ? "billed every month"
                  : ""
                  }}
                </p>
              </div>
            </div>
          </mat-radio-button>
        </mat-radio-group>


        <mat-radio-group *ngIf="data?.avialbleProduct?.viewMode" aria-label="Select an option" class="grid" [class]="!data?.avialbleProduct.viewMode??'gap-4 mt-4'">
          <mat-radio-button class="mb-2" checked
            *ngFor="let item of data?.currentSubscription?.list?.data" (change)="selectPaymentInterval(item)">
            <div class="flex justify-between flex-row items-start lg:items-center">
              <p class="montserrat-bold text-lg text-sizee">
                {{
                item?.plan?.interval == intervalConstant.year
                ? "Annual Plan"
                : item?.plan?.interval == intervalConstant.month
                ? "Monthly Plan"
                : ""
                }}
              </p>
              <div
                class="flex justify-between items-center lg:flex-col lg:items-end text-left lg:text-right lg:ml-auto">
                <p class="text-sizee text-lg">
                  <span class="montserrat-bold">${{
                    item?.plan?.interval == intervalConstant.year
                    ? (
                    (item?.plan.amount / 100 - item.plan.nickname) /
                    12
                    ).toFixed(2)
                    : (item.plan.amount / 100 - item.plan.nickname).toFixed(2)
                    }}/</span>mo
                </p>
                <p class="s montserrat-medium window-mode">
                  ${{ (item.plan.amount / 100 - item.plan.nickname).toFixed(2) }}
                  {{
                  item?.plan.interval == intervalConstant.year
                  ? "billed once a year"
                  : item?.plan.interval == intervalConstant.month
                  ? "billed every month"
                  : ""
                  }}
                </p>
              </div>
            </div>
          </mat-radio-button>
        </mat-radio-group>
        <p>Description</p>
        <div class="flex items-center cursor-pointer mt-1" *ngFor="let item of data?.avialbleProduct.features">
          <img class="check-box" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros+CHECK+MARK.png"
            width="24" />
          <p class="ml-2 black">{{ item }}</p>
        </div>
      </div>
      <div *ngIf="!data?.avialbleProduct.viewMode" class="border-normal rounded px-4 mt-2">
        <div>
          <div class="flex items-center justify-between w-full mt-5 pt-0.5">
            <p class="s montserrat-bold">Subtotal</p>
            <p>${{ subTotal }}</p>
          </div>
          <div class="flex items-center justify-between w-full my-4">
            <p class="s montserrat-bold">Total Bill Cycle Charged</p>
            <p class="s montserrat-bold">${{ getTotal() }}</p>
          </div>
          <p class="s mb-6">
            All prices are in USD and all sales are final. You will be charged
            <b> ${{ subTotal }} </b> immediately. You will be charged based on
            billing cycle selected while the membership is active. Cancel any
            time.
          </p>
          <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOption" (ngModelChange)="paymentChange()">
            <mat-radio-button [disabled]="!defaultPaymentExist" [value]="'default'">{{defaultPaymentExist ? 'Default Payment Method' :'Default Payment Method not Exist' }}</mat-radio-button>
            <mat-radio-button [value]="'new'">New Payment Method</mat-radio-button>
          </mat-radio-group>

          <p *ngIf="selectedOption==='new'" class="m montserrat-bold">Enter Card Details</p>
          <br />
          <div class="spinner-wrapper" *ngIf="selectedOption==='new'">
            <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
              [fullScreen]="true">
            </ngx-spinner>
          </div>
          <ngx-stripe-card *ngIf="selectedOption==='new'" (change)="onChange($event)" [options]="cardOptions"
            [elementsOptions]="elementsOptions">
          </ngx-stripe-card><br />
        </div>
      </div>
      <div class="flex justify-center items-center mt-2 mb-4">
        <mat-icon class="green mr-2" style="font-size: 14px; width: 10px; height: 14px">lock</mat-icon>
        <p class="s gray-dark">All your payments are encrypted</p>
      </div>
      <!-- <div class="w-full my-4">
        <mat-divider class="w-full"></mat-divider>
      </div> -->
    </div>
  </div>
  <div class="grid gap-2 mb-4 bg-white sticky -bottom-6 px-3.5 md:px-5 border-2 py-2">
    <button mat-button *ngIf="!data?.avialbleProduct.viewMode" [class]="
        !stripeCardValid && subTotal
        ? 'disabled montserrat-medium font-txt'
        : 'montserrat-medium font-txt button black'
        " [disabled]="!stripeCardValid && subTotal" (click)="subscribeAndCheckout()">
      Subscribe
    </button>
    <button
    *ngIf="data?.avialbleProduct.viewMode && data?.avialbleProduct.name.toLowerCase() != planTypeConstant.consumer.toLowerCase() && data?.avialbleProduct.name.toLowerCase()!=planTypeConstant.recruiter.toLowerCase()"
    class="montserrat-medium button normal" (click)="unSubscribe()">
    Unsubscribe
  </button>
    <p class="montserrat-medium blue text-center cursor-pointer pt-1" mat-dialog-close>Cancel</p>
  </div>

</div>
