<div class="admin-setting section-wrapper bg-white px-4 pb-4 pt-0 lg:p-10 ">
  <p class="montserrat-bold text-black montserrat-bold">Admin</p>
  <p class="s black-light mt-2">
    See your admins access
  </p>
  <div class="mt-2">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div class="flex justify-end items-center mt-4">
    <!-- <mat-select placeholder="Select option">
      <mat-option *ngFor="let filterBy of filterOptions" [value]="filterBy.label">
        {{ filterBy.label }}
      </mat-option>
    </mat-select> -->
    <button mat-button class="button black outlined" (click)="showModal()">+ Add</button>
  </div>
  <div class="grid gap-0.5 bg-gray-300 mt-4">
    <div *ngFor="let user of users" class="flex gap-1 justify-between items-center py-3 bg-white">
      <div class="md:min-w-[200px]">
        <p class="text-base montserrat-bold">{{user?.userDetails?.name}}</p>
        <p class="text-sm mt-1">{{user?.userDetails?.email}}</p>
      </div>
      <div class="w-full flex flex-col items-end">
        <div class="w-full flex justify-end md:justify-center md:pr-[200px]">
          <div  class="flex gap-2">
            <div *ngFor="let role of user?.roles" class="ml-auto flex gap-2 capitalize text-sm text-gray-500 font-extrabold">
  
              <mat-icon *ngIf="role === 'ALL' || role === 'POST_MODERATOR'" class="blue">panorama</mat-icon>
              <mat-icon *ngIf="role === 'ALL' || role === 'JOB_MODERATOR'" class="blue">work</mat-icon>
              <mat-icon *ngIf="role === 'ALL' || role === 'SUBSCRIPTIONS_MODERATOR'" class="blue">campaign</mat-icon>
              <mat-icon *ngIf="role === 'ALL' || role === 'SERVICE_MODERATOR'" class="blue">design_services</mat-icon>
            </div>
          </div>
        </div>
        <div class="flex gap-2">
          <div class="mt-2 p-1 rounded-[4px] cursor-pointer flex items-center" [ngClass]="user.status == ModeratorStatusConst.ACTIVE ? 'font-bold blue uppercase border-lg rounded-lg px-4 text-sm py-1 bg-gray-100 mx-auto w-fit':'font-bold text-red-600 uppercase border-lg rounded-lg px-4 text-sm py-1 bg-gray-100 mx-auto w-fit'" (click)="showConfirmModal (user, user.status == ModeratorStatusConst.ACTIVE)">
            <mat-icon [class]="user.status == ModeratorStatusConst.ACTIVE ? 'active-check-box' : 'inactive-check-box'">check</mat-icon>
        </div>
        <div class="flex items-end">
          <mat-icon class="text-[#2887ed] cursor-pointer" (click)="showModal(user)">edit</mat-icon>
        </div>
      </div>
      </div>
  </div>
</div>