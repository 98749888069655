import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
@Component({
  selector: 'app-package-unit-update',
  templateUrl: './package-unit-update.component.html',
  styleUrls: ['./package-unit-update.component.scss']
})
export class PackageUnitUpdateComponent implements OnInit {
  @ViewChild('feature') feature: any;
  @Input() order: number;
  @Input() pricingPackages: any;
  @Input() checkPriceValidation: any;
  @Output() setPackageForm: EventEmitter<any> = new EventEmitter();
  nth: number;
  packageForm: FormGroup;
  serviceType: any[];
  packageFeature: string[];
  allPayRange: any;
  unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService
  ) {
    this.order = 0;
    this.nth = 1;
    this.packageForm = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(1024)]],
      price: [null, [Validators.required]],
      billing_type: ['', [Validators.required]],
      feature: ['', [Validators.maxLength(1024)]]
    });
    this.packageFeature = [];
    this.getPayRangeList();
    this.serviceType = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pricingPackages']) {
      this.packageForm.controls['description'].setValue(
        changes['pricingPackages'].currentValue.description
      );
      this.packageForm.controls['price'].setValue(changes['pricingPackages'].currentValue.price);
      this.packageForm.controls['billing_type'].setValue(
        changes['pricingPackages'].currentValue.billing_type
      );
      this.packageFeature = changes['pricingPackages'].currentValue.features;
    }
  }

  ngOnInit(): void {
    this.getBillingType();
    this.nth = this.order + 1;
    this.packageForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        const { description, price, billing_type } = res;
        const form = {
          description,
          price,
          billing_type: billing_type || '',
          offers: this.packageFeature.length > 0 ? this.getOffers() : ''
        };
        this.setPackageForm.emit(form);
      });
  }

  getBillingType() {
    this.sharedService.billingTypeList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(billingType => {
        if (billingType.length) {
          this.serviceType = billingType;
        } else {
          this.sharedService.getBillingTypes();
        }
      });
  }

  public errorHandling = (control: string, error: string) => {
    return this.packageForm.controls[control].hasError(error);
  };

  getOffers() {
    return this.packageFeature.reduce(
      (memo, feature, idx) => (memo += (idx !== 0 ? ',' : '') + feature)
    );
  }

  getPayRangeList() {
    this.sharedService.payRangeList$.pipe(takeUntil(this.unsubscribe$)).subscribe(payrangelist => {
      if (payrangelist.length) {
        this.allPayRange = payrangelist;
      } else {
        this.sharedService.getPayRangeList();
      }
    });
  }

  addFeature() {
    const feature = this.packageForm.get('feature')?.value;
    if (feature) {
      if (this.packageFeature && this.packageFeature.length > 0) this.packageFeature.push(feature);
      else this.packageFeature = [feature];
    }
    this.packageForm.get('feature')?.reset();
    this.feature.nativeElement.focus();
  }

  removeFeature(feature: string) {
    this.packageFeature = this.packageFeature.filter(f => f !== feature);
    this.packageForm.updateValueAndValidity();
  }

  onFeatureFormChange(e: any) {
    if (e.key === 'Enter' && this.packageForm.get('feature')?.value?.trim()?.length)
      this.addFeature();
  }

  submit(e: any) {
    e.preventDefault();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
