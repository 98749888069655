import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { JobApplyModalComponent } from 'src/app/modules/jobs/modals/job-apply-modal/job-apply-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    JobApplyModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  exports: [],
  providers: [
    DatePipe,
  ],
})
export class JobsModule { }
