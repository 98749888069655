import { NgModule } from '@angular/core';
import { environment } from 'src/environments/env';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { UsersComponent } from 'src/app/modules/users/pages/users/users.component';
// import { PostComponent } from 'src/app/modules/users/pages/post/post.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LivestreamComponent } from 'src/app/modules/users/pages/livestream/livestream.component';
import { HashtagComponent } from 'src/app/modules/users/pages/hashtag/hashtag.component';
import { RouterModule } from '@angular/router';
import { ProfileScheduleComponent } from 'src/app/modules/users/pages/profile/components/profile-schedule/profile-schedule.component';
import { UpdateEducationModalComponent } from 'src/app/modules/users/pages/profile/modals/update-education-modal/update-education-modal.component';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { SubscribeModalComponent } from 'src/app/modules/users/modals/subscribe-modal/subscribe-modal.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from 'src/app/shared/shared.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { UserStreamCardComponent } from './components/user-stream-card/user-stream-card.component';
import { StripeCardModalComponent } from 'src/app/modules/users/modals/stripe-card-modal/stripe-card-modal.component';
import { PlanDetailComponent } from './pages/profile/components/plan-detail/plan-detail.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ContactDetailsModalComponent } from './pages/profile/modals/contact-details-modal/contact-details-modal.component';
import { MentionModule } from 'angular-mentions';
// import { InViewLoggerDirective } from 'src/app/shared/directives/in-view-logger.directive.';

@NgModule({
  declarations: [
    // InViewLoggerDirective,
    // UsersComponent,
    // PostComponent,
    ShareModalComponent,
    LivestreamComponent,
    HashtagComponent,
    ProfileScheduleComponent,
    UpdateEducationModalComponent,
    SubscribeModalComponent,
    UserStreamCardComponent,
    StripeCardModalComponent,
    PlanDetailComponent,
    ContactDetailsModalComponent
  ],
  imports: [
    MentionModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SharedModule,
    IvyCarouselModule,
    NgxStripeModule.forRoot(environment.stripePublishKey)
  ]
})
export class UsersModule {}
