<div class="dashboard-subscription flex flex-col w-full">
	<div class="section-wrapper bg-white lg:px-10">
		<!-- <h3 *ngIf="!myPlans?.length" class="site-color text-center montserrat-bold">No Active Subscriptions</h3> -->
		<!-- <p class="black-light lg:p-0 montserrat-bold uppercase subscription-header" >Subscriptions</p> -->
		<p *ngIf="!myPlans?.length" class="black-light montserrat-bold mt-3 md:my-3 uppercase text-center" >Not Available</p>
		<!-- <div class="flex items-center border-normal rounded p-4 mt-6" *ngFor="let plan of myPlans">
			<div class="flex flex-col lg:flex-row lg:items-center">
				<p class="tag bg-black white montserrat-bold">{{plan.title}}</p>
				<p class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">${{plan.monthly_price}}/Monthly</p>
				<p class="ml-2 lg:ml-4 mt-2.5 lg:mt-0">${{plan.yearly_price}}/Yearly</p>
			</div>
			<div class="ml-auto" *ngIf="profileId != currentLoggedInUserId">
				<button mat-flat-button (click)="purchasePlan(plan)">
					<span class='s red cursor-pointer montserrat-bold ml-3'> {{'purchase'| titlecase}}</span>
				</button>
			</div>
		</div> -->
		<div
			class="m-auto block unit-wrapper w-m-m md:px-4 grid lg:grid-cols-1 plans-wrapper" *ngIf="myPlans?.length">
			<div class="card-border profile-plan">
				<app-payment-option-card  [planList]="myPlans" [cardheights]="cardheights" (showSpinner)="isShowSpinner($event)"
					[user_type]="userType" [type]="'subscribe'" class="payment-option-card" (purchaseProfilePlan)="purchasePlan($event)">
				</app-payment-option-card>
			</div>
		</div>
	</div>
</div>