import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// import { MessagesComponent } from 'src/app/modules/dashboard/pages/messages/messages.component';
import { shareTypeConst, userTypeConstant } from 'src/app/const/appConst';
// import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
// import { NetworkService } from 'src/app/core/services/network.service';
// import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
// import { CommonService } from 'src/app/core/services/common.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { JobModalComponent } from 'src/app/shared/components/job-modal/job-modal.component';
import { MentionService } from 'src/app/shared/services/mentions.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { PostService } from 'src/app/core/services/post.service';
import { CommentModalComponent } from 'src/app/shared/components/comment-modal/comment-modal.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent {
  innerWidth = window.innerWidth;
  private unsubscribe$ = new Subject();
  userType = userTypeConstant;
  routesConst = routesConst;
  // isFollow = false;
  loginedUserId: any;
  jobData: any;
  profile: any;
  dataForInteractionPanel: any;
  @Input() set jobList(value: any) {
    this.jobData = value;
    this.constructInteractionPanelData(this.jobData);
  }

  @Input() set loginedProfile(value: any) {
    this.profile = value;
  }

  @Input() isHome = false;

  @Input() isShowInteractionPanel: boolean = false;
  @Input() feedCardType: any;

  @Output() apply: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() getFollowing: EventEmitter<any> = new EventEmitter();

  commentForm: FormGroup;
  isLoadingLike = false;
  isFavor: boolean = false;
  likeNumber: number = 0;
  showComment: boolean = false;
  showAllJobTags = false;

  constructor(
    private clipboard: Clipboard,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private router: Router,
    public authService: AuthService,
    private snackService: SnackBarService,
    // private networkService: NetworkService,
    private sharedService: SharedService,
    public mentionService: MentionService,
    private fb: FormBuilder,
    private postService: PostService,
    private _bottomSheet: MatBottomSheet
  ) {
    this.commentForm = this.fb.group({
      comment: ['', [Validators.required, Validators.maxLength(256)]]
    });
  }
  @ViewChild('panel') panel!: MatExpansionPanel;
  @ViewChild('card', { read: ElementRef }) card!: ElementRef;

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
    }
  }

  // ngAfterViewInit() {
  //   if (this.panel && localStorage.getItem('jobtoOpen')?.length) {
  //     const idWithoutQuotes = localStorage.getItem('jobtoOpen')?.replace(/"/g, '');
  //     if (this.jobData?.job?._id == idWithoutQuotes) {
  //       this.panel.expanded = true;
  //       setTimeout(() => {
  //         this.card.nativeElement.scrollIntoView({
  //           block: 'start'
  //         });
  //       }, 50);

  //       localStorage.removeItem('jobtoOpen');
  //     }
  //   }
  // }

  /**
   * Format Date according JobPosted Date
   * @param previousDate
   * @returns
   */
  formatPostDate(previousDate?: any) {
    let currentDate = new Date().getTime();
    let jobDate = new Date(previousDate).getTime();
    let time = (currentDate - jobDate) / 1000;
    let days = Math.floor(time / (3600 * 24));
    let minutes = Math.floor(time / 60);
    let hours = Math.floor(time / (60 * 60));
    let weeks = Math.floor(days / 7);
    let months = Math.floor(days / 30);
    let years = Math.floor(days / 365);

    if (time < 60) {
      return Math.floor(time) + 's';
    } else if (minutes < 60) {
      return minutes + 'm';
    } else if (hours < 24) {
      return hours + 'h';
    } else if (days < 7) {
      return days + 'd';
    } else if (weeks < 4) {
      return weeks + 'w';
    } else if (months < 12) {
      return months + 'mo';
    } else {
      return years + 'y';
    }
  }

  /**
   * Get Location Data
   * @param job
   * @returns
   */
  getLocation(job: any) {
    return (
      job?.location.city_name +
      (job?.location?.state_name ? `, ${job?.location?.state_name} ` : '') +
      (job?.location?.country_code ? `, ${job?.location?.country_code} ` : '')
    );
  }

  /**
   * On Applying to Job
   */
  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.loginedUserId = profileInfo._id;
      }
    });
  }

  // onApply(jobData: any) {
  //   const isLoggedIn = this.authService.loggedIn();
  //   if (isLoggedIn) {
  //     if (jobData.job.company_id == this.loginedUserId) {
  //       this.snackService.showMessage('You cannot apply to your own Job', true);
  //       return;
  //     }
  //     this.apply.emit();
  //   } else {
  //     this.router.navigate(['/']);
  //     this.dialog.open(LoginSignupDialogComponent, {
  //       width: '400px',
  //       height: 'h-fit',
  //       maxWidth: '96%',
  //       maxHeight: '600',
  //       data: { pageToOpen: 'login' },
  //     });
  //   }
  // }

  /**
   * Set Company on message Recuirter
   * @param company
   */
  // messageRecuirter(company: any) {
  //   localStorage.setItem('companyId', JSON.stringify(company));
  //   this.router.navigate(['/dashboard/messages'], {
  //     queryParams: { tab: 1 },
  //   });
  // }

  /**
   * Message Recuiter
   * @param companyId
   */
  // openDialog(companyId: String): void {
  //   const isLoggedIn = this.authService.loggedIn();
  //   if (isLoggedIn) {
  //     this.dialog.open(MessagesComponent, {
  //       width: '600px',
  //       height: '70%',
  //       data: { id: companyId },
  //     });
  //   } else {
  //     this.router.navigate(['/auth/login']);
  //   }
  // }

  showProfile(e: any) {
    e.stopPropagation();
    this.router.navigate(['/profile' + '/' + this.jobData.company.user_name]);
  }

  // follow(job: any) {
  //   if (!this.authService.loggedIn()) {
  //     this.router.navigate(['/']);
  //     this.dialog.open(LoginSignupDialogComponent, {
  //       width: '400px',
  //       height: 'h-fit',
  //       maxWidth: '96%',
  //       maxHeight: '600',
  //       data: { pageToOpen: 'login' },
  //     });
  //     return;
  //   }
  //   this.networkService
  //     .followUser(job.company._id)
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe(() => {
  //       this.getFollowing.emit();
  //       return (job.job.followed = true);
  //     });
  // }

  // unFollow(job: any) {
  //   if (!this.authService.loggedIn()) {
  //     this.router.navigate(['/']);
  //     this.dialog.open(LoginSignupDialogComponent, {
  //       width: '400px',
  //       height: 'h-fit',
  //       maxWidth: '96%',
  //       maxHeight: '600',
  //       data: { pageToOpen: 'login' },
  //     });
  //     return;
  //   }
  //   this.networkService
  //     .unFollow(job.company._id)
  //     .pipe(takeUntil(this.unsubscribe$))
  //     .subscribe(() => {
  //       this.getFollowing.emit();
  //       return (job.job.followed = false);
  //     });
  // }

  // onSave(e: any) {
  //   const isLoggedIn = this.authService.loggedIn();
  //   if (isLoggedIn) {
  //     this.save.emit();
  //   } else {
  //     this.router.navigate(['/']);
  //     this.dialog.open(LoginSignupDialogComponent, {
  //       width: '400px',
  //       height: 'h-fit',
  //       maxWidth: '96%',
  //       maxHeight: '600',
  //       data: { pageToOpen: 'login' },
  //     });
  //   }
  //   e.stopPropagation();
  // }

  // share() {
  //   if (this.authService.loggedIn()) {
  //     this.dialog.open(ShareModalComponent, {
  //       width: '1040px',
  //       data: {
  //         link:
  //           this.commonService.location().origin +
  //           `/${routesConst.jobs}?id=` +
  //           this.jobData.job._id,
  //         content_id: this.jobData.job._id,
  //         shareType: shareTypeConst.job,
  //       },
  //     });
  //   } else {
  //     this.router.navigate(['/']);
  //     this.dialog.open(LoginSignupDialogComponent, {
  //       width: '400px',
  //       height: 'h-fit',
  //       maxWidth: '96%',
  //       maxHeight: '600',
  //       data: { pageToOpen: 'login' },
  //     });
  //   }
  // }

  copyLink() {
    this.clipboard.copy(window.location.origin + '/jobs?id=' + this.jobData.job._id);
    this.snackService.showMessage(`Link Copied`, false);
  }

  sharePost() {
    if (this.authService.loggedIn()) {
      const dialogRef = this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px' : '100%',
        maxWidth: window.innerWidth > 600 ? '80vw' : '100vw',
        data: {
          link: window.location.origin + '/jobs?id=' + this.jobData.job._id,
          content_id: this.jobData.job._id,
          shareType: shareTypeConst.job
        }
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {});
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  jobApplyModal() {
    // this.router.navigate([routesConst.jobs], {
    //   queryParams: { id: this.jobData.job._id },
    // });
    const dialogRef = this.dialog.open(JobModalComponent, {
      width: innerWidth && innerWidth > 768 ? '650px' : '100vw',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      data: {
        id: this.jobData.job._id
      }
    });
  }

  isUserLiked() {
    return this.jobData?.job?.likeDetails?.find((like: any) => like?.user_id === this.profile?._id)
      ? true
      : false;
  }

  public errorHandling = (control: string, error: string) => {
    return this.commentForm.controls[control].hasError(error);
  };

  checkWhitespaces(text: any) {
    return text?.replace(/\s/g, '');
  }

  likePost() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      this.isLoadingLike = true;
      const isAlreadyLikedPost = this.jobData?.job?.likeDetails?.find(
        (like: any) => like.user_id === this.profile?._id
      );
      const index = this.jobData?.job?.likeDetails.findIndex(
        (x: any) => x.user_id === this.profile._id
      );
      if (isAlreadyLikedPost?._id) {
        this.postService
          .unLikePost(isAlreadyLikedPost._id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.isLoadingLike = false;
            this.jobData?.job?.likeDetails.splice(index, 1);
            this.isFavor = false;
            this.likeNumber--;
          });
      } else {
        const options = {
          content_id: this.jobData?.job._id,
          content_type: 'Job',
          profile_id: this.profile._id
        };
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((post: any) => {
            this.isLoadingLike = false;
            if (post.status === 'success') {
              this.jobData?.job?.likeDetails.push({
                user_id: post.data.user_id,
                _id: post.data._id
              });
            }
          });
        this.likeNumber++;
        this.isFavor = true;
      }
    }
  }

  submitForm() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      if (this.commentForm.valid) {
        if (this.profile._id) {
          const options = {
            content_type: 'comment',
            comment: this.commentForm.get('comment')?.value,
            content_id: this.jobData?.job._id,
            profile_id: this.profile._id,
            commentedOn: 'JOB'
          };
          if (this.checkWhitespaces(this.commentForm.get('comment')?.value)?.length) {
            this.postService
              .addComment(options)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.status == 'success') {
                  if (this.jobData?.job.commentDetails) {
                    this.jobData.job.totalThreads++;
                    this.jobData?.job.commentDetails.unshift({
                      ...res.data,
                      userInfo: [
                        {
                          email: this.profile.email,
                          profile_img_path: this.profile.avatar,
                          user_name: this.profile.user_name,
                          userType: this.profile.user_type,
                          _id: this.profile._id
                        }
                      ],
                      likeDetails: [],
                      replyCommentDetails: []
                    });
                  }
                } else {
                  this.snackService.showMessage(res.message, true);
                }
              });
            this.commentForm.get('comment')?.setValue('');
          }
        }
      }
    }
  }

  showComments() {
    this.showComment = !this.showComment;
    var commentPanel = document.getElementById(this.jobData?.job?._id);
    if (commentPanel !== null) {
      commentPanel.style.marginLeft = this.showComment ? '0%' : '100%';
    }
  }

  openBottomSheet(): void {
    if (this.innerWidth <= 768) {
      const bottomSheetRef = this._bottomSheet.open(CommentModalComponent, {
        data: {
          postOwnerID: this.profile._id,
          data: this.jobData.job,
          profile: this.profile,
          post_id: this.jobData.job._id
        }
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        // if (result) {
        //   this.searchByInterest(result);
        // }
      });
    }
  }

  isFavorComment(likes: any) {
    return likes?.findIndex((l: any) => l?.user_id === this.profile?._id) !== -1;
  }

  likeComment(user: any) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      const options = {
        user_id: this.profile?._id,
        content_id: user._id,
        content_type: 'COMMENT'
      };
      if (this.isFavorComment(user.likeDetails)) {
        const id = user.likeDetails.find((like: any) => like.user_id === this.profile?._id)._id;
        if (id) {
          this.postService
            .unLikePost(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: any) => {
              if (res.status === 'Success') {
                const u = {
                  ...user,
                  likeDetails: user.likeDetails.filter(
                    (like: any) => like.user_id !== this.profile?._id
                  )
                };
                this.jobData.job = {
                  ...this.jobData.job,
                  commentDetails: this.jobData.job.commentDetails.map((c: any) => {
                    if (c._id === u._id) return u;
                    return c;
                  })
                };
                this.jobData.job = {
                  ...this.jobData.job,
                  contentDetails: this.jobData.job.contentDetails.map((content: any) => {
                    if (content._id === this.jobData.job._id) return this.jobData.job;
                    return content;
                  })
                };
              }
            });
        }
      } else {
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res.status === 'success') {
              this.jobData.job = {
                ...this.jobData.job,
                commentDetails: this.jobData.job.commentDetails.map((comment: any) => {
                  if (comment._id === user._id) {
                    return {
                      ...user,
                      likeDetails: [
                        ...user.likeDetails,
                        {
                          ...res.data,
                          userInfo: [
                            {
                              email: this.profile.email,
                              profile_img_path: this.profile.avatar,
                              user_name: this.profile.user_name,
                              userType: this.profile.user_type,
                              _id: this.profile._id
                            }
                          ]
                        }
                      ]
                    };
                  }
                  return comment;
                })
              };
            }
          });
      }
    }
  }

  onJobViewLogged(event: any) {
    if (event.postId == this.jobData.job._id) {
      this.jobData.job.views += 1;
      this.dataForInteractionPanel.views += 1
    }
  }

  constructInteractionPanelData(data: any) {
    this.dataForInteractionPanel = {
      commentDetails: data.job.commentDetails,
      likeDetails: data.job.likeDetails,
      totalThreads: data.job.totalThreads,
      views: data.job.views,
      created_at: data.job.created_at,
      description: data.job.job_description,
      tags: data.job.preferred_requirements.map((item: string) => `#${item}`),
      _id: data.job._id,
      profile_id: data.company._id,
      profile: data.company,
      shareType: shareTypeConst.job,
      contentType: 'JOB'
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
