import { Component, HostListener, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import {
  createSheetConstant,
  earnSheetConstant,
  exploreSheetConstant,
  loginSignUpConstant,
  userTypeConstant,
} from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { UploadPostModalComponent } from 'src/app/shared/components/upload-post-modal/upload-post-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { registerValues } from '../../const/register';
import { Sheet } from '../../models/explore';
import { AuthService } from '../../services/auth/auth.service';
import { CommonService } from '../../services/common.service';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { ProfileService } from '../../services/profile.service';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-sticky-footer',
  templateUrl: './sticky-footer.component.html',
  styleUrls: ['./sticky-footer.component.scss'],
})
export class StickyFooterComponent implements OnInit {
  private unsubscribe$ = new Subject();
  loginSignUpConstant = loginSignUpConstant;
  routesConst = routesConst;
  userTypeConstant = userTypeConstant;
  username: string = '';
  exploreSheetConstant: Sheet = exploreSheetConstant;
  createSheetConstant: Sheet = createSheetConstant;
  earnSheetConstant: Sheet = earnSheetConstant;
  type = registerValues.UserTypes.candidate;
  avatar: string = '';
  colorVariable: string = '';
  totalUnReadMessages = 0;
  currentRoute: string = '';
  unreadNotification = 0;
  scrollPosition = 0;
  @HostListener('window:scroll')
  checkScroll() {
    this.scrollPosition =
      this.commonService.getWindow()?.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
  }
  
  constructor(
    private commonService: CommonService,
    private _bottomSheet: MatBottomSheet,
    private sharedService: SharedService,
    private router: Router,
    public authService: AuthService,
    private profileService: ProfileService,
    private dialog: MatDialog
  ) {
    this.currentRoute = router.url;
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.checkUnreadNotifications();
    }
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information) => {
        if (this.authService.loggedIn()) {
          if (information === null) {
            this.sharedService.getUserInfo();
          } else {
            const { profileInfo } = information;
            this.username = profileInfo.user_name;
          }
        }
      });
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event: any) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        this.currentRoute = event.url;

        if (event?.url?.includes(routesConst.dashboard)) {
          this.loadUserDetails();
        }
      });
    this.loadUserDetails();
    this.getUnredMessageCount();
  }

  isActiveRoute(routeName: string): boolean {
    return this.currentRoute === routeName;
  }

  postContent() {
    let innerWidth = this.commonService.getWindow()?.innerWidth;
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px':'100vw',
        height: window.innerWidth > 600 ? 'h-fit':'100%',
        maxWidth: window.innerWidth > 600 ? '96%':'100vw',
        maxHeight: window.innerWidth > 600 ? '600':'100vh',
        data: { pageToOpen: 'login' },
      });
    } else {
      this.dialog.open(UploadPostModalComponent, {
        maxWidth: '100vw',
        width: '536px',
        height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
        disableClose: true,
      });
    }
  }

  checkUnreadNotifications() {
    this.sharedService.getUnreadNotification();
    this.sharedService.isUnreadNotification$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.unreadNotification = res;
      });
  }

  openNotifications() {
    this.closeAll();
    if (this.authService.loggedIn()) {
      this.dialog.open(NotificationComponent, {
        maxWidth: '100vw',
        width: '536px',
        height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
        panelClass: 'notification-dialog-container',
      });
    } else {
      this.openSignUp(loginSignUpConstant.login);
    }
  }

  createCampaign() {
    if (this.authService.loggedIn()) {
      this.router.navigate(['/' + routesConst.campaign]);
    } else {
      this.openSignUp(loginSignUpConstant.login);
    }
  }

  openSignUp(pageToOpen: string) {
    this.dialog.open(LoginSignupDialogComponent, {
      width: window.innerWidth > 600 ? '400px':'100vw',
      height: window.innerWidth > 600 ? 'h-fit':'100%',
      maxWidth: window.innerWidth > 600 ? '96%':'100vw',
      maxHeight: window.innerWidth > 600 ? '600':'100vh',
      data: { pageToOpen },
    });
  }

  loadUserDetails() {
    if (this.authService.loggedIn()) {
      this.dashboard();
    }
  }

  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information: { profileInfo: any } | null) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else if (information != null) {
          const { profileInfo } = information;
          const { avatar } = profileInfo;
          this.avatar =
            avatar && avatar?.length
              ? avatar
              : 'https://i.postimg.cc/Hk7vyZZM/no-image.pngs';

          this.unReadMessageCount();
        }
      });
  }

  openSheet(sheet: Sheet) {
    const { data } = sheet;
    this._bottomSheet.open(BottomSheetComponent, {
      panelClass: 'custom-bootom-sheet-modalbox',
      data,
    });
  }

  getUnredMessageCount() {
    this.commonService.refreshUnReadMessages$.subscribe((refresh) => {
      if (refresh) {
        this.totalUnReadMessages++;
      } else if (!refresh) {
        this.totalUnReadMessages--;
      }
    });
  }

  unReadMessageCount() {
    this.profileService.getUnreadMessageCount().subscribe((messages: any) => {
      this.totalUnReadMessages = messages.total;
    });
  }

  closeAll(){
    this.dialog.closeAll();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
