<div class="px-4 lg:px-0 pt-0.5 lg:pt-0 dashboard-payment">
  <div class="flex items-center w-full">
    <h1 class="montserrat-bold window-mode">Events</h1>
  </div>
  <div class="tabs mt-4 md:mt-9">
    <mat-tab-group color="#fff0" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
            <p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">Schedule</p>
          </div>
        </ng-template>
        <app-dashboard-livestreams-stats [ifSchedule]="selected.value"></app-dashboard-livestreams-stats>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="addNewEvent()" class="flex items-center pb-1 tab" [class]="selected.value === 1 ? 'selected' : ''">
            <p [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">Add Event</p>
          </div>
        </ng-template>
        <!-- <app-dashboard-livestreams-preferences></app-dashboard-livestreams-preferences> -->
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="createCampaign()" class="flex items-center pb-1 tab" [class]="selected.value === 2 ? 'selected' : ''">
            <p [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">Campaign</p>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div (click)="postcontent()" class="flex items-center pb-1 tab" [class]="selected.value === 3 ? 'selected' : ''">
            <p [class]="selected.value === 3 ? 'blue montserrat-bold' : ''">Post Content</p>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>