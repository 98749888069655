import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { OperationHours, userTypeConstant } from 'src/app/const/appConst';
import { JobService } from 'src/app/core/services/job.service';

@Component({
  selector: 'app-profile-jobs',
  templateUrl: './profile-jobs.component.html',
  styleUrls: ['./profile-jobs.component.scss'],
})
export class ProfileJobsComponent implements OnInit {
  userTypeConstant = userTypeConstant;
  private unsubscribe$ = new Subject();
  @Input() set user_name(name: any) {
    if (name) {
      this.getUserInfo(name);
    }
  }

  allExperienceLevel: any;
  userInfo: any;
  operationHours: any[] = OperationHours;
  userSkills: any = ([] = []);
  educations: any[] = [];
  workHistory: any[] = [];
  projects: any[] = [];
  expLevel: any;
  user: any;

  candidateJobPreference:any;

  constructor(
    public dialog: MatDialog,
    private jobService: JobService
  ) {}
  ngOnInit(): void {}

  getUserInfo(user_name: any) {
    this.jobService
      .getCandidateProfileJobPreferences(user_name)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((preference: any) => {
        this.candidateJobPreference = preference.data;
      });
  }
}
