export const routesConst = {
  launchPath: '',
  auth: '',
  home: 'home',
  general: 'content',
  resetPassword: 'reset-password',
  createPassword: 'create-password',
  defaultPath: '',
  signup: 'signup',
  register: 'register',
  login: 'login',
  jobs: 'jobs',
  talents: 'services',
  individualService: 'services/service/:profile/:id',
  individualSubscriptoin: 'subscriptions/subscription/:profile/:id',
  community: 'creators',
  post: 'post/:post_id',
  events: 'events',
  hashtag: 'hashtag',
  subscribe: 'subscribe',
  dashboard: 'dashboard',
  account: 'account',
  services: 'services',
  messages: 'messages',
  network: 'network',
  users: 'users',
  referrals: 'referrals',
  subscription: 'subscriptions',
  payments: 'payments',
  stats: 'stats',
  chat: 'chat',
  contact: 'content/contact',
  about: 'content/about',
  upload: 'upload',
  pricing: 'pricing',
  checkPricing: 'pricing/check',
  profile: 'profile',
  profilelist: 'user/list',
  companyDetail: 'company-profile/:userid',
  jobsCategory: 'job-category/:category',
  companyList: 'company/list',
  profilePage: 'profile',
  changepassword: 'change-password',
  companyRelatedJobs: 'company/:id/jobs',
  editJobDetails: '/jobs/details/:id/edit',
  appliedJobs: 'applied-jobs/:id',
  viewJobs: 'view-jobs/:jobid',
  sidebar: 'sidebar',
  applyjob: 'applyjob/:jobid',
  reviewprofile: 'review-profile/:id',
  privacy: 'privacy',
  // privacy: 'content/privacy',
  // terms: 'content/terms',
  terms: 'terms',
  cookies: 'cookies',
  dsar: 'content/cookies',
  dsn: 'content/do-not-sell',
  'do-not-sell': 'do-not-sell',
  disclaimer: 'disclaimer',
  preferences: 'preferences',
  twitch: 'twitch',
  explore: 'explore',
  faq: 'frequently-ask-question',
  tutorials : 'tutorials',
  create: 'create',
  earn: 'earn',
  plans: 'plans',
  addons: 'addons',
  aboutus: 'about-us',
  more: 'more',
  content: 'content',
  campaign: 'campaigns',
  features: 'features',
  howItWorks: 'how-it-works',
  subscribers: 'subscribers',
  postJob: 'post',
  instantPay: 'features/instant-pay',
  featuresReferrals: 'features/referrals',
  planPricing: 'pricing/plan',
  addonPricing: 'pricing/addons',
  earnReferrals: 'earn/referrals',
  service: 'service',
  discover: 'discover',
  communities: 'community'
};
