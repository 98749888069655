<div class="dashboard-service-orders bg-white p-4 lg:p-10">
	<div class="text-center">
		<p><b>$1,920</b> paid</p>
		<p class="s black-light mt-2">$1,839 in your balance</p>
	</div>

	<div class="">
		<app-service-order-unit></app-service-order-unit>
		<app-service-order-unit></app-service-order-unit>
		<app-service-order-unit></app-service-order-unit>
	</div>
</div>