
  <div class="m-auto block w-m-m ">
    <div class="card-border footer-pricing-plans">
      <app-payment-option-card *ngIf="!isHomePage" [getFromLocalStorage]="true" [planList]="forIndividual ? elitePlanList : enterprisePlanList" [cardheights]="cardheights" (showSpinner)="isShowSpinner($event)"
        [user_type]="userType" class="payment-option-card">
      </app-payment-option-card>

      <swiper [config]="membersConfig" *ngIf="isHomePage" [pagination]="false" class="addons-page">
        <ng-template swiperSlide *ngFor="let item of addOns">
          <app-payment-option-card [getFromLocalStorage]="true" [planList]="[item]" [cardheights]="cardheights" (showSpinner)="isShowSpinner($event)"
          [user_type]="userType" class="payment-option-card">
        </app-payment-option-card>
          </ng-template>
      </swiper>
    </div>
  </div>