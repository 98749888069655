import { Component, Input, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { userTypeConstant } from 'src/app/const/appConst';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-skills-tab',
  templateUrl: './skills-tab.component.html',
  styleUrls: ['./skills-tab.component.scss']
})
export class SkillsTabComponent implements OnInit {
  private unsubscribe$ = new Subject();
  userCandiadate = userTypeConstant.candidate;
  allSkills: any;
  saveSkill = false;
  user: any;
  @Input() set profile(profile: any) {
    this.user = profile;
    this.getSkills();
  }

  @Input() achievements: any;
  constructor(private profileService: ProfileService, private snack: SnackBarService) {}

  ngOnInit(): void {}

  getSkills() {
    this.profileService
      .profileSkills()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        const { data: skills } = result;
        this.allSkills = skills.map((skill: any) => ({
          _id: skill._id,
          name: skill.name,
          active:
            this.user?.profileSkills?.find((item: string) => item === skill._id) ||
            this.achievements?.find((item: any) => item.type == 'Skill' && item.title == skill.name)
              ? true
              : false
        }));
      });
  }

  updateProfileSkills() {
    const profileSkills = this.allSkills
      .filter((i: { active: boolean }) => i.active === true)
      .map((item: { _id: any }) => item._id);
    this.user.profileSkills = profileSkills;
    this.saveSkill = false;
    this.profileService
      .updateProfile({ profileSkills })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.snack.showMessage('Skills Updated Successfully', false);
        },
        () => {
          this.snack.showMessage('Failed to Update Skills', true);
        }
      );
  }
}
