<div class="followers-tab mt-3 lg:mt-12" [class]="loading ? 'spinner' : ''">
  <div class="flex items-center justify-between">
    <p class="tab-size">{{totalcount}} {{totalcount > 1 ? 'Followers' : 'Follower'}}</p>
    <div class="border-normal rounded flex items-center px-3 py-2 bg-white">
      <app-select-form [label]="searchSortValue" (set)="setSearchSort($event)" [data]="sortByData">
      </app-select-form>
    </div>
  </div>
  <div *ngIf="followersList.length" class="my-6 grid lg:grid-cols-5 lg:gap-2 scroll" #jobsContainer infinite-scroll
    [infiniteScrollContainer]="jobsContainer" [infiniteScrollThrottle]="50" [infiniteScrollDistance]="0.5"
    (scrolled)="onScrollDown()">
    <app-follower-card isFollower=true *ngFor="let item of followersList" [data]="item"
      (unfollow)="unFollowUser(item)"></app-follower-card>
  </div>
  <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
  </ngx-spinner>
</div>