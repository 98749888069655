<div class="header grid grid-cols-1 sticky w-full top-0 h-fit justify-center"
[class]="
  (scrollPosition >= 50 ? 'bg-black-dark' : 'bg-none') +
  (opened ? ' top-layer' : '')
">
<div
  class="header flex w-full px-4 h-[58px] justify-between"
  [class]="
    (scrollPosition >= 50 ? 'bg-black-dark' : 'bg-none') +
    (opened ? ' top-layer' : '')
  "
>
  <mat-drawer-container
    class="sidenav-container" [ngClass]="authService.loggedIn() ? 'padding-remove':''"
    autosize
    (click)="handleSidebar($event)"
  >
    <mat-drawer #drawer class="sidenav-content" mode="over" position="end">
      <div class="flex items-start justify-between pl-1 pr-5 relative">
        <img routerLink="/" (click)="drawer.toggle(); opened = !opened" 
       class="w-9" src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png" alt="">
       <mat-icon (click)="drawer.toggle(); this.showOptionGroupF = false; opened = !opened; onSidebarToogle.emit(opened)
     " class="text-3xl close-icon absolute -top-1.5 right-3 cursor-pointer">close</mat-icon>
      </div>
      <div *ngIf="authService.loggedIn()" class="user-info-mobile block">
        <div class="flex flex-col justify-start items-center">
          <div
            class="flex items-center justify-between cursor-pointer"
            routerLink="{{ '/' + routesConst.profile + '/' + user.userName }}"
            routerLinkActive="strong"
            (click)="
              showLogoutOption();
              showOptionGroupF = !showOptionGroupF;
              drawer.toggle();
              opened = !opened
            "
          >
            <div
              *ngIf="user?.avatar !== undefined"
              class="relative avatar bg-contain bg-no-repeat bg-center"
              [ngStyle]="{ 'background-image': 'url(' + user.avatar + ')' }"
            ></div>
            <div class="ml-3">
              <p
                class="montserrat-medium whitespace-nowrap mx-w-40 truncate text-black"
              >
                {{ user?.name ? user?.name : user?.userName }}
              </p>
              <p
                class="text-base gray-light mt-1 whitespace-nowrap mx-w-40 truncate"
              >
                @{{ user?.userName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="authService.loggedIn() ">
        <app-sidenav-list
          [isLoggedIn]="authService.loggedIn()"
          [isUnread]="checkUnReadMessages()"
          [navItems]="authService.loggedIn() ? loggedNavItems : navItems"
          [userType]="type"
          (closeSidebar)="drawer.toggle(); opened = !opened"
          (postJob)="postJob(); drawer.toggle(); opened = !opened"
          (uploadPost)="postContent(); drawer.toggle(); opened = !opened"
        ></app-sidenav-list>
      </ng-container>
      <div class="shadow-sm border -ml-2.5" *ngIf="authService.loggedIn()">
        <div
          (click)="
            logout();
            showOptionGroupF = !showOptionGroupF;
            drawer.toggle();
            opened = !opened
          "
          class="flex items-center cursor-pointer hov p-2 md:pl-5"
        >
          <mat-icon class="mr-2 text-black fill">logout</mat-icon>
          <p class="text-black font-semibold textHoverColor">Log Out</p>
        </div>
      </div>
      <app-sidenav-footer (closeSideMenu)="drawer.toggle();opened = !opened" [opened]="opened"
        [isLoggedIn]="authService.loggedIn()"
      ></app-sidenav-footer>
      <a href="/" class="footer-email h-fit px-6 pt-4" *ngIf="authService.loggedIn()" [ngClass]="authService.loggedIn() ? 'flex justify-center':'bottom-16 justify-center absolute left-0 right-0 md:bottom-2 hidden'">
        <img
          src="https://assets-global.website-files.com/60ca70f6cb7406afbf948a8e/60d15ad570c7d06ba3bd1b4b_footer_email.svg"
          class="mr-2 w-6"
        />
        <a
          href="https://accounts.google.com/v3/signin/identifier?dsh=S-83614693%3A1676377807514689&continue=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&emr=1&followup=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&osid=1&passive=1209600&service=mail&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AWnogHeBsfmsRLkDfVdYJ4aWTG-2e6HoX2weruEodUnnOeO8R6ooMWznliOLmdGdcgnDC1eaHCLINg"
          target="_blank"
          class="footer-email-address text-black"
          >support@jobpros.io</a
        >
      </a>
    </mat-drawer>
    <div class="sidenav-left-content"></div>
  </mat-drawer-container>
  <div class="flex items-center md:mr-4 w-full">
    <a class="header__logo" routerLink="{{ routesConst.launchPath }}">
      <span
        class="truncate"
        [class]="
          (scrollPosition > 50 ? 'w-logo ' : 'b-logo ') +
          (currentPageUrl === '/' ||
          currentPageUrl === '/home' ||
          currentPageUrl === ''
            ? 'home'
            : 'home')
        "
      ></span>
    </a>
    <div *ngIf="showSubMenu" class="hidden md:flex justify-center items-center w-full">
      <app-feature-menu></app-feature-menu>
    </div>
    <div class="ml-auto rounded-xl bg-[#2887ed] px-3 py-2" *ngIf="calculateDaysRemaining(stripeUserData?.trial_expiry_date) > 0">
      <p class="text-sm text-white" style="text-wrap: nowrap;">{{calculateDaysRemaining(stripeUserData?.trial_expiry_date)}} day trial </p>
    </div>
    <!-- <div *ngIf="!SerchClose" class="md:hidden flex h-full ml-auto mr-2">
      <mat-icon
        (click)="serchClose()"
        [class]="scrollPosition >= 50 ? 'text-white' : ' text-black '"
        class="text-3xl search-icon-click items-center justify-center"
        >search</mat-icon
      >
    </div> -->
    <div
      *ngIf="!SerchClose && authService.loggedIn()"
      [matMenuTriggerFor]="notificationMenu"
      class="md:hidden relative w-fit mr-3 mb-1 flex ml-auto"
      (click)="openNotifications()"
    >
      <mat-icon
        class="notification-bell"
        [ngClass]="{ 'text-white border-white': scrollPosition > 50 }"
      >
        notifications_none</mat-icon
      >
      <span *ngIf="unreadNotification" class="badge absolute">
        {{ unreadNotification > 9 ? "9+" : unreadNotification }}
      </span>
    </div>
    <div
      *ngIf="!authService.loggedIn()"
      class="icon-person-wrapper flex ml-auto mr-2 justify-center items-center h-full cursor-pointer"
    >
      <button
        class="signup-signin text-nowrap md:min-w[69px]" style="text-wrap: nowrap;"
        (click)="login = true; openSignUp(loginSignUpConstant.login)"
        [ngClass]="{ loginborder: login, 'text-white': scrollPosition > 50 }"
      >
        Sign In
      </button>
      <button
        class="signup-signin text-nowrap ml-2 md:min-w[79px]" style="text-wrap: nowrap;"
        (click)="login = false; openSignUp(loginSignUpConstant.SignUp)"
        [ngClass]="{ loginborder: !login, 'text-white': scrollPosition > 50 }"
      >
        Sign Up
      </button>
    </div>
  </div>
  <div
    *ngIf="authService.loggedIn()"
    class="md:ml-auto items-center h-full my-auto mr-8 hidden lg:flex cursor-pointer"
    (click)="openNotifications()"
  >
    <div class="relative w-fit">
      <mat-icon
        class="notification-bell"
        [ngClass]="{ 'text-white border-white': scrollPosition > 50 }"
      >
        notifications_none</mat-icon
      >
      <span *ngIf="unreadNotification" class="badge absolute">
        {{ unreadNotification > 9 ? "9+" : unreadNotification }}</span
      >
    </div>
  </div>
  <div
    *ngIf="authService.loggedIn()"
    class="md:ml-auto items-center -mt-2 mr-4 hidden lg:flex"
  >
    <button
      class="flex items-center justify-between"
      mat-text-button
      [routerLink]="routesConst.dashboard"
    >
      <div class="flex items-center justify-between cursor-pointer">
        <div
          *ngIf="user?.avatar !== undefined"
          class="relative avatar bg-contain bg-no-repeat bg-center"
          [ngStyle]="{ 'background-image': 'url(' + user.avatar + ')' }"
        ></div>
        <div class="showUserOption-icon-wrapper mt-2">
        </div>
      </div>
    </button>
  </div>
  <div class="header__menu-control flex items-center">
    <app-menu-icon
      [opened]="opened"
      (click)="
        drawer.toggle();
        this.showOptionGroupF = false;
        opened = !opened;
        onSidebarToogle.emit(opened)
      "
      class="flex"
      [scrollPosition]="scrollPosition"
    ></app-menu-icon>
  </div>
</div>
<!-- <hr [ngStyle]="{'border-top': scrollPosition >= 50 ? '0.6px solid rgba(255, 255, 255, 0.3)':'0.6px solid rgba(0, 0, 0, 0.1)'}"> -->
  <!-- <app-feature-menu></app-feature-menu> -->
</div>
