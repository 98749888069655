<div *ngIf="data" class="
    hidden
    lg:flex
    flex-col
    border-normal
    rounded
    bg-white
    relative
    items-center
    px-2.5
    py-4
  ">
  <img *ngIf="data.profile_image" [src]="data.profile_image" class="mt-2.5 rounded-full w-24 h-24" />
  <p *ngIf="data.user_name" >{{ data.user_name }}</p>
</div>
<div *ngIf="data" class="flex lg:hidden relative items-center px-2.5 py-4">
  <img *ngIf="data.profile_image" [src]="data.profile_image" class="mt-2.5 rounded-full w-20 h-20" />
  <div class="ml-4">
    <div *ngIf="data.user_name" class="flex items-center">
      <p class="montserrat-bold">{{ data.user_name }}</p>
    </div>
  </div>

</div>
<mat-divider class="w-full lg:hidden opacity-50"></mat-divider>