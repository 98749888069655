import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-frequently-ask-question',
  templateUrl: './frequently-ask-question.component.html',
  styleUrls: ['./frequently-ask-question.component.scss']
})
export class FrequentlyAskQuestionComponent implements OnInit {
  @Input() data: any;
  innerWidth = window.innerWidth;
  routesConst = routesConst;
  isAboutUsClose= false;
  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  };
  configButtons: SwiperOptions = {
    slidesPerView: 'auto'
  };
  selectedTab:string = 'About Us';
  constructor(private route: ActivatedRoute) { 
  }
  
  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const tab = params.get('tab');
      if (tab === 'payments') {
        this.selectedTab = 'Payments';
        setTimeout(() => {
          this.scroll('#Payments')
        }, 500);
      } else {
        this.selectedTab = this.data === 'about-us' ? 'Community' : 'About Us';
      }
    });
  }
  
  
  scroll(element: any) {
    if (element !== '') {
      this.isAboutUsClose= false
      // document.querySelector(element).scrollIntoView({ block: 'start', behavior:"smooth" });
      const targetElement = document.querySelector(element);
      let marginTop = this.data === 'about-us'? this.innerWidth < 768 ? 68 : 90 :this.innerWidth < 768 ? 150 : 160
    if (targetElement) {
      const offset = targetElement.getBoundingClientRect().top + window.pageYOffset - marginTop;
      window.scrollTo({
        top: offset,
        behavior: "smooth"
      });
    }
    }
  }

}
