<div class="bg-white section-wrapper overflow-hidden lg:p-10">
  <div class="flex flex-col lg:flex-row mb-2">
    <div class="mb-2 lg:mb-0">
      <p class="black-light montserrat-bold uppercase mt-1.5">
        Followers & Subscribers
      </p>
      <p class="s mt-2 black-light">Review followers in the platform</p>
    </div>
    <div class="block lg:hidden">
      <mat-divider class="w-full"></mat-divider>
    </div>
    <div
      class="border-normal rounded w-full lg:w-32 flex items-center p-2 lg:p-3 lg:ml-auto mt-6 lg:mt-0"
    >
      <mat-select [value]="followerRange">
        <mat-option *ngFor="let item of followerRangeArr" [value]="item.key">{{
          item.value
        }}</mat-option>
      </mat-select>
    </div>
  </div>
  <div class="hidden lg:block">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div
    class="flex flex-col lg:flex-row items-center border-normal rounded blue mt-6 p-5"
  >
    <div class="">
      <p class="montserrat-bold blue">4 influencers pending review</p>
      <p class="s mt-2">
        There are some people requesting to be influencers. Please review them
        shortly.
      </p>
    </div>
    <div class="lg:ml-auto mt-4 lg:mt-0 w-full lg:w-32 flex justify-center">
      <button class="button" mat-button matStepperNext>Review</button>
    </div>
  </div>
  <!-- <div class="mt-10" *ngIf="chartOptions">
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [stroke]="chartOptions.stroke"
      [colors]="chartOptions.colors"
      [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend"
      [markers]="chartOptions.markers"
      [grid]="chartOptions.grid"
      [yaxis]="chartOptions.yaxis"
      [title]="chartOptions.title"
    ></apx-chart>
  </div> -->
</div>
