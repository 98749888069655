import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ImagePreviewModalComponent } from 'src/app/modules/individual-service/components/image-preview-modal/image-preview-modal.component';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';
import { TalentService } from 'src/app/core/services/talent.service';
import { PostService } from 'src/app/core/services/post.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CustomerAllReviewsModalComponent } from '../dashboard/pages/customer-all-reviews-modal/customer-all-reviews-modal.component';
import { ProfileService } from 'src/app/core/services/profile.service';
import { statusConstant } from 'src/app/const/appConst';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { routesConst } from 'src/app/core/const/routers';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/core/services/common.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { ImageService, ImageSnippet } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-individual-service',
  templateUrl: './individual-service.component.html',
  styleUrls: ['./individual-service.component.scss']
})
export class IndividualServiceComponent implements OnInit {
  currentImageSlide = 0;
  updatedTitle: any;
  updatedPackage: any;
  private unsubscribe$ = new Subject();
  selectedPlan = 'Basic';
  user_name: string = '';
  selectedService: any;
  selected: number;
  images: any = {};
  slides?: any[];
  pricingPackages!: any[];
  services: any[] = [];
  otherServices: any[] = [];
  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      992: {
        slidesPerView: 3,
        slidesPerGroup: 3
      },
      1025: {
        slidesPerView: 4,
        slidesPerGroup: 4
      }
    }
  };
  activeSlide: number = 1;
  averageRating: any;
  loginedUser: any;
  service_id: any;
  selectedPackage: any;
  isUpdated: boolean = false;
  allImages: any = [];

  constructor(
    private postService: PostService,
    private authService: AuthService,
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private talentService: TalentService,
    private router: Router,
    private profileService: ProfileService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private snack: SnackBarService,
    private sharedService: SharedService,
    private stripeService: paymentService,
    private imageService: ImageService
  ) {
    this.selected = 0;
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
    }

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params: any) => {
      if (params.id && params.profile) {
        this.addViews(params.id);
        this.user_name = params.profile;
        this.service_id = params.id;
        this.talentService
          .getSingleTalentService(params.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (result: any) => {
              this.averageRating = result?.averageRating;
              this.selectedService = result.data;
              // this.slides = this.selectedService?.portfolios.map(
              //   (portfolio: any) => {
              //     return {
              //       path: portfolio.url,
              //     };
              //   }
              // );
              this.images.slides = this.selectedService?.portfolios.map((portfolio: any) => {
                return {
                  path: portfolio.url
                };
              });
              this.allImages = this.selectedService?.portfolios.map((portfolio: any) => {
                return {
                  content: portfolio.url,
                  cover_type: portfolio.cover_type,
                  format: portfolio?.media_type || 'image',
                  order_by: portfolio.order_by,
                  url: portfolio.url
                };
              });
              this.pricingPackages = this.selectedService?.packages.map((pkg: any) => {
                return {
                  _id: pkg._id,
                  title: pkg.name,
                  price: pkg.price,
                  description: pkg.description,
                  features: pkg.offers.split(','),
                  price_id: pkg.price_id,
                  billing_type: pkg.billing_type,
                  isMore: true,
                  type: pkg?.type ? pkg.type : '',
                  isBigger: pkg.description.length > 140
                };
              });
              this.selectedPackage =
                this.pricingPackages.find(obj => obj.type === 'basic') || this.pricingPackages[0];

              const filter = {
                user_name: params.profile,
                offset: 0,
                limit: 10,
                mode: 'Info'
              };
              this.talentService
                .publicTalentService(filter)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(
                  (service: any) => {
                    this.services = service.data;
                  },
                  () => {
                    this.router.navigate(['/404']);
                  }
                );
            },
            () => {
              this.router.navigate(['/404']);
            }
          );
      } else {
        this.router.navigate(['/404']);
      }
    });
  }

  refreshService(service: any) {
    this.talentService
      .getSingleTalentService(service._id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result: any) => {
          this.averageRating = result?.averageRating;
          this.selectedService = result.data;
          this.images.slides = this.selectedService?.portfolios.map((portfolio: any) => {
            return {
              path: portfolio.url
            };
          });
          this.allImages = this.selectedService?.portfolios.map((portfolio: any) => {
            return {
              content: portfolio.url,
              cover_type: portfolio.cover_type,
              format: portfolio.media_type,
              order_by: portfolio.order_by,
              url: portfolio.url
            };
          });
          this.pricingPackages = this.selectedService?.packages.map((pkg: any) => {
            return {
              title: pkg.name,
              price: pkg.price,
              description: pkg.description,
              features: pkg.offers.split(','),
              price_id: pkg.price_id,
              billing_type: pkg.billing_type,
              isMore: true,
              type: pkg?.type ? pkg.type : '',
              isBigger: pkg.description.length > 140
            };
          });
          this.selectedPackage =
            this.pricingPackages.find(obj => obj.type === 'basic') || this.pricingPackages[0];

          const filter = {
            user_name: service.profile.user_name,
            offset: 0,
            limit: 10,
            mode: 'Info'
          };
          this.talentService
            .publicTalentService(filter)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (service: any) => {
                this.services = service.data;
              },
              () => {
                this.router.navigate(['/404']);
              }
            );
        },
        () => {
          this.router.navigate(['/404']);
        }
      );
  }

  addViews(serviceId: any) {
    if (this.authService.loggedIn()) {
      const payload = {
        content_id: serviceId,
        type: 'service'
      };
      this.postService
        .updateViews(payload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {});
    }
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.loginedUser = profileInfo;
      }
    });
  }

  viewProfile() {
    this.router.navigate(['/profile' + '/' + this.user_name]);
  }

  onSlideChange(swiper: any) {
    this.currentImageSlide = swiper.activeIndex;
    this.activeSlide = swiper.activeIndex + 1;
    this.changeDetector.detectChanges();
  }

  openReviewModal() {
    this.dialog.open(CustomerAllReviewsModalComponent, {
      maxWidth: innerWidth && innerWidth > 768 ? '800px' : '100vw',
      width: '100%',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      data: { selectedService: this.selectedService, reviewsType: 'Seller' },
      disableClose: true
    });
  }

  async purchaseService(event: any, purchasedType: string) {
    if (this.authService.loggedIn()) {
      if (!this.user_name) {
        this.snack.showMessage('UnKnown Service', true);
        return;
      }
      if (this.user_name == this.loginedUser?.user_name) {
        this.snack.showMessage("You can't purchase your own Service", true);
        return;
      }
      // this.profileService
      //   .getOnBoardStatus()
      //   .pipe(takeUntil(this.unsubscribe$))
      //   .subscribe((user: any) => {
      //     if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
      //       const dialogRef = this.dialog.open(ConfirmModalComponent, {
      //         width: '500px',
      //         data: {
      //           message: `Complete one time Stripe onboarding
      //           to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
      //           header: 'Stripe Onboarding'
      //         }
      //       });
      //       dialogRef.afterClosed().subscribe(result => {
      //         if (result) {
      //           localStorage.setItem(
      //             'purchase_plan_type',
      //             JSON.stringify({ plan_type: 'Recruiter' })
      //           );
      //           this.router.navigate(['/' + routesConst.planPricing]);
      //         }
      //       });
      //     } else {
      //     }
      //   });
        this.purchase(event, purchasedType);
      } else {
      this.spinner.hide();
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  purchase(event: any, purchasedType: any) {
    // this.spinner.show();
    const redirect_url = this.commonService.location().href
    const refresh_url = this.commonService.location().href
    this.talentService
      .getStripeUser(redirect_url, refresh_url)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          // if (res.status == 'on_board_required') {
          //   this.snack.showMessage(`${res.message}`, false);
          //   this.spinner.hide();
          //   this.commonService.location().href = res.accountLink.url;
          // } else {
            let params: any = {
              mode: event.billing_type == '61e1a8fa54f7e11728dcfc86' ? 'payment' : 'subscription',
              cancel_url: `${window.origin}/failed`,
              success_url: `${window.origin}/success`,
              line_items: [
                {
                  price: event.price_id,
                  quantity: 1
                }
              ]
            };
            // this.spinner.show();
            this.profileService
              .getCommunityPageUserProfile(this.user_name)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((x: any) => {
                if (x?.data && x?.data[0]) {
                  const { _id, user_name } = x.data[0].profile;
                  this.stripeService
                    .purchaseProduct(
                      params,
                      _id,
                      user_name,
                      this.loginedUser._id,
                      this.service_id,
                      purchasedType,
                      event.billing_type,
                      event._id
                    )
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe(
                      (res: any) => {
                        this.spinner.hide();
                        if (res && res?.accountLink?.url) {
                          this.commonService.location().href = res?.accountLink?.url;
                        } else {
                          this.spinner.hide();
                          this.snack.showMessage(
                            'Unable to connect to Stripe at the moment, Please try again later',
                            true
                          );
                        }
                      },
                      error => {
                        this.spinner.hide();
                        this.snack.showMessage(`${error}`, true);
                      }
                    );
                }
              });
          // }
        },
        error => {
          this.spinner.hide();
          this.snack.showMessage(`${error} `, true);
        }
      );
  }

  savePackageData(formData: any) {
    let index = 0;
    if (this.selectedPlan == 'Basic') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'basic');
    }
    if (this.selectedPlan == 'Standard') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'standard');
    }
    if (this.selectedPlan == 'Premium') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'premium');
    }
    this.updatedPackage = formData;
    Object.assign(this.updatedPackage, {
      price_id: this.selectedPackage.price_id
    });

    this.isUpdated = true;
    // this.pricingPackages[idx].title = formData.name
    // this.pricingPackages[idx].description = formData.description
    // this.pricingPackages[idx].features = formData.offers.split(',')
  }

  getArray(size: number) {
    let array = [0];
    for (let i = 1; i < size; i++) {
      array.push(i);
    }
    return array;
  }

  updatePackage(selectedPackage: any) {
    let payload = {
      name: this.updatedPackage.name,
      description: this.updatedPackage.description,
      offers: this.updatedPackage.offers,
      price_id: this.updatedPackage.price_id
    };

    this.talentService
      .updateServicePackage(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        selectedPackage.editMode = false;
        this.spinner.hide();
        if (res.status === 'success') {
          this.selectedPackage.title = payload.name;
          this.selectedPackage.description = payload.description;
          this.selectedPackage.features = payload.offers.split(',');
          this.snack.showMessage('Package updated successfully.', false);
        }
      });
  }

  selectPackage(planType: string) {
    this.selectedPlan = planType;
    if (this.selectedPlan == 'Basic') {
      this.selectedPackage =
        this.pricingPackages.find(obj => obj.type === 'basic') || this.pricingPackages[0];
    }
    if (this.selectedPlan == 'Standard') {
      this.selectedPackage =
        this.pricingPackages.find(obj => obj.type === 'standard') || this.pricingPackages[1];
    }
    if (this.selectedPlan == 'Premium') {
      this.selectedPackage =
        this.pricingPackages.find(obj => obj.type === 'premium') || this.pricingPackages[2];
    }
  }

  getSelectedPackageIndex() {
    let index = 0;
    if (this.selectedPlan == 'Basic') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'basic');
    }
    if (this.selectedPlan == 'Standard') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'standard');
    }
    if (this.selectedPlan == 'Premium') {
      index = this.pricingPackages.findIndex(obj => obj.type === 'premium');
    }
    return index;
  }

  message() {
    if (this.selectedService.profile._id) {
      const talentInfo = {
        email: this.selectedService.profile.email,
        name: this.selectedService.profile.name,
        profile_img_path: this.selectedService.profile.profile_img_path,
        user_name: this.selectedService.profile.user_name,
        user_type: this.selectedService.profile.user_type,
        _id: this.selectedService.profile._id
      };
      localStorage.setItem('talentInfo', JSON.stringify(talentInfo));
      this.router.navigate(['/dashboard/messages'], {
        queryParams: { tab: 3 }
      });
    }
  }

  updateTitle() {
    let payload = {
      serviceId: this.selectedService._id,
      title: this.updatedTitle
    };
    this.talentService
      .updateServicePackage(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.selectedService.editTitle = false;
        this.spinner.hide();
        if (res.status === 'success') {
          this.selectedService.title = payload.title;
          this.snack.showMessage('Title updated successfully.', false);
        }
      });
  }

  uploadFile(files: FileList, index: number) {
    index = this.currentImageSlide;
    let format: string;
    if (files.length === 0) {
      return;
    }

    if (files[0].type.includes('image')) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (event: any) => {
        if (files[0].size >= 10485760) {
          this.snack.showMessage(`Size exceeded`, true);
          return;
        }
      };

      const file: File = files[0];
      reader.addEventListener('load', (event: any) => {
        const newImagePath = {
          content: reader.result !== null ? reader.result : undefined,
          cover_type: index == 0 ? 'true' : 'false',
          format: 'image',
          url: event.target.result,
          order_by: index,
          file
        };
        this.allImages[index] = newImagePath;
      });
    } else {
      this.snack.showMessage('File Type Not Supported', true);
      return;
    }
  }

  updateServiceContent() {
    let promises: any[] = [];
    this.allImages.forEach(async (item: any, index: any) => {
      if (item?.file?.type) {
        promises.push(this.convertImages(item, index));
      }
    });
    Promise.all(promises).then(() => {
      let payload = {
        _id: this.selectedService._id,
        title: this.selectedService.title,
        portfolio: this.allImages.map((portfolio: any) => {
          return {
            content: portfolio.content,
            cover_type: portfolio.cover_type,
            media_type: portfolio?.media_type ? portfolio.media_type : portfolio?.format,
            order_by: portfolio.order_by,
            url: portfolio.url
          };
        })
      };
      this.talentService
        .updateService(payload)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.refreshService(this.selectedService);
          this.images.editMode = false;
          this.spinner.hide();
          if (res.status === 'success') {
            this.selectedService.title = payload.title;
            this.snack.showMessage('Content updated successfully.', false);
          }
        });
    });
  }

  async convertImages(item: any, index: any) {
    await new Promise(async resolve => {
      await this.imageService
        .imageConvertion(new ImageSnippet(item.url, item.file))
        .then((imageUri: any) => {
          const newImagePath = {
            order_by: index,
            cover_type: index == 0 ? 'true' : 'false',
            media_type: item.format ? item.format : item.media_type,
            url: imageUri[0]
          };
          this.allImages[index] = newImagePath;
        });
      resolve(true);
    });
  }

  activeInactiveTalentService(isActive: any) {
    let payload = {
      _id: this.selectedService._id,
      is_active: isActive
    };
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: {
        message: `Are you sure to ${isActive ? 'Activated' : ' deactivate'} this service?`
      }
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          this.talentService
            .patchService(payload)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              () => {
                this.selectedService.is_active = isActive;
                this.snack.showMessage(
                  this.selectedService.is_active
                    ? 'Service Activated Sucessfully'
                    : 'Service Deactivated Sucessfully',
                  false
                );
              },
              () => {
                this.snack.showMessage(
                  `Failed to ${
                    this.selectedService.is_active ? ' Active ' : ' Delete '
                  } Selected Service`,
                  true
                );
              }
            );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
