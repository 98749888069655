import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { PlanDetailComponent } from 'src/app/modules/users/pages/profile/components/plan-detail/plan-detail.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  userTypeConstant,
  planTypeConstant,
  statusConstant,
  intervalConstant
} from 'src/app/const/appConst';
import { UserPlan } from 'src/app/core/models';
import { routesConst } from 'src/app/core/const/routers';
import { ConfigurationService } from 'src/app/core/services/configuration/configuration.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { PaymentCheckoutModalComponent } from 'src/app/shared/components/payment-checkout-modal/payment-checkout-modal.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SubscriptionPackageModalComponent } from 'src/app/shared/components/subscription-package-modal/subscription-package-modal.component';
import { SwiperOptions } from 'swiper';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-dashboard-subscription',
  templateUrl: './dashboard-subscription.component.html',
  styleUrls: ['./dashboard-subscription.component.scss']
})
export class DashboardSubscriptionComponent implements OnInit {
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5
  };
  selected = new FormControl(0);
  routesConst = routesConst;
  private unsubscribe$ = new Subject();
  purchased_plans: any;
  purchased_services: any;
  platform_subscription: any;
  stripe_user: any;
  myPlans: UserPlan[] = [];
  myServices: any;
  userType: any;
  userTypeConstant = userTypeConstant;
  planTypeConstant = planTypeConstant;

  constructor(
    private dialog: MatDialog,
    private userPlan: userPlans,
    private spinner: NgxSpinnerService,
    private snack: SnackBarService,
    private stripePaymentService: paymentService,
    private configuration: ConfigurationService,
    private sharedService: SharedService,
    private profileService: ProfileService,
    private router: Router,
    private commonService: CommonService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getMyPlans();
    this.getPlateformPlan();
    this.getUserType();
    this.route.queryParams.subscribe(params => {
      const tab = params['tab'];
      if (tab === 'paid-subscriptions') {
        this.selected.setValue(2);
      }
    });
  }

  getUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === '') {
        this.sharedService.getUserType();
      } else {
        this.userType = type;
      }
    });
  }

  getMyPlans() {
    this.userPlan
      .mineSubscribedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((plans: any) => {
        if (plans?.data?.length) {
          this.myPlans = plans.data;
          this.myServices = this.myPlans;
        }
      });
  }

  getPayment() {
    this.stripePaymentService
      .getLoginLink()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status == 'Success') {
          this.commonService.getWindow()?.open(res.link.url, '_blank');
        } else {
          this.snack.showMessage(`Please Upgrade Your Membership`, true);
        }
      });
  }

  getPlateformPlan() {
    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscription: any) => {
        const { platform_subscription } = subscription;
        const { purchased_plans, purchased_services, stripe_user } = subscription.data;
        this.purchased_plans = purchased_plans;
        this.purchased_services = purchased_services;
        this.platform_subscription = platform_subscription;
        this.stripe_user = stripe_user;
      });
  }

  deletePlan(item: any) {
    if (this.userType == userTypeConstant.candidate) {
      if (
        item.active == false &&
        this.platform_subscription?.findIndex(
          (item: any) => item.plan_type.toLowerCase() == planTypeConstant.elite.toLowerCase()
        ) == -1 &&
        this.platform_subscription?.findIndex(
          (item: any) => item.plan_type.toLowerCase() == planTypeConstant.nil.toLowerCase()
        ) == -1
      ) {
        this.spinner.hide();
        this.snack.showMessage('Please upgrade your membership plan to Elite or Nil', true);
      } else {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          width: '500px',
          data: {
            message: `Are sure you want to ${item.active ? 'Deactivate' : ' Activate'} this plan ?`
          }
        });
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(result => {
            if (result) {
              this.spinner.show();
              this.userPlan
                .deletePlan(item)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  this.spinner.hide();
                  const index = this.myPlans.findIndex((x: any) => {
                    return x._id == res.data._id;
                  });
                  this.myPlans.splice(index, 1, res.data);
                  this.snack.showMessage(`${res.message}`, false);
                });
            }
          });
      }
    } else if (this.userType == userTypeConstant.company) {
      if (
        item.active == false &&
        this.platform_subscription?.findIndex(
          (item: any) => item.plan_type.toLowerCase() == planTypeConstant.enterprise.toLowerCase()
        ) == -1
      ) {
        this.spinner.hide();
        this.snack.showMessage('Please upgrade your membership plan', true);
      } else {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          width: '500px',
          data: {
            message: `Are sure you want to ${item.active ? 'Deactivate' : ' Activate'} this plan ?`
          }
        });
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(result => {
            if (result) {
              this.spinner.show();
              this.userPlan
                .deletePlan(item)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  this.spinner.hide();
                  const index = this.myPlans.findIndex((x: any) => {
                    return x._id == res.data._id;
                  });
                  this.myPlans.splice(index, 1, res.data);
                  this.snack.showMessage(`${res.message}`, false);
                });
            }
          });
      }
    }
  }

  newSubscriptionPackage() {
    this.spinner.show();
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          this.spinner.hide();
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToNewSubscriptionPackage();
        }
      });
  }

  proceedToNewSubscriptionPackage() {
    let comparePlan =
      this.userType == userTypeConstant.candidate
        ? planTypeConstant.elite
        : planTypeConstant.enterprise;
    this.spinner.show();
    this.configuration
      .configureCustomerPortal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (
          this.platform_subscription?.findIndex(
            (item: any) => item.plan_type.toLowerCase() == comparePlan.toLowerCase()
          ) != -1 ||
          (this.userType === userTypeConstant.candidate &&
            this.platform_subscription?.findIndex(
              (item: any) => item.plan_type.toLowerCase() == planTypeConstant.nil.toLowerCase()
            ) != -1)
        ) {
          const redirect_url = this.commonService.location().href;
          const refresh_url = this.commonService.location().href;
          this.userPlan
            .getStripeUser(redirect_url, refresh_url)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (user: any) => {
                this.spinner.hide();
                if (user.status == 'on_board_required') {
                  if (user?.accountLink?.url) {
                    this.commonService.location().href = user?.accountLink?.url;
                  }
                  this.snack.showMessage(`${user.message} `, true);
                } else {
                  const dialogRef = this.dialog.open(SubscriptionPackageModalComponent, {
                    width: '536px',
                    height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
                    maxWidth: '100vw',
                    hasBackdrop: false
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.getMyPlans();
                    }
                  });
                }
              },
              error => {
                this.spinner.hide();
                this.snack.showMessage(`${error} `, true);
              }
            );
        } else {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Requires membership upgrade to ELITE, NIL or ENTERPRISE`,
              header: 'Create Subscriptions'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
          this.spinner.hide();
        }
      });
  }

  // subscriptionCheckOut(plan: any) {
  //   this.spinner.show();
  //   if (plan && plan.isPlatform == false) {
  //     this.userPlan
  //       .getUserPlans(plan.profile_id)
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe((userplan: any) => {
  //         this.spinner.hide();
  //         if (userplan && userplan.plans.length) {
  //           plan.account_id = userplan.plans[0].account_id;
  //           plan.viewMode = true;
  //           this.dialog.open(PlanDetailComponent, {
  //             maxWidth: '100vw',
  //             width: '1099px',
  //             height: '884px',
  //             data: plan
  //           });
  //         }
  //       });
  //   } else {
  //     this.stripePaymentService
  //       .publicPrices()
  //       .pipe(takeUntil(this.unsubscribe$))
  //       .subscribe(
  //         (res: any) => {
  //           this.spinner.hide();
  //           if (res?.publicPrice?.length) {
  //             let avialbleProduct = res.publicPrice.find(
  //               (plans: any) => plans.id == plan.stripe_product_id
  //             );
  //             if (avialbleProduct) {
  //               avialbleProduct.features = plan.features;
  //               avialbleProduct.isAddOn = !plan.isPlan;
  //               avialbleProduct.viewMode = true;
  //               avialbleProduct.interval = plan?.interval ? plan.interval : intervalConstant?.month;
  //               const dialogRef = this.dialog.open(PaymentCheckoutModalComponent, {
  //                 maxWidth: '100vw',
  //                 width: '536px',
  //                 height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
  //                 data: avialbleProduct
  //               });
  //               dialogRef
  //                 .afterClosed()
  //                 .pipe(takeUntil(this.unsubscribe$))
  //                 .subscribe((res: any) => {
  //                   if (res?.cancelSubs?.result) {
  //                     this.getMyPlans();
  //                     const idx = this.platform_subscription.findIndex((item: any) => {
  //                       return item.stripe_product_id == res.product;
  //                     });
  //                     if (res.freePlan) {
  //                       this.platform_subscription[idx] = res.freePlan;
  //                     } else {
  //                       this.platform_subscription.splice(idx, 1);
  //                     }
  //                   }
  //                 });
  //             } else {
  //               this.snack.showMessage(`Cannot Find Such a Specific Plan`, true);
  //             }
  //           } else {
  //             this.snack.showMessage(`Cannot Find Plans...`, true);
  //           }
  //         },
  //         err => {
  //           this.snack.showMessage(`${err}`, true);
  //         }
  //       );
  //   }
  // }

  refundService(service: any) {
    this.spinner.show();
    let { package_id } = service;
    let payload = { package_id: package_id };
    this.stripePaymentService
      .sendRefundRequest(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        service['refund'] = [res.data];
        this.snack.showMessage(res.message, false);
        this.spinner.hide();
      });
  }

  checkUserPlan(acceptedPlan1: any, acceptedPlan2?: any) {
    return this.platform_subscription?.findIndex(
      (item: any) => item?.plan_type?.toLowerCase() == acceptedPlan1?.toLowerCase()
    ) != -1 ||
      (this.userType === userTypeConstant.candidate &&
        this.platform_subscription?.findIndex(
          (item: any) => item?.plan_type?.toLowerCase() == acceptedPlan2?.toLowerCase()
        ) != -1)
      ? false
      : true;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
