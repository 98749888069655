<div class="service-order-unit flex items-center justify-between my-4">
	<div>
		<p>Max Lorens</p>
		<p class="s black-light mt-1">maxlorens@gmail.com</p>
	</div>
	<div class="text-right">
		<p class="montserrat-bold">$180.00</p>
		<p class="s black-light mt-1">PRO Package</p>
	</div>
</div>
<mat-divider></mat-divider>
