<div class="operation-hour-unit.component">
  <p class="montserrat-bold black-dark">
    {{ day.day_name }}
  </p>
  <div class="grid md:grid-cols-3 gap-1 mt-2">
    <div
      class="flex items-center"
      [class]="day.closed ? 'opacity-30 pointer-events-none' : ''"
    >
      <p>From</p>
      <div class="ml-2">
        <input
          class="border-normal rounded px-4 py-3 w-28"
          aria-label="default time"
          [ngxTimepicker]="from"
          [value]="day.from_time"
          readonly
        />
        <ngx-material-timepicker
        (closed)="onClose('from_time')"
          #from
          [defaultTime]="day.from_time"
          (timeChanged)="setTime($event, 'from_time')"
        ></ngx-material-timepicker>
      </div>
    </div>
    <div
      class="flex items-center"
      [class]="day.closed ? 'opacity-30 pointer-events-none' : ''"
    >
      <p class="ml-4 md:ml-0 pl-1 md:pl-0">To</p>
      <div class="ml-3">
        <input
          class="border-normal rounded px-4 py-3 w-28"
          aria-label="default time"
          [ngxTimepicker]="to"
          [value]="day.to_time"
          readonly
        />
        <ngx-material-timepicker
        (closed)="onClose('to_time')"
          #to
          [defaultTime]="day.to_time"
          (timeChanged)="setTime($event, 'to_time')"
        ></ngx-material-timepicker>
      </div>
    </div>
    <div class="">
      <mat-checkbox
        class="pt-1"
        [color]="'primary'"
        (change)="setMarkDay($event)"
        [checked]="day.closed"
        ><p>Mark day as closed</p></mat-checkbox
      >
    </div>
  </div>
</div>
