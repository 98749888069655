// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe: 'DEV-STRIPE',
  version: new Date().toLocaleDateString('en-US'),
  firebaseConfig : {
    apiKey: "AIzaSyBS8NsoXVXti0TqOYWyG6PImxK6Ojw5MIQ",
    authDomain: "wellnestlifejobs.firebaseapp.com",
    databaseURL: "https://wellnestlifejobs.firebaseio.com",
    projectId: "wellnestlifejobs",
    storageBucket: "wellnestlifejobs.appspot.com",
    messagingSenderId: "452476998091",
    appId: "1:452476998091:web:044175b674e0bd696da64e",
    measurementId: "G-TWBBSFC3FZ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
