import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { PaymentCheckoutModalComponent } from 'src/app/shared/components/payment-checkout-modal/payment-checkout-modal.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { userTypeConstant, planTypeConstant } from 'src/app/const/appConst';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { routesConst } from 'src/app/core/const/routers';

@Component({
  selector: 'app-payment-option-card',
  templateUrl: './payment-option-card.component.html',
  styleUrls: ['./payment-option-card.component.scss']
})
export class PaymentOptionCardComponent implements OnInit, OnChanges {
  private unsubscribe$ = new Subject();
  @Input() planList: any;
  @Input() cardheights: any;
  @Input() getFromLocalStorage: any;
  @Input() pkgPlan: string | undefined;
  @Input() isDisabled?: boolean;
  @Input() isActive: boolean;
  @Input() stripe_product_id: any;
  @Input() allowdedPlans: any;
  @Input() user_type: string | undefined;
  @Input() isUserCreatedPlan: boolean | undefined;
  @Input() type?: string = '';
  @Output() showSpinner: EventEmitter<Boolean> = new EventEmitter();
  @Output() purchaseProfilePlan: EventEmitter<Boolean> = new EventEmitter();
  purchasedPlan: any;
  purchasedAddOn: any[] = [];
  features: any;
  userType: any;
  planClicked = false;
  stripeUser: any;
  routesConst = routesConst

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private snackService: SnackBarService,
    private stripePaymentService: paymentService,
    private profileService: ProfileService,
    private talentService: TalentService,
    private spinner: NgxSpinnerService,
    private userPlan: userPlans,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isActive = false;
  }

  ngOnInit(): void {
    this.getuserActivatedPlan();
    if (this.authService.loggedIn()) {
      this.profileService
        .getpurchasedPlan()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((userPurchased: any) => {
          this.profileService.purchasedPlan.next(userPurchased.currentPlan);
          this.profileService.purchasedAddOn.next(userPurchased.currentAddOns);
          this.stripeUser = userPurchased.stripeUser;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user_type']?.currentValue) {
      this.userType = changes['user_type'].currentValue;
    }
    if (changes['getFromLocalStorage']?.currentValue && !this.planClicked) {
      const redirect_url = window.location.href
      const refresh_url = window.location.href
      this.talentService
        .getPlatformStripeUser(redirect_url, refresh_url)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          if (res.status == 'on_board_required') {
            localStorage.removeItem('plan_to_purchase');
          } else {
            this.getPlanFromLocalStorage();
          }
        });
    }

    if (changes['planList']?.currentValue[0]?.isPlan) {
      const storage: any = localStorage.getItem('purchase_plan_type');
      const purchase_plan_type = JSON.parse(storage);

      if (this.userType === userTypeConstant.company) {
        if (purchase_plan_type?.plan_type == 'Recruiter') {
          let recruiterPlan = this.planList.find((item: any) => item.plan_type == 'Recruiter');
          this.upgrade(recruiterPlan, false);
        }
      }
      localStorage.removeItem('purchase_plan_type');
    }
  }

  getPlanFromLocalStorage() {
    const storage: any = localStorage.getItem('plan_to_purchase');
    const plan_to_purchase = JSON.parse(storage);
    if (plan_to_purchase) {
      const { stripe_product_id, isPlan, deleteAllPlan, isTrial, consumer_type } = plan_to_purchase;
      if (this.getFromLocalStorage) {
        this.paymentCheckout(stripe_product_id, isPlan, consumer_type, isTrial, deleteAllPlan);
      }
    }
  }

  getuserActivatedPlan() {
    this.profileService.purchasedPlan.pipe(takeUntil(this.unsubscribe$)).subscribe((plan: any) => {
      this.purchasedPlan = plan;
    });
    this.profileService.purchasedAddOn
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((addon: any) => {
        this.purchasedAddOn = addon;
      });
  }

  async upgrade(plan: any, isTrial: any, allowdedPlans?: any) {
    // let { stripe_product_id, isPlan, consumer_type, features } = plan
    // this.features = features;
    if (plan.isUserCreatedPlan) {
      this.purchaseProfilePlan.emit(plan);
      return;
    }
    this.showSpinner.emit(true);
    if (!this.authService.loggedIn()) {
      this.showSpinner.emit(false);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
      return;
    }
    this.proceedToUpgrade(plan, isTrial, allowdedPlans);
  }

  proceedToUpgrade(plan: any, isTrial: any, allowdedPlans: any) {
    let { stripe_product_id, isPlan, consumer_type, plan_type, features, prerequisite } = plan;
    this.features = features;

    this.userPlan
      .userPlanSubscription()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((subscription: any) => {
        let { platform_subscription } = subscription;
        let planToPurchase = plan_type;
        let purchasedPlan = platform_subscription[0]?.plan_type;

        // COMPANY DOWNGRADE

        if (
          purchasedPlan == planTypeConstant.business &&
          planToPurchase == planTypeConstant.recruiter
        ) {
          let message = 'All created expert services will be deactivated';
          let plansToDeactivate = ['expert_services'];
          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }

        if (
          purchasedPlan == planTypeConstant.enterprise &&
          planToPurchase == planTypeConstant.business
        ) {
          let message = 'All created subscriber plans will be deactivated';
          let plansToDeactivate = ['subscriber_plans'];
          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }

        // INDIVIDUAL DOWNGRADE

        if (purchasedPlan == planTypeConstant.pro && planToPurchase == planTypeConstant.consumer) {
          let message = 'All created expert services will be deactivated';
          let plansToDeactivate = ['expert_services'];

          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }

        if (purchasedPlan == planTypeConstant.elite && planToPurchase == planTypeConstant.pro) {
          let message = 'All created subscriber plans will be deactivated';
          let plansToDeactivate = ['subscriber_plans'];

          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }

        if (purchasedPlan == planTypeConstant.nil && planToPurchase == planTypeConstant.consumer) {
          let message = 'All created subscriber plans will be deactivated';
          let plansToDeactivate = ['subscriber_plans'];
          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }

        if (
          purchasedPlan == planTypeConstant.elite &&
          planToPurchase == planTypeConstant.consumer
        ) {
          let message = 'All created expert services and subscriber plans will be deactivated';
          let plansToDeactivate = ['expert_services', 'subscriber_plans'];

          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }

        if (purchasedPlan == planTypeConstant.elite && planToPurchase == planTypeConstant.nil) {
          let message = 'All created expert services will be deactivated';
          let plansToDeactivate = ['expert_services'];

          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }

        if (purchasedPlan == planTypeConstant.pro && planToPurchase == planTypeConstant.nil) {
          let message = 'All created expert services will be deactivated';
          let plansToDeactivate = ['expert_services'];

          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }

        if (purchasedPlan == planTypeConstant.nil && planToPurchase == planTypeConstant.pro) {
          let message = 'All created subscriber plans will be deactivated';
          let plansToDeactivate = ['subscriber_plans'];

          this.confirmation(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            message,
            plansToDeactivate
          );
          return;
        }
        this.purchaseSubscription(
          stripe_product_id,
          isPlan,
          consumer_type,
          allowdedPlans,
          prerequisite,
          isTrial
        );
      });
  }

  confirmation(
    stripe_product_id: any,
    isPlan: any,
    consumer_type: any,
    allowdedPlans: any,
    prerequisite: any,
    isTrial: any,
    message: any,
    plansToDeactivate: any
  ) {
    this.showSpinner.emit(false);
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: { message }
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          this.purchaseSubscription(
            stripe_product_id,
            isPlan,
            consumer_type,
            allowdedPlans,
            prerequisite,
            isTrial,
            plansToDeactivate
          );
        } else {
          this.showSpinner.emit(false);
        }
      });
  }

  purchaseSubscription(
    stripe_product_id: string,
    isPlan: any,
    consumer_type: any,
    allowdedPlans: any,
    prerequisite: any,
    isTrial: any,
    plansToDeactivate?: any
  ) {
    let storePlan = {
      stripe_product_id,
      isPlan,
      plansToDeactivate,
      isTrial,
      consumer_type
    };
    localStorage.setItem('plan_to_purchase', JSON.stringify(storePlan));
    this.showSpinner.emit(true);
    this.spinner.show();
    const redirect_url = window.location.href
    const refresh_url = window.location.href
    this.talentService
      .getPlatformStripeUser(redirect_url, refresh_url)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status == 'on_board_required') {
          this.snackService.showMessage(`${res.message}`, true);
          this.showSpinner.emit(false);
          window.location.href = res.accountLink.url;
        } else {
          this.spinner.hide();
          if (allowdedPlans?.length) {
            if (!allowdedPlans.includes(this.purchasedPlan?._id)) {
              this.showSpinner.emit(false);
              this.snackService.showMessage(
                `Your Current plan Cannot Subscribe the Selected Addon ${prerequisite}`,
                true
              );
              return;
            }
            this.showSpinner.emit(false);
          }
          this.paymentCheckout(
            stripe_product_id,
            isPlan,
            consumer_type,
            isTrial,
            plansToDeactivate
          );
        }
      });
  }

  paymentCheckout(
    stripe_product_id: string,
    isPlan: any,
    consumer_type: any,
    isTrial: any,
    plansToDeactivate?: any
  ) {
    if (consumer_type == this.userType) {
      if (!isPlan) {
        if (this.userType === userTypeConstant.company) {
          this.profileService
            .getpurchasedPlan()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: any) => {
              if (res?.currentAddOns?.length > 0) {
                this.snackService.showMessage(
                  `Please Unsubscribe Current Selected Add on to Purchase this Add on`,
                  true
                );
                this.router.navigate(['/dashboard/subscriptions']);

                this.showSpinner.emit(false);
              } else {
                this.purchaseAddon(stripe_product_id);
              }
            });
        } else {
          this.purchaseAddon(stripe_product_id);
        }
      } else {
        this.talentService.checkTrialPeriod().subscribe((res: any) => {
          if (!res.data && isTrial) {
            this.spinner.hide();
            this.snackService.showMessage(res.message, true);
          } else {
            this.showSpinner.emit(true);
            this.stripePaymentService
              .publicPrices()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                (res: any) => {
                  if (res?.publicPrice?.length) {
                    let avialbleProduct = res.publicPrice.find(
                      (plan: any) => plan.id == stripe_product_id
                    );
                    if (avialbleProduct) {
                      this.showSpinner.emit(false);
                      avialbleProduct.features = this.features;
                      avialbleProduct.isAddOn = false;
                      avialbleProduct.isTrial = isTrial;
                      this.spinner.hide();
                      const dialogRef = this.dialog.open(PaymentCheckoutModalComponent, {
                        maxWidth: '100vw',
                        width: '536px',
                        height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
                        data: {avialbleProduct}
                      });
                      localStorage.removeItem('plan_to_purchase');

                      dialogRef
                        .afterClosed()
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe((result: any) => {
                          if (result && plansToDeactivate?.length) {
                            this.userPlan
                              .deleteMultiplePlan(plansToDeactivate)
                              .pipe(takeUntil(this.unsubscribe$))
                              .subscribe(() => {
                                this.spinner.hide();
                              });
                          }
                          if (result) {
                            this.stripeUser.is_trialing = isTrial;
                            this.profileService
                              .getpurchasedPlan()
                              .pipe(takeUntil(this.unsubscribe$))
                              .subscribe((userPurchased: any) => {
                                this.stripeUser = userPurchased.stripeUser;
                              });
                          }
                        });
                    } else {
                      this.spinner.hide();
                      this.snackService.showMessage(`Plan not Available`, true);
                    }
                  } else {
                    this.spinner.hide();
                    this.snackService.showMessage(`Unable to Find Plan Prices`, true);
                  }
                },
                err => {
                  this.spinner.hide();
                  this.snackService.showMessage(`${err}`, true);
                }
              );
          }
        });
      }
    } else {
      this.spinner.hide();
      this.snackService.showMessage('You are not able to Purchase this Membership', true);
    }
  }

  puchasedAddon(title: any) {
    return this.purchasedAddOn.findIndex((item: any) => item.title.toLowerCase() == title) != -1
      ? true
      : false;
  }

  purchaseAddon(stripe_product_id: any) {
    this.showSpinner.emit(true);
    this.spinner.show();
    this.talentService
      .getMember()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status == 'Failed') {
          this.snackService.showMessage(`${res.message}`, true);
          this.showSpinner.emit(false);
        } else {
          this.stripePaymentService
            .publicPrices()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res: any) => {
                if (res?.publicPrice?.length) {
                  let avialbleProduct = res.publicPrice.find(
                    (plan: any) => plan.id == stripe_product_id
                  );
                  if (avialbleProduct) {
                    this.showSpinner.emit(false);
                    avialbleProduct.features = this.features;
                    avialbleProduct.isAddOn = true;
                    this.spinner.hide();
                    this.dialog.open(PaymentCheckoutModalComponent, {
                      maxWidth: '100vw',
                      width: '536px',
                      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
                      data: {avialbleProduct}
                    });
                    localStorage.removeItem('plan_to_purchase');
                  } else {
                    this.showSpinner.emit(false);
                    this.snackService.showMessage(`Plan not Available`, true);
                  }
                } else {
                  this.snackService.showMessage(`Unable to Find Plan Prices`, true);
                }
              },
              err => {
                this.showSpinner.emit(false);
                this.snackService.showMessage(`${err}`, true);
              }
            );
        }
      });
  }

  getDescription(item: any) {
    const maxLength = 140;
    item.isBigger = maxLength < item.description.length;
    return item.isMore && maxLength < item.description.length
      ? item.description.substring(0, maxLength)
      : item.description;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
