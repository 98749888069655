import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { intervalConstant } from 'src/app/const/appConst';
import { planTypeConstant } from 'src/app/const/appConst';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { routesConst } from 'src/app/core/const/routers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-checkout-modal',
  templateUrl: './payment-checkout-modal.component.html',
  styleUrls: ['./payment-checkout-modal.component.scss'],
})
export class PaymentCheckoutModalComponent implements OnInit {
  intervalConstant = intervalConstant;
  selectedInterval = intervalConstant.month;
  private unsubscribe$ = new Subject();
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  stripeCardValid: boolean = true;
  selectedOption = 'default';
  defaultPaymentExist = false;
  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  stripePurchase: any;
  cancelPlatformSubs: any;
  subTotal: any;
  stripeFee!: number;
  stripePurchaseAddOn: any;
  planTypeConstant = planTypeConstant;
  isNewUser:any = 'false'

  currentSubscription:any;

  constructor(
    public dialogRef: MatDialogRef<PaymentCheckoutModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private stripePaymentService: paymentService,
    private snack: SnackBarService,
    public dialog: MatDialog,
    private stripService: StripeService,
    private spinner: NgxSpinnerService,
    private profileService: ProfileService,
    private userPlan: userPlans,
    private router: Router,
  ) {
    this.stripePurchase = {
      new_payment_method_id: '',
      plan: '',
      product_id: '',
      selectedInterval: this.selectedInterval,
    };
    this.stripePurchaseAddOn = {
      addons: [],
    };
    this.cancelPlatformSubs = {
      new_payment_method_id: '',
      product: '',
    };
  }

  ngOnInit() {
    this.currentSubscription = this.data?.currentSubscription
    this.isNewUser = localStorage.getItem('isNewUser');
    this.stripePurchase.plan = this.data?.avialbleProduct?.default_price;
    this.data.avialbleProduct.prices.find((x: any) => {
      if (x.recurring.interval == intervalConstant?.month) {
        this.subTotal = (x.unit_amount / 100 - +x.nickname).toFixed(2);
        this.stripeFee = +x.nickname;
      }
    });

    this.stripePaymentService.accountSettings().subscribe((response: any) => {
      const invoiceSettings = response?.settings?.invoice_settings;
    
      if (invoiceSettings && invoiceSettings.default_payment_method) {
        this.selectedOption = 'default';
      } else {
        this.selectedOption = 'new';
      }
    });
    
  }

  subscribeAndCheckout() {
    this.spinner.show();
    if (this.data.avialbleProduct.isAddOn) {
      this.stripePurchaseAddOn.addons.push(this.data?.avialbleProduct?.default_price);
      this.stripePaymentService
        .purchaseAddOn(this.stripePurchaseAddOn, this.data?.avialbleProduct.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (purchased: any) => {
            if (purchased.status == 'Success') {
              this.router.navigate(['/' + routesConst.dashboard + '/' + routesConst.referrals]);
              this.profileService
                .getpurchasedPlan()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((res: any) => {
                  this.profileService.purchasedPlan.next(res.currentPlan);
                  this.profileService.purchasedAddOn.next(res.currentAddOns);
                  this.spinner.hide();
                  this.dialogRef.close();
                  this.showSnackBarMessage(purchased.message, false);
                });
            } else {
              this.spinner.hide();
              this.dialogRef.close();
              this.showSnackBarMessage(`${purchased?.message}`, true);
            }
          },
          (err) => {
            this.spinner.hide();
            this.dialogRef.close();
            this.showSnackBarMessage(`${err.message}`, true);
          }
        );
    } else {
      if (this.selectedOption == 'default') {
        this.stripePurchase.new_payment_method_id = null;
        this.stripePurchase.product_id = this.data?.avialbleProduct.id;
        this.stripePurchase.selectedInterval = this.selectedInterval;
        this.stripePurchase.isTrialPurchase = this.data?.avialbleProduct.isTrial;
        this.makePayment();
      } else if (this.selectedOption == 'new') {
        this.stripService
          .createPaymentMethod({
            type: 'card',
            card: this.card.element,
          })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((stripeElement) => {
            if (stripeElement) {
              this.stripePurchase.new_payment_method_id =
                stripeElement?.paymentMethod?.id;
              this.stripePurchase.product_id = this.data?.avialbleProduct.id;
              this.stripePurchase.selectedInterval = this.selectedInterval;
              this.stripePurchase.isTrialPurchase = this.data?.avialbleProduct.isTrial;
            } else {
              this.spinner.hide();
            }
            this.makePayment();
          });
      }
    }
  }

  makePayment() {
    this.spinner.show();
    this.stripePaymentService
      .purchaseSubscription(this.stripePurchase)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.activated_plan || res.status == 'Success') {
            this.profileService.purchasedPlan.next(res.activated_plan);
            this.spinner.hide();
            this.dialogRef.close(res.status == 'Success' ? true : false);
            this.showSnackBarMessage(
              res.activated_plan && !res.purchaseProduct
                ? `Thank you For Purchasing ${this.data?.avialbleProduct.name} Plan`
                : res.purchaseProduct
                  ? `${res.message}`
                  : `${res.message}`,
              false
            );
            if(this.isNewUser == 'true'){
              this.router.navigate(['/' + routesConst.addonPricing]);
            }
          } else {
            this.spinner.hide();
            this.dialogRef.close();
            this.showSnackBarMessage(res.purchaseProduct.message, true);
          }
        },
        (err) => {
          this.spinner.hide();
          this.dialogRef.close();
          this.showSnackBarMessage(`${err}`, true);
        }
      );
  }

  onChange(event: any) {
    this.stripeCardValid = event.complete;
  }

  paymentChange() {
    if (this.selectedOption === 'default') {
      this.stripeCardValid = true;
    } else {
      this.stripeCardValid = false;
    }
  }

  selectPaymentInterval(event: any) {
    this.selectedInterval = event.recurring?.interval;
    this.stripePurchase.plan = event?.id;
    this.subTotal = +(event.unit_amount / 100 - +event.nickname).toFixed(2);
    this.stripeFee = +event.nickname;
  }

  unSubscribe() {
    if (this.data?.avialbleProduct.isAddOn) {
      this.spinner.show();
      this.stripePaymentService
        .cancelPlatformSubscriptionAddon(this.data?.avialbleProduct.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((res: any) => {
          this.spinner.hide();
          this.dialogRef.close(res);
          if (res.cancelSubs.result) {
            this.showSnackBarMessage('Addon Unsubscribed', false);
          } else {
            this.showSnackBarMessage(`${res.message}`, true);
          }
        });
    } else {
      let message =
        'All created expert services and subscriber plans will be deactivated';
      let plansToDeactivate = ['expert_services', 'subscriber_plans'];
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '500px',
        data: { message },
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            this.spinner.show();
            this.cancelPlatformSubs.product = this.data?.avialbleProduct.id;
            this.stripePaymentService
              .cancelPlatformSubscription(this.cancelPlatformSubs)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.cancelSubs.result) {
                  this.userPlan
                    .deleteMultiplePlan(plansToDeactivate)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((result) => {
                      this.spinner.hide();
                      this.showSnackBarMessage('Plan Unsubscribed', false);
                      this.dialogRef.close(res);
                    });
                } else {
                  this.spinner.hide();
                  this.showSnackBarMessage(`${res.message}`, false);
                }
              });
          }
        });
    }
  }

  /**
   * Show Snack Bar Message
   * @param message
   */
  showSnackBarMessage(message: String, errortype: boolean) {
    this.snack.showMessage(`${message}`, errortype);
  }

  getTotal() {
    return (+this.subTotal + +this.stripeFee).toFixed(2);
  }

  getStripeFee(amount: any) {
    return amount * (2.9 / 100);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
