import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from 'src/config/config';
import { apiUrlConst } from 'src/app/const/apiConst';
import { Plan } from 'src/app/core/models/model';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  constructor(public _http: HttpClient, private commonService: CommonService) { }

  getFollowersAndFollowings() {
    return this._http.get(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.getFollowersandFollowings);
  }

  getFollowers(filter: any) {
    return this._http.post(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.getFollowers, filter);
  }

  getFollowingList(filter: any) {
    return this._http.post(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.getFollowings, filter);
  }

  getSubscribers(filter: any) {
    return this._http.post(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.getSubscribers, filter);
  }

  getFollowings() {
    return this._http.get(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.getFollowing);
  }

  followUser(profile_id: string) {
    return this._http.post(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.followUser, { profile_id });
  }

  unFollow(profile_id: string) {
    return this._http.post(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.unfollowUser, { profile_id });
  }

  getPlanList() {
    return this._http.get(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.getPlanList);
  }

  addPlan(plan: Plan) {
    return this._http.post(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.addPlan,
      {
        price: plan.price,
        title: plan.title,
        user_id: plan._id,
        sub_title: plan.sub_title,
        features: plan.features,
        description: plan.description,
        feature_title: plan.feature_title,
        parent_plan_id: null,
        percentage: plan.percentage,
        plan_type: plan.plan_type,
      }
    )
  }

  updatePlan(plan: Plan) {
    return this._http.put(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.updatePlan,
      {
        _id: plan._id,
        price: plan.price,
        title: plan.title,
        user_id: plan._id,
        sub_title: plan.sub_title,
        features: plan.features,
        description: plan.description,
        feature_title: plan.feature_title,
        parent_plan_id: null,
        percentage: plan.percentage,
        plan_type: plan.plan_type,
      }
    )
  }

  deltePlan(id: string) {
    return this._http.delete(config.environmentMode(this.commonService.location().hostname).endPoint + apiUrlConst.deletePlan,
      {
        params: {
          _id: id
        }
      }
    )
  }
}
