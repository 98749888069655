<div class="part-workflow mx-auto">
  <div id="workflow" class="flex flex-col w-full mx-auto px-6 lg:px-0">
    <p class="what-you-get-heading">HOW IT WORKS</p>
    <p class="promote-heading">Four ways to Network & earn online </p>
    <p class="heading-underline"></p>
  </div>
  <app-pakages-card [data]="workFlows"></app-pakages-card>

  <!-- <app-part-home [data]="partWorkFlow"></app-part-home> -->
</div>
