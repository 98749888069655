import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PostService } from 'src/app/core/services/post.service';
@Component({
  selector: 'app-profile-home',
  templateUrl: './profile-home.component.html',
  styleUrls: ['./profile-home.component.scss'],
})
export class ProfileHomeComponent implements OnInit {
  private unsubscribe$=new Subject();
  @Input() set user_name(name: string) {
    this.getPosts(name);
  }

  myPosts: any;

  constructor(
    private postService: PostService,
  ) { }

  ngOnInit(): void {
  }

  getPosts(user_name: any) {
    this.postService.getPublicPosts(user_name).pipe(takeUntil(this.unsubscribe$)).subscribe((post: any) => {
      this.myPosts = post.data;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
}

}
