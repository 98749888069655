import { Component, OnInit } from '@angular/core';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss']
})
export class ExploreComponent implements OnInit {
  routesConst = routesConst;
  isLoggedIn = false;

  partExploreHeaderData = {
    styling: {
      'max-width': '510px'
    },
    class: 'explore-heading-width',
    heading: 'Accelerate growth & get what',
    blueheading: ' you deserve',
    description: 'Whether you’re a influencer, entrepreneur, expert or just exploring your interests this is the place for you.',
    button: { text: 'Try 3 Months Free', buttonLink: false },
    image: {
      src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/rs7Qk.jpeg',
      redirectUrl: ''
    }
  }

  explore = [
    {
      headingStyle: {
        'max-width': innerWidth && innerWidth < 768 ? '350px' : ''
      },
      section: 'growYourBrand',
      imagePosition: 'right',
      superHeading: 'GROW YOUR BRAND',
      divider: false,
      mainHeading: 'Creators, athletes, experts & entrepreneurs',
      description: { content: 'Sell, create and earn all on one platform from your profile.' },
      list: ['Increase paid subscriber audience', 'Learn more from industry experts', 'Own 100% of your content', 'No max seller prices ', 'Instant payout'],
      button: [{ text: 'See Social', buttonLink: routesConst.community }],
      image: [{
        src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_31.png',
        redirectUrl: ''
      }]
    },

    {
      section: 'weValueReferrals',
      descWidth: 'referrals-desc-width',
      imagePosition: 'left',
      superHeading: 'WE VALUE REFERRALS',
      divider: false,
      mainHeading: 'Our members Earn 10% revenue share',
      description: { content: 'Refer Elite members earn 10% revenue share per total subscriber income up to 12 months.', styling: 'lg:max-w-xl' },
      list: ['Select referral membership add on', 'Leverage social influence across all platforms', 'Share referral link to social & business networks', 'Get consistent revenue per referral memberships sold',],
      button: [{ text: 'Get Referrals', buttonLink: '' }],
      image: [{
        src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_Web+Photos_6.png',
        redirectUrl: ''
      }]
    },

    {
      section: 'increaseYourProfits',
      imagePosition: 'right',
      superHeading: 'INCREASE YOUR PROFITS',
      divider: false,
      mainHeading: 'Companies, recruiters & job seekers',
      description: { content: 'Recruit talent, apply on jobs and get hired on one platform', styling: 'lg:max-w-xl' },
      list: ['Unlimited job posting feature','Manage jobs and applications', 'Message job seekers & applicants', 'See job skills and requirements', 'See weekly pay range for all jobs'],
      button: [{ text: 'See Jobs', buttonLink: routesConst.jobs }],
      image: [{
        // src: 'https://jobprosmedia.s3.us-west-1.amazonaws.com/JOBPros_web_photos26.png',
        src: 'https://wellnestlifeimages.s3.us-west-1.amazonaws.com/afHkn.png',
        redirectUrl: ''
      }]
    }
  ];

  exploreRangeSliderData = {
    superheading: 'Create & Earn Online',
    heading: 'How many customers will you have?',
    description: 'Just estimate and we will give you features and tools to grow.',
    subheading: 'How much will you charge?',
    subdescription: 'Create paid access to photos and videos or expert services. Sell, create & earn on JOBPros.',
    income: 'Monthly Income',
    getTotal: 1
  }

  // links = [
  //   {
  //     url: 'https://www.facebook.com/jobpros.io',
  //     class: 'facebook',
  //   },
  //   {
  //     url: 'https://twitter.com/JobprosHQ/',
  //     class: 'twitter',
  //   },
  //   {
  //     url: 'https://www.instagram.com/jobpros.io/',
  //     class: 'instagram',
  //   },
  //   {
  //     url: 'https://www.linkedin.com/company/jobpros-inc',
  //     class: 'linkedin',
  //   },
  //   {
  //     url: 'https://www.youtube.com/channel/UCGOx8v2Q2q3vgrsQThtWboQ/featured',
  //     class: 'youtube',
  //   },
  //   {
  //     url: 'https://medium.com/@jobpros',
  //     class: 'medium',
  //   },
  // ];

  constructor(private router: Router, private dialog: MatDialog, private authService: AuthService,) { }

  ngOnInit(): void {
    // this.isLoggedIn = this.authService.loggedIn();
  }

  // openSignUp(pageToOpen: string) {
  //   this.router.navigate(['/'])
  //   this.dialog.open(LoginSignupDialogComponent, {
  //     width: '400px',
  //     height: 'h-fit',
  //     maxHeight: '600',
  //     data: { pageToOpen },
  //   });
  // }

}

