<div class="px-4 lg:px-0 pt-0 dashboard-payment">
  <div class="flex items-end w-full justify-between">
    <h1 class="montserrat-bold mr-auto text-style window-mode">Services</h1>
    <div *ngIf="stepperOpen" (click)="back()" class="mr-auto cursor-pointer go-back">
      <button mat-button class="button outlined black">
        <mat-icon class="mb-1">keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <!-- <div class="ml-auto">
      <app-view-profile-button [url]="routesConst.talents" [label]="'Experts'">
      </app-view-profile-button>
    </div> -->
  </div>
  <div class="mt-4">
    <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container dashboard-services-swipper common-arrow"
    >
        <ng-template swiperSlide>
          <div class="flex items-center tab swiper-tab" (click)="selected.value = 0" [class]="selected.value === 0 ? 'selected' : ''">
            <p  class="tab-size" [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
              Services
            </p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div>
            <div class="flex items-center tab swiper-tab" (click)="selected.value = 1" [class]="selected.value === 1 ? 'selected' : ''">
              <p class="tab-size" [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">
                {{user_type === userTypeConstant.candidate ? "Contact" :'Contact'}}
              </p>
            </div>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div (click)="getPayment()" class="flex items-center tab swiper-tab" (click)="selected.value = 2" [class]="selected.value === 2 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">
              Orders
            </p>
          </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="flex items-center tab swiper-tab" (click)="selected.value = 3" [class]="selected.value === 3 ? 'selected' : ''">
          <p class="tab-size" [class]="selected.value === 3 ? 'blue montserrat-bold' : ''">
            Paid
          </p>
        </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="flex items-center tab swiper-tab" (click)="selected.value = 4" [class]="selected.value === 4 ? 'selected' : ''">
        <p class="tab-size" [class]="selected.value === 4 ? 'blue montserrat-bold' : ''">
          Sold
        </p>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="flex items-center tab swiper-tab" (click)="selected.value = 5" [class]="selected.value === 5 ? 'selected' : ''">
        <p class="tab-size" [class]="selected.value === 5 ? 'blue montserrat-bold' : ''">
          Reviews
        </p>
      </div>
    </ng-template>
    </swiper>
  </div>
  <div class="tabs">
    <mat-tab-group class="service-tabs" color="#fff0" [selectedIndex]="selected.value" (selectedIndexChange)="selected.setValue($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
            <p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">
              Services
            </p>
          </div>
        </ng-template>
        <app-dashboard-services-services (isStepperOpen)="isStepperOpen($event)" [goBack]="isGoBack"
          [user_type]="user_type" [platformPlans]="mySubscriptions"></app-dashboard-services-services>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 1 ? 'selected' : ''">
              <p [class]="selected.value === 1 ? 'blue montserrat-bold' : ''" matTooltip="Coming Soon">

                {{user_type === userTypeConstant.candidate ? "User" :'Company'}} Info

                
                <!-- Work Orders -->
              </p>
            </div>
          </div>
        </ng-template>
        <app-dashboard-services-preferences [experienceLevel]="experienceLevel"></app-dashboard-services-preferences>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
            <div (click)="getPayment()" class="flex items-center pb-1 tab" [class]="selected.value === 2 ? 'selected' : ''">
              <p [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">
                <!-- Paid Services -->
                Work Orders

              </p>
            </div>
        </ng-template>
        <!-- <app-service-order-unit></app-service-order-unit> -->
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 3 ? 'selected' : ''">
              <p [class]="selected.value === 3 ? 'blue montserrat-bold' : ''">
                Paid Services
              </p>
            </div>
        </ng-template>
        <app-dashboard-paid-services></app-dashboard-paid-services>

      </mat-tab>
      
      <mat-tab>
        <ng-template mat-tab-label>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 4 ? 'selected' : ''">
              <p [class]="selected.value === 4 ? 'blue montserrat-bold' : ''">
                Sold Services
              </p>
            </div>
        </ng-template>
        <app-dashboard-sold-services></app-dashboard-sold-services>

      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 5 ? 'selected' : ''">
              <p [class]="selected.value === 5 ? 'blue montserrat-bold' : ''">
                Reviews
              </p>
            </div>
        </ng-template>
        <app-dashboard-services-reviews></app-dashboard-services-reviews>

      </mat-tab>
      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 4 ? 'selected' : ''">
            <p [class]="selected.value === 4 ? 'blue montserrat-bold' : ''">
              {{user_type === userTypeConstant.candidate ? "User" :'Company'}} Info
            </p>
          </div>
        </ng-template>
        <app-dashboard-services-preferences [experienceLevel]="experienceLevel"></app-dashboard-services-preferences>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</div>