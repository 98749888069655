import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
    localStorage.removeItem('isNewUser');
    setTimeout(() => {
      window.scroll(0, 0)
    }, 500);
  }
  
}
