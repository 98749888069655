
<div class="sidenav-footer my-5">
  <!-- <div class="sidenav-footer-links row mx-0">
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a
        href="https://www.instagram.com/jobpros.io/"
        class="montserrat-medium"
        target="_blank"
        >Instagram</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a
        href="https://twitter.com/JobprosHQ"
        class="montserrat-medium"
        target="_blank"
        >Twitter</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a
        href="https://www.facebook.com/jobpros.io"
        target="_blank"
        class="montserrat-medium"
        >Meta</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a
        href="https://medium.com/@jobpros"
        target="_blank"
        class="montserrat-medium"
        >Blog</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a
        class="montserrat-medium"
        href="https://www.youtube.com/channel/UCGOx8v2Q2q3vgrsQThtWboQ "
        target="_blank"
        >YouTube</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a
        disabled
        class="montserrat-medium cursor-not-allowed"
        >Support</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a disabled class="montserrat-medium cursor-not-allowed">Press</a>
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a href="mailto:support@jobpros.io" class="montserrat-medium"
        >Contact Us</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a [href]="'/' + routesConst.privacy" class="montserrat-medium"
        >Privacy</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a [href]="'/' + routesConst.terms" class="montserrat-medium">Terms</a>
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a href="{{ '/' + routesConst.dsar }}" class="montserrat-medium"
        >Cookies</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a href="{{ '/' + routesConst.dsn }}" class="montserrat-medium">
        Do Not Sell
      </a>
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a href="{{ '/' + routesConst.disclaimer }}" class="montserrat-medium"
        >Disclaimer</a
      >
    </div>
    <div
      class="d-flex flex-column col-6 pr-2 py-2 md:py-4 align-items-start sidenav-footer-link"
    >
      <a href="{{ '/' + routesConst.preferences }}" class="montserrat-medium"
        >Preferences</a
      >
    </div>
  </div> -->
  <div *ngIf="!isLoggedIn">
    <!-- <p class="montserrat-semibold text-black text-center">
      Our Members Make Us Better
    </p>
    <div class="flex justify-center mt-6">
      <a href="mailto:info@jobpros.io" class="text-black"
        >What Can We Improve?</a
      >
    </div> -->
    <div class="flex justify-center mt-5" >
      <a
        (click)="openSignUp(loginSignUpConstant.login)"
        class="blue-border cursor-pointer rounded text-black px-4 py-1"
        >Log In</a
      >
      <a
        (click)="openSignUp(loginSignUpConstant.signup)"
        class="blue-border bg-blue cursor-pointer rounded text-white px-4 py-1 ml-3"
        >Sign Up</a
      >
    </div>
    <div class="mt-7 md:mt-0" *ngIf="opened">
      <app-part-footer (closeSideMenu)="closedMenu($event)" [data]="'sidenavbar'"></app-part-footer>
    </div>

  </div>
</div>
 
