<div class="about-me section-wrapper bg-white px-4 pb-4 pt-0 lg:p-10" [formGroup]="userForm">
  <p *ngIf="user && user.user_type === userRole.candidate; else companyIntro" class="s black-light mb-2">
    Create and display your personal profile
  </p>
  <ng-template #companyIntro>
    <p class="s black-light mb-2">Create and display your company profile</p>
  </ng-template>

  <!--Banner Uplaod-->
  <div class="relative hover-del-icon">
    <div class="flex justify-between items-center">
      <button *ngIf="showBannerSave && !isBannerSaving" (click)="saveBanner(bannerCroppedImage)"
        class="mt-2 button upload-button w-16 outlined hover:text-white">
        Save
      </button>
      <div *ngIf="isBannerSaving" class="mt-2 w-16 flex justify-center items-center">
        <div class="spinner"></div>
      </div>
    </div>
    <label *ngIf="!isEditImageMode" class="montserrat-bold blue cursor-pointer mt-2">
      <input #banner multiple type="file" accept="image/*" (change)="fileChangeEvent($event, banner.files, 'banner')"
        class="uploadFile img w-0 h-0 overflow-hidden" value="Upload Media" />
      <div
        class="relative hover-del-icon w-full banner border-normal rounded bg-contain bg-no-repeat bg-center flex items-center justify-center -mt-6"
        [class]="bannerUploading ? 'spinner' : ''">
        <img *ngIf="user?.banner" class="w-full banner-img" [src]="user?.banner" />
        <mat-icon *ngIf="!user?.banner" class="gray-dark">add_a_photo</mat-icon>
      </div>
    </label>
    <mat-icon *ngIf="user?.banner" class="child absolute remove-icon right-0 cursor-pointer bg-white rounded-full"
      (click)="removeImage('Banner')">delete</mat-icon>
  </div>
  <div *ngIf="imageUpload">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="5.5 / 3" [resizeToWidth]="1000"
      [cropperMinWidth]="1000" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
      [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" [imageQuality]="1"
      [format]="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
      class="crop-image img-croper-dashboard"></image-cropper>
  </div>

  <!--Banner Uplaod End-->

  <!--Profile Uplaod-->
  <div class="flex cover-photo items-center w-fit-content hover-del-icon">
    <label class="portfolio-wrapper hover-del-icon montserrat-bold blue cursor-pointer">

      <!-- Placeholder for adding photo if avatar is not set -->
      <div *ngIf="!!user?.avatar === false" class="insert-photo-button relative bg-white rounded-full"
        [class]="avatarUploading ? 'spinner' : ''">
        <mat-icon class="gray-dark">add_a_photo</mat-icon>
      </div>

      <!-- Display the avatar if it is set -->
      <div *ngIf="!!user?.avatar" class="relative hover-del-icon portfolio bg-contain bg-no-repeat bg-center border"
        [class]="avatarUploading ? 'spinner' : ''">
        <img [src]="user.avatar" alt="Avatar" class="w-full h-full rounded-full" />
      </div>

      <!-- Upload button if avatar is not set -->
      <p *ngIf="!!user?.avatar === false" mat-raise-button
        class="mt-2 ml-3 button upload-button w-16 outlined hover:text-white">
        Upload
      </p>

      <!-- Input field for uploading the avatar -->
      <input #avatar multiple type="file" accept="image/*" class="uploadFile img w-0 h-0 overflow-hidden"
        value="Upload Media" (change)="avatar.files ? uplaodAvatar(avatar.files) : ''" />
    </label>

    <!-- Delete icon for removing the avatar -->
    <mat-icon *ngIf="!!user?.avatar" class="child absolute bg-white top-0 rounded-full left-6 cursor-pointer"
      (click)="removeImage('Avatar')">delete</mat-icon>
  </div>
  <!--Profile Uplaod End-->

  <p class="montserrat-bold text-black mt-4">Name</p>
  <input maxlength="129" class="input-form tab-size w-full my-2 px-4" type="text" placeholder="Full Name"
    formControlName="name" />
  <mat-error *ngIf="errorHandling('name', 'maxlength')" class="text-danger pb-2">
    Name cannot exceed more than 128 characters!
  </mat-error>
  <p class="s black-light">
    Help people discover your account by using your preferred name: either your full name, nickname, or business name.
  </p>
  <p class="montserrat-bold text-black mt-6">Username</p>
  <input maxlength="129" class="input-form tab-size w-full my-2 px-4" type="text" placeholder="username"
    (ngModelChange)="usernameUpdate.next($event)" formControlName="userName" (keypress)="search($event)" />
  <div *ngIf="errorHandling('userName', 'required')" [class]="errorHandling('userName', 'required') ? 'pb-4' : ''">
    <p class="s red">You must provide username.</p>
  </div>
  <div *ngIf="usernameValidationError">
    <p class="s red">User name already exist. Please use another username.</p>
  </div>
  <div *ngIf="errorHandling('userName', 'hasWhiteSpace')"
    [class]="errorHandling('userName', 'hasWhiteSpace') ? 'pb-4' : ''">
    <p class="s red">User Name not contain white spaces.</p>
  </div>
  <div *ngIf="errorHandling('userName', 'maxlength')" [class]="errorHandling('userName', 'maxlength') ? 'pb-4' : ''">
    <p class="s red">user name cannot exceed more than 128 characters!</p>
  </div>
  <p class="montserrat-bold text-black mt-6">City</p>
  <div class="flex items-center -mb-6">
    <mat-form-field class="example-full-width">
      <input maxlength="256" type="text" class="tab-size" placeholder="Type City" aria-label="Location"
        #locationSearchInput matInput [formControl]="myControl" [matAutocomplete]="auto" (keyup.enter)="onEnter()" />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions" value="{{ option.city_name }} - {{ option.state_name }} - {{
            option.country_code
          }}" (click)="setLocation(option)" (onSelectionChange)="setLocation(option)">
          {{ option.city_name
          }}{{ option.state_name ? " - " + option.state_name : "" }}
          {{ option.country_code ? " - " + option.country_code : "" }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <p class="montserrat-bold text-black mt-6">About</p>
  <textarea maxlength="1025" class="w-full border-normal rounded mt-2 user-bio px-4 tab-size"
    formControlName="bio"></textarea>
  <mat-error *ngIf="errorHandling('bio', 'maxlength')" class="text-danger pb-2">
    Abount cannot exceed more than 1024 characters!
  </mat-error>
  <button [disabled]="
      !saveProfile ||
      validationChecking ||
      usernameValidationError ||
      errorHandling('userName', 'hasWhiteSpace') ||
      errorHandling('userName', 'required') ||
      errorHandling('bio', 'maxlength') ||
      errorHandling('name', 'maxlength') ||
      errorHandling('userName', 'maxlength')
    " mat-button class="button outlined black" [class]="
      !saveProfile ||
      validationChecking ||
      usernameValidationError ||
      errorHandling('userName', 'hasWhiteSpace') ||
      errorHandling('userName', 'required') ||
      errorHandling('bio', 'maxlength') ||
      errorHandling('name', 'maxlength') ||
      errorHandling('userName', 'maxlength')
        ? 'disabled'
        : ''
    " (click)="updateProfile()">
    Save
  </button>
</div>

<div class="about-me section-wrapper bg-white p-4 lg:p-10 mt-4">
  <div>
    <p class="montserrat-bold text-black mb-2">Categories</p>
    <div class="input-form flex items-center px-4 categories-select">
      <mat-select [placeholder]="selectedCategories? selectedCategories.name:'Select Categories'">
        <mat-radio-group [value]="selectedCategories">
          <mat-option class="custom-radio-select" *ngFor="let item of serviceCategories"
            (click)="onCategorySelection(item)">
            <span>{{ item.name}}</span>
            <mat-radio-button [value]="item">
            </mat-radio-button>
          </mat-option>
        </mat-radio-group>
      </mat-select>
    </div>
  </div>
  <p class="s black-light my-2"></p>
  <div class="my-4">
    <p class="montserrat-bold text-black mb-2">Experience Level</p>
    <div class="input-form flex items-center px-4">
      <mat-select placeholder="Select Experience" (selectionChange)="setExpLevel($event)" [value]="expLevel?.title">
        <mat-option *ngFor="let item of experienceLists" [value]="item.label">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <div class="mb-4">
    <p class="montserrat-bold text-black mb-2">Preferred Skills</p>
    <div>
      <div class="flex items-center flex-wrap">
        <div *ngFor="let skill of allActiveSkills"
          class="items-center whitespace-nowrap truncate job-feature-tag mr-2 mb-2">
          <mat-icon (click)="removeProfileSkill(skill)">close</mat-icon>
          {{ skill?.title }}
        </div>
        <div *ngFor="let skill of skills" class="items-center whitespace-nowrap truncate job-feature-tag mr-2 mb-2">
          <mat-icon (click)="removeSkill(skill)">close</mat-icon>
          {{ skill?.title }}
        </div>

      </div>
    </div>
    <div class="flex relative items-center">
      <input (keypress)="addSkills($event)" maxlength="129" type="text" placeholder="+ Skills" class="input-form w-full"
        [(ngModel)]="skill" />
      <div class="absolute right-2" *ngIf="skill && skill.length < 129">
        <button mat-button type="button" class="button" (click)="addNewSkill(skill)">
          +
        </button>
      </div>
    </div>
    <mat-error *ngIf="skill && skill.length > 128" class="text-danger pb-2">
      length cannot exceed more than 128 characters!
    </mat-error>
  </div>
  <div class="mb-4">
    <div class="flex justify-between items-center">
      <p class="montserrat-bold text-black mb-2">Client Project</p>
      <button *ngIf="!showAddClientProjectForm" mat-button class="button outlined black" (click)="addProject()">
        <span class="flex"> + Add </span>
      </button>
    </div>

    <div *ngIf="project.length > 0">
      <div class="mb-2" *ngFor="let item of project">
        <app-achievement-card [achievement]="item" (save)="updateAchievement($event, 'Client Project')"
          (remove)="deleteAchievement(item, 'Client Project')">
        </app-achievement-card>
      </div>
    </div>
  </div>
  <div *ngIf="showAddClientProjectForm">
    <app-achievement-form [achievement]="" [type]="AchievementType.ClientProject"
      (save)="saveAchievement($event, AchievementType.ClientProject)"
      (cancel)="showAddClientProjectForm = !showAddClientProjectForm">
    </app-achievement-form>
  </div>
  <div *ngIf="user && user.user_type === userRole.candidate" class="mb-4">
    <div class="flex justify-between items-center">
      <p class="montserrat-bold text-black mb-2">Work History</p>
      <button *ngIf="!showAddHistoryForm" mat-button class="button outlined black" (click)="addHistory()">
        <span class="flex"> + Add </span>
      </button>
    </div>
    <div *ngIf="history.length > 0">
      <div class="mb-2" *ngFor="let item of history">
        <app-achievement-card [achievement]="item" (save)="updateAchievement($event, 'Work History')"
          (remove)="deleteAchievement(item, 'Work History')">
        </app-achievement-card>
      </div>
    </div>
  </div>
  <div *ngIf="showAddHistoryForm">
    <app-achievement-form [achievement]="" [type]="AchievementType.WorkHistory"
      (save)="saveAchievement($event, AchievementType.WorkHistory)" (cancel)="showAddHistoryForm = !showAddHistoryForm">
    </app-achievement-form>
  </div>
  <div *ngIf="user && user.user_type === userRole.candidate" class="mb-4">
    <div class="flex justify-between items-center">
      <p class="montserrat-bold text-black mb-2">Education</p>
      <button *ngIf="!showAddStudyForm" mat-button class="button outlined black" (click)="addStudy()">
        <span class="flex"> + Add </span>
      </button>
    </div>
    <div *ngIf="education.length > 0">
      <div class="mb-2" *ngFor="let item of education">
        <app-achievement-card [achievement]="item" (save)="updateAchievement($event, AchievementType.Education)"
          (remove)="deleteAchievement(item, AchievementType.Education)">
        </app-achievement-card>
      </div>
    </div>
  </div>
  <div *ngIf="showAddStudyForm" class="mb-4">
    <app-achievement-form [achievement]="" (save)="saveAchievement($event, AchievementType.Education)"
      (cancel)="showAddStudyForm = !showAddStudyForm"></app-achievement-form>
  </div>
</div>