<div class="section-wrapper bg-white px-4 lg:p-10">
    <p class="text-black montserrat-bold">You’re sold subscriptions </p>
    <p class="mt-2 black-light s">See what you sold</p>
    <div class="mt-2">
        <mat-divider class="w-full"></mat-divider>
      </div>
    <div class="mt-4">
      <table
        mat-table
        [dataSource]="dataSource"
        class="w-full shadow-none mat-elevation-z8 dashboard-sold-subscrotion"
        *ngIf="dataSource?.length"
      >
      <ng-container matColumnDef="last4">
        <th mat-header-cell *matHeaderCellDef>
          <p
            class="text-black montserrat-bold s header servicedetail-textstyle"
          >
            Members
          </p>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="flex items-center">
            <img class="user-avatarImg" [src]="element.buyer.profile_img_path"
              onerror="this.src='assets/images/no-image.png'">
            <div class="pl-1 w-24 md:w-full">
              <p class="text-sm truncate">@{{element.buyer.user_name}}</p>
            </div>
          </div>
        </td>
      </ng-container>
        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="text-black montserrat-bold s header servicedetail-textstyle"
            >
              Service Name
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="table-data servicedetail-textstyle">
              {{ element.subscription.title | titlecase }}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="expiry">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="text-black montserrat-bold s text-right header servicedetail-textstyle"
            >
            Amount 
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="flex justify-end items-center">
              <!-- <p
                (click)="feedBack($event, element)"
                class="blue cursor-pointer mr-2 text-sm"
              >
                Add feedback
              </p>
              <p
                *ngIf="
                  element?.refund?.length &&
                  element?.refund[0]?.refund_request_status ==
                    RefundStatusConst.PENDING
                "
                class="blinking-div servicedetail-textstyle"
              >
                refund
              </p> -->
              <p class="text-right text-sm servicedetail-textstyle">
                ${{ element.payment.amount }}
              </p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="isDefault">
          <th mat-header-cell *matHeaderCellDef>
            <p
              class="text-black montserrat-bold s pl-8 md:pl-0 header servicedetail-textstyle"
            >
              Sold
            </p>
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="text-sm servicedetail-textstyle">
              {{ element.created_at | date : "mediumDate" }}
            </p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="cursor-pointer"
          mat-row
          *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
</div>    