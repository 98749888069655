<div
  class="grid"
  [class]="layoutMode === 'grid_view' ? 'lg:grid-cols-3 md:grid-cols-2  grid-cols-1  gap-2' : ''"
>
  <div class="w-full" *ngFor="let item of members; trackBy: trackByFunc">
    <app-people-card
      [data]="item"
      [followings]="followings"
      (followMember)="onFollow($event)"
      (unFollowMember)="onUnFollow($event)"
    ></app-people-card>
  </div>
</div>
