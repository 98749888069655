import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ShareModalComponent } from 'src/app/modules/users/modals/share-modal/share-modal.component';
import { takeUntil } from 'rxjs/operators';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { LoginSignupDialogComponent } from 'src/app/shared/components/login-signup-dialog/login-signup-dialog.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CommonService } from 'src/app/core/services/common.service';
import { shareTypeConst } from 'src/app/const/appConst';
import { PostService } from 'src/app/core/services/post.service';
import { MentionService } from 'src/app/shared/services/mentions.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommentModalComponent } from 'src/app/shared/components/comment-modal/comment-modal.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-talent-card',
  templateUrl: './talent-card.component.html',
  styleUrls: ['./talent-card.component.scss']
})
export class TalentCardComponent implements OnInit {
  private unsubscribe$ = new Subject();
  dataForInteractionPanel: any;
  @Input() talent!: any;
  routesConst = routesConst;
  followers: any[] = [];
  followings: [] = [];
  isFollow: boolean = false;
  isLoadingLike = false;
  likeNumber: number = 0;
  isFavor: boolean = false;
  innerWidth = window.innerWidth;
  commentForm: FormGroup;

  profile: any;
  showComment = false;

  @Input() set loginedProfile(value: any) {
    this.profile = value;
  }

  @Input() isShowInteractionPanel: boolean = false;
  @Input() feedCardType: any;


  constructor(
    private clipboard: Clipboard,
    public dialog: MatDialog,
    private snackService: SnackBarService,
    private router: Router,
    private sharedService: SharedService,
    private commonService: CommonService,
    private authService: AuthService,
    private postService: PostService,
    public mentionService: MentionService,
    private fb: FormBuilder,
    private _bottomSheet: MatBottomSheet
  ) {
    this.commentForm = this.fb.group({
      comment: ['', [Validators.required, Validators.maxLength(256)]]
    });
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['talent']) {
      this.constructInteractionPanelData(changes['talent'].currentValue);
    }
  }

  getFollowersAndFollowings(userId: any) {
    this.sharedService.followersAndFollowings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(network => {
        if (network?.followings || network?.followers) {
          this.followers = network.followings.filter((f: any) => f.follower_id === userId);
          this.followings = network.followings;
          this.isFollow =
            this.followers.filter(f => f.user_id === this.talent.profile._id).length > 0;
        } else {
          this.sharedService.getFollowersAndFollowings();
        }
      });
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        const { _id } = profileInfo;
        this.getFollowersAndFollowings(_id);
      }
    });
  }

  viewProfile() {
    this.router.navigate(['/profile'], { queryParams: { profile_id: this.talent.profile._id } });
  }

  messageUser(talent: any) {
    if (talent.profile._id) {
      const talentInfo = {
        email: talent.profile.email,
        name: talent.profile.name,
        profile_img_path: talent.profile.profile_img_path,
        user_name: talent.profile.user_name,
        user_type: talent.profile.user_type,
        _id: talent.profile._id
      };
      localStorage.setItem('talentInfo', JSON.stringify(talentInfo));
      this.router.navigate(['/dashboard/messages'], {
        queryParams: { tab: 3 }
      });
    }
  }

  showService() {
    this.router.navigate([
      'services/service/' + this.talent.profile.user_name + '/' + this.talent._id
    ]);
  }

  getProfileImage(talent: any) {
    return talent?.profile?.profile_img_path != ''
      ? talent.profile.profile_img_path
      : 'https://i.postimg.cc/Hk7vyZZM/no-image.png';
  }

  showProfile() {
    this.router.navigate(['/profile' + '/' + this.talent.profile.user_name]);
  }

  copyLink() {
    this.clipboard.copy(
      this.commonService.location().origin +
        '/services/service' +
        '/' +
        this.talent.profile.user_name +
        '/' +
        this.talent._id
    );
    this.snackService.showMessage(`Link Copied`, false);
  }

  getLowestPrice(packages: any) {
    return packages.reduce(
      (min: any, item: any) => (item?.price < min ? item?.price : min),
      packages[0]?.price
    );
  }

  likePost() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      this.isLoadingLike = true;
      const isAlreadyLikedPost = this.talent?.likeDetails?.find(
        (like: any) => like.user_id === this.profile?._id
      );
      const index = this.talent?.likeDetails.findIndex((x: any) => x.user_id === this.profile._id);
      if (isAlreadyLikedPost?._id) {
        this.postService
          .unLikePost(isAlreadyLikedPost._id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => {
            this.isLoadingLike = false;
            this.talent?.likeDetails.splice(index, 1);
            this.isFavor = false;
            this.likeNumber--;
          });
      } else {
        const options = {
          content_id: this.talent._id,
          content_type: 'Service',
          profile_id: this.talent.profile_id
        };
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((post: any) => {
            this.isLoadingLike = false;
            if (post.status === 'success') {
              this.talent?.likeDetails.push({
                user_id: post.data.user_id,
                _id: post.data._id
              });
            }
          });
        this.likeNumber++;
        this.isFavor = true;
      }
    }
  }

  showComments() {
    this.showComment = !this.showComment;
    var commentPanel = document.getElementById(this.talent._id);
    if (commentPanel !== null) {
      commentPanel.style.marginLeft = this.showComment ? '0%' : '100%';
    }
  }

  openBottomSheet(): void {
    if (this.innerWidth <= 768) {
      const bottomSheetRef = this._bottomSheet.open(CommentModalComponent, {
        data: {
          postOwnerID: this.talent.profile_id,
          data: this.talent,
          profile: this.profile,
          post_id: this.talent._id
        }
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
        // if (result) {
        //   this.searchByInterest(result);
        // }
      });
    }
  }

  sharePost() {
    if (this.authService.loggedIn()) {
      this.dialog.open(ShareModalComponent, {
        width: window.innerWidth > 600 ? '490px' : '100%',
        maxWidth: window.innerWidth > 600 ? '80vw' : '100vw',
        data: {
          link:
            this.commonService.location().origin +
            '/services/service/' +
            this.talent.profile.user_name +
            '/' +
            this.talent._id,
          content_id: this.talent._id,
          shareType: shareTypeConst.service
        }
      });
    } else {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    }
  }

  isUserLiked() {
    return this.talent?.likeDetails?.find((like: any) => like?.user_id === this.profile?._id)
      ? true
      : false;
  }

  formatPostDate(previousDate?: any) {
    let currentDate = new Date().getTime();
    let jobDate = new Date(previousDate).getTime();
    let time = (currentDate - jobDate) / 1000;
    let days = Math.floor(time / (3600 * 24));
    let minutes = Math.floor(time / 60);
    let hours = Math.floor(time / (60 * 60));
    let weeks = Math.floor(days / 7);
    let months = Math.floor(days / 30);
    let years = Math.floor(days / 365);

    if (time < 60) {
      return Math.floor(time) + 's';
    } else if (minutes < 60) {
      return minutes + 'm';
    } else if (hours < 24) {
      return hours + 'h';
    } else if (days < 7) {
      return days + 'd';
    } else if (weeks < 4) {
      return weeks + 'w';
    } else if (months < 12) {
      return months + 'mo';
    } else {
      return years + 'y';
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.commentForm.controls[control].hasError(error);
  };

  checkWhitespaces(text: any) {
    return text?.replace(/\s/g, '');
  }

  submitForm() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      if (this.commentForm.valid) {
        if (this.profile._id) {
          const options = {
            content_type: 'comment',
            comment: this.commentForm.get('comment')?.value,
            content_id: this.talent._id,
            profile_id: this.profile._id,
            commentedOn: 'SERVICE'
          };
          if (this.checkWhitespaces(this.commentForm.get('comment')?.value)?.length) {
            this.postService
              .addComment(options)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.status == 'success') {
                  if (this.talent.commentDetails) {
                    this.talent.totalThreads++;
                    this.talent.commentDetails.unshift({
                      ...res.data,
                      userInfo: [
                        {
                          email: this.profile.email,
                          profile_img_path: this.profile.avatar,
                          user_name: this.profile.user_name,
                          userType: this.profile.user_type,
                          _id: this.profile._id
                        }
                      ],
                      likeDetails: [],
                      replyCommentDetails: []
                    });
                  }
                } else {
                  this.snackService.showMessage(res.message, true);
                }
              });
            this.commentForm.get('comment')?.setValue('');
          }
        }
      }
    }
  }

  isFavorComment(likes: any) {
    return likes?.findIndex((l: any) => l?.user_id === this.profile?._id) !== -1;
  }

  likeComment(user: any) {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
    } else {
      const options = {
        user_id: this.profile?._id,
        content_id: user._id,
        content_type: 'COMMENT'
      };
      if (this.isFavorComment(user.likeDetails)) {
        const id = user.likeDetails.find((like: any) => like.user_id === this.profile?._id)._id;
        if (id) {
          this.postService
            .unLikePost(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: any) => {
              if (res.status === 'Success') {
                const u = {
                  ...user,
                  likeDetails: user.likeDetails.filter(
                    (like: any) => like.user_id !== this.profile?._id
                  )
                };
                this.talent = {
                  ...this.talent,
                  commentDetails: this.talent.commentDetails.map((c: any) => {
                    if (c._id === u._id) return u;
                    return c;
                  })
                };
                this.talent = {
                  ...this.talent,
                  contentDetails: this.talent.contentDetails.map((content: any) => {
                    if (content._id === this.talent._id) return this.talent;
                    return content;
                  })
                };
              }
            });
        }
      } else {
        this.postService
          .likePost(options)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            if (res.status === 'success') {
              this.talent = {
                ...this.talent,
                commentDetails: this.talent.commentDetails.map((comment: any) => {
                  if (comment._id === user._id) {
                    return {
                      ...user,
                      likeDetails: [
                        ...user.likeDetails,
                        {
                          ...res.data,
                          userInfo: [
                            {
                              email: this.profile.email,
                              profile_img_path: this.profile.avatar,
                              user_name: this.profile.user_name,
                              userType: this.profile.user_type,
                              _id: this.profile._id
                            }
                          ]
                        }
                      ]
                    };
                  }
                  return comment;
                })
              };
            }
          });
      }
    }
  }

  onServiceViewLogged(event: any) {
    if (event.postId == this.talent._id) {
      this.talent.views += 1;
      this.dataForInteractionPanel.views += 1
    }
  }

  constructInteractionPanelData(data: any) {
    this.dataForInteractionPanel = {
      commentDetails: data.commentDetails,
      likeDetails: data.likeDetails,
      totalThreads: data.totalThreads,
      views: data.views,
      created_at: data.created_at,
      description: data.packages[0].description,
      tags: data.packages[0].offers.split(',').map((item: string) => `#${item}`),
      _id: data._id,
      profile_id: data.profile_id,
      profile: data.profile,
      shareType: shareTypeConst.service,
      contentType: 'SERVICE'
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
