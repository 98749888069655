import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentRoute: any;
  sideBarClose = false;
  constructor(
    private changeDetection: ChangeDetectorRef,
    public authService: AuthService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
      }
    });
  }

  isOpened = false;
  ngOnInit() {
    this.checkVersion();
    const device = this.detectDevice();
    if (device !== undefined) {
      document.body.classList.add(device)
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetection.detectChanges();
  }

  onSidebarToogle(isOpened: boolean) {
    this.isOpened = isOpened;
  }

  checkVersion() {
    const storedEnvironment = localStorage.getItem('current_environment');
    if (
      storedEnvironment !== null &&
      JSON.parse(storedEnvironment) !== environment.version
    ) {
      localStorage.clear();
    } else {
      localStorage.setItem(
        'current_environment',
        JSON.stringify(environment.version)
      );
    }
  }

  detectDevice(): "Android-Detect" | "iOS-Detect-Chrome" | "iOS-Detect-Safari" | "macOS-Detect" | "Windows-Detect" | "Unknown-Detect" | undefined {
    const userAgent = navigator.userAgent;
    
    if (/android/i.test(userAgent)) {
        return "Android-Detect";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
          if (userAgent.match(/CriOS/)) {
            return "iOS-Detect-Chrome";
          }else{
            return "iOS-Detect-Safari";
        }
    } else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent) && !/iPhone|iPad|iPod/.test(userAgent)) {
        return "macOS-Detect";
    } else if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
        return "Windows-Detect";
    } else {
        return "Unknown-Detect";
    }
}
}
