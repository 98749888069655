<div>
  <a routerLink="/" class="absolute top-3 left-3">
    <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png"
      class="w-9 object-cover" />
  </a>
  <mat-icon class="text-3xl absolute close-icon top-2 right-2.5  cursor-pointer text-black" mat-dialog-close>close</mat-icon>
</div>
<div class="container relative contact-us-page h-full">
  <h3 class="flex contact-us mt-10">Let's grow together your success impacts everyone</h3>
  <p class="mt-2 contact-us-description black-light px-5">Let’s connect whether you are a large company, 
    small startup, or an individual entrepreneur. 
    </p>
  <div class="contact-form flex justify-center mt-1">
    
    <form
      [formGroup]="contactForm"
      (ngSubmit)="submitForm()"
      class="w-full max-w-md h-full"
    >
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-full custom-mat-form-field">
          <mat-label>Name</mat-label>
          <input
            matInput
            formControlName="name"
            type="text"
            id="name"
            placeholder="Enter your name"
          />        
          <mat-error
            *ngIf="
              contactForm.get('name')?.invalid &&
              (contactForm.get('name')?.dirty ||
                contactForm.get('name')?.touched)
            "
          >
            Please enter a valid name.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            type="email"
            id="email"
            placeholder="Enter your email"
          />
          <mat-error
            *ngIf="
              contactForm.get('email')?.invalid &&
              (contactForm.get('email')?.dirty ||
                contactForm.get('email')?.touched)
            "
          >
            Please enter a valid email address.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Phone</mat-label>
          <input
            matInput
            formControlName="phone"
            type="number"
            id="phone"
            placeholder="(555) 123 - 4567"
          />
          <mat-error
            *ngIf="
              contactForm.get('phone')?.invalid &&
              (contactForm.get('phone')?.dirty ||
                contactForm.get('phone')?.touched)
            "
          >
            Please enter a valid phone number.
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="form-group">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Subject</mat-label>
          <input
            matInput
            formControlName="subject"
            type="text"
            id="subject"
            placeholder="Enter the subject"
          />
          <mat-error
            *ngIf="
              contactForm.get('subject')?.invalid &&
              (contactForm.get('subject')?.dirty ||
                contactForm.get('subject')?.touched)
            "
          >
            Please enter a subject.
          </mat-error>
        </mat-form-field>
      </div> -->

      <div class="form-group">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Message</mat-label>
          <textarea
          class="messge"
            matInput
            formControlName="message"
            id="message"
            placeholder="Enter your message"
          ></textarea>
          <mat-error
            *ngIf="
              contactForm.get('message')?.invalid &&
              (contactForm.get('message')?.dirty ||
                contactForm.get('message')?.touched)
            "
          >
            Please enter a message.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="started-btn-wrapper">
        <div class="form-group flex justify-center py-1">
          <button mat-button class="button submit-button w-full">Get Started</button>
        </div>
        <div class="flex justify-center mt-2">
          <!-- <span class="mr-2 footer-email-address">Prefer email?</span>
            <img
            src="https://assets-global.website-files.com/60ca70f6cb7406afbf948a8e/60d15ad570c7d06ba3bd1b4b_footer_email.svg"
            class="mr-2 w-6 "
          />  -->
            <a
            href="https://accounts.google.com/v3/signin/identifier?dsh=S-83614693%3A1676377807514689&continue=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&emr=1&followup=https%3A%2F%2Fmail.google.com%2Fmail%2Fu%2F0%2F&osid=1&passive=1209600&service=mail&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AWnogHeBsfmsRLkDfVdYJ4aWTG-2e6HoX2weruEodUnnOeO8R6ooMWznliOLmdGdcgnDC1eaHCLINg"
            target="_blank"
            class="footer-email-address black-light"
            >support@jobpros.io</a
          >
          
        </div>
      </div>
    </form>
  </div>
</div>
