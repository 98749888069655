<div class="talents-page mx-auto">
  <!-- <div class="mb-4 services-title">
    <h1 class="talents-page_title mx-auto lg:mt-0 montserrat-bold">
      Explore Services
    </h1>
    <p class="capitalize">By Creators, Experts & Companies</p>
  </div> -->
  <app-talent-search-tab-group (isFilterApplied)="isFilterApplied($event)" (getFilteredList)="getFilteredList($event)"  (clearFilter)="clearFilter($event)"></app-talent-search-tab-group>
  <div class="scroll-wrapper">
    <div class="scroll" id="talentsContainer" infiniteScroll [infiniteScrollContainer]="'#talentsContainer'"
      [fromRoot]="true" [infiniteScrollThrottle]="50" [infiniteScrollDistance]="0.5" (scrolled)="onScrollDown()">
      <div class="spinner-wrapper">
        <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
          [fullScreen]="true" class="job-list-spinner">
        </ngx-spinner>
      </div>
      <div class="mb-4 grid grid-cols-1 gap-[9px] md:grid-cols-3">
        <app-talent-card [talent]="talent" *ngFor="let talent of talents"></app-talent-card>
      </div>
    </div>
  </div>
</div>