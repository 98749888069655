import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { routesConst } from 'src/app/core/const/routers';
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SwiperOptions } from 'swiper';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  private unsubscribe$ = new Subject();
  routesConst = routesConst;
  selected = new FormControl(0);
  name: string = '';
  tab: string = 'profile';
  profile: any;
  achievements: any;
  privacy: any;
  socialProfiles: any;
  experienceLevel: any;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5
  };
  purchasedAddOn: any = [];
  referralLink: string = '';

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private profileService: ProfileService,
    private commonService: CommonService,
    private clipboard: Clipboard,
    private snack: SnackBarService
  ) { }

  ngOnInit(): void {
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userPurchased: any) => {
        this.purchasedAddOn = userPurchased.currentAddOns;
        this.profileService.purchasedPlan.next(userPurchased.currentPlan);
        this.profileService.purchasedAddOn.next(userPurchased.currentAddOns);
        if (userPurchased?.currentAddOns?.length) {
          this.referralCode();
        }
      });

    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((param: any) => {
      if (param?.tab) {
        // setTimeout(() => {
        this.selected.setValue(param?.tab);
        this.setTab(param?.tab == 1 ? 'interest' : 'profile');
        // }, 100);
      }
    });

    this.experienceLevelList();
    this.dashboard();
    this.userPurchasedPlan();
    this.getLoginedUserType();
    this.referralCode();
  }

  /**
   * Get Referral Code
   */
  referralCode() {
    this.profileService
      .getReferralCode()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((referral: any) => {
        this.getReferralLink(referral.code);
      });
  }

  /**
   * Get User Referral Link
   * @param referralCode
   */
  getReferralLink(referralCode: string) {
    this.referralLink = `${
      this.commonService.location().origin
    }/signup?referralLink=${referralCode}`;
    // if (this.referralLink.length) {
    //   this.copytoClipBoard();
    // }
  }

  /**
   * Copy to Clipboard
   */
  copytoClipBoard() {
    let isCoppied = this.clipboard.copy(this.referralLink);
    if (isCoppied) {
      this.snack.showMessage(`Referral Link Copied...`, false);
    } else {
    }
  }

  getLoginedUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === '') {
        this.sharedService.getUserType();
      }
    });
  }

  experienceLevelList() {
    this.sharedService.experienceLevelList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((experience: string | any[]) => {
        if (experience.length) {
          this.experienceLevel = experience;
        } else {
          this.sharedService.getexperienceLevel();
        }
      });
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (
        information: {
          profileInfo: any;
          achievements: any;
          privacy: any;
          socialProfiles: any;
        } | null
      ) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else {
          const { profileInfo, achievements, privacy, socialProfiles } = information;
          const { name } = profileInfo;
          this.name = name;
          this.profile = profileInfo;
          this.achievements = achievements;
          this.privacy = privacy;
          this.socialProfiles = socialProfiles;
        }
      }
    );
  }

  userPurchasedPlan() {
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.profileService.purchasedPlan.next(res.currentPlan);
        this.profileService.purchasedAddOn.next(res.currentAddOns);
      });
  }

  setTab(selectedTab: string) {
    this.tab = selectedTab;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
 
}
