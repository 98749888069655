import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { routesConst } from 'src/app/core/const/routers';
import { UserPrefrencesComponent } from 'src/app/modules/dashboard/pages/my-jobs/tabs/user-prefrences/user-prefrences.component';
import { userTypeConstant, planTypeConstant, statusConstant } from 'src/app/const/appConst';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { registerValues } from 'src/app/core/const/register';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { JobService } from 'src/app/core/services/job.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { JobPostComponent } from 'src/app/shared/components/job-post/job-post.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-my-jobs',
  templateUrl: './my-jobs.component.html',
  styleUrls: ['./my-jobs.component.scss']
})
export class MyJobsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  selected = new FormControl();
  tab = 0;
  dialogRef: any;
  jobs?: any[];
  savedJobs?: any[];
  preferences$: any;
  routesConst = routesConst;
  user: any;
  registerValues = registerValues;
  type = registerValues.UserTypes.candidate;
  isAdmin = false;
  candidateJobPreference: any;
  jobFilters = { offset: 0, limit: 10 };
  totalcount: any;
  totalAvaileJobs: any;
  userRole = userTypeConstant;
  profile: any;
  categoryToSearch: any = [];
  keywordToSearch: any = '';
  selectedJobId: any;
  appliedJobs: any[] = [];
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5
  };

  constructor(
    public jobService: JobService,
    public authService: AuthService,
    private profileService: ProfileService,
    public dialog: MatDialog,
    private snack: SnackBarService,
    private sharedService: SharedService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.selected.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.categoryToSearch = [];
      this.refreshJobs(true);
    });

    this.isAdmin = this.authService.isAdmin();
  }

  ngOnInit(): void {
    if (this.authService.loggedIn()) {
      this.dashboard();
      this.getUserType();
    }

    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((param: any) => {
      if (param?.tab) {
        this.tab = param.tab;
      }
    });
  }

  dashboard() {
    this.sharedService.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe(information => {
      if (information === null) {
        this.sharedService.getUserInfo();
      } else {
        const { profileInfo } = information;
        this.profile = profileInfo;
        this.getUserAppliedJobs();
        if (profileInfo.user_type === userTypeConstant.candidate) {
          this.getUserPreferences();
        } else {
          this.companyPostedJob();
        }
      }
    });
  }

  getUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === '') {
        this.sharedService.getUserType();
      } else {
        this.type = type;
      }
      setTimeout(() => {
        this.selected.setValue(this.tab);
      }, 2000);
    });
  }

  getUserPreferences() {
    this.jobService
      .getCandidateJobPreferences()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((preference: any) => {
        this.candidateJobPreference = preference.data;
      });
  }

  /**
   * Get User Applied Jobs Details
   */
  getUserAppliedJobs() {
    if (this.profile?._id) {
      const userAppliedJobQueryParams = {
        userid: this.profile?._id,
        offset: 0,
        limit: 10
      };
      this.jobService
        .getUserAppliedJobs(userAppliedJobQueryParams)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          if (result && result.data) {
            this.appliedJobs = result.data.filter((x: any) => {
              return x.job.isSaved == false;
            });
            this.savedJobs = result.data.filter((x: any) => {
              return x.job.isSaved == true;
            });
          }
        });
    }
  }

  jobselectedCategory(categories: any) {
    this.categoryToSearch = categories;
    if (this.profile && this.profile._id) {
      if (categories?.length || this.keywordToSearch?.length) {
        if (this.profile.user_type === userTypeConstant.candidate) {
          this.getCandidateAppliedSearchJob();
        }
        if (this.profile.user_type === userTypeConstant.company) {
          this.getCompanySearchJob();
        }
      } else {
        this.getUserAppliedJobs();
      }
    }
  }

  /**
   * Get Company posted job
   */
  companyPostedJob() {
    if (this.profile && this.profile._id && this.profile?.user_type == this.userRole.company) {
      this.jobFilters.offset = 0;
      this.jobFilters.limit = 10;
      this.jobService
        .allJobListByCompany(this.profile._id, this.jobFilters)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.jobs = result.data;
          this.totalcount = result.length;
          this.totalAvaileJobs = result.totalJobAvailble;
          if (result?.data?.length) {
            this.preferences$ = result.data[0].preference[0];
          }
        });
    }
    this.getUserAppliedJobs();
  }

  checkEligibility(): void {
    this.profileService
      .getOnBoardStatus()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user: any) => {
        if (user.status == statusConstant.inactive || user.status == statusConstant.rejected) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
              message: `Complete one time Stripe onboarding
              to post jobs,expert services, create paid subscriptions, or earn referral revenue.`,
              header: 'Stripe Onboarding'
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              localStorage.setItem(
                'purchase_plan_type',
                JSON.stringify({ plan_type: 'Recruiter' })
              );
              this.router.navigate(['/' + routesConst.planPricing]);
            }
          });
        } else {
          this.proceedToAddJob();
        }
      });
  }

  proceedToAddJob() {
    this.spinner.show();
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.status === 'success') {
          this.spinner.hide();
          if (!res.currentPlan) {
            this.snack.showMessage('Please purchase a paid plan to post job', true);
            this.router.navigate(['/' + routesConst.planPricing]);
            return;
          }
          if (res.currentPlan.plan_type == planTypeConstant.recruiter) {
            this.jobService
              .checkEligibility()
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((res: any) => {
                if (res.result) {
                  const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                      message: `Requires membership upgrade to BUSINESS, ENTERPRISE or NON-PROFIT`,
                      header: 'Unlimited Job Posting'
                    }
                  });
                  dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.router.navigate(['/' + routesConst.planPricing]);
                    }
                  });
                } else {
                  this.openJobPostDialog();
                }
              });
          } else {
            this.openJobPostDialog();
          }
        }
      });
  }

  openJobPostDialog() {
    this.dialogRef = this.dialog.open(JobPostComponent, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      hasBackdrop: false,
      panelClass: 'post-job-dialog-container'
    });
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.refreshJobs(true);
      });
  }

  openUserPrefrences() {
    this.dialogRef = this.dialog.open(UserPrefrencesComponent, {
      width: '900px'
    });
  }

  /**
   * Remove Jobs
   * @param jobdetails
   */
  removejob(jobdetails: any) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: { message: `Are sure you want to delete this item ?` }
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          this.jobService
            .removeAppliedJob(jobdetails.jobId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: any) => {
              this.snack.showMessage(`${res.message}`, false);
              this.savedJobs?.splice(jobdetails.index, 1);
            });
        }
      });
  }

  /**
   * Remove Company Created Job
   * @param company
   */
  removeCompanyJob(company: any) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '500px',
      data: {
        message: `Are sure you want to ${company.is_active ? ' Active ' : ' Delete '} this Job ?`
      }
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          this.jobService
            .updateJobStatus(company)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res: any) => {
                if (res.status == 'success') {
                  this.jobs?.forEach((all: any) => {
                    if (all.job._id == company._id) {
                      all.job.is_active = company.is_active;
                    }
                  });
                  this.selectedJobId = {
                    jobId: company._id,
                    is_active: company.is_active
                  };
                }
                this.snack.showMessage(`${res.message}`, res.status == 'success' ? false : true);
              },
              () => {
                this.snack.showMessage(
                  ` Failed to ${company.is_active ? ' Active ' : ' Delete '} Selected Job`,
                  true
                );
              }
            );
        }
      });
  }

  /**
   *
   * @param preferences Update User Preferences
   */
  updateUserPreferences(preferences: any) {
    this.jobService
      .updateCandidateJobPreference(preferences)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.snack.showMessage(`Job Preferences Updated Sucessfully`, false);
          this.getUserPreferences();
        },
        () => {
          this.snack.showMessage(`Failed to Update`, true);
        }
      );
  }

  searchByKeyword(text: any) {
    this.keywordToSearch = text;
    if (text == null && !this.categoryToSearch?.length) {
      this.profile.user_type == userTypeConstant.company
        ? this.companyPostedJob()
        : this.getUserAppliedJobs(),
        this.getUserPreferences();
    } else if (text != null || (this.categoryToSearch?.length && this.selected.value == 0)) {
      this.profile.user_type == userTypeConstant.company
        ? this.getCompanySearchJob()
        : this.getCandidateAppliedSearchJob();
    } else if (
      text != null ||
      (this.categoryToSearch?.length &&
        (this.selected.value == 1 || this.selected.value == 2) &&
        this.profile.user_type == userTypeConstant.company)
    ) {
      this.getCandidateAppliedSearchJob();
    }
  }

  getCandidateAppliedSearchJob() {
    const userAppliedJobQueryParams = {};
    if (this.categoryToSearch?.length) {
      Object.assign(userAppliedJobQueryParams, {
        jobCategory: this.categoryToSearch
      });
    }
    if (this.keywordToSearch?.length) {
      Object.assign(userAppliedJobQueryParams, {
        keyword: this.keywordToSearch
      });
    }
    this.jobService
      .getAppliedJobsByUserIdbySearch(userAppliedJobQueryParams)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x: any) => {
        if (this.selected.value == 0 && this.profile.user_type == userTypeConstant.candidate) {
          this.appliedJobs = x.data.filter((x: any) => {
            return x.job.isSaved == false;
          });
        }
        if (this.selected.value == 1 && this.profile.user_type == userTypeConstant.company) {
          this.appliedJobs = x.data.filter((x: any) => {
            return x.job.isSaved == false;
          });
        }
        if (this.selected.value == 2) {
          this.savedJobs = x?.data?.filter((x: any) => {
            return x.job.isSaved == true;
          });
        }
      });
  }

  getCompanySearchJob() {
    const userAppliedJobQueryParams = { _id: this.profile._id };
    if (this.categoryToSearch?.length) {
      Object.assign(userAppliedJobQueryParams, {
        jobCategory: this.categoryToSearch
      });
    }
    if (this.keywordToSearch?.length) {
      Object.assign(userAppliedJobQueryParams, {
        keyword: this.keywordToSearch
      });
    }
    if (this.selected.value == 0 && this.profile.user_type == userTypeConstant.company) {
      this.jobService
        .companyjobsearch(userAppliedJobQueryParams)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((x: any) => {
          this.jobs = x.data;
        });
    } else {
      this.getCandidateAppliedSearchJob();
    }
  }

  refreshJobs(value: any) {
    if (value) {
      setTimeout(() => {
        this.profile?.user_type == userTypeConstant.company
          ? this.companyPostedJob()
          : this.getUserAppliedJobs(),
          this.getUserPreferences();
      }, 2000);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
    this.dialogRef?.close();
  }
}
