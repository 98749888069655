<div class="net-work-page px-4 lg:px-0 pt-0.5 lg:pt-0">
  <div class="flex flex-col md:flex-row items-end justify-between">
    <h1 class="montserrat-bold mr-auto window-mode text-style">Network</h1>
  </div>
  <div class="tabs mt-4">
    <swiper
      [navigation]="true"
      [config]="config"
      class="swpier-container common-arrow"
    >
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 0" [class]="selected.value === 0 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">Followers</p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 1" [class]="selected.value === 1 ? 'selected' : ''">
            <p class="tab-size" [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">Following</p>
          </div>
        </ng-template>
        <ng-template swiperSlide>
          <div>
            <div class="flex items-center pb-1 tab cursor-pointer" (click)="selected.value = 2" [class]="selected.value === 2 ? 'selected' : ''">
              <p class="tab-size" [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">Subscribers</p>
            </div>
          </div>
        </ng-template>
    </swiper>
    <mat-tab-group color="#fff0" [selectedIndex]="selected.value" (selectedIndexChange)="handleTabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 0 ? 'selected' : ''">
            <p [class]="selected.value === 0 ? 'blue montserrat-bold' : ''">Followers</p>
          </div>
        </ng-template>
        <app-followers></app-followers>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center pb-1 tab" [class]="selected.value === 1 ? 'selected' : ''">
            <p [class]="selected.value === 1 ? 'blue montserrat-bold' : ''">Following</p>
          </div>
        </ng-template>
        <app-following-list></app-following-list>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>
            <div class="flex items-center pb-1 tab" [class]="selected.value === 2 ? 'selected' : ''">
              <p [class]="selected.value === 2 ? 'blue montserrat-bold' : ''">Subscribers</p>
            </div>
          </div>
        </ng-template>
        <app-subscribing-list></app-subscribing-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
