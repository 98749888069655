import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-preview-modal',
  templateUrl: './image-preview-modal.component.html',
  styleUrls: ['./image-preview-modal.component.scss']
})
export class ImagePreviewModalComponent implements OnInit {
  selected: number;
  slides?: any[];

  constructor(
    private dialogRef: MatDialogRef<ImagePreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selected = 0;
  }

  ngOnInit(): void {
    this.slides = this.data.service?.portfolios.map((portfolio:any) => {
      return {
        path: portfolio.url
      }
    });
    this.selected = this.data.selected
  }

  prev() {
    if (this.slides) {
      this.selected = Math.abs(this.selected-1+this.slides.length)%this.slides.length
    }
  }

  next() {
    if (this.slides) {
      this.selected = (this.selected + 1)%this.slides.length
    }
  }

  close() {
    this.dialogRef.close()
  }
}
