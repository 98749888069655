import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ReviewApplicantComponent } from '../../tabs/review-applicant/review-applicant.component';
import { userTypeConstant, planTypeConstant } from 'src/app/const/appConst';
import { Router } from '@angular/router';
import { JobApplyModalComponent } from 'src/app/modules/jobs/modals/job-apply-modal/job-apply-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationStatus } from 'src/app/core/enums/enums';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { JobPostComponent } from 'src/app/shared/components/job-post/job-post.component';
import { SharedService } from 'src/app/shared/services/shared.service';
import { JobModalComponent } from 'src/app/shared/components/job-modal/job-modal.component';

@Component({
  selector: 'app-my-job-card',
  templateUrl: './my-job-card.component.html',
  styleUrls: ['./my-job-card.component.scss'],
})
export class MyJobCardComponent implements OnInit {
  planTypeConstant = planTypeConstant;
  private unsubscribe$ = new Subject();
  applicantId: any;
  selectedJob: any;
  @Input() job?: any;
  @Input() index?: any;
  @Input() set type(value: any) {
    this.userType = value;
  }
  @Input() set selectedJobStatus(value: any) {
    this.selectedJob = value;
    if (this.job.job._id == this.selectedJob?.jobId) {
      this.job.job.is_active = this.selectedJob?.is_active;
    }
  }
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeCompanyJob: EventEmitter<any> = new EventEmitter<any>();
  @Output() jobedited: EventEmitter<any> = new EventEmitter<any>();
  @Output() jobapplied: EventEmitter<any> = new EventEmitter<any>();

  applicationStatusType = ApplicationStatus;
  isAdmin: boolean;
  userType: any;
  skills: any[] = [];
  interests: any[] = [];
  userInfo: any;
  userRole = userTypeConstant;
  purchasedPlan: any;
  loginedUserId: any;

  constructor(
    public authService: AuthService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private router: Router,
    private sharedService: SharedService,
    private profileService: ProfileService
  ) {
    this.isAdmin = this.authService.isAdmin();
    this.userType = userTypeConstant.company;
  }

  ngOnInit(): void {
    this.getSkillsList();
    this.getInterestList();
    this.getUserInfo();
    this.getPurchasedPlan();
    if (this.authService.loggedIn()) {
      this.dashboard();
    }
  }

  getPurchasedPlan() {
    this.profileService
      .getpurchasedPlan()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.purchasedPlan = res.currentPlan;
      });
  }

  dashboard() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((information) => {
        if (information === null) {
          this.sharedService.getUserInfo();
        } else {
          const { profileInfo } = information;
          this.loginedUserId = profileInfo._id;
        }
      });
  }

  /**
   * Get Skills List
   */
  getSkillsList() {
    this.sharedService.jobCategoryList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((jobcategory: any) => {
        if (jobcategory.length) {
          this.skills = jobcategory;
        } else {
          this.sharedService.categoriesList();
        }
      });
  }

  getInterestList() {
    this.sharedService.skillsOrIntrest$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((interest: any) => {
        if (interest.length) {
          this.interests = interest;
        } else {
          this.sharedService.getSkillsOrIntrest();
        }
      });
  }

  getUserInfo() {
    this.sharedService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userInfo: any) => {
        if (userInfo) {
          this.userInfo = userInfo;
        } else {
          this.sharedService.getUserInfo();
        }
      });
  }

  /**
   * Setting Company on messaging
   * @param company
   */
  messageRecuirter(company: any) {
    localStorage.setItem('companyId', JSON.stringify(company));
    this.router.navigate(['/dashboard/messages'], {
      queryParams: { tab: 1 },
    });
  }

  /**
   * Edit Job
   * @param jobId
   */
  editJob(jobId: String) {
    const dialogRef = this.dialog.open(JobPostComponent, {
      width: '536px',
      height: innerWidth && innerWidth < 768 ? '100vh' : '650px',
      maxWidth: '100vw',
      hasBackdrop: false,
      panelClass: 'post-job-dialog-container',
      data: { jobId: jobId },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.jobedited.emit(true);
      });
  }

  /**
   * Get Location Data
   * @param job
   * @returns
   */
  getLocation(job: any) {
    return (
      job?.city_name +
      (job?.state_name ? `, ${job?.state_name} ` : '') +
      (job?.country_code ? `, ${job?.country_code} ` : '')
    );
  }

  /**
   * Format Date according JobPosted Date
   * @param previousDate
   * @returns
   */
  formatPostDate(previousDate?: any) {
    let currentDate = new Date().getTime();
    let jobDate = new Date(previousDate).getTime();
    let time = (currentDate - jobDate) / 1000;
    let days = Math.floor(time / (3600 * 24));
    let minutes = Math.floor(time / 60);
    let hours = Math.floor(time / (60 * 60));
    let weeks = Math.floor(days / 7);
    let months = Math.floor(days / 30);
    let years = Math.floor(days / 365);

    if (time < 60) {
      return Math.floor(time) + 's';
    } else if (minutes < 60) {
      return minutes + 'm';
    } else if (hours < 24) {
      return hours + 'h';
    } else if (days < 7) {
      return days + 'd';
    } else if (weeks < 4) {
      return weeks + 'w';
    } else if (months < 12) {
      return months + 'mo';
    } else {
      return years + 'y';
    }
  }

  /**
   * Review Job Applicant
   * @param Id
   * @param jobTitle
   */
  reviewApplicant(Id: String, jobTitle: String) {
    this.dialog.open(ReviewApplicantComponent, {
      width: '816px',
      height: '100vh',

      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { id: Id, title: jobTitle, skills: this.interests },
    });
  }

  /**
   * Remove Job
   * @param jobId
   * @param index
   */
  removeJob(job: any, index: any) {
    const removejobDetails = {
      jobId: job._id,
      index: index,
    };
    this.remove.emit(removejobDetails);
  }

  /**
   * Disabled Company Posted Job
   * @param job
   * @param index
   */
  jobRemove(job: any, active: any) {
    let selectedJob = {
      _id: job._id,
      is_active: active,
    };
    this.removeCompanyJob.emit(selectedJob);
  }
  applyJob(selectedJob: any) {
    if (selectedJob) {
      const dialogRef = this.dialog.open(JobApplyModalComponent, {
        maxWidth:'100%',
        width: '536px',
        height: window.innerWidth && window.innerWidth > 768 ? '650px' : '100vh',
        data: {
          job: selectedJob,
        },
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          if (result) {
            this.jobapplied.emit(result);
          }
        });
    }
  }

  openApplyDialog(selectedJobId: any): void {
    const dialogRef = this.dialog.open(JobModalComponent, {
      width: innerWidth && innerWidth > 768 ? '650px' : '100vw',
      height: innerWidth && innerWidth > 768 ? '650px' : '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      data: {
        tab: 'my-jobs',
        id: selectedJobId,
        userType: this.userType,
        interests: this.interests,
        userInfo: this.userInfo,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.action == 'activeDeactiveJob') {
        this.removeCompanyJob.emit(result?.selectedJob);
      }
      if (result?.action == 'unsaveJob') {
        this.remove.emit(result?.selectedJob);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
