<div class="profile-page">
  <app-hero [data]="selectedProfile"></app-hero>
  <div class="grid-view">
    <div class="-mt-10 mx-2 md:mx-0 z-10">
      <app-info-card
        [isFollow]="isFollow"
        (isFollowed)="followCount($event)"
        [currentLoggedInUserId]="currentLoggedInUserId"
        [user]="selectedProfile"
        [postN]="postN"
        [followerN]="followerN"
        [followingN]="followingN"
        [planN]="planN"
        [serviceN]="serviceN"
        [subscribersN]="subscriptionN"
        [averageRating]="averageRating"
        [totalRatings]="totalRatings"
      ></app-info-card>
      <!-- <p
        *ngIf="selectedProfile && averageRating"
        class="blue cursor-pointer font-bold mt-4"
        (click)="openReviewModal()"
      >
        See reviews
      </p> -->
    </div>

    <div class="lg:pl-3.5">
      <div class="w-full mt-4">
        <div class="w-full relative">
          <mat-tab-group
            color="#fff0"
            [selectedIndex]="selected.value"
            (selectedIndexChange)="selected.setValue($event)"
          >
            <mat-tab>
              <ng-template mat-tab-label>
                <div
                  class="hidden xl:flex items-center px-4"
                  [class]="{ 'border-blue': selected.value === 0 }"
                >
                  <h4 class="font-bold" [class]="selected.value === 0 ? 'blue leading-10' : ''">
                    Posts
                  </h4>
                </div>
                <div
                  class="flex-col flex items-center xl:hidden"
                  [class]="{ 'border-blue': selected.value === 0 }"
                >
                  <!-- <mat-icon style="font-size: 26px" [class]="selected.value === 1 ? 'blue' : ''">photo</mat-icon> -->
                  <h4
                    class="tabs-text font-bold"
                    [class]="selected.value === 0 ? 'blue leading-10' : ''"
                  >
                    Posts
                  </h4>
                </div>
              </ng-template>
              <app-profile-home *ngIf="user_name" [user_name]="user_name"></app-profile-home>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div
                  class="hidden xl:flex items-center px-4"
                  [class]="{ 'border-blue': selected.value === 1 }"
                >
                  <h4 class="font-bold" [class]="selected.value === 1 ? 'blue leading-10' : ''">
                    About
                  </h4>
                </div>
                <div
                  class="flex-col flex items-center xl:hidden"
                  [class]="{ 'border-blue': selected.value === 1 }"
                >
                  <!-- <mat-icon style="font-size: 26px"
                    [class]="selected.value === 0 ? 'blue' : ''">account_circle</mat-icon> -->
                  <h4
                    class="tabs-text font-bold"
                    [class]="selected.value === 1 ? 'blue leading-10' : ''"
                  >
                    About
                  </h4>
                </div>
              </ng-template>
              <app-profile-about
                *ngIf="selected.value === 1"
                [user_name]="user_name"
              ></app-profile-about>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <div
                  class="hidden xl:flex items-center px-4"
                  [class]="{ 'border-blue': selected.value === 2 }"
                >
                  <h4 class="font-bold" [class]="selected.value === 2 ? 'blue leading-10' : ''">
                    Jobs
                  </h4>
                </div>
                <div
                  class="flex-col flex items-center xl:hidden"
                  [class]="{ 'border-blue': selected.value === 2 }"
                >
                  <!-- <mat-icon style="font-size: 24px" [class]="selected.value === 2? 'blue' : ''">work
                  </mat-icon> -->
                  <h4
                    class="tabs-text font-bold"
                    [class]="selected.value === 2 ? 'blue leading-10' : ''"
                  >
                    Jobs
                  </h4>
                </div>
              </ng-template>
              <div *ngIf="type === userTypeConstant.company">
                <!-- <h3 *ngIf="!jobs?.length" class="site-color text-center montserrat-bold">No Active Jobs</h3> -->
                <!-- <p class="black-light ml-4 lg:ml-12 mt-3 lg:mt-8 mb-10 lg:mb-12 montserrat-bold uppercase jobs-header" >Jobs</p> -->
                <p
                  class="black-light montserrat-bold uppercase mt-3 lg:mt-1 text-center"
                  *ngIf="!jobs?.length"
                >
                  Not Available
                </p>
                <div *ngIf="jobs?.length" class="flex flex-col lg:flex-row lg:mt-1">
                  <div class="flex flex-col w-full job-list px-4 md:px-0">
                    <div
                      class="scroll"
                      id="jobsContainer"
                      infiniteScroll
                      [infiniteScrollContainer]="'#jobsContainer'"
                      [fromRoot]="true"
                      [infiniteScrollThrottle]="50"
                      [infiniteScrollDistance]="0.5"
                      (scrolled)="onScrollDown()"
                    >
                      <div class="spinner-wrapper">
                        <!-- <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red"
                      type="ball-spin-clockwise" [fullScreen]="true" class="job-list-spinner">
                    </ngx-spinner> -->
                      </div>
                      <div *ngFor="let job of jobs" class="mb-4">
                        <app-job-card
                          [jobList]="job"
                          (getFollowing)="getFollowing()"
                          (save)="saveJob(job)"
                          (apply)="openApplyDialog(job)"
                        ></app-job-card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="type === userTypeConstant.candidate">
                <app-profile-jobs [user_name]="user_name"></app-profile-jobs>
                <!-- <ng-template mat-tab-label> -->
                <!-- <div class="hidden xl:flex items-center px-4" [class]="{'border-blue':selected.value === 2}">
                  <h4 class="font-bold" [class]="selected.value === 2 ? 'blue leading-10' : ''">
                    Jobs
                  </h4>
                </div>
                <div class="flex-col flex items-center xl:hidden" [class]="{'border-blue':selected.value === 2}">
                  <h4 class="tabs-text font-bold" [class]="selected.value === 2 ? 'blue leading-10' : ''">
                    Jobs
                  </h4>
                </div> -->
                <!-- </ng-template> -->
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div
                  class="hidden xl:flex items-center px-4"
                  [class]="selected.value === 3 ? 'border-blue' : ''"
                >
                  <h4 class="font-bold" [class]="selected.value === 3 ? 'blue leading-10' : ''">
                    Services
                  </h4>
                </div>
                <div
                  class="flex-col flex items-center xl:hidden"
                  [class]="selected.value === 3 ? 'border-blue' : ''"
                >
                  <!-- <i style="font-size: 26px" class="fa-solid fa-handshake"
                    [class]="selected.value === 3 && type===userTypeConstant.company ? 'blue' : selected.value ===  2 && type!== userTypeConstant.company ? 'blue': ''">
                  </i> -->
                  <h4
                    class="tabs-text font-bold"
                    [class]="selected.value === 3 ? 'blue leading-10' : ''"
                  >
                    Services
                  </h4>
                </div>
              </ng-template>
              <app-profile-services
                *ngIf="selected.value === 3"
                [user_name]="user_name"
              ></app-profile-services>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div
                  class="hidden xl:flex items-center px-4"
                  [class]="selected.value === 4 ? 'border-blue' : ''"
                >
                  <h4 class="font-bold" [class]="selected.value === 4 ? 'blue leading-10' : ''">
                    Subscribe
                  </h4>
                </div>
                <div
                  class="flex-col flex items-center xl:hidden"
                  [class]="selected.value === 4 ? 'border-blue' : ''"
                >
                  <!-- <mat-icon style="font-size: 26px"
                    [class]="selected.value ===  4 && type=== userTypeConstant.company ? 'blue' : selected.value ===  3 && type!== userTypeConstant.company ? 'blue':''">
                    notifications</mat-icon> -->
                  <h4
                    class="tabs-text font-bold"
                    [class]="selected.value === 4 ? 'blue leading-10' : ''"
                  >
                    Subscribe
                  </h4>
                </div>
              </ng-template>
              <!-- <app-profile-plans
                *ngIf="selected.value === 4"
                [user_name]="user_name" [profileId]="profileId" [currentLoggedInUserId]="currentLoggedInUserId">
              </app-profile-plans> -->
              <app-profile-services
                [isProfileSubscription]="true"
                *ngIf="selected.value === 4"
                [user_name]="user_name"
              ></app-profile-services>
            </mat-tab>
            <!-- <mat-tab *ngIf="type!== userTypeConstant.company">
              <ng-template mat-tab-label>
                <div class="hidden xl:flex items-center px-4" (click)="messageUser()"
                  [class]="selected.value ===  5 && type=== userTypeConstant.company ? 'border-blue' : selected.value ===  4 && type!== userTypeConstant.company ? 'border-blue':''">
                  <h4 class="font-bold"
                    [class]="selected.value ===  5 && type=== userTypeConstant.company ? 'blue leading-10' : selected.value ===  4 && type!==userTypeConstant.company ? 'blue leading-10':''">
                    Messages
                  </h4>
                </div>
                <div class="flex-col flex items-center xl:hidden" (click)="messageUser()"
                  [class]="selected.value ===  5 && type=== userTypeConstant.company ? 'border-blue' : selected.value ===  4 && type!== userTypeConstant.company ? 'border-blue':''"> -->
            <!-- <mat-icon style="font-size: 24px"
                    [class]="selected.value ===  5 && type=== userTypeConstant.company ? 'blue' : selected.value ===  4 && type!== userTypeConstant.company ? 'blue':''">
                    mark_chat_unread
                  </mat-icon> -->
            <!-- <h4 class="tabs-text font-bold"
                    [class]="selected.value ===  5 && type=== userTypeConstant.company ? 'blue leading-10' : selected.value ===  4 && type!==userTypeConstant.company ? 'blue leading-10':''">
                    Messages
                  </h4>
                </div>
              </ng-template>
              <app-my-network-tab
                *ngIf="(sender_id?.length && type== userTypeConstant.company && selected.value === 5) || (sender_id?.length && type!=userTypeConstant.company && selected.value === 4)"
                (myNetworkUnreadMessages)="myNetworkUnreadMessages($event)" [reciever]="reciever"
                [sender_id]="sender_id" [user]="userProfile">
              </app-my-network-tab>
            </mat-tab> -->
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
