import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { userPlans } from 'src/app/modules/services/user-plans/user-plans.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userTypeConstant } from 'src/app/const/appConst';
import { routesConst } from 'src/app/core/const/routers';
import { paymentService } from 'src/app/core/services/stripe-payment.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CommonService } from 'src/app/core/services/common.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-dashboard-services',
  templateUrl: './dashboard-services.component.html',
  styleUrls: ['./dashboard-services.component.scss'],
})
export class DashboardServicesComponent implements OnInit {
  isGoBack = false;
  userTypeConstant = userTypeConstant;
  private unsubscribe$ = new Subject();
  selected = new FormControl(0);
  routesConst = routesConst;
  user_type: string = '';
  mySubscriptions: any[] = [];
  experienceLevel: any[] = [];
  stepperOpen = false;
  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 5,
  };
  constructor(private commonService: CommonService, private sharedService: SharedService, private route: ActivatedRoute, private router: Router, private userPlan: userPlans, private stripePaymentService: paymentService, private snack: SnackBarService) { }

  ngOnInit(): void {
    this.getUserType();
    this.getPlateformPlans();
    this.experienceLevelList();
  }

  back() {
    this.isGoBack = true
  }

  isStepperOpen(event: any) {
    this.stepperOpen = event;
    this.isGoBack = false;
  }

  getUserType() {
    this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
      if (type === "") {
        this.sharedService.getUserType();
      } else {
        this.user_type = type;
      }
    });
  }

  experienceLevelList() {
    this.sharedService.experienceLevelList$.pipe(takeUntil(this.unsubscribe$)).subscribe(experience => {
      if (experience.length) {
        this.experienceLevel = experience;
      } else {
        this.sharedService.getexperienceLevel();
      }
    });
  }

  getPlateformPlans() {
    this.userPlan.userPlanSubscription().pipe(takeUntil(this.unsubscribe$)).subscribe((plan: any) => {
      const { platform_subscription } = plan;
      this.mySubscriptions = platform_subscription;
    });
  }

  ngAfterViewInit() {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params['tab']) {
        this.selected.setValue(params['tab']);
        this.router.navigate(['.'], {
          relativeTo: this.route,
          queryParams: {},
        });
      }
    });
  }

  getPayment() {
    this.stripePaymentService.getLoginLink().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res.status == 'Success') {
        this.commonService.getWindow()?.open(res.link.url, "_blank")
      }
      else {
        this.snack.showMessage(`Please Upgrade Your Membership`,true);
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
