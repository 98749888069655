import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OperationHours } from 'src/app/const/appConst';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { userTypeConstant } from 'src/app/const/appConst';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-dashboard-services-preferences',
  templateUrl: './dashboard-services-preferences.component.html',
  styleUrls: ['./dashboard-services-preferences.component.scss'],
})

export class DashboardServicesPreferencesComponent implements OnInit {
  userTypeConstant = userTypeConstant;
  private unsubscribe$ = new Subject();
  @ViewChild('locationSearchInput', { static: true }) locationSearchInput?: ElementRef;
  formGroup: FormGroup;
  _id: string = '';
  location: string | null = null;
  experienceLists: any[] = []
  filteredOptions: any[] = [];
  operationHours: any[] = [];
  operationHours0: any[] = [];
  preference: any;
  savingBS: boolean = false;
  savingOH: boolean = false;
  isLoggedIn: any;
  type: any;
  to_time: any;
  from_time: any;
  @Input() set experienceLevel(experience_level: any) {
    this.experienceLists = experience_level;
  }

  constructor(private authService: AuthService, private sharedService: SharedService, private fb: FormBuilder, private talentService: TalentService, private profileService: ProfileService, private snack: SnackBarService, private _commonService: CommonService, private spinner: NgxSpinnerService) {
    this.formGroup = this.fb.group({
      phone_number: ['', []],
      location: [null, [Validators.required]],
      website: ['', [Validators.maxLength(512)]],
      license_number: ['', [Validators.maxLength(512)]],
      experience_level: [null, [Validators.required]],
      address: [null, [Validators.maxLength(512)]],
    });
    this.operationHours = JSON.parse(JSON.stringify(OperationHours));
    this.operationHours0 = JSON.parse(JSON.stringify(OperationHours));
  }

  ngOnInit(): void {
    this.userType();
    this.getTalentPreferences();
    this.getOperationHours();
    this._commonService._filterLocation(this.locationSearchInput?.nativeElement).pipe(takeUntil(this.unsubscribe$)).subscribe((location: any) => { this.filteredOptions = location.data; });


    this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(form => {
      if (form.phone_number !== this.preference?.phone_number || form.location !== this.preference?.location[0]?.city_name +
        ' - ' +
        this.preference?.location[0]?.state_name +
        ' - ' +
        this.preference?.location[0]?.country_code || form.website !== this.preference?.website || form.license_number !== this.preference?.license_number || form.experience_level !== this.preference?.experience_level || form.address !== this.preference?.address) {
        this.savingBS = true;
      } else {
        this.savingBS = false;
      }
    })
  }

  public errorHandling = (control: string, error: string) => {
    return this.formGroup.controls[control].hasError(error);
  };

  getNumber(event:any){    
  }
  hasError(event:any){    
  }
  telInputObject(obj:any){    
  }
  onCountryChange(country:any){    
  }

  getTalentPreferences() {
    this.talentService.getTalentPreferences().pipe(takeUntil(this.unsubscribe$)).subscribe((talent: any) => {
      const { data: preference } = talent;
      if (preference?.length) {
        this.preference = preference[0]
        this._id = preference[0]._id;
        this.formGroup
          .get('phone_number')
          ?.setValue(preference[0].phone_number);
        this.formGroup.get('address')?.setValue(preference[0].address);
        if (preference[0]?.location?.length) {
          this.location = preference[0]?.location[0]?._id;
          this.formGroup
            .get('location')
            ?.setValue(
              preference[0]?.location[0]?.city_name +
              ' - ' +
              preference[0]?.location[0]?.state_name +
              ' - ' +
              preference[0]?.location[0]?.country_code
            );
        }
        this.formGroup.get('website')?.setValue(preference[0].website);
        this.formGroup
          .get('license_number')
          ?.setValue(preference[0].license_number);
        this.formGroup
          .get('experience_level')
          ?.setValue(preference[0].experience_level);
      }
    });
  }

  getOperationHours() {
    this.profileService.dashboardOperationHours().pipe(takeUntil(this.unsubscribe$)).subscribe((hours: any) => {
      this.spinner.hide();
      if (hours?.data?.length) {
        if (hours.data[0].operationHours.length > 0) {
          hours.data[0].operationHours.map((item: any) => {
            const idx = this.operationHours.findIndex((oh: any) => oh.day_name === item.day_name);
            delete item.created_at;
            delete item.profile_id;
            delete item.__v;
            this.operationHours[idx] = { ...this.operationHours[idx], ...item };
          });
          this.operationHours0 = JSON.parse(JSON.stringify(this.operationHours));
        }
      }
    });
  }

  setLocation(option: any) {
    this.location = option._id;
  }

  saveTalent() {
    const options = { ...this.formGroup.value, location: this.location, _id: this._id ? this._id : null };
    this.talentService.addTalentPreference(options).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.snack.showMessage('Talent Preference Updated.',false);
      this.savingBS = false;
      this.getTalentPreferences();
      this.getOperationHours();
    },
      () => {
        this.snack.showMessage('Failed to Update Talent Preferences.',true)
        this.savingBS = true;
      }
    );
  }

  saveOperationHours() {
    this.spinner.show()
    const changedArr = this.operationHours.filter((oh, idx) => {
      const str1 = JSON.stringify(oh);
      const str2 = JSON.stringify(this.operationHours0[idx]);
      if (str1 !== str2) return oh;
    });
    if (changedArr?.length > 0) {
      this.savingOH = true;
      const addedArr = changedArr.filter((ch: any) => !ch._id);
      const editedArr = changedArr.filter((ch: any) => !!ch._id);
      if (addedArr && addedArr.length > 0) {
        this.profileService.addOperationHours(addedArr).pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
          if (res.status === 'success') {
            res.data.map((item: any) => {
              const { _id } = item;
              const idx = this.operationHours.findIndex((oh: any) => oh.day_name === item.day_name);
              this.operationHours[idx] = { ...this.operationHours[idx], _id };
            });
          }
          this.spinner.hide()
          if (editedArr && editedArr.length > 0) {
            this.profileService.updateOperationHours(editedArr).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
              this.spinner.hide()
              this.setStateSaved();
            });
          }
          else {
            this.spinner.hide()
            this.setStateSaved();
          }
        },
          () => {
            if (editedArr && editedArr.length > 0) {
              this.profileService.updateOperationHours(editedArr).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                this.setStateSaved();
              },
                () => {
                  this.setStateSaved();
                }
              );
            }
            else {
              this.setStateSaved();
            }
          }
        );
      }
      else if (editedArr && editedArr.length > 0) {
        this.profileService.updateOperationHours(editedArr).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
          this.getOperationHours();
          this.setStateSaved();
        },
          () => {
            this.spinner.hide()
            this.setStateSaved();
          }
        );
      }
    }
  }

  setStateSaved() {
    this.savingOH = false;
    this.snack.showMessage('Operation Hours Updated',false);
  }

  setHour(evt: any) {
    if (evt.field == 'from_time') {
      this.from_time = evt.value
    }
    if (evt.field == 'to_time') {
      this.to_time = evt.value
    }
    const idx = this.operationHours.findIndex((oh: any) => oh.day_name === evt.day_name);
    this.operationHours[idx][evt.field] = evt.value;
    if (this.operationHours[idx].closed === true) {
      this.operationHours[idx].from_time = '';
      this.operationHours[idx].to_time = '';
    }
    this.savingOH = true;
  }

  userType(): void {
    this.isLoggedIn = this.authService.loggedIn();
    if (this.isLoggedIn) {
      this.sharedService.userType$.pipe(takeUntil(this.unsubscribe$)).subscribe((type: any) => {
        if (type === "") {
          this.sharedService.getUserType();
        } else {
          this.type = type;
        }
      });
    }
  }

  validateWebsite(control: string) {
    return (this.formGroup.controls[control]?.value?.length && (!(this.formGroup.controls[control]?.value.includes('https://')) && !(this.formGroup.controls[control]?.value.includes('http://')))) ? true : false
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
