<div class="drag" cdkDrag  cdkDragLockAxis="y" (cdkDragEnded)="onDragEnded($event)" (cdkDragMoved)="onDragMoved($event)">
    <div class="drag-icon cursor-pointer" (click)="closeSheet($event)"></div>
<!-- <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
  [fullScreen]="true" class="job-list-spinner">
</ngx-spinner> -->
<div class="pb-12">
    <div class="heading flex justify-center my-2.5 blue">{{data.heading}}</div>
    <mat-divider></mat-divider>
    <ng-container *ngFor="let item of data.items">
        <div *ngIf="item?.role?.includes(userType)" class="flex items-center m-2.5"
            (click)="navigateTo($event, '/'+item.redirect_url , item?.openDialog);sharedService.bottomSheetElement$.next(item.item);">
            <mat-icon *ngIf="item.item != 'Experts'" [class]="colorVariable == item.item?'active':''">{{item.icon}}</mat-icon>
            <i class="fa-solid fa-handshake" *ngIf="item.item === 'Experts'" [class]="colorVariable == item.item?'active':''"></i>
            <p class="ml-3.5" [class]="colorVariable ==item.item?'active':''">{{item.item}}</p>
        </div>
    </ng-container>
</div>
</div>