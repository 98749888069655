import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { userTypeConstant } from 'src/app/const/appConst';
import { JobService } from 'src/app/core/services/job.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-my-applications',
  templateUrl: './my-applications.component.html',
  styleUrls: ['./my-applications.component.scss'],
})
export class MyApplicationsComponent implements OnInit {
  private unsubscribe$ = new Subject();
  allCategories: any;
  form!: FormGroup;
  selectedJobStatus: any;
  @Input() jobFilter: any;
  @Input() selected: any;
  @Input() userId: any;
  @Input() totalAvaileJobs: any;
  @Input() totalcount: any;

  @Input() set jobs0(value: any) {
    this.jobs = value;
  }

  @Input() set type(value: any) {
    this.userType = value;
  }
  @Input() set selectedJobId(value: any) {
    this.selectedJobStatus = value;
  };

  @Output() jobselectedCategory: EventEmitter<any> = new EventEmitter<any>();
  @Output() removejob: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeCompanyJob: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchInputValue = new EventEmitter<any>();
  @Output() refreshJobs = new EventEmitter<any>();

  jobs: any;
  userType: String;
  searchedText: any;
  keywordToSearch: any = ''

  constructor(
    public _jobsService: JobService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService
  ) {
    this.userType = '';
    this.form = this.fb.group({
      category: new FormControl(),
      searchInput: new FormControl(),
    });
  }
  ngOnInit() {
    this.jobs = [];
    this.getCategoriesList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selected']) {
      this.form.get('category')?.setValue('')
    }
  }

  remove(jobId: any) {
    this.removejob.emit(jobId);
  }

  jobRemove(jobData: any) {
    this.removeCompanyJob.emit(jobData);
  }

  /**
   * Get Filters Category Data
   */
  getCategoriesList() {
    this.sharedService.jobCategoryList$.pipe(takeUntil(this.unsubscribe$)).subscribe((jobcategory: any) => {
      if (jobcategory.length) {
        this.allCategories = jobcategory;
      } else {
        this.sharedService.categoriesList();
      }
    });
  }

  onCategoryChange() {
    if (this.selected == 2 || this.selected == 1) {
      if (this.form.get('category')?.value?.length || this.keywordToSearch?.length) {
        const userAppliedJobQueryParams = {
          userid: this.userId,
          offset: 0,
          limit: 10,
        };
        if (this.form.get('category')?.value?.length) {
          Object.assign(userAppliedJobQueryParams, { jobCategory: this.form.get('category')?.value });
        }
        this._jobsService.getAppliedJobsByUserIdFilter(userAppliedJobQueryParams).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
          if (result && result.data) {
            this.jobs = result.data.filter((x: any) => {
              return x.job.isSaved == true;
            })
          }
        });
      }
      else {
        this.getUserAppliedJobs();
      }
    }
    if (this.selected == 0 && this.userType === userTypeConstant.company && !this.keywordToSearch?.length && !this.form.get('category')?.value?.length) {
      this._jobsService.getCompanyJobSByFilter(this.form.value.category, this.userId).pipe(takeUntil(this.unsubscribe$)).subscribe((x: any) => {
        this.jobs = x.data;
        this.totalAvaileJobs = x.totalJobAvailble;
      });
    }
    this.jobselectedCategory.emit(this.form.value.category);
  }

  /**
  * Get User Applied Jobs Details
  */
  getUserAppliedJobs() {
    if (this.userId) {
      const userAppliedJobQueryParams = {
        userid: this.userId,
        offset: 0,
        limit: 10,
      };
      this._jobsService.getUserAppliedJobs(userAppliedJobQueryParams).pipe(takeUntil(this.unsubscribe$)).subscribe((result: any) => {
        if (result && result.data) {
          this.jobs = result.data.filter((x: any) => {
            return x.job.isSaved == true;
          })
        }
      });
    }
  }

  onScrollDown() {
    this.jobFilter.offset = this.jobFilter.offset + 10;
    if (this.totalcount != this.totalAvaileJobs) {
      this.spinner.show();
      this._jobsService.jobListBycompany(this.userId, this.jobFilter).pipe(takeUntil(this.unsubscribe$)).subscribe((jobs: any) => {
        if (jobs && jobs.data.length) {
          this.spinner.hide();
          this.jobs = [...this.jobs, ...jobs.data];
          this.totalcount = this.jobs.length;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      });
    }
  }

  searchByKeyword(event: any) {
    if (event.key === 'Enter' || !this.keywordToSearch?.length) {
      this.searchedText = this.form.controls['searchInput'].value;
      this.searchInputValue.emit(this.form.controls['searchInput'].value);
    }
    if (this.keywordToSearch == '' && (event.key == 'Delete' || event.key == 'Backspace')) {
      this.removefilter();
    }
  }

  removefilter() {
    this.form.controls['searchInput'].setValue(null);
    this.searchInputValue.emit(this.form.controls['searchInput'].value);
    if (this.selected === 2 && !this.form.controls['searchInput']?.value?.length && this.form.get('category')?.value?.length) {
      this.onCategoryChange();
    }
  }

  refreshJob(value: any) {
    this.refreshJobs.emit(value);
  }

  jobapplied(isApplied: any) {
    if (isApplied) {
      this.refreshJob(isApplied)
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
