import { Injectable } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { ProfileService } from './profile.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private profileService: ProfileService, private spinner: SpinnerService) { }
  requestCount = 0;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requestCount++;
    if (this.requestCount > 0) {
      this.spinner.show()
    }
    const token = this.profileService.loadUserCredentials();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((err) => {
        this.requestCount--;
        this.onRequestComplete();
        if (err.status === 401) {
          // this.authService.logout();
        }
        const error = err?.error?.message || err?.statusText;
        return throwError(error);
      }),
      finalize(() => {
        this.requestCount--;
        this.onRequestComplete();
      }),
    );
  }

  onRequestComplete() {
    if (this.requestCount < 1) {
       this.spinner.hide();
    }
  }
}
