import { Component, HostListener, OnInit } from '@angular/core';
import { routesConst } from 'src/app/core/const/routers';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  innerWidth = window.innerWidth;
  routesConst = routesConst;
  config: SwiperOptions = {
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1
      }
    }
  };
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  constructor() {}

  ngOnInit(): void {}

  scroll(element: any) {
    if (element !== '') {
      const targetElement = document.querySelector(element);
      let marginTop = this.innerWidth < 768 ? 64 : 80
    if (targetElement) {
      const offset = targetElement.getBoundingClientRect().top + window.pageYOffset - marginTop;
      window.scrollTo({
        top: offset,
        behavior: "smooth"
      });
    }
    }
  }
}
