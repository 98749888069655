import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { paymentService } from 'src/app/core/services/stripe-payment.service';

export interface PeriodicElement {
  id: string;
  description: any;
  date: string;
  amount: string;
}

@Component({
  selector: 'app-dashboard-payment-history',
  templateUrl: './dashboard-payment-history.component.html',
  styleUrls: ['./dashboard-payment-history.component.scss']
})
export class DashboardPaymentHistoryComponent implements OnInit {
  private unsubscribe$ = new Subject();
  displayedColumns: string[] = ['id', 'description', 'date', 'amount'];
  dataSource: any;

  constructor(private stripePaymentService: paymentService) { }

  ngOnInit(): void {
    this.getPaymentList();
  }

  getPaymentList() {
    this.stripePaymentService.getPaymentList().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.dataSource = this.sortByCreatedDate(res.data)
    });
  }

  sortByCreatedDate(arr: any) {
    arr.sort((a: any, b: any) => {
      var dateA: any = new Date(a.created);
      var dateB: any = new Date(b.created);
      return dateB - dateA;
    });
    return arr;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
