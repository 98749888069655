import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Router } from '@angular/router';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
import { routesConst } from 'src/app/core/const/routers';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { TalentService } from 'src/app/core/services/talent.service';
import { SharedService } from '../../services/shared.service';
import { SwiperOptions } from 'swiper';
import { ImageService, ImageSnippet } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss']
})
export class ServiceModalComponent implements OnInit {
  @ViewChild('file') file: any;
  private unsubscribe$ = new Subject();
  config: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1
  };

  user_id: any = '';
  packageDescription: any;
  title: any;
  serviceIndex: FormControl = new FormControl();
  serviceTitle: FormControl = new FormControl();
  priceRange: FormControl = new FormControl();
  unSubscribe$ = new Subject();
  serviceCategories: any[];
  filteredCategories: any[];
  selectedServiceCategory: any[];
  packageSize: number;
  isLastStep: boolean;
  portfolios: any[] = [];
  packageList: any[];
  allPayRange: any[];
  allImages: any[] = [];
  imageUrl: any = '';
  productPriceCreated: any = false;
  checkPrice: any;
  price: any = null;
  updatedata = false;
  updatedImage = false;
  showImages = false;
  imageIndex: number = 0;
  updateImage = false;
  visibility: string[] = [];

  constructor(
    private snack: SnackBarService,
    private dialogRef: MatDialogRef<ServiceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private talentService: TalentService,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private imageService: ImageService
  ) {
    dialogRef.disableClose = true;
    this.checkPrice = {
      isInValid: false,
      message: ''
    };
    this.serviceCategories = [];
    this.filteredCategories = [];
    this.selectedServiceCategory = [];
    this.packageSize = 1;
    this.isLastStep = false;
    for (let i = 0; i < 8; i++) {
      this.portfolios[i] = '';
    }
    this.packageList = [{}, {}, {}];
    // this.packageList[0] = {};
    this.allPayRange = [];
  }

  ngOnInit(): void {
    if (this.data?.editmode) {
      this.serviceTitle.setValue(this.data?.serviceData?.title);
      this.title = this.data?.serviceData?.title;
      const { skill_id, skill_name } = this.data?.serviceData;
      const paylaod = { id: skill_id, label: skill_name };
      this.selectedServiceCategory.push(paylaod);
    }
    this.serviceIndex?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.filterService(res);
    });
    this.talentService
      .getTalentServiceCategory()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.data) {
          this.serviceCategories = res.data;
          this.filterService('');
          document.getElementById('searchServiceIdx')?.focus();
        }
      });
    this.getPayRangeList();
  }

  onCheckboxChange(value: string, event: any): void {
    if (event.checked) {
      this.visibility.push(value);
    } else {
      this.visibility = this.visibility.filter(
        (item) => item !== value
      );
    }
  }
  getPayRangeList() {
    this.sharedService.talentServicePayRangeList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(payrangelist => {
        if (payrangelist.length) {
          this.allPayRange = payrangelist;
        } else {
          this.sharedService.getTalentServicePayRangeList();
        }
      });
  }

  onClose() {
    this.dialogRef.close();
  }

  filterService(idx: string) {
    this.filteredCategories = [];
    this.serviceCategories.map((catGroup: any) => {
      let filteredList: any[] = [];
      catGroup.servicecategory.map((serviceCat: any) => {
        if (serviceCat.name.toLowerCase().includes(idx.toLowerCase()) || idx === '') {
          filteredList = [
            ...filteredList,
            {
              id: serviceCat._id,
              label: serviceCat.name
            }
          ];
        }
      });
      if (filteredList.length > 0) {
        this.filteredCategories.push({
          name: catGroup.name,
          serviceCategory: filteredList
        });
      }
    });
  }

  updateCategory(item: any) {
    if (this.selectedServiceCategory && this.selectedServiceCategory.length > 0) {
      if (
        this.selectedServiceCategory.find((selectedService: any) => selectedService.id === item.id)
      ) {
        this.selectedServiceCategory = this.selectedServiceCategory.filter(
          (selectedServiceCategory: any) => selectedServiceCategory.id !== item.id
        );
      } else {
        this.selectedServiceCategory.push(item);
      }
    } else {
      this.selectedServiceCategory = [item];
    }
    this.updateCheck();
  }

  updateCheck() {
    if (this.data.editmode) {
      if (
        this.updatedImage ||
        this.title !== this.data?.serviceData?.title ||
        this.selectedServiceCategory[0].id !== this.data?.serviceData?.skill_id
      ) {
        this.updatedata = true;
      } else {
        this.updatedata = false;
      }
    }
  }

  goToNext(stepper: MatStepper) {
    if (!this.isLastStep) {
      this.isLastStep = true;
      stepper.next();
    }
  }

  goToPrev(stepper: MatStepper) {
    this.isLastStep = false;
    stepper.previous();
  }

  getArray(size: number) {
    let array = [0];
    for (let i = 1; i < size; i++) {
      array.push(i);
    }
    return array;
  }

  addPackage() {
    this.packageSize++;
    this.packageList[this.packageSize - 1] = {};
  }

  removeService(selectedServiceId: string) {
    this.selectedServiceCategory = this.selectedServiceCategory.filter(
      service => service.id !== selectedServiceId
    );
  }

  saveImageUrl(url: string, idx: number) {
    this.imageUrl = url;
    this.portfolios[idx] = {
      url,
      cover_type: idx === 0,
      media_type: 'image',
      order_by: idx
    };
  }

  savePackageData(formData: any, idx: number, packageType: string) {
    Object.assign(formData, { type: packageType });
    this.packageDescription = formData.description;
    this.price = formData?.price ? +formData?.price : this.price;
    this.validatePrice(formData);
    this.packageList[idx] = formData;
  }

  validatePrice(formData?: any) {
    this.price = formData?.price ? +formData?.price : this.price;
    if (this.priceRange?.value?.label) {
      let extractNumber = /\d+/g;
      let label = this.priceRange?.value?.label;
      let matches = label.match(extractNumber);
      matches = matches.map((x: any) => {
        return +x;
      });
      if (matches.length == 2) {
        if ((this.price >= matches[0] && this.price <= matches[1]) || this.price == null) {
          this.checkPrice.isInValid = false;
        } else {
          this.checkPrice.isInValid = true;
          this.checkPrice.message = `please select price between $${matches[0]} - $${matches[1]}`;
        }
      } else if (matches.length == 1) {
        if (this.price > matches[0] || this.price == null) {
          this.checkPrice.isInValid = false;
        } else {
          this.checkPrice.isInValid = true;
          this.checkPrice.message = `please select price greater than $${matches[0]}`;
        }
      }
    } else {
      this.checkPrice.isInValid = true;
      this.checkPrice.message = 'please select price range first';
    }
  }

  saveService() {
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/']);
      this.dialog.open(LoginSignupDialogComponent, {
        width: window.innerWidth > 600 ? '400px' : '100vw',
        height: window.innerWidth > 600 ? 'h-fit' : '100%',
        maxWidth: window.innerWidth > 600 ? '96%' : '100vw',
        maxHeight: window.innerWidth > 600 ? '600' : '100vh',
        data: { pageToOpen: 'login' }
      });
      return;
    }
    this.spinner.show();
    let promises: any[] = [];
    this.allImages.forEach(async (item: any, index: any) => {
      if (item?.file) {
        promises.push(this.convertImages(item, index));
      }
    });
    Promise.all(promises).then(() => {
      const option: any = {
        skill_id: this.selectedServiceCategory[0].id,
        skill_name: this.selectedServiceCategory[0].label,
        title: this.serviceTitle.value,
        portfolio: this.allImages,
        visibility: this.visibility,
        profile_id: this.data?.isModeratorMode ? this.data?.profile_id : null,
        packages: this.packageList.filter(
          (item: any) =>
            item?.billing_type?.length &&
            item?.description?.length &&
            item?.offers?.length &&
            item?.price !== null
        ),
        price_range: this.priceRange?.value?.id ? this.priceRange?.value?.id : '',
        is_active: true
      };
      if (this.data.editmode) {
        let updateServicePayload = {
          _id: this.data.serviceData._id,
          skill_id: this.selectedServiceCategory[0].id,
          skill_name: this.selectedServiceCategory[0].label,
          title: this.serviceTitle.value,
          portfolio: this.portfolios.filter(item => item.url !== '')
        };
        this.talentService
          .updateService(updateServicePayload)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            this.spinner.hide();
            if (res.status === 'success') {
              this.dialogRef.close({ data: updateServicePayload });
              this.snack.showMessage('Service updated successfully.', false);
            }
          });
      } else {
        this.talentService
          .saveService(option)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            this.spinner.hide();
            if (res.status == 'success') {
              if (res) {
                this.dialogRef.close({ data: option });
              }
              this.router.navigate([routesConst.talents], {
                queryParams: { profile_id: this.user_id }
              });
              this.snack.showMessage('Successfully added a new service.', false);
            } else {
              this.snack.showMessage(res.message, true);
            }
          });
      }
    });
  }

  checkServiceValidation() {
    if (this.data?.editmode) {
      if (
        this.title?.length > 256 ||
        this.serviceTitle.invalid ||
        !this.selectedServiceCategory.length
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.packageDescription?.length > 1024 ||
        this.title?.length > 256 ||
        !this.title?.trim()?.length ||
        this.serviceTitle.invalid ||
        !this.allImages?.length ||
        this.priceRange.invalid ||
        !this.packageList.length ||
        !this.packageList[0].billing_type ||
        !this.packageList[0].description ||
        !this.packageList[0].description.trim().length ||
        !this.packageList[0].offers ||
        !this.packageList[0].price ||
        this.checkPrice.isInValid ||
        !this.checkPackageValidations()
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkPackageValidations() {
    const hasValidPackages = this.packageList.every(
      (item: any) =>
        item?.billing_type?.length &&
        item?.description?.length &&
        item?.offers?.length &&
        item?.price !== null
    );
    return hasValidPackages;
  }

  uploadFile(files: FileList, index: number) {
    this.spinner.show();
    let format: string;
    if (files.length === 0) {
      this.spinner.hide();
      return;
    }
    if (files.length > 4) {
      this.spinner.hide();
      this.snack.showMessage('You can only upload a maximum of 4 files.', true);
      this.spinner.hide();
      return;
    }

    let videoCount = this.allImages.filter(image => image.media_type === 'video').length;
    let imageCount = this.allImages.filter(image => image.media_type === 'image').length;

    for (let i = 0; i < files.length; i++) {
      if (files[i].type.includes('image') || files[i].type.includes('video')) {
        if (files[i].type.includes('video') && videoCount >= 1) {
          this.snack.showMessage('You can only upload a maximum of 1 video.', true);
          this.spinner.hide();
          return;
        }
        if (files[i].type.includes('image') && imageCount >= 3) {
          this.snack.showMessage('You can only upload a maximum of 3 images.', true);
          this.spinner.hide();
          return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(files[i]);
        reader.onload = (event: any) => {
          if (
            (files[i].size < 4294967296 && files[i].type.includes('image')) ||
            files[i].type.includes('video')
          ) {
            // if (files[i].type.indexOf('image') > -1) {
            format = files[i].type.includes('image') ? 'image' : 'video';
            const file: File = files[i];
            const newImagePath = {
              cover_type: i == 0,
              media_type: format,
              order_by: i,
              url: event.target.result,
              file
            };

            if (format === 'video' && videoCount < 1) {
              this.allImages.push(newImagePath);
              videoCount++;
            } else if (format === 'image' && imageCount < 3) {
              this.allImages.push(newImagePath);
              imageCount++;
            }
            this.spinner.hide();
            // }
          } else {
            this.snack.showMessage(`Size exceeded`, true);
            this.spinner.hide();
          }
        };
      } else {
        this.snack.showMessage('File Type Not Supported', true);
        this.spinner.hide();
        return;
      }
      if (i + 1 == files.length) {
        setTimeout(() => {
          this.showImages = true;
        }, 50);
      }
    }
  }

  clearFileInput(fileInput: HTMLInputElement) {
    fileInput.value = '';
  }

  removeImage(index: number, event: any) {
    this.allImages.splice(index, 1);
    event.preventDefault();
  }

  async convertImages(item: any, index: any) {
    await new Promise(async resolve => {
      await this.imageService
        .imageConvertion(new ImageSnippet(item.url, item.file))
        .then((imageUri: any) => {
          const newImagePath = {
            cover_type: index == 0,
            media_type: item.media_type,
            url: imageUri[0],
            order_by: index
          };
          this.allImages[index] = newImagePath;
        });
      resolve(true);
    });
  }

  uploadButtonClick(): void {
    this.file.nativeElement.click();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
