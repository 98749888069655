<div class="login-signup-share relative pt-3">
  <mat-icon class="text-3xl absolute close-icon top-3 right-0 md:-top-4 md:-right-3 cursor-pointer text-black" mat-dialog-close>close</mat-icon>
  <a routerLink="{{ routesConst.launchPath }}" class="absolute top-3 left-3 md:-left-3 md:-top-3">
    <img src="https://jobprosmedia.s3.us-west-1.amazonaws.com/Job+Pros+Logo_final_bird_black.png"
      class="w-9 object-cover" />
  </a>
  <app-auth-sign-in [isDialog]="true" (closeDialog)="close(true)" (forsignup)="signInSignUp($event)" *ngIf="loginSignUp.includes('login')"></app-auth-sign-in>
  <app-auth-sign-up [isDialog]="true" (forsignup)="signInSignUp($event)" (closeDialog)="close(false)" *ngIf="loginSignUp.includes('SignUp')"></app-auth-sign-up>
  <app-auth-reset-password [isDialog]="true" (forsignup)="signInSignUp($event)" *ngIf="loginSignUp.includes('ResetPassword')"></app-auth-reset-password>
</div>
