<div class="mt-0">
    <div class="flex justify-end">
        <mat-icon class="cursor-pointer" (click)="onClose()">cancel</mat-icon>
    </div>
    <p class="montserrat-bold">Contact Details</p>
    <div class="flex items-center mt-7"
        *ngIf="data.user?.profile?.phone_number?.length && data.user?.profile?.phone_number[0]!==null && data?.user?.profile?.user_type == userTypeConstant.company">
        <span class="material-icons blue">phone</span>
        <p class="ml-2">{{ data.user.profile.phone_number[0] }}</p>
    </div>
        <div class="flex items-center mt-5" *ngIf="data?.user?.profile?.website?.length && data?.user?.profile?.user_type == userTypeConstant.company">
            <span class='material-icons blue'>language</span>
            <a [href]="data.user.profile.website[0]" class="ml-2">{{ data.user.profile.website[0] }}</a>
        </div>
    <div class="flex items-center mt-5" *ngIf="data?.user?.profile?.location?.length">
        <mat-icon class="gray-dark pb-0.5" style="
        font-size: 22px;
        display: flex;
        color: #2887ed;
        align-items: center;
        width: 20px;
      ">room</mat-icon>
        <p class="ml-2">
            
            {{ data.user.profile.location[0].city_name}},
            {{ data.user.profile.location[0].state_name}},
            {{data.user.profile.location[0].country_code}}</p>
    </div>
    <!-- <div class="flex items-center mt-5"
        *ngIf="data?.user?.profile?.address?.length && data?.user?.profile?.address[0]!==''">
        <i class="fa-sharp fa-solid fa-location-arrow material-icons blue"></i>
        <p class="ml-2">{{ data.user.profile.address[0] }}</p>
    </div> -->
    <div class="flex items-center mt-5" *ngIf="data?.user?.profile?.experiencelevel?.length">
        <i class="fa-solid fa-briefcase blue material-icons"></i>
        <p class="ml-2">{{ data.user.profile.experiencelevel[0]?.job_experience }}</p>
    </div>
</div>
<div class="mt-9 mb-5">
    <p class="montserrat-bold">Business Hours</p>
    <div class="mt-2">
        <div class="flex justify-between items-center mt-2 gap-2" *ngFor="let item of data.operationHours">
            <p>{{item.day_name}}</p>
            <p *ngIf="!item.closed">
                {{item.from_time}} - {{item.to_time}}
            </p>
            <p *ngIf="item.closed">Closed</p>
        </div>
    </div>
</div>