<div class="dashboard-payment-balance">
  <!-- <ngx-spinner bdOpacity="0.9" bdColor="black-dark" size="medium" color="red" type="ball-spin-clockwise"
    [fullScreen]="true" class="job-list-spinner">
  </ngx-spinner> -->
  <div class="section-wrapper bg-white lg:px-10 lg:pt-7 pb-10">
    <div class="w-full border-normal rounded px-6 py-4">
      <div class="w-full mb-4 pb-0.5 lg:pb-4 lg:mb-0">
        <div>
          <div class="flex justify-between">
            <p class="montserrat-bold text-black">Balance</p>
            <button mat-button class="montserrat-medium button outlined black" (click)="getPayment()">
              Payments</button>
          </div>
          <div class="flex items-center mt-4">
            <h1 class="user-blance">${{userBalance && userBalance>0?(userBalance/100):0}}</h1>
            <p class="s ml-2">USD</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>